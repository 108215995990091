import clsx from "clsx";
import { AnimatePresence, motion } from "framer-motion";
import useClickOutside from "hooks/useClickOutside";
import React, { useEffect, useRef, useState } from "react";
import classes from './CustomSelectorValues.module.scss';

const CustomSelectorValues = ({
  id,
  disabled = false,
  onToggle,
  onChange,
  selectedValue,
  items = [],
  inputValidity,
  placeholder,
  validation,
  errorMessage,
  hasError: externalHasError,
}) => {
  const ref = useRef();
  const [open, setOpen] = useState(false);
  const [query, setQuery] = useState("");
  const [filteredList, setFilteredList] = useState(items);
  const [hasError, setHasError] = useState(externalHasError);
  const [firstLoad, setFirstLoad] = useState(true);

  useEffect(() => {
    setHasError(externalHasError);
  }, [externalHasError]);

  const optRef = useClickOutside(() => {
    if (open) {
      handleToggle();
    }
  });

  useEffect(() => {
    if (!firstLoad) setHasError(!selectedValue);
    let allValid = true;

    validation?.forEach((test) => {
      if (!test(selectedValue)) {
        allValid = false;
        return;
      }
    });
    if (inputValidity && allValid) inputValidity(allValid);
  }, [selectedValue, firstLoad]);

  useEffect(() => {
    if (query.length > 0) {
      handleSearch();
    } else {
      setFilteredList(items);
    }
  }, [query]);

  const handleSearch = () => {
    setFilteredList(
      items.filter((item) =>
        item.toLowerCase().startsWith(query.toLowerCase())
      )
    );
  };

  const handleToggle = (e) => {
    if (e) e.stopPropagation();
    if (open) {
      setOpen(false);
      if (firstLoad) setFirstLoad(false);
    } else {
      setOpen(true);
    }
    if (onToggle) onToggle();
  };

  const handleTypeSelection = (item) => {
    setHasError(false);
    onChange(item);
    handleToggle();
  };

  return (
    <div ref={optRef}>
      <div className="relative">
        <button
          type="button"
          className={clsx(
            disabled ? "bg-neutral-100" : "bg-white",
            hasError ? "border-2 border-[#FF6868]" : "border border-gray-300",
            // 2px solid #FF6868;
            "relative h-20 w-full rounded-xl shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
          )}
          style={{ height: "48px" }}
          aria-haspopup="listbox"
          aria-expanded="true"
          aria-labelledby="listbox-label"
          onClick={(e) => {
            handleToggle(e);
          }}
          disabled={disabled}
        >
          {selectedValue ? (
            <div className="pl-3 py-2">
              <span className="flex flex-row items-center">
                <p className="text-2xl truncate">
                  {selectedValue || ""}
                </p>
              </span>
              <span
                className={`absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none ${
                  disabled ? "hidden" : ""
                }`}
              >
                <svg
                  className="h-5 w-5 text-gray-400"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </span>
            </div>
          ) : (
            <div className="px-3 py-2">
              <p className="text-2xl text-black opacity-[0.35] ">
                {placeholder || ""}
              </p>
              <span
                className={`absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none ${
                  disabled ? "hidden" : ""
                }`}
              >
                <svg
                  className="h-5 w-5 text-gray-400"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </span>
            </div>
          )}
        </button>
        {hasError && (
          <div className="text-[#FF6868] font-normal text-xl mt-3 top-20 flex">
            {errorMessage || ""}
          </div>
        )}

        <AnimatePresence>
          {open && (
            <motion.ul
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.1 }}
              className="absolute top-20 overflow-hidden z-10 mt-1 w-full bg-white shadow-lg rounded-md text-base ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
              tabIndex={-1}
              role="listbox"
              aria-labelledby="listbox-label"
              aria-activedescendant="listbox-option-3"
            >
              <div className="sticky h-20 top-0 z-10 bg-white">
                <li className=" text-gray-900 cursor-default select-none relative py-6 px-6">
                  <input
                    type="search"
                    name="search"
                    autoFocus
                    autoComplete="off"
                    className="focus:bg-none outline-none focus:border-none block w-full h-full text-2xl rounded-md"
                    placeholder={"Search a Business Type"}
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                  />
                </li>
                <hr />
              </div>

              <div className={classes.dropdown}>
                
                <div className={classes.set}>
                  <div className={classes.header}>All Business Types</div>
                    <ul
                      className={
                        "max-h-72 scrollbar scrollbar-track-gray-100 scrollbar-thumb-gray-300 hover:scrollbar-thumb-gray-600 scrollbar-thumb-rounded scrollbar-thin overflow-y-scroll"
                      }
                    >
                      {filteredList?.length === 0 ? (
                        <li className="text-gray-900 font-normal text-xl cursor-default select-none relative py-4 pl-4 pr-9">
                          No Business Types found
                        </li>
                      ) : (
                        filteredList.map((item, index) => {
                          return (
                            <li
                              key={`${id}-${index}`}
                              className="text-gray-900 h-16 ml-2 cursor-default select-none relative py-4 pl-4 pr-9 flex items-center hover:bg-gray-50 transition"
                              id="listbox-option-0"
                              role="option"
                              onClick={() => handleTypeSelection(item)}
                            >

                              <span className="font-normal text-xl truncate">
                                {item}
                              </span>
                              {item === selectedValue ? (
                                <span className="text-blue-600 absolute m-auto inset-y-0 right-8 flex items-center">
                                  <svg
                                    className="h-7 w-7"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    aria-hidden="true"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                      clipRule="evenodd"
                                    />
                                  </svg>
                                </span>
                              ) : null}
                            </li>
                          );
                        })
                      )}
                    </ul>
                </div>
              </div>
            </motion.ul>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};

export default CustomSelectorValues;