import classes from 'Components/Global/Search.module.scss';
import searchIcon from 'assets/Images/search.svg';

const Search = (props) =>{
    return (
        <div className={`${classes.search} ${props.className}`}>
            <img src={searchIcon} alt='search-icon' className={classes.icon}/>
            <input type='text'placeholder={props.placeholder} className={classes.input}/>
        </div>
    )
}
export default Search;