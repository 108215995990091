// import classes from 'Components/Tour/TourStartHeader.module.scss'
import clsx from 'clsx'
import LeftIcon from 'assets/Icons/chevron-left-black.svg'
import RightIcon from 'assets/Icons/chevron-right.svg'
import CloseIcon from 'assets/Icons/close-white-icon.svg'
import Button from 'Layout/Button';
import { useRef } from 'react';
import { GlobalActions } from "Store/global-redux";
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { modalActions } from "Store/modal-redux"

const TooltipTourData = ({
    heading,
    body1,
    body2,
    // backButton,
    // nextButton,
    path,
    previousUrl,
    finalStep,
    firstStep
}) => {
  const buttonRef = useRef(null);
  const navigate = useNavigate();
  const stepNumber = useSelector((state) => state.global.stepNumberInTour);
  const dispatch = useDispatch();

//   console.log(
//     heading,
//     body1,
//     body2,
//     backButton,
//     nextButton,
//     path,
//     previousUrl,
//     finalStep,
//     firstStep,
//     stepNumber,
//     "xxxxxx"
//   )

  return (
    <div className="w-[400px]">
        <div className='px-[20px] py-[24px] gap-[16px] flex flex-col'>
            <div className={clsx('text-[18px] leading-[24px] font-[500] text-[#FFFFFF]')}>{heading}</div>
            <div className={clsx('text-[14px] leading-[20px] font-[400] text-[#FFFFFF]')}>{body1}</div>
            { body2 &&
                    <div className={clsx('text-[14px] leading-[20px] font-[400] text-[#FFFFFF]')}>{body2}</div>
            }
        </div>
        <img
            src={CloseIcon}
            alt="close-x"
            className="w-[24px] h-[24px] absolute top-[8px] left-[366px] cursor-pointer"
            onClick={() => {
                dispatch(GlobalActions.setStepNumberInTour(0));
                dispatch(GlobalActions.setShowTour(false))
            }}
        />
        <div className='px-[20px] py-[12px] gap-[12px] flex justify-end' style={{ borderTop: "1px solid rgba(255, 255, 255, 0.12)" }}>
            <Button
                text=""
                ref={buttonRef}
                onClick={() => {
                    if (firstStep) {
                        dispatch(GlobalActions.setStepNumberInTour(0));
                        dispatch(GlobalActions.setShowTour(false));
                        dispatch(modalActions.tourStart(true))
                        navigate(`/dashboard`);
                    } else {
                        if (previousUrl !== path) {
                            navigate(`${previousUrl}`);
                        }
                        dispatch(GlobalActions.setStepNumberInTour(stepNumber - 1));
                    }
                }}
                icon={LeftIcon}
                btnClass={clsx('h-[48px] w-[48px] py-[12px] px-[12px] rounded-[8px] border-[1px] border-solid border-[#FFF] gap-[0px]')}
                btnClassIcon={'w-[24px] h-[24px] ml-[0px] max-w-[24px]'}
                // btnClassText={clsx(`!text-[16px] !leading-[24px] !h-[24px] font-[600]`)}
            />
            <Button
                text={finalStep ? "Finish" : ""}
                ref={buttonRef}
                onClick={() => {
                    if (finalStep) {
                        navigate(`/dashboard`);
                        dispatch(GlobalActions.setStepNumberInTour(0));
                        dispatch(GlobalActions.setShowTour(false));
                    } else {
                        if (previousUrl !== path) {
                            navigate(`${path}`);
                        }
                        dispatch(GlobalActions.setStepNumberInTour(stepNumber + 1));
                    }
                }}
                icon={finalStep ? undefined : RightIcon}
                btnClass={clsx('h-[48px] py-[12px] px-[12px] bg-[#FFFFFF] text-[#181C30] rounded-[8px] gap-[0px]')}
                btnClassIcon={'w-[24px] h-[24px] ml-[0px]'}
                // btnClassText={clsx(`!text-[16px] !leading-[24px] !h-[24px] font-[600]`)}
            />
        </div>
    </div>
  )
}

export default TooltipTourData