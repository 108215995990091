import classes from "Components/Global/PageIntro.module.scss";
import Button from "Layout/Button";
import sideBarIcon from "assets/Images/SideBarIcon.svg";
import adminAvatar from "assets/Icons/account.svg";
import userIcon from "assets/Icons/userIcon.svg";
import logoutIcon from "assets/Icons/logoutIcon.svg";
import { modalActions } from "Store/modal-redux";
import arrowDown from "assets/Images/arrow_down.svg";
import ArrowDown from "assets/Icons/arrow-down-triangle-white.svg";
import { useDispatch, useSelector } from "react-redux";
import { GlobalActions } from "Store/global-redux";
import clsx from "clsx";
import useClickOutside from "hooks/useClickOutside";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import UseApiCall from "hooks/useApiCall";
import { apiSignOut } from "services";
import { tourArrays } from "constants/Tour";
import { Tooltip } from "antd";
import TooltipTourData from "Components/Tour/TooltipTourData";
import CryptoJS from 'crypto-js';
import { secretKey } from "constants";
import downloadIcon from "assets/Icons/downloadCloud.svg"
import Excel from 'exceljs';
import { saveAs } from 'file-saver';
import dayjs from "dayjs";
import { baseUrl } from "constants/Network";

const PageIntro = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const invoiceActionDisplay = useSelector((state) => state.modal.invoiceActionDisplay);
  const [showOptions, setShowOptions] = useState(false);
  const showTourModal = useSelector((state) => state.global.showTour);
  const stepNumber = useSelector((state) => state.global.stepNumberInTour);
  const optRef = useClickOutside(() => setShowOptions(false));
  const [tourArray, setTourArray] = useState(tourArrays[1]);
  const decryptValue = (encryptedValue) => {
    const bytes = CryptoJS.AES.decrypt(encryptedValue || '', secretKey);
    return bytes.toString(CryptoJS.enc.Utf8);
  };

  const storedUserRole = decryptValue(localStorage.getItem('nickName') || '');

  useEffect(() => {
    if(storedUserRole === 'malik') setTourArray(tourArrays[0])
  }, [])

  const [logOut, loggingOut] = UseApiCall(apiSignOut, () => {
    dispatch({type: 'CLEAR_STATE'})
    navigate("/login?mode=email");
  });

  const HandleProfile = () => {
    navigate("/profile-settings");
    dispatch(GlobalActions.setSelectedOption(null))
  }

  // const handleCreateInvoiceBtn = () => {
  //   if(props.createInvoice === "dashboard"){
  //     dispatch(modalActions.setCreateInvoiceReceivePayment(true))
  //   }else if(props.createInvoice === "receivableAll"){
  //     dispatch(modalActions.setCreateInvoiceReceivePaymentRec(true))
  //   }else if(props.createInvoice === "receivableDraft"){
  //     dispatch(modalActions.setCreateInvoiceReceivePaymentRecDraft(true))
  //   }
  // }
  const plusIconSvg = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <path d="M12 5V19" stroke="#060A0D" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M5 12H19" stroke="#060A0D" stroke-linecap="round" stroke-linejoin="round"/>
</svg>`

  const uploadIconSvg = `<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <path d="M21 15V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V15" stroke="#060A0D" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M17 8L12 3L7 8" stroke="#060A0D" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M12 3V15" stroke="#060A0D" stroke-linecap="round" stroke-linejoin="round"/>
</svg>`

  const editIconSvg = `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<g id="edit">
<path id="Vector" d="M11 4H4C3.46957 4 2.96086 4.21071 2.58579 4.58579C2.21071 4.96086 2 5.46957 2 6V20C2 20.5304 2.21071 21.0391 2.58579 21.4142C2.96086 21.7893 3.46957 22 4 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V13" stroke="#1E333F" stroke-linecap="round" stroke-linejoin="round"/>
<path id="Vector_2" d="M18.5 2.49998C18.8978 2.10216 19.4374 1.87866 20 1.87866C20.5626 1.87866 21.1022 2.10216 21.5 2.49998C21.8978 2.89781 22.1213 3.43737 22.1213 3.99998C22.1213 4.56259 21.8978 5.10216 21.5 5.49998L12 15L8 16L9 12L18.5 2.49998Z" stroke="#1E333F" stroke-linecap="round" stroke-linejoin="round"/>
</g>
</svg>
`

  const plusIcon = `data:image/svg+xml;base64,${btoa(plusIconSvg)}`;

  const uploadIcon = `data:image/svg+xml;base64,${btoa(uploadIconSvg)}`;

  const editIcon = `data:image/svg+xml;base64,${btoa(editIconSvg)}`;


  const options = [
    {
      title: "Profile and Settings",
      icon: userIcon,
      onClick: () => {
        HandleProfile();
      },
    },
    {
      title: "Log Out",
      icon: logoutIcon,
      onClick: () => {
        logOut();
      },
    },
  ];

  const handleCustomerOverviewDownload = async (currency) => {
    const response = await fetch(baseUrl.api + `/download/aging-account`, {
        method: "GET",
        headers: {
            "Content-Type": "application/pdf",
        },
        credentials: "include",
    }).then(async function (t) {
        let filename = t.headers.get('Content-Disposition')?.split(';')?.find(n => n.includes('filename='))?.replace('filename=', '')?.trim();
        const b = await t.blob();
        var a = document.createElement("a");
        a.href = URL.createObjectURL(b);
        if (!filename) {
            filename = "Aging_Account_Report";
            // filename = `${filename?.replace(' ', '_')}_details`
        }
        a.setAttribute("download", filename);
        a.click();
    });
  }
  const exportData = async () => {
    if (props.pageName === "Customers") {
      const workbook = new Excel.Workbook();

      const columns = [
        { header: 'Customer ID', key: 'customer_id' },
        { header: 'Customer Name', key: 'customer_name' },
        { header: 'Customer Country', key: 'customer_country' },
        { header: 'Total Receivables Amount (Currency)', key: 'total_receivables_amount_currency' },
        { header: 'Total Receivables Amount (Value)', key: 'total_receivables_amount' },
        { header: 'Count Of Total Receivables Invoices', key: 'count_of_total_receivables_invoices' },
        { header: 'Settled Amount (Currency)', key: 'settled_amount_currency' },
        { header: 'Total Settled Amount (Value)', key: 'settled_amount' },
        { header: 'Count Of Settled Invoices', key: 'count_of_settled_invoices' },
        { header: 'Settled Outside Fideo Amount (Currency)', key: 'paid_outside_fideo_amount_currency' },
        { header: 'Total Settled Outside Fideo Amount (Value)', key: 'paid_outside_fideo_amount' },
        { header: 'Count Of Settled Outside Fideo Invoices', key: 'count_of_paid_outside_fideo_invoices' },
        { header: 'Pending Amount (Currency)', key: 'pending_amount_currency' },
        { header: 'Total Pending Amount (Value)', key: 'pending_amount' },
        { header: 'Count Of Pending Invoices', key: 'count_of_pending_invoices' },
      ];

      const data = props?.exportDataList?.map(item => ({
        'customer_id': item?.customer_detail?.displayId,
        'customer_name': item?.customer_detail?.customerName,
        'customer_country': item?.customer_detail?.countryName,
        'total_receivables_amount_currency': item?.invoice_summary[0]?.totalReceivables?.currency?.code ? item?.invoice_summary[0]?.totalReceivables?.currency?.code : '--',
        'total_receivables_amount': item?.invoice_summary[0]?.totalReceivables?.amount ? item?.invoice_summary[0]?.totalReceivables?.amount?.toFixed(2) : '--',
        'count_of_total_receivables_invoices': item?.invoice_summary[0]?.totalReceivables?.number_of_invoice ? item?.invoice_summary[0]?.totalReceivables?.number_of_invoice : '--',
        'settled_amount_currency': item?.invoice_summary[0]?.settled?.currency?.code ? item?.invoice_summary[0]?.settled?.currency?.code : '--',
        'settled_amount': item?.invoice_summary[0]?.settled?.amount ? item?.invoice_summary[0]?.settled?.amount?.toFixed(2) : '--',
        'count_of_settled_invoices': item?.invoice_summary[0]?.settled?.number_of_invoice ? item?.invoice_summary[0]?.settled?.number_of_invoice : '--',
        'paid_outside_fideo_amount_currency': item?.invoice_summary[0]?.settledOutsideFideo?.currency?.code ? item?.invoice_summary[0]?.settledOutsideFideo?.currency?.code : '--',
        'paid_outside_fideo_amount': item?.invoice_summary[0]?.settledOutsideFideo?.amount ? item?.invoice_summary[0]?.settledOutsideFideo?.amount?.toFixed(2) : '--',
        'count_of_paid_outside_fideo_invoices': item?.invoice_summary[0]?.settledOutsideFideo?.number_of_invoice ? item?.invoice_summary[0]?.settledOutsideFideo?.number_of_invoice : '--',
        'pending_amount_currency': item?.invoice_summary[0]?.notDue?.currency?.code ? item?.invoice_summary[0]?.notDue?.currency?.code : '--',
        'pending_amount': item?.invoice_summary[0]?.notDue?.amount ? item?.invoice_summary[0]?.notDue?.amount?.toFixed(2) : '--',
        'count_of_pending_invoices': item?.invoice_summary[0]?.notDue?.number_of_invoice ? item?.invoice_summary[0]?.notDue?.number_of_invoice : '--',
      }));

      try {
        const fileName = "CustomersReport";

        const worksheet = workbook.addWorksheet("CustomerTableData");

        worksheet.columns = columns;

        worksheet.getRow(1).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FF434343' },
        };

        worksheet.getColumn(1).font = { bold: true };

        worksheet.getRow(1).font = {
          color: { argb: 'FFFFFFFF' },
          bold: true,
        };

        const columnsToAlignRight = [5, 6, 8, 9, 11, 12, 14, 15];

        columnsToAlignRight.forEach(columnIndex => {
          worksheet.getColumn(columnIndex).alignment = { horizontal: 'right' };
        });

        worksheet.columns.forEach(column => {
          column.width = column.header.length;
          // column.alignment = { horizontal: 'center' };
        });

        data?.forEach(singleData => {
          worksheet.addRow(singleData);
        });

        worksheet.eachRow({ includeEmpty: false }, row => {
          const currentCell = row._cells;

          currentCell.forEach(singleCell => {
            const cellAddress = singleCell._address;

            worksheet.getCell(cellAddress).border = {
              top: { style: 'thin' },
              left: { style: 'thin' },
              bottom: { style: 'thin' },
              right: { style: 'thin' }
            };
          });
        });

        // const worksheet2 = workbook.addWorksheet('Report Details');

        // const storedUsername = localStorage.getItem('username');
        // const date = new Date().toLocaleString()
        // worksheet2.addRow(['Report']);
        // worksheet2.addRow(['Generated By', storedUsername]);
        // worksheet2.addRow(['Generated On', date]);
        
        // worksheet2.addRow([]);
        // worksheet2.addRow(['Key Definitions']);
        // worksheet2.addRow(['Invoice Id', 'An invoice number or invoice ID is a unique number generated by a business issuing an invoice to a client']);
        // worksheet2.addRow(['Issue Date', 'The issue date is a term commonly used in various contexts, such as legal contracts, financial documents, and product manufacturing.']);
        // worksheet2.addRow(['Customer Name', 'A customer name is a name of a person or business that buys goods or services from another business.']);
        // worksheet2.addRow(['Customer Country', 'The term Customer Country means that particular NSPO member state for which NSPA is obtaining the services called for under this contract, or a part thereof']);
        // worksheet2.addRow(['Receivable Amount Currency', 'receivable_amount_currency']);
        // worksheet2.addRow(['Receivable Amount', 'receivable_amount']);
        // worksheet2.addRow(['Converted Receivable Amount Currency', 'converted_receivable_amount_currency']);
        // worksheet2.addRow(['Converted Receivable Amount', 'converted_receivable_amount']);
        // worksheet2.addRow(['Pending Amount Currency', 'pending_amount_currency']);
        // worksheet2.addRow(['Pending Amount', 'pending_amount']);
        // worksheet2.addRow(['Status', 'status']);
        // worksheet2.addRow(['Status Updated On', 'status_updated_on']);
        // worksheet2.addRow(['Estimated Savings Currency', 'estimated_savings_currency']);
        // worksheet2.addRow(['Estimated Savings Amount', 'estimated_savings_amount']);
    
        // worksheet2.getColumn(1).font = { bold: true };
    
        // worksheet2.columns.forEach(column => {
        //   column.alignment = { horizontal: 'center' };
        // });

        const buf = await workbook.xlsx.writeBuffer();

        saveAs(new Blob([buf]), `${fileName}.xlsx`);
      } catch (error) {
        console.error('<<<ERRROR>>>', error);
        console.error('Something Went Wrong', error.message);
      } finally {
        workbook.removeWorksheet('MyWorkBook');
      }
    } else if (props.pageName === "Payouts") {
      const workbook = new Excel.Workbook();

      const columns = [
        { header: 'Initiated On', key: 'initiated_on' },
        { header: 'Payout Reference', key: 'payout_reference' },
        { header: 'Invoice Id', key: 'invoice_id' },
        { header: 'Gross Payout Total (Currency)', key: 'gross_payout_total_currency' },
        { header: 'Gross Payout Breakup (Value)', key: 'gross_payout_breakup' },
        { header: 'Gross Payout Total (Value)', key: 'gross_payout_total_value' },
        { header: 'Settled Amount Total (Currency)', key: 'settled_amount_total_currency' },
        { header: 'Settled Amount Breakup (Value)', key: 'settled_amount_breakup' },
        { header: 'Settled Amount Total (Value)', key: 'settled_amount_total_value' },
        { header: 'Payment Advice', key: 'payment_advice' },
      ];

      // const data = props?.exportDataList?.flatMap(item =>
      //   item?.invoices?.map((subItem, index) => ({
      //     'initiated_on': `${dayjs(item?.initiatedOn).local().format('D MMM YYYY')}`,
      //     'payout_reference': `${item?.payoutReference}`,
      //     'invoice_id': `${subItem?.invoiceId}`,
      //     'gross_payout_total_currency': `${item?.invoiceCurrency?.code}`,
      //     'gross_payout_breakup': `${subItem?.grossAmount?.toFixed(2)}`,
      //     'gross_payout_total_value': `${item?.grossPayout?.toFixed(2)}`,
      //     'settled_amount_total_currency': `${item?.settledAmountCurrency?.code}`,
      //     'settled_amount_breakup': `${subItem?.settledAmount?.toFixed(2)}`,
      //     'settled_amount_total_value': `${item?.settledAmount?.toFixed(2)}`,
      //     'payment_advice': `${item?.firaStatus}`,
      //   }))
      // );

      // const columnsToMerge = ['A', 'B', 'D', 'F', 'G', 'I', 'J'];
      // const mergeRanges = [];

      let data = [];

      props?.exportDataList?.forEach((item, ind) => {
        // const ref = item?.payoutReference;

        // Create a single row for the reference
        // const refRow = { 'payout_reference': `${ref}`, 'invoice_id': '' };
        // const refRow = {
        //   'initiated_on': `${dayjs(item?.initiatedOn).local().format('D MMM YYYY')}`,
        //   'payout_reference': `${item?.payoutReference}`,
        //   'invoice_id': `${item?.invoices[0]?.invoiceId}`,
        //   'gross_payout_total_currency': `${item?.invoiceCurrency?.code}`,
        //   'gross_payout_breakup': `${item?.invoices[0]?.grossAmount?.toFixed(2)}`,
        //   'gross_payout_total_value': `${item?.grossPayout?.toFixed(2)}`,
        //   'settled_amount_total_currency': `${item?.settledAmountCurrency?.code}`,
        //   'settled_amount_breakup': `${item?.invoices[0]?.settledAmount?.toFixed(2)}`,
        //   'settled_amount_total_value': `${item?.settledAmount?.toFixed(2)}`,
        //   'payment_advice': `${item?.firaStatus}`,
        // }
        // data.push(refRow);

        // Create a subrow for each id
        item?.invoices?.forEach((subItem, index) => {
          if (true) {
            // const idRow = { 'payout_reference': '', 'invoice_id': `${subItem?.invoiceId}` };
            const idRow = {
              // 'initiated_on': '',
              // 'payout_reference': '',
              // 'invoice_id': `${subItem?.invoiceId}`,
              // 'gross_payout_total_currency': '',
              // 'gross_payout_breakup': `${subItem?.grossAmount?.toFixed(2)}`,
              // 'gross_payout_total_value': '',
              // 'settled_amount_total_currency': '',
              // 'settled_amount_breakup': `${subItem?.settledAmount?.toFixed(2)}`,
              // 'settled_amount_total_value': '',
              // 'payment_advice': '',
              'initiated_on': `${dayjs(item?.initiatedOn).local().format('D MMM YYYY')}`,
              'payout_reference': `${item?.payoutReference}`,
              'invoice_id': `${subItem?.invoiceId}`,
              'gross_payout_total_currency': `${item?.invoiceCurrency?.code}`,
              'gross_payout_breakup': `${subItem?.grossAmount?.toFixed(2)}`,
              'gross_payout_total_value': `${item?.grossPayout?.toFixed(2)}`,
              'settled_amount_total_currency': `${item?.settledAmountCurrency?.code}`,
              'settled_amount_breakup': `${subItem?.settledAmount?.toFixed(2)}`,
              'settled_amount_total_value': `${item?.settledAmount?.toFixed(2)}`,
              'payment_advice': `${item?.firaStatus}`,
            }
            // columnsToMerge.forEach(column => {
            //   mergeRanges.push({ row: `${column}${ind + 2}:${column}${ind + 3}` });
            // });
            data.push(idRow);
          }
        });
      });
  
      try {
        const fileName = "PayoutsReport";
  
        const worksheet = workbook.addWorksheet("PayoutsTableData");
  
        worksheet.columns = columns;
  
        worksheet.getRow(1).fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FF434343' },
        };
  
        worksheet.getColumn(1).font = { bold: true };
  
        worksheet.getRow(1).font = {
          color: { argb: 'FFFFFFFF' },
          bold: true,
        };
  
        const columnsToAlignRight = [5, 6, 8, 9];
  
        columnsToAlignRight.forEach(columnIndex => {
          worksheet.getColumn(columnIndex).alignment = { horizontal: 'right' };
        });

        worksheet.getRow(1).alignment = { horizontal: 'left' };
  
        worksheet.columns.forEach(column => {
          column.width = column.header.length;
          // column.alignment = { horizontal: 'center' };
        });
  
        data?.forEach(singleData => {
          worksheet.addRow(singleData);
        });

        // mergeRanges.forEach(({ row }) => {
        //   worksheet.mergeCells(row);
        // });
  
        worksheet.eachRow({ includeEmpty: false }, row => {
          const currentCell = row._cells;
  
          currentCell.forEach(singleCell => {
            const cellAddress = singleCell._address;
  
            worksheet.getCell(cellAddress).border = {
              top: { style: 'thin' },
              left: { style: 'thin' },
              bottom: { style: 'thin' },
              right: { style: 'thin' }
            };
          });
        });
  
        const buf = await workbook.xlsx.writeBuffer();
  
        saveAs(new Blob([buf]), `${fileName}.xlsx`);
      } catch (error) {
        console.error('<<<ERRROR>>>', error);
        console.error('Something Went Wrong', error.message);
      } finally {
        workbook.removeWorksheet('MyWorkBook');
      }
    }
    else if (props.pageName === "Reports") {
      handleCustomerOverviewDownload()
    }
  }

  useEffect(() => {
      dispatch(modalActions.invoiceActionDisplay(false))
  }, []);

  return (
    <div ref={optRef} className={`${classes.page_intro} ${props.className} w-[100%]`} 
    // style={{marginRight : "42px"}}
    >
      <img
        src={sideBarIcon}
        alt="side-bar-icon"
        className={classes.side_bar}
        onClick={() => dispatch(GlobalActions.setShowSideBarMobile())}
      />
     { 
        (showTourModal && props?.tourId === tourArray[stepNumber]?.id && tourArray[stepNumber]?.id)
            ?
              <Tooltip
                title={
                  <TooltipTourData
                    heading={tourArray[stepNumber]?.heading}
                    body1={tourArray[stepNumber]?.body1}
                    body2={tourArray[stepNumber]?.body2}
                    path={tourArray[stepNumber]?.pageUrl}
                    previousUrl={tourArray[stepNumber > 0 ? stepNumber - 1 : stepNumber]?.previousUrl}
                    finalStep={tourArray[stepNumber]?.finalStep}
                    firstStep={tourArray[stepNumber]?.firstStep}
                  />
                }
                id="customTooltip"
                placement={tourArray[stepNumber]?.placement}
                // defaultOpen={tourArray[stepNumber]?.defaultOpen}
                open={showTourModal}
                overlayInnerStyle={{
                  // color: "orange",
                  background: "#060A0D",
                  width: "400px",
                  padding: "0px",
                  borderRadius: "16px",
                  ...tourArray[stepNumber]?.styles
                }}
              >
                <div className={clsx(classes.pageInfo,'bg-[#FFF] rounded-[12px] p-[12px] border border-solid border-[4px] border-[#FFF] z-[400]')}>
                  <div className={classes.page_name}>{props.pageName}</div>
                  <div className={classes.message}>{props.message}</div>
                </div>
              </Tooltip>
              :
              <div className={classes.pageInfo}>
                <div className={classes.page_name}>{props.pageName}</div>
                <div className={classes.message}>{props.message}</div>
              </div>
      }
      <div className={clsx(classes.button, '-md:flex-col gap-5')}>
        { props.arrBtn?.map((item, index) => (
          (showTourModal && item?.tourId === tourArray[stepNumber]?.id && tourArray[stepNumber]?.id)
            ?
              <Tooltip
                title={
                  <TooltipTourData
                    heading={tourArray[stepNumber]?.heading}
                    body1={tourArray[stepNumber]?.body1}
                    body2={tourArray[stepNumber]?.body2}
                    path={tourArray[stepNumber]?.pageUrl}
                    previousUrl={tourArray[stepNumber > 0 ? stepNumber - 1 : stepNumber]?.previousUrl}
                    finalStep={tourArray[stepNumber]?.finalStep}
                    firstStep={tourArray[stepNumber]?.firstStep}
                  />
                }
                id="customTooltip"
                placement={tourArray[stepNumber]?.placement}
                // defaultOpen={tourArray[stepNumber]?.defaultOpen}
                open={showTourModal}
                overlayInnerStyle={{
                  // color: "orange",
                  background: "#060A0D",
                  width: "400px",
                  padding: "0px",
                  borderRadius: "16px",
                  ...tourArray[stepNumber]?.styles
                }}
              >
                <Button
                  key={`${item.text}-${index}`}
                  text={item.text}
                  icon={item.icon}
                  // secondicon={item.secondicon}
                  // style={{
                  //   zIndex: "400"
                  // }}
                  disabled={!item.active}
                  onClick={() => {if (!showTourModal) item.onClick()}}
                  className={clsx(classes.btn, (item.text === 'Upload Invoices')  && `bg-[#FFF] border border-solid border-[1px] border-[#060a0d1f]`, 'border border-solid border-[4px] border-[#FFF] z-[400]')}
                  btnClass={classes.btnClass}
                  btnClassIcon={clsx(
                    classes.btnClass_icon,
                    !item.active && 'opacity-50'
                  )}
                  btnClassText={clsx(
                    classes.btnClass_text,
                    !item.active && 'opacity-50',
                    (item.text === 'Upload Invoices')  && `text-[#000]`,
                  )}
                />
              </Tooltip>
            :
              <Button
                key={`${item.text}-${index}`}
                text={item.text}
                icon={item.icon}
                // secondicon={item.secondicon}
                disabled={!item.active}
                onClick={() => item.onClick()}
                className={clsx(classes.btn, (item.text === 'Upload Invoices')  && `bg-[#FFF] border border-solid border-[1px] border-[#060a0d1f]`)}
                btnClass={classes.btnClass}
                btnClassIcon={clsx(
                  classes.btnClass_icon,
                  !item.active && 'opacity-50'
                )}
                btnClassText={clsx(
                  classes.btnClass_text,
                  !item.active && 'opacity-50',
                  (item.text === 'Upload Invoices')  && `text-[#000]`,
                )}
              />
          ))
        }

        { props.dontShowBtn === true  &&
            <button ref={props.optRef}
              onClick={() => {
                if(invoiceActionDisplay){
                dispatch(modalActions.invoiceActionDisplay(false))
                }
                else{
                dispatch(modalActions.invoiceActionDisplay(true))
                }
              }}
              className={clsx(("step3" === tourArray[stepNumber]?.id || "step4" === tourArray[stepNumber]?.id) && 'rounded-[8px] border border-solid border-[4px] border-[#FFF] z-[400]','flex items-stretch relative border ')}
              >
                <div className=""></div>
                <div className={clsx(classes.btnLeftPart, 'flex items-center rounded-s-2xl')}>
                  <img
                      src={arrowDown}
                      alt={`ArrowLeft-icon`}
                      className='h-8 w-8'
                  />
                  <div className={classes.btnFontText} style={{ color: true ? "gray" : "white", fontSize: "1.6rem", lineHeight: "2.4rem" }}>Receive Payment</div>
                </div>
                <div className={clsx(classes.btnRightPart, 'flex items-center rounded-e-2xl')}>
                  <img
                      src={ArrowDown}
                      alt={`ArrowLeft-icon`}
                      className='h-[2.4rem] w-8'
                  />
                </div>
                {
                (invoiceActionDisplay || "step3" === tourArray[stepNumber]?.id || "step4" === tourArray[stepNumber]?.id) &&
                    <div className={clsx(`${classes.receiveDropDown} absolute top-[53px] right-[-5px] bg-[#FFF]`)}>
                      {(showTourModal && "step4" === tourArray[stepNumber]?.id && tourArray[stepNumber]?.id)
                          ?
                          <Tooltip
                              title={
                                <TooltipTourData
                                    heading={tourArray[stepNumber]?.heading}
                                    body1={tourArray[stepNumber]?.body1}
                                    body2={tourArray[stepNumber]?.body2}
                                    path={tourArray[stepNumber]?.pageUrl}
                                    previousUrl={tourArray[stepNumber > 0 ? stepNumber - 1 : stepNumber]?.previousUrl}
                                    finalStep={tourArray[stepNumber]?.finalStep}
                                    firstStep={tourArray[stepNumber]?.firstStep}
                                />
                              }
                              id="customTooltip"
                              placement={tourArray[stepNumber]?.placement}
                              // defaultOpen={tourArray[stepNumber]?.defaultOpen}
                              open={showTourModal}
                              overlayInnerStyle={{
                                // color: "orange",
                                background: "#060A0D",
                                width: "400px",
                                padding: "0px",
                                borderRadius: "16px",
                                ...tourArray[stepNumber]?.styles
                              }}
                          >
                            <div
                                className={clsx(classes.dropOption, 'border-b-[1px] border-[#ECEFF2]')}
                                 onClick={() => dispatch(modalActions.receivePayment())}
                            >
                              <img src={plusIcon} className={classes.dropOptionImg}/>
                              Add Single Invoice
                            </div>
                          </Tooltip>
                          :
                          <div className={clsx(classes.dropOption, 'border-b-[1px] border-[#ECEFF2]')}
                               onClick={() => dispatch(modalActions.receivePayment())}>
                            <img src={plusIcon} className={classes.dropOptionImg}/>
                            Add Single Invoice
                          </div>}


                      {(showTourModal && "step3" === tourArray[stepNumber]?.id && tourArray[stepNumber]?.id)
                          ?
                          <Tooltip
                              title={
                                <TooltipTourData
                                    heading={tourArray[stepNumber]?.heading}
                                    body1={tourArray[stepNumber]?.body1}
                                    body2={tourArray[stepNumber]?.body2}
                                    path={tourArray[stepNumber]?.pageUrl}
                                    previousUrl={tourArray[stepNumber > 0 ? stepNumber - 1 : stepNumber]?.previousUrl}
                                    finalStep={tourArray[stepNumber]?.finalStep}
                                    firstStep={tourArray[stepNumber]?.firstStep}
                                />
                              }
                              id="customTooltip"
                              placement={tourArray[stepNumber]?.placement}
                              // defaultOpen={tourArray[stepNumber]?.defaultOpen}
                              open={showTourModal}
                              overlayInnerStyle={{
                                // color: "orange",
                                background: "#060A0D",
                                width: "400px",
                                padding: "0px",
                                borderRadius: "16px",
                                ...tourArray[stepNumber]?.styles
                              }}
                          >
                            <div className={clsx(classes.dropOption, 'border-b-[1px] border-[#ECEFF2]')}
                                 onClick={() => dispatch(modalActions.bulkUploaderDisplay(true))}>
                              <img src={uploadIcon} className={classes.dropOptionImg}/>
                              Upload Multiple Invoices
                            </div>
                          </Tooltip>
                          :
                          <div className={clsx(classes.dropOption, 'border-b-[1px] border-[#ECEFF2]')}
                               onClick={() => dispatch(modalActions.bulkUploaderDisplay(true))}>
                            <img src={uploadIcon} className={classes.dropOptionImg}/>
                            Upload Multiple Invoices
                          </div>}


                      {(showTourModal && "step7" === tourArray[stepNumber]?.id && tourArray[stepNumber]?.id)
                          ?
                          <Tooltip
                              title={
                                <TooltipTourData
                                    heading={tourArray[stepNumber]?.heading}
                                    body1={tourArray[stepNumber]?.body1}
                                    body2={tourArray[stepNumber]?.body2}
                                    path={tourArray[stepNumber]?.pageUrl}
                                    previousUrl={tourArray[stepNumber > 0 ? stepNumber - 1 : stepNumber]?.previousUrl}
                                    finalStep={tourArray[stepNumber]?.finalStep}
                                    firstStep={tourArray[stepNumber]?.firstStep}
                                />
                              }
                              id="customTooltip"
                              placement={tourArray[stepNumber]?.placement}
                              // defaultOpen={tourArray[stepNumber]?.defaultOpen}
                              open={showTourModal}
                              overlayInnerStyle={{
                                // color: "orange",
                                background: "#060A0D",
                                width: "400px",
                                padding: "0px",
                                borderRadius: "16px",
                                ...tourArray[stepNumber]?.styles
                              }}
                          >
                            <div className={clsx(classes.dropOption)}
                                 onClick={() => dispatch(modalActions.setCreateInvoiceReceivePayment(true))}>
                              <img src={editIcon} className={classes.dropOptionImg}/>
                              Create Invoice
                            </div>
                          </Tooltip>
                          :
                          <div className={clsx(classes.dropOption)}
                               onClick={() => dispatch(modalActions.setCreateInvoiceReceivePayment(true))}>
                            <img src={editIcon} className={classes.dropOptionImg}/>
                            Create Invoice
                          </div>}


                      {/*  // <div className={clsx(classes.dropOption, 'border-b-[1px] border-[#ECEFF2]')}*/}
                      {/*  //      onClick={() => dispatch(modalActions.receivePayment())}>*/}
                      {/*  //   <img src={plusIcon} className={classes.dropOptionImg}/>*/}
                      {/*    //   Add Single Invoice*/}
                      {/*    // </div>*/}


                      {/*/!*<div className={clsx(classes.dropOption, 'border-b-[1px] border-[#ECEFF2]')}*!/*/}
                      {/*/!*     onClick={() => dispatch(modalActions.bulkUploaderDisplay(true))}>*!/*/}
                      {/*/!*  <img src={uploadIcon} className={classes.dropOptionImg}/>*!/*/}
                      {/*/!*  Upload Multiple Invoices*!/*/}
                      {/*/!*</div>*!/*/}
                      {/*<div className={clsx(classes.dropOption)}*/}
                      {/*     onClick={() => dispatch(modalActions.setCreateInvoiceReceivePayment(true))}>*/}
                        {/*// onClick={handleCreateInvoiceBtn}>*/}
                      {/*  <img src={editIcon} className={classes.dropOptionImg}/>*/}
                      {/*  Create Invoice*/}
                      {/*</div>*/}
                    </div>
                }
            </button>
        }
        {((props.pageName === "Customers" || props.pageName === "Payouts" || props.pageName === "Reports") && props.downloadBtn) &&
            <div className={classes.right}>
              <Button
                  text={''}
                  icon={downloadIcon}
                  disabled={props.loadingDownloadBtn}
                  onClick={() => exportData()}
                  className={clsx(classes.downloadBtn, props.loadingDownloadBtn ? 'cursor-default' : 'cursor-pointer')}
                  btnClassIcon={clsx(
                      classes.downloadBtnImg,
                      props.loadingDownloadBtn && 'opacity-50'
                  )}
              />
            </div>
        }
      </div>
      <div
          className={clsx(classes.profile, "relative")}
          onClick={() => setShowOptions((pre) => !pre)}
      >
        <img src={adminAvatar} alt="admin-img" className={classes.userImg} />
        {showOptions && (<div
          
          className={clsx(classes.profileContainer,
            "absolute top-20 right-0  z-10",
            showOptions ? "block" : "hidden"
          )}
        >
          <div className={clsx(classes.profileWrapper)}>
            {/* <div className={clsx(classes.profileBox)}>
              <img src={adminAvatar} alt="admin-img" className={classes.profileImg} />
              <div className={clsx(classes.profileTextBox)}>
                <span className={clsx(classes.profileName)}>Vikram Kumar</span>
                <span className={clsx(classes.profileEmail)}>vikram.kumar@fideo.tech</span>
              </div>
            </div> */}
          </div>
          <ul
            className={clsx(classes.listBox)}
            aria-labelledby="dropdownDefaultButton"
          >
            {options?.map((option, index) => (
              <li onClick={option.onClick} key={index} className={clsx(classes.list)}>
                <img src={option.icon} alt="admin-img" className={clsx(classes.listImg)} />
                <p className={clsx(classes.listText)}>
                  {option.title}
                </p>
              </li>
            ))}
          </ul>
        </div>)}
        {/* <div
          className={clsx(
            'absolute top-32 right-[12px] min-w-[200px] z-10 bg-bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700',
            showOptions ? 'block' : 'hidden'
          )}
        >
          <ul
            className="py-2 w-full text-sm text-gray-700 dark:text-gray-200"
            aria-labelledby="dropdownDefaultButton"
          >
            {options?.map((option, index) => (
              <li onClick={option.onClick} key={index}>
                <p className="block cursor-pointer w-full text-center px-8 py-4 text-2xl hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                  {option.title}
                </p>
              </li>
            ))}
          </ul>
        </div> */}
      </div>
    </div>
  )
};

export default PageIntro;
