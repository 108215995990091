import FileIcon from 'assets/Images/verification-file-white-yellow.svg'
import Button from 'Layout/Button'
import InfoIcon from 'assets/Icons/info_brown_circle.svg'
import {modalActions} from "../../../Store/modal-redux";
import {useDispatch} from "react-redux";

const InvoiceVericationPrompt = (props) => {
    const dispatch = useDispatch()
  const { invocieId,whereCalled } = props
  const handleClick = () => {
      if(whereCalled === "createInvoice"){
          dispatch(modalActions.setCreateInvoiceModal(false));
          dispatch(modalActions.setShowDraftEditInvoice(false))
          dispatch(modalActions.setCreateInvoiceReceivePayment(false));
      }
    if (props.closeModal) props.closeModal()
  }
  return (
    <div className="w-[90vw] max-w-[500px] p-10 h-auto flex flex-col items-center justify-center z-50">
      <div className="flex flex-col items-center">
        <div className="w-[88px] h-[88px] flex items-center justify-center rounded-full bg-[#DBFD00]">
          <img src={FileIcon} alt="User" className="w-20 h-20" />
        </div>
        <div className="relative flex flex-row items-center  text-[12px] text-[#8E6C28] -top-4 px-[12px] py-1 bg-[#FFF4DE] rounded-full ">
          <img src={InfoIcon} alt="i" className="w-[28px] aspect-square" />
          <p>UNDER REVIEW</p>
        </div>
      </div>
      <p className="text-[#1E333F] text-center mt-2 font-bold text-[18px]">
        {invocieId ? `Invoice ID ${invocieId}` : ''}
      </p>
      <div className="p-10 border flex flex-col mt-8 mb-10 relative border-[#E9EBEC] rounded-xl">
        <p className="text-[16px] font-semibold text-[#1E333F] mb-2">
          What’s next?
        </p>
        <p className="text-[16px] font-normal text-[#181C30]">
          Once invoice is verified, you can then send it to your customers and
          track payment status. It will take up to 1 hour for verification and
          Fideo team may make some edits if required.
        </p>
        <div className="p-10 flex flex-col mt-6 bg-[#FFF4DE] rounded-xl">
          <p className="text-[14px] font-normal text-[#8E6C28]">
            FIDEO will do a compliance check and verifies invoice information
            basis regulatory requirements to ensure payment is not stuck or
            rejected.
          </p>
        </div>
      </div>
      <Button
        className="w-full"
        text="Okay"
        disabled={false}
        onClick={handleClick}
      />
    </div>
  )
}
export default InvoiceVericationPrompt
