// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ChatBot_root__J\\+62h {
  flex-grow: 0;
}

.ChatBot_iframe__8Zt3H {
  position: fixed;
  z-index: 999;
  bottom: 20px;
  right: 4.4rem;
}

.ChatBot_button__lWniT {
  display: flex; /* Use flexbox */
  justify-content: center; /* Center content horizontally */
  align-items: center; /* Center content vertically */
  border: none;
  cursor: pointer;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  background: linear-gradient(130deg, #E0EDFF 0%, #DBFFF2 95.89%);
}

.ChatBot_icon__L\\+ups {
  width: 30px; /* Adjust icon size as needed */
  height: 30px; /* Adjust icon size as needed */
}`, "",{"version":3,"sources":["webpack://./src/Components/NewBot/ChatBot.module.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;AACJ;;AAEA;EACI,eAAA;EACA,YAAA;EACA,YAAA;EACA,aAAA;AACJ;;AAEA;EACI,aAAA,EAAA,gBAAA;EACA,uBAAA,EAAA,gCAAA;EACA,mBAAA,EAAA,8BAAA;EACA,YAAA;EACA,eAAA;EAEA,kBAAA;EACA,YAAA;EACA,WAAA;EACA,+DAAA;AAAJ;;AAGA;EAEI,WAAA,EAAA,+BAAA;EACA,YAAA,EAAA,+BAAA;AADJ","sourcesContent":[".root {\n    flex-grow: 0;\n}  \n\n.iframe {\n    position: fixed;\n    z-index: 999;\n    bottom: 20px;\n    right: 4.4rem;\n}\n\n.button {\n    display: flex; /* Use flexbox */\n    justify-content: center; /* Center content horizontally */\n    align-items: center; /* Center content vertically */\n    border: none;\n    cursor: pointer;\n    // background-color: #9dfff6bd;\n    border-radius: 50%;\n    height: 50px;\n    width: 50px;\n    background: linear-gradient(130deg, #E0EDFF 0%, #DBFFF2 95.89%);\n}\n\n.icon {\n    // display: block;\n    width: 30px; /* Adjust icon size as needed */\n    height: 30px; /* Adjust icon size as needed */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `ChatBot_root__J+62h`,
	"iframe": `ChatBot_iframe__8Zt3H`,
	"button": `ChatBot_button__lWniT`,
	"icon": `ChatBot_icon__L+ups`
};
export default ___CSS_LOADER_EXPORT___;
