import React, { useEffect, useState } from 'react';
import classes from './ReportPageFilter.module.scss';
import down from 'assets/Icons/chevron-down.svg';
import up from 'assets/Icons/chevron-up.svg';
import circleBlack from 'assets/Icons/x-circleBlack.svg';
import circleWhite from 'assets/Icons/x-circleWhite.svg';
import dotBlack from 'assets/Icons/dotBlack.png';
import defaultSort from 'assets/Icons/defaultSort.svg'
import descSort from 'assets/Icons/descSort.svg'
import ascSort from 'assets/Icons/ascSort.svg'
import rotate from "assets/Icons/rotate-cw.svg";
import check from "assets/Icons/check.svg";
import filter from "assets/Icons/filters.svg";
import sort from "assets/Icons/sort.svg";
import cross from "assets/Icons/crossImg.svg";
import useClickOutside from 'hooks/useClickOutside';

const MobilePageFilter = (props) => {

    const { 
        mobileFilterOpen,
        setMobileFilterOpen,
        mobileSortOpen,
        setMobileSortOpen,
        handleAllCustomersToggle,
        allSelected,
        customers,
        toggleCustomerSelection,
        statuses,
        selectedCustomers,
        handleAllStatusToggle,
        allStatusSelected,
        toggleStatusSelection,
        selectedStatuses,
        apiStatusToDisplayStatus,
        handleDateSelection,
        activeDate,
        tableData,
        customerCounter,
        statusCounter,
        toggleAllStatesSelection,
        showDateCounter,
        setSortState,
        sortState
    } = props;

    const [openDate, setOpenDate] = useState(false);
    const [openStatus, setOpenStatus] = useState(false);
    const [openCustomer, setOpenCustomer] = useState(false);
    const [isResetAllTrue, setIsResetAllTrue] = useState(false);

    const [openSortCreatedOn, setOpenSortCreatedOn] = useState(false);
    const [openSortInvoiceId, setOpenSortInvoiceId] = useState(false);
    const [openSortCustomer, setOpenSortCustomer] = useState(false);
    const [openSortAmount, setOpenSortAmount] = useState(false);
    const [openSortStatus, setOpenSortStatus] = useState(false);

    const [issueDateState,setIssueDateState] = useState('default');
    const [customerDetailsState,setCustomerDetailsState] = useState('default');
    const [amountState,setAmountState] = useState('default');
    const [headerStatusState,setHeaderStatusState] = useState('default');
    const [invoiceIdState,setInvoiceIdState] = useState('default');

    const [isSortApply,setIsSortApply] = useState(false);

    const resetAll = () =>{
        setSortState({sortField:"",order:""})
        setIsSortApply(false)
        setInvoiceIdState('default')
        setIssueDateState('default')
        setCustomerDetailsState('default')
        setAmountState('default')
        setHeaderStatusState('default')
      }
    

    // const invoiceDateDropdownRef = useClickOutside(() => setOpenDate(false));
    // const statusDropdownRef = useClickOutside(() => setOpenStatus(false));
    // const customerDropdownRef = useClickOutside(() => setOpenCustomer(false));

    const handleOpenCustomer = () => {
        setOpenCustomer(!openCustomer);
        setOpenStatus(false); 
        setOpenDate(false); 
      };

    const handleOpenStatus = () => {
        setOpenStatus(!openStatus);
        setOpenCustomer(false); 
        setOpenDate(false); 
    };

    const handleOpenDate = () => {
        setOpenDate(!openDate);
        setOpenCustomer(false); 
        setOpenStatus(false); 
    };

    const handleMobileFilterOpen = () => {
        setMobileFilterOpen(!mobileFilterOpen)
        if(isResetAllTrue){
            setIsResetAllTrue(false)
          }
    }

    const handleOpenSortCreatedOn = () => {
        setOpenSortCreatedOn(!openSortCreatedOn)
        setOpenSortInvoiceId(false)
        setOpenSortCustomer(false)
        setOpenSortAmount(false)
        setOpenSortStatus(false)
    }
    const handleOpenSortInvoiceId = () => {
        setOpenSortCreatedOn(false)
        setOpenSortInvoiceId(!openSortInvoiceId)
        setOpenSortCustomer(false)
        setOpenSortAmount(false)
        setOpenSortStatus(false)
    }
    const handleOpenSortCustomer = () => {
        setOpenSortCreatedOn(false)
        setOpenSortInvoiceId(false)
        setOpenSortCustomer(!openSortCustomer)
        setOpenSortAmount(false)
        setOpenSortStatus(false)
    }
    const handleOpenSortAmount = () => {
        setOpenSortCreatedOn(false)
        setOpenSortInvoiceId(false)
        setOpenSortCustomer(false)
        setOpenSortAmount(!openSortAmount)
        setOpenSortStatus(false)
    }
    const handleOpenSortStatus = () => {
        setOpenSortCreatedOn(false)
        setOpenSortInvoiceId(false)
        setOpenSortCustomer(false)
        setOpenSortAmount(false)
        setOpenSortStatus(!openSortStatus)
    }

    const handleMobileSortOpen = () => {
        setMobileSortOpen(!mobileSortOpen)
    }


    const handleResetAll = () => {
        toggleAllStatesSelection('resetAll');
        setIsResetAllTrue(true)
    }

    const handleSortClickMobile = ({order , field}) =>{
        // console.log('field',field)
        // console.log('order',order)
        // setCurrentPage(1)
    
        if(field === "invoiceId" ){
            // console.log('setIsSortApply')
            
          if(order === "default"){
            resetAll()
            setIsSortApply(false)
            setInvoiceIdState(order)
            setSortState({sortField:"",order:""})
          }
          else if(order === "ascending"){
            resetAll()
            setIsSortApply(true)
            setInvoiceIdState(order)
            setSortState({sortField:field,order:"ASC"})
          }
          else if(order === "descending"){
            resetAll()
            setIsSortApply(true)
            setInvoiceIdState(order)
            setSortState({sortField:field,order:"DESC"})
          }
        }else if(field === "issueDate"){
          if(order === "default"){
            resetAll()
            setIsSortApply(false)
            setIssueDateState(order)
            setSortState({sortField:"",order:""})
          }
          else if(order === "ascending"){
            resetAll()
            setIsSortApply(true)
            setIssueDateState(order)
            setSortState({sortField:field,order:"ASC"})
          }
          else if(order === "descending"){
            resetAll()
            setIsSortApply(true)
            setIssueDateState(order)
            setSortState({sortField:field,order:"DESC"})
          }
        }else if(field === "customerBaseEntity.customerDetails.customerName"){
            
          if(order === "default"){
            resetAll()
            setIsSortApply(false)
            setCustomerDetailsState(order)
            setSortState({sortField:"",order:""})
          }
          else if(order === "ascending"){
            resetAll()
            setIsSortApply(true)
            setCustomerDetailsState(order)
            setSortState({sortField:field,order:"ASC"})
          }
          else if(order === "descending"){
            resetAll()
            setIsSortApply(true)
            setCustomerDetailsState(order)
            setSortState({sortField:field,order:"DESC"})
          }
        }else if(field === "amount"){
            
          if(order === "default"){
            resetAll()
            setIsSortApply(false)
            setAmountState(order)
            setSortState({sortField:"",order:""})
          }
          else if(order === "ascending"){
            resetAll()
            setIsSortApply(true)
            setAmountState(order)
            setSortState({sortField:field,order:"ASC"})
          }
          else if(order === "descending"){
            resetAll()
            setIsSortApply(true)
            setAmountState(order)
            setSortState({sortField:field,order:"DESC"})
          }
        }else if(field === "invoiceOrder"){
            
          if(order === "default"){
            resetAll()
            setIsSortApply(false)
            setHeaderStatusState(order)
            setSortState({sortField:"",order:""})
          }
          else if(order === "ascending"){
            resetAll()
            setIsSortApply(true)
            setHeaderStatusState(order)
            setSortState({sortField:field,order:"ASC"})
          }
          else if(order === "descending"){
            resetAll()
            setIsSortApply(true)
            setHeaderStatusState(order)
            setSortState({sortField:field,order:"DESC"})
          }
        }
    }

    // console.log('isResetAllTrue',isResetAllTrue)
    // console.log('tableHeader',tableHeader)
    // console.log('invoiceIdState',invoiceIdState)
    // console.log('issueDateState',issueDateState)
    // console.log('customerDetailsState',customerDetailsState)
    // console.log('isSortAppy',isSortApply)

  return (
    <div className={classes.mobileFilterPage}>
            <span className={classes.receivableCountText}>{`${tableData?.length}`} Receivables</span>
            <div className={classes.filtersortBox}>
                <div className={classes.filterBox}  onClick={handleMobileFilterOpen}>
                        <img src={filter} alt='filter'  style={{width : '24px' , height : '24px'}}/>
                        {((customerCounter >= 1 && customerCounter !== customers?.length) || (statusCounter >= 1 && statusCounter !== statuses?.length) || showDateCounter) &&
                        (<div className={classes.count}>
                            <span className={classes.countText}>{((customerCounter >= 1 && customerCounter !== customers?.length) ? customerCounter : 0) + ((statusCounter >= 1 && statusCounter !== statuses?.length) ? statusCounter : 0) + (showDateCounter ? 1 : 0)}</span>
                        </div>)}
                </div>
                {mobileFilterOpen &&
                    (<div className={classes.filterMobileViewContainer}>
                        <div className={classes.filterMobileViewWrapper}>
                            <div className={classes.filterMobileViewBox}>
                                <div className={classes.filterMobileTopCon}>
                                    <div className={classes.filterMobileTopWrap} onClick={handleMobileFilterOpen}>
                                        <span className={classes.filterText}>Filters</span>
                                        <img src={cross} alt='filter'  className={classes.filterCrossImg} />
                                    </div>
                                </div>
                                <div className={classes.filterMobileBottom}>
                                    <div className={classes.filterMobileCustomerCon} >
                                        <div className={classes.filterMobileCustomerWrap} >
                                            <div className={classes.filterMobileCustomer} onClick={handleOpenCustomer}>
                                                <div className={classes.counter}>
                                                    <span className={classes.filterTypeText}>Cutomers</span>
                                                    {customerCounter >= 1 && customerCounter !== customers?.length &&
                                                    (<div className={classes.count}>
                                                        <span className={classes.countText}>{customerCounter}</span>
                                                    </div>)}
                                                </div>
                                                <img src={openCustomer ? up : down} className={classes.arrowImg} alt="dropdown"/>
                                            </div>
                                            {openCustomer && (
                                                <div className={classes.filterMobileCustomerDropdown}>
                                                        <div className={classes.dropdownListCustomerMobile}
                                                            style={{borderBottom : '1px solid #ECEFF2'}}
                                                            onClick={(e) => handleAllCustomersToggle(allSelected)}>
                                                            <input type='checkbox' className={classes.dropdownCheckboxMobile}
                                                                checked={allSelected}
                                                            />
                                                            <span className={classes.dropdownTextMobile}>All Customers</span>
                                                        </div>
                                                        {customers && customers?.map(customer => (
                                                            <div className={classes.dropdownListCustomerMobile} style={{}} key={customer?.id}
                                                                onClick={() => toggleCustomerSelection(statuses, customer.id, customers?.length)}
                                                            >
                                                                <input type='checkbox' className={classes.dropdownCheckboxMobile} 
                                                                        checked={selectedCustomers[customer.id] || allSelected}
                                                                        // onChange={() => {}}
                                                                />
                                                                <span className={classes.dropdownTextMobile}
                                                                    // onClick={() => {}}
                                                                >
                                                                {customer.customerName.length > 30
                                                                    ? `${customer.customerName.slice(0, 30)}...` 
                                                                    : customer.customerName
                                                                }
                                                                </span>
                                                            </div>
                                                        ))}
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    <div className={classes.filterMobileStatusCon} >
                                        <div className={classes.filterMobileStatusWrap} >
                                            <div className={classes.filterMobileStatus} onClick={handleOpenStatus}>
                                                <div className={classes.counter}>
                                                    <span className={classes.filterTypeText}>Status</span>
                                                    {statusCounter >= 1 && statusCounter !== statuses?.length &&
                                                    (<div className={classes.count}>
                                                        <span className={classes.countText}>{statusCounter}</span>
                                                    </div>)}
                                                </div>
                                                <img src={openStatus ? up : down} alt='filter'  className={classes.arrowImg}/>
                                            </div>
                                                {openStatus && (
                                                    <div className={`${classes.filterMobileCustomerDropdown}`}>
                                                        <div className={`${classes.dropdownListCustomerMobile}`}
                                                            style={{borderBottom : '1px solid #ECEFF2'}}
                                                            onClick={(e) => handleAllStatusToggle(allStatusSelected)}>
                                                            <input type='checkbox' className={classes.dropdownCheckboxMobile}
                                                                checked={allStatusSelected}
                                                            />
                                                            <span className={classes.dropdownTextMobile}>All Statuses</span>
                                                        </div>
                                                        <div>
                                                            {statuses?.map(status => (
                                                                <div className={`${classes.dropdownListCustomerMobile}`} key={status}
                                                                onClick={() => toggleStatusSelection(status, statuses?.length)}>
                                                                    <input type='checkbox' className={classes.dropdownCheckboxMobile} 
                                                                        checked={selectedStatuses[status] || allStatusSelected}
                                                                        // onChange={() => toggleStatusSelection(status, statuses?.length)}
                                                                    />
                                                                    <span className={classes.dropdownTextMobile}
                                                                    >
                                                                        {apiStatusToDisplayStatus[status]?.length > 20 
                                                                        ? `${apiStatusToDisplayStatus[status].slice(0, 20)}...` 
                                                                        : apiStatusToDisplayStatus[status]
                                                                    }
                                                                    </span>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                )}
                                        </div>
                                    </div>

                                    <div className={classes.filterMobileInvoiceDateCon} >
                                        <div className={classes.filterMobileInvoiceDateWrap} >
                                            <div className={classes.filterMobileInvoiceDate} onClick={handleOpenDate} >
                                                <div className={classes.counter}>
                                                    <span className={classes.filterTypeText}>Invoice Date</span>
                                                    {showDateCounter &&
                                                    (<div className={classes.count}>
                                                        <span className={classes.countText}>1</span>
                                                    </div>)}
                                                </div>
                                                <img src={openDate ? up : down} alt='filter'  className={classes.arrowImg}/>
                                            </div>
                                                {openDate && (
                                                    <div className={classes.dropdownDateMobile}>
                                                        <div className={classes.dropdownListDateMobile} onClick={() => handleDateSelection('all')}>
                                                            <span className={classes.dropdownTextMobile} >All Issue Dates</span>
                                                            {activeDate === 'all' && <img src={check} className={classes.dropdownListImg} />}
                                                        </div>
                                                        <div className={classes.dropdownListDateMobile} onClick={() => handleDateSelection('one')}>
                                                            <span className={classes.dropdownTextMobile} >Last month</span>
                                                            {activeDate === 'one' && <img src={check} className={classes.dropdownListImg} />}
                                                        </div>
                                                        <div className={classes.dropdownListDateMobile} onClick={() => handleDateSelection('three')}>
                                                            <span className={classes.dropdownTextMobile} >Last 3 months</span>
                                                            {activeDate === 'three' && <img src={check} className={classes.dropdownListImg} />}
                                                        </div>
                                                        <div className={classes.dropdownListDateMobile} onClick={() => handleDateSelection('six')}>
                                                            <span className={classes.dropdownTextMobile} >Last 6 months</span>
                                                            {activeDate === 'six' && <img src={check} className={classes.dropdownListImg} />}
                                                        </div>
                                                    </div>
                                                )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={classes.bottomResetContainer} >
                                {(((customerCounter >= 1 && !allSelected) && (statusCounter >= 1 && !allStatusSelected)) ||
                                    ((customerCounter >= 1 && !allSelected) && (activeDate === 'one' || activeDate === 'three' || activeDate === 'six')) ||
                                    ((activeDate === 'one' || activeDate === 'three' || activeDate === 'six') && (statusCounter >= 1 && !allStatusSelected)))
                                    ?
                                    (
                                        <div className={classes.bottomResetLeft}>
                                            <div className={classes.bottomReset} onClick={handleResetAll}>
                                                <img src={rotate} className={classes.bottomResetImg} />
                                                <span className={classes.bottomResetText}>Reset</span>
                                            </div>
                                        </div>
                                    )
                                    :
                                    (
                                        <div className={classes.bottomResetLeft} style={{ opacity : '0.5'}}>
                                            <div className={classes.bottomReset}>
                                                <img src={rotate} className={classes.bottomResetImg} />
                                                <span className={classes.bottomResetText}>Reset</span>
                                            </div>
                                        </div>
                                    )
                                }
                                {( ((customerCounter >= 1 && !allSelected) || (statusCounter >= 1 && !allStatusSelected) ||
                                 (statusCounter === 1) || (activeDate === 'one' || activeDate === 'three' || activeDate === 'six') || isResetAllTrue )
                                    ?
                                    (<div className={classes.bottomResetRight} style={{ background: '#181C30'}} onClick={handleMobileFilterOpen}>
                                        <div className={classes.showResult}>
                                            <span className={classes.showResultText}>Show Results</span>
                                        </div>
                                    </div>
                                    )
                                    :
                                    (<div className={classes.bottomResetRight}>
                                        <div className={classes.showResult}>
                                            <span className={classes.showResultText}>Show Results</span>
                                        </div>
                                    </div>
                                    )
                                )}
                            </div>
                        </div>
                    </div>)
                }

                {/* Sorting start here  */}

                <div className={classes.filterBox}  onClick={handleMobileSortOpen}>
                        <img src={sort} alt='sort'  style={{width : '24px' , height : '24px'}}/>
                        {(isSortApply) &&
                        (<div className={classes.count}>
                            <span className={classes.countText}>1</span>
                        </div>)}
                </div>
                {mobileSortOpen && 
                    (<div className={classes.filterMobileViewContainer}>
                        <div className={classes.filterMobileViewWrapper}  >
                            <div className={classes.filterMobileViewBox} >
                                <div className={classes.filterMobileTopCon}>
                                    <div className={classes.filterMobileTopWrap} onClick={handleMobileSortOpen}>
                                        <span className={classes.filterText}>Sort</span>
                                        <img src={cross} alt='filter'  className={classes.filterCrossImg} />
                                    </div>
                                </div>
                                <div className={classes.filterMobileBottom}>
                                    <div className={classes.filterMobileCustomerCon} >
                                        <div className={classes.filterMobileCustomerWrap} >
                                            <div className={classes.filterMobileCustomer} onClick={handleOpenSortInvoiceId}>
                                                <div className={classes.counter}>
                                                    <span className={classes.filterTypeText}>Invoice Id</span>
                                                    {(sortState?.sortField === "invoiceId") &&
                                                        <img src={dotBlack} style={{width: '15px' , height: '15px'}} alt="dropdown"/>
                                                    }
                                                </div>
                                                <img src={openSortInvoiceId ? up : down} className={classes.arrowImg} alt="dropdown"/>
                                            </div>
                                            {openSortInvoiceId && (
                                                <div className={classes.filterMobileCustomerDropdown}>
                                                        <div className={classes.dropdownDateMobile}>
                                                            <div className={classes.dropdownListDateMobile} onClick={() => handleSortClickMobile({order : 'default' , field : 'invoiceId'})}>
                                                                <div className={classes.counter}>
                                                                    <img src={defaultSort} className={classes.dropdownListImg} style={{marginLeft: '-3px'}} />
                                                                    <span className={classes.dropdownTextMobile} >Default</span>
                                                                </div>
                                                                {invoiceIdState === 'default' && <img src={check} className={classes.dropdownListImg} />}
                                                            </div>
                                                            <div className={classes.dropdownListDateMobile} onClick={() => handleSortClickMobile({order : 'ascending' , field : 'invoiceId'})}>
                                                                <div className={classes.counter}>
                                                                    <img src={ascSort} className={classes.dropdownListImg} />
                                                                    <span className={classes.dropdownTextMobile} >Ascending</span>
                                                                </div>
                                                                {invoiceIdState === 'ascending' && <img src={check} className={classes.dropdownListImg} />}
                                                            </div>
                                                            <div className={classes.dropdownListDateMobile} onClick={() => handleSortClickMobile({order : 'descending' , field : 'invoiceId'})}>
                                                                <div className={classes.counter}>
                                                                    <img src={descSort} className={classes.dropdownListImg} />
                                                                    <span className={classes.dropdownTextMobile} >Descending</span>
                                                                </div>
                                                                {invoiceIdState === 'descending' && <img src={check} className={classes.dropdownListImg} />}
                                                            </div>
                                                        </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    <div className={classes.filterMobileCustomerCon} >
                                        <div className={classes.filterMobileCustomerWrap} >
                                            <div className={classes.filterMobileCustomer} onClick={handleOpenSortCreatedOn}>
                                                <div className={classes.counter}>
                                                    <span className={classes.filterTypeText}>Issue Date</span>
                                                    {(sortState?.sortField === "issueDate") &&
                                                        <img src={dotBlack} style={{width: '15px' , height: '15px'}} alt="dropdown"/>
                                                    }
                                                </div>
                                                <img src={openSortCreatedOn  ? up : down} className={classes.arrowImg} alt="dropdown"/>
                                            </div>
                                            {openSortCreatedOn && (
                                                <div className={classes.filterMobileCustomerDropdown}>
                                                        <div className={classes.dropdownDateMobile}>
                                                            <div className={classes.dropdownListDateMobile} onClick={() => handleSortClickMobile({order : 'default' , field : 'issueDate'})}>
                                                                <div className={classes.counter}>
                                                                    <img src={defaultSort} className={classes.dropdownListImg} style={{marginLeft: '-3px'}} />
                                                                    <span className={classes.dropdownTextMobile} >Default</span>
                                                                </div>
                                                                {issueDateState === 'default' && <img src={check} className={classes.dropdownListImg} />}
                                                            </div>
                                                            <div className={classes.dropdownListDateMobile} onClick={() => handleSortClickMobile({order : 'ascending' , field : 'issueDate'})}>
                                                                <div className={classes.counter}>
                                                                    <img src={ascSort} className={classes.dropdownListImg} />
                                                                    <span className={classes.dropdownTextMobile} >Ascending</span>
                                                                </div>
                                                                {issueDateState === 'ascending' && <img src={check} className={classes.dropdownListImg} />}
                                                            </div>
                                                            <div className={classes.dropdownListDateMobile} onClick={() => handleSortClickMobile({order : 'descending' , field : 'issueDate'})}>
                                                                <div className={classes.counter}>
                                                                    <img src={descSort} className={classes.dropdownListImg} />
                                                                    <span className={classes.dropdownTextMobile} >Descending</span>
                                                                </div>
                                                                {issueDateState === 'descending' && <img src={check} className={classes.dropdownListImg} />}
                                                            </div>
                                                        </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    <div className={classes.filterMobileCustomerCon} >
                                        <div className={classes.filterMobileCustomerWrap} >
                                            <div className={classes.filterMobileCustomer} onClick={handleOpenSortCustomer}>
                                                <div className={classes.counter}>
                                                    <span className={classes.filterTypeText}>Customer Details</span>
                                                    {(sortState?.sortField === "customerBaseEntity.customerDetails.customerName") &&
                                                        <img src={dotBlack} style={{width: '15px' , height: '15px'}} alt="dropdown"/>
                                                    }
                                                </div>
                                                <img src={openSortCustomer ? up : down} className={classes.arrowImg} alt="dropdown"/>
                                            </div>
                                            {openSortCustomer && (
                                                <div className={classes.filterMobileCustomerDropdown}>
                                                        <div className={classes.dropdownDateMobile}>
                                                            <div className={classes.dropdownListDateMobile} onClick={() => handleSortClickMobile({order : 'default' , field : 'customerBaseEntity.customerDetails.customerName'})}>
                                                                <div className={classes.counter}>
                                                                    <img src={defaultSort} className={classes.dropdownListImg} style={{marginLeft: '-3px'}} />
                                                                    <span className={classes.dropdownTextMobile} >Default</span>
                                                                </div>
                                                                {customerDetailsState === 'default' && <img src={check} className={classes.dropdownListImg} />}
                                                            </div>
                                                            <div className={classes.dropdownListDateMobile} onClick={() => handleSortClickMobile({order : 'ascending' , field : 'customerBaseEntity.customerDetails.customerName'})}>
                                                                <div className={classes.counter}>
                                                                    <img src={ascSort} className={classes.dropdownListImg} />
                                                                    <span className={classes.dropdownTextMobile} >Ascending</span>
                                                                </div>
                                                                {customerDetailsState === 'ascending' && <img src={check} className={classes.dropdownListImg} />}
                                                            </div>
                                                            <div className={classes.dropdownListDateMobile} onClick={() => handleSortClickMobile({order : 'descending' , field : 'customerBaseEntity.customerDetails.customerName'})}>
                                                                <div className={classes.counter}>
                                                                    <img src={descSort} className={classes.dropdownListImg} />
                                                                    <span className={classes.dropdownTextMobile} >Descending</span>
                                                                </div>
                                                                {customerDetailsState === 'descending' && <img src={check} className={classes.dropdownListImg} />}
                                                            </div>
                                                        </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    <div className={classes.filterMobileCustomerCon} >
                                        <div className={classes.filterMobileCustomerWrap} >
                                            <div className={classes.filterMobileCustomer} onClick={handleOpenSortAmount}>
                                                <div className={classes.counter}>
                                                    <span className={classes.filterTypeText}>Receivable Amount</span>
                                                    {(sortState?.sortField === "amount") &&
                                                        <img src={dotBlack} style={{width: '15px' , height: '15px'}} alt="dropdown"/>
                                                    }
                                                </div>
                                                <img src={openSortAmount ? up : down} className={classes.arrowImg} alt="dropdown"/>
                                            </div>
                                            {openSortAmount && (
                                                <div className={classes.filterMobileCustomerDropdown}>
                                                        <div className={classes.dropdownDateMobile}>
                                                            <div className={classes.dropdownListDateMobile} onClick={() => handleSortClickMobile({order : 'default' , field : 'amount'})}>
                                                                <div className={classes.counter}>
                                                                    <img src={defaultSort} className={classes.dropdownListImg} style={{marginLeft: '-3px'}} />
                                                                    <span className={classes.dropdownTextMobile} >Default</span>
                                                                </div>
                                                                {amountState === 'default' && <img src={check} className={classes.dropdownListImg} />}
                                                            </div>
                                                            <div className={classes.dropdownListDateMobile} onClick={() => handleSortClickMobile({order : 'ascending' , field : 'amount'})}>
                                                                <div className={classes.counter}>
                                                                    <img src={ascSort} className={classes.dropdownListImg} />
                                                                    <span className={classes.dropdownTextMobile} >Ascending</span>
                                                                </div>
                                                                {amountState === 'ascending' && <img src={check} className={classes.dropdownListImg} />}
                                                            </div>
                                                            <div className={classes.dropdownListDateMobile} onClick={() => handleSortClickMobile({order : 'descending' , field : 'amount'})}>
                                                                <div className={classes.counter}>
                                                                    <img src={descSort} className={classes.dropdownListImg} />
                                                                    <span className={classes.dropdownTextMobile} >Descending</span>
                                                                </div>
                                                                {amountState === 'descending' && <img src={check} className={classes.dropdownListImg} />}
                                                            </div>
                                                        </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    <div className={classes.filterMobileCustomerCon} >
                                        <div className={classes.filterMobileCustomerWrap} >
                                            <div className={classes.filterMobileCustomer} onClick={handleOpenSortStatus}>
                                                <div className={classes.counter}>
                                                    <span className={classes.filterTypeText}>Status</span>
                                                    {(sortState?.sortField === "invoiceOrder") &&
                                                        <img src={dotBlack} style={{width: '15px' , height: '15px'}} alt="dropdown"/>
                                                    }
                                                </div>
                                                <img src={openSortStatus ? up : down} className={classes.arrowImg} alt="dropdown"/>
                                            </div>
                                            {openSortStatus && (
                                                <div className={classes.filterMobileCustomerDropdown}>
                                                        <div className={classes.dropdownDateMobile}>
                                                            <div className={classes.dropdownListDateMobile} onClick={() => handleSortClickMobile({order : 'default' , field : 'invoiceOrder'})}>
                                                                <div className={classes.counter}>
                                                                    <img src={defaultSort} className={classes.dropdownListImg} style={{marginLeft: '-3px'}} />
                                                                    <span className={classes.dropdownTextMobile} >Default</span>
                                                                </div>
                                                                {headerStatusState === 'default' && <img src={check} className={classes.dropdownListImg} />}
                                                            </div>
                                                            <div className={classes.dropdownListDateMobile} onClick={() => handleSortClickMobile({order : 'ascending' , field : 'invoiceOrder'})}>
                                                                <div className={classes.counter}>
                                                                    <img src={ascSort} className={classes.dropdownListImg} />
                                                                    <span className={classes.dropdownTextMobile} >Ascending</span>
                                                                </div>
                                                                {headerStatusState === 'ascending' && <img src={check} className={classes.dropdownListImg} />}
                                                            </div>
                                                            <div className={classes.dropdownListDateMobile} onClick={() => handleSortClickMobile({order : 'descending' , field : 'invoiceOrder'})}>
                                                                <div className={classes.counter}>
                                                                    <img src={descSort} className={classes.dropdownListImg} />
                                                                    <span className={classes.dropdownTextMobile} >Descending</span>
                                                                </div>
                                                                {headerStatusState === 'descending' && <img src={check} className={classes.dropdownListImg} />}
                                                            </div>
                                                        </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>


                            {/* bottom reset box  */}
                            <div className={classes.bottomResetContainer} >
                                {(isSortApply)
                                    ?
                                    (
                                        <div className={classes.bottomResetLeft}>
                                            <div className={classes.bottomReset} onClick={resetAll}>
                                                <img src={rotate} className={classes.bottomResetImg} />
                                                <span className={classes.bottomResetText}>Reset</span>
                                            </div>
                                        </div>
                                    )
                                    :
                                    (
                                        <div className={classes.bottomResetLeft} style={{ opacity : '0.5'}}>
                                            <div className={classes.bottomReset}>
                                                <img src={rotate} className={classes.bottomResetImg} />
                                                <span className={classes.bottomResetText}>Reset</span>
                                            </div>
                                        </div>
                                    )
                                }
                                {( (isSortApply)
                                    ?
                                    (<div className={classes.bottomResetRight} style={{ background: '#181C30'}} onClick={handleMobileSortOpen}>
                                        <div className={classes.showResult}>
                                            <span className={classes.showResultText}>Sort</span>
                                        </div>
                                    </div>
                                    )
                                    :
                                    (<div className={classes.bottomResetRight} onClick={handleMobileSortOpen}>
                                        <div className={classes.showResult}>
                                            <span className={classes.showResultText}>Sort</span>
                                        </div>
                                    </div>
                                    )
                                )}
                            </div>
                        </div>
                    </div>)
                }
            </div>
        </div>
  )
}

export default MobilePageFilter