import React from 'react'

const Spinner = () => {
    return (
        <div className="flex justify-center">
            <div className="h-12 w-12 animate-spin rounded-full border-4 border-dotted border-[#7527c4] border-opacity-50"></div>
        </div>
    )
}

export default Spinner
