import React from 'react';
import classes from "./profileComponents.module.scss";

const Header = ({text1,text2}) => {
  return (
    <div className={`${classes.header}`}>
        <span className={`${classes.textOneHead}`}>{text1}</span>
        <span className={`${classes.textTwoHead}`}>{text2}</span>
    </div>
  )
}

export default Header