export const downloadWithInterval = (urls, intervalTime) => {
    function download() {
        var url = urls.pop();

        if (url) {
            var anchor = document.createElement("a");
            anchor.style.display = 'none';
            anchor.setAttribute('href', url);
            anchor.setAttribute('download', '');
            document.body.appendChild(anchor);
            anchor.click();
            document.body.removeChild(anchor);
        }

        if (urls.length === 0) {
            clearInterval(interval);
        }
    }

    download();

    var interval = setInterval(download, intervalTime);
}