import React, { useEffect, useState, memo, useMemo } from "react";
import { useDropzone } from "react-dropzone";
import { Document, Page, pdfjs } from "react-pdf";
import DragDropIcon from "assets/Icons/darg-drop-icon.svg";
import FileIcon from "assets/Icons/fileIconNew.svg";
import lockGrayIcon from "assets/Icons/lockGrayIcon.svg";
import lockDoneIcon from "assets/Icons/lockDoneIcon.svg";
import BgIcon from "assets/Icons/bg-file-uploader.png";
import UploadIcon from "assets/Icons/upload-cloud.svg";
import ArrowIcon from "assets/Icons/complete-arrow.svg";
import DeleteIcon from "assets/Icons/delete-icon.svg";
import AddFileIcon from "assets/Icons/add-file.svg";
import { enqueueSnackbar, useSnackbar } from 'notistack'
import CloseIcon from 'assets/Images/Close.svg'
import RefreshIcon from 'assets/Icons/refresh.svg'
import DoneIcon from 'assets/Icons/done-white.svg'

import styles from "./EditFileBrowserNew.module.scss";
import clsx from "clsx";
import UseApiCall from "hooks/useApiCall";
import {
  apiCreateMediaBulkTransactionsUploadUrl,
  apiDeleteMediaBulkTransactionsUploadUrl,
} from "services/invoiceServices";
import { fetchFileFromS3 } from 'services'
import {
  apiCreateMediaUploadUrl,
  apiDeleteMedia,
} from "services/invoiceServices";
import { handleDecrypt } from "hooks/handleDecryption";
import { doEncryption } from 'constants';

const fileFormats = {
  image: ['image/jpeg', 'image/jpg', 'image/png'],
  video: ['video/mp4', 'video/mov', 'video/quicktime'],
  text: ['text/csv'],
  document: ['application/pdf'],
}

const img = {
  display: 'block',
  width: 'auto',
  height: '100%',
  margin: '0 auto',
}

const generateRandomId = (randomNumberLength) => {
  let result = ''
  while (result.length <= randomNumberLength) {
    result = `${result}${Math.round(9 * Math.random())}`
  }
  return result
}

const getAllowedFileTypes = (allowedFormats) => {
  const result = []
  allowedFormats?.forEach((types) => {
    result.push(...fileFormats[types])
  })
  return result
}

const MAX_ALLOWED_FILES = 10

const EditFileBrowserNew = ({
  transactionId,
  validFormats,
  isResponsive = true,
  disabled = false,
  displayUrl = [],
  setFile,
  hasError: externalHasError,
  errorMessage,
  isMobile,
  userType,
  docType,
  docNum,
  userName,
  files,
  componentName,
  previousInvoiceData,
  isLocked,
  willUpdate,
  ...rest
}) => {
  const [selectedFiles, setSelectedFiles] = useState({})
  const [selectedPreviewFileIdx, setSelectedPreviewFileIdx] = useState(null)
  const [deletedFiles, setDeletedFile] = useState({})
  const hiddenFileInput = React.useRef(null)
  const [uploadingLoader, setUploadingLoader] = useState(false);
  const [seconds, setSeconds] = useState(0);

  const [deleteMedia] = UseApiCall(apiDeleteMedia)
  const [hasError, setHasError] = useState(externalHasError)
  
  useEffect(() => {
    setHasError(externalHasError);
  }, [externalHasError]);

  // const [getFilesFromS3, fetchingFiles] = UseApiCall(fetchFileFromS3, (res) => {
  //   const blob = res.blob()
  //   setSelectedFiles((prev) => ({ ...prev, ...res }))
  //   // console.log(blobFile(res))
  // })

  // console.log('previousInvoiceData',previousInvoiceData)
  // const [fileCount, setFileCount] = useState(1)
  let fileCountPdf = 1;

  const [getFilesFromS3, fetchingFiles] = UseApiCall(
    fetchFileFromS3,
    (res, headers) => {
      try {
        // setFileCount(prev => prev + 1)
        const fileName = headers['content-disposition']
          ? headers['content-disposition'].split('filename=')[1]
          : `file${fileCountPdf}.pdf`;
          // console.log('fileName',headers['content-type'])
          fileCountPdf++;
    
        const file = new File([res], fileName, { type: headers['content-type'] });

        // console.log('firstfile',file)
        onPreviousSelectFiles([file], res)
      } catch (err) {
        enqueueSnackbar(`${err?.data?.error_response?.message} ${err?.data?.error_response?.errors !== null ? JSON.stringify(err?.data?.error_response?.errors[0]) : ""}`, { variant: 'error' })
      }
    },
    (err)=> {},
    'direct'
  )

  let apiCallCount = 0;

  const downloadInvoices = () => {
    // previousInvoiceData?.filter((item)=>item.isLocked === true)?.map((item, index) => {
      previousInvoiceData?.map((item, index) => {
        apiCallCount = index;
        // console.log('item',item)
        getFilesFromS3(item.url, index)
        return item
    })
  }

  useEffect(() => {
    if (previousInvoiceData && !localStorage.getItem("locked")) {
      localStorage.setItem("locked", "locked")
      downloadInvoices();
    }
  }, [previousInvoiceData])

  useEffect(() => {
    if (isMobile && isLocked && previousInvoiceData && !localStorage.getItem("isLocked")) {
      localStorage.setItem("isLocked", "isLocked")
      downloadInvoices();
    }
  }, [previousInvoiceData])

  const totalFiles = useMemo(
    () => Object.keys(selectedFiles).filter((id) => !deletedFiles[id]),
    [selectedFiles, deletedFiles]
  )

  useEffect(() => {
    setFile(totalFiles)
  }, [totalFiles])

  let count = 0;

  const onPreviousSelectFiles = (files, data) => {
    count++;
    if (files?.length + totalFiles?.length > MAX_ALLOWED_FILES) {
      alert(`Only ${MAX_ALLOWED_FILES} files are allowed at max`)
      return
    }

    if (files?.length > 0) {
      let selectedId = 0;
      const newFiles = {}
      ;[...files].forEach((f) => {
        const id = generateRandomId(6)
        selectedId = id;
        newFiles[id] = Object.assign(f, {
          preview: URL.createObjectURL(f),
          uploadedAt: Date.now(),
          inQueue: false,
          isUploading: false,
          previousDoc: true,
          id,
          invoiceLinkId: previousInvoiceData[count-1]?.invoiceLinkId
        })
      })

      if (!selectedPreviewFileIdx) {
        setSelectedPreviewFileIdx(selectedId);
      }
      setSelectedFiles((prev) => ({ ...prev, ...newFiles }))
    }
  }

  const onSelectFiles = (files) => {
    if (willUpdate) return
    if (files?.length + totalFiles?.length > MAX_ALLOWED_FILES) {
      alert(`Only ${MAX_ALLOWED_FILES} files are allowed at max`)
      return
    }

    // console.log('files',files)
    if (files?.length > 0) {
      setSeconds(4*files?.length)
      const newFiles = {}
      ;[...files].forEach((f) => {
        const id = generateRandomId(6)
        newFiles[id] = Object.assign(f, {
          preview: URL.createObjectURL(f),
          uploadedAt: Date.now(),
          inQueue: true,
          isUploading: null,
          previousDoc: false,
          id,
        })
      })

      setSelectedFiles((prev) => ({ ...prev, ...newFiles }))
      uploadFiles(newFiles)
      setUploadingLoader(true)
    }
  }

  const { acceptedFiles,getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      'application/pdf': [],
      'image/png': [],
      'image/jpeg': [],
      'image/jpg': [],
    },
    onDrop: (acceptedFiles) => {
      onSelectFiles(acceptedFiles)
    },
    disabled: disabled,
  })
  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`
  }, [])

  useEffect(() => {
    return () =>
      Object.keys(selectedFiles).forEach((fileId) =>
        URL.revokeObjectURL(selectedFiles[fileId].preview)
      )
  }, [])

  const uploadFiles = async (newFiles) => {
    if(willUpdate) return;

    // console.log('newFiles',newFiles)
    const sortedFiles = Object.values(newFiles).sort(
      (a, b) => a.uploadedAt - b.uploadedAt
    )
    const fileIds = sortedFiles.map((a) => a.id)
    if (!selectedPreviewFileIdx) {
      setSelectedPreviewFileIdx(fileIds[0])
    }
    for (let i = 0; i < fileIds.length; i++) {
      if (deletedFiles[fileIds[i]]) {
        continue
      }
      const _file = newFiles[fileIds[i]]
      try {
        if (!_file.previousDoc) {
          _file.isUploading = true
          setSelectedFiles((prev) => ({ ...prev, [fileIds[i]]: _file }))

          // File upload process started
          const arrayBuffer = await _file.arrayBuffer()
          const blob = new Blob([arrayBuffer], {
            type: _file.type,
          })

          let fileName = encodeURIComponent(_file?.name);
          // let apiResponse;


          const apiResponse = await apiCreateMediaUploadUrl({
            txnId:transactionId,
            isLocked: isLocked,
            fileName: fileName,
          })
          // const urlData = await apiResponse.data
          let urlData;
          if (doEncryption) {
            urlData = await handleDecrypt(apiResponse.data);
          } else {
            urlData = await apiResponse.data;
          }
          await fetch(urlData.data.url, {
            method: 'PUT',
            body: blob,
            headers: {
              'Content-Type': _file.type,
            },
          })
          _file.invoiceLinkId = urlData.data.invoiceLinkId
        }
        if (deletedFiles[_file.id]) {
        }
        // File uploaded
        _file.inQueue = false
        _file.isUploading = false

        setTimeout(() => {
          if (seconds === 0) {
            setUploadingLoader(false)
            setSeconds(0)
          }
        }, [2000])

        setSeconds((prev) => prev - 0)

        setSelectedFiles((prev) => ({ ...prev, [fileIds[i]]: _file }))
      } catch (e) {
        handleRemoveFile(_file)
      }
    }
  }

  const onUploadClick = (event) => {
    if (disabled) return
    hiddenFileInput.current.click()
  }

  const handleFileUploadChange = (event) => {
    if (disabled) return

    event.preventDefault();
    onSelectFiles(event.target.files)
  }

  const handleRemoveFile = async (file) => {
    const currentFiles = Object.values(selectedFiles)
      .filter((f) => !deletedFiles[f.id])
      .sort((f1, f2) => f1.uploadedAt - f2.uploadedAt)

    const delFileIdx = currentFiles.findIndex((f) => f.id === file.id)

    if (file.id === selectedPreviewFileIdx) {
      if (delFileIdx < currentFiles.length - 1) {
        const _id = currentFiles[delFileIdx + 1].id
        setTimeout(() => setSelectedPreviewFileIdx(_id), 0)
      } else {
        const _id =
          currentFiles.length > 1
            ? currentFiles[currentFiles.length - 2].id
            : null
        setTimeout(() => setSelectedPreviewFileIdx(_id), 0)
      }
    } else if (selectedPreviewFileIdx && deletedFiles[selectedPreviewFileIdx]) {
      const _id =
        currentFiles.length > 1
          ? currentFiles[currentFiles.length - 2].id
          : null
      setTimeout(() => setSelectedPreviewFileIdx(_id), 0)
    }
    if (file.invoiceLinkId) {
      deleteMedia({ txnId:transactionId, invoiceLinkId: file.invoiceLinkId })
    }
    setDeletedFile((prev) => ({ ...prev, [file.id]: file.id }))
  }

  return (
    <div
      // className={clsx(styles.documentupload, 'sm:pb-12')}
    >
      <div className="" style={{
        // border: hasError ? "2px solid #FF6868" : "inherit",
        // borderRadius: hasError ? "0.8rem" : "inherit",
        width: "100%"
      }}>
        <div className={clsx(styles.documentupload, `bg-[${willUpdate && 'blue'}]`)}>
          <div
            {...getRootProps({
              className: clsx(
                styles.dropContainer,
                'dropzone',
                // isResponsive && '-sm:hidden'
                `cursor-${willUpdate ? 'default' : 'pointer'} opacity-${willUpdate ? '30' : '100'}`

              ),
            })}
            style={{
              // backgroundImage: `url(${BgIcon})`,
              // backgroundRepeat: 'repeat',
              // backgroundColor: isDragActive
              //   ? 'rgba(194, 243, 194, 0.3)'
              //   : 'transparent',
              // border: isDragActive
              //   ? '1px dashed rgb(63, 74, 63)'
              //   : '1px dashed #d2d6d9',
              // marginTop: totalFiles?.length === 0 ? "-6px" : "18px"
            }}
          >
            {!disabled && (
              <div
                className={clsx(styles.dropMessage, `flex ${"flex-col"} justify-center items-center relative`)}
                onClick={(e)=>{
                 if(willUpdate){
                  e.stopPropagation()
                 }
                }}
              >
                <div className={clsx(`relative w-[84px] ${isMobile ? "h-[44px]" : "h-[84px] bottom-[-6px]"}`)}>
                  <img src={componentName === "locked" ? lockGrayIcon : FileIcon} alt="file" className={styles.fileIconImg} />
                  {/* {componentName !== "locked" &&(<div className={clsx(styles.arrowBackground)}>
                    <img src={ArrowIcon} alt="arrow" className="w-[24px] h-[24px]" />
                  </div>)} */}
                </div>
                <p className="mt-[16px]">
                  { false ?
                      <div className={clsx(styles.fileText, `ml-4`)}><b className="font-semibold">Browse files</b></div>
                      : (isDragActive ? (
                          <><b className="font-semibold">Drag</b> files here to upload{' '}</>
                          ) : (
                          <>
                          { !isMobile
                              ? <p className={styles.fontText}>Drop files here to upload, or click <b className={styles.fontTextUpload}>Upload</b></p>
                              : <p className={styles.fontText}>Tap here to <b className={styles.fontTextUpload}>Upload</b></p>
                          }
                            <p className={styles.fontSubText}>PDF, JPEG, JPG, PNG (max 10mb)</p>
                          </>
                          )
                        )
                  }
                </p>
                {/* {getAllowedFileTypes(validFormats)?.length > 0 && (
                  <p className={styles.fileFormat}>
                    {getAllowedFileTypes(validFormats)?.map((type, index) => {
                      return `${type.split('/')[1].toUpperCase()}${
                        !(index + 1 === getAllowedFileTypes(validFormats)?.length)
                          ? `, `
                          : ''
                      }`
                    })}{' '}
                    (max 10mb)
                  </p>
                )} */}
              </div>
            )}
            <input {...getInputProps()} />
            {/* {selectedFiles[selectedPreviewFileIdx] &&
              !deletedFiles[selectedPreviewFileIdx] && (
                <div
                  className={clsx(
                    styles.previewContainer,
                    isDragActive ? 'opacity-10' : ''
                  )}
                >
                  {fileFormats.document.includes(
                    selectedFiles[selectedPreviewFileIdx].type
                  ) && (
                    <Document
                      file={selectedFiles[selectedPreviewFileIdx].preview}
                      className={clsx(styles.documentFull)}
                    >
                      <Page pageNumber={1} />
                    </Document>
                  )}
                  {fileFormats.image.includes(
                    selectedFiles[selectedPreviewFileIdx].type
                  ) && (
                    <img
                      alt={selectedFiles[selectedPreviewFileIdx].name}
                      src={selectedFiles[selectedPreviewFileIdx].preview}
                      style={img}
                    />
                  )}
                </div>
              )} */}
          </div>
        </div>
        <div
          className={clsx(
            styles.thumbnailContainer,
            isMobile && 'h-[30vh] overflow-y-scroll overflow-x-hidden'
            // isResponsive
            //   ? 'border-0 border-t-0 sm:border'
            //   : 'border border-t-0',
          )}
          style={{ display: totalFiles?.length === 0 && "none"}}
        >
          {componentName === "locked" ?
          (<p className={clsx(styles.fontText, 'font-[500]')}>
            {
              (uploadingLoader && seconds !== 0)
                ? `Uploading ${acceptedFiles?.length} of ${Object.keys(acceptedFiles || {})?.length} documents, ${seconds} seconds left`
                : `Uploaded ${Object.keys(totalFiles || {})?.length} ${Object.keys(totalFiles || {})?.length <= 1  ? 'locked document' : 'locked documents'}`
            }
          </p>) : 
          <p className={clsx(styles.fontText, 'font-[500]')}>
            {
              (uploadingLoader && seconds !== 0)
                ? `Uploading ${acceptedFiles?.length} of ${Object.keys(acceptedFiles || {})?.length} invoices, ${seconds} seconds left`
                : `Uploaded ${Object.keys(totalFiles || {})?.length} ${Object.keys(totalFiles || {})?.length <= 1  ? 'invoice' : 'invoices'}`
            }
          </p>
          }
          <div className={"flex mt-[10px] mb-[12px] relative left-[-24px]"} style={{ border: "1px solid #ECEFF2", background: "#FFF", width: "calc(100% + 48px)" }}></div>
          {totalFiles?.length > 0 &&
            Object.values(selectedFiles)
              .sort((f1, f2) => f1.uploadedAt - f2.uploadedAt)
              .map((file) =>
                deletedFiles[file.id] ? null : (
                  <div className={styles.fileUploader}>
                    <div className="flex items-center">
                      {
                        file.inQueue
                          ? 
                          <img src={RefreshIcon} alt="RefreshIcon" className='' />
                          : 
                          (componentName !== "locked" ?
                            (<div className="bg-[#1CA95D] h-[22px] w-[22px] p-[2px] rounded-full relative">
                              <img src={DoneIcon} alt="DoneIcon" className='bg-[#1CA95D] h-[12px] w-[12px] rounded-full relative top-[22%] left-[15%]' />
                          </div>) : <img src={lockDoneIcon} alt="RefreshIcon" className='pr-[16px]' />)
                      }
                    </div>
                    <div
                      key={file.id}
                      className={clsx(
                        styles.fileThumbnail,
                        // 'aspect-square gap-2 rounded-xl border-solid border items-center py-2 px-2',
                        // selectedPreviewFileIdx === file.id
                        //   ? 'border-[#1E333F]'
                        //   : 'border-[#060A0D33]',
                        file.inQueue ? 'opacity-40' : ''
                      )}
                      onClick={() => setSelectedPreviewFileIdx(file.id)}
                    >
                      {/* {fileFormats.document.includes(file.type) && (
                        <Document
                          file={file.preview}
                          className={clsx(styles.documentThumbnail)}
                        >
                          <Page pageNumber={1} />
                        </Document>
                      )} */}
                      <div className={styles.uploadLayout}>
                        <p className={clsx(styles.documentText, 'mb-[4px]')}>{file?.name?.length > 25  ? `${file.name.slice(0, 25)}...` : file.name}</p>
                        <p className={styles.uploadingText}>{file.isUploading === false ? (componentName === "locked" ? "Uploaded & Locked " : "Uploaded") : "Uploading..."}</p>
                        <p></p>
                      </div>
                      <div
                        className={clsx(
                          styles.progressBar,
                          file.isUploading === false
                            ? styles.progressBar100
                            : file.isUploading === true
                            ? styles.progressBar60 : file.isUploading === true ? styles.progressBar20
                            : ''
                        )}
                      ></div>
                    </div>
                    {!file.inQueue && !disabled && (
                        <div
                          className={clsx(`${styles.closeIcon} cursor-${willUpdate ? 'default' : 'pointer'} opacity-${willUpdate ? '40' : '100'}`)}
                          onClick={ willUpdate ? () => {} : () => handleRemoveFile(file)}
                        >
                          {
                            file.inQueue
                              ? <img src={CloseIcon} alt="CloseIcon" className='' />
                              : <img src={DeleteIcon} alt="DeleteIcon" className='' />
                          }
                        </div>
                    )}
                    {file.inQueue && (
                        <div
                          className={clsx(styles.closeIcon)}
                          // onClick={() => handleRemoveFile(file)}
                          onClick={ willUpdate ? () => {} : () => handleRemoveFile(file)}
                        >
                          {
                            file.inQueue
                            ? <img src={CloseIcon} alt="CloseIcon" className='' />
                            : <img src={DeleteIcon} alt="DeleteIcon" className='' />
                          }
                        </div>
                    )}
                  </div>
                )
              )}
          {/* {!disabled && (
            <div
              onClick={onUploadClick}
              className="aspect-square flex item-center justify-center flex-col gap-2 cursor-pointer rounded-xl border-solid border border-[#060A0D33] items-center py-4 px-8"
            >
              <img
                src={totalFiles?.length > 0 ? AddFileIcon : UploadIcon}
                alt="+"
                className="w-8 h-8"
              />
              <p className="text-lg">
                {totalFiles?.length > 0 ? 'More' : 'Upload'}
              </p>
            </div>
          )} */}
        </div>
        <input
          type="file"
          ref={hiddenFileInput}
          onChange={handleFileUploadChange}
          multiple
          accept={getAllowedFileTypes(validFormats).join(', ')}
          style={{ display: 'none' }}
        />
      </div>
      {/* code to check if error add message */}
      {hasError && <div className={styles.error} style={{ width: "100%"}}>{errorMessage}</div>}
    </div>
  )
}

export default memo(EditFileBrowserNew);
