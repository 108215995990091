import { createSlice } from "@reduxjs/toolkit";

const authInitialState = {
    showBackendError : false
}

export const AuthSlice = createSlice({
    name: 'auth-slice',
    initialState: authInitialState,
    reducers: {
        setShowBackendError(state, action){
            state.showBackendError = action.payload;
        }
    }
})

export const AuthActions = AuthSlice.actions;

const loginInitialState = {
    userName : '',
    emailInput : '', 
    isEmailValid : false, 
    passwordInput : '',
    isPasswordValid : false,
    mobileNumberInput : '',
    mobileCodeInput: '+91',
    isMobileValid : false,
    showBackendError :false,
}

 const LoginSlice = createSlice({
    name: 'login-slice',
    initialState : loginInitialState,
    reducers : {
        setUserName(state, action){
            state.userName = action.payload
        },
        setEmailInput(state, action){
            state.emailInput = action.payload
        },
        setEmailValidity(state, action){
            state.isEmailValid = action.payload
        },
        setPasswordInput(state, action){
            state.passwordInput = action.payload
        },
        setPasswordValidity(state, action){
            state.isPasswordValid = action.payload;
        },
        setMobileNumberInput(state, action){
            state.mobileNumberInput = action.payload
        },
        setMobileCodeInput(state, action){
            state.mobileCodeInput = action.payload
        },
        setMobileValidity(state, action){
            state.isMobileValid = action.payload
        },
        setShowBackendError(state, action){
            state.showBackendError = action.payload;
        }
    }
})

export const LoginActions = LoginSlice.actions;

const forgotPasswordInitialState = { emailInput : '', isEmailValid : false};

export const ForgotPasswordSlice = createSlice({
    name : 'forgot-password',
    initialState : forgotPasswordInitialState,
    reducers : {
        setEmailInput(state, action){
            state.emailInput = action.payload
        },
        setEmailValidity(state, action){
            state.isEmailValid = action.payload
        }
    }
})

export const ForgotPasswordActions = ForgotPasswordSlice.actions;

const passwordSetInitialState ={
    passwordInput : '',
    isPasswordValid : false,
    confirmPasswordInput : '',
    isConfirmPasswordValid : false,
}

export const PasswordSetSlice = createSlice({
    name : 'password-set',
    initialState : passwordSetInitialState,
    reducers : {
        setPasswordInput(state, action){
            state.passwordInput = action.payload;
        },
        setPasswordValidity(state, action){
            state.isPasswordValid = action.payload;
        },
        setConfirmPasswordInput(state, action){
            state.confirmPasswordInput = action.payload;
        },
        setConfirmPasswordValidity(state, action){
            state.isConfirmPasswordValid = action.payload;
        },
    }
})

export const PasswordSetActions = PasswordSetSlice.actions;


const signupInitialState = {
    firstNameInput : '',
    lastNameInput : '',
    emailInput : '',
    isEmailValid : false,
    mobileCodeInput : '+91',
    mobileNumberInput : '',
    isMobileNumberValid: false,
    completeData: {},
}

export const SignupSlice = createSlice({
    name : 'sign-up',
    initialState : signupInitialState,
    reducers: {
        setFirstName(state, action){
            state.firstNameInput = action.payload;
        },
        setLastName(state, action){
            state.lastNameInput = action.payload;
        },
        setEmailInput(state, action){
            state.emailInput = action.payload;
        },
        setEmailValidity(state, action){
            state.isEmailValid = action.payload;
        },
        setMobileCodeInput(state, action){
            state.mobileCodeInput = action.payload;
        },
        setMobileNumberInput(state, action){
            state.mobileNumberInput = action.payload;
        },
        setMobileValidity(state, action){
            state.isMobileNumberValid = action.payload;
        },
        setCompleteData(state, action) {
            state.completeData = action.payload;
        }
    }
})

export const SignupActions = SignupSlice.actions;

export default LoginSlice;