import React, { useEffect, useState } from 'react'
import ReactDOM from "react-dom";
import classes from "./Profile.module.scss"
import editIcon from "assets/Icons/edit.svg"
import userAddBlack from "assets/Images/user-add-white.svg";
import BasicDetails from './BasicDetails'
import BusinessDetails from './BusinessDetails'
import KYCDetails from './KYCDetails'
import ComingSoonPrompt from 'Components/atom/ComingSoonPrompt/ComingSoonPrompt'
import CustomModal from 'Components/atom/CustomModal/CustomModal'
import PageIntro from 'Components/Global/PageIntro'
import { useDispatch, useSelector } from 'react-redux'
import UseApiCall from 'hooks/useApiCall'
import { apiGetProfile, apiGetSummary, apiProfile } from 'services'
import { UsersActions } from 'Store/user-redux'
import { REFRESH_TOKEN } from 'constants'
import { TOKEN } from 'constants'
import axios from 'axios'
import Spinner from 'Components/atom/Spinner/Spinner'
import Loader from 'Components/atom/Loader/Loader'
import UserManagement from './UserManagement'
import Integrations from './Integrations';
import { useSnackbar } from 'notistack'
import { modalActions } from 'Store/modal-redux'
import AddNewUser from './profileComponents/AddNewUser';
import Shimmer from 'Layout/Shimmer'
import { useLocation, useNavigate } from 'react-router-dom';
import Error from 'Components/Global/Error';
import CryptoJS from 'crypto-js';
import { secretKey } from 'constants';

const Profile = () => {
    const [active, setActive] = useState('1');
    const [modalRef, setModalRef] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const addNewUserDisplay = useSelector((state) => state.modal.addNewUserDisplay)
    const { enqueueSnackbar } = useSnackbar()
    let [innerWidth,setInnerWidth] = useState(window.innerWidth);
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const userDetails = useSelector((state) => state.user.users)

    const decryptValue = (encryptedValue) => {
      const bytes = CryptoJS.AES.decrypt(encryptedValue || '', secretKey);
      return bytes.toString(CryptoJS.enc.Utf8);
    };
  
    const storedUserRole = decryptValue(localStorage.getItem('nickName') || '');

    const [getUserDetails, fetchingUserDetails, datares, error] = UseApiCall(apiGetProfile, (res) => {
       dispatch(UsersActions.setUsers(res?.data))
    })
    const [screenWidth, setScreenWidth] = useState(getCurrentDimension());
    function getCurrentDimension() {
        return  window.innerWidth
    }
    
    useEffect(() => {
      const updateDimension = () => {
          setScreenWidth(getCurrentDimension());
       };
       window.addEventListener("resize", updateDimension);
        return () => {
          window.removeEventListener("resize", updateDimension);
        };
    }, [screenWidth]);
    
    
    const showComingSoon = () => {
        modalRef.show({
          title: "",
          child: <ComingSoonPrompt />,
          onClose: () => {},
          compactSize: true,
          closeOnClickOutside: true,
        });
      };

    const arrBtn = [
        // {
        //   text: "Edit Profile",
        //   icon: editIcon,
        //   onClick: function () {
        //     showComingSoon();
        //   },
        // },
        {
          text: 'Add New User',
          icon: userAddBlack,
          active: true,
          onClick: function () {
            if (addNewUserDisplay) {
              dispatch(modalActions.addNewUserDisplay(false))
              setTimeout(() => {
                dispatch(modalActions.addNewUserDisplay(true))
              }, [20])
            }
            else dispatch(modalActions.addNewUserDisplay(true))
            // dispatch(modalActions.addNewUserDisplay(true))
          },
        },
      ];

      useEffect(() => {
        if (storedUserRole === 'malik'){
          // if (Array.isArray(userDetails)) 
          dispatch(UsersActions.setUsers([]))
          getUserDetails();
        }else{
          navigate("/Error")
        }
      },[]);
      const onSubmit = () => {
        getUserDetails();
      }
      // console.log('newLogin')
    

      const basicDetails = userDetails?.basic_user_details || {};
      const applicationId = userDetails?.basic_user_details?.application_id || "";
      const businessDetails = userDetails?.business_details || {};
      const userList = userDetails?.user_list_dto || {};

    // Function to update inner width
    function updateInnerWidth() {
        setInnerWidth(window.innerWidth)
    }
    // Add event listener for window resize
    window.addEventListener('resize', updateInnerWidth);
    // Call the function initially to get the inner width on page load
    useEffect(()=>{
        updateInnerWidth();
    },[])

  return (
    <div className={`${classes.profileContainer}`}>
        {/* <div className={`${classes.profileTop}`}> */}
        <CustomModal ref={setModalRef} />
        {addNewUserDisplay && 
          ReactDOM.createPortal(
            <AddNewUser applicationId = {applicationId} onSubmit = {onSubmit} />,
            document.getElementById('modal-root')
          )
        }
        <PageIntro
          pageName={"Profile and Settings"}
          message={innerWidth < 468 ? '' : ("Manage and view your profile, business & KYC details")}
          arrBtn={arrBtn}
        />
            {/* <div className={`${classes.profileTopLeft}`}>
                <span className={`${classes.Text1}`}>Profile and Settings</span>
                <span className={`${classes.Text2}`}>Manage and view your profile, business & KYC details</span>
            </div>
            <div className={`${classes.profileTopRight}`}>
                <div className={`${classes.profileEditBox}`}>
                    <img src={editIcon} alt='edit-icon'  className={`${classes.profileEditBoxImg}`}/>
                    <span className={`${classes.profileEditBoxText}`}>Edit Profile</span>
                </div>
            </div> */}
        {/* </div> */}
        <div className={`${classes.profileBottom}`}>
            <div className={`${classes.profileBottomNav}`}>
                <div className={`${classes.profileBottomNavOne} ${active === '1' && classes.activeText}`}>
                    <span className={`${classes.profileBottomNavOneText} `} onClick={()=>setActive('1')}>Basic Details</span>
                </div>
                <div className={`${classes.profileBottomNavOne} ${active === '2' && classes.activeText}`}>
                    <span className={`${classes.profileBottomNavOneText} `} onClick={()=>setActive('2')}>Business Details</span>
                </div>
                <div className={`${classes.profileBottomNavOne} ${active == '3' && classes.activeText}`}>
                    <span className={`${classes.profileBottomNavOneText} `} onClick={()=>setActive('3')}>KYC Details</span>
                </div>
                <div className={`${classes.profileBottomNavOne} ${active == '4' && classes.activeText}`}>
                    <span className={`${classes.profileBottomNavOneText} `} onClick={()=>setActive('4')}>Users</span>
                </div>
                <div className={`${classes.profileBottomNavOne} ${active == '5' && classes.activeText}`}>
                    <span className={`${classes.profileBottomNavOneText} `} onClick={()=>setActive('5')}>Integrations</span>
                </div>
                <div className={`${classes.profileBottomNavOne} ${active == '6' && classes.activeText}`}>
                    <span className={`${classes.profileBottomNavOneText} `} onClick={()=>setActive('6')}>Settings</span>
                </div>
            </div>
            {fetchingUserDetails ? (
                [1]?.map((item, index) => (
                  <Shimmer key={index} className="w-[100%] h-[220px] mt-[12px]"/>
                )) 
            ) : (<div className={`${classes.profileBottomContent}`}>
                  {active === '1'  && <BasicDetails basicDetails={basicDetails} error={error?.data?.error_response?.message}/> }
                  {active === '2' && <BusinessDetails businessDetails={businessDetails} /> }
                  {active === '3' && <KYCDetails businessDetails={businessDetails}/> }
                  {active === '4' && <UserManagement userList={userList} fetchingUserDetails={fetchingUserDetails}/>}
                  {
                    active === '5' &&
                      <Integrations />
                  }
                  {
                    active === '6' &&
                    <div className={classes.coomingSoon}>
                      <div className={classes.soonText}>
                        Coming Soon.
                      </div>
                    </div>
                  }
                </div>)}
        </div>
        {
          screenWidth<=600 && <div 
          className={classes.mButtonBox}
          >
            <button 
            className={classes.mButton}
            onClick={()=>{
              dispatch(modalActions.addNewUserDisplay(true))
            }}
            >Add New User</button>
          </div>
        }
    </div>
  )
              
}

export default Profile