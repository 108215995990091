import React, { useState } from 'react';
import classes from "Components/ReportTable/CustomerOverview.module.scss"
import up from "assets/Icons/chevron-up.svg"
import down from "assets/Icons/chevron-down.svg"
import grandAlert from "assets/Icons/grandAlert.svg"
import crossImg from "assets/Icons/crossImg.svg"
import Modal from 'Layout/Modal';
import useClickOutside from 'hooks/useClickOutside';
    
import { formatNumberToLocaleString } from 'hooks/formatNumberToLocaleString';
import Shimmer from 'Layout/Shimmer';

const MobileCustomerOverview = (props) => {
    const [openGrand, setOpenGrand] = useState(false)
    const optRef = useClickOutside(() => setOpenGrand(false));
    const [selectedCustomer, setSelectedCustomer] = useState({});
    const [selectedCurrency, setSelectedCurrency] = useState("");

    const handleGrandTotal = () => {
        setOpenGrand(!openGrand)
    }
  return (
    <>
        <div className={classes.mobileWrap}>
        {props.loading 
            ?
            <div className=''>
                <td colSpan={5}>
                    {[1,2,3]?.map((item, index) => (
                    <Shimmer key={index} className="w-[100%] h-[80px] mb-[12px]"/>
                    ))}
                </td>
            </div>
            :
            (props?.sortedNewCustomerData && Object.keys(props?.sortedNewCustomerData)?.map((currency) => (
                <div className={classes.mobileContainer} >
                    {props?.customerData?.currencyWise[currency]?.length > 0 && <p className='text-[16px] font-[600] leading-[20px]'>{`${currency?.toUpperCase()} Dues`}</p>}
                    <div className={classes.customerContainer} >
                        {props?.customerData?.currencyWise[currency]?.map((customer,index) => (
                                <>
                                    <div className={`${classes.customerBox} ${index === 0 && 'rounded-t-[12px] rounded-b-[0px]' }`} key={index} style={{display : openGrand ? 'none' : 'block'}}>
                                        <div className={classes.customerDetail}>
                                            <div className={classes.customerName}>
                                                <div className={classes.name}>
                                                    <p className={classes.nameMain}>{customer?.customerName}</p>
                                                    <p className={classes.nameSub}>Customer Name</p>
                                                </div>
                                                <div className={classes.upDown}
                                                    onClick={() =>{
                                                        if(selectedCustomer?.customerId === customer?.customerId && selectedCustomer?.currency === customer?.currency){
                                                            setSelectedCustomer({});
                                                        }else{
                                                            setSelectedCustomer({customerId:customer?.customerId, currency:customer?.currency})
                                                        }
                                                    }
                                                    }
                                                >
                                                    <img src={ selectedCustomer?.customerId === customer?.customerId && selectedCustomer?.currency === customer?.currency ? up : down } alt='image' />
                                                </div>
                                            </div>
                                        {  selectedCustomer?.customerId === customer?.customerId && selectedCustomer?.currency === customer?.currency ?
                                            <div className='w-[100%] font-inter p-[16px] flex flex-col item-start gap-[12px] rounded-[12px] border-[1px] border-[#ECEFF2]'>
                                                <div className='w-[100%] flex justify-between items-start self-stretch'>
                                                <div className='text-[#1E333F7F] text-[12px] font-[500] leading-[20px]'>
                                                    Overdue By 0-30
                                                </div>
                                                <div className='flex flex-col items-end justify-center w-[128px]'>
                                                        <div className='text-right text-[14px] font-[500] leading-[24px] text-[#1E333F]'>
                                                            {customer?.overdue0to30 === 0 ? '-' : customer?.currencyDTO?.sign + formatNumberToLocaleString(customer?.overdue0to30)}
                                                        </div>
                                                        <div className='text-right text-[14px] font-[500] leading-[24px] text-[#1e333f66]'>
                                                            {customer?.converted?.overdue0to30 === 0 ? '-' : customer?.converted?.currencyDTO?.sign + formatNumberToLocaleString(customer?.converted?.overdue0to30)}
                                                        </div>
                                                </div>
                                                </div>
                                                <div className='bg-[#ECEFF2] h-[1px] w-[100%]'></div>
                                                <div className='w-[100%] flex justify-between items-start self-stretch'>
                                                <div className='text-[#1E333F7F] text-[12px] font-[500] leading-[20px]'>
                                                    Overdue By 31-45
                                                </div>
                                                <div className='flex flex-col items-end justify-center w-[128px]'>
                                                        <div className='text-right text-[14px] font-[500] leading-[24px] text-[#1E333F]'>
                                                            {customer?.overdue31to45 === 0 ? '-' : customer?.currencyDTO?.sign + formatNumberToLocaleString(customer?.overdue31to45)}
                                                        </div>
                                                        <div className='text-right text-[14px] font-[500] leading-[24px] text-[#1e333f66]'>
                                                            {customer?.converted?.overdue31to45 === 0 ? '-' : customer?.converted?.currencyDTO?.sign + formatNumberToLocaleString(customer?.converted?.overdue31to45)}
                                                        </div>
                                                </div>
                                                </div>
                                                <div className='bg-[#ECEFF2] h-[1px] w-[100%]'></div>
                                                <div className='w-[100%] flex justify-between items-start self-stretch'>
                                                <div className='text-[#1E333F7F] text-[12px] font-[500] leading-[20px]'>
                                                    Overdue By 46-60
                                                </div>
                                                <div className='flex flex-col items-end justify-center w-[128px]'>
                                                        <div className='text-right text-[14px] font-[500] leading-[24px] text-[#1E333F]'>
                                                            {customer?.overdue46to60 === 0 ? '-' : customer?.currencyDTO?.sign + formatNumberToLocaleString(customer?.overdue46to60)}
                                                        </div>
                                                        <div className='text-right text-[14px] font-[500] leading-[24px] text-[#1e333f66]'>
                                                            {customer?.converted?.overdue46to60 === 0 ? '-' : customer?.converted?.currencyDTO?.sign + formatNumberToLocaleString(customer?.converted?.overdue46to60)}
                                                        </div>
                                                </div>
                                                </div>
                                                <div className='bg-[#ECEFF2] h-[1px] w-[100%]'></div>
                                                <div className='w-[100%] flex justify-between items-start self-stretch'>
                                                <div className='text-[#1E333F7F] text-[12px] font-[500] leading-[20px]'>
                                                    Overdue By 61+
                                                </div>
                                                <div className='flex flex-col items-end justify-center w-[128px]'>
                                                        <div className='text-right text-[14px] font-[500] leading-[24px] text-[#1E333F]'>
                                                            {customer?.overdue61to90 === 0 ? '-' : customer?.currencyDTO?.sign + formatNumberToLocaleString(customer?.overdue61to90)}
                                                        </div>
                                                        <div className='text-right text-[14px] font-[500] leading-[24px] text-[#1e333f66]'>
                                                            {customer?.converted?.overdue61to90 === 0 ? '-' : customer?.converted?.currencyDTO?.sign + formatNumberToLocaleString(customer?.converted?.overdue61to90)}
                                                        </div>
                                                </div>
                                                </div>
                                                <div className='bg-[#ECEFF2] h-[1px] w-[100%]'></div>
                                                <div className='w-[100%] flex justify-between items-start self-stretch'>
                                                <div className='text-[#1E333F7F] text-[12px] font-[500] leading-[20px]'>
                                                    Overdue By 91+
                                                </div>
                                                <div className='flex flex-col items-end justify-center w-[128px]'>
                                                        <div className='text-right text-[14px] font-[500] leading-[24px] text-[#1E333F]'>
                                                            {customer?.overdue91Plus === 0 ? '-' : customer?.currencyDTO?.sign + formatNumberToLocaleString(customer?.overdue91Plus)}
                                                        </div>
                                                        <div className='text-right text-[14px] font-[500] leading-[24px] text-[#1e333f66]'>
                                                            {customer?.converted?.overdue91Plus === 0 ? '-' : customer?.converted?.currencyDTO?.sign + formatNumberToLocaleString(customer?.converted?.overdue91Plus)}
                                                        </div>
                                                </div>
                                                </div>
                                                <div className='bg-[#ECEFF2] h-[1px] w-[100%]'></div>
                                                <div className='w-[100%] flex justify-between items-start self-stretch'>
                                                <div className='text-[#1E333F7F] text-[12px] font-[500] leading-[20px]'>
                                                    Total Overdue
                                                </div>
                                                <div className='flex flex-col items-end justify-center w-[128px]'>
                                                        <div className='text-right text-[14px] font-[500] leading-[24px] text-[#1E333F]'>
                                                            {customer?.totalOverdue === 0 ? '-' : customer?.currencyDTO?.sign + formatNumberToLocaleString(customer?.totalOverdue)}
                                                        </div>
                                                        <div className='text-right text-[14px] font-[500] leading-[24px] text-[#1e333f66]'>
                                                            {customer?.converted?.totalOverdue === 0 ? '-' : customer?.converted?.currencyDTO?.sign + formatNumberToLocaleString(customer?.converted?.totalOverdue)}
                                                        </div>
                                                </div>
                                                </div>
                                                <div className='bg-[#ECEFF2] h-[1px] w-[100%]'></div>
                                                <div className='w-[100%] flex justify-between items-start self-stretch'>
                                                <div className='text-[#1E333F7F] text-[12px] font-[500] leading-[20px]'>
                                                    Not Due
                                                </div>
                                                <div className='flex flex-col items-end justify-center w-[128px]'>
                                                        <div className='text-right text-[14px] font-[500] leading-[24px] text-[#1E333F]'>
                                                            {customer?.notDue === 0 ? '-' : customer?.currencyDTO?.sign + formatNumberToLocaleString(customer?.notDue)}
                                                        </div>
                                                        <div className='text-right text-[14px] font-[500] leading-[24px] text-[#1e333f66]'>
                                                            {customer?.converted?.notDue === 0 ? '-' : customer?.converted?.currencyDTO?.sign + formatNumberToLocaleString(customer?.converted?.notDue)}
                                                        </div>
                                                </div>
                                                </div>
                                                <div className='bg-[#ECEFF2] h-[1px] w-[100%]'></div>
                                                <div className='w-[100%] flex justify-between items-start self-stretch'>
                                                <div className='text-[#1E333F7F] text-[12px] font-[500] leading-[20px]'>
                                                    Total
                                                </div>
                                                <div className='flex flex-col items-end justify-center w-[128px]'>
                                                        <div className='text-right text-[14px] font-[500] leading-[24px] text-[#1E333F]'>
                                                            {customer?.total === 0 ? '-' : customer?.currencyDTO?.sign + formatNumberToLocaleString(customer?.total)}
                                                        </div>
                                                        <div className='text-right text-[14px] font-[500] leading-[24px] text-[#1e333f66]'>
                                                            {customer?.converted?.total === 0 ? '-' : customer?.converted?.currencyDTO?.sign + formatNumberToLocaleString(customer?.converted?.total)}
                                                        </div>
                                                </div>
                                                </div>
                                            </div>
                                            :
                                            <div className={classes.totalOverdueBox}>
                                            <div className={classes.totalOverdue}>
                                                <p className={classes.nameSub}>Total Overdue</p>
                                                <div className={classes.totalOverdueAmount}>
                                                    {customer?.total === 0 ? '-' : customer?.currencyDTO?.sign + formatNumberToLocaleString(customer?.total)}
                                                    {/* <p className={classes.amount}>$265.787</p> */}
                                                </div>
                                            </div>
                                        </div>
                                    }
                                            {/* <div className={classes.totalOverdueBox}>
                                                <div className={classes.totalOverdue}>
                                                    <p className={classes.nameSub}>Total Overdue</p>
                                                    <div className={classes.totalOverdueAmount}>
                                                        <p className={classes.amount}>$265.787</p>
                                                    </div>
                                                </div>
                                            </div> */}
                                        </div>
                                    </div> 
                                    {props?.customerData?.currencyWise[currency]?.length !== index + 1 && <hr className={classes.hr}/>}
                                </>
                        ))}
                        {props?.customerData?.subTotals[currency?.toUpperCase()] &&
                        (<div className={classes.subTotalCon}>
                            <div className={classes.subTotalBox}>
                                <div className={classes.subTotalName}>
                                    <div className={classes.name}>
                                        <p className={classes.nameMain}>{`Subtotal ${currency?.toUpperCase()}`}</p>
                                    </div>
                                    <div className={classes.upDown}
                                        onClick={()=> {
                                            if(selectedCurrency){
                                                setSelectedCurrency("")
                                            }
                                            else{
                                                setSelectedCurrency(currency)
                                            }
                                        } 
                                        }
                                    >
                                        <img src={selectedCurrency === currency ? up : down} alt='image' />
                                    </div>
                                </div>
                                {selectedCurrency === currency ?
                                <div className='w-[100%] font-inter bg-[#FFF] p-[16px] flex flex-col item-start gap-[12px] rounded-[12px] border-[1px] border-[#4B5C65]'>
                                    <div className='w-[100%] flex justify-between items-start self-stretch'>
                                        <div className='text-[#1E333F7F] text-[12px] font-[500] leading-[20px]'>
                                            Overdue By 0-30
                                        </div>
                                        <div className='flex flex-col items-end justify-center w-[128px]'>
                                            <div className='text-right text-[14px] font-[500] leading-[24px] text-[#1E333F]'>
                                                {props?.customerData?.subTotals[currency?.toUpperCase()]?.subtotalOverdue0to30 === 0 || null  ? '-' : props?.customerData?.currencyWise[currency].length &&  props?.customerData?.currencyWise[currency][0]?.currencyDTO?.sign + formatNumberToLocaleString((props?.customerData?.subTotals[currency?.toUpperCase()]?.subtotalOverdue0to30),2,2)}
                                            </div>
                                            <div className='text-right text-[14px] font-[500] leading-[24px] text-[#1e333f66]'>
                                                {props?.customerData?.subTotals[currency?.toUpperCase()]?.subtotalOverdue0to30INR === 0 || null  ? '-' : props?.customerData?.currencyWise[currency].length && props?.customerData?.currencyWise[currency][0]?.converted?.currencyDTO?.sign + formatNumberToLocaleString((props?.customerData?.subTotals[currency?.toUpperCase()]?.subtotalOverdue0to30INR),2,2)}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='bg-[#ECEFF2] h-[1px] w-[100%]'></div>
                                    <div className='w-[100%] flex justify-between items-start self-stretch'>
                                        <div className='text-[#1E333F7F] text-[12px] font-[500] leading-[20px]'>
                                            Overdue By 31-45
                                        </div>
                                        <div className='flex flex-col items-end justify-center w-[128px]'>
                                            <div className='text-right text-[14px] font-[500] leading-[24px] text-[#1E333F]'>
                                                {props?.customerData?.subTotals[currency?.toUpperCase()]?.subtotalOverdue31to45 === 0 || null  ? '-' : props?.customerData?.currencyWise[currency].length &&  props?.customerData?.currencyWise[currency][0]?.currencyDTO?.sign + formatNumberToLocaleString((props?.customerData?.subTotals[currency?.toUpperCase()]?.subtotalOverdue31to45),2,2)}
                                            </div>
                                            <div className='text-right text-[14px] font-[500] leading-[24px] text-[#1e333f66]'>
                                                {props?.customerData?.subTotals[currency?.toUpperCase()]?.subtotalOverdue31to45INR === 0 || null  ? '-' : props?.customerData?.currencyWise[currency].length && props?.customerData?.currencyWise[currency][0]?.converted?.currencyDTO?.sign + formatNumberToLocaleString((props?.customerData?.subTotals[currency?.toUpperCase()]?.subtotalOverdue31to45INR),2,2)}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='bg-[#ECEFF2] h-[1px] w-[100%]'></div>
                                    <div className='w-[100%] flex justify-between items-start self-stretch'>
                                        <div className='text-[#1E333F7F] text-[12px] font-[500] leading-[20px]'>
                                            Overdue By 46-60
                                        </div>
                                        <div className='flex flex-col items-end justify-center w-[128px]'>
                                            <div className='text-right text-[14px] font-[500] leading-[24px] text-[#1E333F]'>
                                                {props?.customerData?.subTotals[currency?.toUpperCase()]?.subtotalOverdue46to60 === 0 || null  ? '-' : props?.customerData?.currencyWise[currency].length && props?.customerData?.currencyWise[currency][0]?.currencyDTO?.sign + formatNumberToLocaleString((props?.customerData?.subTotals[currency?.toUpperCase()]?.subtotalOverdue46to60),2,2)}
                                            </div>
                                            <div className='text-right text-[14px] font-[500] leading-[24px] text-[#1e333f66]'>
                                                {props?.customerData?.subTotals[currency?.toUpperCase()]?.subtotalOverdue46to60INR === 0 || null  ? '-' : props?.customerData?.currencyWise[currency].length && props?.customerData?.currencyWise[currency][0]?.converted?.currencyDTO?.sign + formatNumberToLocaleString((props?.customerData?.subTotals[currency?.toUpperCase()]?.subtotalOverdue46to60INR),2,2)}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='bg-[#ECEFF2] h-[1px] w-[100%]'></div>
                                    <div className='w-[100%] flex justify-between items-start self-stretch'>
                                        <div className='text-[#1E333F7F] text-[12px] font-[500] leading-[20px]'>
                                            Overdue By 61+
                                        </div>
                                        <div className='flex flex-col items-end justify-center w-[128px]'>
                                            <div className='text-right text-[14px] font-[500] leading-[24px] text-[#1E333F]'>
                                                {props?.customerData?.subTotals[currency?.toUpperCase()]?.subtotalOverdue61to90 === 0 || null  ? '-' : props?.customerData?.currencyWise[currency].length && props?.customerData?.currencyWise[currency][0]?.currencyDTO?.sign + formatNumberToLocaleString((props?.customerData?.subTotals[currency?.toUpperCase()]?.subtotalOverdue61to90),2,2)}
                                            </div>
                                            <div className='text-right text-[14px] font-[500] leading-[24px] text-[#1e333f66]'>
                                                {props?.customerData?.subTotals[currency?.toUpperCase()]?.subtotalOverdue61to90INR === 0 || null  ? '-' : props?.customerData?.currencyWise[currency].length && props?.customerData?.currencyWise[currency][0]?.converted?.currencyDTO?.sign + formatNumberToLocaleString((props?.customerData?.subTotals[currency?.toUpperCase()]?.subtotalOverdue61to90INR),2,2)}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='bg-[#ECEFF2] h-[1px] w-[100%]'></div>
                                    <div className='w-[100%] flex justify-between items-start self-stretch'>
                                        <div className='text-[#1E333F7F] text-[12px] font-[500] leading-[20px]'>
                                            Overdue By 91+
                                        </div>
                                        <div className='flex flex-col items-end justify-center w-[128px]'>
                                            <div className='text-right text-[14px] font-[500] leading-[24px] text-[#1E333F]'>
                                                {props?.customerData?.subTotals[currency?.toUpperCase()]?.subtotalOverdue91Plus === 0 || null  ? '-' : props?.customerData?.currencyWise[currency].length && props?.customerData?.currencyWise[currency][0]?.currencyDTO?.sign + formatNumberToLocaleString((props?.customerData?.subTotals[currency?.toUpperCase()]?.subtotalOverdue91Plus),2,2)}
                                            </div>
                                            <div className='text-right text-[14px] font-[500] leading-[24px] text-[#1e333f66]'>
                                                {props?.customerData?.subTotals[currency?.toUpperCase()]?.subtotalOverdue91PlusINR === 0 || null  ? '-' : props?.customerData?.currencyWise[currency].length && props?.customerData?.currencyWise[currency][0]?.converted?.currencyDTO?.sign + formatNumberToLocaleString((props?.customerData?.subTotals[currency?.toUpperCase()]?.subtotalOverdue91PlusINR),2,2)}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='bg-[#ECEFF2] h-[1px] w-[100%]'></div>
                                    <div className='w-[100%] flex justify-between items-start self-stretch'>
                                        <div className='text-[#1E333F7F] text-[12px] font-[500] leading-[20px]'>
                                            Total Overdue
                                        </div>
                                        <div className='flex flex-col items-end justify-center w-[128px]'>
                                            <div className='text-right text-[14px] font-[500] leading-[24px] text-[#1E333F]'>
                                                {props?.customerData?.subTotals[currency?.toUpperCase()]?.subtotalTotalOverdue === 0 || null  ? '-' : props?.customerData?.currencyWise[currency].length && props?.customerData?.currencyWise[currency][0]?.currencyDTO?.sign + formatNumberToLocaleString((props?.customerData?.subTotals[currency?.toUpperCase()]?.subtotalTotalOverdue),2,2)}
                                            </div>
                                            <div className='text-right text-[14px] font-[500] leading-[24px] text-[#1e333f66]'>
                                                {props?.customerData?.subTotals[currency?.toUpperCase()]?.subtotalTotalOverdueINR === 0 || null  ? '-' : props?.customerData?.currencyWise[currency].length && props?.customerData?.currencyWise[currency][0]?.converted?.currencyDTO?.sign + formatNumberToLocaleString((props?.customerData?.subTotals[currency?.toUpperCase()]?.subtotalTotalOverdueINR),2,2)}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='bg-[#ECEFF2] h-[1px] w-[100%]'></div>
                                    <div className='w-[100%] flex justify-between items-start self-stretch'>
                                        <div className='text-[#1E333F7F] text-[12px] font-[500] leading-[20px]'>
                                            Not Due
                                        </div>
                                        <div className='flex flex-col items-end justify-center w-[128px]'>
                                            <div className='text-right text-[14px] font-[500] leading-[24px] text-[#1E333F]'>
                                                {props?.customerData?.subTotals[currency?.toUpperCase()]?.subtotalNotDue === 0 || null  ? '-' : props?.customerData?.currencyWise[currency].length && props?.customerData?.currencyWise[currency][0]?.currencyDTO?.sign + formatNumberToLocaleString((props?.customerData?.subTotals[currency?.toUpperCase()]?.subtotalNotDue),2,2)}
                                            </div>
                                            <div className='text-right text-[14px] font-[500] leading-[24px] text-[#1e333f66]'>
                                                {props?.customerData?.subTotals[currency?.toUpperCase()]?.subtotalNotDueINR === 0 || null  ? '-' : props?.customerData?.currencyWise[currency].length && props?.customerData?.currencyWise[currency][0]?.converted?.currencyDTO?.sign + formatNumberToLocaleString((props?.customerData?.subTotals[currency?.toUpperCase()]?.subtotalNotDueINR),2,2)}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='bg-[#ECEFF2] h-[1px] w-[100%]'></div>
                                    <div className='w-[100%] flex justify-between items-start self-stretch'>
                                        <div className='text-[#1E333F7F] text-[12px] font-[500] leading-[20px]'>
                                            Total
                                        </div>
                                        <div className='flex flex-col items-end justify-center w-[128px]'>
                                            <div className='text-right text-[14px] font-[500] leading-[24px] text-[#1E333F]'>
                                                {props?.customerData?.subTotals[currency?.toUpperCase()]?.subtotalTotal === 0 || null  ? '-' : props?.customerData?.currencyWise[currency].length && props?.customerData?.currencyWise[currency][0]?.currencyDTO?.sign + formatNumberToLocaleString((props?.customerData?.subTotals[currency?.toUpperCase()]?.subtotalTotal),2,2)}
                                            </div>
                                            <div className='text-right text-[14px] font-[500] leading-[24px] text-[#1e333f66]'>
                                                {props?.customerData?.subTotals[currency?.toUpperCase()]?.subtotalTotalINR === 0 || null  ? '-' : props?.customerData?.currencyWise[currency].length && props?.customerData?.currencyWise[currency][0]?.converted?.currencyDTO?.sign + formatNumberToLocaleString((props?.customerData?.subTotals[currency?.toUpperCase()]?.subtotalTotalINR),2,2)}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                <div className={classes.subtotalOverdueBox}>
                                    <div className={classes.totalOverdue}>
                                        <div className='w-[100%] flex justify-between items-start self-stretch'>
                                            <div className='text-[#1E333F7F] text-[12px] font-[500] leading-[20px]'>
                                                Total Overdue
                                            </div>
                                            <div className='flex flex-col items-end justify-center w-[128px]'>
                                                <div className='text-right text-[14px] font-[500] leading-[24px] text-[#1E333F]'>
                                                    {props?.customerData?.subTotals[currency?.toUpperCase()]?.subtotalTotalOverdue === 0 || null  ? '-' : props?.customerData?.currencyWise[currency]?.length && props?.customerData?.currencyWise[currency][0]?.currencyDTO?.sign + formatNumberToLocaleString((props?.customerData?.subTotals[currency?.toUpperCase()]?.subtotalTotalOverdue),2,2)}
                                                </div>
                                                {/* <div className='text-right text-[14px] font-[500] leading-[24px] text-[#1e333f66]'>
                                                    {props?.customerData?.subTotals[currency?.toUpperCase()]?.subtotalTotalOverdueINR === 0 || null  ? '-' : props?.customerData?.currencyWise[currency][0]?.converted?.currencyDTO?.sign + formatNumberToLocaleString((props?.customerData?.subTotals[currency?.toUpperCase()]?.subtotalTotalOverdueINR),2,2)}
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                }
                            </div>
                        </div>)}
                    </div>
                </div>))
                
            )
        }

        {!props?.allEmpty && (<div className={classes.grandTotalCon}>
            <div className={classes.grandTotal}>
                <div className={classes.grandTotalBox} onClick={handleGrandTotal}>
                    <div className={classes.grandTotalName}>
                        <p className={classes.grandName}>Grand Total</p>
                    </div>
                    <div className={classes.grandTotalAmount}>
                        {console.log('vbvb', props?.customerData?.grandTotals?.subtotalTotalINR)}
                        <div className={classes.grandTotalAmountBox}>
                            <p className={classes.amountUsd}>
                                {props?.customerData?.grandTotals?.subtotalTotal === 0 || props?.customerData?.grandTotals?.subtotalTotal === null ? '-' :
                                    formatNumberToLocaleString(props?.customerData?.grandTotals?.subtotalTotal, 2, 2)}
                            </p>
                            <p className={classes.amountInr}>
                                {props?.customerData?.grandTotals?.subtotalTotalINR === 0 || props?.customerData?.grandTotals?.subtotalTotalINR === null ? '-' :
                                    (props?.customerData?.currencyWise['USD'] && props?.customerData?.currencyWise['USD'][0]?.converted?.currencyDTO?.sign
                                        ? props?.customerData?.currencyWise['USD'][0]?.converted?.currencyDTO?.sign + formatNumberToLocaleString(props?.customerData?.grandTotals?.subtotalTotalINR, 2, 2)
                                        : '-')}
                            </p>
                        </div>
                        <div className={classes.grandImg}>
                            <img src={grandAlert} alt='grandImg'/>
                        </div>
                    </div>
                </div>
            </div>
        </div>)}
            {openGrand &&
                <div className={classes.grandMask}>
                    <div className={classes.grandMaskCon} ref={optRef}>
                        <div className={classes.grandTop} onClick={handleGrandTotal}>
                            <div className={classes.grandTopCon}>
                                <p className={classes.grandText}>Grand Total</p>
                                <img src={crossImg} alt='close'/>
                            </div>
                        </div>
                        <div className={classes.grandBottom}>
                            <div className={classes.overdueValueCon}>
                                <p className={classes.overdueText}>Overdue By 0-30</p>
                                <div className={classes.overdueAmountBox}>
                                <p className={classes.overdueUsd}>
                                    {props?.customerData?.grandTotals?.subtotalOverdue0to30 === 0 || null  ? '-' :  formatNumberToLocaleString((props?.customerData?.grandTotals?.subtotalOverdue0to30),2,2)}
                                </p>
                                <p className={classes.overdueInr}>
                                    {props?.customerData?.grandTotals?.subtotalOverdue0to30INR === 0 || null  ? '-' : props?.customerData?.currencyWise['USD']?.length && props?.customerData?.currencyWise['USD'][0]?.converted?.currencyDTO?.sign + formatNumberToLocaleString((props?.customerData?.grandTotals?.subtotalOverdue0to30INR),2,2)}
                                </p>
                            </div>
                        </div>
                        {
                        // props?.customerData?.currencyWise[currency]?.length !== index + 1 && 
                        <hr className={classes.hr}/>
                        }

                        <div className={classes.overdueValueCon}>
                            <p className={classes.overdueText}>Overdue By 31-45</p>
                            <div className={classes.overdueAmountBox}>
                                <p className={classes.overdueUsd}>
                                    {props?.customerData?.grandTotals?.subtotalOverdue31to45 === 0 || null  ? '-' :  formatNumberToLocaleString((props?.customerData?.grandTotals?.subtotalOverdue31to45 || 0),2,2)}
                                </p>
                                <p className={classes.overdueInr}>
                                    {props?.customerData?.grandTotals?.subtotalOverdue31to45INR === 0 || null  ? '-' : props?.customerData?.currencyWise['USD']?.length && props?.customerData?.currencyWise['USD'][0]?.converted?.currencyDTO?.sign + formatNumberToLocaleString((props?.customerData?.grandTotals?.subtotalOverdue31to45INR),2,2)}
                                </p>
                            </div>
                        </div>
                        <hr className={classes.hr}/>
                        <div className={classes.overdueValueCon}>
                            <p className={classes.overdueText}>Overdue By 46-60</p>
                            <div className={classes.overdueAmountBox}>
                                <p className={classes.overdueUsd}>
                                    {props?.customerData?.grandTotals?.subtotalOverdue46to60 === 0 || null  ? '-' :  formatNumberToLocaleString((props?.customerData?.grandTotals?.subtotalOverdue46to60),2,2)}
                                </p>
                                <p className={classes.overdueInr}>
                                    {props?.customerData?.grandTotals?.subtotalOverdue46to60INR === 0 || null  ? '-' : props?.customerData?.currencyWise['USD']?.length && props?.customerData?.currencyWise['USD'][0]?.converted?.currencyDTO?.sign + formatNumberToLocaleString((props?.customerData?.grandTotals?.subtotalOverdue46to60INR),2,2)}
                                </p>
                            </div>
                        </div>
                        <hr className={classes.hr}/>
                        <div className={classes.overdueValueCon}>
                            <p className={classes.overdueText}>Overdue By 61+</p>
                            <div className={classes.overdueAmountBox}>
                                <p className={classes.overdueUsd}>
                                    {props?.customerData?.grandTotals?.subtotalOverdue61to90 === 0 || null  ? '-' :  formatNumberToLocaleString((props?.customerData?.grandTotals?.subtotalOverdue61to90),2,2)}
                                </p>
                                <p className={classes.overdueInr}>
                                    {props?.customerData?.grandTotals?.subtotalOverdue61to90INR === 0 || null  ? '-' : props?.customerData?.currencyWise['USD']?.length && props?.customerData?.currencyWise['USD'][0]?.converted?.currencyDTO?.sign + formatNumberToLocaleString((props?.customerData?.grandTotals?.subtotalOverdue61to90INR),2,2)}
                                </p>
                            </div>
                        </div>
                        <hr className={classes.hr}/>
                        <div className={classes.overdueValueCon}>
                            <p className={classes.overdueText}>Overdue By 91+</p>
                            <div className={classes.overdueAmountBox}>
                                <p className={classes.overdueUsd}>
                                    {props?.customerData?.grandTotals?.subtotalOverdue91Plus === 0 || null  ? '-' :  formatNumberToLocaleString((props?.customerData?.grandTotals?.subtotalOverdue91Plus),2,2)}
                                </p>
                                <p className={classes.overdueInr}>
                                    {props?.customerData?.grandTotals?.subtotalOverdue91PlusINR === 0 || null  ? '-' : props?.customerData?.currencyWise['USD']?.length && props?.customerData?.currencyWise['USD'][0]?.converted?.currencyDTO?.sign + formatNumberToLocaleString((props?.customerData?.grandTotals?.subtotalOverdue91PlusINR),2,2)}
                                </p>
                            </div>
                        </div>
                        <hr className={classes.hr}/>
                        <div className={classes.overdueValueCon}>
                            <p className={classes.overdueText}>Total Overdue</p>
                            <div className={classes.overdueAmountBox}>
                                <p className={classes.overdueUsd}>
                                    {props?.customerData?.grandTotals?.subtotalTotalOverdue === 0 || null  ? '-' :  formatNumberToLocaleString((props?.customerData?.grandTotals?.subtotalTotalOverdue),2,2)}
                                </p>
                                <p className={classes.overdueInr}>
                                    {props?.customerData?.grandTotals?.subtotalTotalOverdueINR === 0 || null  ? '-' : props?.customerData?.currencyWise['USD']?.length && props?.customerData?.currencyWise['USD'][0]?.converted?.currencyDTO?.sign + formatNumberToLocaleString((props?.customerData?.grandTotals?.subtotalTotalOverdueINR),2,2)}
                                </p>
                            </div>
                        </div>
                        <hr className={classes.hr}/>
                        <div className={classes.overdueValueCon}>
                            <p className={classes.overdueText}>Not Due</p>
                            <div className={classes.overdueAmountBox}>
                                <p className={classes.overdueUsd}>
                                    {props?.customerData?.grandTotals?.subtotalNotDue === 0 || null  ? '-' :  formatNumberToLocaleString((props?.customerData?.grandTotals?.subtotalNotDue),2,2)}
                                </p>
                                <p className={classes.overdueInr}>
                                    {props?.customerData?.grandTotals?.subtotalNotDueINR === 0 || null  ? '-' : props?.customerData?.currencyWise['USD']?.length && props?.customerData?.currencyWise['USD'][0]?.converted?.currencyDTO?.sign + formatNumberToLocaleString((props?.customerData?.grandTotals?.subtotalNotDueINR),2,2)}
                                </p>
                            </div>
                        </div>
                        <hr className={classes.hr}/>
                        <div className={classes.overdueValueCon}>
                            <p className={classes.overdueText}>Total</p>
                            <div className={classes.overdueAmountBox}>
                                <p className={classes.overdueUsd}>
                                    {props?.customerData?.grandTotals?.subtotalTotal === 0 || null  ? '-' :  formatNumberToLocaleString((props?.customerData?.grandTotals?.subtotalTotal),2,2)}
                                </p>
                                <p className={classes.overdueInr}>
                                    {props?.customerData?.grandTotals?.subtotalTotalINR === 0 || null  ? '-' : props?.customerData?.currencyWise['USD']?.length && props?.customerData?.currencyWise['USD'][0]?.converted?.currencyDTO?.sign + formatNumberToLocaleString((props?.customerData?.grandTotals?.subtotalTotalINR),2,2)}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        }

    </div>
    {(props?.allEmpty && !props.loading) && 
        (<div
        style={{
            textAlign: 'center',
            width: '100%',
            marginTop: '-21rem',
            fontSize: '2.5rem',
        }}
        >
        No Customer Dues Yet.
        </div>)
    }
    </>
    
  )
}

export default MobileCustomerOverview