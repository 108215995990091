import React, { useEffect, useState } from "react";
import messageIcon from "assets/Icons/message-square.svg";
import ChatModal from "./ChatModal";
import {
    apiDeactivateChatbot,
    apiConversationChatbot
} from "services";
import UseApiCall from "hooks/useApiCall";
import { useDispatch, useSelector } from "react-redux";
import { modalActions } from "Store/modal-redux";

import classes from "./ChatBot.module.scss";

const ChatBot = ({ }) => {
    const dispatch = useDispatch();
    const [showModal, setShowModal] = useState(false);
    const [killThread, setKillThread] = useState(false);
    const chatBotHistory = useSelector((state) => state.modal.chatBotHistory)
    const [messages, setMessages] = useState([
        { attachments: [], type: "admin", status: "", action: "", message: "Welcome! \nHow can I help you today?", doc_format: "", file_url: "", missingFields: [], data: null }
    ]);

    const [callDeactivateChatbot] = UseApiCall(
        apiDeactivateChatbot,
        (res) => {
        }, (err) => {
            console.error("Error in killing the thread : ", err);
        }
    )

    const [callConversationChatbot] = UseApiCall(
        apiConversationChatbot,
        (res) => {
            console.log(res?.conversation, "Paaaablooooooo");
            dispatch(modalActions.chatBotHistory(res?.conversation));

            let chatData = [];

            res?.conversation.forEach(item => {
                let newObject = {
                    attachments: [],
                    type: "bot",
                    status: "",
                    action: "",
                    message: "Welcome! \nHow can I help you today?",
                    doc_format: "",
                    file_url: "",
                    missingFields: [],
                    data: null
                };
                if (item.sentBy === "USER") {
                    newObject.type = "user"
                    newObject.message = JSON.parse(item?.message)?.message;
                } else if (item.sentBy === "BOT") {
                    newObject = { ...newObject, ...JSON.parse(item?.message) };
                    // if (newObject?.data?._children) newObject.data = null;
                }

                try {
                    newObject.missingFields = Array.isArray(newObject?.missingFields) ? (newObject?.missingFields?.length > 0 ? JSON.parse(newObject?.missingFields[0]) : []) : JSON.parse(newObject?.missingFields);
                } catch (error) {
                    newObject.missingFields = [];
                    console.error("Error parsing missingFields:", error);
                }

                if (item.filePath !== null) {
                    newObject.file_url = item.filePath;
                }
                chatData.push(newObject);
            });

            // Log the new object
            console.log(chatData);

            setMessages(prevMessages => [...prevMessages, ...chatData]);
        }, (err) => {
            console.error("Error while fetching chats : ", err);
        }
    )

    console.log(messages, killThread, "Paaaablooooooo");

    useEffect(() => {
        if (showModal && chatBotHistory === null) callConversationChatbot();
    }, [showModal])

    useEffect(() => {
        if (killThread) {
            setMessages([
                { attachments: [], type: "admin", status: "", action: "", message: "Welcome! \nHow can I help you today?", doc_format: "", file_url: "", missingFields: [], data: null }
            ])
            callDeactivateChatbot();
        }
    }, [killThread])

    return (
        <div>
            <div className={classes.iframe} style={{ display: showModal ? "none" : "" }}>
                <button
                    className={classes.button}
                    onClick={() => {
                        setShowModal(!showModal);
                        setKillThread(false);
                    }}
                >
                    <img src={messageIcon} alt="message-icon" className={classes.icon} />
                </button>
            </div>
            {showModal &&
                <ChatModal
                    onClose={() => setShowModal(false)}
                    messages={messages}
                    setMessages={setMessages}
                    onKill={() => {
                        setKillThread(true);
                        setShowModal(false);
                    }}
                />
            }
        </div>
    );
};

export default ChatBot;
