// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Search_search__Bhbx6 {
  border: 1px solid #D2D6D9;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  padding: 1.2rem 1.6rem;
  background-color: white;
  z-index: 0;
}
.Search_search__Bhbx6 .Search_icon__dOXZ2 {
  margin-right: 0.8rem;
  height: 2.4rem;
  width: 2.4rem;
  transition: all 1s linear;
}
.Search_search__Bhbx6 .Search_input__V5dxT {
  border: none;
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-weight: 400;
  width: 100%;
}
.Search_search__Bhbx6 .Search_input__V5dxT::placeholder {
  color: rgba(30, 51, 63, 0.5);
}
.Search_search__Bhbx6 .Search_input__V5dxT:focus {
  outline: none;
}`, "",{"version":3,"sources":["webpack://./src/Components/Global/Search.module.scss"],"names":[],"mappings":"AAAA;EACI,yBAAA;EACA,mBAAA;EACA,aAAA;EACA,mBAAA;EACA,sBAAA;EACA,uBAAA;EACA,UAAA;AACJ;AAEI;EACI,oBAAA;EACA,cAAA;EACA,aAAA;EACA,yBAAA;AAAR;AAGI;EACI,YAAA;EACA,iBAAA;EACA,mBAAA;EACA,gBAAA;EACA,WAAA;AADR;AAGQ;EACI,4BAAA;AADZ;AAIQ;EACI,aAAA;AAFZ","sourcesContent":[".search{\n    border: 1px solid #D2D6D9;\n    border-radius: 1rem;\n    display: flex;\n    align-items: center;\n    padding: 1.2rem 1.6rem;\n    background-color: white;\n    z-index: 0;\n    \n\n    .icon{\n        margin-right: 0.8rem;\n        height: 2.4rem;\n        width: 2.4rem;\n        transition: all 1s linear;\n    }\n\n    .input{\n        border: none;\n        font-size: 1.6rem;\n        line-height: 2.4rem;\n        font-weight: 400;\n        width: 100%;\n       \n        &::placeholder{\n            color: rgba(30, 51, 63, 0.5);\n        }\n\n        &:focus{\n            outline: none;\n\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"search": `Search_search__Bhbx6`,
	"icon": `Search_icon__dOXZ2`,
	"input": `Search_input__V5dxT`
};
export default ___CSS_LOADER_EXPORT___;
