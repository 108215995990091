import { useState, useEffect, memo, forwardRef } from 'react'
import { useSnackbar } from 'notistack'
import ReactDOM from "react-dom";
import styles from './FileViewer.module.css'
import DownloadIcon from 'assets/Icons/download-cloud.svg'
// import ViewIcon from 'assets/Images/search.svg'
import BgIcon from 'assets/Icons/bg-file-uploader.png'
import FideoLogo from 'assets/Icons/fideo-logo-faded.svg'
import otpLock from 'assets/Icons/otpLock.svg'
import clsx from 'clsx'
import { Document, Page, pdfjs } from 'react-pdf'
import EnterOtp from 'Components/otpScreen/EnterOtp'
import EnterOtpInner from 'Components/otpInnerScreen/EnterOtp'
import TypeOtp from 'Components/otpScreen/TypeOtp'
import invoice1 from 'assets/Images/invoice1.png';
import invoice2 from 'assets/Images/invoice2.png';
import invoice3 from 'assets/Images/invoice3.png';
import ZoomButtonsModal from '../../atom/FileViewer/ZoomFile'
// import Backdrop from 'Layout/Backdrop'
import { useDispatch, useSelector } from 'react-redux'
import { modalActions } from 'Store/modal-redux'
// import { handleDecrypt } from "hooks/handleDecryption";
// import { doEncryption } from 'constants';
 
const validTypes = {
  image: ['image/jpeg', 'image/jpg', 'image/png'],
  video: ['video/mp4', 'video/mov', 'video/quicktime'],
  text: ['text/csv'],
  document: ['application/pdf'],
}

const FileViewer = forwardRef((props, ref) => {
  const {
    format = [],
    displayUrls = [],
    mobileView = false,
    downloadPrefix = 'invoice',
    otpViewNowBox,
    setOtpViewNowBox,
    setPaymentLinkOtpRequired,
    paymentLinkOtpRequired,
    showInnerTrue,
    handleResendOTPView,otpErrorState,presignedUrlErrorState,invoiceData,otp,setOtp,handleOTPView,handleOTPViewNow,
  } = props
  const [providedUrl, setProvidedUrl] = useState(displayUrls)
  const [providedUrlDumy, setProvidedUrlDumy] = useState(true)
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [zoomIndex, setZoomIndex] = useState(1)
  const [key, setKey] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useDispatch()
  const showFileDisplay = useSelector((state) => state.modal.showFileDisplay)

  let displayUrlsDumy = [
    {
    type: "image/png",
    url: invoice1,
    },
    {
    type: "image/png",
    url: invoice2,
    },
    {
    type: "image/png",
    url: invoice3,
    }
  ]

  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`
  }, [])

  useEffect(() => {
    setSelectedIndex(0)
  }, [providedUrl,providedUrlDumy])
  useEffect(() => {
    setProvidedUrl(displayUrls)
    setZoomIndex(0)
  }, [displayUrls,providedUrlDumy])

  const renderBigFile = (file) => {
    const { type, url } = file
    if (paymentLinkOtpRequired || otpViewNowBox) {
      return <EnterOtpInner handleOTPView={handleOTPView} setOtpViewNowBox={setOtpViewNowBox} setPaymentLinkOtpRequired={setPaymentLinkOtpRequired} />
    }
    switch (true) {
      case type.includes('image'):
        return <img src={providedUrl[parseInt(localStorage.getItem('zoomkaindex')) || 0].url} alt="invoice" 
        className='w-[300px] h-[350px] mb-[10px]'
        onClick={() => {
          openZoomModal()
        }}
        style={{ cursor: "zoom-in" }}
        // className="w-[50px] h-[64px]"
        // style={paymentLinkOtpRequired ? { filter: 'blur(0px)' } : {}}
        />

      case type.includes('application'):
        return (
          <div className="w-[300px] h-[350px] mb-[10px]" 
          // style={paymentLinkOtpRequired ? { filter: 'blur(0px)' } : {}}
          style={{ cursor: "zoom-in" }}
          >
            <Document
              file={url}
              className={clsx('w-full h-full', styles.documentFull)}
              // style={{ cursor: "pointer" }}
              loading={""}
            >
              <Page
                pageNumber={1}
                className={clsx('w-full h-full', styles.pdfViewerSmall)}
                style={{
                  minWidth: '100% !important',
                  minHeight: '100% !important',
                }}
                onClick={() => {
                  openZoomModal()
                }}
                renderTextLayer={false}
                loading={""}
              />
            </Document>
          </div>
        )
      default:
        return <img src={url} alt="invoice" className='w-[300px] h-[350px]' />
    }
  }

  const renderSmallFile = (file) => {
    const { type, url } = file
    switch (true) {
      case type.includes('image'):
        return (
          <img
            src={url}
            alt="invoice"
            className="w-[50px] rounded-xl h-[64px]"
            // style={{ cursor: "pointer" }}
            // style={paymentLinkOtpRequired ? { filter: 'blur(0px)' } : {}}
          />
        )
      case type.includes('application'):
        return (
          <div className="w-[50px] h-[64px]">
            <Document
              file={url}
              className={clsx('w-[50px] h-[64px]', styles.documentFull)}
              // style={paymentLinkOtpRequired ? { filter: 'blur(0px)' } : {}}
              loading={""}
            >
              <Page
                pageNumber={1}
                className={clsx('w-[50px] h-[64px]', styles.pdfViewerSmall)}
                style={{
                  minWidth: '100% !important',
                  minHeight: '100% !important',
                }}
                renderTextLayer={false}
                loading={""}
              />
            </Document>
          </div>
        )
      default:
        return <img src={url} alt="invoice" className="w-[50px] h-[64px]" />
    }
  }

  const openZoomModal = () => {
      dispatch(modalActions.showFileDisplay())
  };

  return (
    <>
      <div className={styles.container}>
        <div className="w-full relative backdrop-blur-lg">
          {showFileDisplay && providedUrl[zoomIndex] &&
            ReactDOM.createPortal(
              <ZoomButtonsModal
                fileUrl={providedUrl[parseInt(localStorage.getItem('zoomkaindex')) || 0]}
              />
              ,document.getElementById("modal-root")
          )
          }
          {!mobileView && (
            <div
              className={clsx(styles.dropContainer, 'relative h-[50vh] backdrop-blur-lg')}
              style={{
                backgroundImage: `url(${BgIcon})`,
                backgroundRepeat: 'repeat',
              }}
            >
              {providedUrl[parseInt(localStorage.getItem('zoomkaindex')) || 0] && (
                <>
                  <a
                    download={`${downloadPrefix}_${
                      parseInt(localStorage.getItem('zoomkaindex')) || 0 + 1
                    }_downloads.${
                      providedUrl[parseInt(localStorage.getItem('zoomkaindex')) || 0].type.split('/')[1]
                    }`}
                    href={providedUrl[parseInt(localStorage.getItem('zoomkaindex')) || 0].url}
                    className="absolute top-5 right-5 z-2000"
                    style={paymentLinkOtpRequired ? { filter: 'blur(4px)' } : { zIndex: "2000" }}
                  >
                    <img src={DownloadIcon} alt="download" style={{ zIndex: "2000" }} />
                  </a>
                  {/* {window?.innerWidth > 640 &&
                    <div className="absolute top-16 right-5" style={{ zIndex: "1000" }} onClick={openZoomModal}>
                      <img src={ViewIcon} alt="view" />
                    </div>
                  } */}
                  {renderBigFile(providedUrl[parseInt(localStorage.getItem('zoomkaindex')) || 0])}
                  <div className="w-[100%] min-h-[300px]  justify-center items-center absolute t-[20px] l-[0px] flex  z-10"
                    style={paymentLinkOtpRequired ? { filter: 'blur(0px)' } : { zIndex: '-10' }}
                  >
                    {otpViewNowBox && showInnerTrue && <TypeOtp handleResendOTPView={handleResendOTPView}  otpErrorState={otpErrorState} presignedUrlErrorState={presignedUrlErrorState} invoiceData={invoiceData} otp={otp} setOtp={setOtp} handleOTPView={handleOTPView} handleOTPViewNow={handleOTPViewNow} setOtpViewNowBox={setOtpViewNowBox} setPaymentLinkOtpRequired={setPaymentLinkOtpRequired}/>}
                    {paymentLinkOtpRequired && showInnerTrue && <EnterOtp handleOTPView={handleOTPView} setOtpViewNowBox={setOtpViewNowBox} setPaymentLinkOtpRequired={setPaymentLinkOtpRequired} />}
                  </div>
                  <div className="absolute z-1 bottom-3 flex flex-row items-center gap-2">
                    <p className="text-2xl font-medium text-[#1E333F4D]">
                      Powered by
                    </p>
                    <img src={FideoLogo} className="w-18 h-6" alt="Fideo" />
                  </div>
                </>
              )}
            </div>
          )}
          <div
            className={clsx(
              'rounded-[8px] min-h-[60px] flex flex-row overflow-auto gap-5 rounded-t-none  py-5 bg-transparent  w-full',
              mobileView
                ? ''
                : 'px-5 border-dashed border border-t-0 border-[#D2D6D9] overflow-x-scroll'
            )}
            // key={key}
          >
          
            {providedUrl?.map((file, index) => (
              <>
                <div
                key={index}
                  onClick={() => {
                    setKey(!key)
                    setSelectedIndex(index)
                    setZoomIndex(index)
                    localStorage.setItem('zoomkaindex', index.toString());
                    dispatch(modalActions.showFileDisplay(false))
                  }}
                  className={clsx(
                    `flex relative min-w-[80px] h-[80px]  gap-2 rounded-xl flex-col border-solid border border-[#060A0D33] items-center justify-center py-1 px-2 cursor-pointer`,
                    (((parseInt(localStorage.getItem('zoomkaindex')) || 0) === index)) ? 'border-[#1E333F]' : 'border-[#060A0D33]'
                  )}
                  style={paymentLinkOtpRequired ? { backdropFilter : 'blur(4px)' } : {}}
                >
                  {renderSmallFile(file)}
                    {/* {console.log('firstIndex',index)} */}
                  <div className="w-[40px] h-[65px] justify-center items-center absolute t-0 l-0 flex  z-10 "
                    // style={paymentLinkOtpRequired ? { backdropFilter: 'blur(4px)' } : {}}
                    // onClick={ paymentLinkOtpRequired ? handleOTPView  : null}
                  >
                    {paymentLinkOtpRequired && <img src={otpLock} alt="lock" className="w-10 h-10" />}
                    {/* {paymentLinkOtpRequired && <EnterOtp setOtpViewNowBox={setOtpViewNowBox} setPaymentLinkOtpRequired={setPaymentLinkOtpRequired}/> } */}
                  </div>
                </div>
              </>
            ))}
          </div>
        </div>

        {/* <div className={styles.fileDisplayContainer}>
          {providedUrl
            ? fileDetailsFromUrl(providedUrl)
            : selectedFiles.map((data) => fileDetails(data))}
        </div> */}
      </div>
    </>
  )
})

export default memo(FileViewer)