import React, { useMemo } from "react";
import ReactDOM from 'react-dom'
import classes from "./Reconcilation.module.scss"
import ReconcilationTable from "Components/ReportTable/reconTable";
import MobileReconTable from "Components/ReportTable/MobileReconTable";
import xIcon from "assets/Icons/xIcon.svg";
import { useState,useEffect } from "react";
import { useSelector } from "react-redux";
import clsx from "clsx";
import Button from 'Layout/Button'
import { formatNumberToLocaleString } from "hooks/formatNumberToLocaleString";
import { modalRefState } from 'recoil/layoutRef'
import { showReconSuccess } from "utils/layout";
import reconSuccessIcon from 'assets/Icons/reconSuccessIcon.svg'
import reconPartialSuccessIcon from 'assets/Icons/reconPartialSuccess.svg'
import down from 'assets/Icons/chevron-down.svg';
import up from 'assets/Icons/chevron-up.svg';
import { useRecoilValue } from 'recoil'
import alertIcon from 'assets/Icons/alert-octagon.svg'
import NormalAction from 'Components/ReportTable/NormalAction'
import { useDispatch } from 'react-redux'
import { modalActions } from 'Store/modal-redux'
import { Tooltip } from "antd";
import {
  apiGetReconcilationCustomerList,
  apiGetReconcilationData,
  apiSendReconcilationData,
} from "services";
import UseApiCall from "hooks/useApiCall";
import { BankAccountActions } from "../../Store/bank-redux";
import { enqueueSnackbar } from "notistack";
import useClickOutside from "hooks/useClickOutside";
import Shimmer from "Layout/Shimmer";
import axios from "axios";
import { baseUrl } from "constants/Network";
import { Modal } from 'antd';
import { handleDecrypt } from "hooks/handleDecryption";
import { doEncryption } from 'constants';

const Reconcilation = (props) => {
  const dispatch = useDispatch()
  const modalRef = useRecoilValue(modalRefState)
  const [screenWidth, setScreenWidth] = useState(getCurrentDimension());
  const invoiceTooltipDisplay = useSelector((state) => state.modal.invoiceTooltipDisplay);
  const reconcilationDetailsTableData = useSelector((state) => state.bankAccount.reconcilationDetails);

  function getCurrentDimension() {
    return  window.innerWidth
  }

  useEffect(() => {
    const updateDimension = () => {
      setScreenWidth(getCurrentDimension());
    };
    window.addEventListener("resize", updateDimension);
    return () => {
      window.removeEventListener("resize", updateDimension);
    };
  }, [screenWidth]);

  const [inputError, setInputError] = useState(false);
  const [selectedInvoices, setSelectedInvoices] = useState([])
  const [filterInvoices, setFilterInvoices] = useState([])
  const mobileTableData = useSelector((state) => state.receivable.invoice_list)
  const [amountToBeReconciled, setAmountToBeReconciled] = useState(0);
  const [amountToBeReconciledForModal, setAmountToBeReconciledForModal] = useState(0);
  const [reconciledAmountSign, setReconciledAmounSign] = useState('');
  const [partialAmount, setPartialAmount] = useState(0.00);
  const [totalBalance, setTotalBalance] = useState(null);
  const [partialCount, setPartialCount] = useState(0);
  const [paymentTypes, setPaymentTypes] = useState('completed');
  const [inputNegativeError, setInputNegativeError] = useState(false);
  const [availableBalanceValue, setAvailableBalanceValue] = useState(0);
  const [orangeFooter, setOrangeFooter] = useState(false);
  const [errorOccured, setErrorOccured] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(true);
  const [currencyCodeValue, setCurrencyCodeValue] = useState(props.selectedAccountDetails?.currencyDTO?.code);
  const [currencyCodeSign, setCurrencyCodeSign] = useState(props.selectedAccountDetails?.currencyDTO?.sign);
  const customerDropdownRef = useClickOutside(() => setShowCustomerDropdown(false));
  const [showCustomerDropdown, setShowCustomerDropdown] = useState(false);
  const [selectedCustomers, setSelectedCustomers] = useState({});
  const [allSelected, setAllSelected] = useState(true);
  const [customerCounter, setCustomerCounter] = useState('0');
  const [customers,setCustomers] = useState([]);
  const [firstCall,setFirstCall] = useState(false);
  const [firstCallData,setFirstCallData] = useState([]);
  const [modal2Open, setModal2Open] = useState(false);

  const [getReconcilationDetails] = UseApiCall(apiGetReconcilationData, (res) => {
      dispatch(BankAccountActions.setReconcilationDetails(res?.data));
      setTotalBalance(res?.data?.total_balance)
      setErrorOccured(false)
      setDataLoaded(false)
    }, (err) => {
      setErrorOccured(true)
      enqueueSnackbar(`${"Error occured while fetching data. Please try again later."}`, { variant: 'error' })
    }
  );

  const [getReconcilationCustomerList] = UseApiCall(apiGetReconcilationCustomerList, 
    (res) => {
      setCustomers(res?.data)
    }
  )

  const selectedCustomerIds = useMemo(() => {
    return Object.keys(selectedCustomers).filter(customerId => selectedCustomers[customerId]);
  }, [selectedCustomers]);

  const params = props.selectedAccountDetails?.currencyDTO?.code;

  const [postSendReconcilationData, fetchingSendReconcilationData] = UseApiCall(apiSendReconcilationData, (res) => {
    let paymentType = "";
    let pSum = 0;
    let pCount = 0;
    let tSum = amountToBeReconciled;
    if (res?.data?.length === selectedInvoices?.length) {
      const total = selectedInvoices?.reduce((total, obj) => total + obj?.amount, 0);
      tSum = total.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
      setAmountToBeReconciledForModal(tSum);
      paymentType = "completed";
    } else {
      const sum = res?.data?.reduce((total, id) => {
        const matchingObject = selectedInvoices.find(obj => obj?.invoice_id === id);
        return matchingObject ? total + matchingObject?.amount : total;
      }, 0);
      const total = selectedInvoices?.reduce((total, obj) => total + obj?.amount, 0);
      const partialSum = total - sum;
      setPartialCount(selectedInvoices?.length - res?.data?.length);
      pCount = selectedInvoices?.length - res?.data?.length;
      pSum = partialSum.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
      tSum = sum.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
      setPartialAmount(pSum);
      setAmountToBeReconciledForModal(tSum);

      const updatedInputData = selectedInvoices.map(item => {
        if (res?.data?.includes(item.invoice_id)) {
          return { ...item, error_key: false };
        } else {
          return { ...item, error_key: true };
        }
      });

      setSelectedInvoices(updatedInputData);

      paymentType = "partial";
      setPaymentTypes('partial')
    }
    setModal2Open(false);
    screenWidth > 640 ? handlePayment(paymentType, pSum, pCount, tSum) : handleReconcile(paymentType)
    getReconcilationDetails({
      currencyCode : params,
      customerIds : allSelected ? '' : selectedCustomerIds
    });
  }, (err) => {
    setModal2Open(false);
    if (err?.status === 404) {
      enqueueSnackbar(`${err?.data?.error !== undefined ? err?.data?.error : 'Error Occured.'}`, { variant: 'error' })
    } else {
      enqueueSnackbar(`${"Unable to reconcile now. Please try again later."}`, { variant: 'error' })
    }
  });

let cancelToken;

const handleDataFetch = async () => {
  if (cancelToken) {
    cancelToken.cancel("call canceled");
  }

  cancelToken = axios.CancelToken.source();

  try {
    const response = await axios.get(
      `${baseUrl.api}/reconcile/${params}?customerIds=${allSelected ? '' : selectedCustomerIds}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
        withCredentials: true,
        cancelToken: cancelToken.token
      }
    );

    if (response.status === 200) {
      let result = response.data;

      if (doEncryption) {
        result = handleDecrypt(result);
      }
      
      if(!firstCall){
        setFirstCallData(result?.data);
        setFirstCall(true);
      }

      dispatch(BankAccountActions.setReconcilationDetails(result?.data));
      setTotalBalance(result?.data?.total_balance);
      setErrorOccured(false);
      setDataLoaded(false);

    } else {
      console.error('Network response was not ok:', response.status);
    }
  } catch (err) {
    if (axios.isCancel(err)) {
      console.log('Request canceled:', err.message);
    } else {
      console.error('Request error:', err);
      setErrorOccured(true);
      enqueueSnackbar("Error occurred while fetching data. Please try again later.", { variant: 'error' });
    }
  }
};

useEffect(() => {

  if(!firstCall || (selectedCustomerIds.length !== customers?.length && selectedCustomerIds.length > 0)){
    dispatch(BankAccountActions.setReconcilationDetails([]));
    handleDataFetch();
  }

  return () => {
    if (cancelToken) {
      cancelToken.cancel("component unmounted or dependencies changed");
    }
  };
}, [selectedCustomerIds]);

  useEffect(() => {
    getReconcilationCustomerList(params)
  }, [])

  useEffect(() => {
    if (selectedInvoices?.length > 0) {
      selectedInvoices.map((el)=>{
        if(el.amount > el.total_reconcile_amount){
          setInputError(true)
          return
        }
        setInputError(false)
      })

      setInputNegativeError(selectedInvoices?.filter((item) => item?.amount < 100)?.length ? true : false)
      
      const reconciledAmount = selectedInvoices.reduce((total, invoice) => total + invoice.amount, 0);
      setReconciledAmounSign(reconciledAmount < 0 ? '-' : '');
      const availableBalance = formatNumberToLocaleString((props?.totalBalance?.balance - reconciledAmount), 2, 2);
      setOrangeFooter(props?.totalBalance?.balance - reconciledAmount < 0 ? true : false)
      setAvailableBalanceValue(availableBalance);
      setAmountToBeReconciled(reconciledAmount.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }));

      const groupedByCurrency = selectedInvoices.reduce((acc, item) => {
        const { code } = item.invoiceCurrency;
        acc[code] = acc[code] || [];
        acc[code].push(item);
        return acc;
      }, {});

      const groupedArray = Object.entries(groupedByCurrency).map(([currencyCode, items]) => ({
        currencyCode,
        items,
        price: items.reduce((total, invoice) => total + invoice.invoiceAmount, 0).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
        len: items?.length,
        sign: items?.length && items[0]?.invoiceCurrency?.sign
      }));

      setFilterInvoices(groupedArray);
    } else {
      setOrangeFooter(false)
    }
  }, [selectedInvoices])

  const handlePayment = (paymentType, pSum, pCount, tSum) => {
       props.setApiCallRecon(true);
       showReconSuccess(modalRef,{
        mainIcon : paymentType === "completed" ? reconSuccessIcon : reconPartialSuccessIcon,
        subHeading : 'You have successfully reconciled',
        reconCurrency : props?.totalBalance?.sign,
        reconAmount : tSum,
        reconPartialAmount : pSum,
        partialCount: pCount,
        buttonText : paymentType === "completed" ? 'Done' : 'View',
        showReconPartial: paymentType === "partial",
        title: paymentType === "completed" ? 'Reconciliation Successful' : 'Reconciliation Successful (Partially)',
        handleBtn : paymentType === "completed" ? () => {props.handleClick()} : () => {}
       })
  }

  const handleReconcile = () => {
    props.setApiCallRecon(true);
    dispatch(modalActions.invoiceTooltipDisplay(true))
  }

  const handleLastStep = () => {

    const transformedData = selectedInvoices?.map(item => ({
      "invoice_id": item?.invoice_id,
      "amount_to_reconcile": Number(item?.amount),
      "amount_pending": Number(item?.total_reconcile_amount)
    }));
    
    const params = {
      currency: currencyCodeValue,
      reconcile_receivable_payload_list: transformedData
    }

    postSendReconcilationData(params)
  }

  useEffect(() => {
    let timeoutId;
  
    if (!fetchingSendReconcilationData) {
      setModal2Open(false);
    } else {
      timeoutId = setTimeout(() => {
        setModal2Open(true);
      }, 3000);
    }
  
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [fetchingSendReconcilationData]);

  useEffect(() => {
    if (modal2Open){
      if (!fetchingSendReconcilationData) setModal2Open(false);
    }
  }, [modal2Open])

  const handleAllCustomersToggle = (x) => {
    if (!x) {
        const newSelectedCustomers = {};
        customers.forEach(customer => {
            newSelectedCustomers[customer.id] = true;
        });
        setSelectedCustomers(newSelectedCustomers);
        setCustomerCounter(Object.values(newSelectedCustomers).filter(value => value).length)
        setAllSelected(!allSelected);
    } else {
        setSelectedCustomers({})
        setAllSelected(false);
    }
  };

  const toggleCustomerSelection = async (customerId, len) => {
    setSelectedCustomers(prevSelectedCustomers => {
      const updatedSelection = {
        ...prevSelectedCustomers,
        [customerId]: !prevSelectedCustomers[customerId]
      };
      const countTrueKeys = Object.values(updatedSelection).filter(value => value).length;
      setCustomerCounter(countTrueKeys);
      setAllSelected(countTrueKeys === len);

      return updatedSelection;
    });
  };

  const toggleCustomerDropdown = () => {
    setShowCustomerDropdown(!showCustomerDropdown);
  };

  return(
    <>
      {invoiceTooltipDisplay &&
        ReactDOM.createPortal(
          <NormalAction
            title={paymentTypes === "completed" ? 'Reconciliation Successful' : 'Reconciliation Successful (Partially)'}
            heading={""}
            mainClass={"flex flex-col items-center"}
            showIcon={true}
            icon={paymentTypes === "completed" ? reconSuccessIcon : reconPartialSuccessIcon}
            text={<><div className="text-[16px] font-[500]">You have successfully reconciled </div><span className="text-[16px] font-[700]">{props?.totalBalance?.sign}{amountToBeReconciledForModal}</span></>}
            partialText={<div className="!text-[#F74F51]"><div className="text-[16px] font-[500] !text-[#F74F51]">Reconciliation of </div><span className="text-[16px] font-[700] !text-[#F74F51]">{props?.totalBalance?.sign}{partialAmount}</span> failed for <span className="text-[16px] font-[700] !text-[#F74F51]">{partialCount}</span> {partialCount > 1 ? 'invoices' : 'invoice'}.</div>}
            textClass={"text-center font-[500]"}
            buttonText={paymentTypes === "completed" ? 'Done' : 'View'}
            buttonShow={true}
            navigation={true}
            showReconPartial={paymentTypes === "completed" ? false : true}
            handleClose={paymentTypes === "completed" ? () => {props.handleClick()} : () => {}}
            // route={'/bank-accounts'}
          />,
          document.getElementById("modal-root")
      )}
      { screenWidth > 640
        ?
        <div className={classes.pageHeader}>
          <div className={classes.headerCross} onClick={props.handleClick}>
            <img src={xIcon} alt="X" className={classes.headerCrossImg}/>
          </div>
        </div>
        :
        <div className={classes.mobilePageHeader}>
          <h1 className={classes.mobileHeading}>{currencyCodeValue} Reconcilation</h1>
          <div className={classes.mobileHeaderCross} onClick={props.handleClick}>
            <img className={classes.mobileCross} src={xIcon} alt="X"/>
          </div>
        </div>
      }
      { errorOccured ?
        <div
          style={{
              textAlign: 'center',
              width: '100%',
              marginTop: '4rem',
              fontSize: '2.5rem',
              top: '125px',
              position: 'absolute',
          }}
          className={classes.table}
        >
          Error occured while fetching data. Please try again later.
        </div>
        :
        <div className={classes.fullPageWrap}>
          <div className={clsx(classes.fullDataWrap,'w-[99vw] min-h-[100%] relative max-w-[1032px] 2xl:max-w-[1300px] self-center flex flex-col ')}>
            { screenWidth > 640
              ?
                <div className={classes.virtualBankDetails}>
                    <h1 className={classes.virtualBankDetailsHead1}>You are reconciling <span className={classes.vbcurrency}>{currencyCodeValue}</span> payments</h1>
                    <div className={clsx(classes.virtualBankDetailsHead2, `flex items-center min-w-[204px]`)}>
                    {currencyCodeValue}<div className={classes.dot}></div>Virtual Account Balance
                    <span className={classes.availableBalance}>{props?.totalBalance?.sign}{formatNumberToLocaleString((totalBalance ? totalBalance : props?.totalBalance?.balance), 2, 2)}</span>
                  </div>
                </div>
              :
                <div className={classes.subHeader} style={{marginTop:`72px`}}>
                  <div className={clsx(classes.subHeaderText, `flex items-center min-w-[204px]`)}>
                    {currencyCodeValue}<div className={classes.dot}></div>Virtual Account Balance
                  </div>
                  <div className={classes.subHeaderMoney}>
                  {props?.totalBalance?.sign}{formatNumberToLocaleString((totalBalance ? totalBalance : props?.totalBalance?.balance), 2, 2)}
                  </div>
                </div>
            }
            <div className={classes.allWrap}>
              {
              screenWidth > 640
                ?<>
                  <div className={classes.tableSubHeading}>
                    <h1 className='text-[16px] leading-[24px] font-[600] font-[#1E333F]'>Select invoices and enter amount to be reconciled</h1>
                    <div className={classes.dropdownWrapper} ref={customerDropdownRef}>
                      <div className={classes.customer} onClick={toggleCustomerDropdown}>
                          <span className={classes.text}>Customers</span>
                          <div className={classes.counter}>
                              {customerCounter >= 1 && customerCounter !== customers?.length &&
                              (<div className={classes.count}>
                                  <span className={classes.countText}>{customerCounter}</span>
                              </div>)}
                              <img src={showCustomerDropdown ? up : down} className={classes.imgIcon} alt="dropdown"/>
                          </div>
                      </div>
                      {showCustomerDropdown && (
                          <div className={`${classes.dropdownCustomer}`} >
                              <div className={`${classes.scrollableDropdown}`}>
                                  <div className={classes.dropdownListCustomer} 
                                    onClick={(e) => {
                                        handleAllCustomersToggle(allSelected)
                                    }}>
                                      <input type='checkbox' className={classes.dropdownCheckbox}
                                          checked={allSelected}
                                      />
                                      <span className={classes.dropdownText}>All Customers</span>
                                  </div>
                                  {customers && customers?.map((customer) => (
                                      <div className={classes.dropdownListCustomerMap} key={customer?.id}
                                      onClick={() => {
                                          toggleCustomerSelection(customer.id, customers?.length)
                                      }}
                                      >
                                          <input type='checkbox' className={classes.dropdownCheckbox} 
                                                  checked={selectedCustomers[customer.id] || allSelected}
                                                  // onChange={() => toggleCustomerSelection(customer.id, customers?.length)}
                                          />
                                          <span className={classes.dropdownText}
                                              // onClick={() => toggleCustomerSelection(customer.id, customers?.length)}
                                          >
                                          {customer?.customerName?.length > 22
                                              ? `${customer?.customerName?.slice(0, 22)}...` 
                                              : customer?.customerName
                                          }
                                          </span>
                                      </div>
                                  ))}
                              </div>
                          </div>
                      )}
                    </div>
                  </div>
                  <div className={classes.tableWrap}>
                    {
                      (reconcilationDetailsTableData?.reconcilable_receivable_details_list?.length > 0 || dataLoaded)
                        ?
                          <ReconcilationTable
                            tableData={ (selectedCustomerIds?.length !== customers?.length && selectedCustomerIds?.length > 0) ? reconcilationDetailsTableData?.reconcilable_receivable_details_list : firstCallData?.reconcilable_receivable_details_list}
                            selectedInvoices={selectedInvoices}
                            setSelectedInvoices={setSelectedInvoices}
                            setInputError={setInputError}
                            currencyCodeSign={currencyCodeSign}
                            currencyCodeValue={currencyCodeValue}
                            disabledInput={fetchingSendReconcilationData}
                          />
                        :
                          ( reconcilationDetailsTableData?.reconcilable_receivable_details_list?.length === 0 ?
                          <div
                            style={{
                                textAlign: 'center',
                                width: '100%',
                                margin: '4rem 0',
                                fontSize: '2.5rem'
                            }}
                            className={classes.table}
                          >
                              No Invoices To Reconcile.
                          </div>
                          :
                          [1,3,4]?.map((item, index) => (
                            <Shimmer key={index} className="w-[1660%] h-[80px] mt-[12px]"/>
                          ))
                          )
                      }
                  </div>
                  </>
                :
                  <>
                    <div className={classes.mobileTableWrap}>
                      <div className={clsx(`mt-[24px] mb-[20px] text-[16px] leading-[24px] font-[500] text-[#1E333F]`)}>Select invoices and enter amount to be reconciled</div>
                      {
                        (reconcilationDetailsTableData?.reconcilable_receivable_details_list?.length > 0 || dataLoaded)
                          ?
                            <MobileReconTable
                              tableData={reconcilationDetailsTableData?.reconcilable_receivable_details_list}
                              selectedInvoices={selectedInvoices}
                              setSelectedInvoices={setSelectedInvoices}
                              currencyCodeSign={currencyCodeSign}
                              currencyCodeValue={currencyCodeValue}
                              disabledInput={fetchingSendReconcilationData}
                            />
                          :
                            <div
                              style={{
                                  textAlign: 'center',
                                  width: '100%',
                                  margin: '4rem 0',
                                  fontSize: '2.5rem'
                              }}
                              className={classes.table}
                            >
                                No Invoices To Reconcile.
                            </div>
                      }
                    </div>
                  </>
              }
              
            </div>
          </div>
        </div>
      }

      {/* // Footer */}

      {
        selectedInvoices?.length > 0 &&
          <div className={clsx(`-md:hidden mb-[64px] w-[100%] self-center flex justify-center`)}>
            <div className={classes.footer}>
              <div className={classes.footerLeftText}>
                <span className={clsx(`text-white text-16px font-500 leading-6 uppercase mr-[4px]`)}> {selectedInvoices?.length}</span>
                 Invoice{selectedInvoices?.length > 1 ? 's' : ''} selected
              </div>
              <div>
              { filterInvoices && filterInvoices?.length > 0 && filterInvoices?.map((item, index) => (
                  <div className={classes.footerRightBox} key={index}>
                    <div className="flex flex-col items-start gap-[4px] pr-[24px]">
                      <div className="flex reverse gap-[8px] items-center w-[100%] justify-end">
                        <Tooltip 
                         placement="top" 
                         title="Reconciled amount exceeds available balance" 
                         color="#DD6B20" 
                         overlayInnerStyle={{width:"318px"}}
                         overlayStyle={{fontSize:"14px",fontWeight:"500"}}
                         >
                         {orangeFooter && <img src={alertIcon} alt="img" className='w-[20px] h-[20px]'/>}
                         </Tooltip>
                        <h1 className="text-right  !text-[16px] text-[#FFFFFF] !leading-[24px] !font-[400]">{props?.totalBalance?.sign}{availableBalanceValue}</h1>
                      </div>
                      <p className="!text-[14px] w-[100%] !text-[#FFFFFF99] !leading-[20px] !font-[500] text-right">Available Balance</p>
                    </div>
                    <div className='flex flex-col items-start gap-[4px] px-[24px] border-l-2 border-[#FFFFFF1e]'>
                      <div className={clsx(classes.footerRightPriceBig,'flex flex-row-reverse w-full')}>
                        <h1 className={clsx('!text-right !text-[20px] text-[#FFFFFF] !leading-[24px] !font-[700] w-[100%]')}><span className={clsx('!font-[500]')}>{reconciledAmountSign}{props?.totalBalance?.sign}</span>{reconciledAmountSign === '-' ? amountToBeReconciled?.substr(1) : amountToBeReconciled}</h1>
                      </div>
                      <div className={clsx(classes.exporterDetailsText,'!text-[14px] !leading-[20px] !font-[500] text-right')} style={{ color: "#FFFFFF99" }}>Amount to be reconciled</div>
                    </div>
                    <div>
                      <Button
                        text="Reconcile Now"
                        reconcileLoader={fetchingSendReconcilationData}
                        disabled={fetchingSendReconcilationData}
                        btnClassIcon={'order-2 w-[20px] h-[20px]'}
                        onClick={(inputError||amountToBeReconciled<=0||orangeFooter)?()=>{}:() => {
                          handleLastStep()
                        }}
                        className={clsx(`flex px-[24px] text-[#] py-[12px] justify-center items-center gap-2 rounded-8px border-2 border-white bg-white ${(inputError||amountToBeReconciled<=0||orangeFooter || inputNegativeError || fetchingSendReconcilationData) ? 'opacity-90 cursor-not-allowed':'opacity-100 cursor-pointer'}`)}
                        btnClassText={clsx(`!text-[16px] !leading-[24px]  !font-[600] ${(inputError||amountToBeReconciled<=0||orangeFooter || inputNegativeError || fetchingSendReconcilationData) ? 'text-[gray]':'text-[#181C30]'}`)}
                      />
                    </div>
                  </div>
              ))}
              </div>
            </div>
          </div>
      }
      {
        selectedInvoices?.length > 0 &&
          <div className={clsx(`md:hidden w-[100%] flex`)}>
            <div className={classes.footer}>
              { filterInvoices && filterInvoices?.length > 0 && filterInvoices?.map((item, index) => (
              <div className={classes.footerRightBox}>
                <div className="flex gap-[4px] flex-col">
                  <div className={`flex gap-[4px]`}>
                    <div className={classes.footerRightPrice}>
                      {reconciledAmountSign}{props?.totalBalance?.sign}{reconciledAmountSign === '-' ? amountToBeReconciled?.substr(1) : amountToBeReconciled}
                    </div>
                    <div className={clsx(classes.reconcileCountText, 'flex items-end')} style={{ color: "#FFFFFF99" }}> ({item?.len} Invoice{item?.len > 1 ? 's' : ''})</div>
                  </div>
                  <div className={classes.reconcileAmountText}>
                    Amt. to be reconciled
                  </div>
                </div>
                <div>
                  <Button
                    text="Reconcile Now"
                    reconcileLoader={fetchingSendReconcilationData}
                    disabled={fetchingSendReconcilationData}
                    btnClassIcon={'order-2 w-[20px] h-[20px]'}
                    onClick={(inputError||amountToBeReconciled<=0||orangeFooter)?()=>{}:() => handleLastStep()}
                    className={clsx(`bg-[#FFFFFF] border rounded-[8px] px-[1px] py-[12px] border-solid border-[2px] border-[#FFF] h-[48px] gap-[8px] ${(inputError||amountToBeReconciled<=0||orangeFooter || inputNegativeError || fetchingSendReconcilationData) ? 'opacity-90 cursor-not-allowed w-[150px]':'opacity-100 cursor-pointer w-[124px]'} `)}
                    btnClassText={clsx(`!text-[14px] text-[#181C30] !leading-[24px] !h-[24px] !font-[600] ${(inputError||amountToBeReconciled<=0||orangeFooter || inputNegativeError || fetchingSendReconcilationData) ? 'text-[gray]':'text-[#181C30]'} `)}
                  />
                </div>
              </div>
              ))}
            </div>
          </div>
      }
      {
        screenWidth <= 640 && orangeFooter && selectedInvoices?.length > 0 &&
          <div className={clsx(`md:hidden mb-[64px] w-[100%] flex`)}>
            <div className={classes.orangeFooter}>
              <div className={classes.orangeFooterRightBox}>
                <div className="flex w-[100%] justify-between">
                  <div className={clsx(classes.reconcileAmountExceedsText, 'flex items-center')}>
                    Reconciled amount exceeds avail. bal.
                  </div>
                  <div className={clsx(classes.reconcileAmountExceedsValue, 'flex gap-[4px]')}>
                    <p className="font-[500]">-</p>${availableBalanceValue}
                  </div>
                </div>
              </div>
            </div>
          </div>
      }

      <Modal
        title={
          <div className="flex items-center">
            <>
              <svg
                class="animate-spin -ml-1 mr-3 h-8 w-8 text-black"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                    class="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    stroke-width="4"
                    stroke-dasharray="64"
                    stroke-dashoffset="0"
                ></circle>
                <path
                    class="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            </>
            <div>Please wait while we are processing your request.</div>
          </div>
        }
        centered
        open={modal2Open}
        footer={null}
        closeIcon={false}
      >
      </Modal>
      
    </>
  )
}

export default Reconcilation