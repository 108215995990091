import { useEffect, useState } from 'react'
import clsx from 'clsx'

import classes from 'Components/ReportTable/PaymentStatusTag.module.scss'
import InitiatedIcon from 'assets/Icons/initiated-arrow-white.svg'
import LinkSentIcon from 'assets/Icons/link-sent-white.svg'
import TickCircleIcon from 'assets/Icons/tick-circle-white.svg'
import CrossIcon from 'assets/Icons/cross-circle-white.svg'
import EditWhiteIcon from 'assets/Icons/edit-white.svg'
import DiscWhiteIcon from 'assets/Icons/disc-white.svg'
import InfoIcon from 'assets/Icons/info_brown_circle.svg'
import StatusVerifiedIcon from 'assets/Icons/status-verified.svg'

const getDarkStatusDetails = (status) => {
  switch (status.split(' ').join('').toLowerCase()) {
    case 'created':
      return {
        icon: EditWhiteIcon,
        title: 'UNDER REVIEW',
        bgColor: '#FFB800',
      }
    case 'initiated':
      return {
        icon: InitiatedIcon,
        title: 'INITIATED',
        bgColor: '#FF8934',
      }
    case 'inprogress':
      return {
        icon: DiscWhiteIcon,
        title: 'IN PROGRESS',
        bgColor: '#ADB45B',
      }
    case 'linksent':
      return {
        icon: LinkSentIcon,
        title: 'LINK SENT',
        bgColor: '#3998DD',
      }
    case 'settled':
      return {
        icon: TickCircleIcon,
        title: 'SETTLED',
        bgColor: '#38B565',
      }
    case 'inreview':
      return {
        icon: InfoIcon,
        title: 'UNDER REVIEW',
        bgColor: '#8E6C28',
      }
    case 'verified':
      return {
        icon: StatusVerifiedIcon,
        title: 'VERIFIED',
        bgColor: '#7750CB',
      }
    case 'cancelled':
      return {
        icon: CrossIcon,
        title: 'CANCELLED',
        bgColor: '#D34646',
      }
    case 'settledoutside':
      return {
        icon: TickCircleIcon,
        title: 'SETTLED',
        bgColor: '#D34646',
      }
    case 'indraft':
      return {
        icon: EditWhiteIcon,
        title: 'DRAFT',
        bgColor: '#4380A2',
      }
    case 'overdue':
      return {
        icon: EditWhiteIcon,
        title: 'OVERDUE',
        bgColor: '#E3506E',
      }
    case 'partiallypaid':
      return {
        icon: TickCircleIcon,
        title: 'PARTIALLY PAID',
        bgColor: '#2FD4D4',
      }
    default:
      return {
        icon: EditWhiteIcon,
        title: status,
        bgColor: '#FFB800',
      }
  }
}

const getLightStatusDetails = (status) => {
  switch (status.split(' ').join('').toLowerCase()) {
    case 'created':
      return {
        icon: EditWhiteIcon,
        title: 'UNDER REVIEW',
        bgColor: '#FFF4D9',
        textColor: '#FFB800',
      }
    case 'initiated':
      return {
        icon: InitiatedIcon,
        title: 'INITIATED',
        bgColor: '#FFEBDC',
        textColor: '#FF8934',
      }
    case 'verified':
      return {
        title: 'VERIFIED',
        bgColor: '#DED2F8',
        textColor: '#7750CB',
      }
    case 'inprogress':
      return {
        icon: DiscWhiteIcon,
        title: 'IN PROGRESS',
        bgColor: '#F3FFC1',
        textColor: '#ADB45B',
      }
    case 'linksent':
      return {
        icon: LinkSentIcon,
        title: 'LINK SENT',
        bgColor: '#E8F5FF',
        textColor: '#3998DD',
      }
    case 'settled':
      return {
        icon: TickCircleIcon,
        title: 'SETTLED',
        bgColor: '#EBFFF4',
        textColor: '#38B565',
      }
    case 'inreview':
      return {
        icon: InfoIcon,
        title: 'UNDER REVIEW',
        bgColor: '#FFF4DE',
        textColor: '#8E6C28',
      }
    case 'cancelled':
      return {
        icon: CrossIcon,
        title: 'CANCELLED',
        bgColor: '#F6DFDF',
        textColor: '#D34646',
      }
    case 'settledoutside':
      return {
        icon: StatusVerifiedIcon,
        title: 'SETTLED',
        bgColor: '#F6DFDF',
        textColor: '#D34646',
      }
    case 'indraft':
      return {
        icon: EditWhiteIcon,
        title: 'DRAFT',
        bgColor: '#C1D9E6',
        textColor: '#4380A2',
      }
    case 'overdue':
      return {
        icon: EditWhiteIcon,
        title: 'OVERDUE',
        bgColor: '#e3506e54',
        textColor: '#E3506E',
      }
    case 'partiallypaid':
      return {
        icon: TickCircleIcon,
        title: 'PARTIALLY PAID',
        bgColor: '#E1F6F7',
        textColor: '#2FD4D4',
      }
    case 'potential' :
      return {
        title: 'POTENTIAL',
        bgColor: "#FFEBF0",
        textColor: "#E3506E",
      }
    case 'no_match' :
      return {
        title: 'NO MATCH',
        bgColor: "#EBFFF4",
        textColor: "#38B565",
      }
    case 'pending_verification' :
      return {
        title : "PENDING VERIFICATION",
        bgColor: '#FFF4D9',
        textColor: '#FFB800'
      }
    case 'potential_match' :
      return {
        title : "POTENTIAL MATCH",
        bgColor: "#FFEBF0",
        textColor: "#E3506E"
      }
    case 'verification_failed' :
      return {
        title: "REJECTED",
        bgColor: "#FFEBF0",
        textColor: "#E3506E"
      }
    case 'under_review' :
      return {
        title: "UNDER REVIEW",
        bgColor: '#FFF4D9',
        textColor: '#FFB800',
      }
    case 'active' :
      return{
        title: "ACTIVE",
        bgColor: '#EBFFF4',
        textColor: '#38B565',
      }
    case 'disabled' :
      return{
        title: "DISABLED",
        bgColor: '#F0F2F4',
        textColor: '#AFB7BE',
      }
    case 'rejected' :
      return{
        title: "REJECTED",
        bgColor: "#FFEFEF",
        textColor: "#D56F6F"
      }

    default:
      return {
        icon: EditWhiteIcon,
        title: status,
        bgColor: '#FFF4D9',
        textColor: '#FFB800',
      }
  }
}

const PaymentStatusTag = (props) => {
  let { status, withIcon = false, type = 'dark' } = props

  if (
    status?.toLowerCase() === 'in review'
  ) {
    status = 'created'
  } else if (status?.toLowerCase() === 'created') {
    status = 'verified'
  }

  const [statusDetails, setStatusDetails] = useState(
    props?.type === 'light'
      ? getLightStatusDetails(status || '')
      : getDarkStatusDetails(status || '')
  )

  useEffect(() => {
    setStatusDetails(
      props?.type === 'light'
        ? getLightStatusDetails(status || '')
        : getDarkStatusDetails(status || '')
    )
  }, [props, status])

  return (
    <div
      className={clsx(
        'px-[8px] min-h-[20px] rounded-full flex flex-row items-center w-fit'
      )}
      style={{
        backgroundColor: statusDetails?.bgColor
          ? statusDetails?.bgColor
          : 'inherit',
      }}
    >
      {withIcon && statusDetails.icon && (
        <img
          className="w-[16px] aspect-square mr-1"
          src={statusDetails.icon}
          alt="icon"
        />
      )}
      <div className={clsx(classes.text)}>
        <p
          className="text-white font-bold w-max"
          style={{
            color: statusDetails?.textColor ? statusDetails?.textColor : '#fff',
            fontSize: 12,
          }}
        >
          {statusDetails?.title}
        </p>
      </div>
    </div>
  )
}

export default PaymentStatusTag
