// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CustomSelectorValues_dropdown__e9bN5 {
  overflow: scroll;
}
.CustomSelectorValues_dropdown__e9bN5 .CustomSelectorValues_set__iwTB8 {
  margin-top: 2rem;
  margin-bottom: 0.4rem;
}
.CustomSelectorValues_dropdown__e9bN5 .CustomSelectorValues_set__iwTB8 .CustomSelectorValues_header__rndVH {
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 1.8rem;
  text-transform: uppercase;
  color: rgba(30, 51, 63, 0.4);
  padding-left: 2rem;
  margin-bottom: 1.2rem;
}

.CustomSelectorValues_selectLabel__bSgeJ {
  z-index: 10;
  font-size: 1.2rem;
  line-height: 1.8rem;
  padding: 0px 0.4rem;
  background-color: white;
  position: absolute;
  left: 1.4rem;
  color: rgba(30, 51, 63, 0.5);
  font-weight: 400;
  top: -1rem;
  transition: all 0.2s ease;
  pointer-events: none;
}

.CustomSelectorValues_searchInput__WxBmQ::-webkit-search-decoration,
.CustomSelectorValues_searchInput__WxBmQ::-webkit-search-cancel-button,
.CustomSelectorValues_searchInput__WxBmQ::-webkit-search-results-button,
.CustomSelectorValues_searchInput__WxBmQ::-webkit-search-results-decoration {
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/Components/atom/CustomerSelecterValues/CustomSelectorValues.module.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;AACJ;AACI;EAEI,gBAAA;EACA,qBAAA;AAAR;AAEQ;EAEI,gBAAA;EACA,iBAAA;EACA,mBAAA;EACA,yBAAA;EACA,4BAAA;EACA,kBAAA;EACA,qBAAA;AADZ;;AAMA;EACI,WAAA;EACA,iBAAA;EACA,mBAAA;EACA,mBAAA;EACA,uBAAA;EAEA,kBAAA;EACA,YAAA;EACA,4BAAA;EACA,gBAAA;EACA,UAAA;EACA,yBAAA;EACA,oBAAA;AAHJ;;AAMA;;;;EAGkD,aAAA;AAFlD","sourcesContent":[".dropdown{         \n    overflow: scroll;    \n\n    .set{\n        \n        margin-top: 2rem;\n        margin-bottom: 0.4rem;\n\n        .header{\n            \n            font-weight: 600;\n            font-size: 1.2rem;\n            line-height: 1.8rem;\n            text-transform: uppercase;\n            color: rgba(30, 51, 63, 0.4);\n            padding-left: 2rem;\n            margin-bottom: 1.2rem;\n        }\n    }\n}\n\n.selectLabel {\n    z-index: 10;\n    font-size: 1.2rem;\n    line-height: 1.8rem;\n    padding: 0px 0.4rem;\n    background-color: white;\n    transition: all 0.2s ease;\n    position: absolute;\n    left: 1.4rem;\n    color: rgba(30, 51, 63, 0.5);\n    font-weight: 400;\n    top: -1rem;\n    transition: all 0.2s ease;\n    pointer-events: none;\n}\n\n.searchInput::-webkit-search-decoration,\n.searchInput::-webkit-search-cancel-button,\n.searchInput::-webkit-search-results-button,\n.searchInput::-webkit-search-results-decoration { display: none; }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropdown": `CustomSelectorValues_dropdown__e9bN5`,
	"set": `CustomSelectorValues_set__iwTB8`,
	"header": `CustomSelectorValues_header__rndVH`,
	"selectLabel": `CustomSelectorValues_selectLabel__bSgeJ`,
	"searchInput": `CustomSelectorValues_searchInput__WxBmQ`
};
export default ___CSS_LOADER_EXPORT___;
