// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DownloadFiraMobile_backdrop__gEJfh {
  z-index: 91;
  top: 0;
  height: 100vh;
}

.DownloadFiraMobile_headerTop__yjXnY {
  padding: 20px;
  display: flex;
  align-self: center;
  justify-content: space-between;
  font-size: 18px;
  font-weight: 600;
  line-height: 26px;
  border-bottom: 1px solid #D7DDE1;
}
.DownloadFiraMobile_headerTop__yjXnY .DownloadFiraMobile_invoiceHead__\\+FcH4 {
  max-width: 80%;
  overflow-wrap: anywhere;
}

.DownloadFiraMobile_cont__zs28U {
  display: flex;
  padding: 20px 20px;
  align-items: center;
  justify-content: space-between;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  border-bottom: 1px solid #E5E7E8;
}
.DownloadFiraMobile_cont__zs28U .DownloadFiraMobile_remAmount__CPLyX {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}`, "",{"version":3,"sources":["webpack://./src/Components/atom/DownloadFiraMobile/DownloadFiraMobile.module.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,MAAA;EACA,aAAA;AACJ;;AAEA;EACI,aAAA;EACA,aAAA;EACA,kBAAA;EACA,8BAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,gCAAA;AACJ;AACI;EACI,cAAA;EACA,uBAAA;AACR;;AAEA;EACI,aAAA;EACA,kBAAA;EACA,mBAAA;EACA,8BAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,gCAAA;AACJ;AACI;EACI,eAAA;EACA,gBAAA;EACA,iBAAA;AACR","sourcesContent":[".backdrop{\n    z-index: 91;\n    top: 0;\n    height: 100vh;\n}\n\n.headerTop{\n    padding: 20px;\n    display: flex;\n    align-self: center;\n    justify-content: space-between;\n    font-size: 18px;\n    font-weight: 600;\n    line-height: 26px;\n    border-bottom: 1px solid #D7DDE1;\n\n    .invoiceHead{\n        max-width: 80%;\n        overflow-wrap: anywhere;\n    }\n}\n.cont{\n    display: flex;\n    padding: 20px 20px;\n    align-items: center;\n    justify-content: space-between;\n    font-size: 16px;\n    font-weight: 500;\n    line-height: 24px;\n    border-bottom: 1px solid #E5E7E8;\n\n    .remAmount{\n        font-size: 16px;\n        font-weight: 400;\n        line-height: 24px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"backdrop": `DownloadFiraMobile_backdrop__gEJfh`,
	"headerTop": `DownloadFiraMobile_headerTop__yjXnY`,
	"invoiceHead": `DownloadFiraMobile_invoiceHead__+FcH4`,
	"cont": `DownloadFiraMobile_cont__zs28U`,
	"remAmount": `DownloadFiraMobile_remAmount__CPLyX`
};
export default ___CSS_LOADER_EXPORT___;
