import React, { ReactElement } from 'react'
import clsx from 'clsx'
import CloseIcon from 'assets/Icons/cross_black.svg'

// interface State {
//     title?: string | ReactElement
//     child?: ReactElement | null
//     isModalVisible?: boolean
//     onClose?: CallableFunction
//     closeOnClickOutside?: boolean
//     compactSize?: Boolean
// }

// interface Props extends Omit<State, 'child'> {
//     // interface Props extends State {
//     title?: string
//     children?: ReactElement
// }

/**
 * @function Custom Modal
 * @description A wrapper over Martial UI Modal
 * ---
 * ### Benefit
 * - It can be called over onClick and without maintaining any state
 * - It doesn't re render parent to open/close a modal
 * - Easy to use!
 *
 * ### Limitation
 * - It can't be re rendered in sync with parent once opened (instead use as normal modal i.e. with state dependency)
 * ---
 * @example
 * - On Component side:
 *    (Boiler code)
 *    const [modalRef, setModalRef] = useState<CustomModal | null>(null);
 *    return(
 *      ...
 *      <CustomModal ref={setModalRef} />
 *    )
 *
 *    (onClick -- open)
 *     onClick={() => {
 *        modalRef.show({
 *          title: '',
 *          child: <div/>,
 *          onClose: () => {},
 *        })
 *     }}
 *
 *     ( -- close)
 *     modalRef.close()
 *
 *
 *     (Other way -- In case a component has to be open always in modal)
 *     <CustomModal> {Content} </CustomModal>
 *
 *
 */
class CustomModal extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            isModalVisible: Boolean(props.children),
            child: null,
            title: props.title || '',
            onClose: props.onClose || undefined,
            closeOnClickOutside: props.closeOnClickOutside || false,
            compactSize: props.compactSize || false,
        }
        this.close = this.close.bind(this)
        this.show = this.show.bind(this)
    }

    static getDerivedStateFromProps(props, state) {
        if (props.title !== state.title && !!props.children) {
            return {
                title: props.title,
            }
        }
        return null
    }

    /**
     * @function show - Open modal
     * @description show modal including child component
     * @param {Boolean} disableSoftClose set true to disable close of modal when clicked outside of modal {default - false}
     * @param {Boolean} compactSize if true then modal will wrap content tightly
     * @param {ReactElement} child UI element to show inside modal
     * @param {string | ReactElement} title Title of the Modal
     * @param {string | ReactElement} if title is not present, then it is a full modal with padding 0.
     * @param {callbackFunction} onClose callback Function to be called on close of modal
     * ---
     * #### Call close() on modal ref to close modal.
     */
    show({
        child = null,
        title = this.props.title || '',
        onClose = undefined,
        closeOnClickOutside = false,
        compactSize = false,
    }) {
        this.setState({
            isModalVisible: true,
            child,
            title,
            onClose,
            closeOnClickOutside,
            compactSize,
        })
    }

    /**
     * @function close
     * @description close current open modal
     */
    close() {
        this.closeModal()
    }

    closeModal() {
        const { onClose } = this.state
        this.setState({ isModalVisible: false, child: <div /> })
        if (onClose instanceof Function) {
            onClose()
        }
    }

    render() {
        const {
            isModalVisible,
            child,
            title,
            closeOnClickOutside,
            compactSize,
        } = this.state
        const { children } = this.props

        if (isModalVisible) {
            return (
                <div
                    onClick={
                        closeOnClickOutside
                            ? (e) => {
                                  e.stopPropagation()
                                  this.closeModal()
                              }
                            : undefined
                    }
                    className={clsx(
                        'fixed inset-0 z-20 flex flex-col items-center justify-center bg-bg-black bg-opacity-60 backdrop-blur-sm backdrop-filter z-[100]'
                    )}
                >
                    <div className="fixed inset-0 opacity-50"></div>
                    <div
                        className={clsx(
                            'max-h-[90%] overflow-y-auto rounded-[12px] bg-bg-white backdrop-blur-md',
                            compactSize
                                ? 'h-auto w-auto min-w-fit'
                                : 'h-[90%] w-[90%] min-w-[400]',
                            'shadow-modal'
                        )}
                        onClick={(e) => {
                            e.stopPropagation()
                        }}
                    >
                        {children || (
                            <>
                                {title && (
                                    <>
                                        <div className="flex flex-row justify-between">
                                            <p className="py-8 text-3xl font-bold text-[#1E333F] px-10">
                                                {title || ''}
                                            </p>
                                        </div>
                                        <div className="mb-4 h-[1px] w-full bg-[#E9EBEC]" />
                                    </>
                                )}
                                <button
                                    className="bg-transparent text-gray-400 hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-200 dark:hover:text-white absolute right-4 top-6 ml-auto inline-flex items-center rounded-lg p-2 text-sm"
                                    onClick={(e) => {
                                        e.stopPropagation()
                                        this.closeModal()
                                    }}
                                >
                                    <img
                                        width={20}
                                        height={20}
                                        src={CloseIcon}
                                        alt=""
                                    />
                                </button>
                                {child || <div />}
                            </>
                        )}
                    </div>
                </div>
            )
        }

        return null
    }
}

export default CustomModal
