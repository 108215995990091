import ReportTableLayout from 'Components/ReportTable/ReportTableLayout'
import classes from './CustomerDetailsTabContent.module.scss'

import clsx from 'clsx'
import MobileCustomerDetailsTable from './MobileCustomerDetailsTable'
import { Card, Divider } from 'antd'

const CustomerDetailsTable = (props) => {
  const { details, address,customerData } = props

    function formatDate(dateString) {
        const date = new Date(dateString);
        const options = { month: 'long', day: 'numeric', year: 'numeric' };
        return new Intl.DateTimeFormat('en-US', options).format(date);
    }

    console.log('customerData',customerData?.created_by?.toLowerCase())

  return (
    <div className={clsx('customerDetailsTableScreen')}>

        {
            customerData?.created_by?.toLowerCase() === "fideo-tally-service" &&
            <div className="flex items-center text-[#1e333f80] text-[14px] leading-[24px] mt-[0px] mb-[16px]">
            {/*<span*/}
            {/*    className="py-[2px] px-[8px] text-[14px] leading-[20px] font-inter text-[#FFFFFF] font-[500] bg-[#1E333F] rounded-[100px] mr-[5px]">*/}
            {/*    Tally*/}
            {/*</span>*/}
                <p>{customerData?.created_at ? `This customer data from Tally was synced on ${formatDate(customerData.created_at)}.` : 'No sync data available'}</p>
            </div>
        }

        <Card
            className="modalDetailsCard"
            title={'Basic Information'}
            bordered={false}
            style={{
          background: 'transparent',
          boxShadow: 'none',
        }}
      >

        <div className="customerDetails">
          {details.map((dataRow, index) => {
            return (
              <>
                <div
                  key={index}
                  className="flex flex-row flex-nowrap justify-start items-center w-full"
                >
                  <div className="w-[50%] -sm:w-full accordianDetailsWrapper">
                    <p className="accordianDetailsDataHeader">
                      {dataRow[0]?.value || ""}
                    </p>
                    <p className="accordianDetailsDataSubText">
                      {dataRow[0].label}
                    </p>
                  </div>
                  <div className="w-[50%] -sm:w-full accordianDetailsWrapper">
                    <p className="accordianDetailsDataHeader">
                      {dataRow[1]?.value || ""}
                    </p>
                    <p className="accordianDetailsDataSubText">
                      {dataRow[1].label}
                    </p>
                  </div>
                </div>
                {details.length - 1 !== index && <Divider />}
              </>
            )
          })}
        </div>
      </Card>
      <Card
        className="modalDetailsCard"
        title={'Address'}
        bordered={false}
        style={{
          background: 'transparent',
          boxShadow: 'none',
        }}
      >
        <div className="customerDetails">
          {address.map((dataRow, index) => {
            return (
              <>
                <div
                  key={index}
                  className="flex flex-row flex-nowrap justify-start items-center w-full"
                >
                  <div className="w-[50%] -sm:w-full accordianDetailsWrapper">
                    <p className="accordianDetailsDataHeader">
                      {dataRow[0]?.value || ""}
                    </p>
                    <p className="accordianDetailsDataSubText">
                      {dataRow[0].label}
                    </p>
                  </div>
                  <div className="w-[50%] -sm:w-full accordianDetailsWrapper">
                    <p className="accordianDetailsDataHeader">
                      {dataRow[1]?.value || ""}
                    </p>
                    <p className="accordianDetailsDataSubText">
                      {dataRow[1].label}
                    </p>
                  </div>
                </div>
                {address.length - 1 !== index && <Divider />}
              </>
            )
          })}
        </div>
      </Card>
      <MobileCustomerDetailsTable address={address} details={details} />
    </div>
  )
}
export default CustomerDetailsTable
