import classes from "./CreateInvoice.module.scss";
import billToDark from "../../assets/Icons/billToDark.svg";
import billToLight from "../../assets/Icons/billToLight.svg";
import chevronUp from "../../assets/Icons/chevron-upNew.svg";
import chevronDown from "../../assets/Icons/chevron-downNew.svg";
import checkIcon from "../../assets/Icons/check.svg";
import ContactInfo from "../Global/ContactInfo";
import {useState} from "react";
import clsx from "clsx";
import {useDispatch, useSelector} from "react-redux";
import Shimmer from "../../Layout/Shimmer";
import ReportsPaymentStatusTag from "../ReportTable/ReportsPaymentStatusTag";
import arrowRight from "../../assets/Images/arrow-right.svg";
import {DashboardSummaryActions} from "../../Store/customer-redux";
import useClickOutside from "../../hooks/useClickOutside";

const ContactBillTo = (props) => {
    const [contactOpen, setContactOpen] = useState(false);
    const contactRef = useClickOutside(() =>
        setContactOpen(false)
    );

    return (
        <>
            <div className={classes.contactCon} ref={contactRef}>
                {/*contact*/}
                <div
                    className={clsx(`${classes.contact} ${contactOpen ? classes.contactInActive : classes.contactActive}`)}
                >
                    <div className={classes.contactLeft}>
                        <div className={classes.shortName}>
                            <p className={classes.shortNameText}>
                                {
                                    props?.passOnContact?.customer_name?.split(' ')
                                        .map((char) => char.charAt(0).toUpperCase())
                                        .join('').slice(0,2)
                                }
                            </p>
                        </div>
                        <ContactInfo
                            className={classes.contact_info}
                            name={props?.passOnContact?.customer_name}
                            country={props?.passOnContact?.customer_country}
                            phoneNumber={props?.passOnContact?.phoneNumber}
                            email={props?.passOnContact?.customer_email}
                        />
                    </div>
                    <div className={classes.contactRight}>
                        {/*<img src={contactOpen ? chevronUp : chevronDown}/>*/}
                    </div>
                </div>

            </div>
        </>
    )
}

export default ContactBillTo;