// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PaymentModal_backdrop__F-dS1 {
  z-index: var(--level_5);
  height: 100vh;
}

.PaymentModal_modal__OP\\+eF {
  z-index: var(--level_6);
}`, "",{"version":3,"sources":["webpack://./src/Components/molecule/PaymentDetailsModal/PaymentModal.module.scss"],"names":[],"mappings":"AAAA;EACI,uBAAA;EACA,aAAA;AACJ;;AAEA;EACI,uBAAA;AACJ","sourcesContent":[".backdrop {\n    z-index: var(--level_5);\n    height: calc(100vh);\n}\n\n.modal {\n    z-index: var(--level_6);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"backdrop": `PaymentModal_backdrop__F-dS1`,
	"modal": `PaymentModal_modal__OP+eF`
};
export default ___CSS_LOADER_EXPORT___;
