import React from "react";
import {useSelector} from "react-redux";

const TermsAndConditions = () => {

    const displayUrl = localStorage.getItem("displayUrl");

    return(
        <iframe src={displayUrl} className="absolute top-0 left-0 w-[100vw] h-[100vh] z-[1000]"></iframe>
    )
}

export default TermsAndConditions;