import AccountIcon from 'assets/Icons/account-neon.svg'
import Button from 'Layout/Button'
import InfoIcon from 'assets/Icons/info_brown_circle.svg'

const UnderVericationPrompt = (props) => {
  const handleClick = () => {
    if (props.closeModal) props.closeModal()
  }
  return (
    <div className="w-[90vw] max-w-[500px] p-10 h-auto flex flex-col items-center justify-center">
      <img src={AccountIcon} alt="User" className="w-32 h-32 mb-5" />
      <p className="text-3xl text-[#1E333F] text-center mb-5 font-bold text-[18px]">
        {props.name || 'User'}
      </p>
      <div className="p-14 border flex flex-col items-center mt-11 mb-6 relative border-[#E9EBEC] rounded-xl">
        <div className="absolute flex flex-row items-center  text-[12px] text-[#8E6C28] -top-7 px-[12px] py-1 bg-[#FFF4DE] rounded-full ">
          <img src={InfoIcon} alt="i" className="w-[28px] aspect-square" />
          <p
            style={{
              fontWeight: 700,
            }}
          >
            Under Review
          </p>
        </div>
        <p className="text-[16px] font-medium text-center text-[#181C30]">
          We need to verify the customer before you start creating invoices. It
          will take up to 1 hour for verification.
        </p>
      </div>
      <Button
        className="w-full"
        text="Okay"
        disabled={false}
        onClick={handleClick}
      />
    </div>
  )
}
export default UnderVericationPrompt
