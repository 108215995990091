// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Modal_modal__WwJYP {
  padding: 0rem 4rem;
  box-sizing: border-box;
  background-color: white;
  min-width: 64rem;
  position: fixed;
  right: 0;
}
@media (max-width: 640px) {
  .Modal_modal__WwJYP {
    min-width: auto;
    width: 100vw;
    left: 0;
    padding: 0 2rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/Layout/Modal.module.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,sBAAA;EACA,uBAAA;EACA,gBAAA;EACA,eAAA;EACA,QAAA;AACJ;AACI;EARJ;IASQ,eAAA;IACA,YAAA;IACA,OAAA;IACA,eAAA;EAEN;AACF","sourcesContent":[".modal{\n    padding: 0rem 4rem;\n    box-sizing: border-box;\n    background-color: white;\n    min-width: 64rem;\n    position: fixed;\n    right: 0;\n\n    @media (max-width:640px) {\n        min-width: auto;\n        width: 100vw;\n        left: 0;\n        padding: 0 2rem;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": `Modal_modal__WwJYP`
};
export default ___CSS_LOADER_EXPORT___;
