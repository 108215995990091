import dayjs from 'dayjs'
import classes from './FxRateTable.module.scss'
import clsx from 'clsx'
import MobileFXRateTable from './MobileFXRateTable'
import ReportTableLayout from 'Components/ReportTable/ReportTableLayout'
import RightIcon from 'assets/Icons/arrow-right.svg'
import greenUpArrow from 'assets/Icons/greenUpArrow.svg'
import redDownArrow from 'assets/Icons/redDownArrow.svg'

import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from 'Components/ui/tooltip'
import { useEffect, useRef, useState } from 'react'
import UseApiCall from 'hooks/useApiCall'
import { apiGetHistoricFxRates } from 'services'
import INRFlag from 'assets/Images/INR_Flag.svg'
import USDFlag from 'assets/Images/USD_Flag.svg'
import EURFlag from 'assets/Images/EUR_Flag_new.svg'
import GBPFlag from 'assets/Images/GBP_Flag.svg'
import Pagination from 'Components/atom/Pagination/Pagination'
import { getDatefromUnix } from 'utils/utils'
import Loader from 'Components/atom/Loader/Loader'
import TableLayout from './TableLayout'
import ReportTableLayoutFxPage from 'Components/ReportTable/ReportTableLayoutFxPage'
import Shimmer from 'Layout/Shimmer'

const tableHeader = [
  {
    index: 0,
    sellCurrency: {
      name: 'USD',
      image: USDFlag,
    },
    buyCurrency: {
      name: 'INR',
      image: INRFlag,
    },
  },
  {
    index: 1,
    sellCurrency: {
      name: 'EUR',
      image: EURFlag,
    },
    buyCurrency: {
      name: 'INR',
      image: INRFlag,
    },
  },
  {
    index: 2,
    sellCurrency: {
      name: 'GBP',
      image: GBPFlag,
    },
    buyCurrency: {
      name: 'INR',
      image: INRFlag,
    },
  },
]

const DEFAULT_PAGE_SIZE = 35

const FXRateTable = (props) => {
  const [rateList, setRateList] = useState([])
  const [groupedData, setGroupedData] = useState()
  const [currentPage, setCurrentPage] = useState(1)
  const [markup, setMarkup] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [openDate, setOpenDate] = useState('');

  const [getServerFxRates, fetching, fxRatesData] = UseApiCall(
    apiGetHistoricFxRates
  )

  const ModifiedData = (fxRateEntries) => {
    setGroupedData(fxRateEntries?.reduce((acc, entry) => {
      const date = new Date(parseInt(entry?.timestamp)).toDateString();
      const timestamp = parseInt(entry?.timestamp);
      
      if (!acc[date]) {
        acc[date] = [];
      }
      
      acc[date].push({
        timestamp: timestamp,
        currencyFxRates: entry.currencyFxRates
      });
      
      return acc;
    }, {}));
  }
  
  useEffect(() => {
    setIsLoading(true)
    getServerFxRates({ size: DEFAULT_PAGE_SIZE, page: currentPage - 1 })
  }, [currentPage])

  useEffect(() => {
    setTimeout(() => {
      // After 500ms, update the data
      ModifiedData(fxRatesData?.data?.fxRateEntries)
      setRateList(fxRatesData?.data?.fxRateEntries)
      setMarkup(fxRatesData?.data?.userFxMarkup)
      setOpenDate(new Date(parseInt(fxRatesData?.data?.fxRateEntries[0]?.timestamp)).toDateString())
      setIsLoading(false) // Turn off the loader
    }, 500) // Simulate a 500ms API call
  }, [fxRatesData])

  // console.log(fxRatesData?.data?.fxRateEntries[0]?.timestamp)

  // const handleToggleDateCollapse = (date) => {
  //   setOpenDate(date === openDate ? '' : date);
  // };

  return (
    <div className="">
      <>
        <ReportTableLayoutFxPage
          className={clsx(
            classes.table,
            'relative pb-[64px] my-[4rem]',
            isLoading && 'md:min-h-[70vh] xl:min-h-[60vh]'
          )}
        >
          <div className={classes.headContainer}>
            <div className={classes.container}>
              <span className={classes.headingTextDate}>Date & Time</span>
              <span className={classes.headingText}>USD / INR</span>
              <span className={classes.headingText}>EUR / INR</span>
              <span className={classes.headingText}>GBP / INR</span>
            </div>
          </div>
          
          <div className={classes.ratesContainer}>
            <div className={classes.ratesWrapper}>
              {
              isLoading ? (
                [1,3,4,5]?.map((item, index) => (
                  <Shimmer key={index} className="w-[100%] h-[80px] mt-[12px] "/>
                )) 
              ) : (
                groupedData &&
                Object.keys(groupedData).map((date,dateIndex) => {
                  const currentDateEntries = groupedData[date];
                  const previousDateEntries = groupedData[Object.keys(groupedData)[dateIndex + 1]];
                  return (<TableLayout isLoading={isLoading} currentDateEntries={currentDateEntries} previousDateEntries={previousDateEntries} groupedData={groupedData} date={date} markup={markup} openDate={openDate} setOpenDate={setOpenDate} />)
                })
              )
              }
            </div>
          </div>
          {fxRatesData?.data?.totalElements > DEFAULT_PAGE_SIZE && groupedData && (
            <div className="w-full absolute bottom-[20px] right-[0px]">
              <Pagination
                totalElements={fxRatesData?.data?.totalElements}
                pageSize={DEFAULT_PAGE_SIZE}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
              />
            </div>
          )}
        </ReportTableLayoutFxPage>
        <div className="md:hidden">
          {isLoading ? (
            <div className="absolute top-0 left-0 bottom-0 right-0 flex items-center justify-center">
              {/* <Loader /> */}
            </div>
          ) : (
            // <MobileFXRateTable
            //   tableData={rateList}
            //   service={getServerFxRates}
            //   totalElements={fxRatesData?.data?.totalElements}
            //   pageSize={DEFAULT_PAGE_SIZE}
            //   currentPage={currentPage}
            //   setCurrentPage={setCurrentPage}
            // />
            null
          )}
        </div>
      </>
    </div>
  )
}

export default FXRateTable

export const ConversionValue = ({ conversionData, previousDateEntry, previousEntry,isLastEntry, markup }) => {
  const { midMarketRate, sellCurrency, buyCurrency } = conversionData

  // const calculateTrendLastEntry = (previousRate, currentRate) => {
  //   if (previousRate < currentRate) {
  //     return 'up'; // Green arrow for increasing trend
  //   } else if (previousRate > currentRate) {
  //     return 'down'; // Red arrow for decreasing trend
  //   } else {
  //     return 'equal'; // Equal sign for no change in rate
  //   }
  // };


  const calculateTrend = (previousRate, currentRate) => {
    if (Number(previousRate) < Number(currentRate)) {
      return 'up'; // Green arrow for increasing trend
    } else if (Number(previousRate) > Number(currentRate)) {
      return 'down'; // Red arrow for decreasing trend
    } else {
      return 'equal'; // Equal sign for no change in rate
    }
  };

  const trend = previousEntry || previousDateEntry
    ? calculateTrend( previousEntry?.midMarketRate || previousDateEntry?.midMarketRate  ,conversionData?.midMarketRate)
    : 'equal';
  return (
    <TooltipProvider delayDuration={100}>
      <Tooltip open={!!Number(markup)}>
        <TooltipTrigger asChild>
          <div
            className={clsx(
              'flex flex-row items-center gap-[12px] ',
              !!Number(markup) && 'cursor-pointer'
            )}
          >
            <div className={classes.rateValue}>
              <p>{midMarketRate}</p>
              {/* <p className={classes.subText}>{buyCurrency}</p> */}
              <p>
                {trend === 'up' && <img src={greenUpArrow} alt="Up Trend" className={classes.trendImg} />}
                {trend === 'down' && <img src={redDownArrow} alt="Down Trend"  className={classes.trendImg}/>}
                {trend === 'equal' && ' '}
              </p>
            </div>
          </div>
        </TooltipTrigger>
        <TooltipContent
          className="rounded-[12px] relative"
          side="bottom"
          sideOffset={24}
        >
          <ConversionValueTooltip
            conversionData={conversionData}
            markup={markup}
          />
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  )
}

const ConversionValueTooltip = ({ conversionData, markup }) => {
  const { midMarketRate, userRate } = conversionData
  return (
    <div className="flex flex-col w-[224px] p-[12px] gap-[10px]">
      <div className="flex flex-row items-center justify-between color-white">
        <p className="opacity-60 text-[12px]">Mid market rate</p>
        <p className="text-2xl">{midMarketRate}</p>
      </div>
      <hr className="opacity-[0.06]" />
      <div className="flex flex-row items-center justify-between color-white">
        <p className="opacity-60 text-[12px]">FX markup applied</p>
        <p className="text-2xl">{markup}%</p>
      </div>
      <hr className="opacity-[0.06]" />
      <div className="flex flex-row items-center justify-between color-white">
        <p className="opacity-60 text-[12px]">Your rate</p>
        <p className="text-2xl">{userRate}</p>
      </div>
    </div>
  )
} 


            // <thead className={clsx(classes.headContainer, 'rounded-t-xl')}>
            //   <th className={clsx(classes.cell_header1)}>
            //     <p className={clsx(classes.head, 'text-xl')}>Date & Time</p>
            //   </th>
            //   {tableHeader?.map((header, index) => (
            //     <th
            //       key={`${header.toString()}-${index}`}
            //       className={clsx(classes.cell_header1, 'pr-10')}
            //     >
            //       <div className="flex flex-row items-center gap-[4px]">
            //         {/* <img src={header?.sellCurrency?.image} alt="sell-currency" /> */}
            //         <p className="text-xl">{header?.buyCurrency?.name}</p>
            //         {/* <div className="px-[8px] pt-[3px] pb-[2px] rounded-[4px] border border-[#A3A4AC] color-[#A3A4AC]">
            //         </div> */}
            //           <p className="leading-normal">/</p>
            //         <p className="text-xl">{header?.sellCurrency?.name}</p>
            //         {/* <img src={header?.buyCurrency?.image} alt="buy-currency" /> */}
            //       </div>
            //     </th>
            //   ))}
            // </thead>


                  // <div key={index} className={`${classes.row}`}>
                    // <div className={classes.cell}>
                    //   <div className="flex flex-col items-start gap-[4px]">
                    //     <p className="text-2xl">
                    //       {dayjs(getDatefromUnix(data?.timestamp)).format(
                    //         'DD MMM YYYY'
                    //       )}
                    //     </p>
                        // <p className={clsx(classes.subText, 'text-lg')}>
                        //   {dayjs(getDatefromUnix(data?.timestamp))
                        //     .local()
                        //     .format('hh:mm a')}{' '}
                        //   IST
                        // </p>
                    //   </div>
                    // </div>
                    // <div className={classes.cell}>
                    //   <ConversionValue
                    //     conversionData={data?.currencyFxRates[0]}
                    //     markup={markup}
                    //   />
                    // </div>
                    // <div className={classes.cell}>
                    //   <ConversionValue
                    //     conversionData={data?.currencyFxRates[1]}
                    //     markup={markup}
                    //   />
                    // </div>
                    // <div className={classes.cell}>
                    //   <ConversionValue
                    //     conversionData={data?.currencyFxRates[2]}
                    //     markup={markup}
                    //   />
                    // </div>
                  // </div>



          // <div className={classes.ratesContainer}>
          //   <div className={classes.ratesWrapper}>
          //     {isLoading ? (
          //       <div className="absolute top-0 left-0 bottom-0 right-0 flex items-center justify-center">
          //         <Loader />
          //       </div>
          //     ) : (
          //       rateList &&
          //       rateList?.length > 0 &&
          //       rateList?.map((data, index) => (
                  // <div className={classes.ratesBox}>
                  //   <div className={classes.ratesBoxTop}>
                  //     <div className={classes.dateBox}>
                  //       <span className={classes.dateText}> 
                  //         <span className={classes.dateTextDay}>
                  //               {dayjs(getDatefromUnix(data?.timestamp)).format(
                  //                     'DD'
                  //               )}
                  //         </span>
                  //               {dayjs(getDatefromUnix(data?.timestamp)).format(
                  //                     'MMM YYYY'
                  //               )}
                  //       </span>
                  //     </div>
                  //   </div>
                    // <div className={classes.ratesBoxBottom}>
                    //   <div className={classes.ratesBoxBottomLeft}>
                    //     <img src={arrowUp} alt='arrow-up'/>
                    //   </div>
                    //   <div className={classes.ratesBoxBottomRight}>
                    //     <div className={classes.ratesBoxBottomRow}>
                    //         <div className={classes.ratesBoxBottomRowContent}>
                    //           <div className={classes.boxCell}>
                    //             <div className="flex flex-col items-start gap-[4px]">
                    //               {/* <p className="text-2xl">
                    //                 {dayjs(getDatefromUnix(data?.timestamp)).format(
                    //                   'DD MMM YYYY'
                    //                 )}
                    //               </p> */}
                    //               <p className={clsx(classes.timeBox)}>
                    //                 {dayjs(getDatefromUnix(data?.timestamp))
                    //                   .local()
                    //                   .format('hh:mm a')}{' '}
                    //                 IST
                    //               </p>
                    //             </div>
                    //           </div>
                    //           <div className={classes.boxCell}>
                    //             <ConversionValue
                    //               conversionData={data?.currencyFxRates[0]}
                    //               markup={markup}
                    //             />
                    //           </div>
                    //           <div className={classes.boxCell}>
                    //             <ConversionValue
                    //               conversionData={data?.currencyFxRates[1]}
                    //               markup={markup}
                    //             />
                    //           </div>
                    //           <div className={classes.boxCell}>
                    //             <ConversionValue
                    //               conversionData={data?.currencyFxRates[2]}
                    //               markup={markup}
                    //             />
                    //           </div>  
                    //       </div>
                    //     </div>
                    //   </div>
                    // </div>
          //         </div>
          //       ))
          //     )}
          //   </div>
          // </div>