import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import './BusinessDetails.css';
import DoneSvg from './done.svg';
import DividerDone from './divider.svg';
import Divider from './dividerempty.svg';
import BusinessDetailsSvg from './businessDetails.svg';
import BankSvg from './bank.svg';
import FlagSvg from './flag.svg';
import TextInput from "Layout/TextInput";
import MobileInputSimple from "Layout/MobileInputSimple";
import PlusSvg from "./plusicon.svg";
import ArrowLeft from "./arrowLeft.svg";
import WhiteBankSvg from './BankWhite.svg';
import useElementOnScreen from "hooks/useElementOnScreen";
import { apiGetBusinessType, apiSubmitLeadForm, apiGetTermsAndConditions } from "services";
import { CompleteFormActions } from "Store/form-redux";
import UseApiCall from "hooks/useApiCall";
import CustomSelectorValues from "Components/atom/CustomerSelecterValues/CustomSelectorValues";
import FileBrowserNew from 'Components/atom/FileBrowserAnother/FileBrowserNew';
import SmallFileBrowser from 'Components/atom/FileBrowserAnother/SmallFileBrowser';
import PendingIconSvg from './watch-pending.svg';
import BackButtonSvg from './arrowLeftLight.svg';
import { useSnackbar } from 'notistack'
import clsx from 'clsx'
import useApiCall from "hooks/useApiCall";
import { Navigate } from "react-router-dom";

const companyIdentifiersDocumentArray = [
    {name: "CIN/ LLPIN - Certificate of Incorporation/ Registration", type: "CIN"},
    {name: "GSTIN - GST Registration", type: "GSTIN"},
    {name: "Company PAN - Copy of company PAN card", type: "PAN"}
]

// function useApplicationId() {
//     return useSelector((state) => state.signup.completeData?.applicationId);
// }

const BusinessDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
//   const UUID = useSelector((state) => state.signup.completeData?.applicationId);
  const { enqueueSnackbar } = useSnackbar();
  const [currentStep, setCurrentStep] = useState(2);
  const [businessName, setBusinessName] = useState('');
  const [webisteName, setWebisteName] = useState('');
  const [businessType, setBusinessType] = useState('');
  const [businessService, setBusinessService] = useState('');
  const [businessAddressLine1, setBusinessAddressLine1] = useState('');
  const [businessAddressLine2, setBusinessAddressLine2] = useState('');
  const [businessAddressCity, setBusinessAddressCity] = useState('');
  const [businessAddressState, setBusinessAddressState] = useState('');
  const [businessAddressPostalCode, setBusinessAddressPostalCode] = useState('');
  const [cinNumber, setCinNumber] = useState('');
  const [gstinNumber, setGstinNumber] = useState('');
  const [businessPan, setBusinessPan] = useState('');
  const [businessNameValid, setBusinessNameValid] = useState(false);
  const [webisteNameValid, setWebisteNameValid] = useState(false);
  const [businessTypeValid, setBusinessTypeValid] = useState(false);
  const [businessServiceValid, setBusinessServiceValid] = useState(false);
  const [businessAddressLine1Valid, setBusinessAddressLine1Valid] = useState(false);
//   const [businessAddressLine2Valid, setBusinessAddressLine2Valid] = useState(false);
  const [businessAddressCityValid, setBusinessAddressCityValid] = useState(false);
  const [businessAddressStateValid, setBusinessAddressStateValid] = useState(false);
  const [businessAddressPostalCodeValid, setBusinessAddressPostalCodeValid] = useState(false);
  const [cinNumberValid, setCinNumberValid] = useState(false);
  const [gstinNumberValid, setGstinNumberValid] = useState(false);
  const [businessPanValid, setBusinessPanValid] = useState(false);
  const [directorsArray, setDirectorsArray] = useState([{
      name: "",
      isValid: false,
      panFiles: {},
      director_pan : "",
    },
  ]);
  const [ownersArray, setOwnersArray] = useState([{
      name: "",
      isValid: false,
      panFiles: {},
      owner_pan : "",
    },
  ]);
  const [fideoUsersArray, setFideoUsersArray] = useState([
    {
        name: "",
        mobile: "",
        mobileCodeInput: '+91',
        isMobileValid: false,
        user_pan : "",
    }
  ]);
  const [mobileCodeInput, setMobileCodeInput] = useState('+91');
  const [mobile, setMobile] = useState('');
  const [isMobileValid, setIsMobileValid] = useState(false);
  const [fideoUser, setFideoUser] = useState('');
  const [fideoUserValid, setFideoUserValid] = useState(false);
  const [key, setKey] = useState(false);
  const [businessTypeArray, setBusinessTypeArray] = useState([false]);
  const [uuidId, setUuidId] = useState("");
  const [cinDocument, setCinDocument] = useState({});
  const [gstinDocument, setGstinDocument] = useState({});
  const [panDocument, setPanDocument] = useState({});
//   const [ownersPan, setOwnersPan] = useState([])
//   const [directorsPan, setDirectorsPan] = useState([])
  const [fideoUsersPan, setFideoUsersPan] = useState({})
  const [fideoUsersPanNumber, setFideoUsersPanNumber] = useState('')
  const [bankStatementDocument, setBankStatementDocument] = useState({})
  const [tickCheckboxFinal, setTickCheckboxFinal] = useState(false);
  const [oneTimeClickedFirstPage, setOneTimeClickedFirstPage] = useState(false);
  const [oneTimeClickedSecondPage, setOneTimeClickedSecondPage] = useState(false);
  const [windowSize, setWindowSize] = useState(700);
  const [checkboxes, setCheckboxes] = useState({
    currentAccountCheckbox: false,
    foreignAccountCheckbox: false,
  });

  const handleCheckboxChange = (checkboxName) => {
    setCheckboxes((prevState) => ({
      ...prevState,
      [checkboxName]: !prevState[checkboxName],
    }));
  };


  const [getTermsAndConditions] = useApiCall(apiGetTermsAndConditions,(res) => {

      localStorage.setItem("displayUrl",res?.data);
      window.open("/terms-and-conditions","_blank")

  },(err)=>{
      console.log(err)
  })

  const handleClick = (e) => {
      e.preventDefault();
      getTermsAndConditions();
  }

  useEffect(() => {
    const url = window.location.pathname;
    const parts = url.split('/');
    setUuidId(parts[parts?.length - 1]);

    const searchParams = new URLSearchParams(document.location.search);
    setMobile(searchParams.get('ownerContact[phone]'));

    const completeUrl = window.location.href;

    const businessNameParam = completeUrl?.split('businessName=')[1];
    setBusinessName(businessNameParam?.replace(/\%20/g, ' ') || '');

    const patternForFirstName = /primaryBusiness\[first\]=(.*?)&primaryBusiness\[last\]=/;

    const matchFirstName = completeUrl.match(patternForFirstName);
    let firstNameFromUrl = "";
    let lastNameFromUrl = "";

    if (matchFirstName) {
        const capturedText = matchFirstName[1];
        firstNameFromUrl = capturedText?.replace(/\%20/g, ' ');
    }

    const patternForLastName = /primaryBusiness\[last\]=(.*?)&email=/;

    const matchLastname = completeUrl.match(patternForLastName);

    if (matchLastname) {
        const capturedText = matchLastname[1];
        lastNameFromUrl = capturedText?.replace(/\%20/g, ' ');
    }

    const fideoUserValue = `${firstNameFromUrl} ${lastNameFromUrl}`
    setFideoUser(fideoUserValue)
    // const ownerNameValue = `${searchParams.get('primaryBusiness[first]')} ${searchParams.get('primaryBusiness[last]')}`
    // const updatedOwnersArray = [...ownersArray];
    // updatedOwnersArray[0] = { name: ownerNameValue, isValid: ownerNameValue?.trim() !== "" };
    // setOwnersArray(updatedOwnersArray);
  }, [])

  const [formDataCurrentAccount, setFormDataCurrentAccount] = useState({
    "Account Number*": {
      value: "",
      placeholder: "XXXX-XXXX-XXXX",
    },
    "Account Holder Name*": {
      value: "",
      placeholder: "Enter Name",
    },
    "Bank Name*": {
      value: "",
      placeholder: "Enter Bank Name",
    },
    "Branch Name*": {
      value: "",
      placeholder: "Enter Branch Name",
    },
    "Bank Address*": {
      value: "",
      placeholder: "Enter Bank Address",
    },
    "IFSC Code*": {
      value: "",
      placeholder: "e.g. ICICI01234",
    },
    "BIC/ SWIFT Code": {
      value: "",
      placeholder: "e.g. AAAA-US-11-XXX",
    },
  });

  const handleInputChangeCurrentAccount = (name, value) => {
    setFormDataCurrentAccount((prevFormData) => ({
      ...prevFormData,
      [name]: {
        ...prevFormData[name],
        value: value,
      },
    }));
  };

  const [accountNumberValid, setAccountNumberValid] = useState(false);
  const [accountNameValid, setAccountNameValid] = useState(false);
  const [bankNameValid, setBankNameValid] = useState(false);
  const [branchNameValid, setBranchNameValid] = useState(false);
  const [bankAddressValid, setBankAddressValid] = useState(false);
  const [ifscCodeValid, setIFSCCodeValid] = useState(false);
  const [bicCodeValid, setBICCodeValid] = useState(false);
  
  const handleInputValidityCurrentAccount = (fieldName, value) => {
    switch (fieldName) {
        case "Account Number*":
            setAccountNumberValid(value);
            break;
        case "Account Holder Name*":
            setAccountNameValid(value);
            break;
        case "Bank Name*":
            setBankNameValid(value);
            break;
        case "Branch Name*":
            setBranchNameValid(value);
            break;
        case "Bank Address*":
            setBankAddressValid(value);
            break;
        case "IFSC Code*":
            setIFSCCodeValid(value);
            break;
        case "BIC/ SWIFT Code":
            setBICCodeValid(value);
            break;
        default:
            break;
    }
  };

  const [formDataForeignAccount, setFormDataForeignAccount] = useState({
    "Account Number*": {
      value: "",
      placeholder: "XXXX-XXXX-XXXX",
    },
    "Account Holder Name*": {
      value: "",
      placeholder: "Enter Name",
    },
    "Bank Name*": {
      value: "",
      placeholder: "Enter Bank Name",
    },
    "Branch Name*": {
        value: "",
        placeholder: "Enter Branch Name",
    },
    "Bank Address*": {
      value: "",
      placeholder: "Enter Bank Address",
    },
    "IFSC Code*": {
      value: "",
      placeholder: "e.g. ICICI01234",
    },
    "BIC/ SWIFT Code": {
      value: "",
      placeholder: "e.g. AAAA-US-11-XXX",
    },
  });

  const handleInputChangeForeignAccount = (name, value) => {
    setFormDataForeignAccount((prevFormData) => ({
      ...prevFormData,
      [name]: {
        ...prevFormData[name],
        value: value,
      },
    }));
  };

  const [accountNumberForeignValid, setAccountNumberForeignValid] = useState(false);
  const [accountNameForeignValid, setAccountNameForeignValid] = useState(false);
  const [bankNameForeignValid, setBankNameForeignValid] = useState(false);
  const [branchNameForeignValid, setBranchNameForeignValid] = useState(false);
  const [bankAddressForeignValid, setBankAddressForeignValid] = useState(false);
  const [ifscCodeForeignValid, setIFSCCodeForeignValid] = useState(false);
  const [bicCodeForeignValid, setBICCodeForeignValid] = useState(false);
  
  const handleInputValidityForeignAccount = (fieldName, value) => {
    switch (fieldName) {
        case "Account Number*":
            setAccountNumberForeignValid(value);
            break;
        case "Account Holder Name*":
            setAccountNameForeignValid(value);
            break;
        case "Bank Name*":
            setBankNameForeignValid(value);
            break;
        case "Branch Name*":
            setBranchNameForeignValid(value);
            break;
        case "Bank Address*":
            setBankAddressForeignValid(value);
            break;
        case "IFSC Code*":
            setIFSCCodeForeignValid(value);
            break;
        case "BIC/ SWIFT Code":
            setBICCodeForeignValid(value);
            break;
        default:
            break;
    }
  };

  const handleBusinessTypeChange = (prop) => {
    setBusinessType(prop)
    setBusinessTypeValid(prop === '')
  }

  const isFirstPageValid = () => {
    if (!businessType) {
        setBusinessTypeValid(true);
        return false;
    } else {
        if (businessType === "Partnership" && gstinNumber === "") return false;
    }
    if (
        (
            !businessNameValid ||
            !webisteNameValid ||
            !businessServiceValid ||
            !businessAddressLine1Valid ||
            !businessAddressCityValid ||
            !businessAddressStateValid ||
            !businessAddressPostalCodeValid ||
            !fideoUserValid ||
            !isMobileValid
        ) || (
            !cinNumber &&
            !gstinNumber &&
            !businessPan
        )
    )
        return false;
    
    if (
        ((!cinDocument || Object.keys(cinDocument).length === 0) && (!gstinDocument || Object.keys(gstinDocument).length === 0) && (!panDocument || Object.keys(panDocument).length === 0)) ||
        (!fideoUsersPan || Object.keys(fideoUsersPan).length === 0) ||
        // (!bankStatementDocument || Object.keys(bankStatementDocument).length === 0)
        // ||
        !directorsArray.every((director) => (!director || !director.panFiles || Object.keys(director.panFiles).length > 0)) ||
        !ownersArray.every((owner) => (!owner || !owner.panFiles || Object.keys(owner.panFiles).length > 0))
    ) {
        return false;
    }

    const isDirectorsArrayValid = directorsArray.every((director) => director.isValid);
    const isOwnersArrayValid = ownersArray.every((owner) => owner.isValid);

    return isDirectorsArrayValid && isOwnersArrayValid;
  };

  const handleFirstPageNext = () => {
    setOneTimeClickedFirstPage(true);

    if (isFirstPageValid()) {
        if ((currentStep < 4)) {
            setCurrentStep(currentStep + 1);
        }
    } else {
        try {
            enqueueSnackbar('Please fill all the required fields!', { variant: 'error' })
        } catch (error) {}
    }
  };

  const isSecondPageValid = () => {
  
    if (!checkboxes.currentAccountCheckbox && !checkboxes.foreignAccountCheckbox) {
        handleCheckboxChange('currentAccountCheckbox');
        return false;
    }

    if (checkboxes.currentAccountCheckbox) {
        if (
            !accountNumberValid ||
            !accountNameValid ||
            !bankNameValid ||
            !branchNameValid ||
            !bankAddressValid ||
            !ifscCodeValid
            //  ||
            // !bicCodeValid
        ) {
            return false;
        }
    }

    if (checkboxes.foreignAccountCheckbox) {
        if (
            !accountNumberForeignValid ||
            !accountNameForeignValid ||
            !bankNameForeignValid ||
            !branchNameForeignValid ||
            !bankAddressForeignValid ||
            !ifscCodeForeignValid
            //  ||
            // !bicCodeForeignValid
        ) {
            return false;
        }
    }

    // console.log(cinDocument, !cinDocument, gstinDocument, !gstinDocument, panDocument, !panDocument)

    if (
        // ((!cinDocument || Object.keys(cinDocument).length === 0) && (!gstinDocument || Object.keys(gstinDocument).length === 0) && (!panDocument || Object.keys(panDocument).length === 0)) ||
        // (!fideoUsersPan || Object.keys(fideoUsersPan).length === 0) ||
        (!bankStatementDocument || Object.keys(bankStatementDocument).length === 0)
        // ||
        // !directorsArray.every((director) => (!director || !director.panFiles || Object.keys(director.panFiles).length > 0)) ||
        // !ownersArray.every((owner) => (!owner || !owner.panFiles || Object.keys(owner.panFiles).length > 0))
    ) {
        return false;
    }
    

    if (!tickCheckboxFinal) {
        return false;
    }
  
    return true;
  };
  

  const handleSecondPageNext = () => {
    setOneTimeClickedSecondPage(true);

    if (isSecondPageValid()) {
        if ((currentStep < 4)) {

            const businessDirectorDetails = {
                noOfDirectors: directorsArray?.length,
            };
              
            directorsArray?.slice(0, 4).forEach((director, index) => {
                const array = director.name.split(' ');
                const directorFirstName = array[0];
                let directorLastName = "";
                if (array?.length > 1) {
                    array.shift();
                    let str = array.join(' ');
                    directorLastName = str;
                }
                const keyFirstName = index === 0 ? 'directorFirstName' : `directorFirstName${index+1}`;
                const keyLastName = index === 0 ? 'directorLastName' : `directorLastName${index+1}`;
                const keyPanName = index === 0 ? 'directorPAN' : `directorPAN${index+1}`;
                businessDirectorDetails[keyFirstName] = directorFirstName;
                businessDirectorDetails[keyLastName] = directorLastName;
                businessDirectorDetails[keyPanName] = director?.director_pan;
            });
            
            if (directorsArray?.length === 5) {
                // const otherDirectorsNames = directorsArray.slice(4).map((director) => director.name).join(', ');
                const otherDirectorsNames = directorsArray[4]?.name;
                businessDirectorDetails['otherDirectorDetails'] = otherDirectorsNames;
            }

            const businessOwnerDetails = {
                noOfOwners: ownersArray?.length,
            };
            
            ownersArray?.slice(0, 4).forEach((owner, index) => {
                const array = owner.name.split(' ');
                const ownerFirstName = array[0];
                let ownerLastName = "";
                if (array?.length > 1) {
                    array.shift();
                    let str = array.join(' ');
                    ownerLastName = str;
                }
                const keyFirstName = index === 0 ? 'primaryOwnerFirstName' : `ownerFirstName${index+1}`;
                const keyLastName = index === 0 ? 'primaryOwnerLastName' : `ownerLastName${index+1}`;
                const keyPanName = index === 0 ? 'primaryOwnerPAN' : `ownerPAN${index+1}`;
                businessOwnerDetails[keyFirstName] = ownerFirstName;
                businessOwnerDetails[keyLastName] = ownerLastName;
                businessOwnerDetails[keyPanName] = owner?.owner_pan;
            });
            
            if (ownersArray?.length === 5) {
                const otherOwnersNames = ownersArray[4]?.name;
                businessOwnerDetails['otherOwnerDetails'] = otherOwnersNames;
            }

            const fideoUserArray = fideoUser.split(' ');
            const account_user_first_name = fideoUserArray[0];
            let account_user_last_name = "";
            if (fideoUserArray?.length > 1) {
                fideoUserArray.shift();
                let str = fideoUserArray.join(' ');
                account_user_last_name = str;
            }
              
            const body = {
                application_id: uuidId,
                business_name: businessName,
                business_country: "IN",
                website: webisteName,
                user_pan: fideoUsersPanNumber,
                business_type: businessType,
                brand_name: businessName,
                product_description: businessService,
                address_details: {
                    address_line1: businessAddressLine1,
                    address_line2: businessAddressLine2,
                    city: businessAddressCity,
                    state: businessAddressState,
                    postal_code: businessAddressPostalCode
                },
                cin_llpin: cinNumber,
                gstin: gstinNumber,
                business_pan: businessPan,
                business_director_details: businessDirectorDetails,
                business_owner_details: businessOwnerDetails,
                account_user_details: {
                    account_user_first_name: account_user_first_name,
                    account_user_last_name: account_user_last_name,
                    account_user_country_code: mobileCodeInput.substring(1),
                    account_user_mobile_number: mobile,
                    account_user_pan_number: fideoUsersPanNumber,
                },
                account_details: {
                    accountNumber: formDataCurrentAccount["Account Number*"].value,
                    accountName: formDataCurrentAccount["Account Holder Name*"].value,
                    bankAddress: formDataCurrentAccount["Bank Address*"].value,
                    bankName: formDataCurrentAccount["Bank Name*"].value,
                    branchName: formDataCurrentAccount["Branch Name*"].value,
                    ifscCode: formDataCurrentAccount["IFSC Code*"].value,
                    swiftCode: formDataCurrentAccount["BIC/ SWIFT Code"].value,
                    // "routingCode":"test"
                },
                terms_and_conditions: tickCheckboxFinal
            }
            if (checkboxes.foreignAccountCheckbox) {
                body["eefc_account_details"] = {
                    eefcAccountNumber: formDataForeignAccount["Account Number*"].value,
                    eefcAccountName: formDataForeignAccount["Account Holder Name*"].value,
                    eefcBankAddress: formDataForeignAccount["Bank Address*"].value,
                    eefcBankName: formDataForeignAccount["Bank Name*"].value,
                    eefcBranchName: formDataForeignAccount["Branch Name*"].value,
                    eefcIfscCode: formDataForeignAccount["IFSC Code*"].value,
                    eefcSwiftCode: formDataForeignAccount["BIC/ SWIFT Code"].value,
                    // "eefcRoutingCode":"test"
                };
            }
            postLeadData(body);
            // setCurrentStep(currentStep + 1);
        }
    } else {
        try {
            enqueueSnackbar('Please fill all the required fields!', { variant: 'error' })
        } catch (error) {}
    }
  };

//   const handleNext = () => {
//     if (currentStep < 4) {
//         setCurrentStep(currentStep + 1);
//     }
//   };

  const handlePrev = () => {
    if (currentStep > 2) {
      setCurrentStep(currentStep - 1);
    }
  };

  const removeDirector = (indexToRemove) => {
    const updatedDirectorsArray = [...directorsArray];
    updatedDirectorsArray.splice(indexToRemove, 1);
    setDirectorsArray(updatedDirectorsArray);
  };

  const setDirectorsPanForDirector = (index, files) => {
    setDirectorsArray((prevDirectorsArray) => {
      const updatedDirectorsArray = [...prevDirectorsArray];
      updatedDirectorsArray[index] = {
        ...updatedDirectorsArray[index],
        panFiles: files,
      };
      return updatedDirectorsArray;
    });
  };

    const setDirectorsPanNumberForDirector = (index, panNumber) => {
        setDirectorsArray((prevDirectorsArray) => {
            const updatedDirectorsArray = [...prevDirectorsArray];
            updatedDirectorsArray[index] = {
                ...updatedDirectorsArray[index],
                director_pan: panNumber,
            };
            return updatedDirectorsArray;
        });
    };

  const removeOwner = (indexToRemove) => {
    const updatedOwnersArray = [...ownersArray];
    updatedOwnersArray.splice(indexToRemove, 1);
    setOwnersArray(updatedOwnersArray);
  };

  const setOwnersPanForOwner = (index, files) => {
    setOwnersArray((prevOwnersArray) => {
      const updatedOwnersArray = [...prevOwnersArray];
      updatedOwnersArray[index] = {
        ...updatedOwnersArray[index],
        panFiles: files,
      };
      return updatedOwnersArray;
    });
  };

  const setOwnersPanNumberForOwner = (index, panNumber) => {
    setOwnersArray((prevOwnersArray) => {
      const updatedOwnersArray = [...prevOwnersArray];
      updatedOwnersArray[index] = {
        ...updatedOwnersArray[index],
          owner_pan: panNumber,
      };
      return updatedOwnersArray;
    });
  };

  const removeFideoUsers = (indexToRemove) => {
    const updatedFideoUsersArray = [...fideoUsersArray];
    updatedFideoUsersArray.splice(indexToRemove, 1);
    setFideoUsersArray(updatedFideoUsersArray);
  };

  const redirectToSignUp = () => {
    navigate("/sign-up");
  }

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
      setWindowSize(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const [containerRef, isVisible] = useElementOnScreen({
    root: null,
    rootMargin: "0px",
    threshold: 0.5
  })
  const [companyParticularsRef, companyParticularsVisible] = useElementOnScreen({
    root: null,
    rootMargin: "0px",
    threshold: 0.6
  })
  const [registrationAndIdentificationRef, registrationAndIdentificationVisible] = useElementOnScreen({
    root: null,
    rootMargin: "0px",
    threshold: 0.8
  })
  const [businessDirectorsRef, businessDirectorsVisible] = useElementOnScreen({
    root: null,
    rootMargin: "0px",
    threshold: 0.8
  })
  const [fideoAccountUsersRef, fideoAccountUsersVisible] = useElementOnScreen({
    root: null,
    rootMargin: "0px",
    threshold: 0.3
  })

  const [containeSecondRef, isSecondVisible] = useElementOnScreen({
    root: null,
    rootMargin: "0px",
    threshold: 0.5
  })
  const [bankDetailsRef1, bankDetailsVisible1] = useElementOnScreen({
    root: null,
    rootMargin: "0px",
    threshold: 0.5
  })
  const [bankDetailsRef2, bankDetailsVisible2] = useElementOnScreen({
    root: null,
    rootMargin: "0px",
    threshold: 0.5
  })
  const [bankDetailsRef3, bankDetailsVisible3] = useElementOnScreen({
    root: null,
    rootMargin: "0px",
    threshold: 0.5
  })
  const [kycRef, kycVisible] = useElementOnScreen({
    root: null,
    rootMargin: "0px",
    threshold: 0.6
  })

  useEffect(() => {
    if (isMobile && currentStep === 2) {
        const section = document.getElementById("business_directors_button")
        if (businessDirectorsVisible) {
            if (section) {
            section.scrollBy({
                left: 400,
                top: 0,
                behavior: "smooth",
            })
            }
        }
        if (registrationAndIdentificationVisible) {
            if (section) {
                section.scrollBy({
                    left: -600,
                    top: 0,
                    behavior: "smooth",
                })
            }
        }
    }
  }, [businessDirectorsVisible, registrationAndIdentificationVisible])

//   const scrollToSection = (sectionId) => {
//     const section = document.getElementById(sectionId);
//     if (section) {
//         section.scrollIntoView({ block: 'start', behavior: 'smooth' });
//       }
//   };

  const scrollToSectionMultipleTimes = (sectionId, scrollCount = 3) => {
    const section = document.getElementById(sectionId);
    
    if (scrollCount > 0 && section) {
        section.scrollIntoView({ block: 'start', behavior: 'smooth' });
        
        setTimeout(() => {
            scrollToSectionMultipleTimes(sectionId, scrollCount - 1);
        }, 400);
    }
  };

  const [getBusinessType, fetchingBusinessType, data, error] = UseApiCall(apiGetBusinessType, (res) => {
    dispatch(CompleteFormActions.setBusinessType(res?.data));
  }, (err) => {
    dispatch(CompleteFormActions.setShowBackendError(err));
  });

  const [postLeadData, leadDataLoader, leadDataResult, leadDataError] = UseApiCall(apiSubmitLeadForm, (res) => {
    dispatch(CompleteFormActions.setLeadData(res?.data));
  }, (err) => {
    dispatch(CompleteFormActions.setLeadDataError(err));
  });

  useEffect(() => {
    getBusinessType();
  }, []);

  useEffect(() => {
    if (leadDataResult?.data === "User lead submitted successfully") {
        enqueueSnackbar(`${leadDataResult?.data}`, { variant: 'success' })
        setCurrentStep(currentStep + 1);
    }
    if (leadDataError?.status === 500) {
        enqueueSnackbar(`${leadDataError?.data?.error_response?.message}`, { variant: 'error' })
    } else if (leadDataError?.status === 400) {
        enqueueSnackbar(`${leadDataError?.data?.error_response?.message} ${leadDataError?.data?.error_response?.errors !== null && leadDataError?.data?.error_response?.errors[0]?.message}`, { variant: 'error' })
    }
  }, [leadDataResult, leadDataError])

  const business = useSelector((state) => state.completeForm.businessType);

  useEffect(() => {
    setBusinessTypeArray(business);
    setKey(!key);
  }, [business])

  const handleInputValidityForDirectors = (isValid, index) => {
    const updatedDirectorsArray = [...directorsArray];
    updatedDirectorsArray[index].isValid = isValid;
    setDirectorsArray(updatedDirectorsArray);
  };

  const handleInputValidityForOwners = (isValid, index) => {
    const updatedOwnersArray = [...ownersArray];
    updatedOwnersArray[index].isValid = isValid;
    setOwnersArray(updatedOwnersArray);
  };

  const scrollToSectionFast = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
        section.scrollIntoView({ block: 'start', behavior: 'auto' });
      }
  };

  useEffect(() => {
    if (currentStep === 3 || currentStep === 2) {
        scrollToSectionFast('come_above');
    }
  }, [currentStep])


//   const handleMobileNumberChange = (index, value) => {
//     const updatedFideoUsersArray = [...fideoUsersArray];
//     updatedFideoUsersArray[index].mobile = value;
//     setFideoUsersArray(updatedFideoUsersArray);
//   };
  
//   const handleMobileValidityChange = (index, value) => {
//     const updatedFideoUsersArray = [...fideoUsersArray];
//     updatedFideoUsersArray[index].isMobileValid = value;
//     // setIsMobileValid(value);
//     setFideoUsersArray(updatedFideoUsersArray);
//   };
  
//   const handleMobileCodeChange = (index, value) => {
//     const updatedFideoUsersArray = [...fideoUsersArray];
//     updatedFideoUsersArray[index].mobileCodeInput = value;
//     setCountryCode(value);
//     setFideoUsersArray(updatedFideoUsersArray);
//   };


  return (
    <div className="container" id="come_above">
      <div
        className={clsx(
            'left',
            isMobile ? '' : 'element'
        )}
      >
        { isMobile ?
            <div className="mobileHeader">
                <button
                    onClick={() => {
                        if (currentStep === 2) redirectToSignUp();
                        else handlePrev();
                    }}
                    >
                        <img src={BackButtonSvg} alt="BackButtonSvg" className={''} />
                </button>
                <div className='mobileTabMiddle'>
                    <div className="mobileTabHeading">
                        {
                            currentStep === 2
                                ? "Business details"
                                : currentStep === 3
                                    ? "Bank & KYC"
                                    : currentStep === 4
                                        ? "Confirmation"
                                        : ""
                        }
                    </div>
                    <div className='mobileStepper'>
                        <div className='mobileStepperBall' style={{ backgroundColor: (currentStep > 1) ? "#38B565" : "#181C30" }}></div>
                        <div style={{ width: "22%", border: currentStep > 1 ? "1px solid #38B565" : "1px solid #CACCD3" }}></div>
                        <div className='mobileStepperBall' style={{ backgroundColor: (currentStep === 2 ? "#181C30" : ((currentStep > 2) ? "#38B565" : "#E7E8EF")) }}></div>
                        <div style={{ width: "22%", border: currentStep > 2 ? "1px solid #38B565" : "1px solid #CACCD3" }}></div>
                        <div className='mobileStepperBall' style={{ backgroundColor: (currentStep === 3 ? "#181C30" : ((currentStep > 3) ? "#38B565" : "#E7E8EF")) }}></div>
                        <div style={{ width: "22%", border: currentStep > 3 ? "1px solid #38B565" : "1px solid #CACCD3" }}></div>
                        <div className='mobileStepperBall' style={{ backgroundColor: (currentStep >= 4 ? "#181C30" : "#E7E8EF") }}></div>
                    </div>
                </div>
                <div style={{ width: "24px" }}></div>
            </div>
            :
            <div className="stepper">
                <div className='combine'>
                    <div className={`done step ${currentStep === 1 ? 'active' : ''}`}>
                        <img src={DoneSvg} alt="DoneSvg" className={'donesvg'} />
                    </div>
                    <div className='details'>
                        <div className='flex items-center'>
                            <p className={`heading ${currentStep === 1 ? 'activetext' : ''}`}>Basic details</p>
                            {/* { currentStep < 4 &&
                                <button
                                    onClick={redirectToSignUp}
                                    className='editButton'
                                    >
                                        <div className='editButtonText'>Edit</div>
                                </button>
                            } */}
                        </div>
                        <p className={`text ${currentStep === 1 ? 'activetext' : ''}`}>We need your basic details to start with</p>
                    </div>
                </div>
                <div className=''>
                    <img src={DividerDone} alt="DividerSvg" className={'divider'} />
                </div>
                <div className='combine'>
                    <div className={`done ${currentStep > 2 ? '' : 'notdone'} step ${currentStep === 2 ? 'active' : ''}`}>
                        { currentStep > 2 ?
                            <img src={DoneSvg} alt="DoneSvg" className={'donesvg'} />
                            :
                            <img src={BusinessDetailsSvg} alt="BusinessDetailsSvg" className={'iconsvg'} />
                        }
                    </div>
                    <div className='details'>
                        <p className={`heading ${currentStep === 2 ? 'activetext' : ''}`}>Business details</p>
                        <p className={`text ${currentStep === 2 ? 'activetext' : ''}`}>Tell us more about your business</p>
                    </div>
                </div>
                <div className=''>
                    <img src={currentStep > 2 ? DividerDone: Divider} alt="DividerSvg" className={'divider'} />
                </div>
                <div className='combine'>
                    <div className={`done ${currentStep > 3 ? '' : 'notdone'} step ${currentStep === 3 ? 'active' : ''}`}>
                        { currentStep > 3 ?
                            <img src={DoneSvg} alt="DoneSvg" className={'donesvg'} />
                            :
                            <img src={currentStep === 3 ? WhiteBankSvg : BankSvg} alt="BankSvg" className={'iconsvg'} style={{ color: "white" }} />
                        }
                    </div>
                    <div className='details'>
                        <p className={`heading ${currentStep === 3 ? 'activetext' : ''}`}>Bank & KYC</p>
                        <p className={`text ${currentStep === 3 ? 'activetext' : ''}`}>Details to secure and verify your account</p>
                    </div>
                </div>
                <div className=''>
                    <img src={currentStep > 3 ? DividerDone: Divider} alt="DividerSvg" className={'divider'} />
                </div>
                <div className='combine'>
                    <div className={`done notdone step ${currentStep === 4 ? '' : ''}`}>
                        <img src={FlagSvg} alt="FlagSvg" className={'iconsvg'} />
                    </div>
                    <div className='details'>
                        <p className={`heading ${currentStep === 4 ? 'activetext' : ''}`}>Confirmation</p>
                        <p className={`text ${currentStep === 4 ? 'activetext' : ''}`}>Your account with Fideo is confirmed. enjoy!</p>
                    </div>
                </div>
            </div>
        }
      </div>

      <div className="right">
        { currentStep === 2 ?
            <div>
                <div className="navbar-container" style={{ display: isVisible ? 'none' : ''}}>
                    <div className='main-navbar'>
                    </div>
                    <div id="business_directors_button" className={`navbar ${true ? 'show' : 'hide'} ${isMobile ? 'mobile' : ''}`}>
                        <button 
                            onClick={() => {
                                if (isMobile) scrollToSectionMultipleTimes('company_particulars');
                                else  scrollToSectionMultipleTimes('company_particulars', 1);
                            }}
                        className='navText' style={{ color: companyParticularsVisible ? "#1E333F" : "#1E333F80"}}>Company Particulars</button>
                        <button 
                            onClick={() => {
                                if (isMobile) scrollToSectionMultipleTimes('registration_and_identification');
                                else scrollToSectionMultipleTimes('registration_and_identification', 1);
                            }}
                        className='navText' style={{ color: registrationAndIdentificationVisible ? "#1E333F" : "#1E333F80"}}>Registration & Identification</button>
                        <button 
                            onClick={() => {
                                if (isMobile) scrollToSectionMultipleTimes('business_directors');
                                else scrollToSectionMultipleTimes('business_directors', 1);
                            }}
                        className='navText' style={{ color: (!registrationAndIdentificationVisible && businessDirectorsVisible) ? "#1E333F" : "#1E333F80"}}>Business Directors</button>
                        <button 
                            onClick={() => {
                                if (isMobile) scrollToSectionMultipleTimes('fideo_account_users');
                                else scrollToSectionMultipleTimes('fideo_account_users', 1);
                            }}
                        className='navText' style={{ color: fideoAccountUsersVisible ? "#1E333F" : "#1E333F80", paddingRight: "0px"}}>Fideo account users</button>
                    </div>
                </div>
                <div className={clsx(
                    '-sm:h-screen flex relative flex-col h-screen',
                    (windowSize <= 1026 && windowSize > 768) ? `widthfixed` : ''
                )}>
                    <div className="flex flex-col mt-1 items-start">
                        <div ref={containerRef}>
                            <p className='rightHeading'>Business Details</p>
                            <p className='rightText'>Please provide your Business information for account setup</p>
                        </div>

                        <div className='mainBox' id='company_particulars' ref={companyParticularsRef}>
                            <p className='boxHeading headingDiv'>Company Particulars</p>
                            <div className='inputBoxDiv'>
                                <p className='inputHeading'>Brand Name / Doing Business as*</p>
                                <TextInput
                                    type="text"
                                    text="Business Name*"
                                    className="col-span-2"
                                    errorMessage="Please enter a valid business name"
                                    customStyle={{ "height": true }}
                                    validation={[(value) => (value ? value?.trim() !== "" : false)]}
                                    inputValidity={(value) => {
                                        setBusinessNameValid(value);
                                    }}
                                    setInput={(value) => {
                                        setBusinessName(value)
                                    }}
                                    enteredInput={businessName}
                                    hasError={oneTimeClickedFirstPage && businessName === ""}
                                />
                            </div>
                            <div className='inputBoxDiv'>
                                <p className='inputHeading'>Website*</p>
                                <TextInput
                                    type="text"
                                    text="Website*"
                                    className="col-span-2"
                                    errorMessage="Please enter a valid website name"
                                    customStyle={{ "height": true }}
                                    validation={[(value) => (value ? value?.trim() !== "" : false)]}
                                    inputValidity={(value) => {
                                        setWebisteNameValid(value);
                                    }}
                                    setInput={(value) => {
                                        setWebisteName(value)
                                    }}
                                    placeholder="Website url"
                                    enteredInput={webisteName}
                                    hasError={oneTimeClickedFirstPage && webisteName === ""}
                                />
                            </div>
                            <div className='inputBoxDiv'>
                                <p className='inputHeading'>Business Type*</p>
                                <CustomSelectorValues
                                    onChange={handleBusinessTypeChange}
                                    key={key}
                                    selectedValue={businessType}
                                    items={businessTypeArray}
                                    validation={[(value) => value !== '']}
                                    placeholder="Business Type*"
                                    errorMessage="Please enter a valid business type"
                                    inputValidity={(value) => setBusinessTypeValid(!value)}
                                    hasError={oneTimeClickedFirstPage && businessType === ''}
                                />
                            </div>
                            <div className='inputBoxDiv'>
                                <p className='inputHeading'>Describe your Product / Services*</p>
                                <TextInput
                                    type="text"
                                    text="Business Services*"
                                    className="col-span-2"
                                    errorMessage="Please enter a valid business services"
                                    customStyle={{ "height": true }}
                                    validation={[(value) => (value ? value?.trim() !== "" : false)]}
                                    inputValidity={(value) => {
                                        setBusinessServiceValid(value);
                                    }}
                                    setInput={(value) => {
                                        setBusinessService(value)
                                    }}
                                    enteredInput={businessService}
                                    hasError={oneTimeClickedFirstPage && businessService === ""}
                                />
                            </div>
                            <div className='inputBoxDiv'>
                                <p className='inputHeading'>Business Address*</p>
                                <div className="">
                                    <TextInput
                                        type="text"
                                        text="Street Address*"
                                        className="col-span-2 pb-6"
                                        errorMessage="Please enter a valid address"
                                        customStyle={{ "height": true }}
                                        validation={[(value) => (value ? value?.trim() !== "" : false)]}
                                        inputValidity={(value) => {
                                            setBusinessAddressLine1Valid(value);
                                        }}
                                        setInput={(value) => {
                                            setBusinessAddressLine1(value)
                                        }}
                                        enteredInput={businessAddressLine1}
                                        hasError={oneTimeClickedFirstPage && businessAddressLine1 === ""}
                                    />
                                    <TextInput
                                        type="text"
                                        text="Street Address Line 2"
                                        className="col-span-2 pb-6"
                                        customStyle={{ "height": true }}
                                        setInput={(value) => {
                                            setBusinessAddressLine2(value)
                                        }}
                                        enteredInput={businessAddressLine2}
                                    />
                                    <div className='flex'>
                                        <TextInput
                                            type="text"
                                            text="City*"
                                            className="pb-6 pr-3"
                                            errorMessage="Please enter a valid city"
                                            customStyle={{ "height": true }}
                                            validation={[(value) => (value ? value?.trim() !== "" : false)]}
                                            inputValidity={(value) => {
                                                setBusinessAddressCityValid(value);
                                            }}
                                            setInput={(value) => {
                                                setBusinessAddressCity(value)
                                            }}
                                            enteredInput={businessAddressCity}
                                            hasError={oneTimeClickedFirstPage && businessAddressCity === ""}
                                        />
                                        <TextInput
                                            type="text"
                                            text="State / Province*"
                                            className="pb-6 pl-3"
                                            errorMessage="Please enter a valid state"
                                            customStyle={{ "height": true }}
                                            validation={[(value) => (value ? value?.trim() !== "" : false)]}
                                            inputValidity={(value) => {
                                                setBusinessAddressStateValid(value);
                                            }}
                                            setInput={(value) => {
                                                setBusinessAddressState(value)
                                            }}
                                            enteredInput={businessAddressState}
                                            hasError={oneTimeClickedFirstPage && businessAddressState === ""}
                                        />
                                    </div>
                                    <div className='flex w-1/2'>
                                        <TextInput
                                            type="number"
                                            text="Postal Code*"
                                            className="pb-6 pr-3"
                                            errorMessage="Please enter a valid postal code"
                                            customStyle={{ "height": true }}
                                            validation={[(value) => (value ? (Number(value) < 0 ? false : value?.trim() !== "") : false)]}
                                            inputValidity={(value) => {
                                                setBusinessAddressPostalCodeValid(value);
                                            }}
                                            setInput={(value) => {
                                                setBusinessAddressPostalCode(value)
                                            }}
                                            enteredInput={businessAddressPostalCode}
                                            hasError={oneTimeClickedFirstPage && businessAddressPostalCode === ""}
                                        />
                                        <div></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='mainBox marginTop24px' id='registration_and_identification' ref={registrationAndIdentificationRef}>
                            {/* <p className='boxHeading headingDiv'>Registration & Identification</p> */}
                            <div className='boxHeading headingDiv alignDiv'>
                                <p className=''>
                                    Registration & Identification
                                </p>
                                <p className='headingTextDiv'>{`Any of the following is mandatory*`}</p>
                            </div>
                            <div className='inputBoxDiv'>
                                <div className={clsx('')}>
                                    <TextInput
                                        type="text"
                                        text={`CIN Number*`}
                                        placeholder={`CIN Number${(cinNumber === "" && gstinNumber === "" && businessPan === "") ? "*" : ""}`}
                                        className="col-span-2"
                                        errorMessage="Please enter a valid CIN number"
                                        customStyle={{ "height": true }}
                                        // validation={[(value) => (value ? (value?.trim() !== "" && (cinNumber === "" && gstinNumber === "" && businessPan === "")) : false)]}
                                        inputValidity={(value) => {
                                            setCinNumberValid(value);
                                        }}
                                        setInput={(value) => {
                                            setCinNumber(value)
                                        }}
                                        enteredInput={cinNumber}
                                        hasError={oneTimeClickedFirstPage && (cinNumber === "" && gstinNumber === "" && businessPan === "")}
                                    />
                                    {uuidId && (
                                        <div className='mt-[16px]'>
                                            <FileBrowserNew
                                                setFile={setCinDocument}
                                                files={cinDocument}
                                                uuid={uuidId}
                                                isMobile={isMobile}
                                                validFormats={['document', 'image']}
                                                hasError={oneTimeClickedFirstPage && cinNumber ? Object.keys(cinDocument || {})?.length === 0 : false}
                                                userType={"COMPANY"}
                                                docType={"CIN"}
                                                docNum={Object.keys(cinDocument || {})?.length + 1}
                                                // userName
                                                guideLineText={true && !isMobile}
                                                changeText={true}
                                                changeTextLine={'Upload CIN Document'}
                                                errorMessage={Object.keys(cinDocument || {})?.length === 0 ? "Please upload atleast one file" : "Please upload a valid file"}
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className='inputBoxDiv'>
                                <div className={clsx('')}>
                                    <TextInput
                                        type="text"
                                        text={`GSTIN Number*`}
                                        placeholder={`GSTIN Number${((cinNumber === "" && gstinNumber === "" && businessPan === "") || (businessType === "Partnership" && gstinNumber === "")) ? "*" : ""}`}
                                        className="col-span-2"
                                        errorMessage={businessType === "Partnership" ? "GSTIN number is mandatory" : "Please enter a valid GSTIN number"}
                                        customStyle={{ "height": true }}
                                        // validation={[(value) => (value ? (value?.trim() !== "" && businessType === "Partnership") : false)]}
                                        inputValidity={(value) => {
                                            setGstinNumberValid(value);
                                        }}
                                        setInput={(value) => {
                                            setGstinNumber(value)
                                        }}
                                        enteredInput={gstinNumber}
                                        hasError={oneTimeClickedFirstPage && ((cinNumber === "" && gstinNumber === "" && businessPan === "") || (businessType === "Partnership" && gstinNumber === ""))}
                                    />
                                    {uuidId && (
                                        <div className='mt-[16px]'>
                                            <FileBrowserNew
                                                setFile={setGstinDocument}
                                                files={gstinDocument}
                                                uuid={uuidId}
                                                isMobile={isMobile}
                                                validFormats={['document', 'image']}
                                                hasError={oneTimeClickedFirstPage && gstinNumber ? Object.keys(gstinDocument || {})?.length === 0 : false}
                                                userType={"COMPANY"}
                                                docType={"GSTIN"}
                                                docNum={Object.keys(gstinDocument || {})?.length + 1}
                                                // userName
                                                guideLineText={true && !isMobile}
                                                changeText={true}
                                                changeTextLine={'Upload GSTIN Document'}
                                                errorMessage={Object.keys(gstinDocument || {})?.length === 0 ? "Please upload atleast one file" : "Please upload a valid file"}
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className='inputBoxDiv'>
                                <div className={clsx('')}>
                                    <TextInput
                                        type="text"
                                        text={`Business PAN*`}
                                        placeholder={`Business PAN${(cinNumber === "" && gstinNumber === "" && businessPan === "") ? "*" : ""}`}
                                        className="col-span-2"
                                        errorMessage="Please enter a valid Business PAN"
                                        customStyle={{ "height": true }}
                                        // validation={[(value) => (value ? (value?.trim() !== "" && (cinNumber === "" && gstinNumber === "" && businessPan === "")) : false)]}
                                        inputValidity={(value) => {
                                            setBusinessPanValid(value);
                                        }}
                                        setInput={(value) => {
                                            setBusinessPan(value)
                                        }}
                                        enteredInput={businessPan}
                                        hasError={oneTimeClickedFirstPage && (cinNumber === "" && gstinNumber === "" && businessPan === "")}
                                    />
                                    {uuidId && (
                                        <div className='mt-[16px]'>
                                            <FileBrowserNew
                                                setFile={setPanDocument}
                                                files={panDocument}
                                                uuid={uuidId}
                                                isMobile={isMobile}
                                                validFormats={['document', 'image']}
                                                hasError={oneTimeClickedFirstPage && businessPan ? Object.keys(panDocument || {})?.length === 0 : false}
                                                userType={"COMPANY"}
                                                docType={"PAN"}
                                                docNum={Object.keys(panDocument || {})?.length + 1}
                                                // userName
                                                guideLineText={true && !isMobile}
                                                changeText={true}
                                                changeTextLine={'Upload PAN Card'}
                                                errorMessage={Object.keys(panDocument || {})?.length === 0 ? "Please upload atleast one file" : "Please upload a valid file"}
                                            />
                                        </div>
                                    )}
                                </div>
                                <div className='guidelineText'>Supported document formats: pdf, jpg, jpeg or png. Max. size: 10MB</div>
                            </div>
                        </div>

                        <div className='mainBox marginTop24px' id='business_directors' ref={businessDirectorsRef}>
                            <div className='boxHeading headingDiv alignDiv'>
                                <p className=''>
                                    Business Directors
                                </p>
                                <p className='headingTextDiv'>{`Compulsory disclosure of directors with >25% shareholding for account setup.`}</p>
                            </div>
                            <div className='inputBoxDiv'>
                                {directorsArray.map((item, index) => {
                                    return(
                                        <div className={`${index !== 0 && 'marginTop24px'}`}>
                                            <div style={{ display: "flex", justifyContent: "space-between"}}>
                                                <p className='inputHeading'>{index < 4 ? `Director ${index+1}` : `Other Directors Name`}</p>
                                                <button onClick={() => removeDirector(index)} className={`inputButton ${directorsArray?.length === 1 && 'inputButtonHide'}`}>Remove</button>
                                            </div>
                                            {/* { */}
                                            <div className={clsx('flex flex-col gap-[20px]')}>
                                                <TextInput
                                                    type="text"
                                                    text={ index < 4 ? `Director ${index+1} Name*` : `Other Directors Name*`}
                                                    className="col-span-2"
                                                    errorMessage={index < 4 ? "Please enter a valid director name" : "Please enter a valid other directors name"}
                                                    customStyle={{ "height": index < 4 ? true : false }}
                                                    validation={[(value) => (value ? value?.trim() !== "" : false)]}
                                                    multiline={index < 4 ? false : true}
                                                    setInput={(value) => {
                                                        const updatedDirectorsArray = [...directorsArray];
                                                        updatedDirectorsArray[index] = { name: value, isValid: value?.trim() !== "" };
                                                        setDirectorsArray(updatedDirectorsArray);
                                                    }}
                                                    enteredInput={item?.name}
                                                    hasError={oneTimeClickedFirstPage && item?.name === ""}
                                                    inputValidity={(isValid) => handleInputValidityForDirectors(isValid, index)}
                                                />

                                                <TextInput
                                                    type="text"
                                                    text={ index < 4 ? `Director ${index+1} PAN*` : `Other Directors PAN*`}
                                                    className="col-span-2"
                                                    errorMessage="Please enter a valid Director PAN"
                                                    customStyle={{ "height": index < 4 ? true : false }}
                                                    validation={[(value) => (value ? value?.trim() !== "" : false)]}
                                                    setInput={(value) => setDirectorsPanNumberForDirector(index,value)}
                                                    enteredInput={item?.director_pan}
                                                    hasError={oneTimeClickedFirstPage && item?.director_pan === ""}
                                                    inputValidity={(isValid) => handleInputValidityForDirectors(isValid, index)}
                                                />

                                                {uuidId && (
                                                    <div className='mt-[16px]'>
                                                        <FileBrowserNew
                                                            setFile={(files) => setDirectorsPanForDirector(index, files)}
                                                            files={item?.panFiles}
                                                            uuid={uuidId}
                                                            isMobile={isMobile}
                                                            validFormats={['document', 'image']}
                                                            hasError={oneTimeClickedFirstPage && Object.keys(item?.panFiles || {})?.length === 0}
                                                            errorMessage={Object.keys(item?.panFiles || {})?.length === 0 ? "Please upload at least one file" : "Please upload a valid file"}
                                                            userType={"DIRECTOR"}
                                                            docType={"PAN"}
                                                            docNum={Object.keys(item?.panFiles || {})?.length + 1}
                                                            userName={item?.name}
                                                            guideLineText={true && !isMobile}
                                                            changeText={true}
                                                            changeTextLine={'Upload PAN Card'}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                            {/* } */}
                                        </div>
                                    )
                                })}
                                { directorsArray?.length < 5 &&
                                    <button
                                        onClick={() => setDirectorsArray([...directorsArray, { name: "", isValid: false, director_pan : "",}])}
                                        disabled={directorsArray?.length > 4}
                                        className='customButton w-full gap-2 marginTop24px'
                                        >
                                            <img
                                                src={PlusSvg}
                                                alt={`PlusSvg-icon`}
                                                className='w-8 h-8 ml-3'
                                            />
                                            <div className='buttonText'>Add more Director</div>
                                    </button>
                                }
                                <div className='guidelineText'>Supported document formats: pdf, jpg, jpeg or png. Max. size: 10MB</div>
                            </div>
                        </div>

                        <div className='mainBox marginTop24px'>
                            <div className='boxHeading headingDiv alignDiv'>
                                <p className=''>
                                    Business Owners
                                </p>
                                <p className='headingTextDiv'>{`Compulsory disclosure of owners with >25% shareholding for account setup.`}</p>
                            </div>
                            <div className='inputBoxDiv'>
                                {ownersArray.map((item, index) => {
                                    return(
                                        <div className={`${index !== 0 && 'marginTop24px'}`}>
                                            <div style={{ display: "flex", justifyContent: "space-between"}}>
                                                <p className='inputHeading'>{index < 4 ? `Owner ${index+1}` : `Other Owners Name`}</p>
                                                <button onClick={() => removeOwner(index)} className={`inputButton ${ownersArray?.length === 1 && 'inputButtonHide'}`}>Remove</button>
                                            </div>
                                            {/* { index < 4 ?
                                                <TextInput
                                                    type="text"
                                                    text={`Owner ${index+1} Name`}
                                                    className="col-span-2"
                                                    customStyle={{ "height": index < 4 ? true : false }}
                                                    setInput={(value) => {
                                                        const updatedOwnersArray = [...ownersArray];
                                                        updatedOwnersArray[index] = value;
                                                        setOwnersArray(updatedOwnersArray);
                                                    }}
                                                    enteredInput={ownersArray[index]}
                                                />
                                                :
                                                <TextInput
                                                    type="text"
                                                    text={`Other Owners Name`}
                                                    className="col-span-2"
                                                    multiline={true}
                                                    setInput={(value) => {
                                                        const updatedOwnersArray = [...ownersArray];
                                                        updatedOwnersArray[index] = value;
                                                        setOwnersArray(updatedOwnersArray);
                                                    }}
                                                    enteredInput={ownersArray[index]}
                                                />
                                            } */}
                                            {/* { */}
                                            <div className={clsx('flex flex-col gap-[20px]')}>
                                                <TextInput
                                                    type="text"
                                                    text={ index < 4 ? `Owner ${index+1} Name*` : `Other Owners Name*`}
                                                    className="col-span-2"
                                                    errorMessage={index < 4 ? "Please enter a valid owner name" : "Please enter a valid other owners name"}
                                                    customStyle={{ "height": index < 4 ? true : false }}
                                                    validation={[(value) => (value ? value?.trim() !== "" : false)]}
                                                    multiline={index < 4 ? false : true}
                                                    setInput={(value) => {
                                                        const updatedOwnersArray = [...ownersArray];
                                                        updatedOwnersArray[index] = { name: value, isValid: value?.trim() !== "" };
                                                        setOwnersArray(updatedOwnersArray);
                                                    }}
                                                    enteredInput={item?.name}
                                                    hasError={oneTimeClickedFirstPage && item?.name === ""}
                                                    inputValidity={(isValid) => handleInputValidityForOwners(isValid, index)}
                                                />

                                                <TextInput
                                                    type="text"
                                                    text={ index < 4 ? `Owner ${index+1} PAN*` : `Other Owner PAN*`}
                                                    className="col-span-2"
                                                    errorMessage="Please enter a valid Owner PAN"
                                                    customStyle={{ "height": index < 4 ? true : false }}
                                                    validation={[(value) => (value ? value?.trim() !== "" : false)]}
                                                    setInput={(value) => setOwnersPanNumberForOwner(index,value)}
                                                    enteredInput={item?.owner_pan}
                                                    hasError={oneTimeClickedFirstPage && item?.owner_pan === ""}
                                                    inputValidity={(isValid) => handleInputValidityForOwners(isValid, index)}
                                                />
                                                {uuidId && (
                                                    <div className='mt-[16px]'>
                                                        <FileBrowserNew
                                                            setFile={(files) => setOwnersPanForOwner(index, files)}
                                                            files={item?.panFiles}
                                                            uuid={uuidId}
                                                            isMobile={isMobile}
                                                            validFormats={['document', 'image']}
                                                            hasError={oneTimeClickedFirstPage && Object.keys(item?.panFiles || {})?.length === 0}
                                                            errorMessage={Object.keys(item?.panFiles || {})?.length === 0 ? "Please upload at least one file" : "Please upload a valid file"}
                                                            userType={"OWNER"}
                                                            docType={"PAN"}
                                                            docNum={Object.keys(item?.panFiles || {})?.length + 1}
                                                            userName={item?.name}
                                                            guideLineText={true && !isMobile}
                                                            changeText={true}
                                                            changeTextLine={'Upload PAN Card'}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                            {/* } */}
                                        </div>
                                    )
                                })}
                                { ownersArray?.length < 5 &&
                                    <button
                                        onClick={() => setOwnersArray([...ownersArray, { name: "", isValid: false, owner_pan : "" }])}
                                        disabled={ownersArray?.length > 4}
                                        className='customButton w-full gap-2 marginTop24px'
                                        >
                                            <img
                                                src={PlusSvg}
                                                alt={`PlusSvg-icon`}
                                                className='w-8 h-8 ml-3'
                                            />
                                            <div className='buttonText'>Add more Owner</div>
                                    </button>
                                }
                                <div className='guidelineText'>Supported document formats: pdf, jpg, jpeg or png. Max. size: 10MB</div>
                            </div>
                        </div>

                        <div className='mainBox marginTop24px' id='fideo_account_users' ref={fideoAccountUsersRef}>
                            <p className='boxHeading headingDiv'>Fideo account users</p>
                            <div className='inputBoxDiv'>
                                {fideoUsersArray.map((item, index) => {
                                    return(
                                        <div className={`${index !== 0 && 'marginTop24px'}`}>
                                            <div style={{ display: "flex", justifyContent: "space-between"}}>
                                                <p className='inputHeading'>{`User ${fideoUsersArray?.length === 1 ? '' : index+1}`}</p>
                                                <button onClick={() => removeFideoUsers(index)} className={`inputButton ${index === 0 && 'inputButtonHide'}`}>Remove</button>
                                            </div>
                                            <div className={clsx('flex flex-col gap-[20px]')}>
                                                <TextInput
                                                    type="text"
                                                    // text={`User ${index+1} Name`}
                                                    text={`User Name*`}
                                                    className="col-span-2"
                                                    customStyle={{ "height": true }}
                                                    errorMessage="Please enter a valid user name"
                                                    validation={[(value) => (value ? value?.trim() !== "" : false)]}
                                                    inputValidity={(value) => {
                                                        setFideoUserValid(value);
                                                    }}
                                                    setInput={(value) => {
                                                        // const updatedFideoUsersArray = [...fideoUsersArray];
                                                        // if (updatedFideoUsersArray[index]) {
                                                        //   updatedFideoUsersArray[index].name = value;
                                                        //   setFideoUsersArray(updatedFideoUsersArray);
                                                        // }
                                                        setFideoUser(value)
                                                    }}
                                                    
                                                    // enteredInput={fideoUsersArray[index]?.name}
                                                    enteredInput={fideoUser}
                                                    hasError={oneTimeClickedFirstPage && fideoUser === ""}
                                                />

                                                <TextInput
                                                    type="text"
                                                    text={`User PAN*`}
                                                    className="col-span-2"
                                                    errorMessage="Please enter a valid User PAN"
                                                    customStyle={{ "height": true}}
                                                    validation={[(value) => (value ? value?.trim() !== "" : false)]}
                                                    inputValidity={(value) => {
                                                        setFideoUserValid(value);
                                                    }}
                                                    setInput={(value) => setFideoUsersPanNumber(value)}
                                                    enteredInput={fideoUsersPanNumber}
                                                    hasError={oneTimeClickedFirstPage && fideoUsersPanNumber === ""}
                                                    // inputValidity={(isValid) => handleInputValidityForDirectors(isValid, index)}
                                                />


                                                <MobileInputSimple
                                                    className='mt-[16px]'
                                                    isRequired={true}
                                                    customStyle={{ "height": true }}
                                                    mobileCodeInput={mobileCodeInput}
                                                    mobileNumberInput={mobile}
                                                    setMobileNumberInput={(value) => setMobile(value)}
                                                    setMobileValidity={(value) => setIsMobileValid(value)}
                                                    setMobileCodeInput={(value) => setMobileCodeInput(value)}
                                                    hasError={oneTimeClickedFirstPage && !isMobileValid}
                                                    errorMessage="Please enter a valid mobile number"
                                                />
                                                {uuidId && (
                                                    <div className='mt-[16px]'>
                                                        <FileBrowserNew
                                                            setFile={setFideoUsersPan}
                                                            files={fideoUsersPan}
                                                            uuid={uuidId}
                                                            isMobile={isMobile}
                                                            validFormats={['document', 'image']}
                                                            hasError={oneTimeClickedFirstPage && Object.keys(fideoUsersPan || {})?.length === 0}
                                                            errorMessage={Object.keys(fideoUsersPan || {})?.length === 0 ? "Please upload atleast one file" : "Please upload a valid file"}
                                                            userType={"USER"}
                                                            docType={"PAN"}
                                                            docNum={Object.keys(fideoUsersPan || {})?.length + 1}
                                                            userName={fideoUser}
                                                            guideLineText={true && !isMobile}
                                                            changeText={true}
                                                            changeTextLine={'Upload PAN Card'}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    )
                                })}
                                <div className='guidelineText' style={{ paddingTop: "16px" }}>Supported document formats: pdf, jpg, jpeg or png. Max. size: 10MB</div>
                                {/* { fideoUsersArray?.length < 5 &&
                                    <button
                                        onClick={() => setFideoUsersArray([...fideoUsersArray, { name: "", mobile: "", mobileCodeInput: '+91', isMobileValid: false }])}
                                        // disabled={fideoUsersArray?.length > 4}
                                        disabled={true}
                                        className='customButton w-full gap-2 marginTop24px'
                                        >
                                            <img
                                                src={PlusSvg}
                                                alt={`PlusSvg-icon`}
                                                className='w-8 h-8 ml-3'
                                            />
                                            <div className='buttonText'>Add more User</div>
                                    </button>
                                } */}
                            </div>
                        </div>

                        <div className='bothButton marginTop24px marginBottom24px' style={{ width: "100%", height: "48px" }}>
                            <button
                                // onClick={handlePrev}
                                onClick={redirectToSignUp}
                                // disabled={false}
                                className='backButton gap-2'
                                >
                                    <img
                                        src={ArrowLeft}
                                        alt={`ArrowLeft-icon`}
                                        className='arrowBack'
                                    />
                            </button>
                            <button
                                onClick={handleFirstPageNext}
                                // disabled={false}
                                className='saveDraftButton gap-2'
                                >
                                    <div className='saveButtonText'>Continue</div>
                            </button>
                        </div>

                    </div>
                </div>
            </div>
            :
            (currentStep === 3) ?
                <div>
                    <div className="navbar-container" style={{ display: isSecondVisible ? 'none' : ''}}>
                        <div className='main-navbar'>
                        </div>
                        <div id="second_page_button" className={`navbar ${isMobile ? 'mobile justify-start' : 'flex items-center justify-start'}`}>
                            <button onClick={() => scrollToSectionMultipleTimes('bank_details', 1)} className='navText navTextSecond' style={{ color: (bankDetailsVisible1 || bankDetailsVisible2 || bankDetailsVisible3) ? "#1E333F" : "#1E333F80"}}>Bank Details</button>
                            <button onClick={() => scrollToSectionMultipleTimes('kyc_details', 1)} className='navText navTextSecond' style={{ color: ((bankDetailsVisible1 || bankDetailsVisible2 || bankDetailsVisible3) ? false : true) ? "#1E333F" : "#1E333F80"}}>KYC Details</button>
                        </div>
                    </div>
                    <div 
                        className={clsx(
                            '-sm:h-screen flex relative flex-col h-screen',
                            (windowSize <= 1026 && windowSize > 768) ? `widthfixed` : '',
                        )}
                    >
                        <div className="flex flex-col mt-1 items-start">
                            <div ref={containeSecondRef}>
                                <p className='rightHeading'>Bank & KYC</p>
                                <p className='rightText'>To secure your account and receive funds, complete bank & KYC info</p>
                            </div>

                            <div className='mainBox' id="bank_details">
                                <div className='boxHeading headingDiv alignDiv'>
                                    <p className=''>
                                        Bank Details
                                    </p>
                                    <p className='headingTextDiv'>{`For bank verification, a small credit (~INR 1.00) will be made.`}</p>
                                </div>
                                <div className='inputBoxDiv'>
                                    <p className='inputHeadingStep3'>Account Type</p>
                                    <div ref={bankDetailsRef1}>
                                        <div className='accountType' onClick={() => handleCheckboxChange('currentAccountCheckbox')}>
                                            <div className=''>
                                                <p className='accountTypeHeading'>Current account (INR)</p>
                                                <p className='accountTypeText'>To collect your export dues in INR</p>
                                            </div>
                                            <div>
                                            <input
                                                className='termscheckboxForBank'
                                                type="checkbox"
                                                style={{ cursor: "pointer" }}
                                                checked={checkboxes.currentAccountCheckbox}
                                                // onChange={() => handleCheckboxChange('currentAccountCheckbox')}
                                            />
                                            </div>
                                        </div>
                                        {
                                            checkboxes.currentAccountCheckbox &&
                                                Object.keys(formDataCurrentAccount).map((fieldName) => (
                                                    <div key={fieldName} className='acoountFieldsBox'>
                                                    <p className='inputHeading'>{fieldName}</p>
                                                    <TextInput
                                                        type="text"
                                                        text={fieldName}
                                                        className="col-span-2"
                                                        multiline={fieldName === "Bank Address*"}
                                                        setInput={(value) => {
                                                            handleInputChangeCurrentAccount(fieldName, value);
                                                        }}
                                                        enteredInput={formDataCurrentAccount[fieldName].value}
                                                        placeholder={formDataCurrentAccount[fieldName].placeholder}
                                                        errorMessage={`Please enter a valid ${fieldName.toLowerCase()}`}
                                                        customStyle={{ "height": true }}
                                                        validation={[(value) => (value ? value?.trim() !== "" : false)]}
                                                        inputValidity={(value) => {
                                                            handleInputValidityCurrentAccount(fieldName, value);
                                                        }}
                                                        hasError={fieldName === "BIC/ SWIFT Code" ? false : (oneTimeClickedSecondPage && formDataCurrentAccount[fieldName].value === "")}
                                                    />
                                                    </div>
                                                ))
                                        }
                                    </div>
                                    <div className='accountbreaker' ref={bankDetailsRef2}></div>
                                    <div ref={bankDetailsRef3}>
                                        <div className='accountType' onClick={() => handleCheckboxChange('foreignAccountCheckbox')}>
                                            <div className=''>
                                                <p className='accountTypeHeading'>EEFC account (Foreign currency)</p>
                                                <p className='accountTypeText'>To collect your export dues in in foreign currency (USD, EUR, GBP & more)</p>
                                            </div>
                                            <div>
                                            <input
                                                className='termscheckboxForBank'
                                                type="checkbox"
                                                style={{ cursor: "pointer" }}
                                                checked={checkboxes.foreignAccountCheckbox}
                                                // onChange={() => handleCheckboxChange('foreignAccountCheckbox')}
                                            />
                                            </div>
                                        </div>
                                        {
                                            checkboxes.foreignAccountCheckbox &&
                                                Object.keys(formDataForeignAccount).map((fieldName) => (
                                                    <div key={fieldName} className='acoountFieldsBox'>
                                                    <p className='inputHeading'>{fieldName}</p>
                                                    <TextInput
                                                        type="text"
                                                        text={fieldName}
                                                        className="col-span-2"
                                                        multiline={fieldName === "Bank Address*"}
                                                        setInput={(value) => {
                                                            handleInputChangeForeignAccount(fieldName, value);
                                                        }}
                                                        enteredInput={formDataForeignAccount[fieldName].value}
                                                        placeholder={formDataForeignAccount[fieldName].placeholder}
                                                        errorMessage={`Please enter a valid ${fieldName.toLowerCase()}`}
                                                        customStyle={{ "height": true }}
                                                        validation={[(value) => (value ? value?.trim() !== "" : false)]}
                                                        inputValidity={(value) => {
                                                            handleInputValidityForeignAccount(fieldName, value);
                                                        }}
                                                        hasError={fieldName === "BIC/ SWIFT Code" ? false : (checkboxes.foreignAccountCheckbox && oneTimeClickedSecondPage && formDataForeignAccount[fieldName].value === "")}
                                                    />
                                                    </div>
                                                ))
                                        }
                                    </div>
                                </div>
                            </div>

                            <div>
                                <p className='rightHeadingKYC'>KYC</p>
                            </div>
                            
                            {/* <div className='mainBox marginTop24px' id="kyc_details" ref={kycRef}>
                                <div className='boxHeading headingDiv alignDiv'>
                                    <p className=''>
                                        Company identifiers
                                    </p>
                                    <p className='headingTextDiv'>{`Documents confirming the following:`}</p>
                                </div>
                                <div className='documentBoxDiv'>
                                    {companyIdentifiersDocumentArray?.map((item, index) => {
                                        let selectedDocumentState;
                                        let setSelectedDocumentState;
                                    
                                        if (index === 0) {
                                            selectedDocumentState = cinDocument;
                                            setSelectedDocumentState = setCinDocument;
                                        } else if (index === 1) {
                                            selectedDocumentState = gstinDocument;
                                            setSelectedDocumentState = setGstinDocument;
                                        } else {
                                            selectedDocumentState = panDocument;
                                            setSelectedDocumentState = setPanDocument;
                                        }

                                        const numKeysInSelectedDocumentState = selectedDocumentState
                                            ? Object.keys(selectedDocumentState).length
                                            : 0;

                                        return(
                                            <div className={`${index !== 0 ? 'marginTop24px' : ''}`} style={{ height: "auto" }}>
                                                <div className='circularBox'>
                                                    <div className='circularIndex'>{String.fromCharCode(65 + index)}</div>
                                                    <p className='circularIndexText'>{item?.name}</p>
                                                </div>
                                                {uuidId && (
                                                    <FileBrowserNew
                                                        setFile={setSelectedDocumentState}
                                                        files={selectedDocumentState}
                                                        uuid={uuidId}
                                                        isMobile={isMobile}
                                                        validFormats={['document', 'image']}
                                                        hasError={oneTimeClickedSecondPage && (Object.keys(cinDocument || {})?.length + Object.keys(gstinDocument || {})?.length + Object.keys(panDocument || {})?.length) === 0}
                                                        userType={"COMPANY"}
                                                        docType={item?.type}
                                                        docNum={numKeysInSelectedDocumentState + 1}
                                                        // userName
                                                        errorMessage={numKeysInSelectedDocumentState === 0 ? "Please upload atleast one file" : "Please upload a valid file"}
                                                    />
                                                )}
                                            </div>
                                        )
                                    })}
                                    <div className='guidelineText'>Supported document formats: pdf, jpg, jpeg or png. Max. size: 10MB</div>
                                </div>
                            </div> */}

                            {/* PAN PDF UPLOAD FOR BUSINESS OWNERS */}
                            {/* <div className='mainBox marginTop24px'>
                                <div className='boxHeading headingDiv alignDiv'>
                                    <p className=''>
                                        Business Owner(s)
                                    </p>
                                    <p className='headingTextDiv'>{`Upload PAN card copies for all business owners with >25% shareholding`}</p>
                                </div>
                                <div className='documentBoxDiv'>
                                    {ownersArray
                                        .filter((item) => item?.name?.trim() !== "")
                                        .map((item, index) => {
                                            const numKeysInSelectedDocumentState = item?.panFiles
                                                ? Object.keys(item?.panFiles).length
                                                : 0;
                                            return (
                                                <div style={{ height: "auto" }} key={index}>
                                                    <div className={`${index !== 0 ? 'marginTop24px' : ''}`}>
                                                        <div className='circularBox'>
                                                        <div className='circularIndex'>{(1 + index)}</div>
                                                        <div className='circularBoxTextDiv'>
                                                            <p className='circularIndexText'>Upload PAN for <b className='font-semibold pl-1'>{item?.name?.trim()}</b></p>
                                                        </div>
                                                        </div>
                                                        {uuidId && (
                                                            <FileBrowserNew
                                                                setFile={(files) => setOwnersPanForOwner(index, files)}
                                                                files={item?.panFiles}
                                                                uuid={uuidId}
                                                                isMobile={isMobile}
                                                                validFormats={['document', 'image']}
                                                                hasError={oneTimeClickedSecondPage && numKeysInSelectedDocumentState === 0}
                                                                errorMessage={numKeysInSelectedDocumentState === 0 ? "Please upload at least one file" : "Please upload a valid file"}
                                                                userType={"OWNER"}
                                                                docType={"PAN"}
                                                                docNum={numKeysInSelectedDocumentState + 1}
                                                                userName={item?.name}
                                                            />
                                                        )}
                                                    </div>
                                                </div>
                                        )})
                                    }
                                    <div className='guidelineText'>Supported document formats: pdf, jpg, jpeg or png. Max. size: 10MB</div>
                                </div>
                            </div> */}

                            {/* PAN PDF UPLOAD FOR DIRECTORS */}
                            {/* <div className='mainBox marginTop24px'>
                                <div className='boxHeading headingDiv alignDiv'>
                                    <p className=''>
                                        Director(s)
                                    </p>
                                    <p className='headingTextDiv'>{`PAN card copies for all directors`}</p>
                                </div>
                                <div className='documentBoxDiv'>
                                    {directorsArray
                                        .filter((item) => item?.name?.trim() !== "")
                                        .map((item, index) => {
                                            const numKeysInSelectedDocumentState = item?.panFiles
                                                ? Object.keys(item?.panFiles).length
                                                : 0;
                                            return (
                                                <div style={{ height: "auto" }} key={index}>
                                                    <div className={`${index !== 0 ? 'marginTop24px' : ''}`}>
                                                        <div className='circularBox'>
                                                        <div className='circularIndex'>{(1 + index)}</div>
                                                        <div className='circularBoxTextDiv'>
                                                            <p className='circularIndexText'>Upload PAN for <b className='font-semibold pl-1'>{item?.name?.trim()}</b></p>
                                                        </div>
                                                        </div>
                                                        {uuidId && (
                                                            <FileBrowserNew
                                                                setFile={(files) => setDirectorsPanForDirector(index, files)}
                                                                files={item?.panFiles}
                                                                uuid={uuidId}
                                                                isMobile={isMobile}
                                                                validFormats={['document', 'image']}
                                                                hasError={oneTimeClickedSecondPage && numKeysInSelectedDocumentState === 0}
                                                                errorMessage={numKeysInSelectedDocumentState === 0 ? "Please upload at least one file" : "Please upload a valid file"}
                                                                userType={"DIRECTOR"}
                                                                docType={"PAN"}
                                                                docNum={numKeysInSelectedDocumentState + 1}
                                                                userName={item?.name}
                                                            />
                                                        )}
                                                    </div>
                                                </div>
                                        )})
                                    }
                                    <div className='guidelineText'>Supported document formats: pdf, jpg, jpeg or png. Max. size: 10MB</div>
                                </div>
                            </div> */}

                            {/* PAN PDF UPLOAD FOR FIDEO USERS */}
                            {/* <div className='mainBox marginTop24px'>
                                <div className='boxHeading headingDiv alignDiv'>
                                    <p className=''>
                                        Fideo account user(s)
                                    </p>
                                    <p className='headingTextDiv'>{`PAN card copies for person(s) who will use the account`}</p>
                                </div>
                                <div className='documentBoxDiv'>
                                    {[fideoUser]
                                        .filter((item) => item?.trim() !== '')
                                        .map((item, index) => {
                                            const numKeysInSelectedDocumentState = fideoUsersPan
                                                ? Object.keys(fideoUsersPan).length
                                                : 0;
                                            return (
                                                <div style={{ height: "auto" }} key={index}>
                                                    <div className={`${index !== 0 ? 'marginTop24px' : ''}`}>
                                                        <div className='circularBox'>
                                                        <div className='circularIndex'>{(1 + index)}</div>
                                                        <div className='circularBoxTextDiv'>
                                                            <p className='circularIndexText'>Upload PAN for <b className='font-semibold pl-1'>{item?.trim()}</b></p>
                                                        </div>
                                                        </div>

                                                        {uuidId && (
                                                            <FileBrowserNew
                                                                setFile={setFideoUsersPan}
                                                                files={fideoUsersPan}
                                                                uuid={uuidId}
                                                                isMobile={isMobile}
                                                                validFormats={['document', 'image']}
                                                                hasError={oneTimeClickedSecondPage && numKeysInSelectedDocumentState === 0}
                                                                errorMessage={numKeysInSelectedDocumentState === 0 ? "Please upload atleast one file" : "Please upload a valid file"}
                                                                userType={"USER"}
                                                                docType={"PAN"}
                                                                docNum={numKeysInSelectedDocumentState + 1}
                                                                userName={item}
                                                            />
                                                        )}
                                                    </div>
                                                </div>
                                        )})
                                    }
                                    <div className='guidelineText'>Supported document formats: pdf, jpg, jpeg or png. Max. size: 10MB</div>
                                </div>
                            </div> */}

                            {/* PAN PDF UPLOAD FOR BANK STATEMENT */}
                            <div className='mainBox marginTop24px' id="kyc_details" ref={kycRef}>
                                <div className='boxHeading headingDiv alignDiv'>
                                    <p className=''>
                                        Bank Statement
                                    </p>
                                    <p className='headingTextDiv'>{`Bank statement or cancelled cheque of your business`}</p>
                                </div>
                                <div className='documentBoxDiv'>
                                    <div style={{ height: "auto" }} key={0}>
                                    {/* <div className={`${0 !== 0 ? 'marginTop24px' : ''}`}> */}
                                    <div>
                                        <div className='circularBox'>
                                        {/* <div className='circularIndex'>{(1 + index)}</div> */}
                                        <div className='circularBoxTextDiv'>
                                            <p className='circularIndexText pl-0'>Upload Bank Statement / Cheque</p>
                                            {/* <p className='circularIndexText font-semibold pl-2'>{item?.trim()}</p> */}
                                        </div>
                                        </div>
                                        {uuidId && (
                                            <FileBrowserNew
                                                setFile={setBankStatementDocument}
                                                files={bankStatementDocument}
                                                uuid={uuidId}
                                                isMobile={isMobile}
                                                validFormats={['document', 'image']}
                                                hasError={oneTimeClickedSecondPage && (bankStatementDocument ? Object.keys(bankStatementDocument)?.length : 0) === 0}
                                                errorMessage={(bankStatementDocument ? Object.keys(bankStatementDocument)?.length : 0) === 0 ? "Please upload atleast one file" : "Please upload a valid file"}
                                                userType={"COMPANY"}
                                                docType={"BANK_ACCOUNT"}
                                                docNum={(bankStatementDocument ? Object.keys(bankStatementDocument)?.length : 0) + 1}
                                                // userName
                                                guideLineText={true && !isMobile}
                                                changeText={true}
                                                changeTextLine={'Upload Bank Statement / Cheque'}
                                            />
                                        )}
                                    </div>
                                    </div>
                                    <div className='guidelineText'>Supported document formats: pdf, jpg, jpeg or png. Max. size: 10MB</div>
                                </div>
                            </div>

                            <div className='shdf'>
                                <div className='terms'>
                                    <input 
                                        className='termscheckbox'
                                        checked={tickCheckboxFinal}
                                        type="checkbox"
                                        onChange={(e) => setTickCheckboxFinal(e.target.checked)}
                                    />
                                    <p className='text-left'>
                                        I agree to Fideo’s <a href="#" onClick={(e)=>handleClick(e)}  className="font-semibold underline">T&C</a> and <a href="#" onClick={(e)=>handleClick(e)} className="font-semibold underline">privacy policy</a>
                                    </p>
                                </div>
                                {(oneTimeClickedSecondPage && !tickCheckboxFinal) && (
                                    <div className="termserror">
                                        You must agree to the terms and conditions
                                    </div>
                                )}
                            </div>

                            <div className='bothButton marginTop24px marginBottom24px' style={{ width: "100%", height: "48px" }}>
                                <button
                                    onClick={handlePrev}
                                    disabled={leadDataLoader}
                                    className='backButton gap-2'
                                    >
                                        <img
                                            src={ArrowLeft}
                                            alt={`ArrowLeft-icon`}
                                            className='arrowBack'
                                        />
                                </button>
                                <button
                                    onClick={handleSecondPageNext}
                                    disabled={leadDataLoader}
                                    className='saveDraftButton gap-2 flex items-center justify-center'
                                    >
                                        { leadDataLoader &&
                                            <svg
                                                class="animate-spin -ml-1 mr-3 h-8 w-8 text-white"
                                                xmlns="http://www.w3.org/2000/svg"
                                                fill="none"
                                                viewBox="0 0 24 24"
                                            >
                                                <circle
                                                    class="opacity-25"
                                                    cx="12"
                                                    cy="12"
                                                    r="10"
                                                    stroke="currentColor"
                                                    stroke-width="4"
                                                    stroke-dasharray="64"
                                                    stroke-dashoffset="0"
                                                ></circle>
                                                <path
                                                    class="opacity-75"
                                                    fill="currentColor"
                                                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                                ></path>
                                            </svg>
                                        }
                                        <div className='saveButtonText' style={{ color: leadDataLoader ? "gray" : "white"}}>Submit</div>
                                </button>
                            </div>

                        </div>
                    </div>
                </div>
                :
                <>
                    <div className='divInMobile'></div>
                    <div className='stepFourMain'>
                        <div className='stepFourCircle'>
                            <img src={PendingIconSvg} alt="PendingIconSvg" className={'pendingsvg'} />
                        </div>
                        <div className='stepFourStatus'>
                            <p className='stepFourStatusText'>Verification Pending</p>
                        </div>
                        <div className='stepFourHeading'>
                            Account Confirmation Awaiting
                        </div>
                        <div className='stepFourText'>
                            We have received all your documents. Please wait while we verify all your details. You’ll be notified on your email id.
                        </div>
                        <div className='marginTop24px marginBottom24px' style={{ width: "100%", height: "48px" }}>
                            <button
                                onClick={() => {
                                    window.open(
                                        "https://www.fideo.tech/",
                                        "_self"
                                    );
                                }}
                                className='stepFourButton gap-2 w-full'
                                >
                                    <div className='stepFourButtonText'>Explore Website</div>
                            </button>
                        </div>
                    </div>
                </>
        }
      </div>
    </div>
  );

};

export default BusinessDetails;