// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.OtherPagesWrapper_admin_root__QxUhR {
  display: flex;
  width: 100%;
}
.OtherPagesWrapper_admin_root__QxUhR .OtherPagesWrapper_outlet__zNPJW {
  padding: var(--gap_1) var(--gap_2);
  background-color: var(--admin_backgroundColor);
  flex: 1 1;
  height: calc(100vh - var(--admin_header_length) - 8px);
  overflow: scroll;
}
@media (max-width: 640px) {
  .OtherPagesWrapper_admin_root__QxUhR .OtherPagesWrapper_outlet__zNPJW {
    padding: 0;
    height: max-content;
  }
}`, "",{"version":3,"sources":["webpack://./src/Layout/OtherPagesWrapper/OtherPagesWrapper.module.scss"],"names":[],"mappings":"AAIA;EACI,aAAA;EACA,WAAA;AAHJ;AAKI;EACI,kCAAA;EACA,8CAAA;EACA,SAAA;EACA,sDAAA;EACA,gBAAA;AAHR;AAKQ;EAPJ;IAQQ,UAAA;IACA,mBAAA;EAFV;AACF","sourcesContent":[".admin_header {\n    // height: var(--admin_header_length);\n}\n\n.admin_root {\n    display: flex;\n    width: 100%;\n\n    .outlet {\n        padding: var(--gap_1) var(--gap_2);\n        background-color: var(--admin_backgroundColor);\n        flex: 1;\n        height: calc(100vh - var(--admin_header_length) - 8px);\n        overflow: scroll;\n\n        @media (max-width: 640px) {\n            padding: 0;\n            height: max-content;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"admin_root": `OtherPagesWrapper_admin_root__QxUhR`,
	"outlet": `OtherPagesWrapper_outlet__zNPJW`
};
export default ___CSS_LOADER_EXPORT___;
