// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Shimmer_loadingShimmer__8YLcC {
  animation: Shimmer_shimmer__7YJB- 3s infinite linear;
  background: linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%);
  background-size: 1000px 100%;
}

@keyframes Shimmer_shimmer__7YJB- {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}`, "",{"version":3,"sources":["webpack://./src/Layout/Shimmer.module.scss"],"names":[],"mappings":"AAAA;EACI,oDAAA;EACA,2EAAA;EACA,4BAAA;AACJ;;AAEA;EACI;IACI,8BAAA;EACN;EACE;IACI,6BAAA;EACN;AACF","sourcesContent":[".loadingShimmer {\n    animation: shimmer 3s infinite linear;\n    background: linear-gradient(to right, #eff1f3 4%, #e2e2e2 25%, #eff1f3 36%);\n    background-size: 1000px 100%;\n}\n\n@keyframes shimmer {\n    0% {\n        background-position: -1000px 0;\n    }\n    100% {\n        background-position: 1000px 0;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loadingShimmer": `Shimmer_loadingShimmer__8YLcC`,
	"shimmer": `Shimmer_shimmer__7YJB-`
};
export default ___CSS_LOADER_EXPORT___;
