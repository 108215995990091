// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MobileInvoiceReconModal_backdrop__2-GJm {
  z-index: 91;
  top: 0;
  height: 100vh;
}

.MobileInvoiceReconModal_headerTop__uGi9D {
  padding: 20px;
  display: flex;
  align-self: center;
  justify-content: space-between;
  font-size: 2.25rem;
  font-weight: 600;
  line-height: 2.5rem;
  border-bottom: 1px solid #E5E7E8;
}

.MobileInvoiceReconModal_bigCont__Atowk {
  padding-top: 20px;
}`, "",{"version":3,"sources":["webpack://./src/Components/molecule/InvoiceViewReconModal/MobileInvoiceReconModal.module.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,MAAA;EACA,aAAA;AACJ;;AAEA;EACI,aAAA;EACA,aAAA;EACA,kBAAA;EACA,8BAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;EACA,gCAAA;AACJ;;AACA;EACI,iBAAA;AAEJ","sourcesContent":[".backdrop{\n    z-index: 91;\n    top: 0;\n    height: 100vh;\n}\n\n.headerTop{\n    padding: 20px;\n    display: flex;\n    align-self: center;\n    justify-content: space-between;\n    font-size: 2.25rem;\n    font-weight: 600;\n    line-height: 2.5rem;\n    border-bottom: 1px solid #E5E7E8;\n}\n.bigCont{\n    padding-top: 20px;  \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"backdrop": `MobileInvoiceReconModal_backdrop__2-GJm`,
	"headerTop": `MobileInvoiceReconModal_headerTop__uGi9D`,
	"bigCont": `MobileInvoiceReconModal_bigCont__Atowk`
};
export default ___CSS_LOADER_EXPORT___;
