import clsx from "clsx";
import { AnimatePresence, motion } from "framer-motion";
import useClickOutside from "hooks/useClickOutside";
import React, { useEffect, useRef, useState } from "react";
import classes from './CustomSelectorValues.module.scss';
import { useDispatch } from 'react-redux'
import { modalActions } from 'Store/modal-redux'
import checkIcon from 'assets/Icons/check.svg'

const CustomSelectorValues = ({
  id,
  disabled = false,
  onToggle,
  onChange,
  selectedValue,
  items = [],
  inputValidity,
  placeholder,
  validation,
  errorMessage,
  searchText,
  headingText,
  nothingFoundText,
  hasError: externalHasError,
  handleDropdownClick,
  focusRef,
  noneRef,
  initiateFocus,
  classNameStyle
}) => {
  const ref = useRef();
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false);
  const [query, setQuery] = useState("");
  const [filteredList, setFilteredList] = useState(items);
  const [hasError, setHasError] = useState(externalHasError);
  const [firstLoad, setFirstLoad] = useState(true);
  const [windowSize, setWindowSize] = useState(800);

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    setHasError(externalHasError);
  }, [externalHasError]);

  const optRef = useClickOutside(() => {
    if (open) {
      handleToggle();
    }
  });

  useEffect(() => {
    if (!firstLoad) setHasError(!selectedValue);
    let allValid = true;

    validation?.forEach((test) => {
      if (!test(selectedValue)) {
        allValid = false;
        return;
      }
    });
    if (inputValidity && allValid) inputValidity(allValid);
  }, [selectedValue, firstLoad]);

  useEffect(() => {
    if (query.length > 0) {
      handleSearch();
    } else {
      setFilteredList(items);
    }
  }, [query]);

  const handleSearch = () => {
    setFilteredList(
      items?.filter((item) =>
        item?.purposeCode?.toLowerCase()?.startsWith(query?.toLowerCase())
      )
    );
  };

  const handleToggle = (e) => {
    if (e) e.stopPropagation();
    if (open) {
      // setQuery("")
      setOpen(false);
      if (firstLoad) setFirstLoad(false);
    } else {
      console.log(windowSize)
      if (windowSize >= 600) {
        setOpen(true);
      } else {
        dispatch(modalActions.purposeCodeDropDown(true))
        setFirstLoad(false)
      }
    }
    if (onToggle) onToggle();
  };

  const handleTypeSelection = (item) => {
    setHasError(false);
    onChange(item);
    handleToggle();
  };

  return (
    <div ref={optRef}>
      <div className={clsx(`${classNameStyle}`,"relative")}>
      <label className={classes.selectLabel}>{(selectedValue !== "") ? placeholder : ""}</label>
        <button
          type="button"
          className={clsx(
            disabled ? "bg-neutral-100" : "bg-white",
            open ? "border-2 border-[#1E333F]" : (hasError ? "border-2 border-[#FF6868]" : "border border-gray-300"),
            // 2px solid #FF6868;
            "relative h-20 w-full rounded-xl shadow-sm pl-1 pr-1  text-left cursor-default focus:outline-none sm:text-sm"
          )}
          // style={{ height: "48px" }}
          aria-haspopup="listbox"
          aria-expanded="true"
          aria-labelledby="listbox-label"
          onClick={(e) => {
            console.log(e, "xxx")
            
            handleToggle(e);
          }}
          disabled={disabled}
        >
          {(selectedValue && !open) ? (
            <div className="pl-3 py-2" onClick={()=>{setQuery(selectedValue)}}>
              <span className="flex flex-row items-center">
                <p className="text-[1.6rem] leading-[2.4rem] font-[400] text-[#1E333F]" >
                  {selectedValue || ""}
                </p>
              </span>
              <span
                className={`absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none ${
                  disabled ? "hidden" : ""
                }`}
              >
                { open
                  ?
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path d="M18 15L12 9L6 15" stroke="#1E333F" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  :
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path d="M18 9L12 15L6 9" stroke="#1E333F" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                }
              </span>
            </div>
          ) : (
            <div className="w-full h-full">
              {/* <p className="text-[14px] leading-[24px] font-[400] text-[#1E333F80]">
                {placeholder || ""}
              </p> */}
              <input
                        type="search"
                        id="Search"
                        name="search"
                        autoFocus={open}
                        autoComplete="off"
                        className={clsx(`${classes.searchInput} focus:bg-none outline-none pl-2 py-2 focus:border-none block w-full h-full rounded-md text-[1.6rem] leading-[2.4rem] font-[400]`)}
                        placeholder={searchText}
                        value={query}
                        onChange={(e) => setQuery(e.target.value)}
                        onClick={handleDropdownClick}
                        ref={initiateFocus?focusRef:noneRef}
                      />
              <span
                className={`absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none ${
                  disabled ? "hidden" : ""
                }`}
              >
                { open
                  ?
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path d="M18 15L12 9L6 15" stroke="#1E333F" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  :
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path d="M18 9L12 15L6 9" stroke="#1E333F" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                }
              </span>
            </div>
          )}
        </button>
        {hasError && (
          <div className="text-[#FF6868] font-normal text-xl mt-3 top-20 flex">
            {errorMessage || ""}
          </div>
        )}

        { windowSize >= 600 &&
            <AnimatePresence>
              {open && (
                <motion.ul
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.1 }}
                  className="absolute top-[48px] overflow-hidden z-10 mt-1 w-[100%] bg-white shadow-lg rounded-md text-base ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
                  tabIndex={-1}
                  role="listbox"
                  aria-labelledby="listbox-label"
                  aria-activedescendant="listbox-option-3"
                >
                  {/* <div className="sticky h-20 top-0 z-10 bg-white">
                    <li className=" text-gray-900 cursor-default select-none relative py-6 px-6">
                      <input
                        type="search"
                        id="Search"
                        name="search"
                        autoFocus
                        autoComplete="off"
                        className="focus:bg-none outline-none focus:border-none block w-full h-full text-2xl rounded-md"
                        placeholder={searchText}
                        value={query}
                        onChange={(e) => setQuery(e.target.value)}
                      />
                        <style>
                          {`
                            #Search::-webkit-search-cancel-button {
                              filter: hue-rotate(252deg) brightness(0);
                            }
                          `}
                        </style>
                    </li>
                    <hr />
                  </div> */}

                  <div className={clsx(classes.dropdown, 'mt-[0px]')}>
                    
                    <div className={clsx(classes.set, '!mt-[0px]')}>
                      {/* <div className={(clsx(classes.header, 'mt-[12px] !mb-[6px]'))}>{headingText}</div> */}
                        <ul
                          className={
                            "max-h-[240px] scrollbar scrollbar-track-gray-100 scrollbar-thumb-gray-300 hover:scrollbar-thumb-gray-600 scrollbar-thumb-rounded scrollbar-thin overflow-y-scroll"
                          }
                        >
                          {filteredList?.length === 0 ? (
                            <li className="text-gray-900 font-normal text-[14px] font-[400] leading-[20px] cursor-default select-none relative py-[12px] px-[20px]">
                              {nothingFoundText}
                            </li>
                          ) : (
                            filteredList.map((item, index) => {
                              return (
                                <li
                                  key={`${id}-${index}`}
                                  className="text-gray-900 h-auto cursor-default select-none relative py-[12px] px-[20px] flex items-center hover:bg-gray-50 transition border-b border-b-[#ECEFF2]"
                                  id="listbox-option-0"
                                  role="option"
                                  onClick={() => handleTypeSelection(item)}
                                >

                                  <div className="gap-[2px] w-[100%]">
                                    <div className="text-[1.6rem] w-[100%] leading-[2.4rem] font-[500] h-[24px] text-[#1E333F]">{item?.purposeCode}</div>
                                    <div className="flex gap-[4px] items-center justify-between w-[100%]">
                                      <h1 className="text-[14px] leading-[20px] font-[400] text-[#1E333F80]">{item?.description}</h1>
                                      {item?.purposeCode === selectedValue ? (
                                    <div className="w-[20px] h-[20px] mb-[14px]">
                                      <img style={{width:'100%',height:'100%', minWidth: "20px"}} src={checkIcon} alt="checkIcon"/>
                                    </div>
                                  ) : null}
                                      </div>
                                  </div>
                                  
                                </li>
                              );
                            })
                          )}
                        </ul>
                    </div>
                  </div>
                </motion.ul>
              )}
            </AnimatePresence>
        }
      </div>
    </div>
  );
};

export default CustomSelectorValues;