import Backdrop from 'Layout/Backdrop'
import Modal from 'Layout/Modal'
import clsx from 'clsx'
import ReactDOM from 'react-dom'
import { useDispatch } from 'react-redux'
import { modalActions } from 'Store/modal-redux'
import Close from 'Layout/Close'

import classes from './CreateInvoice.module.scss'
import leftPointer from 'assets/Icons/arrow-left.svg'
import ContactInfo from 'Components/Global/ContactInfo'
import InfoIcon from 'assets/Icons/info_white_circle.svg'
import PointerIcon from 'assets/Icons/mouse-pointer.svg'
import FileIcon from 'assets/Icons/file-plus.svg'
import PurposeIcon from 'assets/Icons/purpose-code.svg'
import PayIcon from 'assets/Icons/pay-icon.svg'
import CopyIcon from 'assets/Icons/copy-black.svg'
import { formatNumberToLocaleString } from 'hooks/formatNumberToLocaleString';

import {
    Accordion,
    AccordionContent,
    AccordionItem,
    AccordionTrigger,
} from 'Components/atom/Accordion/Accordion'
import Button from 'Layout/Button'
import { useSnackbar } from 'notistack'

const BankDetailsModal = (props) => {
    const { enqueueSnackbar } = useSnackbar()
    const { invoiceDetails } = props
    // const { invoiceResponse, virtualAccountDto } = invoiceDetails
    const dispatch = useDispatch()

    const handleCopy = (event, method) => {
        event.preventDefault()
        try {
            const text = `Beneficiary : ${
                method.accountHolderName
            }\nBeneficiary address: ${method.bankAddress}\nAccount Number : ${
                method.accountNumber
            }\nAccount Type : ${method.accountType}\n${
                method?.routingCodeType &&
                `${method.routingCodeType}: ${method.routingCode}`
            }\nBank Name: ${method.bankName}\nBank Address: ${method.bankAddress}`
            if ('clipboard' in navigator) {
                navigator.clipboard.writeText(text)
            }
            document.execCommand('copy', true, text)
            enqueueSnackbar('Details Copied!', { variant: 'success' })
            return
        } catch (error) {}
    }

    console.log('invoiceDetails',invoiceDetails)
    return (
        <Modal
            className={clsx(
                classes.modal,
                'h-screen max-w-[640px] p-0 overflow-scroll bg-[#F4F6FA] z-[400]'
            )}
        >
            {ReactDOM.createPortal(
                <Backdrop
                    onClick={() => {
                        dispatch(modalActions.showPaymentDisplay())
                    }}
                    className={classes.backdropBank}
                />,
                document.getElementById('backdrop-root')
            )}
            <div className="flex z-10  flex-row items-center justify-center sm:justify-between sticky top-0 right-0 left-0 px-16 py-[20px] bg-[#F4F6FA] ">
                <img
                    src={leftPointer}
                    alt="left-pointer"
                    className="block sm:hidden absolute left-8"
                    onClick={() => {
                        dispatch(modalActions.showPaymentDisplay())
                    }}
                />
                <p className="text-4xl font-bold">Bank Details</p>
                <Close
                    className="w-[32px] h-[32px] hidden sm:block"
                    onClick={() => {
                        dispatch(modalActions.showPaymentDisplay())
                    }}
                />
            </div>
            <div className=" flex flex-col gap-[32px]">
                <div className="w-full px-8 sm:px-16 flex flex-col gap-[20px]">
                    <div className="flex flex-col gap-[12px]">
                        {/*<p className="text-4xl font-bold">Payment Methods</p>*/}
                        {/*<p className="text-2xl font-medium  text-[#1E333F80] ">*/}
                        {/*    Select payment method , copy details and make the transfer*/}
                        {/*</p>*/}
                    </div>
                    <div className="flex flex-col items-center justify-between w-full gap-4 pb-[100px] ">
                        <Accordion
                            type="single"
                            collapsible
                            className="w-full flex flex-col gap-[20px]"
                        >
                            {invoiceDetails?.map((method, index) => (
                                <AccordionItem
                                    value={method?.paymentMethod}
                                    className="bg-[#FFFFFF] px-8 py-8 rounded-[12px]"
                                >
                                    <AccordionTrigger openOnLoad={index === 0}>
                                        <div className="w-full flex flex-col items-start gap-2 justify-between">
                                            <p className="text-3xl font-semibold text-[#1E333F]">
                                                Payment Method : {method.paymentMethod}
                                                {/*{index === 0 && '(Recommended)'}*/}
                                            </p>
                                            {/*<p className="text-2xl font-medium text-left text-[#1E333F80]">*/}
                                            {/*    {paymentTime[method.paymentMethod?.toLowerCase()] || ''}*/}
                                            {/*</p>*/}
                                        </div>
                                    </AccordionTrigger>
                                    <AccordionContent>
                                        <div className="flex flex-col gap-[16px]">
                                            <PaymentMethodDetails
                                                title={'Beneficiary name'}
                                                value={method?.accountHolderName}
                                            />
                                            <PaymentMethodDetails
                                                title={'Beneficiary bank country'}
                                                value={method?.beneficaryCountry}
                                            />
                                            <PaymentMethodDetails
                                                title={'Beneficiary address'}
                                                value={method?.bankAddress || '-'}
                                            />
                                            <PaymentMethodDetails
                                                title={'Account Number'}
                                                value={method?.accountNumber}
                                            />
                                            <PaymentMethodDetails
                                                title={'Account Type'}
                                                value={method?.accountType}
                                            />
                                            <PaymentMethodDetails
                                                title={'BIC/SWIFT code'}
                                                value={method?.methodRoutingNumber?.SWIFT} />
                                            <PaymentMethodDetails
                                                title={method?.routingCodeType}
                                                value={
                                                    method?.routingCodeType ? method?.routingCode : null
                                                }
                                            />
                                            <PaymentMethodDetails
                                                title={'Bank Name'}
                                                value={method?.bankName || '-'}
                                            />
                                            <PaymentMethodDetails
                                                title={'Bank Address'}
                                                value={method?.bankAddress || '-'}
                                            />
                                            {
                                                method?.methodRoutingNumber?.FEDWIRE &&
                                                <PaymentMethodDetails
                                                    title={'Fedwire routing number'}
                                                    value={method?.methodRoutingNumber?.FEDWIRE || '-'}
                                                />
                                            }
                                            {
                                                method?.methodRoutingNumber?.ACH &&
                                                <PaymentMethodDetails
                                                    title={'ACH routing number'}
                                                    value={method?.methodRoutingNumber?.ACH || '-'}
                                                />
                                            }
                                            {
                                                method?.methodRoutingNumber?.SEPA_INSTANT &&
                                                <PaymentMethodDetails
                                                    title={'BIC'}
                                                    value={method?.methodRoutingNumber?.SEPA_INSTANT || '-'}
                                                />
                                            }
                                            {
                                                method?.methodRoutingNumber?.BACS_CREDIT &&
                                                <PaymentMethodDetails
                                                    title={'Sort Code'}
                                                    value={method?.methodRoutingNumber?.BACS_CREDIT || '-'}
                                                />
                                            }
                                        </div>
                                    </AccordionContent>
                                    <Button
                                        className="w-full mt-2 bg-white border border-dashed border-[#181C3033]"
                                        btnClassText="text-2xl font-medium text-black"
                                        icon={CopyIcon}
                                        text="Copy Details"
                                        disabled={false}
                                        onClick={(e) => handleCopy(e, method)}
                                    />
                                </AccordionItem>
                            ))}
                        </Accordion>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

const PaymentStep = (props) => {
    const { title, text, icon, isEnd } = props
    return (
        <div className="flex flex-row items-center w-full gap-5">
            <div className="w-[48px] aspect-square rounded-full flex items-center justify-center bg-[#F7F8FB]">
                <img src={icon} alt="" className="w-[24px] aspect-square" />
            </div>
            <div
                className={clsx(
                    'w-full flex flex-col gap-2 py-6',
                    !isEnd && 'border-b border-b-[#E9EBEC]'
                )}
            >
                <p className="text-xl text-[#1E333F80] font-semibold">{title}</p>
                <p className="text-2xl text-[#1E333F] font-medium">{text}</p>
            </div>
        </div>
    )
}

const PaymentMethodDetails = ({ title, value }) => {
    if (!value) return
    return (
        <div className="w-full flex flex-col gap-[4px] justify-between">
            <p className="text-2xl font-medium text-[#1E333F80]">{title}</p>
            <p className="text-2xl font-medium text-[#1E333F]">{value}</p>
        </div>
    )
}

export default BankDetailsModal


