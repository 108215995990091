import Button from "Layout/Button";
import MobileInputSimple from "Layout/MobileInputSimple";
import TextInput from "Layout/TextInput";
import { useState, useEffect } from "react";
import classes from "./AddUserForm.module.scss";
import { Form, Link, useNavigate } from "react-router-dom";
import clsx from "clsx";
import UseApiCall from "hooks/useApiCall";
import { apiSignUp } from "services";
import CustomSelector from "Components/atom/CustomSelector/CustomSelector";
import { COUNTRIES } from "lib/data/countries";
import { useSnackbar } from 'notistack'

const emailValidation = [
  (value) => value.trim() !== "",
  (value) => /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value)
];

// (value) => /[a-zA-z0-9_\.\-]+[@][a-z]+[\.][a-z]+/.test(value),

const AddUserForm = (props) => {
  const {
    firstName,
    setFirstName,
    setIsFirstNameValid,
    lastName,
    setLastName,
    setIsLastNameValid,
    email,
    setEmail,
    setIsEmailValid,
    mobile,
    setMobile,
    setIsMobileValid,
    oneTimeClicked,
    countryCode,
    setCountryCode
    } = props;

  return (
    <>
      <Form className={classes.form}>
        <div className={classes.personalName}>
            <TextInput
            type="text"
            text="First Name*"
            className={clsx(classes.input, )}
            errorMessage="Please enter a valid first name"
            validation={[(value) => (value ? value.trim() !== "" : false)]}
            inputValidity={(value) => {
                setIsFirstNameValid(value);
            }}
            setInput={(value) => {
                setFirstName(value);
            }}
            enteredInput={firstName}
            hasError={oneTimeClicked && firstName === ""}
            maxLength={128}
            />
            <TextInput
            type="text"
            text="Last Name*"
            className={clsx(classes.input, )}
            errorMessage="Please enter a valid last name"
            validation={[(value) => (value ? value.trim() !== "" : false)]}
            inputValidity={(value) => {
                setIsLastNameValid(value);
            }}
            setInput={(value) => {
                setLastName(value);
            }}
            enteredInput={lastName}
            hasError={oneTimeClicked && lastName === ""}
            maxLength={128}
            />
        </div>
        <TextInput
          type="text"
          text="Email Address*"
          className={clsx(classes.input, 'w-[100%]' )}
          errorMessage="Please enter a valid email address"
          validation={emailValidation}
          inputValidity={(value) => {
            setIsEmailValid(value);
          }}
          setInput={(value) => {
            setEmail(value);
          }}
          enteredInput={email}
          // hasError = {wrongInput && loginType === 'email' && !isEmailValid}
          hasError={oneTimeClicked && email === ""}
          maxLength={128}
        />
        <MobileInputSimple
          className={classes.input}
          // hasError = {wrongInput && loginType === 'mobile' && !isMobileValid}
          hasError={oneTimeClicked && mobile === ""}
          mobileCodeInput={countryCode}
          isRequired={false}
          mobileNumberInput={mobile}
          setMobileNumberInput={(value) => setMobile(value)}
          setMobileValidity={(value) => setIsMobileValid(value)}
          setMobileCodeInput={(value) => setCountryCode(value)}
          inputValidity={(value) => {
            setIsMobileValid(value);
          }}
        />
      </Form>
    </>
  );
};

export default AddUserForm;
