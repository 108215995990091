import classes from "./PayoutAmountMobile.module.scss";
import clsx from "clsx";
import Modal from "Layout/Modal";
import Backdrop from "Layout/Backdrop";
import ReactDOM from 'react-dom'
import Close from "Layout/Close";
import { modalActions } from "Store/modal-redux";
import { useDispatch } from "react-redux";

const PayoutAmountMobile = (props) =>{
    const {data, tableHeading, heading} = props
    const dispatch = useDispatch();
return (
    <Modal
    className={clsx(
      classes.modal,
      ' -sm:h-fit p-0 w-screen md:w-[100%] xl:w-auto z-[100] rounded-t-[12px] bottom-0 overflow-y-scroll'
    )}
    >
    {
        ReactDOM.createPortal(
            <Backdrop className={classes.backdrop}
            onClick={()=>{
            dispatch(modalActions.payoutAmountInfo(false))
            }}
            />,
            document.getElementById('backdrop-root')
    )}
    <div className={classes.headerTop}>
        <div className={classes.invoiceHead}>{heading}</div>
        <Close
        onClick = {()=>{
            dispatch(modalActions.payoutAmountInfo(false))
        }}
        />
    </div>
    <div className={classes.bigCont}>
        <table style={{ borderCollapse: 'collapse', width: '100%' }}>
            {/* <thead>
                <tr>
                <th style={{ minWidth: '100px', borderBottom: '1px solid #E5E7E8', padding: '20px', textAlign: 'left', overflowWrap: "anywhere", fontSize:"18px", lineHeight:"26px", fontWeight:"600" }}>Invoice Id</th>
                <th style={{ minWidth: '130px', borderBottom: '1px solid #E5E7E8', padding: '20px', textAlign: 'right', overflowWrap: "anywhere", fontSize:"18px", lineHeight:"26px", fontWeight:"600" }}>{tableHeading}</th>
                </tr>
            </thead> */}
          { tableHeading === "Gross Payout"
            ?
                <tbody>
                    {data?.invoices?.map((item) => (
                    <tr key={item?.id}>
                        <td style={{ minWidth: '100px', borderBottom: '1px solid #E5E7E8', padding: '20px', textAlign: 'left', overflowWrap: "anywhere", fontSize:"16px", fontWeight:"400", lineHeight:"24px" }}>
                        {item?.invoiceId}
                        </td>
                        <td style={{ minWidth: '150px', borderBottom: '1px solid #E5E7E8', padding: '20px', textAlign: 'right', overflowWrap: "anywhere", fontSize:"16px", fontWeight:"400", lineHeight:"24px" }}>
                        {data?.invoiceCurrency?.sign}
                        {
                            data?.invoiceCurrency?.sign === '₹' 
                            ? item?.grossAmount?.toLocaleString('en-IN', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                                })
                            : item?.grossAmount?.toLocaleString('en-US', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                                })
                        }
                        </td>
                    </tr>
                    ))}
                </tbody>
            :
                <tbody>
                    {data?.invoices?.map((item) => (
                        <tr key={item?.id}>
                        <td style={{ minWidth: '100px', borderBottom: '1px solid #E5E7E8', padding: '20px', textAlign: 'left', overflowWrap: "anywhere", fontSize:"16px", fontWeight:"400", lineHeight:"24px"}}>
                            {item.invoiceId}
                        </td>
                        <td style={{ minWidth: '130px', borderBottom: '1px solid #E5E7E8', padding: '20px', textAlign: 'right', overflowWrap: "anywhere", fontSize:"16px", fontWeight:"400", lineHeight:"24px"}}>
                            {item.settledAmount ? data.settledAmountCurrency.sign : '--'}
                            {
                            data.settledAmountCurrency?.sign === '₹' 
                                ? item.settledAmount?.toLocaleString('en-IN', {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                })
                                : item.settledAmount?.toLocaleString('en-US', {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                })
                            }
                        </td>
                        </tr>
                    ))}
                </tbody>   
        }
            </table>
        </div>

    </Modal>
 )
}

export default PayoutAmountMobile