import { useEffect, useState } from 'react';
import classes from 'Layout/MobileInput.module.scss';
import chevron from 'assets/Images/chevron-down.svg';
import useClickOutside from 'hooks/useClickOutside';
import CountryDropdown from 'Components/Dropdown/CountryDropdown';
import clsx from 'clsx'

const MobileInputSimple = (props) => {
  const {initiateFocus , focusRef , noneRef} = props
  const [isTouched, setIsTouched] = useState(false)
  const [showCountry, setShowCountry] = useState(false)
  const [firstTyping, setFirstTyping] = useState(false)
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    setHasError(props.hasError);
  }, [props.hasError])

  const dropdownRef = useClickOutside(() => setShowCountry(false))

  const [isInputValid, setIsInputValid] = useState(true);

  useEffect(() => {
    const regex = /^[1-9][0-9]{5,13}$/; // Regex to match 6 to 14 digits starting with 1-9
    setIsInputValid(regex.test(props.mobileNumberInput) && props.mobileNumberInput !== '');
  }, [props.mobileNumberInput]);


  useEffect(() => {
    if (props.setMobileValidity) props.setMobileValidity(isInputValid)
  }, [isInputValid])

  useEffect(() => {
    if (props.inputValidity) props.inputValidity(isInputValid);
  }, [isInputValid]);

  const changeHandler = (event) => {
    props.setMobileNumberInput(event.target.value);
    setFirstTyping(true);
  }

  const blurHandler = () => {
    setIsTouched(true)
  }

  const selectCountry = (code) => {
    setShowCountry(false)
    props.setMobileCodeInput(code)
  }

  useEffect(() => {
    if (isTouched && !isInputValid) {
      setHasError(true);
    } else {
      setHasError(props.hasError);
    }
  }, [isInputValid, props])

  return (
    <div className={clsx(classes.box, props.className)}>
      <div
        className={clsx(
          classes.mobile_number,
          hasError ? 'border-[2px] border-solid border-[#FF6868]' : 'border-[1px] border-solid border-[#D2D6D9]',
        )}
        style={{ height: props?.customStyle?.height && "48px" }}
      >
        <div
          ref={dropdownRef}
          className={`${classes.country_selector} ${
            showCountry && classes.country_selector_active
          }`}
        >
          <div
            className={classes.default}
            onClick={() => {
              setShowCountry((prev) => !prev)
            }}
          >
            <div className={classes.code}>{props.mobileCodeInput}</div>
            <img
              src={chevron}
              alt="chevron-icon"
              className={`${classes.icon} ${
                showCountry && classes.icon_active
              }`}
            />
          </div>
          {showCountry && (
            <CountryDropdown
              className={classes.dropdown}
              dropdownOptionClass={classes.dropdown_option}
              onClick={(value) => selectCountry(value.dial_code)}
              mobileCode={props.mobileCodeInput}
            />
          )}
        </div>
        <input
          type="number"
          className={classes.input}
          placeholder={`Mobile Number${props?.isRequired ? '*' : ''}`}
          onChange={changeHandler}
          value={props.mobileNumberInput}
          onBlur={blurHandler}
          onFocus={() => setShowCountry(false)}
          maxLength={20}
          name="mobile"
          ref={initiateFocus?focusRef:noneRef}
        />
      </div>
      {hasError && (
        <div className={classes.error}>Please enter a valid phone number</div>
      )}
    </div>
  )
}
export default MobileInputSimple;