import classes from "./Compliance.module.scss"
import PageIntro from "Components/Global/PageIntro"
import ComplianceTable from "Components/ReportTable/ComplianceTable"
import { apiGetComplianceList } from "services"
import UseApiCall from "hooks/useApiCall"
import { useEffect, useState } from "react"
import { enqueueSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux"
import { ComplianceActions } from "Store/customer-redux"



const Compliance = () => {
    const [resData, setResData] = useState([])
    const [currentPage , setCurrentPage] = useState(0);
    const [pageSize , setPageSize] = useState(30);

    const dispatch = useDispatch();
    const complianceData = useSelector((state) => state.compliance.compliance_list)
    const [otherLoader, setOtherLoader] = useState(true)
    const [getComplianceData, fetchingCompliance] = UseApiCall(apiGetComplianceList, (res) => {
        setOtherLoader(false);
        dispatch(ComplianceActions.getComplianceList(res?.data?.complianceRecordResponseDTOS))
        setResData(res?.data)
      }, (err) => {
        setOtherLoader(false)
        enqueueSnackbar(`${err?.data?.error_response?.message} ${err?.data?.error_response?.errors !== null ? JSON.stringify(err?.data?.error_response?.errors[0]) : ""}`, { variant: 'error' })
    })

    const handlePaginate = (page)=>{
        setCurrentPage(page - 1)
    }

    useEffect(()=>{
        getComplianceData({
            pageNum : currentPage,
            size : pageSize
        });
    },[currentPage,pageSize])
    return (
        <div className={classes.compliance}>
            <PageIntro
               pageName={'Compliance Management'}
            //    message={'Lorem ipsum text goes here'}
            />
            <ComplianceTable
               tableData = {complianceData}
               loading={fetchingCompliance || otherLoader}
               resData={resData}
               handlePaginate={handlePaginate}
               currentPage={currentPage}
               pageSize={pageSize}
            />
        </div>
    )
}

export default Compliance