import clsx from 'clsx'
import styles from './ComingSoonPromp.module.css'

const ComingSoonPrompt = () => {
    return (
        <div className="w-[40rem] h-96 flex flex-col items-center justify-center">
            <p className={clsx("text-3xl text-bg-black text-center")}>
                Coming Soon!
            </p>
        </div>
    )
}
export default ComingSoonPrompt