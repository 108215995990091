import Button from 'Layout/Button';
import classes from 'Pages/Admin/Financing.module.scss';

const Financing = ( ) => {
    return (
        <div className={classes.financing}>  
            <div className={classes.text}>Coming Soon.</div>
            {/* <Button text="Get instant credit" className={classes.button} disabled={true}/> */}
        </div>
    )
}
export default Financing;