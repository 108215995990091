import Action from 'Components/ReportTable/Action'
import ContactInfo from 'Components/Global/ContactInfoNew'
import classes from 'Components/ReportTable/MobileReportTable.module.scss'
import Status from './Status'
import ReportTableLayout from 'Components/ReportTable/ReportTableLayout'
import PaymentStatusTag from './PaymentStatusTag'
import clsx from 'clsx'
import { DownloadOutlined } from '@ant-design/icons'
import { Tag } from 'antd'
import { formatNumberToLocaleString } from 'hooks/formatNumberToLocaleString';
import Shimmer from 'Layout/Shimmer'
import { useDispatch , useSelector } from 'react-redux'
import ReactDOM from 'react-dom'
import { modalActions } from 'Store/modal-redux'
import DownloadFiraMobile from 'Components/atom/DownloadFiraMobile/DownloadFiraMobile'
import { useState } from 'react'

const MobileFiraReportTable = (props) => {
  const { option } = props
  const dispatch = useDispatch();
  const [downloadInvoice , setDownloadInvoice] = useState([]);
  const showMobileFiraDownload = useSelector((state)=>state.modal.downloadFiraMobile);
  if (props.tableData?.length > 0)
    return (
    <>
    {showMobileFiraDownload &&
        ReactDOM.createPortal(
          <DownloadFiraMobile
            invoice = {downloadInvoice}
            handleDownloadClick = {props.handleReportDownload}
          />,
          document.getElementById('modal-root')
        )
    }
      <ReportTableLayout className={`${classes.table} ${props.className}`}>
        {props.tableData.map((data, index) => {
          const {
            // remittanceAmount,
            action,
            customer,
            invoiceId,
            invoiceAmount,
            invoiceCurrency,
            // remittanceAmountCurrency,
            firaSummary
          } = data
          const { emailAddress, customerName, country, countryName } = customer || {}
          return (
            <div key={`${data.email}-${index}`} className={classes.row}>
              <div className={classes.infoOne}>
                <div className={classes.invoice}>{invoiceId}</div>
                {/* Action */}
                {action === 'PENDING' ? (
                  <Tag color="volcano">PENDING</Tag>
                ) : (
                  <DownloadOutlined
                    style={{
                      fontSize: 16,
                      background: 'lightgrey',
                      borderRadius: '50%',
                      padding: 6,
                    }}
                    onClick={() => {
                      setDownloadInvoice(data);
                      dispatch(modalActions.downloadFiraMobile(true))
                    }}
                  />
                )}
              </div>
              <div className={classes.infoTwo}>
                <ContactInfo
                  name={customerName}
                  country={country}
                  countryName={countryName}
                  email={emailAddress}
                  className={classes.contact}
                />
                <div className={classes.infoThree}>
                  <div className={clsx(classes.amount, 'mb-1')}>
                    {invoiceCurrency?.sign}
                    {formatNumberToLocaleString((invoiceAmount), 2, 2)}
                  </div>
                  <div className={clsx(classes.amount, 'mb-1')}>
                    {
                      firaSummary?.remittanceAmount
                        ? `${firaSummary?.remittanceAmountCurrency?.sign}${firaSummary?.remittanceAmountCurrency?.sign === '₹' ? firaSummary?.remittanceAmount?.toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : firaSummary?.remittanceAmount?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
                        : '--'
                    }
                  </div>
                  {/* {status && <PaymentStatusTag status={status} type="light" />} */}
                </div>
              </div>
            </div>
          )
        })}
      </ReportTableLayout>
    </>
    )
  return (
    <div
      style={{
        textAlign: 'center',
        width: '100%',
        marginTop: '4rem',
        fontSize: '2.5rem'
      }}
      className={`${classes.table}`}
    >
      {
         props.loading ?
         <>
            <Shimmer className="w-[100%] mb-[10px] h-[100px]"/>
            <Shimmer className="w-[100%] mb-[10px] h-[100px]"/>
            <Shimmer className="w-[100%] h-[100px]"/>
          </>
         :
         (props.tableData?.length === 0 && "No Reports Yet.")
      }
    </div>
  )
}

export default MobileFiraReportTable
