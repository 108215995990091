import React, { useEffect, useRef, useState } from 'react';
import classes from 'Components/otpScreen/EnterOtp.module.scss';
import crossImg from 'assets/Icons/crossImg.svg'
import rightTickImg from 'assets/Icons/rightTickImg.svg'


const TypeOtp = ({handleResendOTPView,invoiceData, setOtpViewNowBox ,otpErrorState, setPaymentLinkOtpRequired,handleOTPView,presignedUrlErrorState, handleOTPViewNow, otp, setOtp}) => {

    // const [otp, setOtp] = useState(['', '', '', '','','']); // Initialize OTP as an array of empty strings
    const otpInputs = useRef([]); // Create a ref to hold references to OTP input elements
    const [active,setActive] = useState(false)
    const [counter,setCounter] = useState(false)
    const [timer, setTimer] = useState(180); // Initial timer value in seconds
    const [isTimerActive, setIsTimerActive] = useState(false);

    const handleOtpInput = (e, index) => {
        const value = e.target.value;

        if(index  === 0)
            otp[index] = '';
            setOtp([...otp]);
          
          if (e.key === 'Backspace' && index > 0) {
            if(index - 1 === 0)
            otp[index] = '';
            
              otp[index] = ''; // Clear the current digit
              setOtp([...otp]);
              otpInputs.current[index - 1].focus();

            if(index === 0)
            otp[index] = '';

            if(index <= 5)
             setActive(false)
            return;
          }

        const isDigit = /[0-9]/.test(value);
    
        if (isDigit && index >= 0 && index < otp.length) {
          otp[index] = value; // Update the OTP array
          setOtp([...otp]);

          if(index === 5)
          setActive(true)
          if(index < 5)
          setActive(false)
    
          // Automatically focus on the next input element if available
          if (otpInputs.current[index + 1]) {
            otpInputs.current[index + 1].focus();
          }
        } 
      };

    // console.log(otp.join(''))
    let email = invoiceData?.invoiceResponse?.customer?.emailAddress;
    // let f = email?.slice(0,1);
    // let l = email?.substring(email?.indexOf("@")-1, email?.indexOf("@"))
    // let e = email?.substring(email?.indexOf("@")+1, email?.indexOf("@")+2)
    // const parts = email?.split('.');
    // const substringAfterDot = parts[parts?.length - 1];

    const handleOTPViewCross = () => {
        setOtpViewNowBox((prev) => !prev)
        // setPaymentLinkOtpRequired((prev) => !prev)
    }

  // Function to start the timer
  const startTimer = () => {
    setIsTimerActive(true);
  };

  useEffect(() => {
    let countdown;

    // Update the timer every second
    if (isTimerActive && timer > 0) {
      countdown = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }

    // Clear the interval when the timer reaches 0
    if (timer === 0) {
      setIsTimerActive(false);
      clearInterval(countdown);
    }

    return () => {
      clearInterval(countdown);
    };
  }, [isTimerActive, timer]);

  // Function to handle OTP resend
  const handleResend = () => {
    // Perform OTP resend logic here
    // Start the timer when the resend button is clicked
    startTimer();
  };

  const handleResendAndOTPView = () => {
    handleResend();
    handleResendOTPView();
  };

  return (
    <div className={`${classes.typeOtpContainer}`} style={{ left: "0px" }}>
      {/* <div className={`${classes.typeOtpContainer}, 'w-[100vw] min-h-[100%] max-w-[1032px] 2xl:max-w-[1300px] absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2`}></div> */}
        <div className={`${classes.typeOtpBox}`}>
            <div className={`${classes.typeOtpBox1}`}>
                <span className={`${classes.typeOtpBox1Text}`}>View Invoice</span>
                <img src={crossImg} alt="" className={`${classes.crossImg}`} onClick={handleOTPViewCross}/>
            </div>
            <div className={`${classes.typeOtpBox2}`}>
                <div className={`${classes.typeOtpBox2A}`}>
                    <span className={`${classes.typeOtpBox2AText}`}>This invoice is OTP-protected. Please enter the OTP you've received on your on your email : {email}</span>
                </div>
                <div className={`${classes.typeOtpBox2B}`}>
                    <span className={`${classes.typeOtpBox2BText}`}>Enter OTP</span>
                    <div className={`${classes.otpDigits}`}>
                        {otp?.map((digit, index) => (
                        <input
                            key={index}
                            type="text"
                            value={digit}
                            onChange={(e) => handleOtpInput(e, index)}
                            onKeyDown={(e) => handleOtpInput(e, index)}
                            ref={(el) => (otpInputs.current[index] = el)} // Store references to input elements
                            maxLength={1}// Limit input to a single digit
                            className={`${classes.otpDigit1}`}
                        />
                        ))}
                    </div>
                    {/* {otpErrorState?.error &&  <span className={`${classes.typeOtpBox2BTextActive}`}>{otpErrorState?.title}</span>} */}
                    {presignedUrlErrorState?.error &&  <span className={`${classes.typeOtpBox2BTextActive}`}>{presignedUrlErrorState?.title}</span>}
                </div>
                <div className={`${classes.typeOtpBox2C}`} onClick={handleOTPViewNow}>
                    <div className={`${classes.typeOtpBox2C1}`} style={active ? {background : "#181C30" }: {} }>
                        <div className={`${classes.typeOtpBox2c2}`}>
                            <span className={`${classes.typeOtpBox2c2Text}`} style={active ? {background : "#181C30" }: {} } >View Now</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`${classes.typeOtpBox3}`}>
                <span className={`${classes.typeOtpBox3Text}`} style={isTimerActive ? {color:'#FFF'} : {}}>Didn't receive OTP? <p className={`${classes.resend}`} style={isTimerActive ? {color:'#FFF'} : {}} onClick={handleResendAndOTPView} >Resend</p></span>
                { isTimerActive && 
                    (<div className={`${classes.counterContainer}`}>
                        <div className={`${classes.counterBox}`}>
                            <div className={`${classes.counterImg}`}>
                                <img src={rightTickImg} alt="" className={`${classes.rightTickImg}`}/>
                            </div>
                            <span className={`${classes.counterText}`}>{`OTP Sent (${timer} seconds)`}</span>
                        </div>
                    </div>)
                }
            </div>
        </div>
    </div>
  )
}

export default TypeOtp