import React, { useEffect, useState, memo, useMemo } from "react";
import { useDropzone } from "react-dropzone";
import { Document, Page, pdfjs } from "react-pdf";
import DragDropIcon from "assets/Icons/darg-drop-icon.svg";
import BgIcon from "assets/Icons/bg-file-uploader.png";
import UploadIcon from "assets/Icons/upload-cloud.svg";
import AddFileIcon from "assets/Icons/add-file.svg";
import DeleteIcon from "assets/Icons/delete-icon.svg";
import { useSnackbar } from 'notistack'

import styles from "./SmallFileBrowser.module.scss";
import clsx from "clsx";
import UseApiCall from "hooks/useApiCall";
import {
  apiCreateMediaDocumentUploadUrl,
  apiDeleteMediaDocument,
} from "services/services";
import { fetchFileFromS3 } from 'services'
import { handleDecrypt } from "hooks/handleDecryption";
import { doEncryption } from 'constants';

const fileFormats = {
  image: ['image/jpeg', 'image/jpg', 'image/png'],
  video: ['video/mp4', 'video/mov', 'video/quicktime'],
  text: ['text/csv'],
  document: ['application/pdf'],
}

const img = {
  display: 'block',
  width: 'auto',
  height: '100%',
  margin: '0 auto',
}

const generateRandomId = (randomNumberLength) => {
  let result = ''
  while (result.length <= randomNumberLength) {
    result = `${result}${Math.round(9 * Math.random())}`
  }
  return result
}

const getAllowedFileTypes = (allowedFormats) => {
  const result = []
  allowedFormats?.forEach((types) => {
    result.push(...fileFormats[types])
  })
  return result
}

const MAX_ALLOWED_FILES = 10

const SmallFileBrowser = ({
  uuid,
  validFormats,
  isResponsive = true,
  disabled = false,
  displayUrl = [],
  setFile,
  hasError: externalHasError,
  errorMessage,
  isMobile,
  userType,
  docType,
  docNum,
  userName,
  files,
  ...rest
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [selectedFiles, setSelectedFiles] = useState(files || {})
  const [selectedPreviewFileIdx, setSelectedPreviewFileIdx] = useState(null)
  const [deletedFiles, setDeletedFile] = useState({})
  const hiddenFileInput = React.useRef(null)
  const [currentFiles, setCurrentFiles] = useState([]);
  const [saveCurrentFiles, setSaveCurrentFiles] = useState();

  const [deleteMedia, loadingDelete, resultDelete, errorDelete] = UseApiCall(apiDeleteMediaDocument);
  const [hasError, setHasError] = useState(externalHasError)
  
  useEffect(() => {
    setHasError(externalHasError);
  }, [externalHasError]);

  const [getFilesFromS3, fetchingFiles] = UseApiCall(fetchFileFromS3, (res) => {
    // const blob = res.blob()
    // setSelectedFiles((prev) => ({ ...prev, ...res }))
    // console.log(blobFile(res))
  },
  (err)=> {},
  'direct'
  )

  const totalFiles = useMemo(() => {
    if (!selectedFiles) {
      return [];
    }
  
    return Object.keys(selectedFiles).filter((id) => !deletedFiles[id]);
  }, [selectedFiles, deletedFiles]);

  useEffect(() => {
    setFile(saveCurrentFiles)
  }, [saveCurrentFiles])

  useEffect(() => {
    if (selectedFiles && deletedFiles) {
      const filteredFiles = Object.keys(selectedFiles).filter((file) => {
        return !deletedFiles[file];
      });
      setCurrentFiles(filteredFiles);
    }
  }, [totalFiles]);

  useEffect(() => {
    if (selectedFiles) {
      const newObject = Object.keys(selectedFiles).reduce((result, key) => {
        if (currentFiles.includes(key)) {
          result[key] = selectedFiles[key];
        }
        return result;
      }, {});
  
      setSaveCurrentFiles(newObject);
    }
  }, [selectedFiles, currentFiles]);

  const onSelectFiles = (files) => {
    if (files?.length + totalFiles?.length > MAX_ALLOWED_FILES) {
      alert(`Only ${MAX_ALLOWED_FILES} files are allowed at max`)
      return
    }
    if (files?.length > 0) {
      const newFiles = {}
      ;[...files].forEach((f) => {
        const id = generateRandomId(6)
        newFiles[id] = Object.assign(f, {
          preview: URL.createObjectURL(f),
          uploadedAt: Date.now(),
          inQueue: true,
          isUploading: null,
          id,
        })
      })

      setSelectedFiles((prev) => ({ ...prev, ...newFiles }))
      uploadFiles(newFiles)
    }
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      'application/pdf': [],
      'image/png': [],
      'image/jpeg': [],
      'image/jpg': [],
    },
    onDrop: (acceptedFiles) => {
      onSelectFiles(acceptedFiles)
    },
    disabled: disabled,
  })
  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`
  }, [])

  useEffect(() => {
    return () =>
      Object.keys(selectedFiles).forEach((fileId) =>
        URL.revokeObjectURL(selectedFiles[fileId].preview)
      )
  }, [])

  const uploadFiles = async (newFiles) => {
    const sortedFiles = Object.values(newFiles).sort(
      (a, b) => a.uploadedAt - b.uploadedAt
    )
    const fileIds = sortedFiles.map((a) => a.id)
    if (!selectedPreviewFileIdx) {
      setSelectedPreviewFileIdx(fileIds[0])
    }
    for (let i = 0; i < fileIds.length; i++) {
      if (deletedFiles[fileIds[i]]) {
        continue
      }
      const _file = newFiles[fileIds[i]]
      try {
        _file.isUploading = true
        setSelectedFiles((prev) => ({ ...prev, [fileIds[i]]: _file }))

        // File upload process started
        const arrayBuffer = await _file.arrayBuffer()
        const blob = new Blob([arrayBuffer], {
          type: _file.type,
        })

        // const apiResponse = await apiCreateMediaDocumentUploadUrl({
        //   applicationId: uuid,
        //   filename: _file?.name,
        //   userType,
        //   docType,
        //   docNum,
        //   ...(userName && { userName }),
        // })
        let apiResponse;
        try {
          let fileName = encodeURIComponent(_file?.name)
          apiResponse = await apiCreateMediaDocumentUploadUrl({
            applicationId: uuid,
            filename: fileName,
            userType,
            docType,
            docNum,
            ...(userName && { userName }),
          });
        } catch (error) {
          enqueueSnackbar(`${JSON.stringify(error?.data?.error_response?.message)}`, { variant: 'error' })
        }
        // const urlData = await apiResponse.data
        let urlData;
        if (doEncryption) {
          urlData = await handleDecrypt(apiResponse.data);
        } else {
          urlData = await apiResponse.data;
        }
        await fetch(urlData.data.url, {
          method: 'PUT',
          body: blob,
          headers: {
            'Content-Type': _file.type,
          },
        })
        if (deletedFiles[_file.id]) {
        }
        // File uploaded
        _file.inQueue = false
        _file.isUploading = false
        _file.linkId = urlData.data.linkId

        setSelectedFiles((prev) => ({ ...prev, [fileIds[i]]: _file }))
      } catch (e) {
        handleRemoveFile(_file)
      }
    }
  }

  const onUploadClick = (event) => {
    if (disabled) return
    hiddenFileInput.current.click()
  }

  const handleFileUploadChange = (event) => {
    event.preventDefault();
    onSelectFiles(event.target.files)
  }

  const handleRemoveFile = async (file) => {
    const currentFiles = Object.values(selectedFiles)
      .filter((f) => !deletedFiles[f.id])
      .sort((f1, f2) => f1.uploadedAt - f2.uploadedAt)

    const delFileIdx = currentFiles.findIndex((f) => f.id === file.id)

    if (file.id === selectedPreviewFileIdx) {
      if (delFileIdx < currentFiles.length - 1) {
        const _id = currentFiles[delFileIdx + 1].id
        setTimeout(() => setSelectedPreviewFileIdx(_id), 0)
      } else {
        const _id =
          currentFiles.length > 1
            ? currentFiles[currentFiles.length - 2].id
            : null
        setTimeout(() => setSelectedPreviewFileIdx(_id), 0)
      }
    } else if (selectedPreviewFileIdx && deletedFiles[selectedPreviewFileIdx]) {
      const _id =
        currentFiles.length > 1
          ? currentFiles[currentFiles.length - 2].id
          : null
      setTimeout(() => setSelectedPreviewFileIdx(_id), 0)
    }
    if (file.linkId) {
      deleteMedia({ linkId: file.linkId })
    }
    setDeletedFile((prev) => ({ ...prev, [file.id]: file.id }))
  }

  return (
    <div
      // className={clsx(styles.documentupload, 'sm:pb-12')}
    >
      <div className="" style={{
        // border: hasError ? "2px solid #FF6868" : "inherit",
        // borderRadius: hasError ? "0.8rem" : "inherit",
        width: "100%"
      }}>
        <div className={clsx(styles.documentupload, '')}>
          <div
            {...getRootProps({
              className: clsx(
                styles.dropContainer,
                'dropzone',
                // isResponsive && '-sm:hidden'
              ),
            })}
            style={{
              // backgroundImage: `url(${BgIcon})`,
              // backgroundRepeat: 'repeat',
              // backgroundColor: isDragActive
              //   ? 'rgba(194, 243, 194, 0.3)'
              //   : 'transparent',
              // border: isDragActive
              //   ? '1px dashed rgb(63, 74, 63)'
              //   : '1px dashed #d2d6d9',
              // marginTop: totalFiles?.length === 0 ? "-6px" : "18px"
            }}
          >
            {!disabled && (
              <div
                className={clsx(styles.dropMessage, `flex ${isMobile ? "flex-row" : "flex-row gap-[8px]"} justify-center items-center`)}
              >
                <img src={DragDropIcon} alt="file" className="" />
                <p className="">
                  { isMobile ?
                      <div className={clsx(styles.fileText, ``)}>
                        {/* <b className="font-semibold">Browse files</b> */}
                      </div>
                      : (isDragActive ? (
                          <><b className="font-semibold">Drag</b> files{' '}</>
                          ) : (
                          <><p className={styles.fontText}>Upload Files</p></>
                          )
                        )
                  }
                </p>
                {/* {getAllowedFileTypes(validFormats)?.length > 0 && (
                  <p className={styles.fileFormat}>
                    {getAllowedFileTypes(validFormats)?.map((type, index) => {
                      return `${type.split('/')[1].toUpperCase()}${
                        !(index + 1 === getAllowedFileTypes(validFormats)?.length)
                          ? `, `
                          : ''
                      }`
                    })}{' '}
                    (max 10mb)
                  </p>
                )} */}
              </div>
            )}
            <input {...getInputProps()} />
            {/* {selectedFiles[selectedPreviewFileIdx] &&
              !deletedFiles[selectedPreviewFileIdx] && (
                <div
                  className={clsx(
                    styles.previewContainer,
                    isDragActive ? 'opacity-10' : ''
                  )}
                >
                  {fileFormats.document.includes(
                    selectedFiles[selectedPreviewFileIdx].type
                  ) && (
                    <Document
                      file={selectedFiles[selectedPreviewFileIdx].preview}
                      className={clsx(styles.documentFull)}
                    >
                      <Page pageNumber={1} />
                    </Document>
                  )}
                  {fileFormats.image.includes(
                    selectedFiles[selectedPreviewFileIdx].type
                  ) && (
                    <img
                      alt={selectedFiles[selectedPreviewFileIdx].name}
                      src={selectedFiles[selectedPreviewFileIdx].preview}
                      style={img}
                    />
                  )}
                </div>
              )} */}
          </div>
        </div>
        <div
          className={clsx(
            styles.thumbnailContainer,
            // isResponsive
            //   ? 'border-0 border-t-0 sm:border'
            //   : 'border border-t-0',
          )}
          style={{ display: totalFiles?.length === 0 && "none"}}
        >
          {totalFiles?.length > 0 &&
            Object.values(selectedFiles)
              .sort((f1, f2) => f1.uploadedAt - f2.uploadedAt)
              .map((file) =>
                deletedFiles[file.id] ? null : (
                  <div className={styles.fileUploader}>
                    <div
                      key={file.id}
                      className={clsx(
                        styles.fileThumbnail,
                        // 'aspect-square gap-2 rounded-xl border-solid border items-center py-2 px-2',
                        // selectedPreviewFileIdx === file.id
                        //   ? 'border-[#1E333F]'
                        //   : 'border-[#060A0D33]',
                        file.inQueue ? 'opacity-40' : ''
                      )}
                      onClick={() => setSelectedPreviewFileIdx(file.id)}
                    >
                      {/* {fileFormats.document.includes(file.type) && (
                        <Document
                          file={file.preview}
                          className={clsx(styles.documentThumbnail)}
                        >
                          <Page pageNumber={1} />
                        </Document>
                      )} */}
                      {/* {fileFormats.image.includes(file.type) && (
                        <img alt={file.name} src={file.preview} style={img} />
                      )} */}
                      <div className={styles.uploadLayout}>
                        <p className={styles.documentText}>{file.name}</p>
                        <p className={styles.uploadingText}>{file.isUploading === false ? "" : "uploading..."}</p>
                        <p></p>
                      </div>
                      <div
                        className={clsx(
                          styles.progressBar,
                          file.isUploading === false
                            ? styles.progressBar100
                            : file.isUploading
                            ? styles.progressBar20
                            : ''
                        )}
                      ></div>
                    </div>
                    {!file.inQueue && !disabled && (
                        <div
                          className={clsx(styles.closeIcon)}
                          onClick={() => handleRemoveFile(file)}
                        >
                          <img src={DeleteIcon} alt="DeleteIcon" className='' />
                        </div>
                    )}
                  </div>
                )
              )}
          {/* {!disabled && (
            <div
              onClick={onUploadClick}
              className="aspect-square flex item-center justify-center flex-col gap-2 cursor-pointer rounded-xl border-solid border border-[#060A0D33] items-center py-4 px-8"
            >
              <img
                src={totalFiles?.length > 0 ? AddFileIcon : UploadIcon}
                alt="+"
                className="w-8 h-8"
              />
              <p className="text-lg">
                {totalFiles?.length > 0 ? 'More' : 'Upload'}
              </p>
            </div>
          )} */}
        </div>
        <input
          type="file"
          ref={hiddenFileInput}
          onChange={handleFileUploadChange}
          multiple
          accept={getAllowedFileTypes(validFormats).join(', ')}
          style={{ display: 'none' }}
        />
      </div>
      {/* code to check if error add message */}
      {hasError && <div className={styles.error} style={{ width: "100%"}}>{errorMessage}</div>}
    </div>
  )
}

export default memo(SmallFileBrowser);
