import React ,{useState , useEffect} from 'react'
import UserTable from './profileComponents/UserTable';
import UserTableMobile from './profileComponents/UserTableMobile';
import classes from './Integrations.module.scss'
import Header from './profileComponents/Header';
import Button from 'Layout/Button';

const Integrations = (props) => {
    const [screenWidth, setScreenWidth] = useState(getCurrentDimension());
    function getCurrentDimension() {
        return  window.innerWidth
    }
    
    useEffect(() => {
      const updateDimension = () => {
          setScreenWidth(getCurrentDimension());
       };
       window.addEventListener("resize", updateDimension);
        return () => {
          window.removeEventListener("resize", updateDimension);
        };
    }, [screenWidth]);

    // console.log(props?.userList);
return(
    <div className={classes.integrationsContainer}>
        <Header text1={'ERP/Accounting Softwares'} text2={'Connect your ERP/Accounting Softwares in a single click and your data will be synced automatically'} />
        {/* <div className={classes.header}></div> */}
        <div className={'px-[40px] pt-[20px] w-[100%] flex flex-col gap-[16px]'}>
            {
                ["Tally", "SAP", "Microsoft Envision"]?.map((item, index) => (
                    <div key={index} className={`${classes.kycHeader}`}>
                        <div className={`${classes.kycTextTwo}`}>{item}</div>
                        <Button
                            className="w-[120px] opacity-20 min-w-[120px]"
                            text="Connect"
                            disabled={true}
                            // onClick={handleClick}
                        />
                    </div>
                ))
            }
        </div>
    </div>
)
}

export default Integrations