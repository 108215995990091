import Shimmer from 'Layout/Shimmer';
import classes from './MobilePayoutReportTable.module.scss'
import ReportTableLayout from 'Components/ReportTable/ReportTableLayout'
import clsx from 'clsx'
import dayjs from 'dayjs'
import infoIcon from 'assets/Icons/infoIcon.svg'
import { DownloadOutlined } from '@ant-design/icons'
import { modalActions } from 'Store/modal-redux';
import { useDispatch, useSelector} from 'react-redux';
import ReactDOM from 'react-dom'
import PayoutAmountMobile from 'Components/atom/PayoutsAmountTooltipMobile/PayoutAmountMobile';
import { useState } from 'react';

const MobilePayoutReportTable = (props) => {
const dispatch = useDispatch();
const payoutAmountInfo = useSelector((state)=> state.modal.payoutAmountInfo)
const [data, setData] = useState([]);
const [tableHeading, setTableHeading] = useState("");
const [heading, setHeading] = useState("")
  if (props.tableData?.length > 0)
    return (
        <ReportTableLayout className={`${classes.table} ${props.className}`}>
           {payoutAmountInfo &&
                ReactDOM.createPortal(
                <PayoutAmountMobile
                 data = {data}
                 tableHeading = {tableHeading}
                 heading = {heading}
                />,
                document.getElementById('modal-root')
                )
            }
            {props?.tableData?.map((data, index) => {
                return (
                    <div key={`${data?.email}-${index}`} className={classes.row}>
                        <div
                            className={classes.infoTwo}
                        >
                            <div className={clsx(classes.amountText)} style={{ maxWidth: "100%", overflowWrap: "anywhere", color: "#1e333fc7", fontWeight: "400" }}>
                                {data?.payoutReference ? data?.payoutReference : '--'}
                            </div>
                            <div>
                                {
                                    data?.firaStatus === 'GENERATED'
                                        ?
                                            <DownloadOutlined
                                                style={{
                                                    fontSize: 16,
                                                    background: 'lightgrey',
                                                    borderRadius: '50%',
                                                    padding: 6,
                                                }}
                                                onClick={() => props?.handleReportDownload(data?.firaId)}
                                            />
                                        :
                                            ''
                                }
                            </div>
                        </div>
                        <div className={classes.infoOne}>
                            <div
                                className={clsx(classes.invoice, 'flex items-center gap-[8px]')}
                            >
                            <div
                                className={clsx(classes.amountText, '!text-[#1E333F50]')}
                            >
                                Initiated On
                            </div>
                            </div>
                            <div className={clsx(classes.amountText,'flex gap-[5px]')} style={{ maxWidth: "125px", overflowWrap: "anywhere" }}>
                                {data?.initiatedOn ? dayjs(data?.initiatedOn).local().format('D MMM YYYY') : '--'}
                            </div>
                        </div>
                        <div className={classes.infoOne}>
                            <div
                                className={clsx(classes.invoice, 'flex items-center gap-[8px]')}
                            >
                            <div
                                className={clsx(classes.amountText, '!text-[#1E333F50]')}
                            >
                                Expected On
                            </div>
                            </div>
                            <div className={clsx(classes.amountText,'flex gap-[5px]')} style={{ maxWidth: "125px", overflowWrap: "anywhere" }}>
                                {data?.expectedOn ? dayjs(data?.expectedOn).local().format('D MMM YYYY') : '--'}
                            </div>
                        </div>
                        <div
                            className={classes.infoTwo}
                        >
                            <div className={clsx(classes.amountText, '!text-[#1E333F50]')}>Gross Payout</div>
                            <div className={clsx(classes.amountText)} style={{ maxWidth: "125px", overflowWrap: "anywhere" }}
                               onClick={() => {
                                setData(data)
                                setTableHeading("Gross Payout")
                                setHeading("Gross Amount Breakup")
                                dispatch(modalActions.payoutAmountInfo(true))
                               }}                            
                            >
                                {data?.grossPayout ? data?.invoiceCurrency?.sign : '--'}
                                {
                                    data?.invoiceCurrency?.sign === '₹' 
                                        ? data?.grossPayout?.toLocaleString('en-IN', {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                        })
                                        : data?.grossPayout?.toLocaleString('en-US', {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                        })
                                }
                                <button>
                                    <img src={infoIcon} alt='info'/>
                                </button>
                            </div>
                        </div>
                        <div
                            className={classes.infoTwo}
                            // onClick={() => {
                            //   data?.paymentLink && window.open(data?.paymentLink, "_self");
                            // }}
                        >
                            <div className={clsx(classes.amountText, '!text-[#1E333F50]')}>Settled Amount</div>
                            <div className={clsx(classes.amountText)} style={{ maxWidth: "125px", overflowWrap: "anywhere" }}
                               onClick={() => {
                                setData(data)
                                setTableHeading("Settled Amount")
                                setHeading("Settled Amount Breakup")
                                dispatch(modalActions.payoutAmountInfo(true))
                               }}            
                            >
                                {data?.settledAmount ? data?.settledAmountCurrency?.sign : '--'}
                                {
                                    data?.settledAmountCurrency?.sign === '₹' 
                                    ? data?.settledAmount?.toLocaleString('en-IN', {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                        })
                                    : data?.settledAmount?.toLocaleString('en-US', {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2,
                                        })
                                }
                                <button>
                                    <img src={infoIcon} alt='info'/>
                                </button>
                            </div>
                        </div>
                        {/* <div
                            className={classes.infoTwo}
                            style={{ justifyContent: "flex-end", marginBottom: "0px" }}
                            // onClick={() => {
                            //   data?.paymentLink && window.open(data?.paymentLink, "_self");
                            // }}
                        >
                            {
                              data?.firaStatus === 'PENDING'
                                ?
                                  <Tag color="volcano" style={{ margin: "0px" }}>PENDING</Tag>
                                :
                                  (
                                    data?.firaStatus === 'NOT_GENERATED'
                                      ?
                                        <Tag color="#f84f4f" style={{ margin: "0px" }}>NOT GENERATED</Tag>
                                      :
                                        (
                                          data?.firaStatus === 'GENERATED'
                                            ?
                                              <DownloadOutlined
                                                style={{
                                                    fontSize: 16,
                                                    background: 'lightgrey',
                                                    borderRadius: '50%',
                                                    padding: 6,
                                                }}
                                                onClick={() => props?.handleReportDownload(data?.firaId)}
                                              />
                                            :
                                                '--'
                                        )
                                  )
                            }
                        </div> */}
                    </div>
                )
            })}
      </ReportTableLayout>
    )
  if (props.tableData?.length === 0)
    return (
        <div
            style={{
                textAlign: 'center',
                width: '100%',
                marginTop: '4rem',
                fontSize: '2.5rem'
            }}
            className={classes.table}
        >
            No Payouts Yet.
        </div>
    )
  return (
    <div
      style={{
        textAlign: 'center',
        width: '100%',
        marginTop: '4rem',
      }}
    >
        <>
            <Shimmer className="w-[100%] mb-[10px] h-[100px] rounded-[12px]"/>
            <Shimmer className="w-[100%] mb-[10px] h-[100px] rounded-[12px]"/>
            <Shimmer className="w-[100%] h-[100px] rounded-[12px]"/>
        </>
    </div>
  )
}

export default MobilePayoutReportTable