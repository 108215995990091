import { useEffect, useState } from 'react';
import classes from 'Layout/MobileInput.module.scss';
import chevron from 'assets/Images/chevron-down.svg';
import useClickOutside from 'hooks/useClickOutside';
import CountryDropdown from 'Components/Dropdown/CountryDropdown';
import clsx from 'clsx'

const MobileInput = (props) => {
  const {initiateFocus , focusRef , noneRef} = props
  const [isTouched, setIsTouched] = useState(false)
  const [showCountry, setShowCountry] = useState(false)
  const [firstTyping, setFirstTyping] = useState(false)

  // const mobileValidation = [
  //   (value) => value === '' || /^[1-9][0-9]{6,14}$/.test(value),
  // ]

  const mobileValidation = [
    (value) => value === '' || /^\+?[1-9][0-9]{6,14}$/.test(value),
  ]
  useEffect(() => {
    if (props.isRequired) mobileValidation.push((value) => value?.trim() !== '')
  }, [props?.isRequired])

  const dropdownRef = useClickOutside(() => setShowCountry(false))
  // let isInputValid = props.isMobileValid;
  let isInputValid = true;

  for (let i = 0; i < mobileValidation.length; i++) {
    isInputValid = isInputValid && mobileValidation[i](props.mobileNumberInput)
  }

  useEffect(() => {
    if (props.setMobileValidity) props.setMobileValidity(isInputValid)
  }, [isInputValid])

  useEffect(() => {
    if (props.inputValidity) props.inputValidity(isInputValid);
  }, [isInputValid]);

  const changeHandler = (event) => {
    props.setMobileNumberInput(event.target.value);
    isInputValid = props.isMobileValid;

    if (!firstTyping) {
      setIsTouched(false)
      setFirstTyping(true)
    }
  }

  const blurHandler = () => {
    setIsTouched(true)
  }

  const selectCountry = (code) => {
    setShowCountry(false)
    props.setMobileCodeInput(code)
  }

  const [hasError, setHasError] = useState(props.hasError)

  useEffect(() => {
    setHasError(hasError || (props.mobileNumberInput !== '' && props.mobileNumberInput !== null && !/\d{1,14}/.test(props.mobileNumberInput)))
    if (firstTyping && isTouched && !isInputValid) {
      setHasError(true);
    }
  }, [props])



  return (
    <div className={clsx(classes.box, props.className)}>
      <div
        className={clsx(
          classes.mobile_number,
          'border-[1px] border-solid',
          hasError ? 'border-[#FF6868]' : 'border-[#D2D6D9]'
        )}
        style={{ height: props?.customStyle?.height && "48px" }}
      >
        <div
          ref={dropdownRef}
          className={`${classes.country_selector} ${
            showCountry && classes.country_selector_active
          }`}
        >
          <div
            className={classes.default}
            onClick={() => {
              setShowCountry((prev) => !prev)
            }}
          >
            <div className={classes.code}>{props.mobileCodeInput}</div>
            <img
              src={chevron}
              alt="chevron-icon"
              className={`${classes.icon} ${
                showCountry && classes.icon_active
              }`}
            />
          </div>
          {showCountry && (
            <CountryDropdown
              className={classes.dropdown}
              dropdownOptionClass={classes.dropdown_option}
              onClick={(value) => selectCountry(value.dial_code)}
              mobileCode={props.mobileCodeInput}
            />
          )}
        </div>
        <input
          type="number"
          className={classes.input}
          placeholder={`Mobile Number${props?.isRequired ? '*' : ''}`}
          onChange={changeHandler}
          value={props.mobileNumberInput}
          onBlur={blurHandler}
          onFocus={() => setShowCountry(false)}
          maxLength={20}
          name="mobile"
          ref={initiateFocus?focusRef:noneRef}
        />
      </div>
      {hasError && (
        <div className={classes.error}>Please enter a valid phone number</div>
      )}
    </div>
  )
}
export default MobileInput;
                           
