import React from 'react';
import classes from "./profileComponents.module.scss";

const Content = ({head,para}) => {
  return (
    <div className={`${classes.content}`}>
        <span className={`${classes.textTwo}`}>{head}</span>
        <span className={`${classes.textOne}`}>{para || '--'}</span>
    </div>
  )
}

export default Content