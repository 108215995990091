// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AddContact_backdrop__\\+ZukB {
  z-index: var(--level_9);
  height: calc(100vh - var(--admin_header_length));
}

.AddContact_modal__XtOpB {
  z-index: var(--level_10);
  height: calc(100vh - var(--admin_header_length));
}

.AddContact_add_contact__GZ6ze {
  width: 100%;
  height: calc(100vh - var(--admin_header_length));
}

.AddContact_documentupload__5bmtH {
  display: flex;
  padding: 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 12px;
  border: 2px dashed rgba(30, 51, 63, 0.24);
  height: 126px;
}
@media (max-width: 640px) {
  .AddContact_documentupload__5bmtH {
    width: 100%;
    padding: 20px 16px;
    height: 100px;
  }
}

.AddContact_dropMessage__VWObw {
  width: 100%;
  display: flex;
  align-items: center;
}

.AddContact_fontText__CNy25 {
  color: #1E333F;
  text-align: center;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.AddContact_fontSubText__Yp-\\+u {
  color: rgba(30, 51, 63, 0.5);
  text-align: center;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  display: flex;
  justify-content: center;
  margin-top: 8px;
}`, "",{"version":3,"sources":["webpack://./src/Components/General/AddContact.module.scss"],"names":[],"mappings":"AAAA;EACI,uBAAA;EACA,gDAAA;AACJ;;AAEA;EACI,wBAAA;EACA,gDAAA;AACJ;;AAEA;EACI,WAAA;EACA,gDAAA;AACJ;;AAEA;EACI,aAAA;EAEA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,QAAA;EACA,mBAAA;EACA,yCAAA;EACA,aAAA;AAAJ;AAEI;EAXJ;IAYM,WAAA;IACA,kBAAA;IACA,aAAA;EACJ;AACF;;AAEE;EACE,WAAA;EACA,aAAA;EACA,mBAAA;AACJ;;AAGE;EACE,cAAA;EACA,kBAAA;EACA,kBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;AAAJ;;AAGE;EACE,4BAAA;EACA,kBAAA;EACA,kBAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,aAAA;EACA,uBAAA;EACA,eAAA;AAAJ","sourcesContent":[".backdrop{\n    z-index: var(--level_9);\n    height: calc(100vh - var(--admin_header_length));\n}\n\n.modal{\n    z-index: var(--level_10);\n    height: calc(100vh - var(--admin_header_length));\n}\n\n.add_contact{\n    width: 100%;\n    height: calc(100vh - var(--admin_header_length));\n}\n\n.documentupload {\n    display: flex;\n    // width: 560px;\n    padding: 16px;\n    justify-content: center;\n    align-items: center;\n    gap: 8px;\n    border-radius: 12px;\n    border: 2px dashed rgba(30, 51, 63, 0.24);\n    height: 126px;\n    // background-color: #FFF;\n    @media (max-width: 640px) {\n      width: 100%;\n      padding: 20px 16px;\n      height: 100px;\n    }\n  }\n\n  .dropMessage {\n    width: 100%;\n    display: flex;\n    align-items: center;\n    // flex-direction: column;\n  }\n\n  .fontText {\n    color: #1E333F;\n    text-align: center;\n    font-family: Inter;\n    font-size: 14px;\n    font-style: normal;\n    font-weight: 400;\n    line-height: 24px;\n  }\n  \n  .fontSubText {\n    color: rgba(30, 51, 63, 0.50);\n    text-align: center;\n    font-family: Inter;\n    font-size: 12px;\n    font-style: normal;\n    font-weight: 500;\n    line-height: 16px;\n    display: flex;\n    justify-content: center;\n    margin-top: 8px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"backdrop": `AddContact_backdrop__+ZukB`,
	"modal": `AddContact_modal__XtOpB`,
	"add_contact": `AddContact_add_contact__GZ6ze`,
	"documentupload": `AddContact_documentupload__5bmtH`,
	"dropMessage": `AddContact_dropMessage__VWObw`,
	"fontText": `AddContact_fontText__CNy25`,
	"fontSubText": `AddContact_fontSubText__Yp-+u`
};
export default ___CSS_LOADER_EXPORT___;
