import clsx from "clsx";
import InfoIcon from "assets/Icons/info_brown_circle.svg";
import { useState } from "react";
import Tooltip from "Layout/Tooltip";

const getCustomerVerificationDetails = (status) => {
  if (status === "PENDING_VERIFICATION")
    return {
      icon: InfoIcon,
      title: "UNDER REVIEW",
      bgColor: "#FFF4DE",
      textColor: "#8E6C28",
    };
  else if (status === "VERIFICATION_FAILED")
    return {
      title: "VERIFICATION FAILED",
      bgColor: "#D56F6F",
      textColor: "#FFFFFF",
    };
  return;
};

const CustomerVerificationTag = (props) => {
  const { status, message = "" } = props;
  const [typeDetails, setTypeDetails] = useState(
    getCustomerVerificationDetails(status)
  );

  const renderTag = () => {
    return (
      <div
        style={{
          backgroundColor: typeDetails?.bgColor
            ? typeDetails?.bgColor
            : "inherit",
        }}
        className={clsx(
          "px-[8px] h-[20px] rounded-full flex flex-row items-center"
        )}
      >
        {typeDetails?.icon && (
          <img src={typeDetails?.icon} alt="." className="w-[20px] aspect-square" />
        )}

        <p
          className={clsx("text-base")}
          style={{
            color: typeDetails?.textColor ? typeDetails?.textColor : "#fff",
          }}
        >
          {typeDetails?.title}
        </p>
      </div>
    );
  };

  if (!typeDetails) return null;
//   if (message?.length > 0)
//     return <Tooltip content={renderTag()} text={'ahsasja'} />;
  return renderTag();
};

export default CustomerVerificationTag;
