import React, { useEffect, useState } from "react";
import classes from "./ChatModal.module.scss";
import SendIcon from "assets/Icons/sendIcon.svg";
import MinusIcon from "assets/Icons/minusIconBlack.svg";
import clsx from "clsx";
import {
    apiAddCustomer,
    apiCreateInvoiceChatbot,
    apiGetBulkTransactionsId,
    apiSendReconcilationData,
    apiDownloadFiraReportWithInvoiceId,
    apiGetBankList,
    apiFiraFromLastTransactionChatbot,
    apiFiraFromCustomerNameChatbot,
    apiGeneralFiraDownloadChatbot,
    apiMessageChatbot
} from "services";
import {
    apiCreateMediaUploadUrl,
} from "services/invoiceServices";
import UseApiCall from "hooks/useApiCall";
import { useSelector } from "react-redux";
import UploadInvoicesIcon from "assets/Icons/upload-invoices.svg";
import { doEncryption } from "constants";
import { convertBase64 } from "hooks/convertBase64";
import { handleDecrypt } from "hooks/handleDecryption";
import { baseUrl } from "constants/Network";
import { Document, Page, pdfjs } from "react-pdf";
import { downloadWithInterval } from "hooks/downloadFiles";
import Close from "Layout/Close";

const SEARCH_TEXT = "return an object which consists of these keys and their respective values [invoiceId, issueDate, dueDate, blDate, remarks, netTerms, invoiceAmount, receivingAmount, invoiceCurrency, description, customerName]\n customerName is the name of the customer or client or bill to it must be in string if not found retun null. calculate issueDate, dueDate and  blDate must be in this format DD-MM-YYYY or if not found then null. description, remarks and netTerms are text fields and invoiceCurrency is code only eg USD, EUR, GBP, INR\nJust return the object other than that nothing else\nremove json too and don't return any comments in object\n"

const ChatModal = ({ onClose, threadId, messages, setMessages, onKill }) => {

    const [inputValue, setInputValue] = useState("");
    const [showTypeAMessage, setShowTypeAMessage] = useState(true);
    const [focus, setFocus] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loadingSuccess, setLoadingSuccess] = useState(false);
    const [open, setOpen] = useState(false);
    const [transactionId, setTransactionId] = useState(null)
    const [invoiceFile, setInvoiceFile] = useState(null)
    const [organizationId, setOrganizationId] = useState(process.env.REACT_APP_NODE_ENV === "production" ? 4 : 2);
    const userId = useSelector(state => state?.login?.userId);
    const [ocrResponse, setOcrResponse] = useState([]);
    const [primaryBank, setPrimaryBank] = useState(null)
    const [numPages, setNumPages] = useState(null);
    const dateToTimestamp = (dateString) => {
        if (!dateString) return null;
        return Math.floor(new Date(dateString.split("-").reverse().join("-")).getTime() / 1000);
    };
    useEffect(() => {
        pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`
    }, [])

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    };

    const handleSuccessNew = (data1, action) => {
        // console.log(data, action, "Paaboooo");
        let data = data1.map(item => item.toString());
        if (action === "FETCH_FIRA_LATEST") {
            // downloadFile(data);
            downloadWithInterval(data, 1000);
            let newResponse = { attachments: [], type: "api", status: "", action: "", message: "Congratulations! Your latest payment advice has been downloaded successfully.", doc_format: "", file_url: "", missingFields: [], data: null }
            setMessages(prevMessages => [...prevMessages, newResponse]);
        } else if (action === "FETCH_FIRA_GENERAL") {
            downloadWithInterval(data, 1000);
            let newResponse = { attachments: [], type: "api", status: "", action: "", message: "Congratulations! Your fira report has been downloaded successfully.", doc_format: "", file_url: "", missingFields: [], data: null }
            setMessages(prevMessages => [...prevMessages, newResponse]);
        } else if (action === "FETCH_FIRA_CUSTOMER") {
            // downloadFile(data);
            downloadWithInterval(data, 1000);
            let newResponse = { attachments: [], type: "api", status: "", action: "", message: "The latest payment advice for against payment received from this customer has been downloaded successfully, Please check your download.", doc_format: "", file_url: "", missingFields: [], data: null }
            setMessages(prevMessages => [...prevMessages, newResponse]);
        }
    }

    const [callMessageChatbot] = UseApiCall(
        apiMessageChatbot,
        (res) => {
            setLoading(false);

            console.log(res, "Paaabooooooooooo");

            const messageType = "bot";

            let obj = { ...res };
            obj.type = messageType;

            try {
                obj.missingFields = Array.isArray(res?.missingFields) ? (res?.missingFields?.length > 0 ? JSON.parse(res?.missingFields[0]) : []) : JSON.parse(res?.missingFields);
            } catch (error) {
                obj.missingFields = [];
                console.error("Error parsing missingFields:", error);
            }

            setMessages(prevMessages => [...prevMessages, obj]);

            if (obj?.status === "SUCCESS") {
                if (obj.events === "FETCH_FIRA_GENERAL" || obj.events === "FETCH_FIRA_CUSTOMER" || obj.events === "FETCH_FIRA_LATEST") {
                    handleSuccessNew(obj.attachments, obj.events);
                }
            }
            console.log(obj, "Paaboooooooo")

        }, (err) => {
            setLoading(false);
            const messageType = "bot";
            const newBotMessage = { type: messageType, message: err?.data?.message };
            if (err?.data?.message) setMessages(prevMessages => [...prevMessages, newBotMessage]);
            const newMessage = { type: messageType, message: err?.data?.errorResponse?.message };
            setMessages(prevMessages => [...prevMessages, newMessage]);
            console.error("Error in sending and retrieving message data : ", err);
            console.error("Error in sending and retrieving message data : ", err?.data?.errorResponse?.message);
            console.error("Error in sending and retrieving message data : ", err?.data?.error_response?.message);
        }
    )

    const [addCustomer, adding] = UseApiCall(apiAddCustomer, (res) => {
        let newResponse = { attachments: [], type: "api", status: "", action: "", message: "Congratulations! Your customer has been successfully created. You can now head over to the dashboard to view and manage your new customer.", doc_format: "", file_url: "", missingFields: [], data: null }
        setMessages(prevMessages => [...prevMessages, newResponse]);
    }, (err) => {
        let newResponse = { attachments: [], type: "api", status: "", action: "", message: err?.data?.error_response?.message ? err?.data?.error_response?.message : "Sorry, we encountered an issue while creating the customer. Please try again.", doc_format: "", file_url: "", missingFields: [], data: null }
        setMessages(prevMessages => [...prevMessages, newResponse]);
        console.error("Error while creating customer : ", err);
    })

    const [createInvoiceCall, creatingInvoice] = UseApiCall(
        apiCreateInvoiceChatbot,
        (res) => {
            setOcrResponse([]);
            setHitDraftApi(false);
            setCurrentTxnId('');
        }, (err) => {
            let errorMessage = err?.data?.error_response?.message || "Sorry, we encountered an issue while creating the invoice. Please try again.";

            let formattedErrorMessage = errorMessage.startsWith('[') && errorMessage.endsWith(']')
                ? errorMessage.slice(1, -1).replace(/,\s*/g, '\n')
                : errorMessage;

            let newResponse = { attachments: [], type: "api", status: "", action: "", message: formattedErrorMessage, doc_format: "", file_url: "", missingFields: [], data: null }
            setMessages(prevMessages => [...prevMessages, newResponse]);
            console.error("Error while creating invoice : ", err);
        }
    )

    const [postSendReconcilationData, fetchingSendReconcilationData] = UseApiCall(apiSendReconcilationData, (res) => {
        let newResponse = { attachments: [], type: "api", status: "", action: "", message: "Congratulations! Your invoice has been successfully reconciled. You can now review and manage it on the dashboard.", doc_format: "", file_url: "", missingFields: [], data: null }
        setMessages(prevMessages => [...prevMessages, newResponse]);
    }, (err) => {
        let newResponse = { attachments: [], type: "api", status: "", action: "", message: "Sorry, we encountered an issue while reconciling the invoice. Please try again.", doc_format: "", file_url: "", missingFields: [], data: null }
        setMessages(prevMessages => [...prevMessages, newResponse]);
        console.error("Error while reconciling invoice : ", err);
    });

    const [createMediaUploadUrl, createMediaUploadUrlLoader] = UseApiCall(
        apiCreateMediaUploadUrl,
        (res) => {
        }, (err) => {
            console.error("Error while creating url : ", err);
        }
    )

    const [getBankAccounts] = UseApiCall(apiGetBankList, (res) => {
        setPrimaryBank(res?.data[0]);
        res?.data?.forEach((item) => {
            if (item.isPrimary) {
                setPrimaryBank(item)
            }
        })
    })

    const [invoiceId, setInvoiceId] = useState('')
    const [purposeCode, setPurposeCode] = useState('')
    const [issueDate, setIssueDate] = useState()
    const [dueDate, setDueDate] = useState()
    const [blDate, setBLDate] = useState()
    const [invoiceAmount, setInvoiceAmount] = useState('')
    const [receivableAmount, setReceivableAmount] = useState('')
    const [remarks, setRemarks] = useState('')
    const [paymentTerms, setPaymentTerms] = useState('')
    const [changedIssueDate, setChangedIssueDate] = useState(false)
    const [invoiceCurrency, setInvoiceCurrency] = useState('USD')
    const [hitDraftApi, setHitDraftApi] = useState(false)
    const [params, setParams] = useState({})
    const [currentTxnId, setCurrentTxnId] = useState('')

    function extractNumericValue(str) {
        if (typeof str === 'number') {
            str = str.toString();
        } else if (!str || typeof str !== 'string') {
            return '0.00';
        }

        const match = str.replace(/[^0-9.$]/g, '').match(/(\d+(\.\d+)?)$/);

        if (match) {
            const numericValue = parseFloat(match[1]);
            return numericValue;
        }

        return '0.00';
    }

    useEffect(() => {
        console.log(ocrResponse, "ocr_response")
        setInvoiceId('')
        setPurposeCode('')
        setIssueDate(null)
        setDueDate(null)
        setBLDate(null)
        setInvoiceAmount('')
        setReceivableAmount('')
        setRemarks('')
        setPaymentTerms('')
        if (ocrResponse?.length > 0) {
            if (ocrResponse[0]?.invoiceId) {
                setInvoiceId(ocrResponse[0]?.invoiceId?.trim());
            }
            if (ocrResponse[0]?.invoiceAmount) {
                const stringWithCurrency = ocrResponse[0]?.invoiceAmount;
                const numericValue = extractNumericValue(stringWithCurrency);
                setInvoiceAmount(numericValue);
                setReceivableAmount(numericValue);
            }
            if (ocrResponse[0]?.receivingAmount) {
                const stringWithCurrency = ocrResponse[0]?.receivingAmount;
                const numericValue = extractNumericValue(stringWithCurrency);
                setReceivableAmount(numericValue);
            }
            if (ocrResponse[0]?.netTerms) {
                setPaymentTerms(ocrResponse[0]?.netTerms);
            }
            if (ocrResponse[0]?.remarks) {
                setRemarks(ocrResponse[0]?.remarks);
            }

            if (ocrResponse && ocrResponse?.length > 0 && ocrResponse[0]?.issueDate) {
                setIssueDate(ocrResponse[0]?.issueDate);
                setChangedIssueDate(true);
            }

            if (ocrResponse[0]?.dueDate) {
                setDueDate(ocrResponse[0]?.dueDate);
            }

            if (ocrResponse[0]?.blDate) {
                setBLDate(ocrResponse[0]?.blDate);
            }

            if (ocrResponse[0]?.invoiceCurrency) {
                setInvoiceCurrency(ocrResponse[0]?.invoiceCurrency);
            }
            setHitDraftApi(true);
            setCurrentTxnId(ocrResponse[0]?.txnId)
        }
    }, [ocrResponse])

    const downloadFile = (url) => {
        const anchor = document.createElement('a');
        anchor.style.display = 'none';
        document.body.appendChild(anchor);

        anchor.href = url;

        anchor.click();

        document.body.removeChild(anchor);
    };

    useEffect(() => {
        return () => {
            const anchor = document.querySelector('a[download]');
            if (anchor) {
                document.body.removeChild(anchor);
            }
        };
    }, []);

    const [downloadFiraReportWithInvoiceId] = UseApiCall(
        apiDownloadFiraReportWithInvoiceId,
        (res) => {
            let fileUrls = res?.data
            downloadWithInterval(fileUrls, 1000);
            let newResponse = { attachments: [], type: "api", status: "", action: "", message: "Congratulations! Your fira report has been downloaded successfully.", doc_format: "", file_url: "", missingFields: [], data: null }
            setMessages(prevMessages => [...prevMessages, newResponse]);
        }, (err) => {
            let newResponse = { attachments: [], type: "api", status: "", action: "", message: err?.data?.error_response?.message ? err?.data?.error_response?.message : "Sorry, we encountered an issue while downloading the fira report. Please try again.", doc_format: "", file_url: "", missingFields: [], data: null }
            setMessages(prevMessages => [...prevMessages, newResponse]);
            console.error("Error in downloading Fira Report : ", err);
        }
    )

    const [downloadLatestPaymentAdvice] = UseApiCall(
        apiFiraFromLastTransactionChatbot,
        (res) => {
            let url = res?.data;
            downloadFile(url);
            let newResponse = { attachments: [], type: "api", status: "", action: "", message: "Congratulations! Your latest payment advice has been downloaded successfully.", doc_format: "", file_url: "", missingFields: [], data: null }
            setMessages(prevMessages => [...prevMessages, newResponse]);
        }, (err) => {
            let newResponse = { attachments: [], type: "api", status: "", action: "", message: err?.data?.error_response?.message ? err?.data?.error_response?.message : "Sorry, we encountered an issue while downloading the latest payment advice. Please try again.", doc_format: "", file_url: "", missingFields: [], data: null }
            setMessages(prevMessages => [...prevMessages, newResponse]);
            console.error("Error in downloading Fira Report : ", err);
        }
    )

    const [downloadCustomerNamePaymentAdvice] = UseApiCall(
        apiFiraFromCustomerNameChatbot,
        (res) => {
            let url = res?.data;
            downloadFile(url);
            let newResponse = { attachments: [], type: "api", status: "", action: "", message: "The latest payment advice for against payment received from this customer has been downloaded successfully, Please check your download.", doc_format: "", file_url: "", missingFields: [], data: null }
            setMessages(prevMessages => [...prevMessages, newResponse]);
        }, (err) => {
            let newResponse = { attachments: [], type: "api", status: "", action: "", message: err?.data?.error_response?.message ? err?.data?.error_response?.message : "Sorry, we encountered an issue while downloading the payment advice. Please try again.", doc_format: "", file_url: "", missingFields: [], data: null }
            setMessages(prevMessages => [...prevMessages, newResponse]);
            console.error("Error in downloading Fira Report : ", err);
        }
    )

    const [downloadGeneralMehtodPaymentAdvice] = UseApiCall(
        apiGeneralFiraDownloadChatbot,
        (res) => {
            let fileUrls = res?.data?.firaUrls
            if (res?.data?.message === "Success" || Array.isArray(fileUrls)) {
                downloadWithInterval(fileUrls, 1000);
                let newResponse = { attachments: [], type: "api", status: "", action: "", message: "Congratulations! Your payment advice has been downloaded successfully.", doc_format: "", file_url: "", missingFields: [], data: null }
                setMessages(prevMessages => [...prevMessages, newResponse]);
            } else {
                let newResponse = { attachments: [], type: "api", status: "", action: "", message: res?.data?.message, doc_format: "", file_url: "", missingFields: [], data: null }
                setMessages(prevMessages => [...prevMessages, newResponse]);
            }
        }, (err) => {
            let newResponse = { attachments: [], type: "api", status: "", action: "", message: err?.data?.error_response?.message ? err?.data?.error_response?.message : "Sorry, we encountered an issue while downloading the payment advice. Please try again.", doc_format: "", file_url: "", missingFields: [], data: null }
            setMessages(prevMessages => [...prevMessages, newResponse]);
            console.error("Error in downloading Fira Report : ", err);
        }
    )

    // const [downloadFiraReport] = UseApiCall(
    //     apiDownloadFiraReportUrl,
    //     (res) => {
    //         let url = res?.data
    //         window.location.assign(url);
    //         let newResponse = { attachments: [], type: "api", status: "", action: "", message: "Congratulations! Your fira report has been downloaded successfully.", missingFields: [], data: null }
    //         setMessages(prevMessages => [...prevMessages, newResponse]);
    //     }, (err) => {
    //         let newResponse = { attachments: [], type: "api", status: "", action: "", message: "Sorry, we encountered an issue while downloading the fira report. Please try again.", missingFields: [], data: null }
    //         setMessages(prevMessages => [...prevMessages, newResponse]);
    //         console.error("Error in downloading Fira Report : ", err);
    //         // enqueueSnackbar(`Error in downloading Fira Report.`, { variant: 'error' })
    //     }
    // )

    const handleCreateTransaction = async () => {
        let transactionIdResponse;
        try {
            let transactionIdRes = await apiGetBulkTransactionsId();
            transactionIdResponse = doEncryption ? handleDecrypt(transactionIdRes?.data) : transactionIdRes?.data;
            setTransactionId(transactionIdResponse?.data)
        } catch (error) {
            console.log("Error : ", error)
        }
        console.log("first::::")
    }

    const handleOcrDocumentProcessing = async (_file) => {
        setLoading(true);
        let apiResponse;
        let transactionIdResponse;
        try {
            let fileName = encodeURIComponent(_file?.name)
            // count++;

            let transactionIdRes = await apiGetBulkTransactionsId();
            transactionIdResponse = doEncryption ? handleDecrypt(transactionIdRes?.data) : transactionIdRes?.data;
            setTransactionId(transactionIdResponse?.data)
            _file.txnId = transactionIdResponse?.data
            apiResponse = await apiCreateMediaUploadUrl({
                txnId: transactionIdResponse?.data,
                fileName: fileName,
            });
        } catch (error) {
            setLoading(false);
            console.log("check kar : ", error)
            // enqueueSnackbar(`${JSON.stringify(error?.data?.error_response?.message || 'Error while uploading document.')}`, { variant: 'error' })
        }

        let urlData;
        const arrayBuffer = await _file?.arrayBuffer();
        const blob = new Blob([arrayBuffer], {
            type: _file?.type,
        });

        console.log(blob, "Paablo")

        if (doEncryption) {
            urlData = await handleDecrypt(apiResponse?.data);
            await fetch(urlData?.data?.url, {
                method: 'PUT',
                body: blob,
                headers: {
                    'Content-Type': _file.type,
                },
            })
        } else {
            urlData = await apiResponse;
            await fetch(urlData?.data?.data?.url, {
                method: 'PUT',
                body: blob,
                headers: {
                    'Content-Type': _file.type,
                },
            })
        }

        try {
            let chatRes = {};

            if (_file?.type.startsWith('image/')) {
                console.log('Image file detected');
                const BASE64_STRING = await convertBase64(_file);
                const data = {
                    "model": "gpt-4-vision-preview",
                    "messages": [
                        {
                            "role": "user",
                            "content": [
                                {
                                    "type": "text",
                                    "text": `${SEARCH_TEXT}`
                                },
                                {
                                    "type": "image_url",
                                    "image_url": {
                                        "url": `${BASE64_STRING}`,
                                        "detail": "high"
                                    }
                                }
                            ]
                        }
                    ],
                    "max_tokens": 3000
                };

                await fetch(baseUrl.api + `/proxy?docType=IMAGE&transactionId=${_file.txnId}`, {
                    method: 'POST',
                    credentials: 'include',
                    body: JSON.stringify(data)
                })
                    .then(async (response) => {
                        let responseData;
                        if (doEncryption) {
                            responseData = await response.text();
                        } else {
                            responseData = await response.json();
                        }
                        let res = doEncryption ? handleDecrypt(responseData) : responseData;
                        return res;
                    })
                    .then(async (res) => {
                        chatRes = await JSON.parse(res?.data);
                    })
                    .catch(error => {
                        console.error('Error:', error);
                    })
                    .finally(() => {
                        //   setOcrLoading(false);
                    })
            } else {
                const formData = new FormData();
                formData.append('file', _file);
                // formData.append('organization_id', 8);

                const analyzeValues = await fetch(baseUrl.api + `/analyze/invoice`, {
                    method: 'POST',
                    body: formData,
                    credentials: 'include',
                });

                let response;
                if (doEncryption) {
                    response = await analyzeValues.text();
                } else {
                    response = await analyzeValues.json();
                }

                console.log(response, "text_response");

                let res = doEncryption ? handleDecrypt(response) : response;
                if (doEncryption) {
                    response = await res?.data;
                } else {
                    response = await response?.data;
                }

                await fetch(baseUrl.api + `/proxy?docType=PDF&transactionId=${_file.txnId}`, {
                    method: 'POST',
                    credentials: 'include',
                    body: JSON.stringify(response)
                })
                    .then(async (response) => {
                        let responseData;
                        if (doEncryption) {
                            responseData = await response.text();
                        } else {
                            responseData = await response.json();
                        }
                        let res = doEncryption ? handleDecrypt(responseData) : responseData;
                        return res;
                    })
                    .then(async (res) => {
                        chatRes = await JSON.parse(res?.data);
                    })
                    .catch(error => {
                        console.error('Error:', error);
                    })
                    .finally(() => {
                        //   setOcrLoading(false);
                    })
            }

            chatRes.txnId = transactionIdResponse?.data;
            const updatedObject = chatRes;
            setOcrResponse([updatedObject]);

        } catch (error) {
            setLoading(false);
            console.log(error)
        }
    }

    useEffect(() => {
        if (hitDraftApi) handleCreateParamsForInvoice();
    }, [hitDraftApi])

    const handleCreateParamsForInvoice = async () => {
        // let transactionIdRes = await apiGetBulkTransactionsId({ organizationId: organizationId, userId: Number(userId) });
        // // await createTransaction({ organizationId, userId: userId });
        // // await new Promise(resolve => setTimeout(resolve, 4000));
        // let fileName = encodeURIComponent(invoiceFile?.name)
        // let createMediaUploadUrlResult = await apiCreateMediaUploadUrl({
        //     txnId: transactionIdRes?.data?.data,
        //     organizationId,
        //     fileName: fileName,
        // });
        // const arrayBuffer = await invoiceFile?.arrayBuffer();
        // const blob = new Blob([arrayBuffer], {
        //     type: invoiceFile?.type,
        // });
        // let urlData;
        // // urlData = await handleDecrypt(createMediaUploadUrlResult?.data);
        // // await new Promise(resolve => setTimeout(resolve, 4000));
        // urlData = createMediaUploadUrlResult?.data;
        // await fetch(urlData?.data?.url, {
        //     method: 'PUT',
        //     body: blob,
        //     headers: {
        //         'Content-Type': invoiceFile?.type,
        //     },
        // });
        // await new Promise(resolve => setTimeout(resolve, 4000));
        // const newDataCreateInvoice = {
        //     // organizationId,
        //     // userId: userId,
        //     // transactionId: transactionIdRes?.data?.data
        // };
        // const paramsCreateInvoice = {
        //     ...data,
        //     ...newDataCreateInvoice,
        // };
        let paramsFromOcr = {
            transactionId: currentTxnId,
            // invoiceId: Math.floor(1000000000 + Math.random() * 9000000000),
            invoiceId: invoiceId === "" ? null : invoiceId,
            // purposeCode: purposeCode === "" ? null : purposeCode,
            issueDate: issueDate ? issueDate : null,
            // dueDate: dueDate ? new Date(dueDate)?.getTime() : null,
            // blDate: blDate ? new Date(blDate)?.getTime() : null,
            invoiceAmount: (invoiceAmount === '' || invoiceAmount === null) && (receivableAmount === '' || receivableAmount === null)
                ? null
                : (invoiceAmount !== '' && invoiceAmount !== null) && (receivableAmount === '' || receivableAmount === null)
                    ? invoiceAmount
                    : (receivableAmount !== '' && receivableAmount !== null) && (invoiceAmount === '' || invoiceAmount === null)
                        ? receivableAmount
                        : Math.max(parseFloat(invoiceAmount), parseFloat(receivableAmount)),
            receivingAmount: (receivableAmount === '' || receivableAmount === null) ? (invoiceAmount === '' || invoiceAmount === null) ? null : invoiceAmount : receivableAmount,
            // description: invoiceDescription,
            // customerId: null,
            invoiceCurrency,
            outputCurrency: "INR",
            // receiveMoneyAccountId: primaryBank?.accountId,
            // mode: "DRAFT",
            // requestSendBy: "from-chatbot"
        }

        let textFromOcr = `transaction id is ${currentTxnId}, mode is DRAFT, invoice id is ${invoiceId === "" ? null : invoiceId}, issue date is ${issueDate ? issueDate : null}, invoice amount is ${(invoiceAmount === '' || invoiceAmount === null) && (receivableAmount === '' || receivableAmount === null)
            ? null
            : (invoiceAmount !== '' && invoiceAmount !== null) && (receivableAmount === '' || receivableAmount === null)
                ? invoiceAmount
                : (receivableAmount !== '' && receivableAmount !== null) && (invoiceAmount === '' || invoiceAmount === null)
                    ? receivableAmount
                    : Math.max(parseFloat(invoiceAmount), parseFloat(receivableAmount))
            }, receiving amount is ${(receivableAmount === '' || receivableAmount === null) ? (invoiceAmount === '' || invoiceAmount === null) ? null : invoiceAmount : receivableAmount}, invoice currency is ${invoiceCurrency}, output currency is INR and file is uploaded`;

        setParams(textFromOcr);
        await sendMessageApi(textFromOcr);
        // await fetchData();
    }

    const handleCreateInvoice = async (data) => {
        let transactionIdResponse;
        try {
            let transactionIdRes = await apiGetBulkTransactionsId();
            transactionIdResponse = doEncryption ? handleDecrypt(transactionIdRes?.data) : transactionIdRes?.data;
            setTransactionId(transactionIdResponse?.data)
        } catch (error) {
            console.log("Error : ", error)
        }

        console.log("second::::", transactionIdResponse)

        let newDataCreateInvoice = {
            transactionId: transactionIdResponse?.data,
            // invoiceId: Math.floor(1000000000 + Math.random() * 9000000000),
            // invoiceId: invoiceId === "" ? null : invoiceId,
            purposeCode: purposeCode === "" ? null : purposeCode,
            // issueDate: issueDate ? new Date(issueDate)?.getTime() : null,
            // dueDate: dueDate ? new Date(dueDate)?.getTime() : null,
            // blDate: blDate ? new Date(blDate)?.getTime() : null,
            // invoiceAmount: (invoiceAmount === '' || invoiceAmount === null) && (receivableAmount === '' || receivableAmount === null)
            //     ? null
            //     : (invoiceAmount !== '' && invoiceAmount !== null) && (receivableAmount === '' || receivableAmount === null)
            //         ? invoiceAmount
            //         : (receivableAmount !== '' && receivableAmount !== null) && (invoiceAmount === '' || invoiceAmount === null)
            //             ? receivableAmount
            //             : Math.max(parseFloat(invoiceAmount), parseFloat(receivableAmount)),
            // receivingAmount: (receivableAmount === '' || receivableAmount === null) ? (invoiceAmount === '' || invoiceAmount === null) ? null : invoiceAmount : receivableAmount,
            // description: invoiceDescription,
            // customerId: null,
            // invoiceCurrency,
            // outputCurrency: "INR",
            // receiveMoneyAccountId: primaryBank?.accountId,
            // mode: "CREATE",
            requestSendBy: "from-chatbot"
        }
        const paramsCreateInvoice = {
            ...data,
            ...newDataCreateInvoice,
            issueDate: dateToTimestamp(data?.issueDate),
            dueDate: dateToTimestamp(data?.dueDate),
            issueDate: dateToTimestamp(data?.issueDate),
            mode: data?.mode === null ? "DRAFT" : data?.mode,
        };
        if (transactionIdResponse?.data === '' || transactionIdResponse?.data === null || transactionIdResponse?.data === undefined) {
            let newResponse = { attachments: [], type: "api", status: "", action: "", message: "Error occured while creating an invoice.", doc_format: "", file_url: "", missingFields: [], data: null }
            setTimeout(() => {
                setMessages(prevMessages => [...prevMessages, newResponse]);
            }, [500])
        }
        // else if (data?.invoiceId === '' || data?.invoiceId === null || data?.invoiceId === undefined) {
        //     let newResponse = { attachments: [], type: "api", status: "", action: "", message: "Please tell the invoice id to create an invoice.", doc_format: "", file_url: "", missingFields: [], data: null }
        //     setTimeout(() => {
        //         setMessages(prevMessages => [...prevMessages, newResponse]);
        //     }, [500])
        // } else if (data?.customerName === '' || data?.customerName === null || data?.customerName === undefined) {
        //     let newResponse = { attachments: [], type: "api", status: "", action: "", message: "Please tell the customer name to create an invoice.", doc_format: "", file_url: "", missingFields: [], data: null }
        //     setTimeout(() => {
        //         setMessages(prevMessages => [...prevMessages, newResponse]);
        //     }, [500])
        // } else if (data?.invoiceAmount === '' || data?.invoiceAmount === null || data?.invoiceAmount === undefined) {
        //     let newResponse = { attachments: [], type: "api", status: "", action: "", message: "Please tell the invoice amount to create an invoice.", doc_format: "", file_url: "", missingFields: [], data: null }
        //     setTimeout(() => {
        //         setMessages(prevMessages => [...prevMessages, newResponse]);
        //     }, [500])
        // } else if (data?.invoiceCurrency === '' || data?.invoiceCurrency === null || data?.invoiceCurrency === undefined) {
        //     let newResponse = { attachments: [], type: "api", status: "", action: "", message: "Please tell the invoice currency to create an invoice.", doc_format: "", file_url: "", missingFields: [], data: null }
        //     setTimeout(() => {
        //         setMessages(prevMessages => [...prevMessages, newResponse]);
        //     }, [500])
        // } else if (data?.issueDate === '' || data?.issueDate === null || data?.issueDate === undefined) {
        //     let newResponse = { attachments: [], type: "api", status: "", action: "", message: "Please tell the issue date to create an invoice.", doc_format: "", file_url: "", missingFields: [], data: null }
        //     setTimeout(() => {
        //         setMessages(prevMessages => [...prevMessages, newResponse]);
        //     }, [500])
        // } 
        else {
            await createInvoiceCall(paramsCreateInvoice);
        }
    }

    const handleUploadInvoice = async (data) => {
        console.log("second::::")

        let newDataCreateInvoice = {
            transactionId: currentTxnId,
            // invoiceId: Math.floor(1000000000 + Math.random() * 9000000000),
            // invoiceId: invoiceId === "" ? null : invoiceId,
            purposeCode: purposeCode === "" ? null : purposeCode,
            // issueDate: issueDate ? new Date(issueDate)?.getTime() : null,
            // dueDate: dueDate ? new Date(dueDate)?.getTime() : null,
            // blDate: blDate ? new Date(blDate)?.getTime() : null,
            // invoiceAmount: (invoiceAmount === '' || invoiceAmount === null) && (receivableAmount === '' || receivableAmount === null)
            //     ? null
            //     : (invoiceAmount !== '' && invoiceAmount !== null) && (receivableAmount === '' || receivableAmount === null)
            //         ? invoiceAmount
            //         : (receivableAmount !== '' && receivableAmount !== null) && (invoiceAmount === '' || invoiceAmount === null)
            //             ? receivableAmount
            //             : Math.max(parseFloat(invoiceAmount), parseFloat(receivableAmount)),
            // receivingAmount: (receivableAmount === '' || receivableAmount === null) ? (invoiceAmount === '' || invoiceAmount === null) ? null : invoiceAmount : receivableAmount,
            // description: invoiceDescription,
            // customerId: null,
            // invoiceCurrency,
            // outputCurrency: "INR",
            // receiveMoneyAccountId: primaryBank?.accountId,
            // mode: "CREATE",
            requestSendBy: "from-chatbot"
        }
        const paramsCreateInvoice = {
            ...data,
            ...newDataCreateInvoice,
            issueDate: dateToTimestamp(data?.issueDate),
            dueDate: dateToTimestamp(data?.dueDate),
            issueDate: dateToTimestamp(data?.issueDate),
            mode: data?.mode === null ? "DRAFT" : data?.mode,
        };
        if (currentTxnId === '' || currentTxnId === null || currentTxnId === undefined) {
            let newResponse = { attachments: [], type: "api", status: "", action: "", message: "Please upload the file to create an invoice.", doc_format: "", file_url: "", missingFields: [], data: null }
            setTimeout(() => {
                setMessages(prevMessages => [...prevMessages, newResponse]);
            }, [500])
        }
        // else if (data?.invoiceId === '' || data?.invoiceId === null || data?.invoiceId === undefined) {
        //     let newResponse = { attachments: [], type: "api", status: "", action: "", message: "Please tell the invoice id to create an invoice.", doc_format: "", file_url: "", missingFields: [], data: null }
        //     setTimeout(() => {
        //         setMessages(prevMessages => [...prevMessages, newResponse]);
        //     }, [500])
        // } else if (data?.customerName === '' || data?.customerName === null || data?.customerName === undefined) {
        //     let newResponse = { attachments: [], type: "api", status: "", action: "", message: "Please tell the customer name to create an invoice.", doc_format: "", file_url: "", missingFields: [], data: null }
        //     setTimeout(() => {
        //         setMessages(prevMessages => [...prevMessages, newResponse]);
        //     }, [500])
        // } else if (data?.invoiceAmount === '' || data?.invoiceAmount === null || data?.invoiceAmount === undefined) {
        //     let newResponse = { attachments: [], type: "api", status: "", action: "", message: "Please tell the invoice amount to create an invoice.", doc_format: "", file_url: "", missingFields: [], data: null }
        //     setTimeout(() => {
        //         setMessages(prevMessages => [...prevMessages, newResponse]);
        //     }, [500])
        // } else if (data?.invoiceCurrency === '' || data?.invoiceCurrency === null || data?.invoiceCurrency === undefined) {
        //     let newResponse = { attachments: [], type: "api", status: "", action: "", message: "Please tell the invoice currency to create an invoice.", doc_format: "", file_url: "", missingFields: [], data: null }
        //     setTimeout(() => {
        //         setMessages(prevMessages => [...prevMessages, newResponse]);
        //     }, [500])
        // } else if (data?.issueDate === '' || data?.issueDate === null || data?.issueDate === undefined) {
        //     let newResponse = { attachments: [], type: "api", status: "", action: "", message: "Please tell the issue date to create an invoice.", doc_format: "", file_url: "", missingFields: [], data: null }
        //     setTimeout(() => {
        //         setMessages(prevMessages => [...prevMessages, newResponse]);
        //     }, [500])
        // } 
        else {
            await createInvoiceCall(paramsCreateInvoice);
        }
    }

    const handleAddCustomer = async (data) => {
        const newDataCreateCustomer = {
            organizationId,
            userId: userId,
        };
        const paramsCreateCustomer = {
            ...data,
            // ...newDataCreateCustomer,
        };
        await addCustomer(paramsCreateCustomer);
    }

    const handleReconcilation = async (data) => {
        await postSendReconcilationData({ reconcile_receivable_payload_list: data?.reconcile_receivable_payload_list });
    }

    const handleReportDownload = async (data) => {
        await downloadFiraReportWithInvoiceId({ invoiceId: data?.invoice_id })
    }

    const handlePaymentAdviceLatestDownload = async () => {
        await downloadLatestPaymentAdvice()
    }

    const handlePaymentAdviceCustomerDownload = async (data) => {
        await downloadCustomerNamePaymentAdvice(data)
    }

    const handlePaymentAdviceGeneralDownload = async (data) => {
        await downloadGeneralMehtodPaymentAdvice(data)
    }

    const handleSuccess = async (action, data) => {
        console.log(data, "Paabo")
        switch (action) {
            case "create_invoice":
                handleCreateInvoice(data);
                break;
            case "upload_invoice":
                handleUploadInvoice(data);
                break;
            case "create_customer":
                handleAddCustomer(data);
                break;
            case "reconcile_invoice":
                handleReconcilation(data);
                break;
            case "get_payment_advice_invoice":
                handleReportDownload(data);
                break;
            case "get_payment_advice_latest":
                handlePaymentAdviceLatestDownload();
                break;
            case "get_payment_advice_customer":
                handlePaymentAdviceCustomerDownload(data);
                break;
            case "get_payment_advice_general_method":
                handlePaymentAdviceGeneralDownload(data);
                break;
            default:
                console.log("Action didn't match to any success.");
                break;
        }
        setLoadingSuccess(false);
    };

    const handleInputChange = (event) => {
        setInputValue(event.target.value);
    };

    const scrollBy = (sectionId, scrollCount = 2) => {
        const section = document.getElementById("last__div");

        if (scrollCount > 0 && section) {
            section.scrollIntoView({ block: 'start', behavior: 'smooth' });

            setTimeout(() => {
                scrollBy(sectionId, scrollCount - 1);
            }, 0);
        }
    };

    useEffect(() => {
        scrollBy();
    }, [messages])

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (inputValue.trim() !== "") {
            setLoading(true);

            const messageType = "user";

            const newMessage = { type: messageType, message: inputValue };

            setMessages([...messages, newMessage]);

            setInputValue("");

            await sendMessageApi(inputValue);

            // setTimeout(() => {
            //     fetchData();
            // }, 1000);
        }
        scrollBy();
    };

    const sendMessageApi = async (inputValue) => {
        await callMessageChatbot({ message: inputValue });
    }

    const fetchData = async () => {
        const response = await fetch(`https://api.openai.com/v1/threads/${threadId}/runs`, {
            method: "POST",
            headers: {
                "Authorization": process.env.REACT_APP_CHATBOT_TOKEN,
                "Content-Type": "application/json",
                //   "openai-organization": "org-FxS2tgYoHII55PxF56TLFsaC",
                'OpenAI-Beta': 'assistants=v1',
            },
            body: JSON.stringify({
                assistant_id: process.env.REACT_APP_ASSISTANT_ID,
                stream: true
            })
        });

        if (!response.ok) {
            setLoading(false);
            throw new Error('Network response was not ok');
        }

        const reader = response.body.getReader();
        const decoder = new TextDecoder('utf-8');

        const processStream = async ({ done, value }) => {
            console.log(done, "xcvxcdvfbgfdfghfv")
            if (done) {
                console.log('Stream closed');
                setLoading(false);
                return;
            }

            // Decode the chunk
            const text = decoder.decode(value, { stream: true });

            // Split the text into lines
            const lines = text?.trim()?.split('\n');

            // Initialize an object to store parsed event data
            const eventData = {};

            // Iterate over each line
            lines.forEach(line => {
                // Split each line at the first occurrence of ':'
                const separatorIndex = line?.indexOf(':');
                if (separatorIndex !== -1) {
                    const key = line?.slice(0, separatorIndex)?.trim();
                    let value = line?.slice(separatorIndex + 1)?.trim();

                    // Check if the key is 'data'
                    if (key === 'data') {
                        // Check if value is '[DONE]'
                        if (value === '[DONE]') {
                            eventData.data = '[DONE]';
                        } else {
                            try {
                                eventData.data = JSON?.parse(value);
                            } catch (error) {
                                console.error('JSON parsing error:', error, JSON.stringify(value));
                            }
                        }
                    } else {
                        eventData[key] = value; // Store other fields in eventData
                    }
                }
            });

            // Log the original event data
            console.log('Received:', eventData);

            let newMessageTemp = [];
            if (eventData.event === 'thread.message.completed') {
                const messageType = "bot";
                let jsonString = "";
                const startIndex = eventData.data && eventData.data?.content && eventData.data?.content?.length > 0 && eventData.data?.content[0]?.text && eventData.data?.content[0]?.text?.value && eventData.data?.content[0]?.text?.value?.indexOf('{');

                if (startIndex !== -1) {
                    const endIndex = eventData.data && eventData.data?.content && eventData.data?.content?.length > 0 && eventData.data?.content[0]?.text && eventData.data?.content[0]?.text?.value && eventData.data?.content[0]?.text?.value?.lastIndexOf('}');

                    if (endIndex !== -1) {
                        jsonString = eventData.data && eventData.data?.content && eventData.data?.content?.length > 0 && eventData.data?.content[0]?.text && eventData.data?.content[0]?.text?.value && eventData.data?.content[0]?.text?.value?.slice(startIndex, endIndex + 1);

                    } else {
                        console.error('Closing curly brace (}) not found.');
                    }
                } else {
                    console.error('Opening curly brace ({) not found.');
                }

                let obj;

                try {
                    obj = JSON.parse(jsonString);
                    obj.type = messageType;
                } catch (error) {
                    console.error('Error parsing JSON:', error);
                }
                const newMessage = obj;
                console.log(newMessage, "Paabo")
                newMessageTemp?.push(newMessage);
                if (newMessage?.status === "success") {
                    setLoadingSuccess(true);
                    handleSuccess(newMessage?.action, newMessage?.data);
                }

                setMessages(prevMessages => [...prevMessages, newMessage]);
            }

            // Read the next chunk
            reader.read().then(processStream);
        };

        // Start reading the response stream
        await reader.read().then(processStream);
    };

    const handleFocus = () => {
        setFocus(true);
    };

    const handleBlur = () => {
        setFocus(false);
    };

    const handleFileChange = async (event) => {
        const file = event?.target?.files[0];
        setInvoiceFile(file);
        if (file) {
            const id = 8;
            const previewURL = URL.createObjectURL(file); // Create preview URL
            // const arrayBuffer = await file.arrayBuffer(); // Fetch array buffer
            // const blob = new Blob([arrayBuffer], {
            //     type: file.type,
            // });
            console.log(previewURL, file.type, "Paablo")

            let newResponseFile = { attachments: [], type: "user", status: "", action: "", message: "", doc_format: file.type, file_url: previewURL, missingFields: [], data: null }
            setMessages(prevMessages => [...prevMessages, newResponseFile]);

            let newResponse = { attachments: [], type: "upload", status: "", action: "", message: "Please wait while we are processing your file.", doc_format: "", file_url: "", missingFields: [], data: null }
            setMessages(prevMessages => [...prevMessages, newResponse]);

            const newFile = Object.assign(file, {
                preview: previewURL,
                uploadedAt: Date.now(),
                inQueue: true,
                isUploading: null,
                id,
            });
            setHitDraftApi(false);
            getBankAccounts();
            handleOcrDocumentProcessing(file);
            console.log("Selected file:", file);
        }
    };

    const formatString = (key) => {
        return key
            ?.split(/[_\s]|(?=[A-Z])/)
            ?.map(word => word?.charAt(0)?.toUpperCase() + word?.slice(1))
            ?.join(" ");
    };

    return (
        <div className={classes.modal}>
            <div
                className="flex z-10 flex-row items-center justify-between sticky top-0 right-0 left-0 px-[16px] py-[12px] bg-[#FFFFFF] border-b-[1px] border-s-[#E8EAF]"
                style={{ borderTopRightRadius: "8px", borderTopLeftRadius: "8px" }}
            >
                <div>
                    <p className="font-inter text-[1.8rem] text-[#1E333F] font-[600] leading-[2.8rem] ">{`Your Virtual Assistant`}</p>
                </div>
                <div className="flex">
                    <div
                        onClick={() => {
                            onClose();
                        }}
                        className={classes.minus__icon}
                    >
                        <img src={MinusIcon} alt="MinusIcon" className="h-[2.8rem] w-[2.8rem] rounded-[100%]" />
                    </div>
                    <Close
                        className="h-[2.8rem] w-[2.8rem] rounded-[100%]"
                        onClick={() => {
                            onKill();
                        }}
                    />
                </div>
            </div>

            <div className={"overflow-scroll h-[516px]"}>
                <div
                    className="flex flex-col sticky top-0 right-0 left-0 px-[16px] pt-[20px] bg-[#FFFFFF]"
                >
                    {messages.map((message, index) => (
                        <div
                            key={index}
                            className={clsx(classes.user__box, `${message?.type === "user" ? "ml-auto !rounded-tl-[12px]" : "!rounded-tr-[12px]"
                                } max-w-[70%] mb-4`)}
                        >
                            <p className={classes.user__name}>{message?.type === "user" ? "You" : "Fideo AI"}</p>
                            <p className={classes.user__text}>{message?.message}</p>
                            {
                                message?.file_url && message?.file_url !== "" && message?.doc_format?.includes("image") &&
                                <img
                                    alt={`${index}+image`}
                                    src={message?.file_url}
                                />
                            }
                            {
                                message?.file_url && message?.file_url !== "" && message?.doc_format?.includes("application") &&
                                <Document
                                    file={message?.file_url}
                                    onLoadSuccess={onDocumentLoadSuccess}
                                    loading={""}
                                >
                                    {Array.from(new Array(numPages), (el, index) => (
                                        <Page
                                            key={`page_${index + 1}`}
                                            pageNumber={index + 1}
                                            className={clsx('!w-[309px] !h-[400px]',
                                                classes.pdfViewerSmall
                                            )}
                                            style={{
                                                minWidth: '309px !important',
                                                minHeight: '309px !important',
                                            }}
                                            renderTextLayer={false}
                                            renderAnnotationLayer={false}
                                            loading={""}
                                        />
                                    ))}
                                </Document>
                            }
                            {
                                message?.missingFields && message?.missingFields?.length > 0 &&
                                <ul className={classes.user__text}>
                                    {message?.missingFields?.map((field, index) => (
                                        <li key={index}>{index + 1}. {formatString(field)}
                                            {/* {field?.split("_")?.map(word => word?.charAt(0)?.toUpperCase() + word?.slice(1))?.join(" ")} */}
                                        </li>
                                    ))}
                                </ul>
                            }
                            {
                                message?.data && Object.keys(message?.data)?.length > 0 &&
                                <ul className={classes.user__text}>
                                    {Object.keys(message?.data).map((key, index) => (
                                        <li key={index}>
                                            {/* <strong>{key?.split("_")?.map(word => word?.charAt(0)?.toUpperCase() + word?.slice(1))?.join(" ")}: {" "}</strong> */}
                                            <strong>{formatString(key)}: {" "}</strong>
                                            {Array.isArray(message?.data[key]) && message?.data[key]?.length > 0 ? (
                                                <ul>
                                                    {message?.data[key].map((obj, index) => (
                                                        <li key={index}>
                                                            <div>
                                                                {Object?.keys(obj)?.map((innerKey, innerIndex) => (
                                                                    <p key={innerIndex}>
                                                                        {/* {innerKey?.split("_")?.map(word => word?.charAt(0)?.toUpperCase() + word?.slice(1))?.join(" ")}: {obj[innerKey]} */}
                                                                        {formatString(innerKey)}: {obj[innerKey]}
                                                                        <br /> {/* Line break after each key */}
                                                                    </p>
                                                                ))}
                                                            </div>
                                                        </li>
                                                    ))}
                                                </ul>
                                            ) : (
                                                message?.data[key] // Render text if it's not an array or if it's an empty array
                                            )}
                                            {/* {Array.isArray(message?.data[key]) ? message?.data[key].map(obj => obj.value).join(", ") : message?.data[key]} */}
                                        </li>
                                    ))}
                                </ul>
                            }
                        </div>
                    ))}
                    {(loading || loadingSuccess) && (
                        <div className={classes.chatbot__message}>
                            {/* <div className={classes.user__message__name}>
                                <Image
                                    src={gptIcon}
                                    layout="fill"
                                    alt="gpt-res-icon"
                                />
                                </div> */}

                            <div className={classes.typing}>
                                {
                                    [1, 2, 3, 4]?.map((item, index) => {
                                        return <div key={index} className={classes.dot}></div>;
                                    })
                                }
                            </div>
                        </div>
                    )}
                </div>
                <div id="last__div"></div>
            </div>

            <div
                className="flex z-10 flex-row items-center justify-between bottom-0 right-0 left-0 px-[16px] py-[12px] bg-[#FFFFFF] absolute rounded-bl-[8px] rounded-br-[8px]"
            >
                {showTypeAMessage && (
                    <div className={classes.form__container}>
                        <form onSubmit={handleSubmit}>
                            <div className={classes.input__box}>
                                <input
                                    className={classes.user__name}
                                    onBlur={handleBlur}
                                    onFocus={handleFocus}
                                    type="text"
                                    placeholder="Write something..."
                                    value={inputValue}
                                    onChange={handleInputChange}
                                />
                                <label htmlFor="fileInput">
                                    <input
                                        id="fileInput"
                                        type="file"
                                        accept=".pdf, .jpg, .jpeg, .png"
                                        onChange={handleFileChange}
                                        style={{ display: "none" }}
                                    />
                                    <button
                                        type="button"
                                        className="attachment-button"
                                        onClick={() => document.getElementById('fileInput').click()}
                                        disabled={loading || loadingSuccess}
                                    >
                                        <img src={UploadInvoicesIcon} alt="UploadInvoicesIcon" className="w-[32px] mr-[12px]" />
                                    </button>
                                </label>
                                <button
                                    type="submit"
                                    className={(loading || loadingSuccess || inputValue?.trim()?.length === 0) ? 'opacity-50' : ''}
                                    disabled={loading || loadingSuccess || inputValue?.trim()?.length === 0}
                                >
                                    <img src={SendIcon} alt="SendIcon" className="w-[32px]" />
                                </button>
                            </div>
                        </form>

                    </div>
                )}
            </div>
        </div>
    );
};

export default ChatModal;
