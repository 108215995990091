// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DropdownOption_opt__0EkoI {
  display: flex;
  list-style: none;
  align-items: center;
  padding: 1.2rem 2rem;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2.4rem;
}
.DropdownOption_opt_active__FpHZ3, .DropdownOption_opt__0EkoI:hover {
  background-color: #F6F7F7;
}
.DropdownOption_opt__0EkoI .DropdownOption_btn__DbJ1p {
  height: 2rem;
  width: 2rem;
  border: 0.1rem solid #1E333F;
  border-radius: 50%;
  margin-right: 1.8rem;
}
.DropdownOption_opt__0EkoI .DropdownOption_btn_active__keNWs {
  border: 0.5rem solid #1E333F;
}
.DropdownOption_opt__0EkoI .DropdownOption_heading__JnKU8 {
  margin-right: auto;
}`, "",{"version":3,"sources":["webpack://./src/Components/Dropdown/DropdownOption.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,gBAAA;EACA,mBAAA;EACA,oBAAA;EACA,gBAAA;EACA,iBAAA;EACA,mBAAA;AACJ;AACI;EACI,yBAAA;AACR;AAEI;EACI,YAAA;EACA,WAAA;EACA,4BAAA;EACA,kBAAA;EACA,oBAAA;AAAR;AAEQ;EACI,4BAAA;AAAZ;AAKI;EACI,kBAAA;AAHR","sourcesContent":[".opt{\n    display: flex;\n    list-style: none;\n    align-items: center;\n    padding: 1.2rem 2rem;\n    font-weight: 400;\n    font-size: 1.6rem;\n    line-height: 2.4rem;\n\n    &_active, &:hover{\n        background-color: #F6F7F7;\n    }\n\n    .btn{\n        height: 2rem;\n        width: 2rem;\n        border: 0.1rem solid #1E333F;\n        border-radius: 50%;\n        margin-right: 1.8rem;\n\n        &_active{\n            border: 0.5rem solid #1E333F;\n        }\n        \n    }\n\n    .heading{\n        margin-right: auto;\n    }\n\n    .sub_heading{\n        \n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"opt": `DropdownOption_opt__0EkoI`,
	"opt_active": `DropdownOption_opt_active__FpHZ3`,
	"btn": `DropdownOption_btn__DbJ1p`,
	"btn_active": `DropdownOption_btn_active__keNWs`,
	"heading": `DropdownOption_heading__JnKU8`
};
export default ___CSS_LOADER_EXPORT___;
