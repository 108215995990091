import React, { useState } from 'react'
import { Collapse, Card, Divider } from 'antd'
import clsx from 'clsx'
import copyDetailsSvg from '../../../assets/Images/copy-details.svg'
import { enqueueSnackbar } from 'notistack'

const BankAccountDetailsTabContent = (props) => {
  const { invoice_status } = props?.invoice
  const payment_account_details = props?.invoice?.payment_account_details
  const { bank_account_details } = payment_account_details || {}
  const { virtual_account_details } =
    payment_account_details?.virtual_bank_account_details || {}

  const [copyDetailsClicked, setCopyDetailsClicked] = useState(false)

  const CopyDetails = () => {
    const copyToClipboardText = 'Copy Details'
    return (
      <div className="copyDetails">
        <img src={copyDetailsSvg} alt="Copy Details" />
        <div className="copyDetailsText">{copyToClipboardText}</div>
      </div>
    )
  }

  const handleCopyDetailsClicked = () => {
    setCopyDetailsClicked(!copyDetailsClicked)
  }

  const copyTextToClipboard = (text) => {
    if ('clipboard' in navigator) {
      return navigator.clipboard.writeText(text)
    }
    return document.execCommand('copy', true, text)
  }

  const convertTextToAlphabeticCaseWithSpaces = (text) => {
    const lines = text.trim().split('\n')
    let result = ''

    for (const line of lines) {
      const [key, value] = line.split(':').map((item) => item.trim())
      const formattedKey = key
        .replace(/([A-Z])/g, ' $1')
        .trim()
        .split(/\s+/)
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ')
      result += `${formattedKey} : ${value}\n`
    }

    return result.trim()
  }

  const handleCopy = (data) => {
    try {
      let value = ''
      // for (var key in data) {
      //   if (data.hasOwnProperty(key)) {
      //     var val = data[key]
      //     value += key + ' : ' + val + '\n'
      //   }
      // }
      // const result = convertTextToAlphabeticCaseWithSpaces(value)
      const text = `Beneficiary : ${
        data.accountHolderName
      }\nBeneficiary address: ${data.bankAddress}\nAccount Number : ${
        data.accountNumber
      }\nAccount Type : ${data.accountType}\n${
        data?.routingCodeType &&
        `${data.routingCodeType}: ${data.routingCode}`
      }\nBank Name: ${data.bankName}\nBank Address: ${data.bankAddress}`
      copyTextToClipboard(text)
      enqueueSnackbar('Bank Details Copied!', { variant: 'success' })
    } catch (error) {}
  }

  const priorityOrder = ['Fedwire', 'Swift', 'ACH']
  const paymentMethodSubText = {
    Fedwire: 'Settles in 1-2 hours. Cheaper than Swift.',
    Swift: 'Settles same day or next day.',
    ACH: 'Settles in 2-3 days after payment.',
  }


    const sortedVirtualAccounts = [...virtual_account_details]?.sort((a, b) => {
    const priorityA = priorityOrder.indexOf(a.paymentMethod)
    const priorityB = priorityOrder.indexOf(b.paymentMethod)
    return priorityA - priorityB
  })

  const items = sortedVirtualAccounts?.map((account, index) => ({
    key: `${index}`,
    label: (
      <div>
        <div className="paymentMethodHeader">
          {account?.paymentMethod === 'Fedwire'
            ? 'Fedwire (Recommended)'
            : account?.paymentMethod}
        </div>
        <div className="paymentMethodSubtext">
          {paymentMethodSubText[account?.paymentMethod]}
        </div>
      </div>
    ),
    children: (
      <>
        <Divider />
        <div className="bankAccountAccordianDetails">
          <div className="flex flex-row flex-wrap justify-start items-center w-full">
            <div className="w-[50%] -sm:w-full accordianDetailsWrapper">
              <p className="accordianDetailsDataHeader">
                {account.accountHolderName}
              </p>
              <p className="accordianDetailsDataSubText">Beneficiary</p>
            </div>
            <div className="w-[50%] -sm:w-full accordianDetailsWrapper">
              <p className="accordianDetailsDataHeader">
                {account.bankAddress}
              </p>
              <p className="accordianDetailsDataSubText">Beneficiary Address</p>
            </div>
          </div>
          <div className="flex flex-row flex-wrap justify-start items-center w-full">
            <div className="w-[50%] -sm:w-full accordianDetailsWrapper">
              <p className="accordianDetailsDataHeader">
                {account.accountNumber}
              </p>
              <p className="accordianDetailsDataSubText">Account Number</p>
            </div>
            <div className="w-[50%] -sm:w-full accordianDetailsWrapper">
              <p className="accordianDetailsDataHeader">
                {account.accountType}
              </p>
              <p className="accordianDetailsDataSubText">Account Type</p>
            </div>
          </div>
          <div className="flex flex-row flex-wrap justify-start items-center w-full">
            <div className="w-[50%] -sm:w-full accordianDetailsWrapper">
              <p className="accordianDetailsDataHeader">
                {account.routingCode}
              </p>
              <p className="accordianDetailsDataSubText">
                {account.routingCodeType}
              </p>
            </div>
            {/* <div className="w-[50%] -sm:w-full accordianDetailsWrapper" /> */}
          </div>
          <div className="flex flex-row flex-wrap justify-start items-center w-full">
            <div className="w-[50%] -sm:w-full accordianDetailsWrapper">
              <p className="accordianDetailsDataHeader">{account.bankName}</p>
              <p className="accordianDetailsDataSubText">Bank Name</p>
            </div>
            <div className="w-[50%] -sm:w-full accordianDetailsWrapper">
              <p className="accordianDetailsDataHeader">
                {account.bankAddress}
              </p>
              <p className="accordianDetailsDataSubText">Bank Address</p>
            </div>
          </div>
        </div>
        <div className="copyDetails" onClick={() => handleCopy(account)}>
          <img src={copyDetailsSvg} alt="Copy Details" />
          <div className="copyDetailsText">{`Copy Details`}</div>
        </div>
      </>
    ),
  }))

  const [openKeys, setOpenKeys] = useState(['0']) // Default panel with key '0' is opened

  const handleCollapseChange = (keys) => {
    setOpenKeys(keys)
  }
 
  return (
    <div className={clsx('virtualAccountDetailsTabContent')}>
      <Card
        title={'Virtual Account Details'}
        bordered={false}
        style={{
          background: 'transparent',
          boxShadow: 'none',
        }}
      >
        <Collapse
          size="large"
          ghost={true}
          bordered={false}
          accordion
          expandIconPosition="end"
          activeKey={openKeys}
          onChange={handleCollapseChange}
        >
          {items.map((item) => (
            <Collapse.Panel
              key={item.key}
              header={item.label}
              showArrow={true}
              disabled={openKeys.includes(item.key)}
            >
              {item.children}
            </Collapse.Panel>
          ))}
        </Collapse>
      </Card>
      <Card
        title={'Settlement bank account'}
        bordered={false}
        style={{
          background: 'transparent',
          boxShadow: 'none',
        }}
      >
        <div className="userBankAccountDetails">
          <div className="flex flex-row flex-wrap justify-start items-center w-full">
            <div className="w-[50%] -sm:w-full accordianDetailsWrapper">
              <p className="accordianDetailsDataHeader">
                {bank_account_details.account_holder_name || "NA"}
              </p>
              <p className="accordianDetailsDataSubText">Account Name</p>
              <Divider className="-sm:block hidden" />
            </div>
            <div className="w-[50%] -sm:w-full accordianDetailsWrapper">
              <p className="accordianDetailsDataHeader">
                {bank_account_details.account_number || "NA"}
              </p>
              <p className="accordianDetailsDataSubText">Account Number</p>
            </div>
          </div>
          <Divider className="-sm:block" />
          <div className="flex flex-row flex-wrap justify-start items-center w-full">
            <div className="w-[50%] -sm:w-full accordianDetailsWrapper">
              <p className="accordianDetailsDataHeader">
                {bank_account_details.bank_name || "NA"}
              </p>
              <p className="accordianDetailsDataSubText">Bank Name</p>
              <Divider className="-sm:block hidden" />
            </div>
            <div className="w-[50%] -sm:w-full accordianDetailsWrapper">
              <p className="accordianDetailsDataHeader">
                {bank_account_details.branch_name || "NA"}
              </p>
              <p className="accordianDetailsDataSubText">Branch Name</p>
            </div>
          </div>
          <Divider className="-sm:block" />
          <div className="flex flex-row flex-wrap justify-start items-center w-full">
            <div className="w-[50%] -sm:w-full accordianDetailsWrapper">
              <p className="accordianDetailsDataHeader">
                {bank_account_details.swift_code || "NA"}
              </p>
              <p className="accordianDetailsDataSubText">Swift Code</p>
              <Divider className="-sm:block hidden" />
            </div>
            <div className="w-[50%] -sm:w-full accordianDetailsWrapper">
              <p className="accordianDetailsDataHeader">
                {bank_account_details.ifsc_code || "NA"}
              </p>
              <p className="accordianDetailsDataSubText">IFSC Code</p>
            </div>
          </div>
          {/* <Divider className="-sm:block" />
          <div className="flex flex-row flex-wrap justify-start items-center w-full">
            <div className="w-[50%] -sm:w-full accordianDetailsWrapper">
              <p className="accordianDetailsDataHeader">{`$3,000/month`}</p>
              <p className="accordianDetailsDataSubText">Account Type</p>
            </div>
          </div> */}
          <Divider className="-sm:block" />
          <div className="flex flex-row flex-wrap justify-start items-center w-full">
            <div className="w-full -sm:w-full accordianDetailsWrapper">
              <p
                className="accordianDetailsDataHeader"
                style={{
                  color: 'rgba(30, 51, 63, 0.50)',
                  fontFamily: 'Inter',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: '500',
                  lineHeight: '20px',
                }}
              >
                Money in your bank will be transferred to this account
                automatically
              </p>
            </div>
          </div>
        </div>
      </Card>
    </div>
  )
}

export default BankAccountDetailsTabContent
