import PageIntro from 'Components/Global/PageIntro'
import classes from 'Pages/Admin/Customers.module.scss'
import FXRateTable from 'Components/molecule/FxRateTable/FXRateTable'

const Customers = () => {
  return (
    <div className={classes.customer}>
      <PageIntro
        pageName={'FX Rates'}
        message={'Below are the mid market rates'}
      />
      <FXRateTable />
    </div>
  )
}
export default Customers
