import Backdrop from 'Layout/Backdrop'
import Modal from 'Layout/Modal'
import clsx from 'clsx'
import ReactDOM from 'react-dom'
import { useDispatch, useSelector } from 'react-redux'
import { modalActions } from 'Store/modal-redux'
import classes from './ComplianceChecks.module.scss'
import leftPointer from 'assets/Icons/chevron-left.svg'
import alert from 'assets/Icons/alert-circleNew.svg'
// import downPointer from 'assets/Icons/chevron-downNew.svg'
import { useEffect, useRef, useState } from 'react'
import {Pagination, Tooltip} from "antd";
// import AccessTooltip from "../../../Pages/Admin/profile/profileComponents/AccessTooltip";
import CompTooltip from "./CompTooltip";
import useClickOutside from "../../../hooks/useClickOutside";
import HitDetailsModalComp from "./HitDetailsModalComp";
import quoteIcon from "../../../assets/Icons/message-square.svg";
import dayjs from "dayjs";
import ComplianceStatusTag from "../../ReportTable/ComplianceStatusTag";



const SearchDetailsModalComp = (props) => {
    // const { enqueueSnackbar } = useSnackbar()
    const dispatch = useDispatch();
    const [tooltip, setTooltip] = useState(false);
    const [remarks, setRemarks] = useState("");
    const [hitData, setHitData] = useState(null);
    const [dobArray, setDobArray] = useState(null);
    const [countryArray, setCountryArray] = useState(null);
    const [typeCountArray, setTypeCountArray] = useState(null);
    const [groupedStringsResult, setGroupedStringsResult] = useState(null);
    const [entities, setEntities] = useState([]);
    const [start, setStart] = useState(0);
    const [end, setEnd] = useState(20);
    // const [prevPage, setPrevPage] = useState();
    const showHitComp = useSelector((state) => state.modal.showHitComp)

    const customerDetailModalRef = useRef(null);
    // const tooltipRef = useRef(null);

    const tooltipRef = useClickOutside(() => setTooltip(false));

    useEffect(() => {
        const ParseData = async () => {
            // console.log('typeof',typeof props?.SearchData?.data)
            if(typeof props?.SearchData?.data === 'string'){
                const myObject = await JSON.parse(props?.SearchData?.data || {});
                // console.log('myObject',myObject)
                setEntities(myObject)

                const myRemarks = props?.SearchData?.comment ? await JSON.parse(props?.SearchData?.comment) : null;

                const filteredRemarks = myRemarks && myRemarks?.filter(remark => remark?.remark && remark?.remark.trim() !== '');

                if(filteredRemarks !== null)
                    filteredRemarks?.sort((a, b) => b?.createdAt - a?.createdAt);

                if (filteredRemarks !== null || filteredRemarks?.length > 0) {
                    const latestRemark = filteredRemarks[0];
                    // console.log('latestRemark',latestRemark)
                    setRemarks(latestRemark);
                } else {
                    setRemarks(null);
                    // Handle case when there are no valid remarks
                    // For example, setRemarks to a default value or handle accordingly
                }
            }
        }

        ParseData()
    }, [props?.SearchData]);

    const handlePaginate = (page)=>{
        // console.log('page',page)

        // if(end < props.SearchData?.totalHits)
        setStart((page - 1) * 20)
        setEnd((page) * 20)

        const section = document.getElementById('sectionId');
        if (section) {
            section.scrollIntoView({ block: 'start', behavior: 'smooth' });
        }
    }

    const handleHitCall = (hit,dobArray,countryArray,typeCountArray,groupedStringsResult) =>{
        // console.log('handleHitCall',hit)
        setHitData(hit);
        setDobArray(dobArray);
        setCountryArray(countryArray);
        setTypeCountArray(typeCountArray);
        setGroupedStringsResult(groupedStringsResult);
        dispatch(modalActions.setShowHitComp(true))
    }

    // console.log('start',start);
    // console.log('remarks',remarks);
    // console.log('props?.SearchData',props?.SearchData?.data);
    // console.log('entities',entities);
    // console.log('props.SearchData',props.SearchData);

    return (
        <>
            {
                showHitComp &&
                ReactDOM.createPortal(
                    <HitDetailsModalComp
                        hitData={hitData}
                        SearchData={props.SearchData}
                        dobArray={dobArray}
                        countryArray={countryArray}
                        typeCountArray={typeCountArray}
                        groupedStringsResult={groupedStringsResult}
                    />,
                    document.getElementById('modal-root')
                )
            }
            <Modal
                className={clsx(
                    classes.modal,
                    `bg-[#F4F6FA] z-[40] p-[0px] overflow-scroll`
                )}
            >
                {/*{ReactDOM.createPortal(*/}
                {/*    <Backdrop*/}
                {/*        onClick={() => {*/}
                {/*            dispatch(modalActions.setShowSearchDataComp(false))*/}
                {/*        }}*/}
                {/*        // className={classes.backdrop}*/}
                {/*    />,*/}
                {/*    document.getElementById('backdrop-root')*/}
                {/*)}*/}
                {/*<Header top/>*/}
                <div
                    ref={customerDetailModalRef}
                    className={clsx(classes.topHeader,"flex items-start justify-between border-b-[1px] border-[#E8EAEF] px-[20px] md:px-[60px] py-[20px] bg-[#FFFFFF]")}
                    id={'sectionId'}
                >
                    {/*left*/}
                    <div className="flex gap-[16px]">
                        <div
                            className="flex items-center justify-center w-[48px] h-[48px] rounded-[100px] px-[12px] py-[8px] bg-[#1E333F14] cursor-pointer"
                            onClick={() => {
                                dispatch(modalActions.setShowSearchDataComp(false))
                            }}
                        >
                            <img src={leftPointer} style={{width: "48px", height: "48px"}}/>
                        </div>


                        <div className="flex-start flex-col gap-[4px]">
                            <p className="font-inter font-[700] text-[16px] leading-[20px] text-[#1E333F]">{props.SearchData?.searchTerm ? props.SearchData?.searchTerm : "--"}</p>
                            <div className="flex items-start md:items-center gap-[4px]">
                                <div className="flex flex-col items-start md:items-center justify-center md:flex-row gap-[4px]">
                                    <p className="font-inter font-[500] text-[14px] leading-[16px] text-[#1E333F66]">Search
                                        Reference</p>
                                    <p className="font-inter font-[400] text-[14px] leading-[20px] text-[#1E333F]">{props.SearchData?.searchId ? props.SearchData?.searchId : "--"}</p>
                                </div>

                                <div
                                    ref={tooltipRef}
                                    className="relative cursor-pointer"
                                     onClick={()=>setTooltip(!tooltip)}
                                >
                                    <img src={alert} style={{width: "24px", height: "24px"}}/>
                                    {tooltip &&
                                        <CompTooltip
                                            SearchData={props.SearchData}
                                        />
                                    }
                                </div>

                            </div>
                        </div>

                    </div>

                    {/*right*/}
                    <div className="flex gap-[4px]">
                        {/*Risk Level*/}
                        <div className="flex flex-col items-start gap-[4px]">
                            <ComplianceStatusTag status={props.SearchData?.matchStatus} type="light"/>
                            <div
                                className="font-inter ml-[8px] font-[500] text-[14px] leading-[24px] text-[#1E333F]">{props.SearchData?.riskLevel?.toLowerCase() !== 'unknown' ? props.SearchData?.riskLevel?.slice(0, 1)?.toUpperCase() + props.SearchData?.riskLevel?.slice(1, props.SearchData?.riskLevel?.length)?.toLowerCase() + " Risk" : ' '}</div>
                        </div>
                    </div>
                </div>

                <div className="flex flex-col justify-center gap-[24px] px-[20px] md:px-[60px] py-[20px]">
                    {(remarks !== null ) &&
                        <div className='flex justify-center '>
                            <div className='flex justify-center items-center bg-[#DBDFE7] rounded-tl-[8px] rounded-bl-[8px] px-[4px]'>
                                <img className='w-[20px] h-[20px]' src={quoteIcon} alt='quote-icon'/>
                            </div>
                            <div className='flex py-[4px] px-[16px] items-center justify-between bg-[#EAEDF3] rounded-tr-[8px] rounded-br-[8px] w-[100%]'>
                                <div className='flex items-center gap-[8px]'>
                                    <div className='text-#1E333F text-[14px] font-[400] leading-[20px]'>
                                        { `Remarks${remarks?.remark ?`(${ dayjs(remarks?.createdAt).local().format(`D MMM YYYY`) })`: ""}` }: <span className='text-[#1e333f80]'>{ remarks?.remark } </span>
                                    </div>
                                </div>
                                {/*<div className='text-#1E333F text-[14px] ml-[12px] font-medium leading-20 underline cursor-pointer'*/}
                                {/*     // onClick={()=>handleRemarks(SearchData?.data?.riskLevel)}*/}
                                {/*>*/}
                                {/*    Edit*/}
                                {/*</div>*/}
                            </div>
                        </div>}

                    <div className={clsx(classes.result,"flex items-start md:items-center justify-between")}>
                        <p className="font-inter font-[600] text-[14px] md:text-[16px] leading-[20px] text-[#1E333F]">{entities?.hits?.length !== undefined ? entities?.hits?.length + " Results" : "0 Results"   }</p>
                    </div>

                    <div className={clsx(classes.shadowCustom ,"flex flex-col justify-center rounded-[12px] p-[20px] bg-[#FFFFFF]")}>
                        {entities?.hits?.length > 0 &&
                            (<div className="grid grid-cols-1 gap-[16px] min-h-fit xl:grid-cols-2">
                            {/*detail box*/}
                            {entities?.hits && entities?.hits?.slice(start, end)?.map((hit, index) => {
                                let dobArray = '';
                                const countryArray = [];

                                hit?.doc?.fields?.forEach(field => {
                                    if (field.name === "Date of Birth") {
                                        // dobArray.push(field.value);
                                        dobArray = field.value.split('-')
                                    } else if (field.name === "Country") {
                                        if (!countryArray.includes(field.value))
                                            countryArray.push(field.value);
                                    }
                                });

                                const typeCounts = {};

                                hit?.doc?.types.forEach(type => {
                                    typeCounts[type] = (typeCounts[type] || 0) + 1;
                                });

                                const typeCountArray = Object.keys(typeCounts).map(key => ({ type: key, count: typeCounts[key] }));

                                // console.log('hit?.doc?.types',hit?.doc?.types)

                                // const result = hit?.doc?.types.map(type => type.startsWith("adverse-media-v2") ? type.slice("adverse-media-v2".length + 1) : type.startsWith("pep-class") ? type.slice("pep".length + 1) : type);
                                //
                                // console.log('result',result);

                                const groupedStrings = {};

                                hit?.doc?.types.forEach(str => {
                                    const prefix = str.split("-")[0]; // Extract the prefix
                                    if (!groupedStrings[prefix]) {
                                        groupedStrings[prefix] = [];
                                    }
                                    groupedStrings[prefix].push(str);
                                });

                                const groupedStringsResult = Object.values(groupedStrings);

                                // console.log('groupedStringsResult',groupedStringsResult);




                                return (<div
                                        className="flex flex-col  rounded-[12px] p-[20px] gap-[12px] border-[1px] border-[#ECEFF2]
                                        hover:cursor-pointer hover:bg-[#F3F4F6] hover:border-[black]"
                                        onClick={()=>handleHitCall(hit,dobArray,countryArray,typeCountArray,groupedStringsResult)}
                                        key={index}
                                    >
                                            <div className={clsx(classes.status,"flex items-start justify-between  gap-[8px]")}>
                                                <p className="font-inter font-[600] text-[14px] md:text-[16px] leading-[20px] text-[#1E333F]">{hit?.doc?.name}</p>
                                                {hit?.doc?.match_status &&
                                                    <ComplianceStatusTag status={hit?.doc?.match_status} type="light"/>
                                                }
                                            </div>

                                        {/*bottom*/}
                                        <div className="flex flex-col">

                                            {/*Appears on*/}
                                            <div
                                                className={clsx(classes.detail, "flex items-start justify-between py-[12px]")}>
                                                <p className="font-inter min-w-[100px] font-[400] text-[12px] leading-[16px] text-[#1E333F66]">APPEARS
                                                    ON</p>
                                                <div
                                                    className="flex flex-wrap justify-start items-end md:justify-end gap-[10px] py-[2px]"
                                                >
                                                    {groupedStringsResult &&
                                                        groupedStringsResult.map((group, groupIndex) => (
                                                            <div key={groupIndex} className="flex flex-col gap-[5px]">
                                                                <Tooltip
                                                                    title={
                                                                        ((group[0] === 'adverse-media' && group?.length > 2) || (group[0] !== 'adverse-media' && group?.length > 1)) &&
                                                                        <div>
                                                                            {(group[0] === 'adverse-media' || group[0] === 'pep') ?
                                                                                group.slice(1,group?.length).map((item, index) => (
                                                                                <div
                                                                                    key={index}
                                                                                    className="px-[15px] py-[2px] text-[12px]"
                                                                                >
                                                                                    {item &&
                                                                                        (() => {
                                                                                            let itemModified = '';
                                                                                            if(item?.startsWith("adverse-media-v2")){
                                                                                                itemModified = item?.slice("adverse-media-v2".length + 1)?.split('-').join(" ")
                                                                                            }else{
                                                                                                itemModified = item?.split('-').join(" ")
                                                                                            }


                                                                                            const capitalized = itemModified
                                                                                                .replace(
                                                                                                    /\b\w/g,
                                                                                                    (char) =>
                                                                                                        char.toUpperCase()
                                                                                                )
                                                                                                .replace(
                                                                                                    /\bAml\b/g,
                                                                                                    "AML"
                                                                                                )
                                                                                                .replace(
                                                                                                    /\bCft\b/g,
                                                                                                    "CFT"
                                                                                                );
                                                                                            return capitalized.replace(
                                                                                                /Aml|Cft/g,
                                                                                                (abbr) =>
                                                                                                    abbr.toUpperCase() + "/"
                                                                                            )
                                                                                        })()}
                                                                                </div>
                                                                            )) :

                                                                                group?.map((item, index) => (
                                                                                    <div
                                                                                        key={index}
                                                                                        className="px-[15px] py-[2px] text-[12px]"
                                                                                    >
                                                                                        {item &&
                                                                                            (() => {
                                                                                                let itemModified = '';
                                                                                                if(item?.startsWith("adverse-media-v2")){
                                                                                                    itemModified = item?.slice("adverse-media-v2".length + 1)?.split('-').join(" ")
                                                                                                }else{
                                                                                                    itemModified = item?.split('-').join(" ")
                                                                                                }


                                                                                                const capitalized = itemModified
                                                                                                    .replace(
                                                                                                        /\b\w/g,
                                                                                                        (char) =>
                                                                                                            char.toUpperCase()
                                                                                                    )
                                                                                                    .replace(
                                                                                                        /\bAml\b/g,
                                                                                                        "AML"
                                                                                                    )
                                                                                                    .replace(
                                                                                                        /\bCft\b/g,
                                                                                                        "CFT"
                                                                                                    );
                                                                                                return capitalized.replace(
                                                                                                    /Aml|Cft/g,
                                                                                                    (abbr) =>
                                                                                                        abbr.toUpperCase() + "/"
                                                                                                )
                                                                                            })()}
                                                                                    </div>
                                                                                ))


                                                                            }
                                                                        </div>
                                                                    }
                                                                >
                                                                    <div>
                                                                        {(group?.length === 2 && group[0] === 'adverse-media') ?
                                                                            group.slice(1, 2).map((item, index) => (
                                                                                <div key={index}>
                                                                                    <div className="flex items-center min-w-fit gap-[4px] border-[1px] border-[#FFACC0] justify-between rounded-[100px] pl-[8px] pt-[2px] pr-[2px] pb-[2px]">
                                                                                        <p className="font-inter font-[400] text-[11px] leading-[16px] text-[#1E333F] uppercase">
                                                                                            {item ? (item?.startsWith("adverse-media-v2") ?  item?.slice("adverse-media-v2".length + 1)?.split('-').join(" ") : item?.split('-').join(" ")) : "--"}
                                                                                        </p>
                                                                                        <div className="flex items-center justify-center gap-[10px] px-[5px] bg-[#FFE8EE] w-[16px] h-[16px] rounded-[100px]">
                                                                                            <p className="font-inter font-[400] text-[12px] leading-[16px] text-[#1E333F]">
                                                                                                {(group[0] === 'adverse-media' || group[0] === 'pep') && group.length > 1 ? group.length - 1 : group.length}
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            ))
                                                                            :
                                                                            group.slice(0, 1).map((item, index) => (
                                                                                <div key={index}>
                                                                                    <div className="flex items-center min-w-fit gap-[4px] border-[1px] border-[#FFACC0] justify-between rounded-[100px] pl-[8px] pt-[2px] pr-[2px] pb-[2px]">
                                                                                        <p className="font-inter font-[400] text-[11px] leading-[16px] text-[#1E333F] uppercase">
                                                                                            {item ? item.split("-").join(" ") : "--"}
                                                                                        </p>
                                                                                        <div className="flex items-center justify-center gap-[10px] px-[5px] bg-[#FFE8EE] w-[16px] h-[16px] rounded-[100px]">
                                                                                            <p className="font-inter font-[400] text-[12px] leading-[16px] text-[#1E333F]">
                                                                                                {(group[0] === 'adverse-media' || group[0] === 'pep') && group.length > 1 ? group.length - 1 : group.length}
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            ))
                                                                        }
                                                                    </div>
                                                                </Tooltip>
                                                            </div>
                                                        ))}
                                                </div>
                                            </div>

                                            <div className="w-[100%] h-[1px] bg-[#ECEFF2]"></div>

                                            {/*dob*/}
                                            <div
                                                className={clsx(classes.detail, "flex items-start  md:items-center justify-between py-[12px]")}>
                                                <p className="font-inter font-[400] text-[12px] leading-[16px] text-[#1E333F66]">DOB</p>
                                                <div
                                                    className="font-inter font-[400] text-[14px] leading-[20px] text-[#1E333F]">
                                                    {dobArray?.length !== 0 ?
                                                        (dobArray[2] ? new Date(dobArray).getDate() + ' ' : '') +
                                                        (dobArray[1] ? new Date(dobArray).toLocaleString('default', {month: 'short'}) + ' ' : '') +
                                                        (dobArray[0] ? new Date(dobArray).getFullYear() : '')
                                                        : "--"}
                                                </div>
                                            </div>


                                            <div className="w-[100%] h-[1px] bg-[#ECEFF2]"></div>

                                            {/*recency*/}
                                            {/*<div className="flex items-center justify-between py-[12px]">*/}
                                            {/*    <p className="font-inter font-[500] text-[12px] leading-[16px] text-[#1E333F66]">RECENCY</p>*/}
                                            {/*    <div*/}
                                            {/*        className="font-inter font-[500] text-[14px] leading-[20px] text-[#1E333F]">{'Lorem ipsum'}</div>*/}
                                            {/*</div>*/}

                                            {/*countries*/}
                                            <div className={clsx(classes.detail,"flex items-start justify-between py-[12px]")}>
                                                <p className="font-inter min-w-[100px] font-[400] text-[12px] leading-[16px] text-[#1E333F66]">COUNTRIES</p>
                                                <div
                                                    className="flex flex-wrap justify-start items-start md:justify-end gap-[4px] ">
                                                    {countryArray?.length ? countryArray?.map((country, indexC) =>
                                                            (
                                                                <div
                                                                    className=""
                                                                    key={indexC}
                                                                >
                                                                    <p className="font-inter font-[400] text-[14px] leading-[20px] text-[#1E333F]">{`${country} ${indexC < countryArray?.length - 1 ? ' , ' : ''}`}</p>
                                                                </div>
                                                            ))
                                                        :
                                                        <p className="font-inter font-[400] text-[14px] leading-[20px] text-[#1E333F]">{`--`}</p>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>)}

                        {props.SearchData?.totalHits === 0 &&
                            <div className="flex items-center justify-center">
                                <p className="font-inter font-[600] text-[16px] leading-[20px] text-[#1E333F]"
                                >
                                    No Results Found
                                </p>
                            </div>
                        }
                        {/*pagination*/}
                        {
                            entities?.hits?.length > 0 &&
                            <div className={clsx(classes.paginationWrap,"mt-[30px] mb-[10px]")}>
                                <Pagination
                                    // defaultCurrent={1}
                                    total={entities?.hits?.length}
                                    showSizeChanger={false}
                                    // current={props?.currentPage + 1}
                                    pageSize={20}
                                    // defaultPageSize={4}
                                    // itemRender={itemRender}
                                    onChange={handlePaginate}
                                />
                            </div>
                        }
                    </div>


                </div>

            </Modal>
        </>
    )
}

export default SearchDetailsModalComp
