import { createSlice } from "@reduxjs/toolkit";

const modalInitialState = {
  showUserDetails: false,
  receivePaymentDisplay: false,
  sendPaymentDisplay: false,
  createInvoiceDisplay: false,
  backdropDisplay: false,
  addContactDisplay: false,
  addPaymentDetailDisplay: false,
  showPaymentDisplay: false,
  showInvoiceDisplay: false,
  showCustomerDisplay: false,
  editInvoiceDisplay: false,
  showFileDisplay: false,
  fileDisplayUrl: "",
  bulkUploaderDisplay: false,
  invoiceActionDisplay: false,
  invoiceActionDisplayMwebRec: false,
  showBankDropDown: false,
  invoiceTooltipDisplay: false,
  reconcileModalDisplay: false,
  purposeCodeDropDown: false,
  addNewUserDisplay: false,
  invoiceViewRecon: false,
  tourStart: false,
  downloadFiraMobile: false,
  mobileInvoiceSuccess: false,
  payoutAmountInfo: false,
  createInvoiceModal: false,
  createInvoiceModalRec: false,
  createInvoiceModalRecDraft: false,
  createInvoiceReceivePayment: false,
  createInvoiceReceivePaymentRec: false,
  createInvoiceReceivePaymentRecDraft: false,
  showPreviewDisplay: false,
  showDraftEditInvoice: false,
  showVbanDeposits: false,
  showSearchDataComp: false,
  chatBotDisplay: [],
  chatBotHistory: null,
  updateContact: false,
  showHitComp: false,
}

export const ModalSlice = createSlice({
  name: 'modal-slice',
  initialState: modalInitialState,
  reducers: {
    showUserDetails(state, action) {
      state.showUserDetails = action.payload
    },
    receivePayment(state) {
      state.receivePaymentDisplay = !state.receivePaymentDisplay
    },
    sendPayment(state) {
      state.sendPaymentDisplay = !state.sendPaymentDisplay
    },
    createInvoice(state) {
      state.createInvoiceDisplay = !state.createInvoiceDisplay
    },
    backdrop(state) {
      state.backdropDisplay = !state.backdropDisplay
    },
    addContact(state) {
      state.addContactDisplay = !state.addContactDisplay
    },
    addPaymentDetail(state) {
      state.addPaymentDetailDisplay = !state.addPaymentDetailDisplay
    },
    showPaymentDisplay(state) {
      state.showPaymentDisplay = !state.showPaymentDisplay
    },
    showInvoiceDisplay(state) {
      state.showInvoiceDisplay = !state.showInvoiceDisplay;
    },
    showCustomerDisplay(state) {
      state.showCustomerDisplay = !state.showCustomerDisplay;
    },
    editInvoice(state) {
      state.editInvoiceDisplay = !state.editInvoiceDisplay;
    },
    showFileDisplay(state, action) {
      state.showFileDisplay = action.payload === false ? action.payload : !state.showFileDisplay;
    },
    fileDisplayUrl(state, action) {
      state.fileDisplayUrl = action.payload;
    },
    bulkUploaderDisplay(state, action) {
      state.bulkUploaderDisplay = action.payload;
    },
    invoiceActionDisplay(state, action) {
      state.invoiceActionDisplay = action.payload;
    },
    invoiceActionDisplayMwebRec(state, action) {
      state.invoiceActionDisplayMwebRec = action.payload;
    },
    showBankDropDown(state, action) {
      state.showBankDropDown = action.payload === undefined ? !state.showBankDropDown : action.payload;
    },
    invoiceTooltipDisplay(state, action) {
      state.invoiceTooltipDisplay = action.payload;
    },
    reconcileModalDisplay(state, action) {
      state.reconcileModalDisplay = action.payload;
    },
    purposeCodeDropDown(state, action) {
      state.purposeCodeDropDown = action.payload;
    },
    addNewUserDisplay(state, action) {
      state.addNewUserDisplay = action.payload;
    },
    invoiceViewRecon(state, action) {
      state.invoiceViewRecon = action.payload;
    },
    tourStart(state, action) {
      state.tourStart = action.payload;
    },
    downloadFiraMobile(state, action) {
      state.downloadFiraMobile = action.payload;
    },
    mobileInvoiceSuccess(state, action) {
      state.mobileInvoiceSuccess = action.payload;
    },
    payoutAmountInfo(state, action) {
      state.payoutAmountInfo = action.payload;
    },
    setCreateInvoiceModal(state, action) {
      state.createInvoiceModal = action.payload;
    },
    setCreateInvoiceModalRec(state, action) {
      state.createInvoiceModalRec = action.payload;
    },
    setCreateInvoiceModalRecDraft(state, action) {
      state.createInvoiceModalRecDraft = action.payload;
    },
    setCreateInvoiceReceivePayment(state, action) {
      state.createInvoiceReceivePayment = action.payload;
    },
    setCreateInvoiceReceivePaymentRec(state, action) {
      state.createInvoiceReceivePaymentRec = action.payload;
    },
    setCreateInvoiceReceivePaymentRecDraft(state, action) {
      state.createInvoiceReceivePaymentRecDraft = action.payload;
    },
    setShowPreviewDisplay(state, action) {
      state.showPreviewDisplay = action.payload;
    },
    setShowDraftEditInvoice(state, action) {
      state.showDraftEditInvoice = action.payload;
    },
    setShowVbanDeposits(state, action) {
      state.showVbanDeposits = action.payload;
    },
    chatBotDisplay(state, action) {
      state.chatBotDisplay = action.payload;
    },
    chatBotHistory(state, action) {
      state.chatBotHistory = action.payload;
    },
    setShowSearchDataComp(state, action) {
      state.showSearchDataComp = action.payload;
    },
    setUpdateContact(state, action) {
      state.updateContact = action.payload;
    },
    setShowHitComp(state, action) {
      state.showHitComp = action.payload;
    },


    removeAll(state) {
      state.backdropDisplay = false
      state.receivePaymentDisplay = false
      state.sendPaymentDisplay = false
      state.createInvoiceDisplay = false
      state.addContactDisplay = false
      state.addPaymentDetailDisplay = false
      state.showPaymentDisplay = false
      state.showInvoiceDisplay = false
      state.editInvoiceDisplay = false
      state.showFileDisplay = false
      state.fileDisplayUrl = ""
      state.bulkUploaderDisplay = false
      state.invoiceActionDisplay = false
      state.invoiceActionDisplayMwebRec = false
      state.showBankDropDown = false
      state.invoiceTooltipDisplay = false
      state.reconcileModalDisplay = false
      state.purposeCodeDropDown = false
      state.addNewUserDisplay = false
      state.showUserDetails = false
      state.invoiceViewRecon = false
      state.tourStart = false
      state.downloadFiraMobile = false
      state.mobileInvoiceSuccess = false
      state.payoutAmountInfo = false
      state.createInvoiceModal = false
      state.createInvoiceModalRec = false
      state.createInvoiceModalRecDraft = false
      state.createInvoiceReceivePayment = false
      state.createInvoiceReceivePaymentRec = false
      state.createInvoiceReceivePaymentRecDraft = false
      state.showPreviewDisplay = false
      state.showDraftEditInvoice = false
      state.showVbanDeposits = false
      state.showSearchDataComp = false
      state.showHitComp = false
      state.updateContact = false
      state.chatBotDisplay = []
      state.chatBotHistory = null
    },
  },
})

export const modalActions = ModalSlice.actions;
