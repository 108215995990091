// import clsx from "clsx";
// import classes from "./ComplianceChecks.module.scss";
// import {Switch} from "antd";
import urlIcon from "../../../assets/Icons/urLIcon.svg";
import {Tooltip} from "antd";

const KeyDataHit = (props) => {

    // const uniqueSources = {};
    //
    // props.hitData.doc.fields.forEach(field => {
    //     if (field.source) {
    //         const sourceName = field.source;
    //         const fieldName = field.name;
    //         // const fieldValue = field.value;
    //         const fieldValues = field.value.split(',').map(value => value.trim());
    //
    //         if (!uniqueSources.hasOwnProperty(sourceName)) {
    //             uniqueSources[sourceName] = {};
    //         }
    //         if (!uniqueSources[sourceName].hasOwnProperty(fieldName)) {
    //             uniqueSources[sourceName][fieldName] = [];
    //         }
    //
    //         // if (!uniqueSources[sourceName][fieldName].includes(fieldValue)) {
    //         //     uniqueSources[sourceName][fieldName].push(fieldValue);
    //         // }
    //
    //         fieldValues.forEach(value => {
    //             if (!uniqueSources[sourceName][fieldName].includes(value)) {
    //                 uniqueSources[sourceName][fieldName].push(value);
    //                 // uniqueSources[sourceName][fieldName].add(value);
    //             }
    //         });
    //     }
    // });

    const newObject = {};

    (props.hitData?.doc?.fields || [])?.forEach(field => {
        const source = field.source;
        const fieldValues = field.value.split(',').map(value => value.trim());
        const amlTypes = props?.hitData?.doc?.source_notes[source]?.aml_types;
        if (amlTypes && amlTypes.length > 0) {
            const amlType = amlTypes[0]; // Get the first element of amlTypes array
            if (!newObject[amlType]) {
                newObject[amlType] = {};
            }
            if (!newObject[amlType][source]) {
                newObject[amlType][source] = {};
            }
            if (!newObject[amlType][source][field.name]) {
                newObject[amlType][source][field.name] = [];
            }
            // if (!newObject[amlType][source][field.name].includes(field.value)) {
            //     newObject[amlType][source][field.name].push(field.value);
            // }

            fieldValues.forEach(value => {
                if (!newObject[amlType][source][field.name].includes(value)) {
                    newObject[amlType][source][field.name].push(value);
                    // uniqueSources[sourceName][fieldName].add(value);
                }
            });
        }
    });

    // console.log('newObject',newObject);
    //
    // console.log('props.groupedStringsResult',props.groupedStringsResult);
    // console.log('props?.hitData?.doc',props?.hitData?.doc);
    // console.log('props?.hitData?.doc.aml_types',props?.hitData?.doc?.source_notes["complyadvantage-adverse-media"]?.aml_types[0]);
    //
    // console.log('Object.entries(uniqueSources)?.length',Object.entries(uniqueSources)?.length)
    return(
        <>
            <div className="flex flex-col gap-[32px] w-full">
                {/*Key Information*/}
                <div className="flex flex-col gap-[16px] w-full">
                    <p className="font-inter font-[600] text-[14px] md:text-[14px] leading-[20px] text-[#1E333F]">Key Information</p>
                    <div className="flex flex-col min-w-full justify-center rounded-[12px] px-[20px] py-[8px] gap-[12px] bg-[#FFFFFF]">
                        <div className="grid grid-cols-1 min-h-fit md:grid-cols-2 gap-x-[40px]">
                            {/*full name*/}
                            <div
                                className="flex flex-col border-b-[1px] border-[#ECEFF2] px-[0px] py-[16px] gap-[2px]">
                                <p className="font-inter font-[400] text-[12px] md:text-[12px] leading-[16px] text-[#1E333F66] uppercase">Full
                                    name</p>
                                <p className="font-inter font-[400] text-[12px] md:text-[14px] leading-[16px] text-[#1E333F]">{props.hitData?.doc?.name ? props.hitData?.doc?.name : "--"}</p>
                            </div>
                            {/*appears on*/}
                            <div
                                className="flex flex-col border-b-[1px] border-[#ECEFF2] px-[0px] py-[16px] gap-[2px]">
                                <p className="font-inter font-[400] text-[12px] md:text-[12px] leading-[16px] text-[#1E333F66] uppercase">APPEARS
                                    ON</p>
                                <div
                                    className="flex flex-wrap justify-start items-start md:justify-start gap-[10px] py-[2px]"
                                >
                                    {props.groupedStringsResult &&
                                        props.groupedStringsResult.map((group, groupIndex) => (
                                            <div key={groupIndex} className="flex flex-col gap-[5px]">
                                                <Tooltip
                                                    title={
                                                        ((group[0] === 'adverse-media' && group?.length > 2) || (group[0] !== 'adverse-media' && group?.length > 1)) &&
                                                        <div>
                                                            {(group[0] === 'adverse-media' || group[0] === 'pep') ?
                                                                group.slice(1, group?.length).map((item, index) => (
                                                                    <div
                                                                        key={index}
                                                                        className="px-[15px] py-[2px] text-[12px]"
                                                                    >
                                                                        {item &&
                                                                            (() => {
                                                                                let itemModified = '';
                                                                                if (item?.startsWith("adverse-media-v2")) {
                                                                                    itemModified = item?.slice("adverse-media-v2".length + 1)?.split('-').join(" ")
                                                                                } else {
                                                                                    itemModified = item?.split('-').join(" ")
                                                                                }


                                                                                const capitalized = itemModified
                                                                                    .replace(
                                                                                        /\b\w/g,
                                                                                        (char) =>
                                                                                            char.toUpperCase()
                                                                                    )
                                                                                    .replace(
                                                                                        /\bAml\b/g,
                                                                                        "AML"
                                                                                    )
                                                                                    .replace(
                                                                                        /\bCft\b/g,
                                                                                        "CFT"
                                                                                    );
                                                                                return capitalized.replace(
                                                                                    /Aml|Cft/g,
                                                                                    (abbr) =>
                                                                                        abbr.toUpperCase() + "/"
                                                                                )
                                                                            })()}
                                                                    </div>
                                                                )) :

                                                                group?.map((item, index) => (
                                                                    <div
                                                                        key={index}
                                                                        className="px-[15px] py-[2px] text-[12px]"
                                                                    >
                                                                        {item &&
                                                                            (() => {
                                                                                let itemModified = '';
                                                                                if (item?.startsWith("adverse-media-v2")) {
                                                                                    itemModified = item?.slice("adverse-media-v2".length + 1)?.split('-').join(" ")
                                                                                } else {
                                                                                    itemModified = item?.split('-').join(" ")
                                                                                }


                                                                                const capitalized = itemModified
                                                                                    .replace(
                                                                                        /\b\w/g,
                                                                                        (char) =>
                                                                                            char.toUpperCase()
                                                                                    )
                                                                                    .replace(
                                                                                        /\bAml\b/g,
                                                                                        "AML"
                                                                                    )
                                                                                    .replace(
                                                                                        /\bCft\b/g,
                                                                                        "CFT"
                                                                                    );
                                                                                return capitalized.replace(
                                                                                    /Aml|Cft/g,
                                                                                    (abbr) =>
                                                                                        abbr.toUpperCase() + "/"
                                                                                )
                                                                            })()}
                                                                    </div>
                                                                ))


                                                            }
                                                        </div>
                                                    }
                                                >
                                                    <div>
                                                        {(group?.length === 2 && group[0] === 'adverse-media') ?
                                                            group.slice(1, 2).map((item, index) => (
                                                                <div key={index}>
                                                                    <div
                                                                        className="flex items-center min-w-fit gap-[4px] border-[1px] border-[#FFACC0] justify-between rounded-[100px] pl-[8px] pt-[2px] pr-[2px] pb-[2px]">
                                                                        <p className="font-inter font-[400] text-[11px] leading-[16px] text-[#1E333F] uppercase">
                                                                            {item ? (item?.startsWith("adverse-media-v2") ? item?.slice("adverse-media-v2".length + 1)?.split('-').join(" ") : item?.split('-').join(" ")) : "--"}
                                                                        </p>
                                                                        <div
                                                                            className="flex items-center justify-center gap-[10px] px-[5px] bg-[#FFE8EE] w-[16px] h-[16px] rounded-[100px]">
                                                                            <p className="font-inter font-[400] text-[12px] leading-[16px] text-[#1E333F]">
                                                                                {(group[0] === 'adverse-media' || group[0] === 'pep') && group.length > 1 ? group.length - 1 : group.length}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))
                                                            :
                                                            group.slice(0, 1).map((item, index) => (
                                                                <div key={index}>
                                                                    <div
                                                                        className="flex items-center min-w-fit gap-[4px] border-[1px] border-[#FFACC0] justify-between rounded-[100px] pl-[8px] pt-[2px] pr-[2px] pb-[2px]">
                                                                        <p className="font-inter font-[400] text-[11px] leading-[16px] text-[#1E333F] uppercase">
                                                                            {item ? item.split("-").join(" ") : "--"}
                                                                        </p>
                                                                        <div
                                                                            className="flex items-center justify-center gap-[10px] px-[5px] bg-[#FFE8EE] w-[16px] h-[16px] rounded-[100px]">
                                                                            <p className="font-inter font-[400] text-[12px] leading-[16px] text-[#1E333F]">
                                                                                {(group[0] === 'adverse-media' || group[0] === 'pep') && group.length > 1 ? group.length - 1 : group.length}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))
                                                        }
                                                    </div>
                                                </Tooltip>
                                            </div>
                                        ))}
                                </div>
                            </div>

                            {/*as known as*/}
                            <div
                                className="flex flex-col border-b-[1px] border-[#ECEFF2] px-[0px] py-[16px] gap-[2px]">
                                <p className="font-inter font-[400] text-[12px] md:text-[12px] leading-[16px] text-[#1E333F66] uppercase">also
                                    known as</p>
                                <div className="flex flex-wrap gap-[10px] py-[2px] ">
                                    {props.hitData?.doc?.aka?.length ? props.hitData?.doc?.aka?.map((item, indexT) =>
                                            (
                                                <div
                                                    className=""
                                                    key={indexT}
                                                >
                                                    <p className="font-inter font-[400] text-[14px] leading-[16px] text-[#1E333F]">{`${item?.name} ${indexT < props.hitData?.doc?.aka?.length - 1 ? ' , ' : ''}`}</p>
                                                </div>
                                            ))
                                        :
                                        <p className="font-inter font-[400] text-[12px] leading-[16px] text-[#1E333F]">{`--`}</p>
                                    }
                                </div>
                            </div>

                            {/*countries*/}
                            <div className="flex flex-col border-b-[1px] border-[#ECEFF2] px-[0px] py-[16px] gap-[2px]">
                                <p className="font-inter font-[400] text-[12px] md:text-[12px] leading-[16px] text-[#1E333F66] uppercase">COUNTRIES</p>
                                <div
                                    className="flex flex-wrap gap-[10px] py-[2px]  ">
                                    {props.countryArray?.length ? props.countryArray?.map((country, indexC) =>
                                            (
                                                <div
                                                    className=""
                                                    key={indexC}
                                                >
                                                    <p className="font-inter font-[400] text-[14px] leading-[16px] text-[#1E333F]">{`${country} ${indexC < props.countryArray?.length - 1 ? ' , ' : ''}`}</p>
                                                </div>
                                            ))
                                        :
                                        <p className="font-inter font-[400] text-[12px] leading-[16px] text-[#1E333F]">{`--`}</p>
                                    }
                                </div>
                            </div>

                            {/*dob*/}
                            <div className="flex flex-col px-[0px] py-[16px] gap-[2px]">
                                <p className="font-inter font-[400] text-[12px] md:text-[12px] leading-[16px] text-[#1E333F66] uppercase">dob</p>
                                <div
                                    className="font-inter font-[400] text-[14px] leading-[20px] text-[#1E333F]">
                                    {(props.dobArray || [])?.length !== 0 ?
                                        (props.dobArray[2] ? new Date(props.dobArray).getDate() + ' ' : '') +
                                        (props.dobArray[1] ? new Date(props.dobArray).toLocaleString('default', {month: 'short'}) + ' ' : '') +
                                        (props.dobArray[0] ? new Date(props.dobArray).getFullYear() : '')
                                        : "--"}
                                </div>
                            </div>

                            {/*entity_type*/}
                            <div
                                className="flex flex-col px-[0px] py-[16px] gap-[2px]">
                                <p className="font-inter font-[400] text-[12px] md:text-[12px] leading-[16px] text-[#1E333F66] uppercase">entity
                                    type</p>
                                <p className="font-inter font-[400] text-[12px] md:text-[14px] leading-[16px] text-[#1E333F]">{props.hitData?.doc?.entity_type ? props.hitData?.doc?.entity_type?.slice(0, 1)?.toUpperCase() + props.hitData?.doc?.entity_type?.slice(1, props.hitData?.doc?.entity_type?.length)?.toLowerCase() : "--"}</p>
                            </div>

                        </div>
                    </div>
                </div>

                {/*Appears on*/}
                <div className="flex flex-col gap-[16px] w-full">
                    {/*top*/}
                    <div className="flex items-start justify-between">
                        {Object.entries(newObject)?.length !== 0 &&
                            <p className="font-inter font-[600] text-[14px] md:text-[14px] leading-[20px] text-[#1E333F]">Appears
                                on</p>
                        }
                        {/*<div className="flex items-center gap-[8px] py-[12px]">*/}
                        {/*    <p className="font-inter font-[500] text-[14px] md:text-[14px] leading-[20px] text-[#1E333F]">Show all details</p>*/}
                        {/*    <Switch*/}
                        {/*        */}
                        {/*    />*/}
                        {/*</div>*/}
                    </div>

                    {/*bottom*/}
                    <div className="flex flex-col gap-[24px]">
                        {Object.keys(newObject).map((amlType) => (
                            <div className="flex flex-col relative" key={amlType}>
                                <div className="flex px-[20px] absolute top-[-10px]">
                                    <div
                                        className="flex items-center gap-[4px] rounded-[100px] py-[2px] pl-[10px] pr-[4px] bg-[#FF658A]">
                                        <p className="font-inter font-[500] text-[12px] md:text-[12px] leading-[16px] text-[#FFFFFF] uppercase">{amlType ? amlType.split('-').join(" ") : "--"}</p>
                                        <div
                                            className="flex items-center justify-center w-[15px] h-[15px] rounded-full px-[5px] bg-[#FFE8EE]">
                                            <p className="font-inter font-[500] text-[12px] md:text-[12px] leading-[16px] text-[#FF658A] uppercase">{Object.keys(newObject[amlType])?.length}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex flex-col rounded-[12px] p-[20px] gap-[12px] bg-[#FFFFFF] w-full">
                                    {Object.keys(newObject[amlType]).map(source => (
                                        <div className="flex rounded-[12px] px-[20px] py-[4px] bg-[#F4F8FF] w-full">
                                            <div className="grid w-full grid-cols-1 md:grid-cols-2 gap-x-[40px]">
                                                {/* key value box */}
                                                <div
                                                    className="flex items-start justify-between min-w-full border-b-[1px] border-[#ECEFF2] px-[0px] py-[16px] gap-[2px]"
                                                    key={source}>
                                                    <div className="flex flex-col gap-[2px]">
                                                        <p className="font-inter font-[400] text-[12px] md:text-[12px] leading-[16px] text-[#1E333F66] uppercase">{"currently on"}</p>
                                                        <div className="flex flex-wrap gap-[4px]"
                                                             style={{overflowWrap: "anywhere"}}>
                                                            <p className="font-inter font-[400] min-w-fit text-[12px] md:text-[14px] leading-[16px] text-[#1E333F]">{source ? source.split('-').join(" ").replace(/\b\w/g, char => char.toUpperCase()) : "--"}</p>
                                                        </div>

                                                    </div>
                                                </div>

                                                {Object.entries(newObject[amlType][source]).map(([name, value]) => (
                                                    <div
                                                        className="flex items-start justify-between min-w-full border-b-[1px] border-[#ECEFF2] px-[0px] py-[16px] gap-[2px]"
                                                        key={name}>
                                                        <div className="flex flex-col gap-[2px]">
                                                            <p className="font-inter font-[400] text-[12px] md:text-[12px] leading-[16px] text-[#1E333F66] uppercase">{name}</p>

                                                            {name?.toLowerCase().includes('country') ?
                                                                <div className="flex flex-wrap gap-[4px]"
                                                                     style={{overflowWrap: "anywhere"}}>
                                                                    {
                                                                        value.map((val, index) => (
                                                                            <p key={index}
                                                                               className="font-inter font-[400] min-w-fit text-[12px] md:text-[14px] leading-[16px] text-[#1E333F]">
                                                                                {`${val} ${index < value.length - 1 ? ' , ' : ''}`}
                                                                            </p>
                                                                        ))
                                                                    }
                                                                </div>

                                                                :

                                                                <div className="flex flex-wrap gap-[4px]"
                                                                     style={{overflowWrap: "anywhere"}}>
                                                                    {value.map((val, index) => (
                                                                        <p key={index}
                                                                           className="font-inter font-[400] min-w-fit text-[12px] md:text-[14px] leading-[16px] text-[#1E333F]">{val ? val : "--"}</p>
                                                                    ))}
                                                                </div>
                                                            }

                                                        </div>
                                                        {name?.toLowerCase().includes('url') && (
                                                            <div className="flex flex-col gap-[4px]">
                                                                {value.map((val, index) => (
                                                                    <div key={index}
                                                                         className="flex items-center justify-center rounded-full p-[10px] w-[36px] h-[36px] bg-[#FFFFFF]">
                                                                        <a href={val} target="_blank"
                                                                           rel="noopener noreferrer">
                                                                            <img src={urlIcon} alt="urlIcon"
                                                                                 style={{
                                                                                     width: "36px",
                                                                                     height: "36px"
                                                                                 }}/>
                                                                        </a>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        )}
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>

                </div>
            </div>
        </>
    )
}

export default KeyDataHit;