// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.InvoiceDetailsModal_modal__XGBr- {
  z-index: var(--level_8);
  height: calc(100vh - var(--admin_header_length));
}

.InvoiceDetailsModal_invoiceModalContainer__l71MV {
  position: relative;
}

.InvoiceDetailsModal_backdrop__GM0MU {
  z-index: var(--level_7);
  height: calc(100vh - var(--admin_header_length));
}

.InvoiceDetailsModal_create_invoice__K2DpT {
  height: 90vh;
}

.InvoiceDetailsModal_dateField__x2wIF:invalid::-webkit-datetime-edit {
  color: #fff;
}

.InvoiceDetailsModal_error__VsGlx {
  color: #ff6868;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 1.8rem;
  margin-top: 0.8rem;
  top: 5.6rem;
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/Components/molecule/InvoiceDetailsModal/InvoiceDetailsModal.module.scss"],"names":[],"mappings":"AAAA;EACE,uBAAA;EACA,gDAAA;AACF;;AAQA;EACE,kBAAA;AALF;;AAQA;EACE,uBAAA;EACA,gDAAA;AALF;;AAQA;EACE,YAAA;AALF;;AAQA;EACE,WAAA;AALF;;AAQA;EACE,cAAA;EACA,gBAAA;EACA,iBAAA;EACA,mBAAA;EACA,kBAAA;EACA,WAAA;EACA,WAAA;AALF","sourcesContent":[".modal {\n  z-index: var(--level_8);\n  height: calc(100vh - var(--admin_header_length));\n}\n\n// @media (max-width: 540px) {\n//   .modal {\n//     width: 100%;\n//   }\n// }\n\n.invoiceModalContainer {\n  position: relative;\n}\n\n.backdrop {\n  z-index: var(--level_7);\n  height: calc(100vh - var(--admin_header_length));\n}\n\n.create_invoice {\n  height: 90vh;\n}\n\n.dateField:invalid::-webkit-datetime-edit {\n  color: #fff;\n}\n\n.error {\n  color: #ff6868;\n  font-weight: 400;\n  font-size: 1.2rem;\n  line-height: 1.8rem;\n  margin-top: 0.8rem;\n  top: 5.6rem;\n  width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": `InvoiceDetailsModal_modal__XGBr-`,
	"invoiceModalContainer": `InvoiceDetailsModal_invoiceModalContainer__l71MV`,
	"backdrop": `InvoiceDetailsModal_backdrop__GM0MU`,
	"create_invoice": `InvoiceDetailsModal_create_invoice__K2DpT`,
	"dateField": `InvoiceDetailsModal_dateField__x2wIF`,
	"error": `InvoiceDetailsModal_error__VsGlx`
};
export default ___CSS_LOADER_EXPORT___;
