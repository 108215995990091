import { useEffect, useState } from 'react'
import clsx from 'clsx'

import classes from './CustomerStatusTag.module.scss'
import InfoIcon from 'assets/Icons/info_brown_circle.svg'
import XCircle from 'assets/Icons/x-circle.svg'
import AlertCircle from 'assets/Icons/alert-circle.svg'
import EditWhiteIcon from 'assets/Icons/edit-white.svg'

const getStatusDetails = (status) => {
  switch (status.split('_').join('').toLowerCase()) {
    case 'pendingverification':
      return {
        icon: EditWhiteIcon,
        title: 'UNDER REVIEW',
        bgColor: '#FFB800',
        textColor: '#FFFFFF',
      }
    case 'verificationfailed':
      return {
        icon: XCircle,
        title: 'REJECTED',
        bgColor: '#D56F6F',
        textColor: '#FFEFEF',
      }
    default:
      return {
        // icon: EditWhiteIcon,
        // title: status,
        // bgColor: '#FFB800',
        // textColor: '#ffffff',
      }
  }
}

const CustomerStatusTag = (props) => {
  let { status } = props
  if (status?.toLowerCase() === 'in review') {
    status = 'created'
  } else if (status?.toLowerCase() === 'created') {
    status = 'verified'
  }
  const [statusDetails, setStatusDetails] = useState(
    getStatusDetails(status || '')
  )

  useEffect(() => {
    setStatusDetails(getStatusDetails(status || ''))
  }, [props])

  return (
    <div
      className={clsx(
        'px-[8px] min-h-[20px] rounded-full flex flex-row items-center w-fit',
        props.className
      )}
      style={{
        backgroundColor: statusDetails?.bgColor
          ? statusDetails?.bgColor
          : 'inherit',
      }}
    >
      {statusDetails?.icon && (
        <img
          className="w-[16px] aspect-square mr-1"
          src={statusDetails?.icon}
          alt="icon"
        />
      )}
      <div className={clsx(classes.text)}>
        <p
          className="text-white w-max -md:text-xl -sm:text-2xl"
          style={{
            color: statusDetails?.textColor ? statusDetails?.textColor : '#fff',
          }}
        >
          {statusDetails?.title}
        </p>
      </div>
    </div>
  )
}

export default CustomerStatusTag
