import classes from "Components/ReportTable/ActionWithOutIcon.module.scss";
// import actionIcon from "assets/Images/action-icon.svg";
// import { useState, useCallback, useEffect } from "react";
import { modalActions } from "Store/modal-redux";
import { useDispatch, useSelector } from "react-redux";
import useClickOutside from "hooks/useClickOutside";
import Close from "Layout/Close";
import ReactDOM from "react-dom";
import Backdrop from 'Layout/Backdrop'
import clsx from 'clsx'
// import dayjs from 'dayjs'

const ActionWithOutIcon = (props) => {
  const { cellData, option = [] } = props
  const dispatch = useDispatch();
  const invoiceActionDisplay = useSelector((state) => state.modal.invoiceActionDisplay);
  // const [showOption, setShowOption] = useState(false)
  // const [notShowFiraButton, setNotShowFiraButton] = useState(false)
  const optRef = useClickOutside(() => dispatch(modalActions.invoiceActionDisplay(true)))
  const handleOptionClick = (option) => {
    if (option?.onClick) option?.onClick(cellData)
    dispatch(modalActions.invoiceActionDisplay(true))
  }

  const status = cellData?.customer_detail?.verificationStatus;
  // const receivablesCustomerStatus = cellData?.status?.split(' ')?.join('')?.toLowerCase();

  return (
    <>
      {invoiceActionDisplay &&
        ReactDOM.createPortal(
          <Backdrop className={classes.backdrop} />,
          document.getElementById('backdrop-root')
        )}
      <div ref={props.optRef} className={`${classes.action} ${props.className}`} onClick={()=>dispatch(modalActions.invoiceActionDisplay(false))}>
        {invoiceActionDisplay && (
          <div className={classes.action_all}>
            <div className={classes.header}>
              <div className={classes.heading}>Receive Payment</div>
              <Close
                onClick={() => dispatch(modalActions.invoiceActionDisplay(false))}
                className={classes.close}
              />
            </div>
            <div className={classes.options}>
              {option?.map((opt, index) => (
                <div
                  className={clsx(
                    classes.opt,
                    !opt.onClick && 'opacity-50',
                    'cursor-pointer',
                    index && 'border-t border-[#ECEFF2]',
                    // opt.text === 'Receive Payment' && 
                    opt.text === 'Receive Payment' &&  (status === ('PENDING_VERIFICATION') || status === 'VERIFICATION_FAILED')  && 'opacity-50',
                  )}
                  key={`${opt.text}-${index}`}
                  onClick={() => handleOptionClick(opt)}
                >
                  <img
                    src={opt.icon}
                    alt={`${opt.icon}-icon`}
                    className={classes.opt_icon}
                  />
                  <div className={classes.text}>{opt.text}</div>
                </div>
              ))}
            </div>
          </div>
        )}
        {/* <img
          src={actionIcon}
          alt="action-icon"
          className={clsx(
            classes.icon,
            invoiceActionDisplay && classes.icon_active,
            'h-12'
          )}
          onClick={() => setShowOption((prev) => !prev)}
          dispatch(modalActions.invoiceActionDisplay()
        /> */}
      </div>
    </>
  )
}
export default ActionWithOutIcon;
