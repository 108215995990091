import Button from "Layout/Button";
import MobileInputSimple from "Layout/MobileInputSimple";
import TextInput from "Layout/TextInput";
import { useState, useEffect, useRef} from "react";
import classes from "./SignUp.module.scss";
import { Form, Link, useNavigate } from "react-router-dom";
import clsx from "clsx";
import UseApiCall from "hooks/useApiCall";
import { apiSignUp } from "services";
import CustomSelector from "Components/atom/CustomSelector/CustomSelector";
import { COUNTRIES } from "lib/data/countries";
import { useSnackbar } from 'notistack'

const emailValidation = [
  (value) => value.trim() !== "",
  (value) => /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value)
];

// (value) => /[a-zA-z0-9_\.\-]+[@][a-z]+[\.][a-z]+/.test(value),

const Form1 = (props) => {
  const { setFormData, formData, setPage, setApplicationId , formSubmitHandler, loader, apiError} = props;
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar()

  const [firstName, setFirstName] = useState(formData?.firstName || "");
  const [lastName, setLastName] = useState(formData?.lastName || "");
  const [email, setEmail] = useState(formData?.email || "");
  const [countryCode, setCountryCode] = useState(
    formData?.countryCode || "+91"
  );
  const [mobile, setMobile] = useState(formData?.mobileNumber || "");
  const [businessName, setBusinessName] = useState(formData?.businessName);
  const [businessCountry, setBusinessCountry] = useState(  {
    title: 'India',
    value: 'IN',
  });
  const [tickCheckbox, setTickCheckbox] = useState(false);
  const [isBusinessNameValid, setIsBusinessNameValid] = useState(false);
  const [isBusinessCountryValid, setIsBusinessCountryValid] = useState(false);

  const [isFirstNameValid, setIsFirstNameValid] = useState(false);
  const [isLastNameValid, setIsLastNameValid] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isMobileValid, setIsMobileValid] = useState(false);
  const [key, setKey] = useState(false);
  const [hasErrorCheckbox, setHasErrorCheckbox] = useState(false);
  const [oneTimeClicked, setOneTimeClicked] = useState(false);
  const [signUpError , setSignUpError] = useState(false);
  const inputErrorScroll = useRef(null);
  const focusRef = useRef(null);
  const noneRef = useRef(null);
 
  const handleInputError = () =>{
    if(inputErrorScroll.current) {
      inputErrorScroll.current.scrollIntoView({
        behavior: 'smooth' , 
        block: 'start' ,
        inline: 'start',
      });
    }
  }

  const handleFocus = () =>{
    if(focusRef.current) {
      setSignUpError(false)
      focusRef.current.focus()
    }
  }

  useEffect(() => {
    if (formData?.firstName !== '' && formData?.firstName !== undefined) {
      setFirstName(formData?.firstName || "");
      setLastName(formData?.lastName || "");
      setEmail(formData?.email || "");
      (formData?.countryCode !== '' && formData?.countryCode !== undefined) ? setCountryCode(`${formData?.countryCode}`) : setCountryCode(`+91`);
      setMobile(formData?.mobileNumber || "");
      setBusinessName(formData?.businessName || "");
      // setBusinessCountry(formData?.businessCountry || "");
      // setTickCheckbox(true);
    }
  }, [formData]);

  // useEffect(() => {
  //   setKey(!key);
  // }, [tickCheckbox])

  // const [signUp, signingUp] = UseApiCall(apiSignUp, (res) => {
  //   setApplicationId(res.data.applicationId)
  // });

  // const [signUp, signingUp] = UseApiCall(apiSignUp, (res) => {
  //   window.open(
  //     res?.data?.redirectionUrl || "https://eu.jotform.com/231081680908356",
  //     "_self"
  //   );
  // });

  // const formSubmitHandler1 = (props) => {
  //   signUp({
  //     ...props,
  //   });
  // };

  // const handleCountryChange = (prop) => {
  //   setBusinessCountry(prop);
  // };

  const handleSubmit = (e) => {
    e.preventDefault();

    // if (!tickCheckbox) {
    //   setHasErrorCheckbox(true);
    // }

    setOneTimeClicked(true);

    if (isValid()) {
      if (setFormData)
        setFormData((pre) => {
          return {
            ...pre,
            firstName,
            lastName,
            mobileNumber: mobile,
            email,
            countryCode,
            businessName,
            businessCountry,
          };
        });
      // if (setPage) setPage(1);

      if (formSubmitHandler){
        formSubmitHandler({
          firstName : firstName,
          lastName : lastName,
          email : email,
          countryCode : countryCode?.slice(1),
          mobileNumber : mobile,
          businessName: businessName,
          businessCountry: businessCountry?.value,
        });
      }
    } else {
      setSignUpError(true)
      handleInputError()
      handleFocus()
      try {
        enqueueSnackbar('Please fill all the required fields!', { variant: 'error' })
      } catch (error) {}
    }
  };

  // console.log(formData)

  const isValid = () => {
    if (
      !isEmailValid ||
      !isMobileValid ||
      !isFirstNameValid ||
      !isLastNameValid ||
      !isBusinessNameValid
      // !isBusinessCountryValid ||
      // !tickCheckbox
    )
      return false;
    return true;
  };

  return (
    <>
      <div className={clsx(classes.header, "mb-12")}>
        {/* Sign up on <br className="sm:hidden" />
        Fideo for free! */}
        Sign up on Fideo!
      </div>
      <Form className={classes.form}>
        <div ref={!isFirstNameValid ? inputErrorScroll : noneRef}>
          <TextInput
            type="text"
            text="First Name*"
            className={classes.input}
            errorMessage="Please enter a valid first name"
            validation={[(value) => (value ? value.trim() !== "" : false)]}
            inputValidity={(value) => {
              setIsFirstNameValid(value);
            }}
            setInput={(value) => {
              setFirstName(value);
            }}
            enteredInput={firstName}
            hasError={oneTimeClicked && firstName === ""}
            maxLength={128}
            initiateFocus = {!isFirstNameValid}
            focusRef = {focusRef}
            noneRef = {noneRef}
          />
        </div>
        <div ref={(isFirstNameValid && !isLastNameValid) ? inputErrorScroll : noneRef}>
          <TextInput
            type="text"
            text="Last Name*"
            className={classes.input}
            errorMessage="Please enter a valid last name"
            validation={[(value) => (value ? value.trim() !== "" : false)]}
            inputValidity={(value) => {
              setIsLastNameValid(value);
            }}
            setInput={(value) => {
              setLastName(value);
            }}
            enteredInput={lastName}
            hasError={oneTimeClicked && lastName === ""}
            maxLength={128}
            initiateFocus = {isFirstNameValid && !isLastNameValid}
            focusRef = {focusRef}
            noneRef = {noneRef}
          />
        </div>
        <div ref={(isFirstNameValid && isLastNameValid && !isEmailValid) ? inputErrorScroll : noneRef}>
          <TextInput
            type="text"
            text="Email Address*"
            className={classes.input}
            errorMessage="Please enter a valid email address"
            validation={emailValidation}
            inputValidity={(value) => {
              setIsEmailValid(value);
            }}
            setInput={(value) => {
              setEmail(value);
            }}
            enteredInput={email}
            // hasError = {wrongInput && loginType === 'email' && !isEmailValid}
            hasError={oneTimeClicked && email === ""}
            maxLength={128}
            initiateFocus = {isFirstNameValid && isLastNameValid && !isEmailValid}
            focusRef = {focusRef}
            noneRef = {noneRef}
          />
        </div>
        <div ref={(isFirstNameValid && isLastNameValid && isEmailValid && !isMobileValid) ? inputErrorScroll : noneRef}>
          <MobileInputSimple
            className={classes.input}
            // hasError = {wrongInput && loginType === 'mobile' && !isMobileValid}
            hasError={oneTimeClicked && mobile === ""}
            mobileCodeInput={countryCode}
            isRequired={true}
            mobileNumberInput={mobile}
            setMobileNumberInput={(value) => setMobile(value)}
            setMobileValidity={(value) => setIsMobileValid(value)}
            setMobileCodeInput={(value) => setCountryCode(value)}
            inputValidity={(value) => {
              setIsMobileValid(value);
            }}
            initiateFocus = {isFirstNameValid && isLastNameValid && isEmailValid && !isMobileValid}
            focusRef = {focusRef}
            noneRef = {noneRef}
          />
        </div>
        <div ref={(isFirstNameValid && isLastNameValid && isEmailValid && isMobileValid && !isBusinessNameValid) ? inputErrorScroll : noneRef}>
          <TextInput
            type="text"
            text="Your Business Name*"
            className={classes.input}
            errorMessage="Please enter a valid business name"
            validation={[(value) => (value ? value.trim() !== "" : false)]}
            inputValidity={(value) => {
              setIsBusinessNameValid(value);
            }}
            setInput={(value) => {
              setBusinessName(value);
            }}
            enteredInput={businessName}
            hasError={oneTimeClicked && businessName === ""}
            maxLength={128}
            initiateFocus = {isFirstNameValid && isLastNameValid && isEmailValid && isMobileValid && !isBusinessNameValid}
            focusRef = {focusRef}
            noneRef = {noneRef}
          />
        </div>
        {/* <div className="w-full mb-12">
          <CustomSelector
            onChange={handleCountryChange}
            selectedValue={businessCountry}
            items={COUNTRIES}
            validation={[(value) => !!value]}
            inputValidity={(value) => {
              setIsBusinessCountryValid(value);
            }}
            placeholder='Country of Registration'
            errorMessage="Please enter a valid business country"
          />
        </div> */}
        {/* <div className={classes.terms}style={{ 
              marginBottom: (hasErrorCheckbox && !tickCheckbox) ? "0" : "2.4rem",
            }}>
          <input 
            className={classes.termscheckbox}
            checked={tickCheckbox}
            type="checkbox"
            style={{ 
              // accentColor: hasErrorCheckbox ? "#FF6868" : "black",
            }}
            onChange={(e) => setTickCheckbox(e.target.checked)}
          />
          I agree to Fideo’s <a href="https://drive.google.com/file/d/1Xf-kSM4yirQEJBPj9s177fQkW3Ez1jSo/view" target="_blank" className="font-semibold underline">Terms and conditions</a> and <a href="https://drive.google.com/file/d/1s38FbMwF8ol9KuKyBLOzbIlRwGJ2LVRT/view" target="_blank" className="font-semibold underline">privacy policy</a>
        </div>
        {(hasErrorCheckbox && !tickCheckbox) && (
          <div className={classes.error}>
            You must agree to the terms and conditions
          </div>
        )} */}
        <Button
          text="Continue"
          disabled={loader}
          className={clsx(classes.btn, 
            // !isValid() && classes.disabled
            )}
          loader={loader}
          apiError={apiError}
          onClick={handleSubmit}
        >
        </Button>
      </Form>
      <div className={classes.footer}>
        Already have an account?{" "}
        <Link to="/" className={classes.link}>
          Sign in
        </Link>
      </div>
    </>
  );
};

export default Form1;
