import React from 'react';
import classes from 'Components/ReportTable/CustomerReportTable.module.scss'
import clsx from 'clsx'
import { formatNumberToLocaleString } from 'hooks/formatNumberToLocaleString';


const CustomerInvoiceTotal = ({data,category}) => {
    const output = data?.invoice_summary || [];

    const currencyOrder = ['USD', 'EUR', 'GBP']

    const sortedData = output.filter((item) =>
      currencyOrder.includes(item.currency)
    )

    // Sort the invoiceSummary array based on the currency order
    const sortedInvoiceSummary = sortedData.sort((a, b) => {
      return (
        currencyOrder.indexOf(a.currency) - currencyOrder.indexOf(b.currency)
      )
    })
    const totalInvoice = data?.invoice_summary?.reduce((prev, curr) => {
        return prev + (curr?.[category]?.number_of_invoice || 0);
      }, 0);

  return (
    <div>
        {sortedInvoiceSummary.map((item, index) => (
            <div className={clsx(classes.currencySymbolInvoice)}>
              <span className={clsx(classes.currencySymbolSign)}>{item?.[category]?.amount>0 && item?.[category]?.currency && item?.[category]?.currency?.sign}</span>
              <span className={clsx(classes.currencySymbolAmt)}>{item?.[category]?.amount ? formatNumberToLocaleString((item?.[category]?.amount), 2, 2) : '-'}</span>
          </div>
        ))}
        <div className={classes.InvoiceNumber}>
        {`${totalInvoice} Invoice${totalInvoice >= 2 ? 's' : ''}`}
      </div>
    </div>
  )
}

export default CustomerInvoiceTotal