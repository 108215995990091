import clsx from 'clsx';
import classes from './ButtonSet.module.scss'
import Button from 'Layout/Button'

const ButtonSet = (props) => {
    const {arrBtn} = props;
    return (
        <div className={classes.button}>
        {arrBtn.map((item) => (
          <Button
            key={item.text}
            text={item.text}
            icon={item.icon}
            onClick={item.onClick}
            btnClass={clsx(classes.btn, !item.active && 'opacity-30 cursor-not-allowed border-[#060a0d1f] border-solid border-[1px]', item.isVisible || 'hidden', item.className)}
            disabled={!item.active}
            btnClassIcon={clsx(!item.active, !item.active && '')}
            btnClassText={clsx(!item.active, item.btnClassText, !item.active && 'text-[#bfc2ca]')}
          />
        ))}
      </div>
    )
}
export default ButtonSet;