import Action from 'Components/ReportTable/Action'
import ContactInfoNew from 'Components/Global/ContactInfoNew'
import classes from 'Components/ReportTable/MobileReceivableReportTable.module.scss'
import Status from './Status'
import ReportTableLayout from 'Components/ReportTable/ReportTableLayout'
import PaymentStatusTag from './PaymentStatusTag'
import clsx from 'clsx'
import { useState } from 'react'
import { formatNumberToLocaleString } from 'hooks/formatNumberToLocaleString';
import Shimmer from 'Layout/Shimmer';
import { Pagination } from 'antd'

const MobileReceivableReportTable = (props) => {
  const { option } = props;
  const [status, setStatus] = useState(true)

  const handleClick = () => {
    setStatus((p) => !p)
  }

  if (props.tableData?.length > 0)
    return (
      <div>
        <ReportTableLayout className={`${classes.table} ${props.className}`}>
          {props.tableData.map((data, index) => {
            const {
              amount,
              status,
              customer,
              invoiceId,
              invoiceAmount,
              receivingAmount,
              invoiceCurrency,
              id,
            } = data
            const { emailAddress, customerName, country, countryName } = customer || {}
            return (
              <div key={`${data.email}-${index}`} className={classes.row} style={{ display: props?.mobileFilterOpen || props?.mobileSortOpen ? 'none' : 'block' }}>
                <div className={classes.infoOne} onClick={handleClick}>
                  <div className={classes.invoice}
                    onClick={() => {
                      if (data?.status.toLowerCase() !== 'in draft') props.handleInvoiceSelection(id);
                    }}
                  >{invoiceId}</div>
                  <Action
                    option={
                      data?.status.toLowerCase() === 'partially paid'
                        ?
                        [...option.slice(1, 5)]
                        :
                        (
                          data?.status.toLowerCase() === 'in draft'
                            ? [...option.slice(0, 5)]
                            :
                            (
                              data?.status.toLowerCase() === 'in review' ||
                                data?.status.toLowerCase() === 'created' ||
                                data?.status.toLowerCase() === 'link sent' ||
                                data?.status.toLowerCase() === 'in progress'
                                ? [...option.slice(1, 5)]
                                : ((data?.status?.toLowerCase() === 'settled') ? [...option.slice(1, 4)] : [...option.slice(1, 4)])
                            )
                        )
                    }
                    cellData={data}
                    className={classes.action}
                  />
                </div>
                <div className={classes.infoTwo}>
                  <ContactInfoNew
                    name={customerName}
                    country={country}
                    countryName={countryName}
                    email={emailAddress}
                    className={classes.contact}
                  />
                  <div style={{
                    // display: "flex", 
                    // flexDirection: "column", justifyContent: "space-between" 
                  }}>
                    <div className={classes.infoThree}>
                      <div className={clsx(classes.amount, 'mb-1 pr-[8px]')}>
                        {invoiceCurrency?.sign}
                        {formatNumberToLocaleString((receivingAmount), 2, 2)}
                      </div>
                    </div>
                    <div className="mt-[2px] flex justify-end">
                      {status && <PaymentStatusTag status={status} type="light" />}
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
        </ReportTableLayout>
        {
          <div className={classes.paginationWrapMobile}
            style={{ margin: '-70px 0px 100px 0px' }}
          >
            <Pagination showSizeChanger={false} current={props.current} total={props.total} defaultPageSize={30} itemRender={props.itemRender} onChange={props.handlePaginate} showLessItems size='default' />
          </div>
        }
      </div>

    )
  return (
    <div
      style={{
        textAlign: 'center',
        width: '100%',
        marginTop: '4rem',
        fontSize: '2.5rem'
      }}
      className={`${classes.table}`}
    >
      {
        props.loading ?
          <>
            <Shimmer className="w-[100%] mb-[10px] h-[100px]" />
            <Shimmer className="w-[100%] mb-[10px] h-[100px]" />
            <Shimmer className="w-[100%] h-[100px]" />
          </>
          :
          "No Recent Transactions Yet."
      }
    </div>
  )
}

export default MobileReceivableReportTable
