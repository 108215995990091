// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CountryDropdown_dropdown__GUrJJ {
  overflow: scroll;
  position: absolute;
}
.CountryDropdown_dropdown__GUrJJ .CountryDropdown_set__lgtky {
  margin-top: 2rem;
  margin-bottom: 0.4rem;
}
.CountryDropdown_dropdown__GUrJJ .CountryDropdown_set__lgtky .CountryDropdown_header__EjQsJ {
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 1.8rem;
  text-transform: uppercase;
  color: rgba(30, 51, 63, 0.4);
  padding-left: 2rem;
  margin-bottom: 1.2rem;
}`, "",{"version":3,"sources":["webpack://./src/Components/Dropdown/CountryDropdown.module.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,kBAAA;AACJ;AAEI;EAEI,gBAAA;EACA,qBAAA;AADR;AAGQ;EAEI,gBAAA;EACA,iBAAA;EACA,mBAAA;EACA,yBAAA;EACA,4BAAA;EACA,kBAAA;EACA,qBAAA;AAFZ","sourcesContent":[".dropdown{         \n    overflow: scroll;\n    position: absolute;\n    \n\n    .set{\n        \n        margin-top: 2rem;\n        margin-bottom: 0.4rem;\n\n        .header{\n            \n            font-weight: 600;\n            font-size: 1.2rem;\n            line-height: 1.8rem;\n            text-transform: uppercase;\n            color: rgba(30, 51, 63, 0.4);\n            padding-left: 2rem;\n            margin-bottom: 1.2rem;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropdown": `CountryDropdown_dropdown__GUrJJ`,
	"set": `CountryDropdown_set__lgtky`,
	"header": `CountryDropdown_header__EjQsJ`
};
export default ___CSS_LOADER_EXPORT___;
