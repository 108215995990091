// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Admin_admin_header__SsOYZ {
  height: var(--admin_header_length);
}
@media (max-width: 640px) {
  .Admin_admin_header__SsOYZ {
    display: none;
  }
}

.Admin_admin_root__ePfMS {
  display: flex;
}
@media (max-width: 640px) {
  .Admin_admin_root__ePfMS .Admin_sideBar__\\+qah2 {
    display: none;
  }
  .Admin_admin_root__ePfMS .Admin_sideBar_able__n99ox {
    display: block;
    position: absolute;
    background-color: white;
    height: 100vh;
    z-index: var(--level_3);
    position: fixed;
  }
}
.Admin_admin_root__ePfMS .Admin_outlet__dWzO1 {
  padding: var(--gap_1) var(--gap_2);
  background-color: var(--admin_backgroundColor);
  flex: 1 1;
  height: calc(100vh - var(--admin_header_length));
}
@media (max-width: 640px) {
  .Admin_admin_root__ePfMS .Admin_outlet__dWzO1 {
    padding: 0 2rem;
    height: max-content;
    min-height: 100vh;
  }
}

.Admin_backdrop__nLdix {
  z-index: 100;
  top: -7.2rem;
  background: rgba(6, 10, 13, 0.2);
  height: calc(100vh + var(--admin_header_length));
}`, "",{"version":3,"sources":["webpack://./src/Layout/Admin/Admin.module.scss"],"names":[],"mappings":"AAAA;EACI,kCAAA;AACJ;AACI;EAHJ;IAIQ,aAAA;EAEN;AACF;;AACA;EACI,aAAA;AAEJ;AACQ;EADJ;IAEQ,aAAA;EAEV;EAAU;IACI,cAAA;IACA,kBAAA;IACA,uBAAA;IACA,aAAA;IACA,uBAAA;IACA,eAAA;EAEd;AACF;AASI;EACI,kCAAA;EACA,8CAAA;EACA,SAAA;EACA,gDAAA;AAPR;AAUQ;EAPJ;IAQQ,eAAA;IACA,mBAAA;IACA,iBAAA;EAPV;AACF;;AAWA;EACI,YAAA;EACA,YAAA;EACA,gCAAA;EACA,gDAAA;AARJ","sourcesContent":[".admin_header{\n    height: var(--admin_header_length);\n\n    @media (max-width:640px) {\n        display: none;\n    }\n}\n\n.admin_root{\n    display: flex;\n\n    .sideBar{\n        @media (max-width:640px) {\n            display: none;\n            \n            &_able{\n                display: block;\n                position: absolute;\n                background-color: white;\n                height: 100vh;\n                z-index: var(--level_3);\n                position: fixed;\n            }\n        }\n\n        // @media (max-width:1210px) and (min-width:640px) {\n        //     // position: absolute;\n        //     background-color: white;\n        //     z-index: var(--level_3);\n        // }\n\n    }\n\n    .outlet{\n        padding: var(--gap_1) var(--gap_2);\n        background-color: var(--admin_backgroundColor);\n        flex: 1;\n        height: calc(100vh - var(--admin_header_length));\n        // overflow: scroll;\n\n        @media (max-width: 640px) {\n            padding: 0 2rem;\n            height: max-content;\n            min-height: 100vh;\n        }\n    }\n}\n\n.backdrop {\n    z-index: 100;\n    top: -7.2rem;\n    background: #060a0d33;\n    height: calc(100vh + var(--admin_header_length));\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"admin_header": `Admin_admin_header__SsOYZ`,
	"admin_root": `Admin_admin_root__ePfMS`,
	"sideBar": `Admin_sideBar__+qah2`,
	"sideBar_able": `Admin_sideBar_able__n99ox`,
	"outlet": `Admin_outlet__dWzO1`,
	"backdrop": `Admin_backdrop__nLdix`
};
export default ___CSS_LOADER_EXPORT___;
