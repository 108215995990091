import React, { useEffect, useRef } from 'react';
import classes from 'Components/ReportTable/CustomerOverview.module.scss'
import clsx from 'clsx'
import Shimmer from 'Layout/Shimmer'
import { Tag } from 'antd'
import ReportTableLayout from './ReportTableLayout';
import ContactInfo from 'Components/Global/ContactInfo';
import { formatNumberToLocaleString } from 'hooks/formatNumberToLocaleString';
import { DownloadOutlined } from '@ant-design/icons';
import MobileCustomerOverview from './MobileCustomerOverview';

const currencySign = {
    // "usd" : {icon : },
    // "eur" : {icon : },
    // "gbp" : {icon : }
}

const CustomerOverview = (props) => {
    const theadRef = useRef(null);
    const tbodyRef = useRef(null);
    // const usdLength = props?.customerData?.currencyWise?.usd?.length;
    // const eurLength = props?.customerData?.currencyWise?.eur?.length;
    // const gbpLength = props?.customerData?.currencyWise?.gbp?.length;
    // const cadLength = props?.customerData?.currencyWise?.cad?.length;

    // console.log('first',usdLength,eurLength,gbpLength,cadLength)

    useEffect(() => {
        const handleScroll = (event) => {
          if (event.target.id === 'thead') {
            tbodyRef.current.scrollLeft = theadRef.current.scrollLeft;
          }
          else if (event.target.id === 'tbody') {
            theadRef.current.scrollLeft = tbodyRef.current.scrollLeft;
          }
        };
    
        const thead = theadRef.current;
        const tbody = tbodyRef.current;
    
        thead.addEventListener('scroll', handleScroll);
        tbody.addEventListener('scroll', handleScroll);
    
        return () => {
          thead.removeEventListener('scroll', handleScroll);
          tbody.removeEventListener('scroll', handleScroll);
        };
      }, []);

      console.log('sortedNewCustomerData',props.sortedNewCustomerData)
    //   console.log('width',window.innerWidth)

    // const inr = props?.customerData?.currencyWise[currency][0]?.converted?.currencyDTO?.sign;

    // console.log('inr',inr)
  return (
        <>
            <div className={classes.allWrap}>
                <div className={clsx(`${classes.table} ${classes.reportTableWrapTop}`)} id="thead" ref={theadRef}>
                        <p className={classes.topHeading}>Amount shown are in Thousands*</p>
                        <thead 
                        // className='flex'
                        >
                        {props.tableHeader.map((header, index) => (
                                <th key={`${header}-${index}`} className={classes.cell_header1} 
                                style={{ width : `${header.width}%`, minWidth : `${header.minWidth}px` }} 
                                valign="top"
                                >
                                    <div className={classes.cell_header2} >
                                        <div className={classes.head}>{header.title}</div>
                                        <div className={classes.head}>{header.title1}</div>
                                    </div>
                                </th>   
                        ))}
                        </thead>
                </div>

                <div className={`${classes.reportTableWrap}`} id="tbody"  ref={tbodyRef}>
                    <ReportTableLayout className={classes.table}>
                        {props.loading 
                            ?
                            <td colSpan={5}>
                            {[1,2,3]?.map((item, index) => (
                                <Shimmer key={index} className="w-[100%] h-[80px] mb-[12px]"/>
                            ))}
                            </td>
                            :
                            (<tbody>
                                    {!props?.allEmpty && Object.keys(props?.sortedNewCustomerData)?.map((currency) => (
                                        <div className={classes.tbodyWrap}>

                                                {!props?.allEmpty && props?.customerData?.currencyWise[currency]?.map((customer,index) => (
                                                <div className={classes.tRowWrap} key={currency} style={{borderRadius : index === 0 ? '12px 12px 0px 0px' : '0px'}}>
                                                    <tr key={index} className={`${classes.row} `} >
                                                        <td className={classes.cell} style={{ width: '12.25%', minWidth: '140px'}} valign="top">
                                                            <p className='text-[#1E333F] text-[14px] font-[500] leading-[20px]'>{customer?.customerName}</p>
                                                        </td>

                                                        <td className={classes.cell} style={{ width: '12.25%', minWidth: '120px' }} valign="top">
                                                            <div className={classes.amount}>
                                                                {(customer?.overdue0to30 === 0 || customer?.overdue0to30 === null)  ? '-' : (customer?.currencyDTO?.sign || '') + formatNumberToLocaleString((customer?.overdue0to30 || 0),2,2)}
                                                            </div>
                                                            <div className={classes.amount} style={{ color: "#1e333f40" }}>
                                                                {(customer?.converted?.overdue0to30 === 0 || customer?.converted?.overdue0to30 === null)  ? '-' : (customer?.converted?.currencyDTO?.sign || '') + formatNumberToLocaleString((customer?.converted?.overdue0to30 || 0),2,2)}
                                                            </div>
                                                        </td>

                                                        <td className={classes.cell} style={{ width: '12.25%', minWidth: '120px' }} valign="top">
                                                            <div className={classes.amount}>
                                                                {(customer?.overdue31to45 === 0 || customer?.overdue31to45 === null)  ? '-' : (customer?.currencyDTO?.sign || '') + formatNumberToLocaleString((customer?.overdue31to45 || 0),2,2)}
                                                            </div>
                                                            <div className={classes.amount} style={{ color: "#1e333f40" }}>
                                                                {(customer?.converted?.overdue31to45 === 0 || customer?.converted?.overdue31to45 === null)  ? '-' : (customer?.converted?.currencyDTO?.sign || '') + formatNumberToLocaleString((customer?.converted?.overdue31to45 || 0),2,2)}
                                                            </div>
                                                        </td>

                                                        <td className={clsx(classes.cell)} style={{ width: '12.25%', minWidth: '120px' }} valign="top">
                                                            <div className={classes.amount}>
                                                                {(customer?.overdue46to60 === 0 || customer?.overdue46to60 === null)  ? '-' : (customer?.currencyDTO?.sign || '') + formatNumberToLocaleString((customer?.overdue46to60 || 0),2,2)}
                                                            </div>
                                                            <div className={classes.amount} style={{ color: "#1e333f40" }}>
                                                                {(customer?.converted?.overdue46to60 === 0 || customer?.converted?.overdue46to60 === null)  ? '-' : (customer?.converted?.currencyDTO?.sign || '') + formatNumberToLocaleString((customer?.converted?.overdue46to60 || 0),2,2)}
                                                            </div>
                                                        </td>

                                                        <td className={clsx(classes.cell)} style={{ width: '12.25%', minWidth: '120px' }}  valign="top">
                                                            <div className={classes.amount}>
                                                                {(customer?.overdue61to90 === 0 || customer?.overdue61to90 === null)  ? '-' : (customer?.currencyDTO?.sign || '') + formatNumberToLocaleString((customer?.overdue61to90 || 0),2,2)}
                                                            </div>
                                                            <div className={classes.amount} style={{ color: "#1e333f40" }}>
                                                                {(customer?.converted?.overdue61to90 === 0 || customer?.converted?.overdue61to90 === null)  ? '-' : (customer?.converted?.currencyDTO?.sign || '') + formatNumberToLocaleString((customer?.converted?.overdue61to90 || 0),2,2)}
                                                            </div>
                                                        </td>

                                                        <td className={clsx(classes.cell)} style={{ width: '12.25%', minWidth: '120px' }} valign="top">
                                                            <div className={classes.amount}>
                                                                {(customer?.overdue91Plus === 0 || customer?.overdue91Plus === null)  ? '-' : (customer?.currencyDTO?.sign || '') + formatNumberToLocaleString((customer?.overdue91Plus || 0),2,2)}
                                                            </div>
                                                            <div className={classes.amount} style={{ color: "#1e333f40" }}>
                                                                {(customer?.converted?.overdue91Plus === 0 || customer?.converted?.overdue91Plus === null)  ? '-' : (customer?.converted?.currencyDTO?.sign || '') + formatNumberToLocaleString((customer?.converted?.overdue91Plus || 0),2,2)}
                                                            </div>
                                                        </td>

                                                        <td className={clsx(classes.cell)} style={{ width: '12.25%', minWidth: '120px' }} valign="top">
                                                            <div className={classes.amount}>
                                                                {(customer?.totalOverdue === 0 || customer?.totalOverdue === null)  ? '-' : (customer?.currencyDTO?.sign || '') + formatNumberToLocaleString((customer?.totalOverdue || 0),2,2)}
                                                            </div>
                                                            <div className={classes.amount} style={{ color: "#1e333f40" }}>
                                                                {(customer?.converted?.totalOverdue === 0 || customer?.converted?.totalOverdue === null)  ? '-' : (customer?.converted?.currencyDTO?.sign || '') + formatNumberToLocaleString((customer?.converted?.totalOverdue || 0),2,2)}
                                                            </div>
                                                        </td>

                                                        <td className={clsx(classes.cell)} style={{ width: '12.25%', minWidth: '120px' }} valign="top">
                                                            <div className={classes.amount}>
                                                                {(customer?.notDue === 0 || customer?.notDue === null)  ? '-' : (customer?.currencyDTO?.sign || '') + formatNumberToLocaleString((customer?.notDue || 0),2,2)}
                                                            </div>
                                                            <div className={classes.amount} style={{ color: "#1e333f40" }}>
                                                                {(customer?.converted?.notDue === 0 || customer?.converted?.notDue === null)  ? '-' : (customer?.converted?.currencyDTO?.sign || '') + formatNumberToLocaleString((customer?.converted?.notDue || 0),2,2)}
                                                            </div>
                                                        </td>

                                                        <td className={clsx(classes.cell)} style={{ width: '12.25%', minWidth: '120px' }} valign="top">
                                                            <div className={classes.amount}>
                                                                {(customer?.total === 0 || customer?.total === null)  ? '-' : (customer?.currencyDTO?.sign || '') + formatNumberToLocaleString((customer?.total || 0),2,2)}
                                                            </div>
                                                            <div className={classes.amount} style={{ color: "#1e333f40" }}>
                                                                {(customer?.converted?.total === 0 || customer?.converted?.total === null)  ? '-' : (customer?.converted?.currencyDTO?.sign || '') + formatNumberToLocaleString((customer?.converted?.total || 0),2,2)}
                                                            </div>
                                                        </td>           
                                                    </tr>
                                            </div> 
                                                ))}
                                            {/* {console.log(props?.customerData?.subTotals[currency?.toUpperCase()])} */}
                                            {props?.customerData?.subTotals[currency?.toUpperCase()] &&
                                            (<div className={classes.rowFixed}>
                                                <tr className={`${classes.row} `}>
                                                    <td className={classes.cell} style={{width: '12.25%', minWidth : '140px'}} valign="top">
                                                        <p className='text-[#1E333F] text-[14px] font-[700] leading-[20px]'>{`Sub total ${currency?.toUpperCase()}`}</p>
                                                    </td>

                                                    <td className={classes.cell} style={{width: '12.25%', minWidth : '120px'}} valign="top">
                                                        {console.log(props?.customerData, "dshjhjds1")}
                                                        {/*{console.log(props?.customerData, "dshjhjds2")}*/}
                                                        {/*{console.log(props?.customerData?.currencyWise[currency][0]?.currencyDTO?.sign, "dshjhjds3")}*/}
                                                        {/*{console.log(props?.customerData?.currencyWise[currency], "dshjhjds4")}*/}
                                                        {console.log(props?.customerData?.subTotals["AED"], "dshjhjds5")}
                                                        <div className={classes.amount}>
                                                            {(props?.customerData?.subTotals[currency?.toUpperCase()]?.subtotalOverdue0to30 === 0 || props?.customerData?.subTotals[currency?.toUpperCase()]?.subtotalOverdue0to30 === null)  ? '-' : (props?.customerData?.currencyWise[currency]?.length && props?.customerData?.currencyWise[currency][0]?.currencyDTO?.sign || '') + formatNumberToLocaleString((props?.customerData?.subTotals[currency?.toUpperCase()]?.subtotalOverdue0to30 || 0),2,2)}
                                                        </div>
                                                        <div className={classes.amount} style={{ color: "#1e333f40" }}>
                                                            {(props?.customerData?.subTotals[currency?.toUpperCase()]?.subtotalOverdue0to30INR === 0 || props?.customerData?.subTotals[currency?.toUpperCase()]?.subtotalOverdue0to30INR === null)  ? '-' : (props?.customerData?.currencyWise[currency]?.length && props?.customerData?.currencyWise[currency][0]?.converted?.currencyDTO?.sign || '') + formatNumberToLocaleString((props?.customerData?.subTotals[currency?.toUpperCase()]?.subtotalOverdue0to30INR || 0),2,2)}
                                                        </div>
                                                    </td>

                                                    <td className={classes.cell} style={{width: '12.25%', minWidth : '120px'}} valign="top">
                                                        <div className={classes.amount}>
                                                            {(props?.customerData?.subTotals[currency?.toUpperCase()]?.subtotalOverdue31to45 === 0 || props?.customerData?.subTotals[currency?.toUpperCase()]?.subtotalOverdue31to45 === null)  ? '-' : (props?.customerData?.currencyWise[currency]?.length && props?.customerData?.currencyWise[currency][0]?.currencyDTO?.sign || '') + formatNumberToLocaleString((props?.customerData?.subTotals[currency?.toUpperCase()]?.subtotalOverdue31to45 || 0),2,2)}
                                                        </div>
                                                        <div className={classes.amount} style={{ color: "#1e333f40" }}>
                                                            {(props?.customerData?.subTotals[currency?.toUpperCase()]?.subtotalOverdue31to45INR === 0 || props?.customerData?.subTotals[currency?.toUpperCase()]?.subtotalOverdue31to45INR === null)  ? '-' : (props?.customerData?.currencyWise[currency]?.length && props?.customerData?.currencyWise[currency][0]?.converted?.currencyDTO?.sign || '') + formatNumberToLocaleString((props?.customerData?.subTotals[currency?.toUpperCase()]?.subtotalOverdue31to45INR || 0),2,2)}
                                                        </div>
                                                    </td>

                                                    <td className={clsx(classes.cell)} style={{width: '12.25%', minWidth : '120px'}} valign="top">
                                                        <div className={classes.amount}>
                                                            {(props?.customerData?.subTotals[currency?.toUpperCase()]?.subtotalOverdue46to60 === 0 || props?.customerData?.subTotals[currency?.toUpperCase()]?.subtotalOverdue46to60 === null)  ? '-' : (props?.customerData?.currencyWise[currency]?.length && props?.customerData?.currencyWise[currency][0]?.currencyDTO?.sign || '') + formatNumberToLocaleString((props?.customerData?.subTotals[currency?.toUpperCase()]?.subtotalOverdue46to60 || 0),2,2)}
                                                        </div>
                                                        <div className={classes.amount} style={{ color: "#1e333f40" }}>
                                                            {(props?.customerData?.subTotals[currency?.toUpperCase()]?.subtotalOverdue46to60INR === 0 || props?.customerData?.subTotals[currency?.toUpperCase()]?.subtotalOverdue46to60INR === null)  ? '-' : (props?.customerData?.currencyWise[currency]?.length && props?.customerData?.currencyWise[currency][0]?.converted?.currencyDTO?.sign || '') + formatNumberToLocaleString((props?.customerData?.subTotals[currency?.toUpperCase()]?.subtotalOverdue46to60INR || 0),2,2)}
                                                        </div>
                                                    </td>

                                                    <td className={clsx(classes.cell)} style={{width: '12.25%', minWidth : '120px'}}  valign="top">
                                                        <div className={classes.amount}>
                                                            {(props?.customerData?.subTotals[currency?.toUpperCase()]?.subtotalOverdue61to90 === 0 || props?.customerData?.subTotals[currency?.toUpperCase()]?.subtotalOverdue61to90 === null)  ? '-' : (props?.customerData?.currencyWise[currency]?.length && props?.customerData?.currencyWise[currency][0]?.currencyDTO?.sign || '') + formatNumberToLocaleString((props?.customerData?.subTotals[currency?.toUpperCase()]?.subtotalOverdue61to90 || 0),2,2)}
                                                        </div>
                                                        <div className={classes.amount} style={{ color: "#1e333f40" }}>
                                                            {(props?.customerData?.subTotals[currency?.toUpperCase()]?.subtotalOverdue61to90INR === 0 || props?.customerData?.subTotals[currency?.toUpperCase()]?.subtotalOverdue61to90INR === null)  ? '-' : (props?.customerData?.currencyWise[currency]?.length && props?.customerData?.currencyWise[currency][0]?.converted?.currencyDTO?.sign || '') + formatNumberToLocaleString((props?.customerData?.subTotals[currency?.toUpperCase()]?.subtotalOverdue61to90INR || 0),2,2)}
                                                        </div>
                                                    </td>

                                                    <td className={clsx(classes.cell)} style={{width: '12.25%', minWidth : '120px'}} valign="top">
                                                        <div className={classes.amount}>
                                                            {(props?.customerData?.subTotals[currency?.toUpperCase()]?.subtotalOverdue91Plus === 0 || props?.customerData?.subTotals[currency?.toUpperCase()]?.subtotalOverdue91Plus === null)  ? '-' : (props?.customerData?.currencyWise[currency]?.length && props?.customerData?.currencyWise[currency][0]?.currencyDTO?.sign || '') + formatNumberToLocaleString((props?.customerData?.subTotals[currency?.toUpperCase()]?.subtotalOverdue91Plus || 0),2,2)}
                                                        </div>
                                                        <div className={classes.amount} style={{ color: "#1e333f40" }}>
                                                            {(props?.customerData?.subTotals[currency?.toUpperCase()]?.subtotalOverdue91PlusINR === 0 || props?.customerData?.subTotals[currency?.toUpperCase()]?.subtotalOverdue91PlusINR === null)  ? '-' : (props?.customerData?.currencyWise[currency]?.length && props?.customerData?.currencyWise[currency][0]?.converted?.currencyDTO?.sign || '') + formatNumberToLocaleString((props?.customerData?.subTotals[currency?.toUpperCase()]?.subtotalOverdue91PlusINR || 0),2,2)}
                                                        </div>
                                                    </td>

                                                    <td className={clsx(classes.cell)} style={{width: '12.25%', minWidth : '120px'}} valign="top">
                                                        <div className={classes.amount}>
                                                            {(props?.customerData?.subTotals[currency?.toUpperCase()]?.subtotalTotalOverdue === 0 || props?.customerData?.subTotals[currency?.toUpperCase()]?.subtotalTotalOverdue === null)  ? '-' : (props?.customerData?.currencyWise[currency]?.length && props?.customerData?.currencyWise[currency][0]?.currencyDTO?.sign || '') + formatNumberToLocaleString((props?.customerData?.subTotals[currency?.toUpperCase()]?.subtotalTotalOverdue || 0),2,2)}
                                                        </div>
                                                        <div className={classes.amount} style={{ color: "#1e333f40" }}>
                                                            {(props?.customerData?.subTotals[currency?.toUpperCase()]?.subtotalTotalOverdueINR === 0 || props?.customerData?.subTotals[currency?.toUpperCase()]?.subtotalTotalOverdueINR === null)  ? '-' : (props?.customerData?.currencyWise[currency]?.length && props?.customerData?.currencyWise[currency][0]?.converted?.currencyDTO?.sign || '') + formatNumberToLocaleString((props?.customerData?.subTotals[currency?.toUpperCase()]?.subtotalTotalOverdueINR || 0),2,2)}
                                                        </div>
                                                    </td>

                                                    <td className={clsx(classes.cell)} style={{width: '12.25%', minWidth : '120px'}} valign="top">
                                                        <div className={classes.amount}>
                                                            {(props?.customerData?.subTotals[currency?.toUpperCase()]?.subtotalNotDue === 0 || props?.customerData?.subTotals[currency?.toUpperCase()]?.subtotalNotDue === null)  ? '-' : (props?.customerData?.currencyWise[currency]?.length && props?.customerData?.currencyWise[currency][0]?.currencyDTO?.sign || '') + formatNumberToLocaleString((props?.customerData?.subTotals[currency?.toUpperCase()]?.subtotalNotDue || 0),2,2)}
                                                        </div>
                                                        <div className={classes.amount} style={{ color: "#1e333f40" }}>
                                                            {(props?.customerData?.subTotals[currency?.toUpperCase()]?.subtotalNotDueINR === 0 || props?.customerData?.subTotals[currency?.toUpperCase()]?.subtotalNotDueINR === null)  ? '-' : (props?.customerData?.currencyWise[currency]?.length && props?.customerData?.currencyWise[currency][0]?.converted?.currencyDTO?.sign || '') + formatNumberToLocaleString((props?.customerData?.subTotals[currency?.toUpperCase()]?.subtotalNotDueINR || 0),2,2)}
                                                        </div>
                                                    </td>

                                                    <td className={clsx(classes.cell)} style={{width: '12.25%', minWidth : '120px'}} valign="top">
                                                        <div className={classes.amount}>
                                                            {(props?.customerData?.subTotals[currency?.toUpperCase()]?.subtotalTotal === 0 || props?.customerData?.subTotals[currency?.toUpperCase()]?.subtotalTotal === null)  ? '-' : (props?.customerData?.currencyWise[currency]?.length && props?.customerData?.currencyWise[currency][0]?.currencyDTO?.sign || '') + formatNumberToLocaleString((props?.customerData?.subTotals[currency?.toUpperCase()]?.subtotalTotal || 0),2,2)}
                                                        </div>
                                                        <div className={classes.amount} style={{ color: "#1e333f40" }}>
                                                            {(props?.customerData?.subTotals[currency?.toUpperCase()]?.subtotalTotalINR === 0 || props?.customerData?.subTotals[currency?.toUpperCase()]?.subtotalTotalINR === null)  ? '-' : (props?.customerData?.currencyWise[currency]?.length && props?.customerData?.currencyWise[currency][0]?.converted?.currencyDTO?.sign || '') + formatNumberToLocaleString((props?.customerData?.subTotals[currency?.toUpperCase()]?.subtotalTotalINR || 0),2,2)}
                                                        </div>
                                                    </td>           
                                                </tr> 
                                            </div>)}
                                        </div>
                                        ))
                                    } 
                                    <div  >
                                        {!props?.allEmpty &&
                                        (<div className={classes.grandTotal}>
                                            <tr className={`${classes.row} `}>
                                                <td className={classes.cell}
                                                    style={{width: '12.25%', minWidth: '140px'}} valign="top">
                                                    <p className='text-[#1E333F] text-[14px] font-[700] leading-[20px]'>{`Grand Total`}</p>
                                                </td>

                                                <td className={classes.cell}
                                                    style={{width: '12.25%', minWidth: '120px'}} valign="top">
                                                    <div className={classes.amount}>
                                                        {(props?.customerData?.grandTotals?.subtotalOverdue0to30 === 0 || props?.customerData?.grandTotals?.subtotalOverdue0to30 === null || props?.customerData?.grandTotals?.subtotalOverdue0to30 === undefined) ? '-' : formatNumberToLocaleString((props?.customerData?.grandTotals?.subtotalOverdue0to30 || 0), 2, 2)}
                                                    </div>
                                                    <div className={classes.amount} style={{color: "#1e333f40"}}>
                                                        {(props?.customerData?.grandTotals?.subtotalOverdue0to30INR === 0 || props?.customerData?.grandTotals?.subtotalOverdue0to30INR === null || props?.customerData?.grandTotals?.subtotalOverdue0to30INR === undefined) ? '-' : (props?.inrSign === undefined || props?.inrSign === null ? '' : props?.inrSign) + formatNumberToLocaleString((props?.customerData?.grandTotals?.subtotalOverdue0to30INR || 0), 2, 2)}
                                                    </div>
                                                </td>

                                                <td className={classes.cell}
                                                    style={{width: '12.25%', minWidth: '120px'}} valign="top">
                                                    <div className={classes.amount}>
                                                        {props?.customerData?.grandTotals?.subtotalOverdue31to45 === 0 || props?.customerData?.grandTotals?.subtotalOverdue31to45 === null ? '-' : formatNumberToLocaleString((props?.customerData?.grandTotals?.subtotalOverdue31to45 || 0), 2, 2)}
                                                    </div>
                                                    <div className={classes.amount} style={{color: "#1e333f40"}}>
                                                        {props?.customerData?.grandTotals?.subtotalOverdue31to45INR === 0 || props?.customerData?.grandTotals?.subtotalOverdue31to45INR === null ? '-' : (props?.inrSign === undefined || props?.inrSign === null ? '' : props?.inrSign) + formatNumberToLocaleString((props?.customerData?.grandTotals?.subtotalOverdue31to45INR || 0), 2, 2)}
                                                    </div>
                                                </td>


                                                <td className={clsx(classes.cell)}
                                                    style={{width: '12.25%', minWidth: '120px'}} valign="top">
                                                    <div className={classes.amount}>
                                                        {props?.customerData?.grandTotals?.subtotalOverdue46to60 === 0 || props?.customerData?.grandTotals?.subtotalOverdue46to60 === null ? '-' : formatNumberToLocaleString((props?.customerData?.grandTotals?.subtotalOverdue46to60 || 0), 2, 2)}
                                                    </div>
                                                    <div className={classes.amount} style={{color: "#1e333f40"}}>
                                                        {props?.customerData?.grandTotals?.subtotalOverdue46to60INR === 0 || props?.customerData?.grandTotals?.subtotalOverdue46to60INR === null ? '-' : (props?.inrSign === undefined || props?.inrSign === null ? '' : props?.inrSign) + formatNumberToLocaleString((props?.customerData?.grandTotals?.subtotalOverdue46to60INR || 0), 2, 2)}
                                                    </div>
                                                </td>

                                                <td className={clsx(classes.cell)}
                                                    style={{width: '12.25%', minWidth: '120px'}} valign="top">
                                                    <div className={classes.amount}>
                                                        {(props?.customerData?.grandTotals?.subtotalOverdue61to90 === 0 || props?.customerData?.grandTotals?.subtotalOverdue61to90 === null) ? '-' : formatNumberToLocaleString((props?.customerData?.grandTotals?.subtotalOverdue61to90 || 0), 2, 2)}
                                                    </div>
                                                    <div className={classes.amount} style={{color: "#1e333f40"}}>
                                                        {(props?.customerData?.grandTotals?.subtotalOverdue61to90INR === 0 || props?.customerData?.grandTotals?.subtotalOverdue61to90INR === null) ? '-' : (props?.inrSign === undefined || props?.inrSign === null ? '' : props?.inrSign) + formatNumberToLocaleString((props?.customerData?.grandTotals?.subtotalOverdue61to90INR || 0), 2, 2)}
                                                    </div>
                                                </td>

                                                <td className={clsx(classes.cell)}
                                                    style={{width: '12.25%', minWidth: '120px'}} valign="top">
                                                    <div className={classes.amount}>
                                                        {props?.customerData?.grandTotals?.subtotalOverdue91Plus === 0 || props?.customerData?.grandTotals?.subtotalOverdue91Plus === null ? '-' : formatNumberToLocaleString((props?.customerData?.grandTotals?.subtotalOverdue91Plus || 0), 2, 2)}
                                                    </div>
                                                    <div className={classes.amount} style={{color: "#1e333f40"}}>
                                                        {props?.customerData?.grandTotals?.subtotalOverdue91PlusINR === 0 || props?.customerData?.grandTotals?.subtotalOverdue91PlusINR === null ? '-' : (props?.inrSign === undefined || props?.inrSign === null ? '' : props?.inrSign) + formatNumberToLocaleString((props?.customerData?.grandTotals?.subtotalOverdue91PlusINR || 0), 2, 2)}
                                                    </div>
                                                </td>

                                                <td className={clsx(classes.cell)}
                                                    style={{width: '12.25%', minWidth: '120px'}} valign="top">
                                                    <div className={classes.amount}>
                                                        {props?.customerData?.grandTotals?.subtotalTotalOverdue === 0 || props?.customerData?.grandTotals?.subtotalTotalOverdue === null ? '-' : formatNumberToLocaleString((props?.customerData?.grandTotals?.subtotalTotalOverdue || 0), 2, 2)}
                                                    </div>
                                                    <div className={classes.amount} style={{color: "#1e333f40"}}>
                                                        {props?.customerData?.grandTotals?.subtotalTotalOverdueINR === 0 || props?.customerData?.grandTotals?.subtotalTotalOverdueINR === null ? '-' : (props?.inrSign === undefined || props?.inrSign === null ? '' : props?.inrSign) + formatNumberToLocaleString((props?.customerData?.grandTotals?.subtotalTotalOverdueINR || 0), 2, 2)}
                                                    </div>
                                                </td>

                                                <td className={clsx(classes.cell)}
                                                    style={{width: '12.25%', minWidth: '120px'}} valign="top">
                                                    <div className={classes.amount}>
                                                        {props?.customerData?.grandTotals?.subtotalNotDue === 0 || props?.customerData?.grandTotals?.subtotalNotDue === null ? '-' : formatNumberToLocaleString((props?.customerData?.grandTotals?.subtotalNotDue || 0), 2, 2)}
                                                    </div>
                                                    <div className={classes.amount} style={{color: "#1e333f40"}}>
                                                        {props?.customerData?.grandTotals?.subtotalNotDueINR === 0 || props?.customerData?.grandTotals?.subtotalNotDueINR === null ? '-' : (props?.inrSign === undefined || props?.inrSign === null ? '' : props?.inrSign) + formatNumberToLocaleString((props?.customerData?.grandTotals?.subtotalNotDueINR || 0), 2, 2)}
                                                    </div>
                                                </td>

                                                <td className={clsx(classes.cell)}
                                                    style={{width: '12.25%', minWidth: '120px'}} valign="top">
                                                    <div className={classes.amount}>
                                                        {props?.customerData?.grandTotals?.subtotalTotal === 0 || props?.customerData?.grandTotals?.subtotalTotal === null ? '-' : formatNumberToLocaleString((props?.customerData?.grandTotals?.subtotalTotal || 0), 2, 2)}
                                                    </div>
                                                    <div className={classes.amount} style={{color: "#1e333f40"}}>
                                                        {props?.customerData?.grandTotals?.subtotalTotalINR === 0 || props?.customerData?.grandTotals?.subtotalTotalINR === null ? '-' : (props?.inrSign === undefined || props?.inrSign === null ? '' : props?.inrSign) + formatNumberToLocaleString((props?.customerData?.grandTotals?.subtotalTotalINR || 0), 2, 2)}
                                                    </div>
                                                </td>
                                            </tr>
                                        </div>)}
                                    </div>
                            </tbody>)
                        }
                    </ReportTableLayout>
                </div>
            </div>
            {/* {console.log(Object.keys(props?.sortedNewCustomerData))}
            {console.log(Object.keys(props?.customerData?.currencyWise?.usd?.length))}
            {console.log(props?.customerData?.currencyWise?.usd?.length)} */}
            {(props?.allEmpty && !props.loading) &&
                (<div
                    style={{
                        textAlign: 'center',
                        width: '100%',
                        marginTop: '4rem',
                        fontSize: '2.5rem',
                        // backgroundColor: 'red'
                    }}
                    className={classes.table}
                >
                    No Customer Dues Yet.
                </div>)
            }
            <MobileCustomerOverview
                customerData={props.customerData}
                sortedNewCustomerData = {props.sortedNewCustomerData}
                allEmpty={props?.allEmpty}
                loading={props?.loading}
            />
        </>
    )
}

export default CustomerOverview