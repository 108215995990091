import Backdrop from 'Layout/Backdrop'
import Modal from 'Layout/Modal'
import clsx from 'clsx'
import ReactDOM from 'react-dom'
import { useDispatch } from 'react-redux'
import { modalActions } from 'Store/modal-redux'
import Close from 'Layout/Close'
import classes from './BankDepositsModal.module.scss'
import ContactInfo from 'Components/Global/ContactInfo'
import dwnVban from 'assets/Icons/dwnVban.svg';
import plus from 'assets/Icons/icons8-plus.svg';
import { formatNumberToLocaleString } from 'hooks/formatNumberToLocaleString';
// import { useSnackbar } from 'notistack'
import UseApiCall from "../../../hooks/useApiCall";
import {apiGetVBankDeposits} from "../../../services";
import {useEffect, useRef} from "react";
import ReportTableLayout from "../../ReportTable/ReportTableLayout";
import Shimmer from "../../../Layout/Shimmer";
import dayjs from "dayjs";

const tableHeader = [
    {
        id:1,
        header:"Date & Time",
        width: 25,
        minWidth:150,
    },
    {
        id:2,
        header:"Details",
        width: 50,
        minWidth:280,
    },
    {
        id:3,
        header:"Amount",
        width: 25,
        minWidth:150,
    }
]
const BankDepositsModal = (props) => {
    // const { enqueueSnackbar } = useSnackbar()
    const { selectedAcc,selectedAccountDetails } = props
    // const { invoiceResponse, virtualAccountDto } = invoiceDetails
    const dispatch = useDispatch();
    const theadRef = useRef(null);
    const tbodyRef = useRef(null);

    const [getVBankDeposits, fetchinfVBankDeposits,VBankDepositsData] = UseApiCall(
        apiGetVBankDeposits,
        (res) => {
            // console.log('res',res)
            // const currencyCode = apiCallRecon ? selectedAccountDetails?.currencyDTO?.code : 'USD';
            // if (res?.data?.balances?.[currencyCode]?.balance) {
            //     setSelectedAccountDetails(res?.data?.balances?.[currencyCode]);
            // }
            // dispatch(BankAccountActions.setVirtualAccounts(res?.data));
        }
    );

    useEffect(() => {
        getVBankDeposits(selectedAcc)
    }, [selectedAcc]);

    useEffect(() => {
        const handleScroll = (event) => {
            if (event.target.id === 'thead') {
                tbodyRef.current.scrollLeft = theadRef.current.scrollLeft;
            }
            else if (event.target.id === 'tbody') {
                theadRef.current.scrollLeft = tbodyRef.current.scrollLeft;
            }
        };

        const thead = theadRef.current;
        const tbody = tbodyRef.current;

        thead.addEventListener('scroll', handleScroll);
        tbody.addEventListener('scroll', handleScroll);

        return () => {
            thead.removeEventListener('scroll', handleScroll);
            tbody.removeEventListener('scroll', handleScroll);
        };
    }, []);


    // console.log('selectedAcc',selectedAcc)
    // console.log('selectedAccountDetails',selectedAccountDetails)
    // console.log('VBankDepositsData',VBankDepositsData?.data)
    // console.log('VBankDepositsData?.length',VBankDepositsData?.data?.length)
    return (
        <Modal
            className={clsx(
                classes.modal,
                'h-screen max-w-[640px] p-0 overflow-scroll bg-[#FFFFFF] z-[400]'
            )}
        >
            {ReactDOM.createPortal(
                <Backdrop
                    onClick={() => {
                        dispatch(modalActions.setShowVbanDeposits(false))
                    }}
                    className={classes.backdropBank}
                />,
                document.getElementById('backdrop-root')
            )}
            <div
                className="flex z-10  flex-row items-center justify-center sm:justify-between sticky top-0 right-0 left-0 px-16 py-[20px] bg-[#FFFFFF] border-b-[1px] border-s-[#E8EAF]">
                <div>
                    <p className="font-inter text-[18px] text-[#1E333F] font-[600] leading-[28px] ">{`Deposits : ${props?.selectedAcc} Virtual Bank Account`}</p>
                    {selectedAccountDetails?.balance !== undefined && selectedAccountDetails?.balance !== null &&
                        <p className="font-inter text-[14px] text-[#181C30B2] font-[500] leading-[20px] ">
                            {`Current Balance: ${selectedAccountDetails?.currencyDTO?.sign}${selectedAccountDetails?.balance && selectedAccountDetails?.balance?.toLocaleString('en-US', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2
                            })}`}
                        </p>}
                </div>
                <Close
                    className="w-[32px] h-[32px] "
                    onClick={() => {
                        dispatch(modalActions.setShowVbanDeposits(false))
                    }}
                />
            </div>

            <div className={classes.allWrap}>
                <div className={clsx(`${classes.table} ${classes.reportTableWrapTop}`)} id="thead" ref={theadRef}>
                    <thead
                        className={clsx(classes.thead )}
                    >
                    {tableHeader.map((header, index) => (
                        <th
                            key={`${header}-${index}`}
                            className={classes.cell_header1}
                            style={{
                                width: `${header.width}%`,
                                minWidth: `${header.minWidth}px`,
                                maxWidth: `${header.minWidth}px`
                            }}
                            valign="top"
                        >
                            <div className={classes.cell_header2}
                                 style={{
                                     justifyContent: header.header === "Amount" ? 'end' : 'start'
                                 }}
                            >
                                {header?.header}
                            </div>
                        </th>
                    ))}
                    </thead>
                </div>
                <div className={`${classes.reportTableWrap}`} id="tbody" ref={tbodyRef}>
                    <ReportTableLayout className={clsx(classes.table, 'pt-[0px]')}>
                        <tbody className={classes.tbody}>
                        {fetchinfVBankDeposits
                            ?
                            [1, 3, 4]?.map((item, index) => (
                                <Shimmer key={index} className="w-[100%] h-[80px] mt-[12px]"/>
                            ))
                            :
                            VBankDepositsData?.data?.length > 0 && VBankDepositsData?.data.map((data, index) => (
                                <tr
                                    key={data.id}
                                    className={classes.row}
                                >
                                    <td
                                        className={`${classes.cell} ${classes.invoice}`}
                                        style={{
                                            userSelect: 'none',
                                            // cursor: 'pointer',
                                            width: `${tableHeader[0].width}%`,
                                            minWidth: `${tableHeader[0].minWidth}px`,
                                            maxWidth: `${tableHeader[0].minWidth}px`,
                                            borderBottom: index < (VBankDepositsData?.data?.length - 1 || 0) ? '1px dashed #d3d3e5' : 'none'
                                        }}
                                        valign="top"
                                    >
                                        <div className={classes.dateColumn}>
                                            <div className={classes.dateOne}>
                                                {data?.time ? dayjs(data?.time).utc().utcOffset('+05:30').format('DD MMM YYYY') : "--"}
                                            </div>
                                            <div className={classes.dateTwo}>
                                                {data?.time && dayjs(data?.time).utc().utcOffset('+05:30').format('hh:mm A [IST]')}
                                            </div>
                                        </div>
                                    </td>
                                    <td
                                        className={`${classes.cell} ${classes.invoice}`}

                                        style={{
                                            userSelect: 'none',
                                            // cursor: 'pointer',
                                            width: `${tableHeader[1].width}%`,
                                            minWidth: `${tableHeader[1].minWidth}px`,
                                            maxWidth: `${tableHeader[1].minWidth}px`,
                                            borderBottom: index < (VBankDepositsData?.data?.length - 1 || 0) ? '1px dashed #d3d3e5' : 'none'
                                        }}
                                        valign="top"
                                    >
                                        {
                                            data?.description === null
                                                ?
                                                '--'
                                                :
                                                <div className="flex items-start gap-[12px]">
                                                    {/*<div className="flex items-center justify-center w-[48px] h-[48px] rounded-[8px] p-[12px] bg-[#F7F8FB]">*/}
                                                    {/*    <img src={dwnVban} style={{maxWidth:"24px" , height:"24px"}} />*/}
                                                    {/*</div>*/}
                                                    <ContactInfo
                                                        name={data?.description}
                                                        // email={data?.customer?.emailAddress}
                                                        // country={data?.customer?.country}
                                                        // countryName={data?.customer?.countryName}
                                                        // classNameCountry={classes.classNameCountry}
                                                    />
                                                </div>
                                        }
                                    </td>
                                    <td
                                        className={classes.cell}
                                        style={{
                                            width: `${tableHeader[2].width}%`,
                                            minWidth: `${tableHeader[2].minWidth}px`,
                                            maxWidth: `${tableHeader[2].minWidth}px`,
                                            borderBottom: index < (VBankDepositsData?.data?.length - 1 || 0) ? '1px dashed #d3d3e5' : 'none',
                                            // wordWrap: 'anyWhere'
                                        }}

                                        valign="top"
                                    >
                                        <div className={classes.amount}>
                                            <div className="flex w-[10px] h-[10px] mr-[1px]">
                                                {data?.amount &&
                                                    <img src={plus} style={{width:"10px" , height:"10px"}}/>
                                                }
                                            </div>
                                            <p className="flex font-inter  text-[14px] font-[500] leading-[20px]">{data?.currency?.sign || "--"}</p>
                                            <p className="flex font-inter  text-[14px] font-[500] leading-[20px]">{data?.amount ? formatNumberToLocaleString((data?.amount), 2, 2) : "--"}</p>
                                        </div>
                                    </td>
                                </tr>
                            ))
                        }

                        {(VBankDepositsData?.data?.length === 0 && !fetchinfVBankDeposits )  &&
                            (<div
                                style={{
                                    textAlign: 'center',
                                    width: '100%',
                                    marginTop: '4rem',
                                    marginBottom: '4rem',
                                    fontSize: '2.5rem'
                                }}
                                className={classes.table}
                            >
                                No Result Found.
                            </div>)
                        }
                        </tbody>
                    </ReportTableLayout>
                </div>
            </div>

        </Modal>
    )
}

export default BankDepositsModal;


