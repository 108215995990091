import { createSlice } from "@reduxjs/toolkit";

const bankAccountInitialState = {
    accounts: [],
    accountDetails : [],
    virtualAccounts: [],
    reconcilationDetails: [],
};

export const BankAccountSlice = createSlice({
    name: "bank-account",
    initialState: bankAccountInitialState,
    reducers: {
        setBankAccounts(state, action) {
            state.accounts = action.payload;
        },
        setBankAccountDetails(state, action) {
            state.accountDetails = action.payload;
        },
        setVirtualAccounts(state, action) {
            state.virtualAccounts = action.payload;
        },
        setReconcilationDetails(state, action) {
            state.reconcilationDetails = action.payload;
        },
    },
});


export const BankAccountActions = BankAccountSlice.actions;
