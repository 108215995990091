// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Settings_settings__WfCMd {
  font-size: 2.5rem;
  line-height: 3rem;
  border: 2px soild red;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  /*.userState{
        display: flex;
        font-weight: 700;
        font-size: 1.6rem;
        line-height: 4.8rem;
        border-bottom: 1px solid #E3E6E9;
        color: rgba(30, 51, 63, 0.5);
        cursor: pointer;
        margin-bottom: 2.4rem;

        @media (max-width:600px) {
            margin-top: 1.5rem;
            margin-bottom: 4rem;
        }

        .user{
            margin-right: 1.6rem;
            text-align: center;

            @media (max-width:600px) {
                flex: 1;
            }
        }

        .active{
            border-bottom: 2px solid #181C30;
            color: #181C30;
        }
    }

    .button{

        display: none;
        padding: 1.2rem 1.6rem;
        background-color: white;
        width: 100%;
        position: fixed;
        top: 100%;
        left: 0;
        transform: translateY(-100%);

        .btn:not(:last-child){
            margin-right: 1.2rem;
        }

        .btn{
            flex: 1;
        }

        @media (max-width: 600px) {
            display: flex;

        }
    }*/
}
.Settings_settings__WfCMd .Settings_text__yqxnA {
  background: -webkit-linear-gradient(right, #c4c4c4, #333);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Admin/Settings.module.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,iBAAA;EACA,qBAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EASA;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;MAAA;AA8CF;AAtDE;EACE,yDAAA;EACA,6BAAA;EACA,oCAAA;AAwDJ","sourcesContent":[".settings {\n  font-size: 2.5rem;\n  line-height: 3rem;\n  border: 2px soild red;\n  margin: auto;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  .text {\n    background: -webkit-linear-gradient(right, #c4c4c4, #333);\n    -webkit-background-clip: text;\n    -webkit-text-fill-color: transparent;\n  }\n\n  .btn {\n  }\n  /*.userState{\n        display: flex;\n        font-weight: 700;\n        font-size: 1.6rem;\n        line-height: 4.8rem;\n        border-bottom: 1px solid #E3E6E9;\n        color: rgba(30, 51, 63, 0.5);\n        cursor: pointer;\n        margin-bottom: 2.4rem;\n\n        @media (max-width:600px) {\n            margin-top: 1.5rem;\n            margin-bottom: 4rem;\n        }\n    \n        .user{\n            margin-right: 1.6rem;\n            text-align: center;\n\n            @media (max-width:600px) {\n                flex: 1;\n            }\n        }\n\n        .active{\n            border-bottom: 2px solid #181C30;\n            color: #181C30;\n        }\n    }\n\n    .button{\n        \n        display: none;\n        padding: 1.2rem 1.6rem;\n        background-color: white;\n        width: 100%;\n        position: fixed;\n        top: 100%;\n        left: 0;\n        transform: translateY(-100%);\n        \n        .btn:not(:last-child){\n            margin-right: 1.2rem;\n        }\n\n        .btn{\n            flex: 1;\n        }\n\n        @media (max-width: 600px) {\n            display: flex;\n\n        }\n    }*/\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"settings": `Settings_settings__WfCMd`,
	"text": `Settings_text__yqxnA`
};
export default ___CSS_LOADER_EXPORT___;
