import ReactDOM from "react-dom";
import React, { useEffect, useRef, useState } from 'react';
import classes from "./AddNewUser.module.scss";
import { modalActions } from 'Store/modal-redux';
import Backdrop from 'Layout/Backdrop';
import { useDispatch } from 'react-redux';
import Modal from 'Layout/Modal';
import Close from 'Layout/Close';
import clsx from 'clsx';
import cross from 'assets/Images/Close.svg'
import xCircle from "assets/Icons/xCircleNew.svg"
import alertCircleNew from "assets/Icons/alert-circleNew.svg"
import uploadCloud from "assets/Icons/upload-cloud.svg"
import AddUserForm from "./AddUserForm";
import GrayBox from "./GrayBox";
import TextInput from "Layout/TextInput";
import { Button } from "antd";
import AccessTooltip from "./AccessTooltip";
import { enqueueSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import UseApiCall from "hooks/useApiCall";
import { apiAddUser } from "services";
import FileBrowserNew from 'Components/atom/FileBrowserAnother/FileBrowserNew';

const initialFormState = {
    firstName: "",
    lastName: "",
    email: "",
    countryCode: "+91",
    mobileNumber: "",
};

const AddNewUser = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isMobile, setIsMobile] = useState(false);
    const [showTooltip, setShowTooltip] = useState(false);
    const [accessType, setAccessType] = useState('ADMIN');
    const [selectedFile, setSelectedFile] = useState(null);
    const [panNumber, setPanNumber] = useState(null);
    const [isPanNumberValid, setIsPanNumberValid] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [uuid, setUuid] = useState(props.applicationId);
    const [fideoUsersPan, setFideoUsersPan] = useState({});
    const inputRef = useRef(null);


    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [countryCode, setCountryCode] = useState("+91");
    const [mobile, setMobile] = useState("");
    const [linkArr, setLinkArr] = useState([]);
    // const [fideoUser, setFideoUser] = useState(`${firstName} ${lastName}`);

    const [isFirstNameValid, setIsFirstNameValid] = useState(false);
    const [isLastNameValid, setIsLastNameValid] = useState(false);
    const [isEmailValid, setIsEmailValid] = useState(false);
    const [isMobileValid, setIsMobileValid] = useState(false);
    const [key, setKey] = useState(false);
    const [hasErrorCheckbox, setHasErrorCheckbox] = useState(false);
    const [oneTimeClicked, setOneTimeClicked] = useState(false);

    const handleAccessChange = (event) => {
        setAccessType(event.target.value);
      };

    const handleFileUpload = () => {
        inputRef.current.click()
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0]; 
        setSelectedFile(file); 
    };

    useEffect(() => {
      const handleResize = () => {
        setIsMobile(window.innerWidth <= 640);
      };
  
      window.addEventListener('resize', handleResize);
  
      handleResize();
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

    useEffect(() => {
        const url = window.location.pathname;
        const parts = url.split('/');
        // setUuid(parts[parts?.length - 1]);
    
        const searchParams = new URLSearchParams(document.location.search);
        setMobile(searchParams.get('ownerContact[phone]'));
    
        const completeUrl = window.location.href;
    
        const businessNameParam = completeUrl?.split('businessName=')[1];
        // setBusinessName(businessNameParam?.replace(/\%20/g, ' ') || '');
    
        const patternForFirstName = /primaryBusiness\[first\]=(.*?)&primaryBusiness\[last\]=/;
    
        const matchFirstName = completeUrl.match(patternForFirstName);
        let firstNameFromUrl = "";
        let lastNameFromUrl = "";
    
        if (matchFirstName) {
            const capturedText = matchFirstName[1];
            firstNameFromUrl = capturedText?.replace(/\%20/g, ' ');
        }
    
        const patternForLastName = /primaryBusiness\[last\]=(.*?)&email=/;
    
        const matchLastname = completeUrl.match(patternForLastName);
    
        if (matchLastname) {
            const capturedText = matchLastname[1];
            lastNameFromUrl = capturedText?.replace(/\%20/g, ' ');
        }
    
        // const fideoUserValue = `${firstNameFromUrl} ${lastNameFromUrl}`
        // setFideoUser(fideoUserValue)
      }, [])

    const [addUser , adding] = UseApiCall(apiAddUser , () => {
        if(props.onSubmit) props?.onSubmit();
        dispatch(modalActions.addNewUserDisplay(false));
        enqueueSnackbar(`User created successfully.`, { variant: 'success' })
        }, (err) => {
            enqueueSnackbar(`${err?.data?.error_response?.message} ${err?.data?.error_response?.errors !== null ? JSON.stringify(err?.data?.error_response?.errors[0]) : ""}`, { variant: 'error' })
        }
    )
    const formSubmitHandler = (props) => {
        // signUp({
        //   ...props,
        // });
      };

    const handleSubmit = (e) => {
        e.preventDefault();
    
        setOneTimeClicked(true);
        // setShowPassword(true);
        // navigate("/set-password");
    
        if (isValid()) {
            addUser({
              first_name : firstName,
              last_name : lastName,
              email : email,
              country_code : countryCode?.slice(1),
              mobile_number : mobile,
              role: accessType,
              pan_number: panNumber,
              link_arr : linkArr,
            //   selectedFile: selectedFile,
            });
            // setShowPassword(true);
        } else {
          try {
            enqueueSnackbar('Please fill all the required fields!', { variant: 'error' })
          } catch (error) {}
        }
    };

    const isValid = () => {
        if (
          !isEmailValid ||
          !isMobileValid ||
          !isFirstNameValid ||
          !isLastNameValid ||
          !isPanNumberValid 
        )
          return false;
        return true;
    };

    // console.log('firstName',firstName)
    // console.log('lastName',lastName)
    // console.log('email',email)
    // console.log('mobile',mobile)
    // console.log('file', selectedFile)
    // console.log('accessType', accessType)
    // console.log('panNumber', panNumber)

  return (
    <div>
        {ReactDOM.createPortal(
          <Backdrop
            onClick={() => {
              dispatch(modalActions.addNewUserDisplay(false));
            }}
            className={classes.backdrop}
          />,
          document.getElementById("backdrop-root")
        )}
        {/* { !isMobile ? */}
            <Modal className={clsx(classes.modal, '-sm:p-0')}>
                <div className={classes.addUserCon}>
                    <div className={clsx(classes.addUserConTop)}>
                        <div className={classes.headerCon}>
                            <p className={classes.sub1}>Add new User</p>
                            <img src={cross}
                                onClick={() => {
                                    dispatch(modalActions.addNewUserDisplay(false));
                                }}
                                className={classes.crossImg}
                            />
                        </div>
                        {/* header for mobile less then 600px */}
                        <div className={`${classes.headerConMobile} px-[2rem]`}>
                            <div className={classes.crossImgCon}>
                                <img src={cross}
                                onClick={() => {
                                    dispatch(modalActions.addNewUserDisplay(false));
                                }}
                                className={classes.crossImg}
                                />
                            </div>
                            <div className={classes.sub1Con}>
                                <p className={classes.sub1}>Add new User</p>
                            </div>
                            <div className={classes.crossImgCon}>
                            <img src='' />
                            </div>
                        </div>
                        <div className={`${classes.personalDetailsCon} px-[2rem]`}>
                            <div className={classes.personalHead}>
                                <p className={classes.sub2}>Personal Details</p>
                                <p className={classes.sub3}>Fields with ‘*’ are mandatory</p>
                            </div>
                            <div className={classes.personalDetails}>
                                <AddUserForm
                                    firstName={firstName}
                                    setFirstName={setFirstName}
                                    lastName={lastName}
                                    setLastName={setLastName}
                                    email={email}
                                    setEmail={setEmail}
                                    mobile={mobile}
                                    setMobile={setMobile}
                                    oneTimeClicked={oneTimeClicked}
                                    setIsFirstNameValid={setIsFirstNameValid}
                                    setIsLastNameValid={setIsLastNameValid}
                                    setIsEmailValid={setIsEmailValid}
                                    countryCode={countryCode}
                                    setCountryCode={setCountryCode}
                                    setIsMobileValid={setIsMobileValid}
                                />
                            </div>
                            <div className={classes.accessType}>
                                <div className={classes.accessTypeHead}>
                                    <p className={classes.sub2}>Access Type</p>
                                </div>
                                <div className={classes.accessTypeCon}>
                                    <div className={classes.fullAccess}>
                                        <input type="radio"  className={classes.inputRadio} 
                                            value="ADMIN"
                                            checked={accessType === 'ADMIN'}
                                            onChange={handleAccessChange}
                                        />
                                        <div className={classes.fullAccessBox}>
                                            <div className={classes.fullAccessBoxTop}>
                                                <p className={classes.sub5}>Full Access</p>
                                                {/* <img src={alertCircleNew} className={classes.alertCircleNewImg}
                                                    // onMouseOver={() => setShowTooltip(true)}
                                                    // onMouseOut={() => setShowTooltip(false)}
                                                    onMouseEnter={() => setShowTooltip(!showTooltip)}
                                                    onMouseLeave={() => setShowTooltip(!showTooltip)}
                                                    /> */}
                                            </div>
                                            {/* {showTooltip && <AccessTooltip/> } */}
                                            <p className={classes.sub4}>Full access to Receivables, Customers, Payment Advice, Bank Accounts </p>
                                        </div>
                                    </div>
                                    <div className={classes.gray}></div>
                                    <div className={classes.fullAccess}>
                                        <input type="radio"  className={classes.inputRadio} 
                                            value="USER"
                                            checked={accessType === 'USER'}
                                            onChange={handleAccessChange}
                                        />
                                        <div className={classes.fullAccessBox}>
                                            <div className={classes.fullAccessBoxTop}>
                                                <p className={classes.sub5}>Limited Access</p>
                                                {/* <img src={alertCircleNew} className="w-[24px] h-[24px] " /> */}
                                            </div>
                                            <p className={classes.sub4}>Full access to Receivables, Customers, Payment Advice, Bank Accounts </p>
                                        </div>
                                    </div>
                                    <div className={classes.limitedAccess}></div>
                                </div>
                            </div>
                            <div className={classes.accessType}>
                                <div className={classes.accessTypeHead}>
                                    <p className={classes.sub2}>Upload PAN for User</p>
                                </div>
                                <div className={classes.panCon}>
                                    <TextInput
                                        text="PAN Number*"
                                        type="text"
                                        className={classes.input}
                                        enteredInput={panNumber}
                                        validation = {[(value) => (value ? value.trim() !== "" : false)]}
                                        inputValidity = {(value) => {
                                            setIsPanNumberValid(value);
                                        }}
                                        setInput={(value) => {
                                            setPanNumber(value);
                                        }}
                                        hasError = {oneTimeClicked && panNumber === ""}
                                        errorMessage = "Please enter a valid PAN number"
                                        placeholder="PAN Number*"
                                    />
                                    {/* <div className={classes.fileCon} onClick={handleFileUpload}>
                                        <img src={uploadCloud} className="w-[24px] h-[24px] " />
                                        <p className={classes.sub6}>Browse files or drag and drop</p>
                                        <input type="file" ref={inputRef} style={{ display: 'none' }} onChange={handleFileChange}/>
                                    </div> */}
                                    {uuid && (
                                        <div className='mt-[16px] w-[100%]'>
                                            <FileBrowserNew
                                                setFile={setFideoUsersPan}
                                                files={fideoUsersPan}
                                                uuid={uuid}
                                                isMobile={isMobile}
                                                validFormats={['document', 'image']}
                                                // hasError={oneTimeClicked && Object.keys(fideoUsersPan || {})?.length === 0}
                                                // errorMessage={Object.keys(fideoUsersPan || {})?.length === 0 ? "Please upload atleast one file" : "Please upload a valid file"}
                                                userType={"USER"}
                                                docType={"PAN"}
                                                docNum={Object.keys(fideoUsersPan || {})?.length + 1}
                                                userName={`${firstName} ${lastName}`}
                                                guideLineText={true && !isMobile}
                                                changeText={true}
                                                changeTextLine={'Upload PAN Card'}
                                                linkArr = {linkArr}
                                                setLinkArr = {setLinkArr}
                                                isActive = {true}
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={clsx(classes.addUserConBottom,'!px-[2rem]')}>
                        <div className={classes.addNow} onClick={handleSubmit} >
                            <p className={classes.addNowButton}>Add Now</p>
                        </div>
                    </div>
                </div>
            </Modal>
        //   :
        //   null
        {/* } */}
    </div> 
  )
}

export default AddNewUser