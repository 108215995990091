import React, { useState } from 'react';
import classes from "Pages/Admin/BankAccounts.module.scss";
import showArrow from "assets/Icons/showArrow.svg";
import ellipseImg from "assets/Icons/Ellipse.svg"
import { useSelector } from 'react-redux';


const VirtualBankData = ({
    active,
    virtualMap,
    data,
    img,
    setSelectedAcc,
    handleClick,
    showReconcile,
    setSelectedAccountDetails,
    tourAccess
}) => {
    const stepNumber = useSelector((state) => state.global.stepNumberInTour);
    let localMethod = virtualMap?.details?.LocalMethods;
    let internationalMethod = virtualMap?.details?.InternationalMethods;
    let disable = (localMethod == undefined  && internationalMethod == undefined) ? true : false;


  return (
    <div className={`${classes.virtualContainerParent} ${stepNumber === 4 && tourAccess && 'z-[400]'}`}>
       <div className={`${classes.virtualContainer}`}>
            <div className={stepNumber === 4 && tourAccess && classes.virtualBoxDummy}>
                <div className={`${classes.virtualBox}`} 
                    style={{
                        border: disable === true ? 'none' : ( active ? '2px solid #1E333F' : '2px solid #FFF'),
                        background: disable == true ? '#E5E8F1' : '',
                        cursor : disable == true ? '' : 'pointer'
                    }}
                    onClick={
                        disable === false
                            ?
                                () => {
                                    setSelectedAccountDetails(data)
                                    setSelectedAcc(data?.currencyDTO?.code)
                                }
                            :
                                null
                    }
                >
                    <div className={`${classes.virtualBox1}`}>
                        <div className={classes.virtualBox1p1}>
                        <div className={classes.virtualBox1Balance}>
                            <span className={`${classes.virtualBox1Country}`}>{data?.currencyDTO?.code}</span>
                            {/* <div className={classes.virtualBox1DotWrap}>
                                <img src={ellipseImg} alt='.' className={classes.virtualBox1DotWrap}/>
                            </div> */}
                            {/* <span className={`${classes.virtualBox2Amount}`}>{disable == true ? '--' : (data?.balance ? (data?.currencyDTO?.sign === "₹" ? data?.balance?.toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : data?.balance?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })) : '--')}</span> */}
                        </div>
                        <div className={`${classes.virtualBox1Img}`}>
                            <img src={img} alt='' className={`${classes.virtualBox1Img1}`} />
                        </div>
                        </div>
                        {/* <span className={`${classes.virtualBox2Balance}`}>Balance</span> */}
                        <span className={`${classes.virtualBox2Balance}`}>
                            {
                                data?.currencyDTO?.code
                                ?
                                    (disable == true ? 'No Balance' : (data?.balance ? (data?.currencyDTO?.sign === "₹" ? `${data?.currencyDTO?.sign}${data?.balance?.toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` : `${data?.currencyDTO?.sign}${data?.balance?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`) : 'No Balance'))
                                : '--'
                            }
                        </span>
                    </div>
                    {(!disable && data?.balance && showReconcile)
                        ?   (<div className={`${classes.virtualBox2}`}>
                                <button className={classes.virtualBox2Button} disabled={data?.balance === null || data?.balance <= 0} style={{ opacity: (data?.balance === null || data?.balance <= 0) ? '0.5' : '1', cursor: (data?.balance === null || data?.balance <= 0) ? 'not-allowed' : 'pointer' }} onClick={handleClick}>Reconcile</button>
                            </div>)
                        :
                            ''
                    }
                </div>
            </div>
            <div className={`${classes.virtualBoxBottom}`}>
                {active && disable == true ? '' : (active ? (<img src={showArrow} alt='arrow' className={`${classes.virtualBoxBottomImg}`}/>) : '')}
            </div>
       </div>
    </div>
  )
}

export default VirtualBankData