// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Tooltip_tooltip__E4JkF {
  position: relative;
}
.Tooltip_tooltip__E4JkF .Tooltip_content__\\+pLjq:hover::after {
  content: attr(content);
  background-color: var(--accent_black_900);
  color: white;
  border-radius: 0.8rem;
  padding: 0.8rem;
  position: absolute;
  width: max-content;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 2rem;
  transform: translate(-58%, 4rem);
  text-transform: none;
}
.Tooltip_tooltip__E4JkF .Tooltip_content__\\+pLjq:hover::before {
  content: "";
  position: absolute;
  top: 1.3rem;
  left: 0;
  border-width: 1.6rem 1.2rem 1.6rem 1.2rem;
  border-color: transparent;
  border-bottom-color: var(--accent_black_900);
  border-style: solid;
}`, "",{"version":3,"sources":["webpack://./src/Layout/Tooltip.module.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;AACJ;AACI;EACI,sBAAA;EACA,yCAAA;EACA,YAAA;EACA,qBAAA;EACA,eAAA;EACA,kBAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,iBAAA;EACA,gCAAA;EACA,oBAAA;AACR;AAEI;EACI,WAAA;EACA,kBAAA;EACA,WAAA;EACA,OAAA;EACA,yCAAA;EACA,yBAAA;EACA,4CAAA;EACA,mBAAA;AAAR","sourcesContent":[".tooltip{\n    position: relative;\n    \n    .content:hover::after{\n        content: attr(content);\n        background-color: var(--accent_black_900);\n        color: white;\n        border-radius: 0.8rem;\n        padding: 0.8rem;\n        position: absolute;\n        width: max-content;\n        font-weight: 500;\n        font-size: 1.4rem;\n        line-height: 2rem;\n        transform: translate(-58%, 4rem);\n        text-transform: none;\n    }\n\n    .content:hover::before{\n        content: \"\";\n        position: absolute;\n        top: 1.3rem;\n        left: 0;\n        border-width: 1.6rem 1.2rem 1.6rem 1.2rem;\n        border-color: transparent;\n        border-bottom-color: var(--accent_black_900);\n        border-style: solid;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tooltip": `Tooltip_tooltip__E4JkF`,
	"content": `Tooltip_content__+pLjq`
};
export default ___CSS_LOADER_EXPORT___;
