import classes from "Components/ReportTable/PurposeCodeDropdown.module.scss";
import { modalActions } from "Store/modal-redux";
import { useDispatch, useSelector } from "react-redux";
import useClickOutside from "hooks/useClickOutside";
import Close from "Layout/Close";
import ReactDOM from "react-dom";
import Backdrop from 'Layout/Backdrop'
import clsx from 'clsx'
import checkIcon from 'assets/Icons/check.svg'

const PurposeCodeDropdown = (props) => {
  const { heading, option = [], purposeCode } = props
  const dispatch = useDispatch();
  const purposeCodeDropDown = useSelector((state) => state.modal.purposeCodeDropDown);
  const optRef = useClickOutside(() => dispatch(modalActions.purposeCodeDropDown(false)))
  const handleOptionClick = (option) => {
    props.onClick(option)
    dispatch(modalActions.purposeCodeDropDown(false))
  }

  return (
    <>
      {purposeCodeDropDown &&
        ReactDOM.createPortal(
          <Backdrop className={clsx(classes.backdrop, 'z-50')} />,
          document.getElementById('backdrop-root')
        )}
      <div ref={optRef} className={`${classes.action} ${props.className}`}>
        {purposeCodeDropDown && (
          <div className={classes.action_all}>
            <div className={classes.header}>
              <div className={classes.heading}>{heading}</div>
              <Close
                onClick={() => dispatch(modalActions.purposeCodeDropDown(false))}
                className={classes.close}
              />
            </div>
            <div className={classes.options}>
              {option?.map((opt, index) => (
                <div
                  className={clsx(
                    classes.opt,
                    'cursor-pointer flex flex-col items-start',
                    'border-b border-[#ECEFF2]',
                  )}
                  key={`${opt?.text}-${index}`}
                  onClick={() => handleOptionClick(opt)}
                >
                  <div className={classes.purposeCodeText}>{opt?.purposeCode}</div>
                  <div className={classes.descriptionDiv}>
                    <p className={classes.descriptionText}>{opt?.description}</p>
                    {purposeCode === opt?.purposeCode && <img src={checkIcon} alt="check" className="w-[20px] h-[20px] mb-[14px]"/>}
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </>
  )
}
export default PurposeCodeDropdown;
