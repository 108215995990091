import { createSlice } from "@reduxjs/toolkit";

const userInitialState = {
    users : [],
}

export const UsersSlice = createSlice({
    name : 'user-slice',
    initialState : userInitialState,
    reducers : {
        setUsers(state,action){
            state.users = action.payload
        }
    }
})

export const UsersActions = UsersSlice.actions;