// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media (max-width: 850px) {
  .VendorReportTable_table__Kl5Dv {
    display: none;
  }
}
.VendorReportTable_table__Kl5Dv .VendorReportTable_cell_header1__3-kSv {
  padding: 2rem 1.2rem;
  background-color: #F7F8FB;
  text-transform: uppercase;
  font-size: 1.2rem;
  line-height: 1.6rem;
  font-weight: 600;
  text-align: start;
  vertical-align: middle;
  color: rgba(30, 51, 63, 0.4);
}
.VendorReportTable_table__Kl5Dv .VendorReportTable_cell_header1__3-kSv .VendorReportTable_cell_header2__HISRU {
  display: flex;
  align-items: center;
}
.VendorReportTable_table__Kl5Dv .VendorReportTable_cell_header1__3-kSv .VendorReportTable_actionHeader__5Togo {
  display: flex;
  justify-content: center;
  align-items: center;
}
.VendorReportTable_table__Kl5Dv .VendorReportTable_row__dvB3X:hover {
  background-color: #F6F8FB;
}
.VendorReportTable_table__Kl5Dv .VendorReportTable_row__dvB3X .VendorReportTable_cell__f4ik4 {
  border-bottom: 1px dashed var(--accent_grey_300);
  padding: 2rem 1.2rem;
  font-size: 1.4rem;
  line-height: 2rem;
  color: rgba(30, 51, 63, 0.8);
}
.VendorReportTable_table__Kl5Dv .VendorReportTable_row__dvB3X .VendorReportTable_cell_action__1Oj8W {
  display: flex;
  justify-content: center;
  align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/Components/ReportTable/VendorReportTable.module.scss"],"names":[],"mappings":"AACI;EADJ;IAEQ,aAAA;EACN;AACF;AACI;EACI,oBAAA;EACA,yBAAA;EACA,yBAAA;EACA,iBAAA;EACA,mBAAA;EACA,gBAAA;EACA,iBAAA;EACA,sBAAA;EACA,4BAAA;AACR;AACQ;EACI,aAAA;EACA,mBAAA;AACZ;AAMQ;EACI,aAAA;EACA,uBAAA;EACA,mBAAA;AAJZ;AASQ;EACI,yBAAA;AAPZ;AAUQ;EACI,gDAAA;EACA,oBAAA;EACA,iBAAA;EACA,iBAAA;EACA,4BAAA;AARZ;AAUY;EACI,aAAA;EACA,uBAAA;EACA,mBAAA;AARhB","sourcesContent":[".table{\n    @media (max-width : 850px) {\n        display: none;\n    }\n\n    .cell_header1{\n        padding: 2rem 1.2rem;\n        background-color: #F7F8FB;\n        text-transform: uppercase;\n        font-size: 1.2rem;\n        line-height: 1.6rem;\n        font-weight: 600;\n        text-align: start;\n        vertical-align: middle;\n        color: rgba(30, 51, 63, 0.4);\n\n        .cell_header2{\n            display: flex;\n            align-items: center;\n        }\n\n        .sorting{\n\n        }\n\n        .actionHeader{\n            display: flex;\n            justify-content: center;\n            align-items: center;\n        }\n    }\n\n    .row{\n        &:hover{\n            background-color: #F6F8FB;\n        }\n\n        .cell{\n            border-bottom: 1px dashed var(--accent_grey_300);\n            padding: 2rem 1.2rem;\n            font-size: 1.4rem;\n            line-height: 2rem;\n            color: rgba(30, 51, 63, 0.8);\n    \n            &_action{\n                display: flex;\n                justify-content: center;\n                align-items: center;\n            }\n        }\n    }\n\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table": `VendorReportTable_table__Kl5Dv`,
	"cell_header1": `VendorReportTable_cell_header1__3-kSv`,
	"cell_header2": `VendorReportTable_cell_header2__HISRU`,
	"actionHeader": `VendorReportTable_actionHeader__5Togo`,
	"row": `VendorReportTable_row__dvB3X`,
	"cell": `VendorReportTable_cell__f4ik4`,
	"cell_action": `VendorReportTable_cell_action__1Oj8W`
};
export default ___CSS_LOADER_EXPORT___;
