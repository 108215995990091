import ReactDOM from 'react-dom'
import ContactInfo from 'Components/Global/ContactInfoNew'
import classes from 'Components/ReportTable/ReceivablesReportTable.module.scss'
import sorting from 'assets/Images/sorting-icon.svg'
import crossSquare from 'assets/Icons/cross-square.svg'
import markDone from 'assets/Icons/tickinsidecircleblack.svg'
import Status from 'Components/ReportTable/Status'
import Action from 'Components/ReportTable/Action'
import eye from 'assets/Images/eye.svg'
import sendIcon from 'assets/Icons/send-link-black.svg'
import edit from 'assets/Images/edit.svg'
import Share from 'assets/Icons/share.svg'
import ReportTableLayout from 'Components/ReportTable/ReportTableLayout'
import Tooltip2 from 'Layout/Tooltip'
import { Tooltip } from "antd"
import moreInfo from 'assets/Images/moreInfo.svg'
import dayjs from 'dayjs'
import { useCallback, useEffect, useRef, useState } from 'react'
import MobileReceivableReportTable from 'Components/ReportTable/MobileReceivableReportTable'
import clsx from 'clsx'
import PaymentStatusTag from './PaymentStatusTag'
import { modalRefState } from 'recoil/layoutRef'
import { useRecoilValue } from 'recoil'
import { Pagination } from 'antd'
import quoteIcon from 'assets/Icons/message-square.svg'
import {
    showInvoiceVerificationPrompt,
    showPaymentLinkModal,
    // showInvoiceCancelledModal,
    showInvoicePaidOutsideModal,
} from 'utils/layout'
import { useDispatch, useSelector } from 'react-redux'
import UseApiCall from 'hooks/useApiCall'
import { apiGetInvoiceDetails, apiDownloadFiraReportUrl, apiDeleteInvoice } from 'services'
import { ReceivablesActions } from 'Store/customer-redux'
import { modalActions } from 'Store/modal-redux'
import InvoiceDetailsModal from 'Components/molecule/InvoiceDetailsModal/InvoiceDetailsModal'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import EditInvoice from "Components/Invoice/EditInvoice";
import { enqueueSnackbar } from 'notistack';
import { formatNumberToLocaleString } from 'hooks/formatNumberToLocaleString';
import Shimmer from 'Layout/Shimmer'
import useElementOnScreen from 'hooks/useElementOnScreen'
import { GlobalActions } from 'Store/global-redux'
import deleteIcon from "assets/Icons/delete-icon.svg"
import { downloadWithInterval } from "hooks/downloadFiles";

const ReceivablesReportTableDraft = (props) => {
    const tableRef = useRef(null);
    const [divPos, setDivPos] = useState(null);
    let { section } = useParams()
    const location = useLocation()
    const [activeTab, setActiveTab] = useState('details')
    const modalRef = useRecoilValue(modalRefState)
    const dispatch = useDispatch()
    const [selectedInvoice, setSelectedInvoice] = useState(null)
    // const [selectedInvoiceId, setSelectedInvoiceId] = useState(null)
    const showInvoiceDisplay = useSelector(
        (state) => state.modal.showInvoiceDisplay
    )
    const editInvoiceDisplay = useSelector((state) => state.modal.editInvoiceDisplay)
    const isCustomerDetailsModalOpen = useSelector((state) => state.modal.showCustomerDisplay);
    const scrollEffect = useSelector((state) => state.global.scrollEffect);
    const scrollYValue = useSelector((state) => state.global.scrollYValue);

    const theadRef = useRef(null);
    const tbodyRef = useRef(null);

    const { customerCounter, statusCounter, showDateCounter, activeDate, selectedInvoiceId, setSelectedInvoiceId } = props;

    const [containerTableRef, isTableVisible] = useElementOnScreen({
        root: null,
        rootMargin: "0px",
        threshold: 0.9
    })

    const optionArr = [
        {
            text: 'View',
            icon: eye,
            onClick: (cellData) => {
                if (cellData?.status?.toLowerCase() !== "in draft") handleInvoiceSelection(cellData?.id);
            },
        },
        // { text: 'Edit', icon: edit },
        {
            text: 'Send Link',
            icon: sendIcon,
            // disabled: (cellData?.status?.split(' ')?.join('')?.toLowerCase() !== "cancelled" && cellData?.status?.split(' ')?.join('')?.toLowerCase() !== "settledoutside") ? false : true,
            onClick: (cellData) => {
                const receivablesCustomerStatus = cellData?.status?.split(' ')?.join('')?.toLowerCase();
                if (receivablesCustomerStatus !== "cancelled" && receivablesCustomerStatus !== "settledoutside") {
                    if (cellData?.status?.toLowerCase() !== 'in review') {
                        if (cellData?.status?.toLowerCase() === 'created' || cellData?.status?.toLowerCase() === 'partially paid' || cellData?.status?.toLowerCase() === 'link sent' || (cellData?.status?.toLowerCase() === 'in progress' && Number(cellData?.pendingAmount || 0) > 0)) {
                            showPaymentLinkModal(modalRef, {
                                invoiceId: cellData?.invoiceId,
                                paymentLink: cellData?.paymentLink,
                            })
                        }
                    }
                    else
                        showInvoiceVerificationPrompt(modalRef, {
                            invoiceId: cellData?.invoiceId,
                        })
                }
            },
        },
        {
            text: 'Edit',
            icon: edit,
            // disabled: cellData?.status?.toLowerCase() === 'in draft' ? false : true,
            onClick: (cellData) => {
                // if (cellData?.status.toLowerCase() === 'in draft' || cellData?.status.toLowerCase() ===  'in review' ||  cellData?.status.toLowerCase() === 'created'
                // ||  cellData?.status.toLowerCase() === 'in progress' ||  cellData?.status.toLowerCase() === 'link sent' ||  cellData?.status.toLowerCase() === 'settled')
                // {
                //     dispatch(modalActions.editInvoice())
                //     setSelectedInvoiceId(cellData?.id)
                // }

                if (cellData?.createdBy === "create-invoice" && cellData?.status.toLowerCase() === 'in draft') {
                    dispatch(modalActions.setShowDraftEditInvoice(true))
                    setSelectedInvoiceId(cellData?.id)
                } else {
                    dispatch(modalActions.editInvoice())
                    setSelectedInvoiceId(cellData?.id)
                }
            },
        },
        {
            text: 'Settled Outside Fideo',
            icon: markDone,
            onClick: (cellData) => {
                if (true)
                    showInvoicePaidOutsideModal(modalRef, {
                        cellData: cellData,
                        invoiceTab: false,
                    })
            },
        },
        {
            text: 'Delete',
            icon: deleteIcon,
            onClick: (cellData) => {
                if (cellData?.status && cellData?.status?.toLowerCase() === 'in draft')
                    handleInvoiceDelete(cellData?.id);
            },
        },
        {
            text: 'Download FIRA',
            icon: Share,
            onClick: (cellData) => {
                if (cellData?.firaStatus && cellData?.firaStatus?.toLowerCase() === 'generated')
                    handleReportDownload(cellData?.invoiceId);
            },
        },
        // {
        //   text: 'Mark as Cancelled',
        //   icon: crossSquare,
        //   onClick: (cellData) => {
        //     if (true)
        //       showInvoiceCancelledModal(modalRef, {
        //         cellData: cellData,
        //       })
        //   },
        // },
        // { text: 'Copy', icon: copy },
    ]

    const [downloadFiraReport, getFileUrlFetching] = UseApiCall(
        apiDownloadFiraReportUrl,
        (res) => {
            const fileUrls = res?.data;
            downloadWithInterval(fileUrls, 1000);
        }, (err) => {
            enqueueSnackbar("Error in downloading Fira Report.", { variant: 'error' })
        }
    )

    const handleReportDownload = async (invoiceId) => {
        await downloadFiraReport({ invoiceId })
    }

    const getTime = useCallback(
        (date) => {
            const invoiceDate = dayjs(date)
            const now = dayjs()
            return now.diff(invoiceDate, 'days')
        },
        [props]
    )

    const [getSelectedInvoice] = UseApiCall(apiGetInvoiceDetails, (res) => {
        dispatch(ReceivablesActions.getSelectedInvoice(res?.data))
        setSelectedInvoice(res?.data)
        dispatch(modalActions.showInvoiceDisplay())
    }, (err) => {
        enqueueSnackbar(`${err?.data?.error_response?.message} ${err?.data?.error_response?.errors !== null ? JSON.stringify(err?.data?.error_response?.errors[0]) : ""}`, { variant: 'error' })
    })

    const [deleteInvoice, fetchingDeleteInvoice, deleteInvoiceData, deleteInvoiceError] = UseApiCall(apiDeleteInvoice,
        (res) => {
            dispatch(ReceivablesActions.setUpdateReceivables());
            enqueueSnackbar('Invoice deleted successfully.', { variant: 'success' });
        },
        (err) => {
            enqueueSnackbar(`${err?.data?.error_response?.message || "Error occured while deleting the invoice."}`, { variant: 'error' })
        }
    )

    const handleInvoiceDelete = async (invoiceId) => {
        deleteInvoice({ invoiceId })
    }

    const handleInvoiceSelection = async (invoiceId) => {
        localStorage.setItem('zoomkaindex', '0')
        await getSelectedInvoice({ invoiceId })
        let tab = 'details'
        if (section === 'details') {
            tab = 'details'
            window.history.pushState(
                {},
                undefined,
                `/receivables/${tab}?invoiceId=${invoiceId}`
            )
        } else if (section === 'paymentStatus') {
            tab = 'payment-status'
            window.history.pushState(
                {},
                undefined,
                `/receivables/${tab}?invoiceId=${invoiceId}`
            )
        } else if (section === 'bankAccount') {
            tab = 'bank-account'
            window.history.pushState(
                {},
                undefined,
                `/receivables/${tab}?invoiceId=${invoiceId}`
            )
        }
    }
    const itemRender = (_, type, originalElement) => {
        if (type === 'prev') {
            return <a>Previous</a>
        }
        if (type === 'next') {
            return <a>Next</a>
        }
        return originalElement
    }
    const handleShowInvoiceDisplayChange = () => {
        window.history.pushState({}, undefined, `/receivables`)
    }

    useEffect(() => {
        // keys: details, paymentStatus, bankAccount
        const searchParams = new URLSearchParams(location.search)
        const invoiceId = searchParams.get('invoiceId')

        if (section === 'details') {
            setActiveTab('details')
        } else if (section === 'payment-status') {
            setActiveTab('paymentStatus')
        } else if (section === 'bank-account') {
            setActiveTab('bankAccount')
        }
        if (invoiceId && selectedInvoice?.invoiceId !== invoiceId) {
            handleInvoiceSelection(invoiceId)
        }
    }, [section, location])

    useEffect(() => {
        // if (!showInvoiceDisplay) localStorage.removeItem('zoomkaindex')
        if (isCustomerDetailsModalOpen) return;
        if (!showInvoiceDisplay) {
            handleShowInvoiceDisplayChange()
        }
    }, [showInvoiceDisplay])

    function abbreviateString(str) {
        if (str.length > 12) {
            return str.substr(0, 5) + '...' + str.substr(-4);
        }
        return str;
    }

    useEffect(() => {
        const handleScroll = (event) => {
            if (event.target.id === 'thead') {
                tbodyRef.current.scrollLeft = theadRef.current.scrollLeft;
            }
            else if (event.target.id === 'tbody') {
                theadRef.current.scrollLeft = tbodyRef.current.scrollLeft;
            }
        };

        const thead = theadRef.current;
        const tbody = tbodyRef.current;

        thead.addEventListener('scroll', handleScroll);
        tbody.addEventListener('scroll', handleScroll);

        return () => {
            thead.removeEventListener('scroll', handleScroll);
            tbody.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className="">
            {showInvoiceDisplay &&
                selectedInvoice &&
                ReactDOM.createPortal(
                    <InvoiceDetailsModal
                        activeTab={activeTab}
                        invoice={selectedInvoice}
                        mobileTableData
                    />,
                    document.getElementById('modal-root')
                )}
            {editInvoiceDisplay &&
                ReactDOM.createPortal(
                    <EditInvoice
                        selectedInvoiceId={selectedInvoiceId}
                        nextPageFunctionality={() => dispatch(modalActions.removeAll())}
                    />,
                    document.getElementById("modal-root")
                )}
            {
                // props.tableData && props.tableData?.length > 0 &&
                (
                    <>
                        <div className={classes.allWrap}>
                            <div className={clsx(`${classes.table} ${classes.reportTableWrapTop}`)} id="thead" ref={theadRef}>
                                <thead
                                // className={clsx(classes.thead , 'flex w-[100%]')}
                                >
                                    {props?.tableHeader?.map((header, index) => (
                                        <th
                                            key={`${header}-${index}`}
                                            className={classes.cell_header1}
                                            style={{
                                                width: `${header.width}%`,
                                                minWidth: `${header.minWidth}px`,
                                                maxWidth: `${header.minWidth}px`
                                            }}
                                            valign="top"
                                        >
                                            <div className={classes.cell_header2}>
                                                <div className={classes.head} style={{ color: `${header.active ? "#1E333F" : "rgba(30, 51, 63, 0.4)"}`, width: "fit-content", paddingTop: header.header !== "Due Date" ? '0px' : '2.8px' }} dangerouslySetInnerHTML={{ __html: (header.header.includes("amount") || (header.header.includes("aving"))) ? `${header.header.split(" ")[0]}<br/>${header.header.split(" ")[1]}` : `${header.header}` }}></div>
                                                {header.button && <div className={clsx(classes.sortingWrap, header.active && 'pt-[2px] pl-[2px]')}
                                                >
                                                    {header.button && (<img
                                                        src={header.icon}
                                                        alt="sorting-icon"
                                                        className={classes.sorting}
                                                        style={{ cursor: `${header.cursor ? "pointer" : "default"}` }}
                                                        onClick={header.handleClick}
                                                    />)}
                                                </div>}

                                            </div>
                                        </th>
                                    ))}
                                    <th className={classes.cell_header1} valign="top"
                                        style={{ width: '2.5%', minWidth: '100px', maxWidth: '100px' }}
                                    >
                                        <div
                                            className={`${classes.cell_header2} ${classes.actionHeader}`}
                                        >
                                            <div className={classes.head} style={{ zIndex: '50' }} >Action</div>
                                        </div>
                                    </th>
                                </thead>
                            </div>
                            <div className={`${classes.reportTableWrap}`} id="tbody" ref={tbodyRef}>
                                <ReportTableLayout className={clsx(classes.table, 'pt-[0px]')}>
                                    <tbody className={classes.tbody}>
                                        {props.loading
                                            ?
                                            [1, 3, 4]?.map((item, index) => (
                                                <Shimmer key={index} className="w-[100%] h-[80px] mt-[12px]" />
                                            ))
                                            :
                                            props?.tableData?.length > 0 && props?.tableData?.filter((item) => item.status.split(' ').join('').toLowerCase() === "indraft")?.map((data, index) => (
                                                <tr
                                                    key={data.id}
                                                    className={classes.row}
                                                    onClick={() => {
                                                        if (data?.status !== "In Draft") handleInvoiceSelection(data.id)
                                                        else {
                                                            if (data?.createdBy === "create-invoice") {
                                                                dispatch(modalActions.setShowDraftEditInvoice(true))
                                                                setSelectedInvoiceId(data?.id)
                                                            } else {
                                                                dispatch(modalActions.editInvoice())
                                                                setSelectedInvoiceId(data?.id)
                                                            }
                                                        }
                                                    }}
                                                >
                                                    <td
                                                        className={`${classes.cell} ${classes.invoice}`}

                                                        style={{
                                                            userSelect: 'none',
                                                            cursor: 'pointer',
                                                            width: `${props.tableHeader[0].width}%`,
                                                            minWidth: `${props.tableHeader[0].minWidth}px`,
                                                            maxWidth: `${props.tableHeader[0].minWidth}px`
                                                        }}
                                                        valign="top"
                                                    >
                                                        {dayjs(data?.createdTime).local().format(`D MMM 'YY`)}
                                                    </td>
                                                    <td
                                                        className={`${classes.cell} ${classes.invoice}`}

                                                        style={{
                                                            userSelect: 'none',
                                                            cursor: 'pointer',
                                                            width: `${props.tableHeader[1].width}%`,
                                                            minWidth: `${props.tableHeader[1].minWidth}px`,
                                                            maxWidth: `${props.tableHeader[1].minWidth}px`
                                                        }}
                                                        valign="top"
                                                    >
                                                        {
                                                            data?.invoiceId?.length > 12
                                                                ?
                                                                <Tooltip
                                                                    title={<div
                                                                    // style={{ color: '#1e333fcc' }}
                                                                    >{data.invoiceId}</div>}
                                                                    placement="bottomLeft"
                                                                // arrow={false}
                                                                // color={"white"}
                                                                >
                                                                    {abbreviateString(data?.invoiceId, 12)}
                                                                </Tooltip>
                                                                :
                                                                data?.invoiceId ? data?.invoiceId : "--"
                                                        }
                                                    </td>
                                                    <td
                                                        className={classes.cell}
                                                        // style={{width:'18.57%',minWidth:'191.22px',maxWidth:'191.22px'}}
                                                        style={{
                                                            width: `${props.tableHeader[2].width}%`,
                                                            minWidth: `${props.tableHeader[2].minWidth}px`,
                                                            maxWidth: `${props.tableHeader[2].minWidth}px`
                                                        }}

                                                        valign="top"
                                                    >
                                                        {
                                                            data?.customer === null
                                                                ?
                                                                '--'
                                                                :
                                                                <ContactInfo
                                                                    name={data?.customer?.customerName}
                                                                    email={data?.customer?.emailAddress}
                                                                    country={data?.customer?.country}
                                                                    countryName={data?.customer?.countryName}
                                                                    classNameCountry={classes.classNameCountry}
                                                                />
                                                        }
                                                    </td>
                                                    <td
                                                        className={classes.cell}
                                                        // style={{width:"11.51%",minWidth:'118.56px',maxWidth:'118.56px'}}
                                                        style={{
                                                            width: `${props.tableHeader[3].width}%`,
                                                            minWidth: `${props.tableHeader[3].minWidth}px`,
                                                            maxWidth: `${props.tableHeader[3].minWidth}px`
                                                        }}

                                                        valign="top"
                                                    >
                                                        <div className={classes.amount}>
                                                            <p className="flex">{data?.invoiceCurrency?.sign}</p>
                                                            <p className="flex">{formatNumberToLocaleString((data?.receivingAmount), 2, 2)}</p>
                                                        </div>

                                                        <div className={classes.amount} style={{ color: "#1e333f80" }}>
                                                            {data?.conversionInvoiceCurrency && data?.convertedInvoiceAmount ? (
                                                                <span>
                                                                    {`${data?.conversionInvoiceCurrency?.sign}${data?.convertedInvoiceAmount?.toLocaleString(
                                                                        'en-IN'
                                                                    )}`}
                                                                </span>
                                                            ) : ('')
                                                            }
                                                        </div>
                                                    </td>

                                                    <td
                                                        className={`${classes.cell} ${classes.invoice} `}

                                                        style={{
                                                            width: `${props.tableHeader[4].width}%`,
                                                            minWidth: `${props.tableHeader[4].minWidth}px`,
                                                            maxWidth: `${props.tableHeader[4].minWidth}px`
                                                        }}
                                                        valign="top"
                                                    >
                                                        {data?.dueDate ? dayjs(data?.dueDate).local().format(`D MMM 'YY`) : "--"}
                                                    </td>
                                                    <td
                                                        className={classes.cell}
                                                        style={{
                                                            width: `${props.tableHeader[5].width}%`,
                                                            minWidth: `${props.tableHeader[5].minWidth}px`,
                                                            maxWidth: `${props.tableHeader[5].minWidth}px`
                                                        }}

                                                        valign="top"
                                                    >
                                                        {
                                                            data?.status.split(' ').join('').toLowerCase() === 'settledggoutside'
                                                                ?
                                                                <p>Settled Outside Fideo</p>
                                                                :
                                                                <>
                                                                    <PaymentStatusTag status={data.status} type="light" />
                                                                    {/* <div className={classes.text}>
                                                                    {
                                                                      getTime(data.lastUpdatedTime) === 0
                                                                      ? "Today"
                                                                      : (
                                                                        `${getTime(data.lastUpdatedTime)} day${getTime(data.lastUpdatedTime) > 1 ? 's' : ''} ago`
                                                                      )
                                                                    }
                                                                  </div> */}
                                                                    <div className={classes.text}
                                                                        style={{ paddingLeft: "8px" }}>
                                                                        {
                                                                            (data?.status.split(' ').join('').toLowerCase() === 'indraft' && data?.completionPercentage) ? `${data?.completionPercentage}% Complete` : "80% Complete"
                                                                        }
                                                                    </div>
                                                                    <div className={classes.text}
                                                                        style={{ paddingLeft: "8px" }}>
                                                                        {
                                                                            data?.status.split(' ').join('').toLowerCase() === 'settledoutside'
                                                                                ? "Outside Fideo"
                                                                                : (
                                                                                    data?.status.split(' ').join('').toLowerCase() === 'settled'
                                                                                        ? "Via Fideo"
                                                                                        : ""
                                                                                )
                                                                        }
                                                                    </div>
                                                                </>
                                                        }
                                                    </td>

                                                    <td className={classes.cell}
                                                        style={{ width: '2.5%', minWidth: '100px', maxWidth: '100px' }}
                                                        valign="top">
                                                        <div className={classes.cell_actionDraft}>
                                                            <Action
                                                                option={
                                                                    data?.status.toLowerCase() === 'partially paid'
                                                                        ?
                                                                        [...optionArr.slice(1, 5)]
                                                                        :
                                                                        (
                                                                            data?.status.toLowerCase() === 'in draft'
                                                                                ? [...optionArr.slice(0, 5)]
                                                                                :
                                                                                (
                                                                                    data?.status.toLowerCase() === 'in review' ||
                                                                                        data?.status.toLowerCase() === 'created' ||
                                                                                        data?.status.toLowerCase() === 'link sent' ||
                                                                                        data?.status.toLowerCase() === 'in progress'
                                                                                        ? [...optionArr.slice(1, 5)]
                                                                                        : ((data?.status?.toLowerCase() === 'settled') ? [...optionArr.slice(1, 4)] : [...optionArr.slice(1, 4)])
                                                                                )
                                                                        )
                                                                }
                                                                cellData={data}
                                                            />
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </ReportTableLayout>
                                {
                                    props?.tableData?.length > 0 &&
                                    <div className={classes.paginationWrap}>
                                        <Pagination showSizeChanger={false} current={props.current} total={props.total} defaultPageSize={30} itemRender={itemRender} onChange={props.handlePaginate} />
                                    </div>
                                }
                            </div>
                            {/* {
            props?.tableData?.length > 0 &&
            <div className={classes.paginationWrap}>
              <Pagination showSizeChanger={false} current={props.current} total={props.total} defaultPageSize={30} itemRender={itemRender} onChange={props.handlePaginate} />
            </div>
          } */}
                        </div>

                        {(props?.tableData.length === 0 && !props.loading) && (customerCounter >= 1 || statusCounter >= 1 || (activeDate === 'one' || activeDate === 'three' || activeDate === 'six')) ?
                            (<div
                                style={{
                                    textAlign: 'center',
                                    width: '100%',
                                    marginTop: '4rem',
                                    fontSize: '2.5rem'
                                }}
                                className={classes.table}
                            >
                                No Result Found.
                            </div>) :
                            (props?.tableData.length === 0 && !props.loading) ?
                                (<div
                                    style={{
                                        textAlign: 'center',
                                        width: '100%',
                                        marginTop: '4rem',
                                        fontSize: '2.5rem'
                                    }}
                                    className={classes.table}
                                >
                                    No Recent Transactions Yet.
                                </div>) :
                                null
                        }

                        <MobileReceivableReportTable
                            tableData={props?.mobileTableData?.filter((item) => item.status.split(' ').join('').toLowerCase() === "indraft")}
                            option={optionArr}
                            handleInvoiceSelection={handleInvoiceSelection}
                            loading={props.loading}
                            current={props.current}
                            total={props.total}
                            itemRender={itemRender}
                            handlePaginate={props.handlePaginate}
                            mobileFilterOpen={props?.mobileFilterOpen}
                            mobileSortOpen={props?.mobileSortOpen}
                        />
                    </>
                )}
        </div>
    )
}

export default ReceivablesReportTableDraft;
