import underverification from 'assets/Images/under-verification2.svg'

const UnderVerification = (props) => {
  return (
    <div
      style={{
        backgroundColor: '#fff',
        borderRadius: '1.2rem',
        height: 'max-content',
        // padding: '2rem',
        marginTop: 'min(4.8rem, 48px)'
      }}
    >
      <div className="uv-parent backdrop-blur-xl border border-[#ECEFF2] rounded-[12px] flex flex-col flex-wrap justify-center items-center h-[326px]">
        <div className="w-[80%] flex flex-col flex-wrap justify-center items-center">
          <img src={underverification} alt="Under Verification" />
          <div className="uv-subtext font-normal text-center text-[#181C30] mt-[12px]" style={{
              fontSize: 16,
              maxWidth: 376,
          }}>
            {props.messageText}
          </div>
        </div>
      </div>
    </div>
  )
}
export default UnderVerification
