// import Action from 'Components/ReportTable/Action'
// import ContactInfo from 'Components/Global/ContactInfo'
import classes from './MobileInvoiceTable.module.scss'
import ReportTableLayout from 'Components/ReportTable/ReportTableLayout'
// import PaymentStatusTag from 'Components/ReportTable/PaymentStatusTag'
import clsx from 'clsx'
import { useDispatch } from 'react-redux'
// import { modalActions } from 'Store/modal-redux'
// import { apiGetInvoiceDetails } from 'services'
// import UseApiCall from 'hooks/useApiCall'
// import { CustomersActions, ReceivablesActions } from 'Store/customer-redux'
import ArrowIcon from "assets/Icons/chevron-right.svg";
import DueIcon from "assets/Icons/due-icon.svg";
import Button from 'Layout/Button'
import dayjs from 'dayjs'
import { formatNumberToLocaleString } from 'hooks/formatNumberToLocaleString';

const MobileInvoiceTable = (props) => {
  // const dispatch = useDispatch();
  // const [getSelectedInvoice] = UseApiCall(apiGetInvoiceDetails, (res) => {
  //   dispatch(ReceivablesActions.getSelectedInvoice(res?.data))
  //   dispatch(CustomersActions.setSelectedInvoice(res?.data))
  //   dispatch(CustomersActions.setActiveInvoiceTab("details"))
  //   dispatch(modalActions.showInvoiceDisplay())
  // })

  // const handleInvoiceSelection = async (invoiceId) => {
  //   await getSelectedInvoice({ invoiceId })
  // }

  const checkedInvoiceId = (event, invoiceId, price, currency) => {

    if (props.selectedInvoices?.length < 1000) {
      !event.target.checked
        ? props.setSelectedInvoices(props.selectedInvoices.filter((invoice) => invoice.invoiceId !== invoiceId))
        : props.setSelectedInvoices((prev) => [...prev, {invoiceId, price, currency}]);
    } else {
      return false;
    }
  };

  if (props.tableData?.length > 0)
    return (
        <ReportTableLayout className={`${classes.table} ${props.className}`}>
        {props.tableData.map((data, index) => {
          const {
            invoiceId,
            invoiceAmount,
            invoiceCurrency,
          } = data
          return (
            <div key={`${data.email}-${index}`} className={classes.row}>
              <div className={classes.infoOne}>
                <div
                  className={clsx(classes.invoice, 'flex items-center gap-[6px]')}
                  // onClick={() => {
                  //   handleInvoiceSelection(invoiceId)
                  // }}
                >
                  <input
                    type="checkbox"
                    name="selectall"
                    className={classes.termsCheckboxForInvoice}
                    id="selectall"
                    style={{ cursor: "pointer" }}
                    checked={props.selectedInvoices.find(
                      (t) => {
                        return t.invoiceId === data?.invoiceId
                      }
                    )}
                    onChange={(e) => checkedInvoiceId(e, data?.invoiceId, data?.invoiceAmount, data?.invoiceCurrency)}
                  />
                  <div
                    onClick={() => {
                      data?.paymentLink && window.open(data?.paymentLink, "_self");
                    }}
                    // style={{ cursor: "pointer" }}
                  >
                    {invoiceId}
                  </div>
                </div>
                <Button
                  text={"Pay"}
                  icon={ArrowIcon}
                  onClick={() => props.handlePayment(data?.invoiceAmount?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }), data.invoiceCurrency)}
                  btnClassIcon={`order-2 w-[16px] h-[16px] ml-[0px] ${props.selectedInvoices?.length > 0 && 'opacity-50'}`}
                  className={clsx(classes.btn, `bg-[#FFF] border rounded-[6px] px-[11px] py-[4px] border-solid border-[2px] border-[#181C30] h-[28px] gap-[2px]`, props.selectedInvoices?.length > 0 && 'cursor-not-allowed border-[#808080]')}
                  btnClassText={clsx(
                    props.selectedInvoices?.length > 0 ? `text-[#808080]` : `text-[#000]`
                  )}
                  disabled={props.selectedInvoices?.length > 0}
                />
                {/* <Action option={props.option} className={classes.action} cellData={data}/> */}
              </div>
              <div
                className={classes.infoTwo}
                onClick={() => {
                  data?.paymentLink && window.open(data?.paymentLink, "_self");
                }}
                // style={{ cursor: "pointer" }}
              >
                  <div className={clsx(classes.amountText, '!text-[#1E333F50]')}>Pending Amount</div>
                  <div className={clsx(classes.amountText, 'flex gap-[8px]')}>
                    {data.isOverDue === true &&
                      <img
                        src={DueIcon}
                        alt="due-icon"
                        className=""
                      />
                    }
                    <div>
                      {invoiceCurrency?.sign}{formatNumberToLocaleString((invoiceAmount), 2, 2)}
                    </div>
                  </div>
              </div>
              <div
                className={classes.infoTwo}
                onClick={() => {
                  data?.paymentLink && window.open(data?.paymentLink, "_self");
                }}
                // style={{ cursor: "pointer" }}
              >
                  <div className={clsx(classes.amountText, '!text-[#1E333F50]')}>Due date</div>
                  <div className={clsx(classes.amountText)}>
                    {dayjs(data?.issueDate).local().format('DD/MM/YYYY')}
                  </div>
              </div>
            </div>
          )
        })}
      </ReportTableLayout>
    )
  return (
    <div
      style={{
        textAlign: 'center',
        width: '100%',
        marginTop: '4rem',
      }}
    >
      No Recent Invoice Yet!
    </div>
  )
}

export default MobileInvoiceTable
