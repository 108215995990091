import { combineReducers, configureStore } from '@reduxjs/toolkit'
import LoginSlice, {
  ForgotPasswordSlice,
  PasswordSetSlice,
  SignupSlice,
  AuthSlice,
} from 'Store/auth-redux'
import { ModalSlice } from 'Store/modal-redux'
import GlobalSlice from 'Store/global-redux'
import {
  ContactsSlice,
  CustomersSlice,
  DashboardSummarySlice,
  ReceivablesSlice,
  ComplianceSlice,
} from 'Store/customer-redux'
import { BankAccountSlice } from './bank-redux'
import { CompleteFormSlice } from './form-redux'
import { FiraReportsSlice } from './reports-redux'
import { UsersSlice } from './user-redux'

const CLEAR_STATE = 'CLEAR_STATE';

const appReducer = combineReducers({
  auth: AuthSlice.reducer,
  modal: ModalSlice.reducer,
  login: LoginSlice.reducer,
  user: UsersSlice.reducer,
  forgotPassword: ForgotPasswordSlice.reducer,
  passwordSet: PasswordSetSlice.reducer,
  signup: SignupSlice.reducer,
  global: GlobalSlice.reducer,
  receivable: ReceivablesSlice.reducer,
  customer: CustomersSlice.reducer,
  customerContacts: ContactsSlice.reducer,
  dashboardSummary: DashboardSummarySlice.reducer,
  bankAccount: BankAccountSlice.reducer,
  reports: FiraReportsSlice.reducer,
  completeForm: CompleteFormSlice.reducer,
  compliance: ComplianceSlice.reducer,
})

const rootReducer = (state, action) => {
    if (action.type === CLEAR_STATE) {
        return appReducer(undefined, action);
    }
    return appReducer(state, action);
}

const store = configureStore({
  reducer: rootReducer,
})

export default store
