
import Shimmer from 'Layout/Shimmer';
import classes from './UserTableMobile.module.scss'
import ReportTableLayout from 'Components/ReportTable/ReportTableLayout'
import clsx from 'clsx'
import dayjs from 'dayjs'
import { formatNumberToLocaleString } from 'hooks/formatNumberToLocaleString';
import UserStatusTag from './UserStatusTag';
import PaymentStatusTag from 'Components/ReportTable/PaymentStatusTag';

const accessName = {
  "ADMIN" : "Full Access",
  "USER" : "Limited Access",
}

const UserTableMobile = (props) => {
  if (props.tableData?.length > 0)
    return (
        <div className={`${classes.mobileTable}`}>
            {props?.tableData?.map((data, index) => {
                return (
                 
                    <div className={classes.bigCont}>
                        <div className={classes.nameCont}>
                            <div className={clsx(`${classes.cont} h-[24px] align-center`)}>
                               <div className={clsx(`${classes.part1} text-[#1E333F]`)}>{data?.first_name} {data?.last_name}</div>
                               <div className={classes.part2Tag}>{accessName[data?.role]}</div>
                            </div>
                            <div className={clsx(`${classes.cont} h-[24px] align-center`)}>
                               <div className={clsx(`${classes.part1} text-[#1E336380]`)}>{data?.email_id}</div>
                            </div>
                        </div>
                        <div className={classes.cont}>
                            <div className={clsx(`${classes.part1} text-[#1E336380]`)}>Created On</div>
                            <div className={classes.part2Date}>{dayjs(data?.createdOn).format('DD/MM/YYYY')}</div>
                        </div>
                        <div className={classes.cont}>
                            <div className={clsx(`${classes.part1} text-[#1E336380]`)}>Status</div>
                            <div className={classes.part2Date}>
                              {
                                data?.user_status ? <PaymentStatusTag status = {data?.user_status} type="light"/> : "--"
                              }
                            </div>
                        </div>

                    </div>
                )
            })}
      </div>
    )
  if (props.tableData?.length === 0)
    return (
        <div
            style={{
                textAlign: 'center',
                width: '100%',
                marginTop: '4rem',
                fontSize: '2.5rem',
                color:'gray'
            }}
            className={classes.table}
        >
            No user to show.
        </div>
    )
  return (
    <div
      style={{
        textAlign: 'center',
        width: '100%',
        marginTop: '4rem',
      }}
    >
        <>
            <Shimmer className="w-[100%] mb-[10px] h-[100px] rounded-[12px]"/>
            <Shimmer className="w-[100%] mb-[10px] h-[100px] rounded-[12px]"/>
            <Shimmer className="w-[100%] h-[100px] rounded-[12px]"/>
        </>
    </div>
  )
}

export default UserTableMobile