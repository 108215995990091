import React, { useEffect, useState, memo, useMemo } from "react";
import { useDropzone } from "react-dropzone";
import { Document, Page, pdfjs } from "react-pdf";
import DragDropIcon from "assets/Icons/darg-drop-icon.svg";
import FileIcon from "assets/Icons/fileIconNew.svg";
import lockGrayIcon from "assets/Icons/lockGrayIcon.svg";
import lockDoneIcon from "assets/Icons/lockDoneIcon.svg";
import BgIcon from "assets/Icons/bg-file-uploader.png";
import UploadIcon from "assets/Icons/upload-cloud.svg";
import ArrowIcon from "assets/Icons/complete-arrow.svg";
import DeleteIcon from "assets/Icons/delete-icon.svg";
import { useSnackbar } from 'notistack'
import CloseIcon from 'assets/Images/Close.svg'
import RefreshIcon from 'assets/Icons/refresh.svg'
import DoneIcon from 'assets/Icons/done-white.svg'
import { apiGetBulkTransactionsId } from 'services';
import styles from "./FileBrowserNew.module.scss";
import clsx from "clsx";
import UseApiCall from "hooks/useApiCall";
import {
  apiCreateMediaBulkTransactionsUploadUrl,
  apiDeleteMediaBulkTransactionsUploadUrl,
} from "services/invoiceServices";
import { fetchFileFromS3 } from 'services'
import {
  apiCreateMediaUploadUrl,
  apiDeleteMedia,
} from "services/invoiceServices";
import { handleDecrypt } from "hooks/handleDecryption";
import { doEncryption } from 'constants';
import { baseUrl } from "constants/Network";

const fileFormats = {
  image: ['image/jpeg', 'image/jpg', 'image/png'],
  video: ['video/mp4', 'video/mov', 'video/quicktime'],
  text: ['text/csv'],
  document: ['application/pdf'],
}

const img = {
  display: 'block',
  width: 'auto',
  height: '100%',
  margin: '0 auto',
}

const generateRandomId = (randomNumberLength) => {
  let result = ''
  while (result.length <= randomNumberLength) {
    result = `${result}${Math.round(9 * Math.random())}`
  }
  return result
}

const getAllowedFileTypes = (allowedFormats) => {
  const result = []
  allowedFormats?.forEach((types) => {
    result.push(...fileFormats[types])
  })
  return result
}

const MAX_ALLOWED_FILES = 10

const FileBrowserNew = ({
  transactionId,
  validFormats,
  isResponsive = true,
  disabled = false,
  displayUrl = [],
  setFile,
  hasError: externalHasError,
  errorMessage,
  isMobile,
  userType,
  docType,
  docNum,
  userName,
  files,
  componentName,
  setOcrResponse,
  setOcrLoading,
  setTransactionId,
  ...rest
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [selectedFiles, setSelectedFiles] = useState(files || {})
  const [selectedPreviewFileIdx, setSelectedPreviewFileIdx] = useState(null)
  const [deletedFiles, setDeletedFile] = useState({})
  const hiddenFileInput = React.useRef(null)
  const [currentFiles, setCurrentFiles] = useState([]);
  const [saveCurrentFiles, setSaveCurrentFiles] = useState();
  const [uploadingLoader, setUploadingLoader] = useState(false);
  const [seconds, setSeconds] = useState(false);

  const [deleteMedia, loadingDelete, resultDelete, errorDelete] = UseApiCall((componentName === "locked" || componentName === "unLocked") ? apiDeleteMedia :   apiDeleteMediaBulkTransactionsUploadUrl);
  const [hasError, setHasError] = useState(externalHasError)
  let count = Object.keys(files || {})?.length;

  let fileValue = "";
  
  useEffect(() => {
    setHasError(externalHasError);
  }, [externalHasError]);

  const totalFiles = useMemo(() => {
    if (!selectedFiles) {
      return [];
    }
  
    return Object.keys(selectedFiles).filter((id) => !deletedFiles[id]);
  }, [selectedFiles, deletedFiles]);

  useEffect(() => {
    setFile(saveCurrentFiles)
  }, [saveCurrentFiles])

  useEffect(() => {
    if (selectedFiles && deletedFiles) {
      const filteredFiles = Object.keys(selectedFiles).filter((file) => {
        return !deletedFiles[file];
      });
      setCurrentFiles(filteredFiles);
    }
  }, [totalFiles]);

  useEffect(() => {
    if (selectedFiles) {
      const newObject = Object.keys(selectedFiles).reduce((result, key) => {
        if (currentFiles.includes(key)) {
          result[key] = selectedFiles[key];
        }
        return result;
      }, {});
  
      setSaveCurrentFiles(newObject);
    }
  }, [selectedFiles, currentFiles]);

  const onSelectFiles = (files) => {
    if (files?.length > 0) {
      setSeconds(4*files?.length)
      const newFiles = {}
      ;[...files].forEach((f) => {
        const id = generateRandomId(6)
        newFiles[id] = Object.assign(f, {
          preview: URL.createObjectURL(f),
          uploadedAt: Date.now(),
          inQueue: true,
          isUploading: null,
          id,
        })
      })

      setSelectedFiles((prev) => ({ ...prev, ...newFiles }))
      uploadFiles(newFiles)
      setUploadingLoader(true)
    }
  }

  const { acceptedFiles, getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      'application/pdf': [],
      'image/png': [],
      'image/jpeg': [],
      'image/jpg': [],
    },
    onDrop: (acceptedFiles) => {
      const maxSize = 10 * 1024 * 1024;
  
      const oversizedFiles = acceptedFiles?.filter((file) => file?.size > maxSize);
      const notOversizedFiles = acceptedFiles?.filter((file) => file?.size < maxSize);
  
      if (oversizedFiles?.length > 0) {

        if(notOversizedFiles?.length > 0){
          fileValue = notOversizedFiles;
          onSelectFiles(notOversizedFiles)
        }

        oversizedFiles?.forEach((f, index) => {
          setTimeout(() => {
            enqueueSnackbar(`This File ${f?.path} size exceeds 10MB:  ${(f?.size / (1024 * 1024))?.toFixed(4)} MB`, {
              variant: 'error',
              anchorOrigin: { horizontal: 'center', vertical: 'top' },
              autoHideDuration: 3000,
            });
          }, index * 3000);
        });
        
      } else {
        fileValue = acceptedFiles;
        onSelectFiles(acceptedFiles)
      }
    },
    disabled: disabled,
  });

  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`
  }, [])

  useEffect(() => {
    return () =>
      Object.keys(selectedFiles).forEach((fileId) =>
        URL.revokeObjectURL(selectedFiles[fileId].preview)
      )
  }, [])

  const uploadFiles = async (newFiles) => {
    const sortedFiles = Object.values(newFiles).sort(
      (a, b) => a.uploadedAt - b.uploadedAt
    )
    const fileIds = sortedFiles.map((a) => a.id)
    if (!selectedPreviewFileIdx) {
      setSelectedPreviewFileIdx(fileIds[0])
    }
    for (let i = 0; i < fileIds.length; i++) {
      if (deletedFiles[fileIds[i]]) {
        continue
      }
      const _file = newFiles[fileIds[i]]
      try {
        _file.isUploading = true
        setSelectedFiles((prev) => ({ ...prev, [fileIds[i]]: _file }))

        // File upload process started
        const arrayBuffer = await _file.arrayBuffer()
        const blob = new Blob([arrayBuffer], {
          type: _file.type,
        })

        let apiResponse;
        try {
          let fileName = encodeURIComponent(_file?.name)
          count++;

          if (componentName === "locked") {
            apiResponse = await apiCreateMediaUploadUrl({
              txnId: transactionId,
              isLocked: true,
              fileName: fileName,
            });
          } else if(componentName === "unLocked") {
            apiResponse = await apiCreateMediaUploadUrl({
              txnId: transactionId,
              isLocked: false,
              fileName: fileName,
            });
          }
          else {

            let transactionIdResponse = await apiGetBulkTransactionsId();
            setTransactionId(transactionIdResponse?.data?.data)
            apiResponse = await apiCreateMediaUploadUrl({
              txnId: transactionId,
              isLocked: false,
              fileName: fileName,
            });
          }
        } catch (error) {
          enqueueSnackbar(`${JSON.stringify(error?.data?.error_response?.message)}`, { variant: 'error' })
        }

        let urlData;
        if (doEncryption) {
          urlData = await handleDecrypt(apiResponse?.data);
          if (componentName === "locked"){
            await fetch(urlData?.data?.url, {
              method: 'PUT',
              body: blob,
              headers: {
                'Content-Type': _file.type,
              },
            })
          } else if(componentName === "unLocked") {
            await fetch(urlData?.data?.url, {
              method: 'PUT',
              body: blob,
              headers: {
                'Content-Type': _file.type,
              },
            })
          }else{
            await fetch(urlData?.url, {
              method: 'PUT',
              body: blob,
              headers: {
                'Content-Type': _file.type,
              },
            })
          }
        } else {
          urlData = await apiResponse;
          if (componentName === "locked"){
            await fetch(urlData?.data?.data?.url, {
              method: 'PUT',
              body: blob,
              headers: {
                'Content-Type': _file.type,
              },
            })
          } else if(componentName === "unLocked") {
            await fetch(urlData?.data?.data?.url, {
              method: 'PUT',
              body: blob,
              headers: {
                'Content-Type': _file.type,
              },
            })
          }else{
            await fetch(urlData?.data?.data?.url, {
              method: 'PUT',
              body: blob,
              headers: {
                'Content-Type': _file.type,
              },
            })
          }
        }

        try {
          setOcrLoading(true);
    
          const formData = new FormData();
          formData.append('file', fileValue[0]);
          // formData.append('organization_id', 8);
          
          const analyzeValues = await fetch(baseUrl.api + `/analyze/invoice`, {
            method: 'POST',
            body: formData,
            credentials: 'include',
          });

          // const response = await analyzeValues.text();
          const response = await analyzeValues.json();
          const res = doEncryption ? handleDecrypt(response) : response;
          setOcrResponse([res?.data]);
        
        } catch (error) {
          console.log(error)
        } finally {
          setOcrLoading(false);
        }
        
        if (deletedFiles[_file.id]) {
        }
        // File uploaded
        _file.inQueue = false
        _file.isUploading = false
        _file.linkId = (componentName === "locked" || componentName === "unLocked") ? urlData?.data?.data?.invoiceLinkId : urlData?.data?.data?.linkId

        setTimeout(() => {
          if (seconds === 0) {
            setUploadingLoader(false)
            setSeconds(0)
          }
        }, [2000])

        setSeconds(prev => prev - 4)

        setSelectedFiles((prev) => ({ ...prev, [fileIds[i]]: _file }))
      } catch (e) {
        handleRemoveFile(_file)
      }
    }
  }

  const onUploadClick = (event) => {
    if (disabled) return
    hiddenFileInput.current.click()
  }

  const handleFileUploadChange = (event) => {
    event.preventDefault();
    fileValue = Array.from(event.target.files);
    onSelectFiles(event.target.files)
  }

  const handleRemoveFile = async (file) => {
    // console.log(file)
    const currentFiles = Object.values(selectedFiles)
      .filter((f) => !deletedFiles[f.id])
      .sort((f1, f2) => f1.uploadedAt - f2.uploadedAt)

    const delFileIdx = currentFiles.findIndex((f) => f.id === file.id)

    if (file.id === selectedPreviewFileIdx) {
      if (delFileIdx < currentFiles.length - 1) {
        const _id = currentFiles[delFileIdx + 1].id
        setTimeout(() => setSelectedPreviewFileIdx(_id), 0)
      } else {
        const _id =
          currentFiles.length > 1
            ? currentFiles[currentFiles.length - 2].id
            : null
        setTimeout(() => setSelectedPreviewFileIdx(_id), 0)
      }
    } else if (selectedPreviewFileIdx && deletedFiles[selectedPreviewFileIdx]) {
      const _id =
        currentFiles.length > 1
          ? currentFiles[currentFiles.length - 2].id
          : null
      setTimeout(() => setSelectedPreviewFileIdx(_id), 0)
    }
    if ((componentName === "locked" || componentName === "unLocked") && file.linkId) {
      deleteMedia({ txnId:transactionId, invoiceLinkId: file.linkId })
    }
    // else if(componentName === "unLocked" && file.linkId) {
    //   deleteMedia({ txnId:transactionId, invoiceLinkId: file.linkId })
    // }
    else{
      deleteMedia({ invoiceLinkId: file.linkId, txnId: transactionId })
    }
    setDeletedFile((prev) => ({ ...prev, [file.id]: file.id }))
  }

  return (
    <div
      // className={clsx(styles.documentupload, 'sm:pb-12')}
    >
      <div className="" style={{
        // border: hasError ? "2px solid #FF6868" : "inherit",
        // borderRadius: hasError ? "0.8rem" : "inherit",
        width: "100%"
      }}>
        <div className={clsx(styles.documentupload, '')}>
          <div
            {...getRootProps({
              className: clsx(
                styles.dropContainer,
                'dropzone',
                // isResponsive && '-sm:hidden'
              ),
            })}
            style={{
              // backgroundImage: `url(${BgIcon})`,
              // backgroundRepeat: 'repeat',
              // backgroundColor: isDragActive
              //   ? 'rgba(194, 243, 194, 0.3)'
              //   : 'transparent',
              // border: isDragActive
              //   ? '1px dashed rgb(63, 74, 63)'
              //   : '1px dashed #d2d6d9',
              // marginTop: totalFiles?.length === 0 ? "-6px" : "18px"
            }}
          >
            {!disabled && (
              <div
                className={clsx(styles.dropMessage, `flex ${"flex-col"} justify-center items-center relative`)}
              >
                <div className={clsx(`relative w-[84px] ${isMobile ? "h-[44px]" : "h-[84px] bottom-[-6px]"}`)}>
                  <img src={componentName === "locked" ? lockGrayIcon : FileIcon} alt="file" className={styles.fileIconImg} />
                  {/* {componentName !== "locked" &&(<div className={clsx(styles.arrowBackground)}>
                    <img src={ArrowIcon} alt="arrow" className="w-[24px] h-[24px]" />
                  </div>)} */}
                </div>
                <p className="mt-[16px]">
                  { false ?
                      <div className={clsx(styles.fileText, `ml-4`)}><b className="font-semibold">Browse files</b></div>
                      : (isDragActive ? (
                          <><b className="font-semibold">Drag</b> files here to upload{' '}</>
                          ) : (
                          <>
                          { !isMobile
                              ? <p className={styles.fontText}>Drop files here to upload, or click <b className={styles.fontTextUpload}>Upload</b></p>
                              : <p className={styles.fontText}>Tap here to <b className={styles.fontTextUpload}>Upload</b></p>
                          }
                            <p className={styles.fontSubText}>PDF, JPEG, JPG, PNG (max 10mb)</p>
                          </>
                          )
                        )
                  }
                </p>
                {/* {getAllowedFileTypes(validFormats)?.length > 0 && (
                  <p className={styles.fileFormat}>
                    {getAllowedFileTypes(validFormats)?.map((type, index) => {
                      return `${type.split('/')[1].toUpperCase()}${
                        !(index + 1 === getAllowedFileTypes(validFormats)?.length)
                          ? `, `
                          : ''
                      }`
                    })}{' '}
                    (max 10mb)
                  </p>
                )} */}
              </div>
            )}
            <input {...getInputProps()} />
            {/* {selectedFiles[selectedPreviewFileIdx] &&
              !deletedFiles[selectedPreviewFileIdx] && (
                <div
                  className={clsx(
                    styles.previewContainer,
                    isDragActive ? 'opacity-10' : ''
                  )}
                >
                  {fileFormats.document.includes(
                    selectedFiles[selectedPreviewFileIdx].type
                  ) && (
                    <Document
                      file={selectedFiles[selectedPreviewFileIdx].preview}
                      className={clsx(styles.documentFull)}
                    >
                      <Page pageNumber={1} />
                    </Document>
                  )}
                  {fileFormats.image.includes(
                    selectedFiles[selectedPreviewFileIdx].type
                  ) && (
                    <img
                      alt={selectedFiles[selectedPreviewFileIdx].name}
                      src={selectedFiles[selectedPreviewFileIdx].preview}
                      style={img}
                    />
                  )}
                </div>
              )} */}
          </div>
        </div>
        <div
          className={clsx(
            styles.thumbnailContainer,
            isMobile && 'h-[30vh] overflow-y-scroll overflow-x-hidden'
            // isResponsive
            //   ? 'border-0 border-t-0 sm:border'
            //   : 'border border-t-0',
          )}
          style={{ display: totalFiles?.length === 0 && "none"}}
        >
          {componentName === "locked" ? 
          (<p className={clsx(styles.fontText, 'font-[500]')}>
            {
              (uploadingLoader && seconds !== 0)
                ? `Uploading ${acceptedFiles?.length} of ${Object.keys(acceptedFiles || {})?.length} documents, ${seconds} seconds left`
                : `Uploaded ${Object.keys(files || {})?.length} ${Object.keys(files || {})?.length <= 1  ? 'locked document' : 'locked documents'}`
            }
          </p>) :
          (<p className={clsx(styles.fontText, 'font-[500]')}>
          {
            (uploadingLoader && seconds !== 0)
              ? `Uploading ${acceptedFiles?.length} of ${Object.keys(acceptedFiles || {})?.length} invoices, ${seconds} seconds left`
              : `Uploaded ${Object.keys(files || {})?.length} ${Object.keys(files || {})?.length <= 1  ? 'invoice' : 'invoices'}`
          }
          </p>)
          }

          <div className={"mt-[10px] mb-[12px] relative left-[-24px]"} style={{ border: "1px solid #ECEFF2", background: "#FFF", width: "calc(100% + 48px)" }}></div>
          {totalFiles?.length > 0 &&
            Object.values(selectedFiles)
              .sort((f1, f2) => f1.uploadedAt - f2.uploadedAt)
              .map((file) =>
                deletedFiles[file.id] ? null : (
                  <div className={styles.fileUploader}>
                    <div className="flex items-center">
                      {
                        file.inQueue
                          ? 
                          <img src={RefreshIcon} alt="RefreshIcon" className='' />
                          : 
                          (componentName !== "locked" ?
                            (<div className="bg-[#1CA95D] h-[22px] w-[22px] p-[2px] rounded-full relative">
                              <img src={DoneIcon} alt="DoneIcon" className='bg-[#1CA95D] h-[12px] w-[12px] rounded-full relative top-[22%] left-[15%]' />
                          </div>) : <img src={lockDoneIcon} alt="RefreshIcon" className='pr-[16px]' />)
                      }
                    </div>
                    <div
                      key={file.id}
                      className={clsx(
                        styles.fileThumbnail,
                        // 'aspect-square gap-2 rounded-xl border-solid border items-center py-2 px-2',
                        // selectedPreviewFileIdx === file.id
                        //   ? 'border-[#1E333F]'
                        //   : 'border-[#060A0D33]',
                        file.inQueue ? 'opacity-40' : ''
                      )}
                      onClick={() => setSelectedPreviewFileIdx(file.id)}
                    >
                      {/* {fileFormats.document.includes(file.type) && (
                        <Document
                          file={file.preview}
                          className={clsx(styles.documentThumbnail)}
                        >
                          <Page pageNumber={1} />
                        </Document>
                      )} */}
                      <div className={styles.uploadLayout}>
                        <p className={clsx(styles.documentText, 'mb-[4px]')}>{file?.name?.length > 25  ? `${file.name.slice(0, 25)}...` : file.name}</p>
                        <p className={styles.uploadingText}>{file.isUploading === false ? (componentName === "locked" ? "Uploaded & Locked " : "Uploaded") : "Uploading..."}</p>
                        <p></p>
                      </div>
                      <div
                        className={clsx(
                          styles.progressBar,
                          file.isUploading === false
                            ? styles.progressBar100
                            : file.isUploading === true
                            ? styles.progressBar60 : file.isUploading === true ? styles.progressBar20
                            : ''
                        )}
                      ></div>
                    </div>
                    {!file.inQueue && !disabled && (
                        <div
                          className={clsx(styles.closeIcon)}
                          onClick={() => handleRemoveFile(file)}
                        >
                          {
                            file.inQueue
                              ? <img src={CloseIcon} alt="CloseIcon" className='' />
                              : <img src={DeleteIcon} alt="DeleteIcon" className='' />
                          }
                        </div>
                    )}
                    {file.inQueue && (
                        <div
                          className={clsx(styles.closeIcon)}
                          onClick={() => handleRemoveFile(file)}
                        >
                          {
                            file.inQueue
                            ? <img src={CloseIcon} alt="CloseIcon" className='' />
                            : <img src={DeleteIcon} alt="DeleteIcon" className='' />
                          }
                        </div>
                    )}
                  </div>
                )
              )}
          {/* {!disabled && (
            <div
              onClick={onUploadClick}
              className="aspect-square flex item-center justify-center flex-col gap-2 cursor-pointer rounded-xl border-solid border border-[#060A0D33] items-center py-4 px-8"
            >
              <img
                src={totalFiles?.length > 0 ? AddFileIcon : UploadIcon}
                alt="+"
                className="w-8 h-8"
              />
              <p className="text-lg">
                {totalFiles?.length > 0 ? 'More' : 'Upload'}
              </p>
            </div>
          )} */}
        </div>
        <input
          type="file"
          ref={hiddenFileInput}
          onChange={handleFileUploadChange}
          multiple
          accept={getAllowedFileTypes(validFormats).join(', ')}
          style={{ display: 'none' }}
        />
      </div>
      {/* code to check if error add message */}
      {hasError && <div className={styles.error} style={{ width: "100%"}}>{errorMessage}</div>}
    </div>
  )
}

export default memo(FileBrowserNew);
