import React from "react";
import infoIcon from "assets/Icons/info_brown_circle.svg"
import clsx from "clsx";

const UserStatusTag = (props) =>{
    const statusArr = [
      {
        status:"active",
        tag:<div 
        className="flex items-center w-fit px-[8px] py-[2px] rounded-[100px] bg-[#FFEAE0] text-[12px] leading-[16px] text-[#FF611D] font-[700] uppercase"
        >
        active
        </div>
      },
      {
        status:"disabled",
        tag:<div 
        className="flex items-center w-fit px-[8px] py-[2px] rounded-[100px] bg-[#F0F2F4] text-[12px] leading-[16px] text-[#AFB7BE] font-[700] uppercase"
        >
        disabled
        </div>
      },
      {
        status:"under verification",
        tag:<div 
        className="flex items-center w-fit px-[8px] py-[2px] rounded-[100px] bg-[#FFF4DE] text-[12px] leading-[16px] text-[#8E6C28] font-[700] uppercase"
        >
        <img src={infoIcon} alt="i" className="w-[20px] h-[20px]"/>
        under verification
        </div>
      },
      
    ]
    return(
        <div>
          {
            statusArr.map((el)=>{
                if(el.status===props.status){
                    return el.tag
                }
            })
          }
        </div>
    )
}

export default UserStatusTag