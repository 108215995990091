// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UserReportTable_usertable__X8sG0 {
  font-size: 1.8rem;
  line-height: 2.4rem;
  border: 2px soild red;
  /* @media (max-width : 850px) {
       display: none;
   }

   .cell_header1{
       padding: 2rem 1.2rem;
       background-color: #F7F8FB;
       text-transform: uppercase;
       font-size: 1.2rem;
       line-height: 1.6rem;
       font-weight: 600;
       text-align: start;
       vertical-align: middle;
       color: rgba(30, 51, 63, 0.4);

       .cell_header2{
           display: flex;
           align-items: center;
       }

       .sorting{

       }

       .actionHeader{
           display: flex;
           justify-content: center;
           align-items: center;
       }
   }

   .row{

       &:hover{
           background-color: #F6F8FB;
       }

       .cell{
           border-bottom: 1px dashed var(--accent_grey_300);
           padding: 2rem 1.2rem;
           font-size: 1.4rem;
           line-height: 2rem;
           color: rgba(30, 51, 63, 0.8);

           &_action{
               display: flex;
               justify-content: center;
               align-items: center;
           }
       }
   }*/
}`, "",{"version":3,"sources":["webpack://./src/Components/ReportTable/UserReportTable.module.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,mBAAA;EACA,qBAAA;EASC;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;KAAA;AA2CH","sourcesContent":[".usertable{\n  font-size: 1.8rem;\n  line-height: 2.4rem;\n  border: 2px soild red;\n\n  .text{\n\n  }\n\n  .btn{\n\n  }\n   /* @media (max-width : 850px) {\n        display: none;\n    }\n\n    .cell_header1{\n        padding: 2rem 1.2rem;\n        background-color: #F7F8FB;\n        text-transform: uppercase;\n        font-size: 1.2rem;\n        line-height: 1.6rem;\n        font-weight: 600;\n        text-align: start;\n        vertical-align: middle;\n        color: rgba(30, 51, 63, 0.4);\n\n        .cell_header2{\n            display: flex;\n            align-items: center;\n        }\n\n        .sorting{\n\n        }\n\n        .actionHeader{\n            display: flex;\n            justify-content: center;\n            align-items: center;\n        }\n    }\n\n    .row{\n\n        &:hover{\n            background-color: #F6F8FB;\n        }\n\n        .cell{\n            border-bottom: 1px dashed var(--accent_grey_300);\n            padding: 2rem 1.2rem;\n            font-size: 1.4rem;\n            line-height: 2rem;\n            color: rgba(30, 51, 63, 0.8);\n    \n            &_action{\n                display: flex;\n                justify-content: center;\n                align-items: center;\n            }\n        }\n    }*/\n\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"usertable": `UserReportTable_usertable__X8sG0`
};
export default ___CSS_LOADER_EXPORT___;
