import PageIntro from "Components/Global/PageIntro";
import classes from "Pages/Admin/BankAccounts.module.scss";
import plusSquareIcon from "assets/Icons/plusSquare-icon.svg";
import React, { useEffect, useRef, useState } from "react";
import INRFlag from "assets/Images/INR_Flag.svg";
import canadaFlag from "assets/Images/canadaFlag.svg";
import gbpFlag from "assets/Images/gbpFlag.svg";
import europeFlag from "assets/Images/europeFlag.svg";
import usaFlag from "assets/Images/usaFlag.svg";
import audFlag from "assets/Icons/Flags/Australia (AU).svg";
import chfFlag from "assets/Icons/Flags/Switzerland (CH).svg";
import hkdFlag from "assets/Icons/Flags/Hong Kong (HK).svg";
import sekFlag from "assets/Icons/Flags/Sweden (SE).svg";
import sgdFlag from "assets/Icons/Flags/Singapore (SG).svg";
import nokFlag from "assets/Icons/Flags/Norway (NO).svg";
import aedFlag from "assets/Icons/Flags/United Arab Emirates (AE).svg";
import czkFlag from "assets/Icons/Flags/Czech Republic (CZ).svg";
import dkkFlag from "assets/Icons/Flags/Denmark (DK).svg";
import plnFlag from "assets/Icons/Poland (PL).svg";

import addIcon from "assets/Icons/add.svg";
import showArrow from "assets/Icons/showArrow.svg";
import arrowDown from "assets/Icons/arrow-down.svg"
import FullScreenModal from "Layout/fullScreenModal"
import moreInfo from "assets/Images/moreInfo.svg";
import arrowRight from "assets/Icons/chevron-right.svg";
import arrowRightW from "assets/Icons/chevron-right-newWhite.svg";
import arrowLeft from "assets/Icons/chevron-left.svg";
import arrowLeftW from "assets/Icons/chevron-left-white.svg";
import tempLogo from "assets/Icons/temp-logo.svg";
import tempLogo2 from "assets/Icons/temp-logo-2.svg";
import Modal from "Layout/Modal";
import howItWorks from "assets/Images/howItWorksDesign-image.svg";
import { useDispatch, useSelector } from "react-redux";
import { DashboardSummaryActions } from "../../Store/customer-redux";
import { fetchSummary } from "./Dashboard";
import { BankAccountActions } from "../../Store/bank-redux";
import {
  apiGetBankDetails,
  apiGetBankList,
  apiGetVirtualBankDetails,
  apiGetVirtualBankList,
  apiGetVirtualBankListGetBalance,
} from "services";
import UseApiCall from "hooks/useApiCall";
import clsx from "clsx";
import ComingSoonPrompt from "Components/atom/ComingSoonPrompt/ComingSoonPrompt";
import CustomModal from "Components/atom/CustomModal/CustomModal";
import { baseUrl } from "constants/Network";
import VirtualBankData from "./VirtualBankData";
import VirtualBankDetails from "./VirtualBankDetails";
import BankAccountDetails from "./BankAccountDetails";
import Reconcilation from "./Reconcilation";
import { useNavigate } from "react-router-dom";
import CryptoJS from 'crypto-js';
import { secretKey } from "constants";
import { tourArrays } from "constants/Tour";
import { Tooltip } from "antd";
import TooltipTourData from "Components/Tour/TooltipTourData";
import login from "../Authentication/Login";
import Shimmer from "../../Layout/Shimmer";

const BankAccounts = () => {
  // const url = window.location.href;
  // const baseUrl = url.split('/').slice(0, 3).join('/');
  const dispatch = useDispatch();
  const showTourModal = useSelector((state) => state.global.showTour);
  const stepNumber = useSelector((state) => state.global.stepNumberInTour);
  const bankList = useSelector((state) => state.bankAccount.accounts);
  const bankDetails = useSelector((state) => state.bankAccount.accountDetails);
  const virtualBankDetails = useSelector((state) => state.bankAccount.virtualAccounts);
  const [selectedBank, setSelectedBank] = useState(null);
  const [virtualActive,setVirtualActive] = useState(true)
  const [settlementActive,setSettlementActive] = useState(false)
  const [virtualBankDataDummy, setVirtualBankDataDummy ] = useState({})
  const [selectedAcc, setSelectedAcc] = useState('USD')
  const [apiCallRecon , setApiCallRecon] = useState(false);
  const [selectedAccountDetails, setSelectedAccountDetails] = useState(virtualBankDetails?.balances?.USD)
  const accountId = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(1);
  const listRef = useRef(null);
  const [modifiedBalances, setModifiedBalances] = useState(null);

  const totalBoxes = Object.keys(virtualBankDetails?.balances || {})?.length;
  const handleNext = () => {
    // if(currentIndex < totalBoxes - 3 && totalBoxes > 4)
    // setCurrentIndex(prevIndex => (prevIndex + 1));

    if (listRef.current && currentIndex < totalBoxes - 3 && totalBoxes > 4) {
      listRef.current.scrollBy({ left: 260, behavior: 'smooth' }); // Adjust 200 according to your desired scroll distance
      setCurrentIndex(prevIndex => prevIndex + 1);
    }
  };

  const handlePrev = () => {
    // if(currentIndex > 1)
    // setCurrentIndex(prevIndex => (prevIndex - 1));

    if (listRef.current && currentIndex > 1) {
      listRef.current.scrollBy({ left: -260, behavior: 'smooth' }); // Adjust -200 according to your desired scroll distance
      setCurrentIndex(prevIndex => prevIndex - 1);
    }
  };




  const decryptValue = (encryptedValue) => {
    const bytes = CryptoJS.AES.decrypt(encryptedValue || '', secretKey);
    return bytes.toString(CryptoJS.enc.Utf8);
  };

  const storedUserRole = decryptValue(localStorage.getItem('nickName') || '');

  const [tourArray, setTourArray] = useState(tourArrays[1]);

  useEffect(() => {
    if(storedUserRole === 'malik') setTourArray(tourArrays[0])
  }, [])

  const [getBankList, fetchingBankList] = UseApiCall(apiGetBankList, (res) => {
    dispatch(BankAccountActions.setBankAccounts(res?.data));
  
    const primaryBank = res?.data?.find((item) => item.isPrimary);
  
    if (primaryBank) {
      setSelectedBank(primaryBank.accountId);
      accountId.current = primaryBank.accountId;
    }
  });

  const [displayRecon,setDisplayRecon] = useState(false);
  const navigate = useNavigate();
  const handleRecon = () =>{
    if (!showTourModal) setDisplayRecon(!displayRecon)
  }


  const [getVirtualBankDetails, fetchinfVBan] = UseApiCall(
    apiGetVirtualBankDetails,
    (res) => {
      const currencyCode = apiCallRecon ? selectedAccountDetails?.currencyDTO?.code : 'USD';
      if (res?.data?.balances?.[currencyCode]?.balance) {
        setSelectedAccountDetails(res?.data?.balances?.[currencyCode]);
      }
      dispatch(BankAccountActions.setVirtualAccounts(res?.data));
    }
  );

  const [modalRef, setModalRef] = useState(null);

  const showComingSoon = () => {
    modalRef.show({
      title: "",
      child: <ComingSoonPrompt />,
      onClose: () => {},
      compactSize: true,
      closeOnClickOutside: true,
    });
  };
  const arrBtn = [
    {
      text: "Add Bank Account",
      icon: plusSquareIcon,
      onClick: function () {
        showComingSoon();
      },
    },
    {
      text: "Add Virtual Bank Account",
      icon: plusSquareIcon,
      onClick: function () {
        showComingSoon();
      },
    },
  ];

  const handleVirtualActive = () =>{
      setVirtualActive(true)
      setSettlementActive(false)
  }

  const handleSettlementActive = () =>{
      setVirtualActive(false)
      setSettlementActive(true)
  }

  const getCurrencyFlag = (currency) => {
    const flagUrls = {
      USD: usaFlag,
      EUR: europeFlag,
      GBP: gbpFlag,
      CAD: canadaFlag,
      AUD: audFlag,
      AED: aedFlag,
      CHF: chfFlag,
      HKD: hkdFlag,
      SEK: sekFlag,
      SGD: sgdFlag,
      NOK: nokFlag,
      CZK: czkFlag,
      DKK: dkkFlag,
      PLN: plnFlag,
    };

    if (flagUrls.hasOwnProperty(currency)) {
      return flagUrls[currency];
    } else {
      return usaFlag;
    }
  };

  useEffect(() => {
    if(storedUserRole === 'malik'){
      getBankList();
      getVirtualBankDetails()
    }else{
      navigate("/Error")
    }
  }, []);

  useEffect(() => {
    if (apiCallRecon) {
      setApiCallRecon(false)
      getVirtualBankDetails()
    }
  },[apiCallRecon])

  let virtualMap = virtualBankDetails?.virtualAccountsDetails?.paymentMethodsDetailsMap;
  // let disable = (virtualMap.USD.localMethod == undefined  && internationalMethod == undefined) ? true : false;
  const handleVbanDownload = async (currency) => {
    const response = await fetch(baseUrl.api + `/download/vban-details?currency=${currency}`, {
        method: "GET",
        headers: {
            "Content-Type": "application/pdf",
        },
        credentials: "include",
    }).then(async function (t) {
        let filename = t.headers.get('Content-Disposition')?.split(';')?.find(n => n.includes('filename='))?.replace('filename=', '')?.trim();
        const b = await t.blob();
        var a = document.createElement("a");
        a.href = URL.createObjectURL(b);
        if (!filename) {
            filename = virtualMap[currency]?.details?.["LocalMethods"][0]?.accountHolderName || virtualMap[currency]?.details["InternationalMethods"][0]?.accountHolderName;
            filename = `${filename?.replace(' ', '_')}_${currency}_vban_details`
        }
        a.setAttribute("download", filename);
        a.click();
    });
  }

  useEffect(() => {
    if (virtualBankDetails?.balances && virtualBankDetails?.virtualAccountsDetails) {
      const newModifiedBalances = {};

      for (const currency in virtualBankDetails?.balances) {
        const balanceDetails = virtualBankDetails?.balances[currency];
        const paymentMethods = virtualBankDetails?.virtualAccountsDetails?.paymentMethodsDetailsMap?.[currency]?.details;

        const hasMethods = paymentMethods && (
            paymentMethods.InternationalMethods?.length > 0 ||
            paymentMethods.localMethods?.length > 0
        );

        if (hasMethods) {
          newModifiedBalances[currency] = balanceDetails;
        }
      }

      for (const currency in virtualBankDetails?.balances) {
        if (!(currency in newModifiedBalances)) {
          newModifiedBalances[currency] = virtualBankDetails?.balances[currency];
        }
      }

      setModifiedBalances(newModifiedBalances);
    }
  }, [virtualBankDetails?.balances, virtualBankDetails?.virtualAccountsDetails]);



  return (
    <div className="min-h-screen">
      {
        displayRecon && <FullScreenModal children={<Reconcilation handleClick={handleRecon} totalBalance={{ sign: (selectedAccountDetails?.currencyDTO?.sign || "$"), balance: Number(selectedAccountDetails?.balance || 0)}} selectedAccountDetails={selectedAccountDetails} apiCallRecon={apiCallRecon} setApiCallRecon={setApiCallRecon} />} />
      }
      <div className={classes.bankAccounts}>
        <CustomModal ref={setModalRef} />
        <PageIntro
          pageName={"Bank Accounts"}
          message={"Add & Manage all your bank accounts"}
          arrBtn={arrBtn}
        />
        <div className={classes.bankListAll}>
          <div className={classes.bankListAll_header}>
            <div className={classes.bankListAll_headerBox1} onClick={handleVirtualActive}>
                <span className={clsx(classes.bankListAll_headerBox1Text1 , virtualActive && classes.bankListAll_headerActive)} >Global Collection Accounts</span>
            </div>
            <div className={classes.bankListAll_headerBox1} onClick={handleSettlementActive}>
                <span className={clsx(classes.bankListAll_headerBox1Text1 , settlementActive && classes.bankListAll_headerActive)} >Your Indian Bank Accounts</span>
            </div>
            {/* <div className={clsx(classes.add, 'opacity-50')}>+ Add New</div> */}
          </div>
          {/*<div className="flex items-center justify-between mb-[20px]">*/}
          {/*  <div className={clsx("flex items-center justify-center w-[48px] h-[48px] border-[1px] bg-[#FFFFFF] rounded-[8px]")}*/}
          {/*       style={{*/}
          {/*         border : currentIndex > 1 && totalBoxes > 4 ? '1px solid #060A0D' : ' 1px solid #060A0D1F',*/}
          {/*         cursor : currentIndex > 1 && totalBoxes > 4 ? 'pointer' : 'not-allowed'*/}
          {/*       }}*/}
          {/*       onClick={handlePrev}>*/}
          {/*    <img src={currentIndex > 1 ? arrowLeft : arrowLeftW}/>*/}
          {/*  </div>*/}
          {/*  <div className="flex items-center justify-center w-[48px] h-[48px] border-[1px] bg-[#FFFFFF] rounded-[8px] cursor-pointer"*/}
          {/*       style={{*/}
          {/*         border: currentIndex < totalBoxes - 3  && totalBoxes > 4 ? '1px solid #060A0D' : '1px solid #060A0D1F',*/}
          {/*         cursor: currentIndex < totalBoxes - 3  && totalBoxes > 4? 'pointer' : 'not-allowed'*/}
          {/*       }}*/}
          {/*       onClick={handleNext}>*/}
          {/*    <img src={currentIndex < totalBoxes - 3 ? arrowRight : arrowRightW}/>*/}
          {/*  </div>*/}
          {/*</div>*/}

          {settlementActive &&
          (<div className={classes.bankListAll_box}>
            {bankList.map((bank, index) => {
              return (
                <div>
                    <div
                      key={`${bank.accountNumber}-${index}`}
                      className={clsx(
                        classes.bankDetails,
                        selectedBank === bank.accountId &&
                          classes.bankDetails_active, 'relative'
                      )}
                      onClick={() => {
                        setSelectedBank(bank.accountId);
                        // handleBankDetails();
                      }}
                    >
                        <div className={classes.bankDetails_header}>
                            <img
                              src={
                                bank.countryFlag
                                  ? `Images/${bank.country}.svg`
                                  : INRFlag
                              }
                              alt="country-flag"
                              className={classes.bankDetails_header_flag}
                            />
                            <div
                              className={`${classes.bankDetails_header_currency} ${
                                selectedBank === bank.accountId &&
                                classes.bankDetails_header_currency_active
                              }`}
                            >
                              {(bank?.bankAccountType === undefined || bank?.bankAccountType === null || bank?.bankAccountType?.toLowerCase() === 'domestic') ? "INR" : bank?.bankAccountType}
                            </div>
                        </div>
                        <div className={classes.bankDetails_name}>
                          {bank?.bankName} Bank
                        </div>
                        <div className={classes.bankDetails_number}>
                          {bank?.accountNumber
                            ?.slice(-4)
                            .padStart(bank?.accountNumber?.length, "X")}
                        </div>
                        {bank?.isPrimary && (
                            <div className={clsx(classes.selectedbank, 'absolute bottom-[0] w-full ')}>
                              <div className={classes.content1}>Primary A/C</div>
                              <div className={classes.content2}>
                                To receive payments
                              </div>
                            </div>
                          )}
                    </div>
                        <div className={`${classes.virtualBoxBottomBank}`}>
                          {selectedBank === bank.accountId && <img src={showArrow} alt='arrow' className={`${classes.virtualBoxBottomImg}`}/>}
                        </div>
                </div>
              )
            })}
            <div className={clsx(classes.addBank, "min-w-[240px] h-[152px]  opacity-50")} >
              <img
                src={addIcon}
                alt="add-icon"
                className={classes.addBank_icon}
              />
              <div className={classes.addBank_header}>Add another</div>
              <div className={classes.addBank_subheader}>Add more accounts</div>
            </div>
          </div>)}
          {settlementActive && (
              <div>
                {bankList.map((bank,index)=> {
                  return(
                      selectedBank === bank.accountId && <BankAccountDetails bank = {bank} />
                  )
                })}
              </div>
          )}

          {virtualActive && (
              <div className={classes.virtualBankListAll_box} ref={listRef}
                   // onWheel={(e) => e.preventDefault()}
                   // style={{ overflowX: 'hidden', whiteSpace: 'nowrap' }}
              >
                {fetchinfVBan
                    ?
                    <div className="flex gap-[30px]">
                      {[1,2,3,4]?.map((item, index) => (
                          <Shimmer key={index} className="w-[250px] h-[100px] rounded-lg"/>
                      ))}
                    </div>
                    :

                  virtualBankDetails && virtualBankDetails?.balances &&
                  Object.entries(virtualBankDetails?.balances)?.map(([currency, balance], index) => (
                    <div key={currency}
                         // style={{ display: index + 1 >= currentIndex && index < currentIndex + 4 ? 'inline-block' : 'none' }}
                    >
                      {/*{console.log('index',index + 1)}*/}
                      {/*{console.log('currentIndex',currentIndex)}*/}
                      {(showTourModal && "step5" === tourArray[stepNumber]?.id && index + 1 === currentIndex) ? (
                          <Tooltip
                              title={
                                <TooltipTourData
                                    heading={tourArray[stepNumber]?.heading}
                                    body1={tourArray[stepNumber]?.body1}
                                    body2={tourArray[stepNumber]?.body2}
                                    path={tourArray[stepNumber]?.pageUrl}
                                    previousUrl={tourArray[stepNumber > 0 ? stepNumber - 1 : stepNumber]?.previousUrl}
                                    finalStep={tourArray[stepNumber]?.finalStep}
                                    firstStep={tourArray[stepNumber]?.firstStep}
                                />
                              }
                              id="customTooltip"
                              placement={tourArray[stepNumber]?.placement}
                              open={showTourModal}
                              overlayInnerStyle={{
                                background: "#060A0D",
                                width: "400px",
                                padding: "0px",
                                borderRadius: "16px"
                              }}
                          >
                            <VirtualBankData
                                active={selectedAcc === currency}
                                virtualMap={virtualMap?.[currency]}
                                data={balance}
                                handleClick={handleRecon}
                                img={getCurrencyFlag(currency)} // Assuming you have a function to get the flag based on the currency
                                setSelectedAcc={setSelectedAcc}
                                showReconcile={true} // Assuming this is constant for all currencies
                                setSelectedAccountDetails={setSelectedAccountDetails}
                            />
                          </Tooltip>
                      ) : (
                          <VirtualBankData
                              active={selectedAcc === currency}
                              virtualMap={virtualMap?.[currency]}
                              data={balance}
                              handleClick={handleRecon}
                              img={getCurrencyFlag(currency)} // Assuming you have a function to get the flag based on the currency
                              setSelectedAcc={setSelectedAcc}
                              showReconcile={true} // Assuming this is constant for all currencies
                              setSelectedAccountDetails={setSelectedAccountDetails}
                          />
                      )}
                    </div>
                ))}
              </div>
          )}
        </div>


        {virtualActive && (
            <div>
              {fetchinfVBan
                  ?
                  <div className="flex gap-[30px]">
                    {[1]?.map((item, index) => (
                        <Shimmer key={index} className="w-[100%] h-[400px] mt-[50px] rounded-lg"/>
                    ))}
                  </div>
                  :
                virtualBankDetails && virtualBankDetails.virtualAccountsDetails && virtualBankDetails.virtualAccountsDetails.paymentMethodsDetailsMap &&
                  Object.entries(virtualBankDetails.virtualAccountsDetails.paymentMethodsDetailsMap)?.map(([currency, details]) => (
                      selectedAcc === currency && (
                          <VirtualBankDetails
                              key={currency} // Add a unique key for each VirtualBankDetails component
                              selectedAcc={selectedAcc}
                              selectedAccountDetails={selectedAccountDetails}
                              data={virtualMap?.[currency]}
                              sign={virtualBankDetails?.balances?.[currency]?.currencyDTO?.sign}
                              amount={'10,000'} // Example amount
                              country={details.country} // Assuming 'country' is a property in the 'details' object
                          />
                      )
                  ))}


              <div className={`${classes.virtualAccContainerTopRight2MobileContainer}`}>
                <div className={`${classes.virtualAccContainerTopRight2Mobile}`}>
                  <a
                      // href={`${baseUrl}/api/download/vban-details?currency=${selectedAcc}`}
                      onClick={() => handleVbanDownload(selectedAcc)}
                  >
                    <div className={`${classes.virtualAccContainerTopRight2A}`}>
                      <img src={arrowDown} alt='arrow-down' className={`${classes.virtualAccContainerTopRight2AImg}`}/>
                      <span className={`${classes.virtualAccContainerTopRight2AText}`}>Download All Details</span>
                    </div>
                  </a>
                </div>
              </div>
            </div>
        )}
      </div>
    </div>
  );
};
export default BankAccounts;