import { createSlice } from "@reduxjs/toolkit";

const completeFormInitialState = {
    businessType: [],
    showBackendError : false,
    leadData: {},
    leadDataError: false,
}

export const CompleteFormSlice = createSlice({
    name: 'completeForm-slice',
    initialState : completeFormInitialState,
    reducers : {

        setBusinessType(state, action){
            state.businessType = action.payload;
        },
        setShowBackendError(state, action){
            state.showBackendError = action.payload;
        },
        setLeadData(state, action){
            state.leadData = action.payload;
        },
        setLeadDataError(state, action){
            state.leadDataError = action.payload;
        }
    }
})

export const CompleteFormActions = CompleteFormSlice.actions;