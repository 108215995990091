import React, { useEffect } from "react";
import classes from "./MobileInvoiceReconModal.module.scss"
import clsx from "clsx";
import Modal from "Layout/Modal";
import Close from "Layout/Close";
import Backdrop from "Layout/Backdrop";
import { useDispatch , useSelector } from "react-redux";
import { modalActions } from "Store/modal-redux";
import ReactDOM from 'react-dom'
import { useState,useRef } from "react";
import { fetchFileFromS3 } from "services";
import UseApiCall from "hooks/useApiCall";
import InvoiceDetailsTabContent from "../InvoiceDetailsModal/InvoiceDetailsTabContent";
import FileViewer from "Components/atom/FileViewer";
import RightIcon from 'assets/Icons/chevron-right-white.svg'

const MobileInvoiceViewRecon = (props) => {
const {viewInvoice} = props;
// const [whereCalled,setWhereCalled] = useState()
// const urls = viewInvoice?.presigned_url_responses;
const dispatch = useDispatch();
const invoiceViewRecon = useSelector((state) => state.modal.invoiceViewRecon);
const [invoicesData, setInvoicesData] = useState(viewInvoice?.presigned_url_responses)
const [invoices, setInvoices] = useState([])
// const [paymentLinkOtpRequired, setPaymentLinkOtpRequired] = useState(viewInvoice?.is_otp_required || false);  // for now i use a state , once new API deployed i will fetch it from backend response
// const [otpViewNowBox,setOtpViewNowBox] = useState(false);
// const transId = useRef(0);
// const transIdpaymentLinkOtpRequired = useRef(false);
// const [otpData, setOtpData] = useState(null)
// const [errorState, setErrorState] = useState({ error: false })
// const [otpErrorState, setOtpErrorState] = useState({ error: false })
// const [presignedUrlErrorState, setPresignedUrlErrorState] = useState({ error: false })
// const [otp, setOtp] = useState(['', '', '', '','','']); 
// const [otpSuccess,setOtpSuccess] = useState(true);
// const [lockInvoices, setLockInvoices] = useState([])
const [unlockInvoices, setunLockInvoices] = useState([])
const [invoicetoBigScreen, setInvoicetoBigScreen] = useState([])
// const [activeTab,setActiveTab] = useState("invoice")
// const [invoicesLocked, setInvoicesLocked] = useState([
//     {
//       type : "image/png",
//       url : RightIcon
//     }
//   ])
const invoiceLockData = invoicesData?.filter((item)=> item.isLocked === true) 
const invoiceunLockData = invoicesData?.filter((item)=> item.isLocked === false) 

const [getFilesFromS3, fetchingFiles] = UseApiCall(
    fetchFileFromS3,
    (res, headers) => {
      try {
        var blob = new Blob([res], { type: headers['content-type'] })
        let link = window.URL.createObjectURL(blob)
        setInvoices((pre) => [
          ...pre,
          {
            url: link,
            type: headers['content-type'],
          },
        ])
      } catch (error) {
         console.log(error)
      }
    },
    (err)=> {},
    'direct'
  )
//   const [getLockFilesFromS3, fetchingLockFiles] = UseApiCall(
//     fetchFileFromS3,
//     (res, headers) => {
//       try {
//         var blob = new Blob([res], { type: headers['content-type'] })
//         let link = window.URL.createObjectURL(blob)
//         setLockInvoices((pre) => [
//           ...pre,
//           {
//             url: link,
//             type: headers['content-type'],
//             isLocked: true,
//           },
//         ])
//       } catch (error) {
//         // console.log(error)
//       }
//     }
//   )
  const [getunLockFilesFromS3, fetchingunLockFiles] = UseApiCall(
    fetchFileFromS3,
    (res, headers) => {
      try {
        var blob = new Blob([res], { type: headers['content-type'] })
        let link = window.URL.createObjectURL(blob)
        setunLockInvoices((pre) => [
          ...pre,
          {
            url: link,
            type: headers['content-type'],
            isLocked: false,
          },
        ])
      } catch (error) {
        // console.log(error)
      }
    },
    (err)=> {},
    'direct'
  )  
  const mappingUrls = () => {
    viewInvoice.presigned_url_responses.map((item)=>{
        getFilesFromS3(item.url)
    })
  }
//   const downloadLockInvoices = () => {
//     invoiceLockData?.map((item, index) => {
//       getLockFilesFromS3(item?.url)
//       return item
//     })
//   }

  const downloadunLockInvoices = () => {
    invoiceunLockData?.map((item, index) => {
      getunLockFilesFromS3(item?.url)
      return item
    })
  }
  useEffect(() => {
    if(viewInvoice){
        mappingUrls();
        // downloadLockInvoices();
        downloadunLockInvoices();
    }
    },[viewInvoice])
  
  useEffect(()=>{
   setInvoicetoBigScreen([unlockInvoices[0]])
  },[unlockInvoices])

return(
    <Modal
      className={clsx(
        classes.modal,
        '-sm:h-[calc(100vh-14.2rem)] p-0 w-screen md:w-[100%] xl:w-auto z-[100] rounded-t-[12px] bottom-0 overflow-y-scroll'
      )}
    >
    {invoiceViewRecon &&
        ReactDOM.createPortal(
          <Backdrop className={classes.backdrop}
          onClick={()=>{
            dispatch(modalActions.invoiceViewRecon(false))
          }}
            />,
          document.getElementById('backdrop-root')
    )}
    <div className={classes.headerTop}>
        <div>Attachments</div>
        <Close
         onClick = {()=>{
            dispatch(modalActions.invoiceViewRecon(false))
         }}
        />
    </div>
    <div className={classes.bigCont}>
        <div className={classes.fileViewerCont}>
        <FileViewer
                format={['document', 'image']}
                displayUrls={invoices}
                // ref={documentRef}
                downloadPrefix={viewInvoice?.invoice_id}
                // otpViewNowBox={otpViewNowBox}
                // setOtpViewNowBox={setOtpViewNowBox}
                // setPaymentLinkOtpRequired={setPaymentLinkOtpRequired}
                // paymentLinkOtpRequired={paymentLinkOtpRequired}
                // handleResendOTPView={handleResendOTPView}
                // otpErrorState={otpErrorState}
                // presignedUrlErrorState={presignedUrlErrorState}
                invoiceData={invoicesData}
                // otp={otp}
                // setOtp={setOtp}
                // handleOTPView={handleOTPView}
                // handleOTPViewNow={handleOTPViewNow}
                whereCalled="bigSmall"
                setInvoicetoBigScreen={setInvoicetoBigScreen}
                invoicetoBigScreen={invoicetoBigScreen}
              />
        </div>
    </div>
    </Modal>
)
}

export default MobileInvoiceViewRecon