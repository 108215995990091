import classes from 'Components/Authentication/AuthSideContent.module.scss';
import fideoLogo from 'assets/Images/FideoLogo.svg';
import fideoLogoWhite from 'assets/Images/fideoLogoWhite.svg';
import globalAccount from 'assets/Images/auth_globe.svg';
import globalAccountWhite from 'assets/Images/globalAccountWhite.svg';
import collect from 'assets/Images/auth_collect.svg';
import collectWhite from 'assets/Images/collectWhite.svg';
import search from 'assets/Images/auth_search.svg';
import searchWhite from 'assets/Images/searchWhite.svg';
import compliance from 'assets/Images/auth_compliance.svg';
import vector from 'assets/Icons/vectorHomePage.svg';
import shield from 'assets/Icons/shield.svg';
import lowCostFx from 'assets/Icons/low-cost-fx.svg';
import fileHomePage from 'assets/Icons/file-homepage.svg';
import complianceWhite from 'assets/Images/complianceWhite.svg';
import { Link } from 'react-router-dom';

const content = [
    {
        id:1,
        header: 'Payments',
        icon : vector,
        alt:'global-account',
        para :'Open bank accounts for international business in minutes & receive money with lowest FX rates'
    },
    {
        id:2,
        header: 'Compliance',
        icon : shield,
        alt:'collect',
        para :'Be fully compliant without legwork. No more bank forms, phone calls & onerous requirements to plough through - the platform does it all automatically'
    },
    {
        id:3,
        header: 'Insurance',
        icon : lowCostFx,
        alt:'search',
        para :'Grow your business and minimise risk with our insurance offerings'
    },
    {
        id:4,
        header: 'Risk and Capital Management',
        icon : fileHomePage,
        alt:'compliance',
        para :'Coming soon'
    }
]

const AuthSideContent = (props) => {

    const handleLogo = () => {
        window.location.href = "https://www.fideo.tech";
    }
    return (
        <div className={`${classes.auth_side_content} ${props.className}`}>
            {/* <Link to="/www.fideo.tech" >
            </Link> */}
                <img src={fideoLogoWhite} alt='fideo-logo' className={classes.logo} onClick={handleLogo}/>
            <div className={classes.content}>
                {/* <div className={classes.header}>Why Fideo?</div> */}
                {content.map(data => 
                    <div key ={data.id} className={classes.content_box}>
                        <img src={data.icon} alt={data.alt} className={classes.img}/>
                        <div className={classes.text}>
                            <div className={classes.sub_header}>{data.header}</div>
                            <p className={classes.para}>{data.para}</p>
                        </div>
                    </div>
                )}
            </div>
            <div className={classes.footer}>©Fideo 2022. All rights reserved.</div>
        </div>
    )
}

export default AuthSideContent;