import PaymentDetailsModal from 'Components/molecule/PaymentDetailsModal/PaymentDetailsModal'
import Button from 'Layout/Button'
import { modalActions } from 'Store/modal-redux'
import ReactDOM from 'react-dom'
import FileIcon from 'assets/Icons/file-icon.svg'
import ExporterIcon from 'assets/Icons/exporter.svg'

import RightIcon from 'assets/Icons/chevron-right-white.svg'
import fideoLogo from 'assets/Images/FideoLogo.svg'
import LockIcon from 'assets/Icons/lock.svg'
import LockIcon2 from 'assets/Icons/lock-icon.svg'
import classes from 'Pages/Other/CustomerPaymentPage/index.module.scss';
import InvoicesTable from './InvoicesTable'


import clsx from 'clsx'
// import dayjs from 'dayjs'
import { useDispatch, useSelector } from 'react-redux'
// import DocumentSheet from './DocumentSheet'
// import FileViewer from 'Components/atom/FileViewer'
import UseApiCall from 'hooks/useApiCall'
import { useEffect, useRef, useState } from 'react'
// import {
//   apiGetNewPaymentDetails,
//   apiGetOTP,
//   apiGetPaymentDetails,
//   apiGetPaymentInvoices,
//   fetchFileFromS3,
// } from 'services'
import { useParams } from 'react-router-dom'
import { apiGetCustomerInvoices, apiGetReceivables, apiGenerateSinglePaymentLink, apiVerifySinglePaymentInvoices, apiVerifySinglePaymentSummary} from 'services'
import { ReceivablesActions } from 'Store/customer-redux'

const MakePayment = () => {

  const [screenSize, setScreenSize] = useState(getCurrentDimension());
    function getCurrentDimension() {
        return {
        width: window.innerWidth,
        height: window.innerHeight,
        };
    }

    useEffect(() => {
        const updateDimension = () => {
          setScreenSize(getCurrentDimension());
        };
        window.addEventListener("resize", updateDimension);
    
        return () => {
          window.removeEventListener("resize", updateDimension);
        };
      }, [screenSize]);



  let { token, customerId, currencyCode } = useParams()
  currencyCode = currencyCode || "USD";
  const dispatch = useDispatch()
  const buttonRef = useRef(null)
  // const searchParams = new URLSearchParams(document.location.search)
  // const oldToken = searchParams.get('token')
  const [invoiceData, setInvoiceData] = useState()
  const [invoiceDataSummary, setInvoiceDataSummary] = useState()
  const [leadDetails, setLeadDetails] = useState()
  const [invoiceHeader, setInvoiceHeader] = useState()
  const [invoicePrices, setInvoicePrices] = useState(0)
  const [currencySignForFooter, setCurrencySignForFooter] = useState('$')
  const [priceForFooter, setPriceForFooter] = useState(0)
  // const [otpData, setOtpData] = useState(null)

  // const [paymentLinkOtpRequired, setPaymentLinkOtpRequired] = useState(false);  // for now i use a state , once new API deployed i will fetch it from backend response
  // const [otpViewNowBox,setOtpViewNowBox] = useState(false);
  const transId = useRef(0);
  // const transIdpaymentLinkOtpRequired = useRef(false);
  const [selectedInvoices, setSelectedInvoices] = useState([])
  const [filterInvoices, setFilterInvoices] = useState([])
  const [isChecked, setIsChecked] = useState(false)

  // const [invoices, setInvoices] = useState([
  //   {
  //     type : "image/png",
  //     url : RightIcon
  //   }
  // ])
  // const [errorState, setErrorState] = useState({ error: false })
  // const [otpErrorState, setOtpErrorState] = useState({ error: false })
  // const [presignedUrlErrorState, setPresignedUrlErrorState] = useState({ error: false })
  // const [otp, setOtp] = useState(['', '', '', '','','']); 

  // const handleOTPView = () => {
  //   setOtpViewNowBox((prev) => !prev);
  //   // setPaymentLinkOtpRequired((prev) => !prev);

  //   const params = oldToken
  //     ? { token: oldToken }
  //     : { token: token, invoiceId: invoiceId }
  //      fetchOtpData(params)
  // }


  // const handleResendOTPView = () => {
  //   const params = oldToken
  //     ? { token: oldToken }
  //     : { token: token, invoiceId: invoiceId }
  //      fetchOtpData(params)
  // }
  
  // const handleOTPViewNow = () => {
  //   // setOtpViewNowBox(false);
  //   // setPaymentLinkOtpRequired(false);
  //   fetchPresignedUrl({ transactionId: invoiceData?.transactionId , otp : otp.join('')})
  // }

  // const [fetchInvoiceData, fetchingInvoiceLoader] = UseApiCall(
  //   oldToken ? apiGetPaymentDetails : apiGetNewPaymentDetails,
  //   (res) => {
  //     if (res?.data?.invoiceResponse?.status === "CANCELLED" || res?.data?.invoiceResponse?.status === "SETTLED_OUTSIDE") {
  //       setErrorState({
  //         error: true,
  //         title: 'This link is no longer valid. Please get in touch with the exporter.',
  //         subtext: '',
  //       })
  //     } else {
  //       setInvoiceData(res?.data)
  //       setPaymentLinkOtpRequired(res?.data?.paymentLinkOtpRequired)
  //       // console.log("transactionId",res?.data.transactionId)
  //       transId.current = res?.data?.transactionId
  //       transIdpaymentLinkOtpRequired.current = res?.data?.paymentLinkOtpRequired
  //       // !paymentLinkOtpRequired && fetchPresignedUrl({ transactionId: invoiceData?.transactionId , otp : otp.join('')})
  //       if(!transIdpaymentLinkOtpRequired.current){
  //         fetchPresignedUrl({ transactionId: transId.current , otp : otp.join('')})
  //       }
  //     }
  //   },
  //   (err) => {
  //     setErrorState({
  //       error: true,
  //       title: JSON.stringify(err?.data?.error_response?.message) || '',
  //       subtext: 'Please generate a new link.',
  //     })
  //   }
  // )

  // const [fetchOtpData] = UseApiCall(
  //   apiGetOTP,
  //   async (res) => {
  //     try {
  //       // Assuming res.data is an object
  //       setOtpData(res?.data);
  //       // Perform other actions if needed
  //     } catch (error) {
  //       // Handle any errors that occur during data processing
  //       console.error('Data processing error:', error);
  //     }
  //   },
  //   async (err) => {
  //     try {
  //       // Assuming err.data is an object
  //       console.error(err?.data?.detail);
  //       setOtpErrorState({
  //         error: true,
  //         title: err?.data?.detail || '',
  //         subtext: 'Please check your emailId.',
  //       });
  //     } catch (error) {
  //       // Handle any errors that occur during error handling
  //       console.error('Error handling error:', error);
  //     }
  //   }
  // );
  
  // const [fetchPresignedUrl, fetchingPresignedUrl] = UseApiCall(
  //   apiGetPaymentInvoices,
  //   (res) => {
  //     setOtpViewNowBox(false);
  //     setPaymentLinkOtpRequired(false);
  //     setInvoices([])
  //     res.data.map(
  //       (item) => item.createdBy === 'fideo-backend' && getFilesFromS3(item.url)
  //     )
  //   },
  //   (err) => {
  //     setPresignedUrlErrorState({
  //       error: true,
  //       title: err?.data?.detail || '',
  //       subtext: 'Not able to fetch Invoices',
  //     })
  //   }
  // )

  // const [getFilesFromS3, fetchingFiles] = UseApiCall(
  //   fetchFileFromS3,
  //   (res, headers) => {
  //     try {
  //       var blob = new Blob([res], { type: headers['content-type'] })
  //       let link = window.URL.createObjectURL(blob)
  //       setInvoices((pre) => [
  //         ...pre,
  //         {
  //           url: link,
  //           type: headers['content-type'],
  //         },
  //       ])
  //     } catch (error) {
  //       console.log(error)
  //     }
  //   }
  // )

  const showPaymentDisplay = useSelector(
    (state) => state.modal.showPaymentDisplay
  )

  const handlePayment = (array) => {
    const codeOccurrences = array.reduce((acc, item) => {
      const code = item.currency.code;
      acc[code] = (acc[code] || 0) + 1;
      return acc;
    }, {});
    
    const maxOccurrenceCode = Object.keys(codeOccurrences).reduce((prev, current) => {
      return codeOccurrences[prev] > codeOccurrences[current] ? prev : current;
    });

    // setInvoicePrices(array.reduce((total, invoice) => total + invoice.price, 0)?.toLocaleString('en-US'))
    const totalAmount = array.reduce((total, invoice) => total + invoice.price, 0);
    const formattedAmount = totalAmount.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    setInvoicePrices(formattedAmount);
    setInvoiceData({
      invoiceResponse: {
        invoiceCurrency: summaryDataResult?.data?.paymentLinkSummaryMap?.[maxOccurrenceCode]?.currencyDTO,
        invoiceAmount: summaryDataResult?.data?.paymentLinkSummaryMap?.[maxOccurrenceCode]?.pendingInvoiceTotal
      },
      virtualAccountDto: summaryDataResult?.data?.paymentLinkSummaryMap?.[maxOccurrenceCode]?.vbanDetails
    });
    dispatch(modalActions.showPaymentDisplay())
  }

  const handlePaymentTable = (price, currencyObject) => {
    const paisa = price;
    setInvoicePrices(paisa)
    setInvoiceData({
      invoiceResponse: {
        invoiceCurrency: summaryDataResult?.data?.paymentLinkSummaryMap?.[currencyObject?.code]?.currencyDTO,
        invoiceAmount: summaryDataResult?.data?.paymentLinkSummaryMap?.[currencyObject?.code]?.pendingInvoiceTotal
      },
      virtualAccountDto: summaryDataResult?.data?.paymentLinkSummaryMap?.[currencyObject?.code]?.vbanDetails
    });
    dispatch(modalActions.showPaymentDisplay())
  }

  const handlePaymentTotal = (key) => {
    const paisa = summaryDataResult?.data?.paymentLinkSummaryMap?.[key]?.pendingInvoiceTotal?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    setInvoicePrices(paisa)
    setInvoiceData({
      invoiceResponse: {
        invoiceCurrency: summaryDataResult?.data?.paymentLinkSummaryMap?.[key]?.currencyDTO,
        invoiceAmount: summaryDataResult?.data?.paymentLinkSummaryMap?.[key]?.pendingInvoiceTotal
      },
      virtualAccountDto: summaryDataResult?.data?.paymentLinkSummaryMap?.[key]?.vbanDetails
    });
    dispatch(modalActions.showPaymentDisplay())
  }


  // Table
  const [customerInvoiceList, setCustomerInvoiceList] = useState(null)
  const [loading, setLoading] = useState(true)
  const [page, setPage] = useState(0)
  const [totalPages, setTotalPages] = useState(1)
  const [current, setCurrent] = useState(1);
  const generateChecklist = (count) => {
    return Array.from({ length: count }, () => ({ isChecked: false }));
  };
  
  const [checklist, setChecklist] = useState({ isChecked: false });
  // const updateCustomersModalValue = useSelector((state) => state.customer.updateCustomers);

  // const [getSinglePaymentLink, singlePaymentLinkLoader, singlePaymentLinkData] = UseApiCall(apiGenerateSinglePaymentLink, (res) => {
  // })

  const [getVerifySinglePaymentInvoices, invoicesLoader] = UseApiCall(apiVerifySinglePaymentInvoices, (res) => {
    setCustomerInvoiceList(res?.data?.invoiceList)
    setTotalPages(res?.data?.totalElements)
    setChecklist(prevChecklist => {
      if (prevChecklist.length === res.data.totalPages) {
        return prevChecklist;
      }
      return generateChecklist(res.data.totalPages);
    });
    // ReceivablesActions.getReceivabelReports(res?.data?.invoice_list)
  })

  const [getVerifySinglePaymentSummary, summaryLoader, summaryDataResult] = UseApiCall(apiVerifySinglePaymentSummary, (res) => {
    setInvoiceDataSummary(res?.data);
    setInvoiceData({
      invoiceResponse: {
        invoiceCurrency: res?.data?.paymentLinkSummaryMap?.[currencyCode]?.currencyDTO,
        invoiceAmount: res?.data?.paymentLinkSummaryMap?.[currencyCode]?.pendingInvoiceTotal
      },
      virtualAccountDto: res?.data?.paymentLinkSummaryMap?.[currencyCode]?.vbanDetails
    });
    setInvoiceHeader({ count: res?.data?.paymentLinkSummaryMap?.[currencyCode]?.invoiceCount, price: res?.data?.paymentLinkSummaryMap?.[currencyCode]?.pendingInvoiceTotal, invoiceCurrency: res?.data?.paymentLinkSummaryMap?.[currencyCode]?.currencyDTO?.sign})
    setLeadDetails(res?.data?.leadDetails);
  })

  useEffect(() => {
    if (page !== null && page !== undefined) {
      getVerifySinglePaymentInvoices({
        customerId,
        token,
        page: page,
        // ...(currencyCode && { currency: currencyCode }),
      })
    }
  }, [customerId, token, page, currencyCode])

  useEffect(() => {
    getVerifySinglePaymentSummary({ customerId, token })
  }, [customerId, token])

  // useEffect(() => {
  //   if (selectedInvoices?.length > 0) {
  //     const codeOccurrences = selectedInvoices.reduce((acc, item) => {
  //       const code = item.currency.sign;
  //       acc[code] = (acc[code] || 0) + 1;
  //       return acc;
  //     }, {});
      
  //     const maxOccurrenceSign = Object.keys(codeOccurrences).reduce((prev, current) => {
  //       return codeOccurrences[prev] > codeOccurrences[current] ? prev : current;
  //     });
  //     setCurrencySignForFooter(maxOccurrenceSign);
  //     const totalAmount = selectedInvoices.reduce((total, invoice) => total + invoice.price, 0);
  //     const formattedAmount = totalAmount.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  //     setPriceForFooter(formattedAmount)
  //   }
  // }, [selectedInvoices])

  useEffect(() => {
    if (selectedInvoices?.length > 0) {
      const codeOccurrences = selectedInvoices.reduce((acc, item) => {
        const code = item.currency.sign;
        acc[code] = (acc[code] || 0) + 1;
        return acc;
      }, {});
      
      const maxOccurrenceSign = Object.keys(codeOccurrences).reduce((prev, current) => {
        return codeOccurrences[prev] > codeOccurrences[current] ? prev : current;
      });
      setCurrencySignForFooter(maxOccurrenceSign);
      const totalAmount = selectedInvoices.reduce((total, invoice) => total + invoice.price, 0);
      const formattedAmount = totalAmount.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
      setPriceForFooter(formattedAmount)

      const groupedByCurrency = selectedInvoices.reduce((acc, item) => {
        const { code } = item.currency;
        acc[code] = acc[code] || [];
        acc[code].push(item);
        return acc;
      }, {});

      const groupedArray = Object.entries(groupedByCurrency).map(([currencyCode, items]) => ({
        currencyCode,
        items,
        price: items.reduce((total, invoice) => total + invoice.price, 0).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
        len: items?.length,
        sign: items?.length && items[0]?.currency?.sign
      }));

      setFilterInvoices(groupedArray);
    }
  }, [selectedInvoices])

  const handlePaginate = (page) => {
    setPage(page)
    // setIsChecked(false)
  }

  const tableHeader = [
    {heading: 'invoice id', sorting: true},
    {heading: 'pending amount', sorting: true},
    {heading: 'attachments', sorting: false},
    {heading: 'due date', sorting: false},
    {heading: 'action', sorting: false}
  ]

  function capitalizeFirstLetterOfWords(str) {
    return str
      ?.split(' ')
      ?.map(word => word.charAt(0).toUpperCase() + word.slice(1))
      ?.join(' ');
  }

  return (
    <div className="w-[100vw] min-h-[100%] relative max-w-[1032px] 2xl:max-w-[1300px] self-center flex flex-col">
      {showPaymentDisplay &&
        ReactDOM.createPortal(
          <PaymentDetailsModal
            contactType={'Customer'}
            invoiceDetails={invoiceData}
            invoicesPrice={invoicePrices}
            showSelectedPrice={true}
          />,
          document.getElementById('modal-root')
        )}
      <div
        className={clsx(
          'relative sm:hidden sticky w- top-0 left-0 right-0 h-[72px] flex flex-row justify-center items-center px-[5%] border-b border-b-[#E8EAEF] bg-[#FFFFFF]'
        )}
      >
        <img
          src={fideoLogo}
          alt="fideo-logo"
          className="w-fit h-[20px] mr-12 absolute left-[5%]"
        />
        <p className="font-bold text-4xl text-[#1E333F]">Make Payment</p>
      </div>

      { !summaryLoader &&
        <>
          <div className='sm:hidden my-[24px] w-[100%] self-center flex justify-between px-[16px]'>
            <div className={clsx(`
            flex w-[100%] justify-between
            `)}>
              <div className={clsx(`flex flex-col items-start gap-[4px]`)} style={{ width: "calc(100% - 100px)"}}>
                <p className={clsx(classes.exporterNameText, 'w-full')} style={{ overflowWrap: 'break-word' }}>{leadDetails?.businessName && capitalizeFirstLetterOfWords(leadDetails?.businessName)}</p>
                <p className={clsx(classes.exporterDetailsText, 'w-full')} style={{ overflowWrap: 'break-word' }}>{`${leadDetails?.city && capitalizeFirstLetterOfWords(leadDetails?.city)}, ${leadDetails?.postalCode}, ${leadDetails?.countryName && capitalizeFirstLetterOfWords(leadDetails?.countryName)}`}</p>
                <p className={clsx(classes.exporterDetailsText, 'w-full')} style={{ overflowWrap: 'break-word' }}>Email Id: {leadDetails?.email}</p>
              </div>
              <div className="min-w-[72px] min-h-[72px] max-w-[72px] max-h-[72px] rounded-[12px] bg-[#DEE3EC]">
                <img
                  src={ExporterIcon}
                  className="relative top-[20px] left-[20px]"
                  style={{
                    borderRadius: "8px",
                  }}
                />
              </div>
            </div>
          </div>

          <div className='hidden sm:flex mb-[24px] w-[100%] self-center flex justify-between'>
            <p className="hidden sm:block font-bold text-5xl text-[#1E333F]">
              Make Payment
            </p>
            <div className={clsx(`flex gap-[16px]`)}>
              <div className={clsx(`flex flex-col items-end gap-[4px]`)}>
                <p className={clsx(classes.exporterNameText)}>{leadDetails?.businessName && capitalizeFirstLetterOfWords(leadDetails?.businessName)}</p>
                <p className={clsx(classes.exporterDetailsText)}>{`${leadDetails?.city && capitalizeFirstLetterOfWords(leadDetails?.city)}, ${leadDetails?.postalCode}, ${leadDetails?.countryName && capitalizeFirstLetterOfWords(leadDetails?.countryName)}`}</p>
                <p className={clsx(classes.exporterDetailsText)}>Email Id: {leadDetails?.email}</p>
              </div>
              <div className="min-w-[72px] min-h-[72px] max-w-[72px] max-h-[72px] rounded-[12px] bg-[#DEE3EC]">
                <img
                  src={ExporterIcon}
                  className="relative top-[20px] left-[20px]"
                  style={{
                    borderRadius: "8px",
                  }}
                />
              </div>
            </div>
          </div>
        </>
      }

      <div
        className={clsx(
          classes.header,
          'self-center'
        )}
      >
        { !summaryLoader &&
          Object.entries(summaryDataResult?.data?.paymentLinkSummaryMap || {})
          .filter(([key, value]) => value?.invoiceCount > 0)
          .sort(([keyA], [keyB]) => (keyA === 'USD' ? -1 : keyB === 'USD' ? 1 : 0))
          .map(([key, value], index) => (
            <div className={clsx(classes.innerHeader)} key={index}>
              <div
                className={clsx(classes.headerLeft)}
              >
                <div className='order-2 sm:order-1'>
                  <img src={FileIcon} alt="open" className="px-[8px]" /> 
                </div>
                <div className={clsx(classes.headerLeftHeading, 'order-1 sm:order-2')}>
                  <p className={clsx(classes.headerLeftCount)}>{value?.invoiceCount}</p>
                  <p className={clsx(classes.headerLeftText)}>Total {key} Invoices</p>
                </div>
              </div>
              <div
                className={clsx(classes.headerRight)}
              >
                <div className={clsx(classes.headerRightBlock)}>
                  <div className={clsx(classes.headerRightPrice)}>{`${value?.currencyDTO?.sign}${value?.pendingInvoiceTotal?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`}</div>
                  <div className={clsx(classes.headerRightText)}>Total Pending Amount</div>
                </div>
                <div className="-sm:w-[100%]">
                  <Button
                    text="Pay now"
                    ref={buttonRef}
                    icon={RightIcon}
                    btnClass={clsx('h-[48px] w-[216px] py-[12px] px-[48px] mb-[6px] text-[16px] -sm:w-[100%] -sm:h-[50px] -sm:py-[4px] -sm:px-[16px] -sm:mb-[4px]')}
                    btnClassIcon={'order-2 w-[20px] h-[20px] ml-[8px] -sm:ml-[2px]'}
                    onClick={() => handlePaymentTotal(key)}
                    btnClassText={clsx(`!text-[16px] !leading-[24px] !h-[24px] font-[600]`)}
                  />
                  <div className="flex flex-row gap-3 items-center justify-center self-center">
                    <img
                      src={LockIcon}
                      alt="secure"
                      className="w-6 aspect-square relative "
                    />
                    <p className="text-xl text-[#1E333FB2] font-medium">
                      Secured Payments
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ))
        }
      </div>

      {(customerInvoiceList?.length > 0) ? (
        <div className={screenSize?.width > 640 && `${filterInvoices?.length === 1 ? 'mb-[110px]' : (filterInvoices?.length === 2 ? 'mb-[195px]' : (filterInvoices?.length === 3 ? 'mb-[290px]' : (filterInvoices?.length > 3 && 'mb-[400px]')))}`}>
          <InvoicesTable
            tableData={customerInvoiceList}
            tableHeader={tableHeader}
            mobileTableData={customerInvoiceList}
            handlePaginate={handlePaginate}
            pages={totalPages}
            selectedInvoices={selectedInvoices}
            setSelectedInvoices={setSelectedInvoices}
            handlePayment={handlePaymentTable}
            setCurrent={setCurrent}
            current={current}
            screenSize={screenSize}
            isChecked={isChecked}
            setIsChecked={setIsChecked}
            checklist={checklist}
            setChecklist={setChecklist}
            page={page}
            // pages={props?.summary?.SETTLED?.count + props?.summary?.OUTSTANDING?.count}
          />
        </div>
      ) : (
        (!invoicesLoader && customerInvoiceList?.length === 0) ?
          <>
            <h1 className={classes.noInvoicesText}>No Invoices To Show.</h1>
            {/* <NoInvoicesView /> */}
          </>
          :
          <></>
      )}

      <div style={{ height: "1px", padding: "1px" }}></div>
      {
        selectedInvoices?.length > 0 &&
          <div className={clsx(`-md:hidden mb-[64px] w-[100%] self-center flex justify-between`)}>
            <div className={classes.footer}>
              <div className={classes.footerLeftText}>
                {selectedInvoices?.length} Invoices selected
              </div>
              <div>
              { filterInvoices && filterInvoices?.length > 0 && filterInvoices?.map((item, index) => (
                  <div className={classes.footerRightBox} key={index}>
                    <div className='flex flex-col items-start'>
                      <div className={classes.footerRightPrice}>
                        {item?.sign}{item?.price}
                      </div>
                      <div className={classes.exporterDetailsText} style={{ color: "#FFFFFF99" }}>{item?.len} Invoices</div>
                    </div>
                    <div className="p-4 mt-5 sm:p-8 flex flex-col gap-[4px] bg-transparent rounded-[12px]">
                      <Button
                        text="Pay"
                        ref={buttonRef}
                        icon={RightIcon}
                        btnClassIcon={'order-2 w-[20px] h-[20px]'}
                        onClick={() => handlePayment(item?.items)}
                        className={clsx(`bg-[#181C30] border rounded-[8px] px-[24px] py-[12px] border-solid border-[2px] border-[#FFF] h-[48px] gap-[8px] w-[105px]`)}
                        btnClassText={clsx(`text-[16px] leading-[24px] h-[24px] font-[600]`)}
                      />
                      <div className="flex flex-row gap-3 items-center justify-center self-center">
                        <img
                          src={LockIcon2}
                          style={{ color: "white" }}
                          alt="secure"
                          // className="w-6 aspect-square relative "
                        />
                        <p className={classes.securedText}>
                          Secured Payments
                        </p>
                      </div>
                    </div>
                  </div>
              ))}
              </div>
            </div>
          </div>
      }
      {
        selectedInvoices?.length > 0 &&
          <div className={clsx(`md:hidden mb-[64px] w-[100%] flex`, filterInvoices?.length === 2 ? 'mb-[130px]' : (filterInvoices?.length === 3 ? 'mb-[200px]' : (filterInvoices?.length > 3 ? 'mb-[300px]' : '')))}>
            <div className={classes.footer}>
              { filterInvoices && filterInvoices?.length > 0 && filterInvoices?.map((item, index) => (
              <div className={classes.footerRightBox}>
                <div className=''>
                  <div className={classes.footerRightPrice}>
                    {item?.sign}{item?.price}
                  </div>
                  <div className={classes.exporterDetailsText} style={{ color: "#FFFFFF99" }}>{item?.len} Invoices</div>
                </div>
                <div className="p-4 sm:p-8 flex flex-col gap-[4px] bg-transparent rounded-[12px] ">
                  <Button
                    text="Pay"
                    ref={buttonRef}
                    icon={RightIcon}
                    btnClassIcon={'order-2 w-[20px] h-[20px]'}
                    onClick={() => handlePayment(item?.items)}
                    className={clsx(`bg-[#181C30] border rounded-[8px] px-[24px] py-[12px] border-solid border-[2px] border-[#FFF] h-[48px] gap-[8px] w-[105px]`)}
                    btnClassText={clsx(`text-[16px] leading-[24px] h-[24px] font-[600]`)}
                  />
                </div>
              </div>
              ))}
            </div>
          </div>
      }
    </div>
  )
}

export default MakePayment
