/* eslint-disable no-unused-vars */
import classes from "./SignUp.module.scss";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import UseApiCall from "hooks/useApiCall";
import { apiSignUp } from "services";
import Form1 from "./Form1";
import Form2 from "./Form2";
import { useNavigate } from "react-router-dom";
import Spinner from "Components/atom/Spinner";
import BusinessDetails from "../SignUpAdd/BusinessDetails";
import { SignupActions } from "Store/auth-redux";
import { enqueueSnackbar } from "notistack";

const initialFormState = {
  firstName: "",
  lastName: "",
  email: "",
  countryCode: "+91",
  mobileNumber: "",
  businessName: "",
  businessCountry: {title: 'India', value: 'IN'},
};

const defaultRedirect = "https://eu.jotform.com/231081680908356";

const SignUp = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [applicationId, setApplicationId] = useState(null);
  const [isForm2Complete, setIsForm2Complete] = useState(false);
  const [formData, setFormData] = useState(initialFormState);
  const [signUp, signingUp, result, error] = UseApiCall(apiSignUp, (res) => {
    dispatch(SignupActions.setCompleteData(res?.data));
    window.open(
      res?.data?.redirectionUrl || "https://eu.jotform.com/231081680908356",
      // res?.data?.redirectionUrl.replace('https://dev.fideo.tech/', 'http://localhost:3000/') || "https://eu.jotform.com/231081680908356",
      "_self"
    );
  }, (err) => {
      enqueueSnackbar(`${err?.data?.error_response?.message} ${err?.data?.error_response?.errors !== null ? JSON.stringify(err?.data?.error_response?.errors[0]) : ""}`, { variant: 'error' })
  });

  console.log(error, "error")

  // const formSubmitHandler = (props) => {
  //   signUp({
  //     ...formData,
  //     countryCode: formData?.countryCode?.slice(1),
  //     ...props,
  //   });
  // };

  const completeDataX = useSelector((state) => state.signup.completeData);

  useEffect(() => {
    setFormData(completeDataX);
  }, [completeDataX]);

  const formSubmitHandler = (props) => {
    signUp({
      ...props,
    });
  };

  // console.log(signingUp, result, error, "error")

  // useEffect(() => {
  //   if (page === 1 && !signingUp && result?.data?.applicationId) {
  //     navigate('/onboarding/details');
  //   }
  // }, [page, signingUp, result])

  // console.log(applicationId)
  // console.log(formData)

  // if(signingUp){
  //   return (
  //       <div className="min-h-[300px] flex items-center justify-center">
  //           <Spinner />
  //       </div>
  //   )
  // }

  return (
    <div className={`${classes.signup} ${props.className}`}>
      {page === 0 ? (
        <Form1
          formSubmitHandler={formSubmitHandler}
          setApplicationId={setApplicationId}
          setFormData={setFormData}
          formData={formData}
          setPage={setPage}
          loader={signingUp}
          apiError={error}
        />
      ) : (
        null
        // page === 1 && !signingUp && result?.data?.applicationId &&
        // <Form2
        //   formSubmitHandler={formSubmitHandler}
        //   setFormData={setFormData}
        //   setPage={setPage}
        //   formData={formData}
        //   setIsForm2Complete={setIsForm2Complete}
        // />
        // <BusinessDetails />
      )}
    </div>
  );
};
export default SignUp;
