import React from 'react';
import classes from "./AccessTooltip.module.scss";
import tooltipIcon from "assets/Icons/Rectangle 123.svg"

const AccessTooltip = () => {
  return (
    <div className={classes.AccessTooltipCon}>
        <div className={classes.AccessTooltipConTop}>
            <img src={tooltipIcon} className='w-[16px] h-[12px] ' />
        </div>
        <div className={classes.AccessTooltipConBottom}>
            <div className={classes.receivableCon}>
                <div className={classes.receivableConTop}>
                    <p className={classes.receivableConTopText}>Receivables</p>
                </div>
                <hr className={classes.hr}/>
                <div className={classes.field}>
                    <p className={classes.fieldText}>Add Invoice</p>
                    <div className={classes.enabledButton}>
                        <p className={classes.enabledButtonText}>Enabled</p>
                    </div>
                </div>
                <div className={classes.field}>
                    <p className={classes.fieldText}>Bulk Upload</p>
                    <div className={classes.enabledButton}>
                        <p className={classes.enabledButtonText}>Enabled</p>
                    </div>
                </div>
                <div className={classes.field}>
                    <p className={classes.fieldText}>Send Payment Link</p>
                    <div className={classes.enabledButton}>
                        <p className={classes.enabledButtonText}>Enabled</p>
                    </div>
                </div>
                <div className={classes.field}>
                    <p className={classes.fieldText}>Payment Status View</p>
                    <div className={classes.enabledButton}>
                        <p className={classes.enabledButtonText}>Enabled</p>
                    </div>
                </div>
                <div className={classes.field}>
                    <p className={classes.fieldText}>Bank Account View</p>
                    <div className={classes.enabledButton}>
                        <p className={classes.enabledButtonText}>Enabled</p>
                    </div>
                </div>
                <div className={classes.field}>
                    <p className={classes.fieldText}>Receivable Details</p>
                    <div className={classes.enabledButton}>
                        <p className={classes.enabledButtonText}>Enabled</p>
                    </div>
                </div>
            </div>
            <div className={classes.receivableCon}>
                <div className={classes.receivableConTop}>
                    <p className={classes.receivableConTopText}>Customers</p>
                </div>
                <hr className={classes.hr}/>
                <div className={classes.field}>
                    <p className={classes.fieldText}>Add Customer</p>
                    <div className={classes.enabledButton}>
                        <p className={classes.enabledButtonText}>Enabled</p>
                    </div>
                </div>
                <div className={classes.field}>
                    <p className={classes.fieldText}>Receivables</p>
                    <div className={classes.enabledButton}>
                        <p className={classes.enabledButtonText}>Enabled</p>
                    </div>
                </div>
                <div className={classes.field}>
                    <p className={classes.fieldText}>Customer Details</p>
                    <div className={classes.enabledButton}>
                        <p className={classes.enabledButtonText}>Enabled</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default AccessTooltip