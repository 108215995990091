// import classes from './TransactionSummary.module.scss'
import classes from '../../Global/TransactionDetails.module.scss'
import { useState } from 'react'
import Status from 'Components/ReportTable/Status'
import summarySaved from 'assets/Images/summary-saved.svg'
import BgOrnamentCircle from 'assets/Icons/bg-ornament-circle.svg'
import BgOrnamentDots from 'assets/Icons/bg-ornament-dots.svg'
import { useNavigate } from 'react-router-dom'
import clsx from 'clsx'
import { formatNumberToLocaleString } from 'hooks/formatNumberToLocaleString';

const TransactionSummary = (props) => {
  const [activeState, setActiveState] = useState(1)
  const { invoice_summary, heading, clickable } = props
  const navigate = useNavigate()

  const navigateToReceievable = () => {
    navigate('/receivables')
  }

  const formatInvoiceSummary = () => {
    const formattedInvoiceSummary = []
    const statusToCover = ['OVERDUE', 'SETTLED', 'OUTSTANDING', 'CREATED']
    if (invoice_summary) {
      Object.entries(invoice_summary).forEach(([statusType, data]) => {
        if (statusToCover.includes(statusType)) {
          const invoiceData = {
            status: statusType,
            count: data.count,
            details: Object.entries(data?.details).map(
              ([currency, amount]) => ({ currency, amount })
            ),
          }
          formattedInvoiceSummary.push(invoiceData)
        }
      })
    }
    return formattedInvoiceSummary
  }

  const getData = (data, type) => {
    return data?.forEach((item) => {
      if (item?.status === type) {
        return item
      }
    })
  }

  const sortData = (data) => {
    const currencyOrder = ['$', '€', '£']

    const sortedData = data.filter((item) =>
      currencyOrder.includes(item.currency)
    )

    sortedData.sort((a, b) => {
      return (
        currencyOrder.indexOf(a.currency) - currencyOrder.indexOf(b.currency)
      )
    })
    return sortedData
  }

  const data = formatInvoiceSummary()
  const outstanding = data.filter((obj) => obj.status === 'OUTSTANDING')[0]
  const settled = data.filter((obj) => obj.status === 'SETTLED')[0]
  const overdue = data.filter((obj) => obj.status === 'OVERDUE')[0]
  const created = data.filter((obj) => obj.status === 'CREATED')[0]

  const totalOutstanding = {
    count: outstanding.count,
    details: sortData(outstanding.details),
    status: outstanding.status,
  }
  const totalSettled = {
    count: settled.count,
    details: sortData(settled.details),
    status: settled.status,
  }
  const totalOverdue = {
    count: overdue.count,
    details: sortData(overdue.details),
    status: overdue.status,
  }
  const totalCreated = {
    count: created.count,
    details: sortData(created.details),
    status: created.status,
  }

  const calculateTotalReceivables = (outstandingObject, settledObject, createdObject) => {
    const outstandingDetails = outstandingObject.details
    const settledDetails = settledObject.details
    const createdDetails = createdObject.details

    const totalReceivables = {}

    // For now we'll only show created details
    // // Process outstanding details
    // for (const detail of outstandingDetails) {
    //   const currency = detail.currency
    //   const amount = detail.amount
    //   totalReceivables[currency] = (totalReceivables[currency] || 0) + amount
    // }

    // // Process settled details
    // for (const detail of settledDetails) {
    //   const currency = detail.currency
    //   const amount = detail.amount
    //   totalReceivables[currency] = (totalReceivables[currency] || 0) + amount
    // }
    
    // Process created details
    for (const detail of createdDetails) {
      const currency = detail.currency
      const amount = detail.amount
      totalReceivables[currency] = (totalReceivables[currency] || 0) + amount
    }

    return totalReceivables
  }
  const totalReceivables = calculateTotalReceivables(
    totalOutstanding,
    totalSettled,
    totalCreated
  )
  const totalReceivablesCount =
    totalOutstanding?.count + totalSettled?.count || 0
  return (
    <div className={`${classes.transaction_details} ${props.className}`}>
      <div className={classes.box1}>
        <div>Summary</div>
      </div>
      <div
        className={clsx(
          classes.box2,
          'grid grid-cols-3 md:gap-5 bg-white p-8 border border-solid border-[#D2D5DB99] rounded-2xl'
        )}
      >
        <div
          className={clsx(
            classes.transaction_TOTAL_AMOUNT,
            // 'col-span-1  h-[160px] md:h-[200px] pb-[24px] md:pt-[24px]'
            'col-span-1  h-[max-content] pb-[24px] md:pt-[24px]'
          )}
        >
          {/* <div className={classes.bg}>
            <img src={CardFeatureBG} alt='' className={""} style={{}} />
          </div> */}

          <div className={classes.bg_mask}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                flex: 1,
              }}
            >
              <div>
                <div
                  style={{
                    fontFamily: 'Inter',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    fontSize: '14px',
                    lineHeight: '20px',
                    display: 'flex',
                    alignItems: 'center',
                    color: 'rgba(30, 51, 63, 0.5)',
                  }}
                >
                  Total Receivables Value
                </div>
                <div
                  style={{
                    fontFamily: 'Inter',
                    fontStyle: 'normal',
                    fontWeight: '600',
                    fontSize: '32px',
                    lineHeight: '48px',
                    /* identical to box height, or 150% */

                    display: 'flex',
                    alignItems: 'center',
                    textAlign: 'center',

                    color: '#181C30',
                  }}
                  className={clsx(classes.summaryCurrencyLabelHeader)}
                >
                  <div className="flex flex-col items-start">
                    {/* {totalReceivablesValues.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className={clsx(classes.invoiceCurrencyFont)}
                        >
                          {item.sign.toLocaleString('en-US')}{' '}
                          {item.amount.toLocaleString('en-US')}
                        </div>
                      )
                    })} */}
                    {Object.keys(totalReceivables).length > 0 ? (
                      Object.keys(totalReceivables).map((item, index) => {
                        return (
                          <div
                            key={index}
                            className={clsx(classes.invoiceCurrencyFont)}
                          >
                            {item.toLocaleString('en-US')}{' '}
                            {formatNumberToLocaleString((totalReceivables[item]), 2, 2)}
                          </div>
                        )
                      })
                    ) : (
                      <div className={clsx(classes.invoiceCurrencyFont)}>0</div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className={clsx(classes.money_status, 'mt-[30px]')}>
              <div
                className={clsx(classes.invoiceTitle, 'flex flex-col gap-1')}
              >
                {totalReceivablesCount}{' '}
                {totalReceivablesCount > 1 ? 'Invoices' : 'Invoice'} created
              </div>
              <div className={classes.ytd}> YTD</div>
            </div>
          </div>
        </div>

        <div className="flex flex-row col-span-2 h-[100%]">
          <div
            className={`${classes[`transaction_SETTLED`]} ${
              activeState === data[1].id
                ? classes.transaction_active
                : undefined
            } relative overflow-hidden`}
            onClick={() => {
              if (clickable) navigateToReceievable()
            }}
            style={{
              cursor: clickable ? 'pointer' : 'default',
            }}
          >
            <img
              src={BgOrnamentCircle}
              alt=""
              className="absolute w-[35%] z-0 bottom-0 right-0"
            />
            <img
              src={BgOrnamentCircle}
              alt=""
              className="absolute w-[35%] top-0 rotate-180 -right-[17%]"
            />
            <div className={clsx(classes.money_status)}>
              <div className={classes.status}>
                <div
                  style={{
                    fontFamily: 'Inter',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    fontSize: '14px',
                    lineHeight: '20px',
                    display: 'flex',
                    alignItems: 'center',
                    color: 'rgba(30, 51, 63, 0.5)',
                  }}
                >
                  Settled in your bank account
                </div>
              </div>
              <div className={clsx(classes.money)}>
                {/* {settledAmounts.length > 0
                  ? settledAmounts.map((item, index) => {
                      return `${item.sign} ${item.amount}`.toLocaleString(
                        'en-US'
                      )
                    })
                  : 0} */}
                {totalSettled?.count > 0 ? (
                  totalSettled.details.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className={clsx(classes.invoiceCurrencyFont)}
                      >
                        {item.currency.toLocaleString('en-US')}{' '}
                        {formatNumberToLocaleString((item?.amount), 2, 2)}
                      </div>
                    )
                  })
                ) : (
                  <div className={clsx(classes.summaryCurrencyFont)}>0</div>
                )}
                <div className="flex flex-col">
                  {/* {settledAmounts.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className={clsx(classes.summaryCurrencyFont)}
                      >
                        {item.sign.toLocaleString('en-US')}{' '}
                        {item.amount.toLocaleString('en-US')}
                      </div>
                    )
                  })} */}
                </div>
              </div>
            </div>
            <div className={clsx(classes.invoiceTitle, 'flex flex-col gap-1')}>
              {/* {totalSettledInvoicesCount}{' '}
              {totalSettledInvoicesCount > 1 ? 'Invoices' : 'Invoice'} */}
              {totalSettled.count}{' '}
              {totalSettled.count > 1 ? 'Invoices' : 'Invoice'} settled
              <div className="h-[20px]">
                {/* <Status
                icon={summarySaved}
                status={`saved`}
                statusText={`${data[1].currency}100 saved`}
              /> */}
              </div>
            </div>
          </div>
          <div
            className={`${classes[`transaction_OUTSTANDING`]} ${
              activeState === data[1].id
                ? classes.transaction_active
                : undefined
            } relative overflow-hidden`}
            onClick={() => {
              if (clickable) navigateToReceievable()
            }}
            style={{
              cursor: clickable ? 'pointer' : 'default',
            }}
          >
            <img
              src={BgOrnamentDots}
              alt=""
              className="absolute w-[24%] z-0 bottom-0 right-0"
            />
            <img
              src={BgOrnamentDots}
              alt=""
              className="absolute w-[24%] min-w-[50px] rotate-[35deg] -top-[12%] right-2 "
            />
            <div className={classes.money_status}>
              <div className={classes.status}>
                <div
                  style={{
                    fontFamily: 'Inter',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    fontSize: '14px',
                    lineHeight: '20px',
                    display: 'flex',
                    alignItems: 'center',
                    color: 'rgba(30, 51, 63, 0.5)',
                  }}
                >
                  Pending payment
                </div>
              </div>
              <div className={classes.money}>
                {/* {data[3].currency}
                {data[3].amount.toLocaleString('en-US')} */}
                <div className="flex flex-col items-start">
                  {/* {totalOutstanding.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className={clsx(classes.summaryCurrencyFont)}
                        >
                          {item.sign.toLocaleString('en-US')}{' '}
                          {item.amount.toLocaleString('en-US')}
                        </div>
                      )
                    })} */}
                  {totalOutstanding.count > 0 ? (
                    totalOutstanding.details.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className={clsx(classes.invoiceCurrencyFont)}
                        >
                          {item.currency.toLocaleString('en-US')}{' '}
                          {formatNumberToLocaleString((item?.amount), 2, 2)}
                        </div>
                      )
                    })
                  ) : (
                    <div className={clsx(classes.invoiceCurrencyFont)}>0</div>
                  )}
                </div>
              </div>
            </div>
            <div className={clsx(classes.invoiceTitle, 'flex flex-col gap-1')}>
              <p className="">
                {totalOutstanding.count}{' '}
                {totalOutstanding.count > 1 ? 'Invoices' : 'Invoice'}
              </p>
              <div className="h-[20px]">
                {totalOverdue?.count > 0 && (
                  <Status
                    status={`OVERDUE_summary`}
                    statusText={`${totalOverdue?.count} OVERDUE >`}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TransactionSummary
