import { createSlice } from "@reduxjs/toolkit";

const firaReportsInitialState = {
    fira_reports: [],
    payout_reports: [],
};

export const FiraReportsSlice = createSlice({
    name: "firaReports",
    initialState: firaReportsInitialState,
    reducers: {
        setFiraReports(state, action) {
            state.fira_reports = action.payload;
        },
        setPayoutReports(state, action) {
            state.payout_reports = action.payload;
        },
    },
});


export const FiraReportsActions = FiraReportsSlice.actions;