// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fullScreenModal_fullScreenModal__D40AN {
  box-sizing: border-box;
  /* min-width: 100vw; */
  /* min-height: 100vh; */
  background-color: #F4F6FA;
  position: fixed;
  top: 0;
  left: 0px;
  right: 0px;
  z-index: 90;
  height: 100%;
  overflow-y: scroll;
}
@media (max-width: 640px) {
  .fullScreenModal_fullScreenModal__D40AN {
    left: 0px;
    right: 0px;
    width: 100vw;
  }
}`, "",{"version":3,"sources":["webpack://./src/Layout/fullScreenModal.module.scss"],"names":[],"mappings":"AAAA;EACI,sBAAA;EACA,sBAAA;EACA,uBAAA;EACA,yBAAA;EACA,eAAA;EACA,MAAA;EACA,SAAA;EACA,UAAA;EACA,WAAA;EACA,YAAA;EACA,kBAAA;AACJ;AACI;EAbJ;IAiBQ,SAAA;IACA,UAAA;IACA,YAAA;EADN;AACF","sourcesContent":[".fullScreenModal{\n    box-sizing: border-box;\n    /* min-width: 100vw; */\n    /* min-height: 100vh; */\n    background-color: #F4F6FA;\n    position: fixed;\n    top: 0;\n    left: 0px;\n    right: 0px;\n    z-index: 90;\n    height: 100%;\n    overflow-y: scroll;\n\n    @media (max-width:640px) {\n        // min-width: auto;\n        // left: 0;\n        // padding: 0 2rem;\n        left: 0px;\n        right: 0px;\n        width: calc(100vw);\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fullScreenModal": `fullScreenModal_fullScreenModal__D40AN`
};
export default ___CSS_LOADER_EXPORT___;
