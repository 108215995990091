/* eslint-disable complexity */
import { get, post, httpPut, httpDelete } from "./network";

const URL = {
  createTxn: 'transactions?customerId={customerId}',
  uploadMedia: 'media/upload-link',
  deleteMedia: 'media/delete-link',
  bulkTransactionsUploadLink: 'bulk/transactions/upload-link',
  bulkTransactionsDeleteLink: 'bulk/transactions/delete',
}

export async function apiCreateTransaction(customerId) {
  return post(URL.createTxn.replace("{customerId}", customerId));
}

export async function apiCreateMediaUploadUrl(params) {
  return get(URL.uploadMedia, params)
}

export async function apiUploadMediaInS3(url, file) {
  return httpPut(url, file);
}

export async function apiDeleteMedia({txnId, invoiceLinkId}) {
  return get(`${URL.deleteMedia}?invoiceLinkId=${invoiceLinkId}&txnId=${txnId}`);
}

export async function apiCreateMediaBulkTransactionsUploadUrl(params) {
  return get(URL.bulkTransactionsUploadLink, params)
}

export async function apiDeleteMediaBulkTransactionsUploadUrl({linkId, transactionId}) {
  return httpDelete(`${URL.bulkTransactionsDeleteLink}?linkId=${linkId}&transactionId=${transactionId}`);
}