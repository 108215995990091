// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UserTableMobile_mobileTable__6fqDt {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  border-radius: 12px;
  border: 1px solid #EBEFF1;
  background: #FFF;
  box-shadow: 0px 4px 20px 0px rgba(76, 87, 125, 0.02);
  margin-bottom: 80px;
}
.UserTableMobile_mobileTable__6fqDt .UserTableMobile_bigCont__hlrlA {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-bottom: 20px;
  border-bottom: 1px dashed var(--accent_grey_300);
}
.UserTableMobile_mobileTable__6fqDt .UserTableMobile_bigCont__hlrlA .UserTableMobile_cont__jFcQE {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.UserTableMobile_mobileTable__6fqDt .UserTableMobile_bigCont__hlrlA .UserTableMobile_cont__jFcQE .UserTableMobile_part1__unDq6 {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}
.UserTableMobile_mobileTable__6fqDt .UserTableMobile_bigCont__hlrlA .UserTableMobile_cont__jFcQE .UserTableMobile_part2Tag__J2TeR {
  color: #1E333F;
  font-size: 12px;
  font-weight: 600;
  line-height: 24px;
  padding: 0px 8px;
  border-radius: 4px;
  border: 1px solid #E4E7EC;
}
.UserTableMobile_mobileTable__6fqDt .UserTableMobile_bigCont__hlrlA .UserTableMobile_cont__jFcQE .UserTableMobile_part2Date__X2QVn {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Admin/profile/profileComponents/UserTableMobile.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;EACA,mBAAA;EACA,yBAAA;EACA,gBAAA;EACA,oDAAA;EACA,mBAAA;AACJ;AAAI;EACI,aAAA;EACA,sBAAA;EACA,QAAA;EACA,oBAAA;EACA,gDAAA;AAER;AADQ;EACI,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,WAAA;AAGZ;AADY;EACI,eAAA;EACA,gBAAA;EACA,iBAAA;AAGhB;AAAY;EACI,cAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;EACA,kBAAA;EACA,yBAAA;AAEhB;AAAY;EACI,eAAA;EACA,gBAAA;EACA,iBAAA;AAEhB","sourcesContent":[".mobileTable{\n    padding: 20px;\n    display: flex;\n    flex-direction: column;\n    gap: 20px;\n    border-radius: 12px;\n    border: 1px solid #EBEFF1;\n    background: #FFF;\n    box-shadow: 0px 4px 20px 0px rgba(76, 87, 125, 0.02);\n    margin-bottom: 80px;\n    .bigCont{\n        display: flex;\n        flex-direction: column;\n        gap: 8px;\n        padding-bottom: 20px;\n        border-bottom: 1px dashed var(--accent_grey_300);\n        .cont{\n            display: flex;\n            align-items: center;\n            justify-content: space-between;\n            width: 100%;\n\n            .part1{\n                font-size: 14px;\n                font-weight: 500;\n                line-height: 20px;\n            }\n\n            .part2Tag{\n                color: #1E333F;\n                font-size: 12px;\n                font-weight: 600;\n                line-height: 24px;\n                padding: 0px 8px;\n                border-radius: 4px;\n                border: 1px solid #E4E7EC;\n            }\n            .part2Date{\n                font-size: 14px;\n                font-weight: 500;\n                line-height: 20px; \n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mobileTable": `UserTableMobile_mobileTable__6fqDt`,
	"bigCont": `UserTableMobile_bigCont__hlrlA`,
	"cont": `UserTableMobile_cont__jFcQE`,
	"part1": `UserTableMobile_part1__unDq6`,
	"part2Tag": `UserTableMobile_part2Tag__J2TeR`,
	"part2Date": `UserTableMobile_part2Date__X2QVn`
};
export default ___CSS_LOADER_EXPORT___;
