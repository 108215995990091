import classes from "./CreateInvoice.module.scss";
import chevronRight from "../../assets/Icons/chevron-right.svg";
import radioLightIcon from "../../assets/Icons/radioLightIcon.svg";
import radioDarkIcon from "../../assets/Icons/radioDarkIcon.svg";
import {useEffect, useState} from "react";
import {modalActions} from "../../Store/modal-redux";
import {useDispatch, useSelector} from "react-redux";
import ReactDOM from "react-dom";
import BankDetailsModal from "./BankDetailsModal";
import Ellipse from "assets/Icons/Ellipse.svg";
import UseApiCall from "../../hooks/useApiCall";
import {apiGetVirtualBankDetails} from "../../services";
import {BankAccountActions} from "../../Store/bank-redux";
import Shimmer from "../../Layout/Shimmer";
import {baseUrl} from "../../constants/Network";
import {enqueueSnackbar} from "notistack";

const BankDetails = (props) => {
    const {
        curr,
        accDetails,
        setAccDetails,
        localAcc,
        setLocalAcc,
        intAcc,
        setIntAcc,
        whichSelect,
        setWhichSelect,
        loading,
        setLoading,
        paymentMethod,
    } = props;
    const dispatch = useDispatch()
    // const [loading,setLoading] = useState(true);
    // const [accData,setAccData] = useState(null);
    // const [error,setError] = useState(null);

    const showPaymentDisplay = useSelector((state) => state.modal.showPaymentDisplay);

    const handleWhichSelect = (which,event,method) => {
        // event.stopPropagation()
        setWhichSelect(which);
        if(which === "local"){
            setAccDetails(localAcc)
        }else if(which === "int"){
            setAccDetails(intAcc)
        }else if(which === "both" && localAcc?.length !== 0 && intAcc?.length !== 0){
            if(localAcc && intAcc){
                setAccDetails([...localAcc,...intAcc])
            }else if(localAcc){
                setAccDetails(localAcc)
            }else if(intAcc){
                setAccDetails(intAcc)
            }
        }
    }

    const handleDetails = (event) => {
        // event.stopPropagation()
        dispatch(modalActions.showPaymentDisplay())
    }

    // useEffect(() => {
    //     const fetchData = async () => {
    //         try {
    //             const response = await fetch(`${baseUrl.api}/virtual-accounts/details`,{
    //                 method: "GET",
    //                 headers: {
    //                     "Content-Type": "application/json",
    //                 },
    //                 credentials: "include",
    //             });
    //
    //             const jsonData = await response.json();
    //             setAccData(jsonData);
    //             setAccDetails(jsonData?.data?.virtualAccountsDetails?.paymentMethodsDetailsMap?.[curr]?.details?.LocalMethods);
    //             setLocalAcc(jsonData?.data?.virtualAccountsDetails?.paymentMethodsDetailsMap?.[curr]?.details?.LocalMethods);
    //             setIntAcc(jsonData?.data?.virtualAccountsDetails?.paymentMethodsDetailsMap?.[curr]?.details?.InternationalMethods);
    //             setLoading(false);
    //         }catch (err){
    //             setError(err);
    //             enqueueSnackbar(err, { variant: 'error' })
    //
    //             setLoading(false);
    //         }
    //     }
    //
    //     fetchData();
    // }, []);

    return(
        <>
            {showPaymentDisplay &&
                ReactDOM.createPortal(
                    <BankDetailsModal
                        contactType={'Customer'}
                        invoiceDetails={accDetails}
                        whichSelect={whichSelect}
                    />,
                    document.getElementById('modal-root')
                )}
            <div className={classes.contactCon} style={{marginTop:'10px'}}>
                {/*bank details*/}
                { loading ?

                    <div className="flex gap-[24px] w-[full] overflow-x-scroll ">
                        {[1,2,3]?.map((item, index) => (
                            <Shimmer key={index} className="w-[320px] h-[120px] mb-[12px] rounded-md "/>
                        ))}
                    </div>

                    :
                    (<div className={classes.bankCon}>
                        {
                            // localAcc &&
                            (<div
                                className={`${classes.international} ${whichSelect === "local" ? classes.intBorderActive : classes.intBorderInActive}`}
                                onClick={(event) => {
                                    if (localAcc) {
                                        handleWhichSelect("local", event, "LocalMethods");
                                    }
                                }}

                                style={{cursor : localAcc ? 'pointer' : 'not-allowed' }}
                            >
                            <div className={classes.internationalLeft}>
                                <p className={classes.intText} style={{color: "#1E333F"}}>Local Method Only</p>
                                <p className={classes.intText} style={{color: "#1E333F80"}}>{localAcc ? localAcc[0]?.paymentMethod : `No Method for ${curr}` }</p>
                                <div className={classes.detailsBtn}
                                     onClick={(event) => {
                                         if (localAcc) {
                                             handleDetails(event);
                                         }
                                     }}
                                >
                                    <p className={classes.detailsText}>Details</p>
                                    <img src={chevronRight} style={{width: "16px", height: "16px"}}/>
                                </div>
                            </div>
                            <div className={classes.internationalRight}>
                                <img src={whichSelect === "local" ? radioDarkIcon : radioLightIcon}
                                     style={{width: "24px", height: "24px"}}/>
                            </div>
                        </div>)}

                        {
                            // intAcc &&
                            (<div
                                className={`${classes.international} ${whichSelect === "int" ? classes.intBorderActive : classes.intBorderInActive}`}
                                onClick={(event) => {
                                    if (intAcc) {
                                        handleWhichSelect("int", event, "InternationalMethods");
                                    }
                                }}
                                style={{cursor : intAcc ? 'pointer' : 'not-allowed' }}
                            >
                            <div className={classes.internationalLeft}>
                                <p className={classes.intText} style={{color: "#1E333F"}}>International Method Only</p>
                                <p className={classes.intText} style={{color: "#1E333F80"}}>{intAcc ? intAcc[0]?.paymentMethod : `No Method for ${curr}`}</p>
                                <div className={classes.detailsBtn}
                                     onClick={(event) => {
                                         if (intAcc) {
                                             handleDetails(event);
                                         }
                                     }}
                                >
                                    <p className={classes.detailsText}>Details</p>
                                    <img src={chevronRight} style={{width: "16px", height: "16px"}}/>
                                </div>
                            </div>
                            <div className={classes.internationalRight}>
                                <img src={whichSelect === "int" ? radioDarkIcon : radioLightIcon}
                                     style={{width: "24px", height: "24px"}}/>
                            </div>
                        </div>)}

                        {
                            // localAcc && intAcc &&
                            (<div
                                className={`${classes.international} ${whichSelect === "both" ? classes.intBorderActive : classes.intBorderInActive}`}
                                onClick={(event) => {
                                    if (localAcc && intAcc) {
                                        handleWhichSelect("both", event, "BOTHACC");
                                    }
                                }}
                                style={{cursor : localAcc && intAcc ? 'pointer' : 'not-allowed' }}
                            >
                            <div className={classes.internationalLeft}>
                                <p className={classes.intText} style={{color: "#1E333F"}}>All Methods</p>
                                <div className="flex gap-[5px]">
                                    <p className={classes.intText}
                                       style={{color: "#1E333F80"}}>{localAcc ? localAcc[0]?.paymentMethod : `No Method for ${curr}` }</p>
                                    <img src={Ellipse}/>
                                    <p className={classes.intText}
                                       style={{color: "#1E333F80"}}>{intAcc ? intAcc[0]?.paymentMethod : `No Method for ${curr}`}</p>
                                </div>
                                <div className={classes.detailsBtn}
                                     onClick={(event) => {
                                         if (localAcc && intAcc) {
                                             handleDetails(event);
                                         }
                                     }}
                                >
                                    <p className={classes.detailsText}>Details</p>
                                    <img src={chevronRight} style={{width: "16px", height: "16px"}}/>
                                </div>
                            </div>
                            <div className={classes.internationalRight}>
                                <img src={whichSelect === "both" ? radioDarkIcon : radioLightIcon}
                                     style={{width: "24px", height: "24px"}}/>
                            </div>
                        </div>)}
                    </div>)}
            </div>
        </>
    )
}

export default BankDetails;
