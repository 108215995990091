import PageIntro from 'Components/Global/PageIntro'
import classes from 'Pages/Admin/Payouts.module.scss'
import book from 'assets/Images/book-white.svg'
import Button from 'Layout/Button'
import PayoutReportTable from 'Components/ReportTable/PayoutReportTable'
import { useDispatch, useSelector } from 'react-redux'
import UseApiCall from 'hooks/useApiCall'
import { apiGetPayoutReports } from 'services'
import { FiraReportsActions } from 'Store/reports-redux'
import { useEffect, useState } from 'react'
import { tourArrays } from "constants/Tour";
// import TooltipTourData from "Components/Tour/TooltipTourData";
import CryptoJS from 'crypto-js';
import { secretKey } from "constants";
import { enqueueSnackbar } from 'notistack';

// const tableHeader = [
//   {
//     id:1,
//     title:"invoice id",
//     width: 26,
//     minWidth:260.26,
//   },
//   {
//     id:2,
//     title:"customer",
//     width: 30,
//     minWidth:333.26,
//   },
//   {
//     id:3,
//     title:"receivable amountsss",
//     width: 20,
//     minWidth:153.26,
//   },
//   {
//     id:4,
//     title:"remitted amount",
//     width: 20,
//     minWidth:162.26,
//   },
//   // 'invoice id',
//   // 'customer',
//   // 'receivable amount',
//   // 'remitted amount',
// ]

const Payouts = () => {
    const arrBtn = [
        // {
        //   text: 'Generate Fira',
        //   icon: book,
        //   onClick: function () {},
        // },
    ]
    const dispatch = useDispatch()
    // const showTourModal = useSelector((state) => state.global.showTour);
    // const stepNumber = useSelector((state) => state.global.stepNumberInTour);
    // const [tourArray, setTourArray] = useState(tourArrays[1]);
    // const decryptValue = (encryptedValue) => {
    //     const bytes = CryptoJS.AES.decrypt(encryptedValue || '', secretKey);
    //     return bytes.toString(CryptoJS.enc.Utf8);
    // };

    // const storedUserRole = decryptValue(localStorage.getItem('nickName') || '');

    // useEffect(() => {
    //     if(storedUserRole === 'malik') setTourArray(tourArrays[0])
    // }, [])

    const payoutReports = useSelector((state) => state.reports.payout_reports)
    const [otherLoader, setOtherLoader] = useState(true)
    const [getPayoutReports, fetchingPayouts] = UseApiCall(apiGetPayoutReports, (res) => {
        setOtherLoader(false);
        dispatch(FiraReportsActions.setPayoutReports(res.data))
    }, (err) => {
        setOtherLoader(false);
        enqueueSnackbar('Error while fetching Payouts.', { variant: 'error' })
    })

    useEffect(() => {
        getPayoutReports();
    }, [])

    return (
        <div className={classes.payouts}>
            <PageIntro
                pageName={'Payouts'}
                message={'View all your payouts in one place'}
                arrBtn={arrBtn}
                className={classes.pageIntroPayouts}
                tourId = "step6"
                downloadBtn={true}
                exportDataList={payoutReports}
                loadingDownloadBtn={fetchingPayouts || payoutReports?.length === 0}
            />
            <PayoutReportTable
                tableData={payoutReports}
                mobileTableData={payoutReports}
                loading={fetchingPayouts || otherLoader}
            />
            <div className={classes.button}>
                {arrBtn.map((arr) => (
                    <Button
                        key={arr.text}
                        text={arr.text}
                        onClick={arr.onClick}
                        className={classes.btn}
                    />
                ))}
            </div>
        </div>
    )
}
export default Payouts