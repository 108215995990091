import { useDispatch } from 'react-redux';
import classes from 'Components/Payment/SendPayment.module.scss';
import Modal from 'Layout/Modal';
import { modalActions } from 'Store/modal-redux';
import ListOfContact from 'Components/General/ListOfContact';

const listOfVendorData = [
    {
        id: 'c1',
        name: 'Rahul Sahni',
        country: 'UK',
        email : 'rahul@gmail.com',
        phoneNumber : '+91 9876543210'
    },
    {
        id: 'c2',
        name: 'Rahul Sahni',
        country: 'UK',
        email : 'rahul@gmail.com',
        phoneNumber : '+91 9876543210'
    },
    {
        id: 'c3',
        name: 'Rahul Sahni',
        country: 'UK',
        email : 'rahul@gmail.com',
        phoneNumber : '+91 9876543210'
    },
    {
        id: 'c4',
        name: 'Rahul Sahni',
        country: 'UK',
        email : 'rahul@gmail.com',
        phoneNumber : '+91 9876543210'
    },
    {
        id: 'c5',
        name: 'Rahul Sahni',
        country: 'UK',
        email : 'rahul@gmail.com',
        phoneNumber : '+91 9876543210'
    },
    {
        id: 'c6',
        name: 'Rahul Sahni',
        country: 'UK',
        email : 'rahul@gmail.com',
        phoneNumber : '+91 9876543210'
    },
    {
        id: 'c7',
        name: 'Rahul Sahni',
        country: 'UK',
        email : 'rahul@gmail.com',
        phoneNumber : '+91 9876543210'
    },
    {
        id: 'c8',
        name: 'Rahul Sahni',
        country: 'UK',
        email : 'rahul@gmail.com',
        phoneNumber : '+91 9876543210'
    },
    {
        id: 'c9',
        name: 'Rahul Sahni',
        country: 'UK',
        email : 'rahul@gmail.com',
        phoneNumber : '+91 9876543210'
    },
    {
        id: 'c10',
        name: 'Rahul Sahni',
        country: 'UK',
        email : 'rahul@gmail.com',
        phoneNumber : '+91 9876543210'
    },
    {
        id: 'c11',
        name: 'Rahul Sahni',
        country: 'UK',
        email : 'rahul@gmail.com',
        phoneNumber : '+91 9876543210'
    },
    {
        id: 'c12',
        name: 'Rahul Sahni',
        country: 'UK',
        email : 'rahul@gmail.com',
        phoneNumber : '+91 9876543210'
    },

]

const SendPayment = () => {
    const dispatch = useDispatch();
  
    return (
        <Modal className={classes.modal}>
            <ListOfContact 
                contactType={'Vendor'}
                contactFunctionality={'send'}
                contact={listOfVendorData}
                btnFunction={()=>{dispatch(modalActions.addContact())}}
                btnText={'Add Vendor'}
                addContactURL={''}
                nextPageFunctionality={()=>{dispatch(modalActions.addPaymentDetail())}}
                onClose={()=>{dispatch(modalActions.sendPayment())}}
            />
        </Modal>
    )
}
export default SendPayment;