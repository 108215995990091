import classes from 'Components/Dropdown/DropdownOption.module.scss';

const DropdownOption = (props) => {
    return (
        <li 
            key={props.id} 
            className={`${classes.opt} ${props.isActive && classes.opt_active} ${props.className}`} 
            onClick={() => {props.onClick(props.data)}}>
            <div className={`${classes.btn} ${props.isActive && classes.btn_active}`}></div>
            <div className={classes.heading}>{props.heading}</div>
            {props.subHeading && <div className={classes.sub_heading}>{props.subHeading}</div>}
        </li>
    )
}

export default DropdownOption;