import PaymentDetails from "Components/Payment/PaymentDetails";
import classes from "./PaymentStatus.module.scss";
import { useEffect, useState } from "react";
import DraftInvoiceBanner from 'Components/atom/DraftInvoiceBanner/DraftInvoiceBanner';

const PaymentStatus = (props) => {
    const [smallestIndex, setSmallestIndex] = useState(10);
    const targetValues = ["INVOICE_CANCELLED", "INVOICE_SETTLED_OUTSIDE_FIDEO"];
    const { recent_transaction_response, invoice_status, invoiceId } = props;
    
//     const recent_transaction_response = {
//       "payment_details": {
//           "invoice_amount": 1000.00,
//           "receiving_amount": 900.00,
//           "pending_amount": 700.00,
//           "in_progress_amount": 200.00,
//           "settled_amount": 0,
//           "invoice_currency": {
//               "code": "USD",
//               "sign": "$",
//               "description": "US Dollar"
//           }
//       },
//       "transaction_info_list": [
//         {
//             "last_updated_time_stamp": "2023-11-23T03:29:01Z",
//             "is_processed": true,
//             "transaction_action": "INVOICE_IN_REVIEW",
//             "forex_conversion_details": null,
//             "title": "Invoice uploaded"
//         },
//         {
//             "last_updated_time_stamp": "2023-11-23T03:29:18Z",
//             "is_processed": true,
//             "transaction_action": "INVOICE_UPLOAD",
//             "forex_conversion_details": null,
//             "title": "Invoice verified"
//         },
//         {
//             "last_updated_time_stamp": null,
//             "is_processed": false,
//             "transaction_action": "PAYMENT_LINK_SENT",
//             "forex_conversion_details": null,
//             "title": "Payment link sent"
//         },
//         {
//             "last_updated_time_stamp": null,
//             "is_processed": false,
//             "transaction_action": "INVOICE_CANCELLED",
//             "forex_conversion_details": null,
//             "title": "Invoice cancelled"
//         },
//         {
//             "last_updated_time_stamp": "2023-11-23T04:09:14Z",
//             "is_processed": true,
//             "transaction_action": "INVOICE_SETTLED_OUTSIDE_FIDEO",
//             "forex_conversion_details": null,
//             "title": "Invoice paid outside of Fideo"
//         }
//     ],
//       "payment_info_list": [
//         {
//             "transaction_id": "abc",
//             "transaction_info_list": [
//                 {
//                     "last_updated_time_stamp": "2023-11-23T03:31:00Z",
//                     "is_processed": true,
//                     "transaction_action": "PAYMENT_CREDITED",
//                     "forex_conversion_details": null,
//                     "title": "$200.00 paid by rahul fideo"
//                 },
//                 {
//                     "last_updated_time_stamp": "2023-11-23T03:33:48Z",
//                     "is_processed": true,
//                     "transaction_action": "FOREX_CONVERSION_SUCCESS",
//                     "forex_conversion_details": {
//                         "converted_amount": 16194.28,
//                         "conversion_rate": 83.04761,
//                         "input_currrency": {
//                             "code": "USD",
//                             "sign": "$",
//                             "description": "US Dollar"
//                         },
//                         "output_currrency": {
//                             "code": "INR",
//                             "sign": "₹",
//                             "description": "Indian Rupee"
//                         },
//                         "transaction_charges": 415.24,
//                         "transaction_charges_currency": {
//                             "code": "INR",
//                             "sign": "₹",
//                             "description": "Indian Rupee"
//                         },
//                         "receiving_amount": 16194.28,
//                         "fira_status": null
//                     },
//                     "title": "Converted to INR"
//                 },
//                 {
//                     "last_updated_time_stamp": null,
//                     "is_processed": false,
//                     "transaction_action": "MONEY_INITIATED_TO_EXPORTER_ACCOUNT",
//                     "forex_conversion_details": null,
//                     "title": "NEFT/RTGS initiated to your A/c "
//                 },
//                 {
//                     "last_updated_time_stamp": null,
//                     "is_processed": true,
//                     "transaction_action": "FIRA_REPORT_GENERATED",
//                     "forex_conversion_details": null,
//                     "title": "FIRA report generated"
//                 }
//             ]
//         },
//         {
//             "transaction_id": "ajknxjknqjknjkxbc",
//             "transaction_info_list": [
//                 {
//                     "last_updated_time_stamp": "2023-11-23T03:31:00Z",
//                     "is_processed": true,
//                     "transaction_action": "PAYMENT_CREDITED",
//                     "forex_conversion_details": null,
//                     "title": "$200.00 paid by rahul fideo"
//                 },
//                 {
//                     "last_updated_time_stamp": "2023-11-23T03:33:48Z",
//                     "is_processed": true,
//                     "transaction_action": "FOREX_CONVERSION_SUCCESS",
//                     "forex_conversion_details": {
//                         "converted_amount": 16194.28,
//                         "conversion_rate": 83.04761,
//                         "input_currrency": {
//                             "code": "USD",
//                             "sign": "$",
//                             "description": "US Dollar"
//                         },
//                         "output_currrency": {
//                             "code": "INR",
//                             "sign": "₹",
//                             "description": "Indian Rupee"
//                         },
//                         "transaction_charges": 415.24,
//                         "transaction_charges_currency": {
//                             "code": "INR",
//                             "sign": "₹",
//                             "description": "Indian Rupee"
//                         },
//                         "receiving_amount": 16194.28,
//                         "fira_status": null
//                     },
//                     "title": "Converted to INR"
//                 },
//                 {
//                     "last_updated_time_stamp": null,
//                     "is_processed": false,
//                     "transaction_action": "MONEY_INITIATED_TO_EXPORTER_ACCOUNT",
//                     "forex_conversion_details": null,
//                     "title": "NEFT/RTGS initiated to your A/c "
//                 },
//                 {
//                     "last_updated_time_stamp": null,
//                     "is_processed": false,
//                     "transaction_action": "FIRA_REPORT_GENERATED",
//                     "forex_conversion_details": null,
//                     "title": "FIRA report generated"
//                 }
//             ]
//         }
//     ]
//   }
    const  transactionData = {
        transactions_detail : {...recent_transaction_response},
        status: invoice_status.split("_").join(" ")
    };
    const showHeader = invoice_status !== 'IN_REVIEW';

    useEffect(() => {
      let indexValue = true;
      transactionData?.transactions_detail?.transaction_info_list?.forEach((item, index) => {
        if (indexValue && targetValues.includes(item?.transaction_action) && item?.is_processed) {
          indexValue = false;
          // setSmallestIndex(() => index);
        }
      });
  
      if (indexValue) {
        // setSmallestIndex(transactionData?.transactions_detail?.transaction_info_list?.length);
      }
    }, []);

    // console.log('recent_transaction_response',recent_transaction_response);
    // console.log('invoice_status',invoice_status);
    // console.log('invoiceId',invoiceId);

    return (
        <div className={classes.payment_status_all}>
          {/* {
            invoice_status === "INVOICE_IN_DRAFT" ?
            <DraftInvoiceBanner
              invoiceId={invoiceId}
            />
            : */}
            <PaymentDetails
              className={classes.payment_status}
              transactionData={transactionData}
              // showHeader = {showHeader}
              invoice_id={invoiceId}
              smallestIndex={smallestIndex}
            />
          {/* } */}
        </div>
    )
}
export default PaymentStatus;