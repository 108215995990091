import React from 'react';
import classes from 'Components/otpInnerScreen/EnterOtp.module.scss';
import otpLock from 'assets/Icons/otpLock.svg';
import clsx from 'clsx';

const EnterOtp = ({setOtpViewNowBox,setPaymentLinkOtpRequired, handleOTPView,className}) => {

    // const handleOTPView = () => {
    //     setOtpViewNowBox((prev) => !prev);
    //     setPaymentLinkOtpRequired((prev) => !prev);
    // }
    // console.log('className',className)

  return (
    <div className={`${classes.otpBlurrContainer} `} style={{ zIndex: 20 }}>
        <div className={clsx(`${classes.otpcontainer} ${className}`)}>
            <div className={`${classes.lockImg}`}>
                <img src={otpLock} alt='otpLock' className={`${classes.img}`} />
            </div>
            <p className={`${classes.otpPara}`}>This invoice is OTP-protected. Please enter the OTP you've received on your email id.</p>
            <div className={`${classes.otpEnterBox}`}  style={{ cursor: "pointer", color: "blue" }} onClick={handleOTPView}>
                <div className={`${classes.otpEntertext}`}>
                    <span className={`${classes.text}`}  >Enter OTP to View</span>
                </div>
            </div>
        </div>
    </div>
  )
}

export default EnterOtp;