import classes from "Components/General/AdminHeader.module.scss";
import fideoLogo from "assets/Images/FideoLogo.svg";
import adminAvatar from "assets/Icons/account.svg";
import sideBarIcon from "assets/Images/SideBarIcon.svg";
import userIcon from "assets/Icons/userIcon.svg";
import logoutIcon from "assets/Icons/logoutIcon.svg";
import chevrondown from "assets/Icons/chevron-down.svg"


import { useDispatch, useSelector } from "react-redux";
import { GlobalActions } from "Store/global-redux";
import clsx from "clsx";
import useClickOutside from "hooks/useClickOutside";
import { useEffect, useState } from "react";
import UseApiCall from "hooks/useApiCall";
import { apiSignOut } from "services";
import { useNavigate } from "react-router-dom";
import { LoginActions } from "Store/auth-redux";
import CryptoJS from 'crypto-js';
import { secretKey } from "constants";

const AdminHeader = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showOptions, setShowOptions] = useState(false);
  const optRef = useClickOutside(() => setShowOptions(false));
  const userName = useSelector(state => state?.login?.userName);


  const [logOut, loggingOut] = UseApiCall(apiSignOut, () => {
    localStorage.removeItem('username')
    localStorage.removeItem('nickName')
    dispatch({type: 'CLEAR_STATE'})
    navigate("/login?mode=email");
  });

  const selectedOption = useSelector(
    (state) => state.global.selectedOption
  );
  
  const HandleProfile = () => {
    navigate("/profile-settings")
    dispatch(GlobalActions.setSelectedOption(null))
  }

  const svgContentUser = `
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M20 21V19C20 17.9391 19.5786 16.9217 18.8284 16.1716C18.0783 15.4214 17.0609 15 16 15H8C6.93913 15 5.92172 15.4214 5.17157 16.1716C4.42143 16.9217 4 17.9391 4 19V21" stroke="#1E333F" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M12 11C14.2091 11 16 9.20914 16 7C16 4.79086 14.2091 3 12 3C9.79086 3 8 4.79086 8 7C8 9.20914 9.79086 11 12 11Z" stroke="#1E333F" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
  `;


  const svgContentLogout = `
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M9 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3H9" stroke="#1E333F" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M16 17L21 12L16 7" stroke="#1E333F" stroke-linecap="round" stroke-linejoin="round"/>
  <path d="M21 12H9" stroke="#1E333F" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
  `;

  // Convert the SVG content to a data URL
  const svgDataURLUser = `data:image/svg+xml;base64,${btoa(svgContentUser)}`;
  const svgDataURLLogout = `data:image/svg+xml;base64,${btoa(svgContentLogout)}`;

  const decryptValue = (encryptedValue) => {
    const bytes = CryptoJS.AES.decrypt(encryptedValue || '', secretKey);
    return bytes.toString(CryptoJS.enc.Utf8);
  };

  const storedUserRole = decryptValue(localStorage.getItem('nickName') || '');

  // console.log(process.env.REACT_SECRET_KEY)

  const AuthOptions = [];

  if (storedUserRole === 'malik') {
    AuthOptions.push(
      {
        title: "Profile and Settings",
        icon: svgDataURLUser,
        onClick: () => {
          HandleProfile();
        },
      },
    );
  }

  const options = [
    // {
    //   title: "Profile and Settings",
    //   icon: svgDataURLUser,
    //   onClick: () => {
    //     HandleProfile();
    //   },
    // },
    ...AuthOptions,
    {
      title: "Logout",
      icon: svgDataURLLogout,
      role:"user",
      onClick: () => {
        logOut();
      },
    },
  ];

  const storedUsername = localStorage.getItem('username');

  if (storedUsername !== null) {
    const firstName = storedUsername.split(' ')[0]; // Extract the first name
    const formattedFirstName = firstName.charAt(0).toUpperCase() + firstName.slice(1); // Capitalize the first letter
    dispatch(LoginActions.setUserName(formattedFirstName));
  }

  // useEffect(() => {
  //   // Get the username from session storage

  //   if (storedUsername !== null) {
  //     const firstName = storedUsername.split(' ')[0]; // Extract the first name
  //     const formattedFirstName = firstName.charAt(0).toUpperCase() + firstName.slice(1); // Capitalize the first letter
  //     dispatch(LoginActions.setUserName(formattedFirstName));
  //   }
  // }, []);

  // console.log('newLogin')

  return (
    <div ref={optRef} className={`${classes.header} ${props.className}`}>
      <img
        src={sideBarIcon}
        alt="side-bar-icon"
        className={classes.sideBarIcon}
        onClick={() => dispatch(GlobalActions.setShowSideBarTablet())}
      />
      <img src={fideoLogo} alt="fideo-logo" className={classes.logo} />
      <div
        className={clsx(classes.profile, "relative")}
        onClick={() => setShowOptions((pre) => !pre)}
      >
        <img src={adminAvatar} alt="admin-img" className={classes.userImg} />
        {userName && (<div className={clsx(classes.userTextBox)}>
          <span className={clsx(classes.userName)}>{userName && userName || ''}</span>
          <img src={chevrondown}  alt="down" className={clsx(classes.chevrondown)}/>
        </div>)}
        {showOptions && (<div
          className={clsx(classes.profileContainer,
            "absolute top-20 right-0  z-10",
            showOptions ? "block" : "hidden"
          )}
        >
          <div className={clsx(classes.profileWrapper)}>
            {/* <div className={clsx(classes.profileBox)}>
              <img src={adminAvatar} alt="admin-img" className={classes.profileImg} />
              <div className={clsx(classes.profileTextBox)}>
                <span className={clsx(classes.profileName)}>Vikram Kumar</span>
                <span className={clsx(classes.profileEmail)}>vikram.kumar@fideo.tech</span>
              </div>
            </div> */}
          </div>
          <ul
            className={clsx(classes.listBox)}
            aria-labelledby="dropdownDefaultButton"
          >
            {options?.map((option, index) => (
                (<li onClick={option.onClick} key={index} className={clsx(classes.list)}>
                <img src={option.icon} alt="admin-img" className={clsx(classes.listImg)} />
                <p className={clsx(classes.listText)}>
                  {option.title}
                </p>
              </li>)
            ))}
          </ul>
        </div>)}
      </div>
    </div>
  );
};
export default AdminHeader;
