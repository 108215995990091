import classes from "./ComplianceTable.module.scss"
import clsx from "clsx"
import ReportTableLayout from "./ReportTableLayout"
import {useRef, useEffect, useState} from "react"
// import Status from "./Status"
import dayjs from "dayjs"
// import Action from "./Action"
// import FullScreenModal from "Layout/fullScreenModal"
// import FullModal from "Layout/FullModal"
import {Pagination, Tooltip} from "antd"
// import CustomerComplianceDetails from "Components/atom/CustomerComplianceDetails/CustomerComplianceDetails"
import MobileComplianceTable from "./MobileComplianceTable"
import PaymentStatusTag from "./PaymentStatusTag"
import Shimmer from "Layout/Shimmer"
// import ScreenWidth from "../atom/ScreenWidth";
import UseApiCall from "../../hooks/useApiCall";
import {useDispatch, useSelector} from "react-redux";
import {CustomersActions} from "../../Store/customer-redux";
import {apiGetCustomerDetails} from "../../services";
import {modalActions} from "../../Store/modal-redux";
import ReactDOM from 'react-dom';
import CustomerDetailsModal from "../molecule/CustomerDetailsModal/CustomerDetailsModal";

const tableHeader = [

    {
        header: "customer details",
        width : 18,
        minWidth: 184,
    },
    {
        header: "search date",
        width : 12,
        minWidth: 124,
    },
    // {
    //     header: "results",
    //     width : 9.95,
    //     minWidth: 102.54,
    // },
    {
        header: "match status",
        width : 18,
        minWidth: 154,
    },
    {
        header: "risk level",
        width : 12,
        minWidth: 114,
    },
    {
        header: "status",
        width : 18,
        minWidth: 194,
    },
    {
        header: "remarks",
        width : 12,
        minWidth: 144,
    },
    {
        header: "Action",
        width : 10,
        minWidth: 114,
    },
]



const ComplianceTable = (props) => {
    const dispatch = useDispatch();
    const {tableData} = props;
    const theadRef = useRef(null);
    const tbodyRef = useRef(null);
    const [selectedCustomerId, setSelectedCustomerId] = useState('');
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const showCustomerDisplay = useSelector((state) => state.modal.showCustomerDisplay)

    function abbreviateString(str) {
        if (str.length > 35) {
          return str.substr(0, 29) + '...';
        }
        return str;
    }

    const [getSelectedCustomer] = UseApiCall(apiGetCustomerDetails, (res) => {
        // console.log(res?.data)
        dispatch(CustomersActions.setSelectedCustomer(res?.data))
        setSelectedCustomer(res?.data)
        !showCustomerDisplay && dispatch(modalActions.showCustomerDisplay())
    })

    const handleCustomerSelection = async (customerDetail) => {
        setSelectedCustomerId(customerDetail?.customerId)
        // setCustomerId(customerDetail?.id)
        // setCustomerOrgId(customerDetail?.organizationId)
        //
        await getSelectedCustomer({ customerId: customerDetail?.customerId})
    }

    const itemRender = (_, type, originalElement) => {
        if (type === 'prev') {
            return <a>Previous</a>
        }
        if (type === 'next') {
            return <a>Next</a>
        }
        return originalElement
    }

    useEffect(() => {
        const handleScroll = (event) => {
          if (event.target.id === 'thead') {
            tbodyRef.current.scrollLeft = theadRef.current.scrollLeft;
          }
          else if (event.target.id === 'tbody') {
            theadRef.current.scrollLeft = tbodyRef.current.scrollLeft;
          }
        };
    
        const thead = theadRef.current;
        const tbody = tbodyRef.current;
    
        thead.addEventListener('scroll', handleScroll);
        tbody.addEventListener('scroll', handleScroll);
    
        return () => {
          thead.removeEventListener('scroll', handleScroll);
          tbody.removeEventListener('scroll', handleScroll);
        };
      }, []);

    // let screeWidth = ScreenWidth();
    // console.log('showCustomerDisplay',showCustomerDisplay)
    // console.log('selectedCustomer',selectedCustomer)
    // console.log('props?.resData?.totalElements',props?.resData?.totalElements)
    // console.log('props?.pageSize',props?.pageSize)
    // console.log('props?.currentPage',props?.currentPage)

    return (
        <>
            {showCustomerDisplay &&
                selectedCustomer &&
                ReactDOM.createPortal(
                    <CustomerDetailsModal
                        customerId={selectedCustomerId}
                        selectedCustomerId={selectedCustomerId}
                        customerData={selectedCustomer}
                        whereCalled={'compliance'}
                    />,
                    document.getElementById('modal-root')
                )}
        <div className={classes.allWrap}>
          <div className={clsx(`${classes.table} ${classes.stickyHeaderTop} ${classes.reportTableWrapTop}`)} id="thead" ref={theadRef}>
                <thead>
                    {tableHeader.map((header, index) => (
                        <th key={`${header}-${index}`} className={classes.cell_header1} 
                        style={{width:`${header.width}%`, minWidth:`${header.minWidth}px`, maxWidth:`${header.minWidth}px`}}
                        valign="top"
                        >
                        <div className={classes.cell_header2}>
                            <div className={classes.head}>{header.header}</div>
                        </div>
                        </th>
                    ))}
                    {/* <th className={classes.cell_header1} 
                       style={{width:"10%", minWidth:"48px", maxWidth:"48px"}}
                    ></th> */}
                </thead>
          </div>
          <div className={`${classes.reportTableWrap}`} id="tbody"  ref={tbodyRef}>
                <ReportTableLayout className={`${classes.table} pt-[0px]`}>
                    <tbody>
                        {props.loading
                            ?
                                <td colSpan={5}>
                                {[1,2,3]?.map((item, index) => (
                                    <Shimmer key={index} className="w-[100%] h-[80px] mb-[12px]"/>
                                ))}
                                </td>
                            :
                            tableData?.map((data,index) => {
                            return <tr className={classes.row}
                                // onClick={()=>{
                                //     setCustomerDetails(data)
                                //     setDisplayCustomerDetails(true)
                                // }}
                            >
                                <td className={classes.cell}
                                    style={{
                                        width: `${tableHeader[0].width}%`,
                                        minWidth: `${tableHeader[0].minWidth}px`,
                                        maxWidth: `${tableHeader[0].minWidth}px`
                                    }}
                                    valign="top"
                                >
                                    {data?.customerDetails ? data?.customerDetails : "--"}
                                </td>
                                <td className={classes.cell}
                                    style={{
                                        width: `${tableHeader[1].width}%`,
                                        minWidth: `${tableHeader[1].minWidth}px`,
                                        maxWidth: `${tableHeader[1].minWidth}px`
                                    }}
                                    valign="top"
                                >
                                    <div className={classes.dateColumn}>
                                        <div className={classes.dateOne}>
                                            {data?.searchDate ? dayjs(data?.searchDate).local().format('DD MMM YYYY') : "--"}
                                        </div>
                                        <div className={classes.dateTwo}>
                                            {data?.searchDate && dayjs(data?.searchDate).local().format('hh:mm A')}
                                        </div>
                                    </div>
                                </td>

                                {/* <td className={classes.cell}
                                        style={{width: '9.95%', minWidth: '102.54px', maxWidth: '102.54px'}}
                                        valign="top"
                                    >
                                        {data?.results}
                                    </td> */}
                                <td className={classes.cell}
                                    style={{
                                        width: `${tableHeader[2].width}%`,
                                        minWidth: `${tableHeader[2].minWidth}px`,
                                        maxWidth: `${tableHeader[2].minWidth}px`
                                    }}
                                    valign="top"
                                >
                                    {data?.matchStatus ?
                                        <PaymentStatusTag status={data?.matchStatus} type="light"/> : "--"}
                                </td>
                                <td className={classes.cell}
                                    style={{
                                        width: `${tableHeader[3].width}%`,
                                        minWidth: `${tableHeader[3].minWidth}px`,
                                        maxWidth: `${tableHeader[3].minWidth}px`
                                    }}
                                    valign="top"
                                >
                                    {data?.riskLevel ? `${data?.riskLevel[0].toUpperCase()}${data?.riskLevel.slice(1, data?.riskLevel.length)}` : "--"}
                                </td>
                                <td className={classes.cell}
                                    style={{
                                        width: `${tableHeader[4].width}%`,
                                        minWidth: `${tableHeader[4].minWidth}px`,
                                        maxWidth: `${tableHeader[4].minWidth}px`
                                    }}
                                    valign="top"
                                >
                                    {data?.status ? <PaymentStatusTag status={data?.status} type="light"/> : "--"}
                                </td>
                                <td className={classes.cell}
                                    style={{
                                        width: `${tableHeader[5].width}%`,
                                        minWidth: `${tableHeader[5].minWidth}px`,
                                        maxWidth: `${tableHeader[5].minWidth}px`
                                    }}
                                    valign="top"
                                >
                                    {data?.remarks?.length > 35 ?
                                        <Tooltip
                                            title={<div>{data?.remarks}</div>}
                                            placement="bottomRight"
                                        >
                                            {abbreviateString(data?.remarks)}
                                        </Tooltip>
                                        :
                                        <div>{data?.remarks ? data?.remarks : "--"}</div>
                                    }

                                </td>
                                <td className={classes.cell}
                                    style={{
                                        width: `${tableHeader[6].width}%`,
                                        minWidth: `${tableHeader[6].minWidth}px`,
                                        maxWidth: `${tableHeader[6].minWidth}px`
                                    }}
                                    valign="top"
                                >
                                    <div className="flex flex-col gap-[4px]">
                                        <button
                                            className="p-[4px] text-[#fff] bg-black rounded-[4px] cursor-pointer"
                                            onClick={() => handleCustomerSelection(data)}
                                        >
                                            Details
                                        </button>
                                    </div>
                                </td>

                                </tr>
                            })
                        }
                        
                    </tbody>
                </ReportTableLayout>
              {
                  props?.tableData?.length > 0 &&
                  <div className={classes.paginationWrap}>
                      <Pagination
                          showSizeChanger={false}
                          current={props?.currentPage + 1}
                          total={props?.resData?.totalElements}
                          pageSize={props?.pageSize}
                          // defaultPageSize={30}
                          itemRender={itemRender}
                          onChange={props?.handlePaginate}
                      />
                  </div>
              }
          </div>
            <MobileComplianceTable
                tableData={tableData}
                resData={props?.resData}
                handlePaginate={props?.handlePaginate}
                currentPage={props?.currentPage}
                pageSize={props?.pageSize}
                handleCustomerSelection={handleCustomerSelection}
            />
        </div>
            {(props?.tableData?.length === 0 && !props?.loading) &&
                (<div
                    style={{
                        textAlign: 'center',
                        width: '100%',
                        marginTop: '4rem',
            fontSize: '2.5rem'
          }}
          className={classes.table}
        >
          No Compliance Checks yet.
        </div>)
        }
        </>
    )
}

export default ComplianceTable