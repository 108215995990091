'use client'

import * as React from 'react'
import * as AccordionPrimitive from '@radix-ui/react-accordion'
import DownIcon from 'assets/Icons/chevron-down.svg'

import clsx from 'clsx'

const Accordion = AccordionPrimitive.Root

const AccordionItem = React.forwardRef(({ className, ...props }, ref) => (
  <AccordionPrimitive.Item
    ref={ref}
    className={clsx('', className)}
    {...props}
  />
))
AccordionItem.displayName = 'AccordionItem'

const AccordionTrigger = React.forwardRef(
  ({ className, children, openOnLoad, ...props }, ref) => {
    const triggerRef = React.useRef()
    React.useEffect(() => {
      if (openOnLoad && triggerRef) triggerRef?.current?.click()
    }, [])
    return (
      <AccordionPrimitive.Header className="flex">
        <AccordionPrimitive.Trigger
          ref={ref || triggerRef}
          className={clsx(
            'flex flex-1 items-start justify-between  py-4 font-medium text-2xl transition-all  [&[data-state=open]>img]:rotate-180',
            className
          )}
          {...props}
        >
          {children}
          <img
            src={DownIcon}
            alt="open"
            className="h-[24px] w-[24px] transition-transform duration-200"
          />
        </AccordionPrimitive.Trigger>
      </AccordionPrimitive.Header>
    )
  }
)
AccordionTrigger.displayName = AccordionPrimitive.Trigger.displayName

const AccordionContent = React.forwardRef(
  ({ className, children, ...props }, ref) => {
    return (
      <AccordionPrimitive.Content
        ref={ref}
        className={clsx(
          'overflow-hidden border-t border-[#ECEFF2] text-xl pt-8 transition-all data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down',
          className
        )}
        {...props}
      >
        <div className="pb-4 pt-0">{children}</div>
      </AccordionPrimitive.Content>
    )
  }
)
AccordionContent.displayName = AccordionPrimitive.Content.displayName

export { Accordion, AccordionItem, AccordionTrigger, AccordionContent }
