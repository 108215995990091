
import Modal from 'Layout/Modal'
import clsx from 'clsx'
import { useDispatch, useSelector } from 'react-redux'
import { modalActions } from 'Store/modal-redux'
import classes from './ComplianceChecks.module.scss'
import leftPointer from 'assets/Icons/leftWhite.svg'
import { useEffect, useRef, useState } from 'react'
import {Tooltip, Pagination, Tabs} from "antd";
import KeyDataHit from "./KeyDataHit";
import AdverseMediaHit from "./AdverseMediaHit";
import ComplianceStatusTag from "../../ReportTable/ComplianceStatusTag";



const HitDetailsModalComp = (props) => {
    const dispatch = useDispatch();
    // const showHitComp = useSelector((state) => state.modal.showHitComp)
    const customerDetailModalRef = useRef(null);

    const items = [
        {
            key: 'KeyData',
            label: `Key Data`,
            children: <KeyDataHit
                hitData={props.hitData}
                SearchData={props.SearchData}
                dobArray={props.dobArray}
                countryArray={props.countryArray}
                typeCountArray={props.typeCountArray}
                groupedStringsResult={props.groupedStringsResult}
            />,
        },
        {
            key: 'AdverseMedia',
            label: `Adverse Media`,
            children: <AdverseMediaHit
                hitData={props.hitData}
                SearchData={props.SearchData}
                dobArray={props.dobArray}
                countryArray={props.countryArray}
                typeCountArray={props.typeCountArray}
            />,
        }
    ]

    const onChange = (key) => {
        // console.log('on tab change', key);
    }

    // console.log('start',start);
    // console.log('end',end);
    // console.log('entities',entities);
    // console.log('showHitComp',showHitComp);

    return (
        <>
            <Modal
                className={clsx(
                    classes.modal,
                    `bg-[#F4F6FA] p-[0px] overflow-scroll z-[42]`
                )}
            >
                <div
                    ref={customerDetailModalRef}
                    className={clsx(classes.topHeader, "flex items-start justify-between border-b-[1px] border-[#E8EAEF] px-[20px] md:px-[60px] py-[20px] bg-[#1E333F]")}
                >
                    {/*left*/}
                    <div className="flex gap-[16px]">
                        <div
                            className="flex items-center justify-center w-[48px] h-[48px] rounded-[100px] px-[12px] py-[8px] bg-[#FFFFFF14] cursor-pointer"
                            onClick={() => {
                                dispatch(modalActions.setShowHitComp(false))
                            }}
                        >
                            <img src={leftPointer} style={{width: "48px", height: "48px"}}/>
                        </div>


                        <div className="flex-start flex-col gap-[4px]">
                            <p className="font-inter font-[700] text-[16px] leading-[20px] text-[#FFFFFF]">{props.hitData?.doc?.name ? props.hitData?.doc?.name : "--"}</p>
                            <div className="flex items-center gap-[4px]">
                                <div className="flex flex-col items-start md:items-center justify-center md:flex-row gap-[4px]">
                                    <p className="font-inter font-[500] text-[14px] leading-[16px] text-[#FFFFFF66]">Search
                                        Reference</p>
                                    <p className="font-inter font-[400] text-[14px] leading-[20px] text-[#FFFFFF]">{props.hitData?.doc?.id ? props.hitData?.doc?.id : "--"}</p>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/*right*/}
                    <div className="flex items-start gap-[4px]">
                        {/*Risk Level*/}
                        <div className="flex flex-col items-start justify-start gap-[4px]">
                            {props.hitData?.doc?.['match_status'] &&
                                <ComplianceStatusTag status={props.hitData?.doc?.['match_status']} type="light"/>
                            }
                            <div
                                className="font-inter ml-[8px] font-[500] text-[14px] leading-[24px] text-[#FFFFFF]">{props.hitData?.doc?.['risk_level']?.toLowerCase() !== 'unknown' ? props.hitData?.doc?.['risk_level']?.slice(0, 1)?.toUpperCase() + props.hitData?.doc?.['risk_level']?.slice(1, props.hitData?.doc?.['risk_level']?.length)?.toLowerCase() + " Risk" : ' '}</div>
                        </div>
                    </div>
                </div>

                <div className="flex justify-start gap-[24px] px-[20px] md:px-[60px] py-[20px]">
                    <div
                        className="flex flex-col w-full gap-[48px] "
                        style={{
                            background: '#F3F6FA',
                        }}
                    >
                        <Tabs defaultActiveKey="1" items={items} onChange={onChange}/>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default HitDetailsModalComp
