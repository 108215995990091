import Action from 'Components/ReportTable/Action'
import ContactInfo from 'Components/Global/ContactInfoNew'
import classes from 'Components/ReportTable/MobileReportTable.module.scss'
import ReportTableLayout from 'Components/ReportTable/ReportTableLayout'
import clsx from 'clsx'
import CustomerVerificationTag from 'Components/atom/CustomerVerificationTag/CustomerVerificationTag'
import { Tooltip } from 'antd'
import ReportsPaymentStatusTag from './ReportsPaymentStatusTag'
import { formatNumberToLocaleString } from 'hooks/formatNumberToLocaleString';
import Shimmer from 'Layout/Shimmer'

const MobileCustomerReportTable = (props) => {
  const getTotalTooltipText = (data) => {
    const totalOutstanding = data.map((item, index) => {
      return (
        <div key={index} className={clsx(classes.currencyTooltip)}>
          <div className={clsx(classes.currencySymbol)}>{item.currency}</div>
          <div className={clsx(classes.currencyAmount)}>
            {formatNumberToLocaleString((item?.totalOutstanding?.amount), 2, 2)}
          </div>
        </div>
      )
    })
    return <>{totalOutstanding}</>
  }
  if (props.tableData?.length > 0 && !props.loading)
    return (
      <ReportTableLayout className={clsx(`${classes.table} ${props.className}`, props?.tableData?.length === 0 && 'hidden')} >
        {props?.tableData?.length > 0 && props?.tableData?.map((data, index) => {
          const { customer_detail, customer_id } = data
          const total_outstanding = data.invoice_summary.totalOutstanding
          const { customerName, country, countryName, emailAddress, id, displayId } =
            customer_detail || {}
          return (
            <div key={`${data.email}-${index}`} className={classes.row}>
              <div className={classes.infoOne}>
                <div className={classes.invoice} style={{
                  cursor: 'pointer',
                  userSelect: 'none'
                }} onClick={() => props.handleCustomerSelection(customer_detail)}>{displayId}</div>
                {/* <Action option={props?.option} cellData={data} className={classes.action} /> */}
                <Action option={data?.customer_detail?.verificationStatus === "PENDING_VERIFICATION" ? [...props?.option?.slice(0,3)] : [...props?.option?.slice(0,2), ...props?.option?.slice(3,4)]} cellData={data} className={classes.action} />
              </div>
              <div className={clsx(classes.infoTwo)}>
                <ContactInfo
                  name={customerName}
                  country={country}
                  countryName={countryName}
                  className={classes.contact}
                />
                <div className="h-[20px] mt-1 w-fit">
                  {data?.customer_detail?.verificationStatus && (
                    // <CustomerVerificationTag
                    //   status={data?.customer_detail?.verificationStatus}
                    //   message={data?.customer_detail?.verificationComment}
                    // />
                    <ReportsPaymentStatusTag status={data?.customer_detail?.verificationStatus} type="light" />
                  )}
                </div>
              </div>
              <div className="flex flex-row justify-between items-center mt-4">
                <p className="text-[#1E333F80]">Total Outstanding</p>
                {/* <p className='text-2xl'>{total_outstanding?.currency}{total_outstanding?.amount}</p> */}
                <p className="text-2xl">
                  <Tooltip
                    placement="bottom"
                    title={() =>
                      getTotalTooltipText(data.invoice_summary, 'OUTSTANDING')
                    }
                  >
                    {data.invoice_summary.reduce((prev, curr) => {
                      return prev + curr.totalOutstanding.number_of_invoice
                    }, 0) + ' Invoices'}
                  </Tooltip>
                </p>
              </div>
            </div>
          )
        })}
      </ReportTableLayout>
    )
  return (
    <div
      style={{
        textAlign: 'center',
        width: '100%',
        marginTop: '4rem',
        fontSize: '2.5rem'
      }}
      className={classes.table}
    >
      {
          props.loading ?
          <>
            <Shimmer className="w-[100%] mb-[10px] h-[100px]"/>
            <Shimmer className="w-[100%] mb-[10px] h-[100px]"/>
            <Shimmer className="w-[100%] h-[100px]"/>
          </>
          :
          "No Recent Customer Yet."
      }
    </div>
  )
}

export default MobileCustomerReportTable
