import Button from 'Layout/Button';
import classes from 'Pages/Admin/Cashflow.module.scss';

const Cashflow = ( ) => {
    return (
        <div className={classes.cashflow}>  
            <div className={classes.text}>Coming Soon.</div>
            {/* <Button text="Manage cashflow" className={classes.button}/> */}
        </div>
    )
}
export default Cashflow;