// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MobileInvoiceSuccessPrompt_backdrop__rkU4g {
  z-index: 91;
  top: 0;
  height: 100vh;
}

.MobileInvoiceSuccessPrompt_headerTop__BKbrl {
  padding: 20px;
  display: flex;
  align-self: center;
  justify-content: space-between;
  font-size: 18px;
  font-weight: 600;
  line-height: 26px;
  border-bottom: 1px solid #D7DDE1;
}
.MobileInvoiceSuccessPrompt_headerTop__BKbrl .MobileInvoiceSuccessPrompt_invoiceHead__kr8Le {
  max-width: 80%;
  overflow-wrap: anywhere;
}`, "",{"version":3,"sources":["webpack://./src/Components/atom/MobileInvoiceSuccess/MobileInvoiceSuccessPrompt.module.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,MAAA;EACA,aAAA;AACJ;;AAEA;EACI,aAAA;EACA,aAAA;EACA,kBAAA;EACA,8BAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,gCAAA;AACJ;AACI;EACI,cAAA;EACA,uBAAA;AACR","sourcesContent":[".backdrop{\n    z-index: 91;\n    top: 0;\n    height: 100vh;\n}\n\n.headerTop{\n    padding: 20px;\n    display: flex;\n    align-self: center;\n    justify-content: space-between;\n    font-size: 18px;\n    font-weight: 600;\n    line-height: 26px;\n    border-bottom: 1px solid #D7DDE1;\n\n    .invoiceHead{\n        max-width: 80%;\n        overflow-wrap: anywhere;\n    }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"backdrop": `MobileInvoiceSuccessPrompt_backdrop__rkU4g`,
	"headerTop": `MobileInvoiceSuccessPrompt_headerTop__BKbrl`,
	"invoiceHead": `MobileInvoiceSuccessPrompt_invoiceHead__kr8Le`
};
export default ___CSS_LOADER_EXPORT___;
