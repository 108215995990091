// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MobileReportTable_table__YJt3D {
  padding: 2rem 2rem;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 2rem;
  margin-top: 2rem;
  width: 100%;
  display: none;
}
@media (max-width: 850px) {
  .MobileReportTable_table__YJt3D {
    display: block;
  }
}
@media (max-width: 600px) {
  .MobileReportTable_table__YJt3D {
    margin-bottom: 10rem;
  }
}
.MobileReportTable_table__YJt3D .MobileReportTable_row__PELH2 {
  padding: 1.8rem 0;
  display: flex;
  flex-direction: column;
}
.MobileReportTable_table__YJt3D .MobileReportTable_row__PELH2:not(:last-child) {
  border-bottom: 1px dashed #D7DDE1;
}
.MobileReportTable_table__YJt3D .MobileReportTable_row__PELH2 .MobileReportTable_infoOne__HYncf {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.2rem;
}
.MobileReportTable_table__YJt3D .MobileReportTable_row__PELH2 .MobileReportTable_infoOne__HYncf .MobileReportTable_invoice__D4Jyw {
  color: rgba(30, 51, 63, 0.7);
}
.MobileReportTable_table__YJt3D .MobileReportTable_row__PELH2 .MobileReportTable_infoTwo__HtlR4 {
  display: flex;
  justify-content: space-between;
}
.MobileReportTable_table__YJt3D .MobileReportTable_row__PELH2 .MobileReportTable_infoTwo__HtlR4 .MobileReportTable_infoThree__8sYGR {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.MobileReportTable_table__YJt3D .MobileReportTable_row__PELH2 .MobileReportTable_infoTwo__HtlR4 .MobileReportTable_infoThree__8sYGR .MobileReportTable_amount__klnqf {
  margin-top: auto;
}
.MobileReportTable_table__YJt3D .MobileReportTable_row__PELH2 .MobileReportTable_infoTwo__HtlR4 .MobileReportTable_infoThree__8sYGR .MobileReportTable_status__FtOnO {
  margin-top: 0.4rem;
}`, "",{"version":3,"sources":["webpack://./src/Components/ReportTable/MobileReportTable.module.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,gBAAA;EACA,iBAAA;EACA,iBAAA;EACA,gBAAA;EACA,WAAA;EAEA,aAAA;AAAJ;AAEI;EAVJ;IAWQ,cAAA;EACN;AACF;AACI;EAdJ;IAeQ,oBAAA;EAEN;AACF;AAAI;EACI,iBAAA;EACA,aAAA;EACA,sBAAA;AAER;AAAQ;EACI,iCAAA;AAEZ;AAEQ;EACI,aAAA;EACA,8BAAA;EACA,mBAAA;EACA,qBAAA;AAAZ;AAEY;EACI,4BAAA;AAAhB;AAQQ;EACI,aAAA;EACA,8BAAA;AANZ;AAYY;EACI,aAAA;EACA,sBAAA;EACA,qBAAA;AAVhB;AAYgB;EACI,gBAAA;AAVpB;AAagB;EACI,kBAAA;AAXpB","sourcesContent":[".table{\n    padding: 2rem 2rem;\n    font-weight: 500;\n    font-size: 1.4rem;\n    line-height: 2rem;\n    margin-top: 2rem;\n    width: 100%;\n    \n    display: none;\n\n    @media (max-width: 850px) {\n        display: block;\n    }\n    \n    @media (max-width:600px) {\n        margin-bottom: 10rem;\n    }\n\n    .row{\n        padding: 1.8rem 0;\n        display: flex;\n        flex-direction: column;\n        \n        &:not(:last-child){\n            border-bottom: 1px dashed #D7DDE1;\n        }\n        \n\n        .infoOne{\n            display: flex;\n            justify-content: space-between;\n            align-items: center;\n            margin-bottom: 1.2rem;\n\n            .invoice{\n                color: rgba(30, 51, 63, 0.7);\n            }\n\n            .action{\n\n            }\n        }\n\n        .infoTwo{\n            display: flex;\n            justify-content: space-between;\n\n            .contact{\n\n            }\n\n            .infoThree{\n                display: flex;\n                flex-direction: column;\n                align-items: flex-end;\n\n                .amount{\n                    margin-top: auto;\n                }\n\n                .status{\n                    margin-top: 0.4rem;\n                    // height: 2rem;\n                }\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table": `MobileReportTable_table__YJt3D`,
	"row": `MobileReportTable_row__PELH2`,
	"infoOne": `MobileReportTable_infoOne__HYncf`,
	"invoice": `MobileReportTable_invoice__D4Jyw`,
	"infoTwo": `MobileReportTable_infoTwo__HtlR4`,
	"infoThree": `MobileReportTable_infoThree__8sYGR`,
	"amount": `MobileReportTable_amount__klnqf`,
	"status": `MobileReportTable_status__FtOnO`
};
export default ___CSS_LOADER_EXPORT___;
