import React, { useEffect, useRef, useState } from 'react'
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetFooter,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from 'Components/atom/BottomSheet/BottomSheet'
import Button from 'Layout/Button'
import {modalActions} from 'Store/modal-redux';
import UseApiCall from 'hooks/useApiCall';
import { apiGetBulkTransactionSubmit } from 'services';
import FileBrowserNew from 'Components/atom/FileBrowserForUploader/FileBrowserNew';
import clsx from 'clsx'
import { enqueueSnackbar } from 'notistack'
import { useDispatch } from 'react-redux';
import styles from "../../../Components/atom/FileBrowserForUploader/FileBrowserNew.module.scss";
import ConnectIcon from "assets/Icons/connect.svg";

const DocumentSheet = (props) => {
  const dispatch = useDispatch();

  const {
    setInvoice,
    invoice,
    isMobile,
    bulkTransactionsIdLoader,
    bulkTransactionsIdResult,
  } = props;

  const [getBulkTransactionSubmit] = UseApiCall(apiGetBulkTransactionSubmit, (res) => {
    dispatch(modalActions.bulkUploaderDisplay(false));
    enqueueSnackbar("Invoices Uploaded Successfully.", { variant: 'success' })
}, (err) => {
    enqueueSnackbar(`${err?.data?.error_response?.message} ${err?.data?.error_response?.errors !== null ? JSON.stringify(err?.data?.error_response?.errors[0]) : ""}`, { variant: 'error' })
})

  return (
    <Sheet className="bg-white" id="sheeet" defaultOpen={true}>
      {/* <SheetTrigger
        // asChild
      /> */}
      <SheetContent
        position="bottom"
        size="content"
        className="bg-white rounded-t-[16px]  h-[90vh] "
      >
        <SheetHeader className="sticky">
          <SheetTitle>Upload Invoices</SheetTitle>
        </SheetHeader>
        <div className={clsx(Object.keys(invoice || {})?.length > 0 ? '!h-[80vh] pt-[12px]' : '!h-[45vh] pt-[12px]')}>
          <div className="mb-[12px] px-[16px] mt-[10px]">
            {
                <FileBrowserNew
                  setFile={setInvoice}
                  files={invoice}
                  transactionId={bulkTransactionsIdResult?.data}
                  isMobile={isMobile}
                  validFormats={['document', 'image']}
                  hasError={false}
                  disabled={bulkTransactionsIdLoader}
                  docNum={Object.keys(invoice || {})?.length + 1 || 0}
              />
            }
          </div>
          {
            Object.keys(invoice || {})?.length === 0
            ?
              <div className='mb-[12px] px-[16px] mt-[10px]'>
                <div className={clsx(styles.documentupload, 'bg-[#c1c1c152] cursor-not-allowed')}>
                  <div
                    className={clsx(styles.dropMessage, `flex ${"flex-col"} justify-center items-center relative`)}
                  >
                    <div className={clsx(`relative w-[84px] opacity-70 ${isMobile ? "h-[44px]" : "h-[84px] bottom-[-6px]"}`)}>
                      <img src={ConnectIcon} alt="file" className={styles.fileIconImg} />
                    </div>
                    <p className="mt-[16px]">
                      { (
                          <>
                            <p className={styles.fontText}>Connect to your ERP/Accounting Software</p>
                            <p className={styles.fontSubText}>Tally, SAP, Microsoft Envision</p>
                            <p className={styles.fontSubText}>(To enable this option please connect with Fideo Support)</p>
                          </>
                        )
                      }
                    </p>
                  </div>
                </div>
              </div>
            :
              ""
          }
          <SheetFooter className="px-[18px] mb-[12px] mt-[70px] justify-center"
          >
            <SheetClose asChild onClick={() => {
                console.log("hello")
                dispatch(modalActions.bulkUploaderDisplay(false))
                console.log("hello")
              }}>
              <div 
              // className={classes.button}
              >
                <Button
                  // className={classes.btn}
                  text="Save"
                  className="outline-none w-[90%] absolute bottom-[12px] z-[10]"
                  // btnClassIcon={'w-[24px] h-[24px] ml-2'}
                  disabled={Object.keys(invoice || {})?.length === 0 ? true : false}
                  onClick={() => {
                    getBulkTransactionSubmit({ transactionId: bulkTransactionsIdResult?.data});
                  }}
                />
              </div>
            </SheetClose>
          </SheetFooter>
        </div>
      </SheetContent>
    </Sheet>
  )
}

export default React.memo(DocumentSheet)
