import * as React from 'react'
import * as TooltipPrimitive from '@radix-ui/react-tooltip'

import { cn } from 'utils/utils'

const getArrowLocationStyle = (side) => {
  switch (side) {
    case 'left':
      return 'border-l-[8px] border-l-[#000] right-[-15px] top-[50%] translate-y-[-50%]'
    case 'right':
      return 'border-r-[8px] border-r-[#000] left-[-15px] top-[50%] translate-y-[-50%]'
    case 'bottom':
      return 'border-b-[8px] border-b-[#000] top-[-15px] left-[50%] translate-x-[-50%]'
    case 'top':
      return 'border-t-[8px] border-t-[#000] bottom-[-15px] left-[50%] translate-x-[-50%]'
    default:
      return 'border-t-[8px] border-t-[#000] bottom-[-15px] left-[50%] translate-x-[-50%]'
  }
}

const TooltipProvider = TooltipPrimitive.Provider

const Tooltip = TooltipPrimitive.Root

const TooltipTrigger = TooltipPrimitive.Trigger

const TooltipContent = React.forwardRef(
  ({ className, sideOffset = 4, side = 'top', children, ...props }, ref) => (
    <TooltipPrimitive.Content
      ref={ref}
      sideOffset={sideOffset}
      className={cn(
        'z-50 relative overflow-hidden rounded-md border border-neutral-200 bg-white px-3 py-1.5 text-sm text-neutral-950 shadow-md animate-in fade-in-0 zoom-in-95 data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=closed]:zoom-out-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2 dark:border-neutral-800 dark:bg-neutral-950 dark:text-neutral-50',
        className
      )}
      side={side}
      {...props}
    >
      <div
        className={cn(
          'fixed w-[8px] h-[8px] bg-transparent border-[8px] border-[transparent]',
          getArrowLocationStyle(side)
        )}
      ></div>

      {children}
    </TooltipPrimitive.Content>
  )
)
TooltipContent.displayName = TooltipPrimitive.Content.displayName

export { Tooltip, TooltipTrigger, TooltipContent, TooltipProvider }
