import ReactDOM from 'react-dom'
import classes from 'Components/ReportTable/CustomerReportTable.module.scss'
import ReportTableLayout from 'Components/ReportTable/ReportTableLayout'
import eye from 'assets/Images/eye.svg'
import edit from 'assets/Images/edit.svg'
import sorting from 'assets/Images/sorting-icon.svg'
import collectPayment from 'assets/Images/collect_payment.svg'
import ContactInfo from 'Components/Global/ContactInfoNew'
import Action from 'Components/ReportTable/Action'
import clsx from 'clsx'
import MobileCustomerReportTable from './MobileCustomerReportTable'
import CustomerVerificationTag from 'Components/atom/CustomerVerificationTag/CustomerVerificationTag'
import { useDispatch, useSelector } from 'react-redux'
import UseApiCall from 'hooks/useApiCall'
import { apiGetCustomerDetails } from 'services'
import { CustomersActions } from 'Store/customer-redux'
import { useEffect, useRef, useState } from 'react'
import { modalActions } from 'Store/modal-redux'
import CustomerDetailsModal from 'Components/molecule/CustomerDetailsModal/CustomerDetailsModal'
import { Tooltip } from 'antd'
import InvoiceDetailsModal from 'Components/molecule/InvoiceDetailsModal/InvoiceDetailsModal'
import PaymentStatusTag from './PaymentStatusTag'
import ReportsPaymentStatusTag from './ReportsPaymentStatusTag'
import CreateInvoice from 'Components/Invoice/CreateInvoice'
import CustomerInvoiceTotal from './CustomerInvoiceTotal'
import LinkIcon from 'assets/Icons/send-link.svg'
import {
  showSinglePaymentLinkModal,
} from 'utils/layout'
import { modalRefState } from 'recoil/layoutRef'
import { useRecoilValue } from 'recoil'
import { formatNumberToLocaleString } from 'hooks/formatNumberToLocaleString';
import Shimmer from 'Layout/Shimmer'
import useElementOnScreen from 'hooks/useElementOnScreen'

const CustomerReportTable = (props) => {
  const dispatch = useDispatch()
  const modalRef = useRecoilValue(modalRefState)
  const [selectedCustomer, setSelectedCustomer] = useState(null)
  const showCustomerDisplay = useSelector(
    (state) => state.modal.showCustomerDisplay
  )

  const createInvoiceDisplay = useSelector(
    (state) => state.modal.createInvoiceDisplay
  );
  const updateCustomersModalValue = useSelector((state) => state.customer.updateCustomers);
  const [selectedCustomerId, setSelectedCustomerId] = useState('')
  const [customerId, setCustomerId] = useState('')
  const [customerDetail, setCustomerDetail] = useState(null);
  const scrollYValue = useSelector((state) => state.global.scrollYValue)
  const theadRef = useRef(null);
  const tbodyRef = useRef(null);
  const [openedInvoice, setOpenedInvoice] = useState(null);

  const [getSelectedCustomer] = UseApiCall(apiGetCustomerDetails, (res) => {
    // res = dummyCustomerData;
    dispatch(CustomersActions.setSelectedCustomer(res?.data))
    setSelectedCustomer(res?.data)
    !showCustomerDisplay && dispatch(modalActions.showCustomerDisplay())
  })
  
  const [getSelectedCustomerEdit] = UseApiCall(apiGetCustomerDetails, (res) => {
    dispatch(CustomersActions.setSelectedCustomer(res?.data))
  })

  const handleCustomerSelection = async (customerDetail) => {
    setSelectedCustomerId(customerDetail?.displayId)
    setCustomerId(customerDetail?.id)
    await getSelectedCustomer({ customerId: customerDetail?.id })
  }

  const handleCustomerEdit = async (customerDetail) => {
    await getSelectedCustomerEdit({ customerId: customerDetail?.id })
  }

  useEffect(() => {
    if (customerId) {
      getSelectedCustomer({ customerId: customerId })
    }
  }, [updateCustomersModalValue])

  const handleCustomerDetail = (cDetail) => {
    setCustomerDetail(cDetail);
  }

  const [containerTableRef, isTableVisible] = useElementOnScreen({
    root: null,
    rootMargin: "0px",
    threshold: 0.9
  })

  const optionArr = [
    {
      text: 'View',
      icon: eye,
      active : true,
      onClick: (cellData) => {
        handleCustomerSelection(cellData?.customer_detail)
      },
    },
    // { text: 'Edit', icon: edit, active: false },
    {
      text: 'Receive Payment', 
      icon: collectPayment, 
      active: true,
      onClick: (cellData) => {
        // dispatch(modalActions.receivePayment());
        if(cellData?.customer_detail.verificationStatus !== 'PENDING_VERIFICATION'  && cellData?.customer_detail.verificationStatus !== 'VERIFICATION_FAILED'){
          handleCustomerDetail(cellData?.customer_detail);
        dispatch(modalActions.createInvoice())
        }
      }, 
    },
    {
      text: 'Edit',
      icon: edit,
      onClick: (cellData) => {
        handleCustomerEdit(cellData?.customer_detail)
        dispatch(modalActions.setUpdateContact(true))
        dispatch(modalActions.addContact())
      },
  },
    { 
      text: 'Single Payment Link', 
      icon: LinkIcon, 
      active: true,
      onClick: (cellData) => {
        showSinglePaymentLinkModal(modalRef, {
          cellData: cellData,
        })
      },
    },
  ]


  const getTotalTooltipText = (data, type) => {
    // {X} invoices {Currency icon} {Amount}
    // {number_of_invoice} invoices {currency.sign} {amount}

    const output = data || [];

    const currencyOrder = ['USD', 'EUR', 'GBP']

    const sortedData = output.filter((item) =>
      currencyOrder.includes(item.currency)
    )

    const sortedInvoiceSummary = sortedData.sort((a, b) => {
      return (
        currencyOrder.indexOf(a.currency) - currencyOrder.indexOf(b.currency)
      )
    })

    let result

    const map = {
      RECEIVABLES: 'totalReceivables',
      SETTLED: 'settled',
      SETTLEDOUTSIDE: 'settledOutsideFideo',
      OUTSTANDING: 'totalOutstanding',
    }

    let noInvoiceCount = 0

    result = sortedInvoiceSummary?.map((item, index) => {
      item = item[map[type]]
      if (item?.number_of_invoice === 0) {
        noInvoiceCount++
        // return (
        //   <div key={index} className={clsx(classes.currencyTooltip)}>
        //     No invoices
        //   </div>
        // )
      } else {
        return (
          <div key={index} className={clsx(classes.currencyTooltip)}>
            <div className={clsx(classes.currencyAmount)}>
              {`${item?.number_of_invoice || 'No' } invoices`}
            </div>
            &nbsp; &nbsp;
            <div className={clsx(classes.currencySymbol)}>
              {item?.currency?.sign}
              {formatNumberToLocaleString((item?.amount), 2, 2)}
            </div>
          </div>
        )
      }
    })

    if (noInvoiceCount === data.length) {
      return <div className={clsx(classes.currencyTooltip)}>No invoices</div>
    }

    return <>{result}</>
  }

  const showInvoiceDisplay = useSelector(
    (state) => state.modal.showInvoiceDisplay
  )
  const selectedInvoice = useSelector(
    (state) => state.customer.selected_invoice
  )
  const activeTab = useSelector(
    (state) => state.customer.active_invoice_tab
  )


  useEffect(() => {
    const handleScroll = (event) => {
      if (event.target.id === 'thead') {
        tbodyRef.current.scrollLeft = theadRef.current.scrollLeft;
      }
      else if (event.target.id === 'tbody') {
        theadRef.current.scrollLeft = tbodyRef.current.scrollLeft;
      }
    };

    const thead = theadRef.current;
    const tbody = tbodyRef.current;

    thead.addEventListener('scroll', handleScroll);
    tbody.addEventListener('scroll', handleScroll);

    return () => {
      thead.removeEventListener('scroll', handleScroll);
      tbody.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="">
      
      {showCustomerDisplay &&
        selectedCustomer &&
        ReactDOM.createPortal(
          <CustomerDetailsModal
              customerId={customerId}
              selectedCustomerId={selectedCustomerId}
              customerData={selectedCustomer}
              whereCalled={'compliance'}
          />,
          document.getElementById('modal-root')
        )}

        {/*{showInvoiceDisplay &&*/}
        {/*selectedInvoice &&*/}
        {/*ReactDOM.createPortal(*/}
        {/*  <InvoiceDetailsModal*/}
        {/*    activeTab={activeTab}*/}
        {/*    invoice={selectedInvoice}*/}
        {/*    openedInvoice={openedInvoice}*/}
        {/*    mobileTableData*/}
        {/*  />,*/}
        {/*  document.getElementById('modal-root')*/}
        {/*)}*/}

        {createInvoiceDisplay && customerDetail &&
        ReactDOM.createPortal(
          <CreateInvoice
            contact={customerDetail}
            nextPageFunctionality={() => dispatch(modalActions.removeAll())}
          />,
          document.getElementById("modal-root")
        )}
      {
      // props.tableData && props.tableData?.length > 0 && 
      (
        <>
        <div className={classes.allWrap} >
            <div className={clsx(`${classes.table} ${classes.stickyHeaderTop} ${classes.reportTableWrapTop}`)} id="thead" ref={theadRef}>
                <thead className={clsx(`${classes.thead}`)}>
                  {props.tableHeader.map((header, index) => (
                    <th key={`${header}-${index}`} className={clsx(classes.cell_header1)} 
                      style={{width:`${header.width}%`,minWidth:`${header.minWidth}px`, maxWidth: `${header.maxWidth}px`}} valign="top">
                      <div className={classes.cell_header2}>
                        <div className={classes.head}>{header?.title}</div>
                        {/* <img
                          src={sorting}
                          alt="sorting-icon"
                          className={classes.sorting}
                        /> */}
                      </div>
                    </th>
                  ))}
                  <th className={classes.cell_header1} style={{ width: '8%' ,minWidth:'80.26px'}} >
                    <div
                      className={`${classes.cell_header2} ${classes.actionHeader}`}
                    >
                      <div className={`${classes.headAction}`}>Action</div>
                    </div>
                  </th>
                  {/* <th className={classes.cell_header1}></th> */}
                </thead>
            </div>
          <div className={`${classes.reportTableWrap}`} id="tbody"  ref={tbodyRef}>
          <ReportTableLayout className={classes.table}>
            <tbody>
                { props.loading
                  ?
                    <td colSpan={7}>
                      {[1,3,4]?.map((item, index) => (
                        <Shimmer key={index} className="w-[100%] h-[80px] mb-[12px]"/>
                      ))}
                    </td>
                  :
                    props?.tableData?.filter(data => data?.customer_id !== null)?.map((data, index) => (
                      <tr
                        key={index}
                        className={`${classes.row} ${
                          data.status && classes.row_deactivate
                        }`}
                      >
                        <td className={classes.cell}
                          style={{ width: '20%' ,minWidth:'162.26px',maxWidth:'162.26px'}}
                         onClick={() =>handleCustomerSelection(data?.customer_detail)}>
                          <div className="flex flex-col items-start">
                            <p
                              className={clsx(
                                classes.invoice,
                                'truncate max-w-[15rem] w-full'
                              )}
                              style={{
                                cursor: 'pointer',
                                userSelect: 'none',
                              }}
                            >
                              {data?.customer_detail?.displayId}
                            </p>
                            <div className="h-[20px] mt-1">
                              {data?.customer_detail?.verificationStatus && (
                                // <CustomerVerificationTag
                                //   status={data?.customer_detail?.verificationStatus}
                                //   // message={data?.customer_detail?.verificationComment || 'Hello'}
                                // />
                                <ReportsPaymentStatusTag status={data?.customer_detail?.verificationStatus} type="light" />
                              )}
                            </div>
                          </div>
                        </td>
                        <td className={classes.cell} 
                        style={{ width: '20%' ,minWidth:'290.26px',maxWidth:'290.26px'}}
                        onClick={() =>handleCustomerSelection(data?.customer_detail)}>
                          <ContactInfo
                            name={data?.customer_detail?.customerName}
                            email={data?.customer_detail?.emailAddress}
                            country={data?.customer_detail?.country}
                            countryName={data?.customer_detail?.countryName}
                            classNameCountry={classes.classNameCountry} 
                          />
                        </td>
                        {/* RECEIVABLES */}
                        <td className={classes.cell} 
                        style={{ width: '16%' ,minWidth:'133.26px',maxWidth:'133.26px'}}
                        onClick={() =>handleCustomerSelection(data?.customer_detail)}> 
                          <div className={classes.text}>
                            <Tooltip
                              placement="bottomLeft"
                              title={() =>
                                getTotalTooltipText(
                                  data?.invoice_summary,
                                  'RECEIVABLES'
                                )
                              }
                            >
                            <div className={clsx(classes.currencySymbolContainer)}>
                              {/* {data?.invoice_summary?.map((item,index)=>(
                                <div className={clsx(classes.currencySymbolInvoice)}>
                                  <span className={clsx(classes.currencySymbolSign)}>{item?.totalReceivables?.currency?.sign}</span>
                                  <span className={clsx(classes.currencySymbolAmt)}>{item?.totalReceivables?.amount ? item?.totalReceivables?.amount?.toFixed(2)?.toLocaleString('en-US') : '-'}</span>
                                </div>
                              ))} */}
                              {/* <CustomerInvoiceTotalCount data={data} category="totalReceivables" /> */}
                              <CustomerInvoiceTotal data={data} category="totalReceivables" />
                              </div>
                            </Tooltip>
                          </div>
                        </td>
                        {/* SETTLED */}
                        <td className={clsx(classes.cell)} 
                        style={{ width: '14%' ,minWidth:'100.26px',maxWidth:'100.26px'}}
                        onClick={() =>handleCustomerSelection(data?.customer_detail)}>
                          <div className={classes.text}>
                            <Tooltip
                              placement="bottomLeft"
                              title={() =>
                                getTotalTooltipText(data?.invoice_summary, 'SETTLED')
                              }
                            >
                            <div className={clsx(classes.currencySymbolContainer)}>
                              <CustomerInvoiceTotal data={data} category="settled" />
                              </div>
                            </Tooltip>
                          </div>
                        </td>
                        {/* SETTLEDOUTSIDE */}
                        <td className={classes.cell} style={{ width: '15%' ,minWidth:'117.26px',maxWidth:'117.26px'}} onClick={() =>handleCustomerSelection(data?.customer_detail)}>
                          <div className={classes.text}>
                            <Tooltip
                              placement="bottomLeft"
                              title={() =>
                                getTotalTooltipText(data?.invoice_summary, 'SETTLEDOUTSIDE')
                              }
                            >
                            <div className={clsx(classes.currencySymbolContainer)}>
                              <CustomerInvoiceTotal data={data} category="settledOutsideFideo" />
                              </div>
                            </Tooltip>
                          </div>
                        </td>
                        {/* OUTSTANDING */}
                        <td className={classes.cell} style={{ width: '14%' ,minWidth:'117.26px',maxWidth:'117.26px'}}  onClick={() =>handleCustomerSelection(data?.customer_detail)}>
                          <div className={classes.text}>
                            <Tooltip
                              placement="bottomLeft"
                              title={() =>
                                getTotalTooltipText(data?.invoice_summary, 'OUTSTANDING')
                              }
                            >
                            <div className={clsx(classes.currencySymbolContainer)}>
                              <CustomerInvoiceTotal data={data} category="totalOutstanding" />
                              </div>
                            </Tooltip>
                          </div>
                        </td>
                        <td className={classes.cell}  style={{ width: '8%' ,minWidth:'60.26px',maxWidth:'60.26px'}}>
                          <div className={classes.cell_action}>
                            <Action option={data?.customer_detail?.verificationStatus === "PENDING_VERIFICATION" ? [...optionArr?.slice(0,3)] : [...optionArr?.slice(0,2), ...optionArr?.slice(3,4)]} cellData={data}/>
                          </div>
                        </td>
                      </tr>
                    ))
                  }
            </tbody>
          </ReportTableLayout>
          </div>
        </div>
            {(props?.tableData.length === 0 && !props.loading) && 
              (<div
                style={{
                  textAlign: 'center',
                  width: '100%',
                  marginTop: '4rem',
                  fontSize: '2.5rem'
                }}
                className={classes.table}
              >
                No Recent Customer Yet.
              </div>)
            }
          <MobileCustomerReportTable
            tableData={props?.mobileTableData}
            option={optionArr}
            handleCustomerSelection={handleCustomerSelection}
            loading={props.loading}
          />
        </>
      )}
    </div>
  )
}

const sortCriteria = (a, b, field, asc) => {
  const reverse = asc ? 1 : -1

  if (typeof a[field] === 'undefined' && typeof b[field] === 'undefined')
    return 0
  if (typeof b[field] === 'undefined') return -1 * reverse
  if (typeof a[field] === 'undefined') return 1 * reverse
  if (a[field] > b[field]) {
    return 1 * reverse
  } else if (b[field] > a[field]) {
    return -1 * reverse
  }
  return 0
}

export default CustomerReportTable
