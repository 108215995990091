import Shimmer from 'Layout/Shimmer';
import classes from './MobileReconTable.module.scss'
import ReportTableLayout from 'Components/ReportTable/ReportTableLayout'
import linkIcon from 'assets/Icons/linkIcon.svg'
import clsx from 'clsx'
import dayjs from 'dayjs'
import { formatNumberToLocaleString } from 'hooks/formatNumberToLocaleString';
import ReactDOM from 'react-dom'
import { modalActions } from 'Store/modal-redux'
import MobileInvoiceViewRecon from 'Components/molecule/InvoiceViewReconModal/MobileInvoiceReconModal';
import { useDispatch,useSelector } from 'react-redux'
import { useState } from 'react'

const MobileReconTable = (props) => {
  const [viewInvoice,setViewInvoice] = useState(null);
  const dispatch = useDispatch()
  const invoiceViewRecon = useSelector((state) => state.modal.invoiceViewRecon);
  const checkedInvoiceId = (event, invoice_id, total_reconcile_amount, invoiceCurrency) => {
    if (props.selectedInvoices?.length < 1000) {
      !event.target.checked
        ? props.setSelectedInvoices(props.selectedInvoices.filter((invoice) => invoice.invoice_id !== invoice_id))
        : props.setSelectedInvoices((prev) => [...prev, {invoice_id, total_reconcile_amount, invoiceCurrency, amount: 0, error_key: false}]);
    } else {
      return false;
    }
  };

  if (props.tableData?.length > 0)
    return (
        <ReportTableLayout className={`${classes.table} ${props.className}`}>
            {invoiceViewRecon &&
        ReactDOM.createPortal(
          <MobileInvoiceViewRecon
            setViewInvoice = {setViewInvoice}
            viewInvoice = {viewInvoice}
          />,
          document.getElementById('modal-root')
        )
        }
            {props?.tableData?.map((data, index) => {
                const {
                    invoice_id,
                } = data
                return (
                    <div key={`${data?.email}-${index}`} className={classes.row}>
                        <div className={classes.infoOne}>
                            <div
                            className={clsx(classes.invoice, 'flex items-center gap-[8px]')}
                            >
                            <input
                                type="checkbox"
                                name="selectall"
                                disabled={props.disabledInput}
                                className={classes.termsCheckboxForInvoice}
                                id="selectall"
                                style={{ cursor: "pointer" }}
                                checked={props.selectedInvoices.filter((el) => el.invoice_id === data.invoice_id)?.length}
                                onChange={(e) => checkedInvoiceId(e, data?.invoice_id, data?.amount_pending, props?.currencyCodeSign)}
                            />
                            <div
                                // onClick={() => {
                                //   data?.paymentLink && window.open(data?.paymentLink, "_self");
                                // }}
                                className={clsx(classes.amountText, '!text-[#1E333F50]')}
                            >
                                Invoice ID
                            </div>
                            </div>
                            <div className={clsx(classes.amountText,'flex gap-[5px]')} style={{ maxWidth: "125px", overflowWrap: "anywhere" }}>
                                {invoice_id}
                                <img src={linkIcon} className={classes.linkIconBtn} alt="icon" 
                                     onClick={(e)=>{
                                     dispatch(modalActions.invoiceViewRecon(true))
                                     setViewInvoice(data)
                                     e.stopPropagation()
                                }}/>
                            </div>
                        </div>
                        <div
                            className={classes.infoTwo}
                            // onClick={() => {
                            //   data?.paymentLink && window.open(data?.paymentLink, "_self");
                            // }}
                        >
                            <div className={clsx(classes.amountText, '!text-[#1E333F50]')}>Issue date</div>
                            <div className={clsx(classes.amountText)} style={{ maxWidth: "125px", overflowWrap: "anywhere" }}>
                                {dayjs(data?.invoice_issue_date).local().format('DD/MM/YYYY')}
                            </div>
                        </div>
                        <div
                            className={classes.infoTwo}
                            // onClick={() => {
                            //   data?.paymentLink && window.open(data?.paymentLink, "_self");
                            // }}
                        >
                            <div className={clsx(classes.amountText, '!text-[#1E333F50]')}>Customer</div>
                            <div className={clsx(classes.amountText)} style={{ maxWidth: "125px", overflowWrap: "anywhere" }}>
                                {data?.reconcile_customer_details?.customer_name}
                            </div>
                        </div>
                        <div
                            className={classes.infoTwo}
                            // onClick={() => {
                            //   data?.paymentLink && window.open(data?.paymentLink, "_self");
                            // }}
                        >
                            <div className={clsx(classes.amountText, '!text-[#1E333F50]')}>Pending Amount</div>
                            <div className={clsx(classes.amountText, 'flex gap-[8px]')} style={{ maxWidth: "125px", overflowWrap: "anywhere" }}>
                                <div>
                                    {props?.currencyCodeSign}{formatNumberToLocaleString((data?.amount_pending), 2, 2)}
                                </div>
                            </div>
                        </div>
                        <div
                        className={classes.infoTwo}
                        >
                           <div className={`${classes.textInputBox} ${props.selectedInvoices.filter((el)=>el.invoice_id===data.invoice_id)?.length ?classes.activeInput:classes.inactiveInput}`} >
                            <p className={`${classes.textInputBoxLabel} ${
                              props.selectedInvoices.filter((el)=>el.invoice_id===data.invoice_id)?.length ?classes.labelActive:classes.labelInactive
                            }` }>Amount</p>
                             <span className={classes.textInputCurrencyTag}>{props.currencyCodeValue}</span>
                            <input 
                                type='number' 
                                placeholder='0.00'
                                disabled={props.disabledInput}
                                value={
                                    props.selectedInvoices.filter((el) => el.invoice_id === data.invoice_id)?.length === 0
                                        ?
                                            ''
                                        :
                                            props.selectedInvoices.filter((el) => el.invoice_id === data.invoice_id)?.amount
                                }
                                className={clsx(`${classes.textInputBoxInput} ${props.selectedInvoices.filter((el) => el.invoice_id === data.invoice_id && (el.amount>data?.amount_pending || el.amount < 0 || el?.error_key))?.length?"border-2 border-solid border-[#FF6868]":"border-2 border-solid border-[#181C30]"}`)}
                                onChange={(e)=>{
                                    const newValue = Number(e.target.value);
                                    props.setSelectedInvoices((prevObj) =>
                                        prevObj.map((item) =>
                                            item.invoice_id === data?.invoice_id ? { ...item, total_reconcile_amount: data?.amount_pending, amount: newValue, error_key: false } : item
                                        )
                                    );
                                }}
                                onWheel={(e) => {
                                    e.currentTarget.blur();
                                }}
                            />
                            {props.selectedInvoices.map((item)=>{
                                if(item?.invoice_id === data?.invoice_id && item?.amount > data?.amount_pending){
                                    return <p className={clsx(classes.errortag,`text-[1.2rem] mt-[0.2rem] text-[#FF6868] font-[400]`)}>Input amount should not exceed pending amount</p>
                                }
                                else if(item?.invoice_id === data?.invoice_id && item?.amount < 100){
                                    return <p className={clsx(classes.errortag,`text-[1.2rem] mt-[0.2rem] text-[#FF6868] font-[400]`)}>Input amount cannot be less than 100</p>
                                }
                                else if(item?.invoice_id === data?.invoice_id && item?.error_key){
                                    return <p className={clsx(classes.errortag,`text-[1.2rem] mt-[0.2rem] text-[#FF6868] font-[400]`)}>Reconcilation failed</p>
                                }
                                else return null
                             })}
                           </div>
                        </div>
                    </div>
                )
            })}
      </ReportTableLayout>
    )
  if (props.tableData?.length === 0)
    return (
        <div
            style={{
                textAlign: 'center',
                width: '100%',
                marginTop: '4rem',
                fontSize: '2.5rem'
            }}
            className={classes.table}
        >
            No Invoices To Reconcile.
        </div>
    )
  return (
    <div
      style={{
        textAlign: 'center',
        width: '100%',
        marginTop: '4rem',
      }}
    >
        <>
            <Shimmer className="w-[100%] mb-[10px] h-[100px] rounded-[12px]"/>
            <Shimmer className="w-[100%] mb-[10px] h-[100px] rounded-[12px]"/>
            <Shimmer className="w-[100%] h-[100px] rounded-[12px]"/>
        </>
    </div>
  )
}

export default MobileReconTable