import clsx from "clsx";
import classes from "./UserTable.module.scss"
import { useDispatch, useSelector } from "react-redux";
import infoIcon from 'assets/Icons/info_black_circle.svg'
import ReportTableLayout from "Components/ReportTable/ReportTableLayout";
import Shimmer from "Layout/Shimmer";
import dayjs from "dayjs";
import UserStatusTag from "./UserStatusTag";
import PaymentStatusTag from "Components/ReportTable/PaymentStatusTag";
import { useRef, useEffect } from "react";
import { modalActions } from "Store/modal-redux";
import ReactDOM from 'react-dom'
import Modal from "Layout/Modal";
import UserDetails from "./UserDetails";

const tableHeader = [
    {
      header:'member details',
      maxWidth:460,
      width:50
    },
    {
      header:'role',
      maxWidth:200,
      width:30
    },
    {
      header:'created on',
      maxWidth:200,
      width:30
    },
    {
      header:'status',
      maxWidth:200,
      width:30
    }
  ]

const accessName = {
  "ADMIN" : "Full Access",
  "USER" : "Limited Access",
}

const UserTable = (props) =>{
  // const dispatch = useDispatch();
  // const showUserDetails = useSelector((state) => state.modal.showUserDetails);
  const theadRef = useRef(null);
  const tbodyRef = useRef(null);
  useEffect(() => {
    const handleScroll = (event) => {
      if (event.target.id === 'thead') {
        tbodyRef.current.scrollLeft = theadRef.current.scrollLeft;
      }
      else if (event.target.id === 'tbody') {
        theadRef.current.scrollLeft = tbodyRef.current.scrollLeft;
      }
    };

    const thead = theadRef.current;
    const tbody = tbodyRef.current;

    thead.addEventListener('scroll', handleScroll);
    tbody.addEventListener('scroll', handleScroll);

    return () => {
      thead.removeEventListener('scroll', handleScroll);
      tbody.removeEventListener('scroll', handleScroll);
    };
  }, []);
return (
    <div className={clsx(`${classes.tableWrap}`)}>
      {
        // showUserDetails &&
        // ReactDOM.createPortal(
        //   <UserDetails
            
        //   />,
        // document.getElementById("modal-root")
        // )
      }
      <div className={classes.allWrap}>
        <div className={clsx(`${classes.table} ${classes.stickyHeaderTop} ${classes.reportTableWrapTop}`)} id="thead" ref={theadRef}>
          <thead>
                  {tableHeader.map((header, index) => (
                    <th key={`${header}-${index}`} 
                        className={classes.cell_header1}
                        style={{width:`${header.width}%`, minWidth:`${header.maxWidth}px`, maxWidth:`${header.maxWidth}px`}}
                        valign="top"
                    > 
                      <div className={classes.cell_header2}>
                        <div className={classes.head}  dangerouslySetInnerHTML={{ __html:  `${header.header}` }}></div>
                      </div>
                    </th>
                  ))}  
          </thead>
        </div>
        <div className={`${classes.reportTableWrap}`} id="tbody"  ref={tbodyRef}>
          <ReportTableLayout className={clsx(`${classes.table} pt-0`)}>
            <tbody>
                  { props?.fetchingUserDetails
                    ?
                    [1,3,4]?.map((item, index) => (
                      <Shimmer key={index} className="w-[222%] h-[80px] mt-[12px]"/>
                    ))
                    :
                  (props?.tableData?.length > 0
                    ?
                      props?.tableData?.map((data,index) => (
                        <tr key={index} className={classes.row} vAlign="top" 
                        onClick={()=>{
                          // dispatch(modalActions.showUserDetails(true))
                        }}>
                          <td className={clsx(`${classes.cell}`)}
                          style={{width: '50%', minWidth: '460px', maxWidth: '460px', overflowWrap: "anywhere"}}
                          valign="top"
                          >
                            <div className={classes.userDetails}>
                                <p className={classes.userName}>{data?.first_name} {data?.last_name}</p>
                                <p className={classes.userEmail}>{data?.email_id}</p>
                            </div>
                          </td>
                          <td
                            className={clsx(`${classes.cell}`)} 
                            style={{width: '30%', minWidth: '200px', maxWidth: '200px', overflowWrap: "anywhere"}}
                              valign="top"                     
                          >
                            <div className={clsx(`${classes.access}`)}>
                                <p className={classes.accessName}>{accessName[data?.role]}</p>
                                {/* {data?.role === "ADMIN" && <img src={infoIcon} alt="info" className="w-[16px] h-[16px]"/>} */}
                            </div>
                          </td>
                          <td
                            className={clsx(`${classes.cell}`)}  
                            style={{width: '30%', minWidth: '200px', maxWidth: '200px', overflowWrap: "anywhere"}}
                              valign="top"
                          >
                            {dayjs(data?.createdOn).format('DD/MM/YYYY')}
                          </td>
                          <td 
                          className={clsx(`${classes.cell}`)}
                          style={{width: '30%', minWidth: '200px', maxWidth: '200px', overflowWrap: "anywhere"}}
                              valign="top"
                          >
                            {
                              data?.user_status ? <PaymentStatusTag status = {data?.user_status} type="light"/> : "--"
                            }
                          </td>
                        
                        </tr>
                      ))
                    :
                    (
                      // props?.tableData?.length === 0 ?
                        <div
                          style={{
                              textAlign: 'center',
                              width: '100%',
                              marginTop: '4rem',
                              fontSize: '2.5rem',
                              color:'gray'
                          }}
                          className={classes.table}
                        >
                            No user to show.
                        </div>
                      // :
                      //   [1,3,4]?.map((item, index) => (
                      //     <Shimmer key={index} className="w-[222%] h-[80px] mt-[12px]"/>
                      //   ))
                    ))
                  }
            </tbody>
          </ReportTableLayout>
       </div>
      </div>
    </div>
)
}

export default UserTable
