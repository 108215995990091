import React from 'react';
import classes from "./profileComponents.module.scss";

const KycHeader = ({text1,text2,text3}) => {
  return (
    <div className={`${classes.kycHeader}`}>
        <div className={`${classes.kycSN}`}>
            <span className={`${classes.kycTextOne}`}>{text1}</span>
        </div>
        <span className={`${classes.kycTextTwo}`}>{text2}<span className={`${classes.kycTextThree}`}>{text3}</span></span>
    </div>
  )
}

export default KycHeader