import classes from './FxRateTable.module.scss'
import ReportTableLayout from 'Components/ReportTable/ReportTableLayout'
import clsx from 'clsx'
import dayjs from 'dayjs'
import INRFlag from 'assets/Images/INR_Flag.svg'
import USDFlag from 'assets/Images/USD_Flag.svg'
import EURFlag from 'assets/Images/EUR_Flag_new.svg'
import GBPFlag from 'assets/Images/GBP_Flag.svg'
import RightIcon from 'assets/Icons/arrow-right.svg'
import Pagination from 'Components/atom/Pagination/Pagination'
import { getDatefromUnix } from 'utils/utils'

const getCountryImage = (countryCode) => {
  switch (countryCode) {
    case 'USD':
      return USDFlag
    case 'INR':
      return INRFlag
    case 'EUR':
      return EURFlag
    case 'GBP':
      return GBPFlag
    default:
      return null
  }
}

const MobileCustomerReportTable = (props) => {
  const {
    tableData = [],
    totalElements,
    pageSize,
    currentPage,
    setCurrentPage,
  } = props
  return (
    <div className="flex flex-col gap-[32px] py-[24px]">
      {tableData?.map((data, index) => (
        <div className="flex flex-col gap-[12px]">
          <div className="flex flex-col items-start gap-[6px]">
            <p className="text-[16px]">
              {dayjs(getDatefromUnix(data?.timestamp)).format('DD MMM YYYY')}
            </p>
            <p
              className={clsx(classes.subText, 'text-[12px] text-[#1E333F66]')}
            >
              {dayjs(getDatefromUnix(data?.timestamp))
                .local()
                .format('hh:mm a')}{' '}
              IST
            </p>
          </div>
          <RateContainer fxRates={data?.currencyFxRates} />
        </div>
      ))}
      {tableData &&
        tableData?.length > 0 &&
        totalElements &&
        totalElements > pageSize && (
          <div className="w-full flex items-center justify-center mb-[16px]">
            <Pagination
              totalElements={totalElements}
              pageSize={pageSize}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </div>
        )}
    </div>
  )
}

const RateContainer = ({ fxRates }) => {
  return (
    <div className="bg-white flex flex-col items-center p-[20px] gap-[20px] rounded-[12px]">
      {fxRates?.map((rate) => (
        <ConversionValue conversionData={rate} />
      ))}
    </div>
  )
}

const ConversionValue = ({ conversionData }) => {
  const { midMarketRate, sellCurrency, buyCurrency } = conversionData
  return (
    <div className="w-full grid grid-cols-5 flex-row items-center justify-around gap-[12px] cursor-pointer text-[16px]">
      <div className="col-span-2 grid grid-cols-5 flex-row items-center justify-center gap-4 text-2xl">
        <div className="col-span-2 flex flex-row justify-end">
          <img src={getCountryImage(sellCurrency)} alt="sell-currency" />
        </div>
        <div className="col-span-3 flex flex-row justify-start">
          <p className="">1.00</p>
          <p className="ml-2 text-[#1E333F66]">{sellCurrency}</p>
        </div>
      </div>
      <div className="col-span-1 flex items-center justify-center">
        <img src={RightIcon} alt="=>" />
      </div>
      <div className="col-span-2 grid grid-cols-5 flex-row items-center justify-center gap-4 text-2xl">
        <div className="col-span-3 flex flex-row justify-end">
          <p>{midMarketRate}</p>
          <p className="ml-2 text-[#1E333F66]">{buyCurrency}</p>
        </div>
        <div className="col-span-2">
          <img src={getCountryImage(buyCurrency)} alt="buy-currency" />
        </div>
      </div>
    </div>
  )
}

export default MobileCustomerReportTable
