import clsx from 'clsx'
import LeftArrow from 'assets/Icons/chevron-left.svg'
import RightArrow from 'assets/Icons/chevron-right.svg'

const NUMBER_OF_PAGES_TO_SHOW = 3

const getPagesToShowArray = (totalPage, currentPage) => {
  const totalArray = Array.from(Array(totalPage).keys())
  if (currentPage < NUMBER_OF_PAGES_TO_SHOW / 2) {
    return totalArray.slice(0, NUMBER_OF_PAGES_TO_SHOW)
  } else if (currentPage > totalPage - NUMBER_OF_PAGES_TO_SHOW / 2) {
    return totalArray.slice(totalPage - NUMBER_OF_PAGES_TO_SHOW, totalPage)
  } else {
    return totalArray.slice(
      currentPage - NUMBER_OF_PAGES_TO_SHOW / 2,
      currentPage + NUMBER_OF_PAGES_TO_SHOW / 2
    )
  }
}

const Pagination = (props) => {
  const { totalElements, pageSize, currentPage, setCurrentPage } = props
  const totalPage = Math.ceil(totalElements / pageSize)
  return (
    <div className="flex flex-row items-center justify-end -md:justify-between -md:w-full  gap-3 text-[16px] leading-[24px]'">
      <div
        className={clsx(
          'flex flex-row min-w-fit items-center',
          currentPage === 1 ? 'opacity-40 cursor-not-allowed' : 'cursor-pointer'
        )}
        onClick={(e) => {
          e.preventDefault()
          if (currentPage > 1) setCurrentPage((pre) => pre - 1)
        }}
      >
        <img src={LeftArrow} alt="<" className="w-[24px] h-[24px]" />
        <p>Prev</p>
      </div>
      <div className="flex flex-row items-center gap-1">
        {getPagesToShowArray(totalPage, currentPage).map((pageNo) => (
          <div
            className={clsx(
              currentPage === pageNo + 1
                ? 'bg-[#E0E2E5] md:bg-[#F4F6FA] '
                : 'bg-[transparent] cursor-pointer',
              'flex items-center justify-center min-w-[40px] min-h-[40px] md:min-w-[24px] md:min-h-[24px] px-[8px] py-[8px] md:py-[2px]  leading-tight hover:bg-[#E0E2E5] md:hover:bg-[#F4F6FA] rounded-[4px]'
            )}
            onClick={(e) => {
              e.preventDefault()
              setCurrentPage(pageNo + 1)
            }}
          >
            <p
              className={clsx(
                currentPage === pageNo + 1
                  ? 'text-[#181C30]'
                  : 'text-[#181C30CC]'
              )}
            >
              {pageNo + 1}
            </p>
          </div>
        ))}
      </div>
      <div
        className={clsx(
          'flex flex-row min-w-fit items-center',
          currentPage === totalPage
            ? 'opacity-40 cursor-not-allowed'
            : 'cursor-pointer'
        )}
        onClick={(e) => {
          e.preventDefault()
          if (currentPage < totalPage) setCurrentPage((pre) => pre + 1)
        }}
      >
        <p className="">Next </p>
        <img src={RightArrow} alt=">" className="w-[24px] h-[24px]" />
      </div>
    </div>
  )
}

export default Pagination
