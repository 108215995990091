import classes from 'Components/Global/TransactionDetails.module.scss'
import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react'
// import Dropdown from 'Components/Dropdown/Dropdown'
// import downIcon from 'assets/Images/chevron-down.svg'
// import summarySaved from 'assets/Images/summary-saved.svg'
// import useClickOutside from 'hooks/useClickOutside'
// import Close from 'Layout/Close'
import Status from 'Components/ReportTable/Status'
// import BgOrnamentCircle from 'assets/Icons/bg-ornament-circle.svg'
// import BgOrnamentDots from 'assets/Icons/bg-ornament-dots.svg'
// import { useNavigate } from 'react-router-dom'
import clsx from 'clsx'
import { formatNumberToLocaleString } from 'hooks/formatNumberToLocaleString';
import WalletBrown from 'assets/Icons/brownWallet.svg'
import Shimmer from 'Layout/Shimmer';
import {Dropdown} from "antd";
import down from "assets/Icons/chevron-down.svg";

// const dropDown = [
//   {
//     id: 1,
//     heading: 'Year to date',
//     def: 'YTD',
//   },
//   {
//     id: 2,
//     heading: 'Month to date',
//     def: 'MTD',
//   },
//   {
//     id: 3,
//     heading: 'Last 7 days',
//     def: 'Last 7 days',
//   },
//   {
//     id: 4,
//     heading: 'Today',
//     def: 'Today',
//   },
//   {
//     id: 5,
//     heading: 'Select Custom Range',
//     def: 'SCR',
//   },
// ]

const TransactionDetails = (props) => {

  // const [activeState, setActiveState] = useState(1)
  const [activeTab, setActiveTab] = useState("USD")
  const [windowSize, setWindowSize] = useState(800)
  // const [selectedDropdown, setSelectedDropdown] = useState(dropDown[0])
  // const [showDropdown, setShowDropdown] = useState(false)
  // const dropDownRef = useClickOutside(() => setShowDropdown(false))
  // const navigate = useNavigate()
  // const [totalReceivablesValues, setTotalReceivablesValues] = useState([])
  // const [totalOutstanding, setTotalOutstanding] = useState([])
  const [totalInvoicesCreated, setTotalInvoicesCreated] = useState({ count: 0, price: 0 })
  // const [settledAmounts, setSettledAmounts] = useState([])
  const [totalSettledInvoices, setTotalSettledInvoices] = useState({ count: 0, price: 0 })
  const [totalOutstandingInvoices, setTotalOutstandingInvoices] = useState({ count: 0, price: 0 })
  const [totalOverdueInvoices, setTotalOverdueInvoices] = useState({ count: 0, price: 0 })
  const [totalPaidOutsideInvoices, setTotalPaidOutsideInvoices] = useState({ count: 0, price: 0 })
  const [totalInProgressInvoices, setTotalInProgressInvoices] = useState({ count: 0, price: 0 })
  const [currencyCodeSign, setCurrencyCodeSign] = useState('$')
  const [savingAmount, setSavingAmount] = useState({ sign: '₹', price: 0.00 });

  const [more, setMore] = useState('More');
  const [showActiveTabText, setShowActiveTabText] = useState(false);

  // const navigateToReceievable = () => {
  //   navigate('/receivables')
  // }

  // const onClickDropdown = (value) => {
  //   setShowDropdown(false)
  //   setSelectedDropdown(value)
  // }
  const data = props?.transactionData?.dashboardSummaryDTOList;

  // const fetchTotalInvoicesCount = (data, checkStatus, checkCurrencyCode) => {
  //   let totalCounts = 0
  //   let totalPrice = 0
  //
  //   data?.forEach((item) => {
  //     const status = item.status
  //     // const totalCount = item.total_count
  //
  //     if (status === checkStatus) {
  //       if (item?.curriencies !== null) {
  //         const newArray = item?.curriencies?.filter(item => item?.currency?.code === checkCurrencyCode);
  //         if (newArray && newArray?.length !== 0) {
  //           totalCounts = newArray[0]?.count;
  //           totalPrice = newArray[0]?.amount;
  //           if (status === 'SETTLED') {
  //             setSavingAmount({
  //               sign: newArray[0]?.saving?.currency?.sign || '₹',
  //               price: newArray[0]?.saving?.currency?.sign === '₹' ? newArray[0]?.saving?.amount?.toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : newArray[0]?.saving?.amount?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
  //             })
  //           }
  //         }
  //       }
  //     }
  //   })
  //
  //   return {count: totalCounts, price: formatNumberToLocaleString((totalPrice), 2, 2)};
  // }

  // const extractTotalReceivables = (data) => {
  //   const currencyTotals = {}

  //   data?.forEach((item) => {
  //     const status = item.status
  //     const currencies = item.curriencies

  //     if (status === 'CREATED') {
  //       if (currencies) {
  //         currencies.forEach((currencyData) => {
  //           const currencyCode = currencyData.currency.code
  //           const currencyAmount = currencyData.amount
  //           const currencySign = currencyData.currency.sign

  //           if (!currencyTotals[currencyCode]) {
  //             currencyTotals[currencyCode] = {
  //               amount: 0,
  //               sign: currencySign,
  //             }
  //           }

  //           currencyTotals[currencyCode].amount += currencyAmount
  //         })
  //       }
  //     }
  //   })

  //   const allCurrencyCodes = Object.keys(currencyTotals)
  //   const output = allCurrencyCodes.map((currency) => {
  //     const data = currencyTotals[currency]
  //     return {
  //       amount: data.amount,
  //       currency: currency,
  //       sign: data.sign,
  //     }
  //   })

  //   const currencyOrder = ['USD', 'EUR', 'GBP']

  //   const sortedData = output.filter((item) =>
  //     currencyOrder.includes(item.currency)
  //   )

  //   sortedData.sort((a, b) => {
  //     return (
  //       currencyOrder.indexOf(a.currency) - currencyOrder.indexOf(b.currency)
  //     )
  //   })
  //   return sortedData
  // }

  // const extractTotalOutstanding = (data) => {
  //   const currencyTotals = {}

  //   data?.forEach((item) => {
  //     const status = item.status
  //     const currencies = item.curriencies

  //     if (status === 'OUTSTANDING') {
  //       if (currencies) {
  //         currencies.forEach((currencyData) => {
  //           const currencyCode = currencyData.currency.code
  //           const currencyAmount = currencyData.amount
  //           const currencySign = currencyData.currency.sign

  //           if (!currencyTotals[currencyCode]) {
  //             currencyTotals[currencyCode] = {
  //               amount: 0,
  //               sign: currencySign,
  //             }
  //           }

  //           currencyTotals[currencyCode].amount += currencyAmount
  //         })
  //       }
  //     }
  //   })

  //   const allCurrencyCodes = Object.keys(currencyTotals)
  //   const output = allCurrencyCodes.map((currency) => {
  //     const data = currencyTotals[currency]
  //     return {
  //       amount: data.amount,
  //       currency: currency,
  //       sign: data.sign,
  //     }
  //   })

  //   const currencyOrder = ['USD', 'EUR', 'GBP']

  //   const sortedData = output.filter((item) =>
  //     currencyOrder.includes(item.currency)
  //   )

  //   sortedData.sort((a, b) => {
  //     return (
  //       currencyOrder.indexOf(a.currency) - currencyOrder.indexOf(b.currency)
  //     )
  //   })
  //   return sortedData
  // }

  // const fetchTotalSettledInvoicesCount = (data, checkCurrencyCode) => {
  //   let totalCounts = 0

  //   data?.forEach((item) => {
  //     const status = item.status
  //     // const totalCount = item.total_count

  //     if (status === 'SETTLED') {
  //       if (item?.curriencies !== null) {
  //         const newArray = item?.curriencies?.filter(item => item?.currency?.code === checkCurrencyCode);
  //         if (newArray && newArray?.length !== 0) {
  //           totalCounts = newArray[0]?.count;
  //         }
  //       }
  //     }
  //   })

  //   return totalCounts
  // }

  // const fetchTotalOutstandingInvoicesCount = (data, checkCurrencyCode) => {
  //   let totalCounts = 0

  //   data?.forEach((item) => {
  //     const status = item.status
  //     // const totalCount = item.total_count

  //     if (status === 'OUTSTANDING') {
  //       if (item?.curriencies !== null) {
  //         const newArray = item?.curriencies?.filter(item => item?.currency?.code === checkCurrencyCode);
  //         if (newArray && newArray?.length !== 0) {
  //           totalCounts = newArray[0]?.count;
  //         }
  //       }
  //     }
  //   })

  //   return totalCounts
  // }

  // const fetchTotalOverdueInvoicesCount = (data, checkCurrencyCode) => {
  //   let totalCounts = 0

  //   data?.forEach((item) => {
  //     const status = item.status
  //     // const totalCount = item.total_count

  //     if (status === 'OVERDUE') {
  //       if (item?.curriencies !== null) {
  //         const newArray = item?.curriencies?.filter(item => item?.currency?.code === checkCurrencyCode);
  //         if (newArray && newArray?.length !== 0) {
  //           totalCounts = newArray[0]?.count;
  //         }
  //       }
  //     }
  //   })

  //   return totalCounts
  // }

  // const extractSettledAmounts = (data) => {
  //   const settledAmounts = {}

  //   data?.forEach((item) => {
  //     const currencies = item.curriencies

  //     if (currencies) {
  //       currencies.forEach((currencyData) => {
  //         const currencyCode = currencyData.currency.code
  //         const currencyAmount = currencyData.amount
  //         const currencySign = currencyData.currency.sign

  //         if (!settledAmounts[currencyCode]) {
  //           settledAmounts[currencyCode] = {
  //             amount: 0,
  //             sign: currencySign,
  //           }
  //         }

  //         settledAmounts[currencyCode].amount += currencyAmount
  //       })
  //     }
  //   })

  //   const allCurrencyCodes = Object.keys(settledAmounts)
  //   const output = allCurrencyCodes.map((currency) => {
  //     const data = settledAmounts[currency]
  //     return {
  //       amount: data.amount,
  //       currency: currency,
  //       sign: data.sign,
  //     }
  //   })

  //   const currencyOrder = ['USD', 'EUR', 'GBP']

  //   const sortedData = output.filter((item) =>
  //     currencyOrder.includes(item.currency)
  //   )

  //   sortedData.sort((a, b) => {
  //     return (
  //       currencyOrder.indexOf(a.currency) - currencyOrder.indexOf(b.currency)
  //     )
  //   })
  //   return sortedData
  // }

  // const settledData = data.filter((item) => item.status === 'SETTLED')
  // const settledAmountsData = useMemo(
  //   () => extractSettledAmounts(settledData),
  //   [settledData]
  // )

  // useEffect(() => {
  //   if (totalReceivablesValues.length === 0) {
  //     setTotalReceivablesValues(extractTotalReceivables(data))
  //   }
  // }, [data])

  // useEffect(() => {
  //   if (totalOutstanding.length === 0) {
  //     setTotalOutstanding(extractTotalOutstanding(data))
  //   }
  // }, [data])

  useEffect(() => {
    handleCount(activeTab)
  }, [data])

  const itemsCurr = props?.transactionData?.supportedCurrencies?.map((item) => {
    return {
      key: item?.code?.toUpperCase(),
      sign: item?.sign,
      label: <div className='text-[16px] font-inter'>{item?.code?.toUpperCase()}</div>,
    }
  })

  // const items = [
  //   {
  //     key: 'AUD',
  //     label: <div className='text-[16px] font-inter'>AUD</div>,
  //   },
  //   {
  //     key: 'AED',
  //     label: <div className='text-[16px] font-inter '>AED</div>,
  //   },
  //   {
  //     key: 'CHF',
  //     label: <div className='text-[16px] font-inter '>CHF</div>,
  //   },
  //   {
  //     key: 'CZK',
  //     label: <div className='text-[16px] font-inter '>CZK</div>,
  //   },
  //   {
  //     key: 'DKK',
  //     label: <div className='text-[16px] font-inter '>DKK</div>,
  //   },
  //   {
  //     key: 'HKD',
  //     label: <div className='text-[16px] font-inter '>HKD</div>,
  //   },
  //   {
  //     key: 'NOK',
  //     label: <div className='text-[16px] font-inter '>NOK</div>,
  //   },
  //   {
  //     key: 'PLN',
  //     label: <div className='text-[16px] font-inter '>PLN</div>,
  //   },
  //   {
  //     key: 'SEK',
  //     label: <div className='text-[16px] font-inter '>SEK</div>,
  //   },{
  //     key: 'SGD',
  //     label: <div className='text-[16px] font-inter '>SGD</div>,
  //   },
  // ];

  // console.log('itemsCurr',itemsCurr.slice(0,4))
  // console.log('items',items)
  // console.log('props?.transactionData?.supportedCurrencies',props?.transactionData?.supportedCurrencies)

  const handleDropdownItemClick = (e) => {
    console.log(e.key);
    setMore(e.key);
    handleCount(e.key);
  };

  const fetchTotalInvoicesCount = (data, checkStatus, checkCurrencyCode) => {
    let totalCounts = 0
    let totalPrice = 0

    data?.forEach((item) => {
      const status = item.tag

      if (status === checkCurrencyCode) {
        if(checkStatus === 'CREATED'){
          totalCounts = item?.totalInvoices;
          totalPrice = item?.totalAmount
        }else if(checkStatus === 'SETTLED'){
          totalCounts = item?.totalSettledInvoices;
          totalPrice = item?.totalSettledAmount
        }else if(checkStatus === 'OUTSTANDING'){
          totalCounts = item?.outstandingInvoices;
          totalPrice = item?.outstandingAmount
        }else if(checkStatus === 'SETTLED_OUTSIDE_FIDEO'){
          totalCounts = item?.settledOutsideInvoices;
          totalPrice = item?.settledOutsideAmount
        }else if(checkStatus === 'IN_PROGRESS'){
          totalCounts = item?.inProgressInvoices;
          totalPrice = item?.inProgressAmount
        }
      }
    })

    return {count: totalCounts, price: formatNumberToLocaleString((totalPrice), 2, 2)};
  }

  const handleCount = (status) => {
    setActiveTab(status)
    setSavingAmount({
      sign: '₹',
      price: formatNumberToLocaleString((0), 2, 2)
    })
    if (status === 'USD') {
      setCurrencyCodeSign('$')
    } else if (status === 'CAD') {
      setCurrencyCodeSign('CA$')
    } else if (status === 'GBP') {
      setCurrencyCodeSign('£')
    } else if (status === 'EUR') {
      setCurrencyCodeSign('€')
    }else if (status === 'AUD') {
      setCurrencyCodeSign('AU$')
    }else if (status === 'AED') {
      setCurrencyCodeSign('د.إ')
    }else if (status === 'CHF') {
      setCurrencyCodeSign('CHF')
      // setCurrencyCodeSign('Fr.')
    }else if (status === 'CZK') {
      setCurrencyCodeSign('Kč')
    }else if (status === 'DKK') {
      setCurrencyCodeSign('Kr')
    }else if (status === 'HKD') {
      setCurrencyCodeSign('HK$')
    }else if (status === 'NOK') {
      setCurrencyCodeSign('kr')
    }else if (status === 'PLN') {
      setCurrencyCodeSign('zł')
    }else if (status === 'SEK') {
      setCurrencyCodeSign('kr')
    }else if (status === 'SGD') {
      setCurrencyCodeSign('S$')
    }
    setTotalInvoicesCreated(fetchTotalInvoicesCount(data, 'CREATED', status))
    setTotalSettledInvoices(fetchTotalInvoicesCount(data, 'SETTLED', status))
    setTotalOutstandingInvoices(fetchTotalInvoicesCount(data, 'OUTSTANDING', status))
    setTotalOverdueInvoices(fetchTotalInvoicesCount(data, 'OVERDUE', status))
    setTotalPaidOutsideInvoices(fetchTotalInvoicesCount(data, 'SETTLED_OUTSIDE_FIDEO', status))
    setTotalInProgressInvoices(fetchTotalInvoicesCount(data, 'IN_PROGRESS', status))
  }

  useEffect(() => {
    const showText = itemsCurr?.some(item => item.key === activeTab && more);
    setShowActiveTabText(showText);
  }, [activeTab, more]);

  // useEffect(() => {
  //   if (settledAmounts.length === 0 && settledAmountsData.length > 0) {
  //     setSettledAmounts(settledAmountsData)
  //   }
  // }, [settledAmounts, settledAmountsData])

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className={`${classes.transaction_details} ${props.className}`}>
      <div className={classes.box1}>
        <div>Receivable Summary</div>
        {/*<div className={classes.date_filter}>
                    <div className={classes.date_title}>Date Range</div>
                    <div ref={dropDownRef} className={classes.selectRange}>
                        <div className={classes.default}
                             onClick={() => setShowDropdown(prev => !prev)}>{selectedDropdown.def}</div>
                        <img src={downIcon} alt='drop-down'
                             className={`${classes.down_icon} ${showDropdown && classes.down_icon_active}`}
                             onClick={() => setShowDropdown(prev => !prev)}/>
                        {showDropdown &&
                            <div className={classes.dateFilter}>
                                <div className={classes.dateFilter_header}>
                                    <Close onClick={() => setShowDropdown(false)} className={classes.close}/>
                                    <div className={classes.dateFilter_header_heading}>Filters</div>
                                </div>
                                <div className={classes.dateFilter_options}>
                                    <div className={classes.dateFilter_options_subheader}>Date Range</div>
                                    <Dropdown className={classes.dropdown} list={dropDown}
                                              selectedOption={selectedDropdown} onClick={onClickDropdown}/>
                                </div>
                            </div>
                        }
                    </div>

                </div>*/}
      </div>
      {
        windowSize <= 640
        ?
          <div
            className={clsx(
              classes.box2,
              'bg-white border border-solid border-[#D2D5DB99] rounded-2xl'
            )}
          >
            <div>
              <div className={clsx(classes.tabHeader)}>
                {props.loading
                    ?
                    <div className="flex gap-[10px]">
                      {[1, 2, 3, 4]?.map((item, index) => (
                          <Shimmer key={index} className="w-[20px] h-[10px] rounded-lg"/>
                      ))}
                    </div>
                    :
                    itemsCurr && itemsCurr?.slice(0, 4)?.map((item) => (
                        <div className={clsx(activeTab === item?.key ? classes.activeTabText : classes.inActiveTabText)}
                             onClick={() => handleCount(item?.key)}>{item?.key}</div>
                    ))}
                {/*<div className={clsx(activeTab === "USD" ? classes.activeTabText : classes.inActiveTabText)} onClick={() => handleCount("USD")}>USD</div>*/}
                {/*<div className={clsx(activeTab === "EUR" ? classes.activeTabText : classes.inActiveTabText)} onClick={() => handleCount("EUR")}>EUR</div>*/}
                {/*<div className={clsx(activeTab === "GBP" ? classes.activeTabText : classes.inActiveTabText)} onClick={() => handleCount("GBP")}>GBP</div>*/}
                {/*<div className={clsx(activeTab === "CAD" ? classes.activeTabText : classes.inActiveTabText)} onClick={() => handleCount("CAD")}>CAD</div>*/}
                <Dropdown
                    menu={{
                      onClick: handleDropdownItemClick,
                      items: itemsCurr && itemsCurr?.slice(4),
                    }}
                    placement="bottom"
                    overlayStyle={{width:"100px", fontSize:"16px !important" }}
                    // open={true}
                >
                  <div className={clsx(activeTab === more ? classes.activeTabText : classes.inActiveTabText, 'flex items-center gap-[0px]')}>
                    <>{more}</>
                    <img src={down} className="w-[20px] h-[20px]" />
                  </div>
                </Dropdown>
              </div>

              <div className={classes.mainCard}>

                <div className={classes.receivablesCard}>
                  <div className={clsx(classes.inner)}>
                    <div className='flex justify-between items-center self-stretch'>
                      <div className=''>
                        <div className={classes.heading}>Total Receivables Value</div>
                        <div className={clsx(classes.text, 'flex')}>{<span className='font-[400]'>{currencyCodeSign}</span>}{props.loading ? <Shimmer className="w-[150px] h-[36px] rounded-lg"/> : totalInvoicesCreated?.price}</div>
                      </div>
                      <div className={classes.circle}>
                        <div className={classes.dollar}>{currencyCodeSign}</div>
                      </div>
                    </div>
                    {
                      props.loading
                        ?
                          <Shimmer className="w-[100%] h-[18px] rounded-lg"/>
                        :
                          <div className={clsx(classes.ytd)}>
                            {totalInvoicesCreated?.count}{' '}
                            {totalInvoicesCreated?.count > 1 ? 'Invoices' : 'Invoice'} created YTD
                          </div>
                    }
                  </div>
                </div>

                <div className={classes.outstandingCard}>
                  <div className='w-[100%]'>
                    <div className={classes.heading}>Outstanding Payment</div>
                    <div className={clsx(classes.price, 'flex')}>{<span className='font-[400]'>{currencyCodeSign}</span>}{props.loading ? <Shimmer className="w-[100%] mb-[10px] h-[30px] rounded-lg"/> : totalOutstandingInvoices?.price}</div>
                  </div>
                  {
                    props.loading
                      ?
                        <Shimmer className="w-[100%] h-[20px] rounded-lg"/>
                      :
                        <div className='flex justify-between items-center self-stretch'>
                          <div className={classes.count}>{totalOutstandingInvoices?.count} {totalOutstandingInvoices?.count > 1 ? 'Invoices' : 'Invoice'}</div>
                          <div className={clsx('')}>
                            <Status
                              status={`OVERDUE_summary`}
                              statusText={`${totalOverdueInvoices?.count} OVERDUE`}
                            />
                          </div>
                        </div>
                  }
                </div>

                <div className={classes.settledCard}>
                  <div className='w-[100%]'>
                    <div className={classes.heading}>Settled via Fideo</div>
                    <div className={clsx(classes.price, 'flex')}>{<span className='font-[400]'>{currencyCodeSign}</span>}{props.loading ? <Shimmer className="w-[100%] mb-[10px] h-[30px] rounded-lg"/> : totalSettledInvoices?.price}</div>
                  </div>
                  {
                    props.loading
                      ?
                        <Shimmer className="w-[100%] h-[20px] rounded-lg"/>
                      :
                        <div className='flex justify-between items-center self-stretch'>
                          <div className={classes.count}>{totalSettledInvoices?.count} {totalSettledInvoices?.count > 1 ? 'Invoices' : 'Invoice'}</div>
                          <div className={clsx('')}>
                            <div className={clsx('bg-[#FFE55E]',`rounded-full w-fit flex flex-row items-center justify-between gap-2 px-[0.8rem] py-[0.2rem]`)}>
                              <img src={WalletBrown} alt="wallet" className="w-6 h-6" />{' '}
                              <p className="text-[1.2rem] font-[600] text-center leading-[1.6rem]">
                                <span className="inline-block text-[#8C6825]">
                                  {savingAmount?.sign}{savingAmount?.price} SAVED
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                  }
                </div>

                <div className={classes.paidOutsideCard}>
                  <div className='w-[100%]'>
                    <div className={classes.heading}>Settled outside Fideo</div>
                    <div className={clsx(classes.price, 'flex')}>{<span className='font-[400]'>{currencyCodeSign}</span>}{props.loading ? <Shimmer className="w-[100%] mb-[10px] h-[30px] rounded-lg"/> : totalPaidOutsideInvoices?.price}</div>
                  </div>
                  <div className='w-[100%]'>
                    {
                      props.loading
                        ?
                          <Shimmer className="w-[100%] h-[20px] rounded-lg"/>
                        :
                          <div className={classes.count}>{totalPaidOutsideInvoices?.count} {totalPaidOutsideInvoices?.count > 1 ? 'Invoices' : 'Invoice'}</div>
                    }
                  </div>
                </div>

                <div className={classes.progressCard}>
                  <div className='w-[100%]'>
                    <div className={classes.heading}>In-Progress</div>
                    <div className={clsx(classes.price, 'flex')}>{<span className='font-[400]'>{currencyCodeSign}</span>}{props.loading ? <Shimmer className="w-[100%] mb-[10px] h-[30px] rounded-lg"/> : totalInProgressInvoices?.price}</div>
                  </div>
                  <div className='w-[100%]'>
                    {
                      props.loading
                        ?
                          <Shimmer className="w-[100%] h-[20px] rounded-lg"/>
                        :
                          <div className={classes.count}>{totalInProgressInvoices?.count} {totalInProgressInvoices?.count > 1 ? 'Invoices' : 'Invoice'}</div>
                    }
                  </div>
                </div>
              </div>

            </div>

            {/* <div className="flex md:flex-row flex-col col-span-2 h-[100%]">
              <div
                className={`${classes[`transaction_${data[1].status}`]} ${
                  activeState === data[1].id
                    ? classes.transaction_active
                    : undefined
                } relative overflow-hidden mr-[0px]`}
                onClick={() => {
                  navigateToReceievable()
                }}
              >
                <img
                  src={BgOrnamentCircle}
                  alt=""
                  className="absolute w-[35%] z-0 bottom-0 right-0"
                />
                <img
                  src={BgOrnamentCircle}
                  alt=""
                  className="absolute w-[35%] top-0 rotate-180 -right-[17%]"
                />
                <div className={clsx(classes.money_status)}>
                  <div className={classes.status}>
                    <div
                      style={{
                        fontFamily: 'Inter',
                        fontStyle: 'normal',
                        fontWeight: 600,
                        fontSize: '14px',
                        lineHeight: '20px',
                        display: 'flex',
                        alignItems: 'center',
                        color: 'rgba(30, 51, 63, 0.5)',
                      }}
                    >
                      Settled in your bank account
                    </div>
                  </div>
                  <div className={clsx(classes.money)}>
                    <div className="flex flex-col">
                      {settledAmounts?.length > 0 ? (
                        settledAmounts.map((item, index) => {
                          return (
                            <div
                              key={index}
                              className={clsx(classes.summaryCurrencyFont)}
                            >
                              {item.sign.toLocaleString('en-US')}{' '}
                              {formatNumberToLocaleString((item?.amount), 2, 2)}
                            </div>
                          )
                        })
                      ) : (
                        <div className={clsx(classes.summaryCurrencyFont)}>0</div>
                      )}
                    </div>
                  </div>
                </div>
                <div className={clsx(classes.invoiceTitle, 'flex flex-col gap-1')}>
                  {totalSettledInvoices}{' '}
                  {totalSettledInvoices > 1 ? 'Invoices' : 'Invoice'}
                  <div className="h-[20px]">
                  </div>
                </div>
              </div>
              <div
                className={`${classes[`transaction_${data[3].status}`]} ${
                  activeState === data[1].id
                    ? classes.transaction_active
                    : undefined
                } relative overflow-hidden`}
                onClick={() => {
                  navigateToReceievable()
                }}
              >
                <img
                  src={BgOrnamentDots}
                  alt=""
                  className="absolute w-[24%] z-0 bottom-0 right-0"
                />
                <img
                  src={BgOrnamentDots}
                  alt=""
                  className="absolute w-[24%] min-w-[50px] rotate-[35deg] -top-[12%] right-2 "
                />
                <div className={classes.money_status}>
                  <div className={classes.status}>
                    <div
                      style={{
                        fontFamily: 'Inter',
                        fontStyle: 'normal',
                        fontWeight: 600,
                        fontSize: '14px',
                        lineHeight: '20px',
                        display: 'flex',
                        alignItems: 'center',
                        color: 'rgba(30, 51, 63, 0.5)',
                      }}
                    >
                      Pending payment
                    </div>
                  </div>
                  <div className={classes.money}>
                    <div className="flex flex-col items-start">
                      {totalOutstanding?.length > 0 ? (
                        totalOutstanding.map((item, index) => {
                          return (
                            <div
                              key={index}
                              className={clsx(classes.summaryCurrencyFont)}
                            >
                              {item.sign.toLocaleString('en-US')}{' '}
                              {formatNumberToLocaleString((item?.amount), 2, 2)}
                            </div>
                          )
                        })
                      ) : (
                        <div className={clsx(classes.summaryCurrencyFont)}>0</div>
                      )}
                    </div>
                  </div>
                </div>
                <div className={clsx(classes.invoiceTitle, 'flex flex-col gap-1')}>
                  <p className="">
                    {totalOutstandingInvoices}{' '}
                    {totalOutstandingInvoices > 1 ? 'Invoices' : 'Invoice'}
                  </p>
                  <div className="h-[20px]">
                    {totalOverdueInvoices?.count > 0 && (
                      <Status
                        status={`OVERDUE_summary`}
                        statusText={`${totalOverdueInvoices?.count} OVERDUE >`}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        :
          <div
            className={clsx(
              classes.box2,
              'bg-white border border-solid border-[#D2D5DB99] rounded-2xl'
            )}
          >
            <div>
              <div className={clsx(classes.tabHeader)}>
                {props?.loading
                    ?
                    <div className="flex gap-[20px]">
                      {[1, 2, 3, 4]?.map((item, index) => (
                          <Shimmer key={index} className="w-[30px] h-[20px] rounded-lg"/>
                      ))}
                    </div>
                    :
                    itemsCurr && itemsCurr?.slice(0, 4)?.map((item) => (
                        <div className={clsx(activeTab === item?.key ? classes.activeTabText : classes.inActiveTabText)}
                             onClick={() => handleCount(item?.key)}>{item?.key}</div>
                    ))}
                {/*<div className={clsx(activeTab === "USD" ? classes.activeTabText : classes.inActiveTabText)} onClick={() => handleCount("USD")}>USD</div>*/}
                {/*<div className={clsx(activeTab === "EUR" ? classes.activeTabText : classes.inActiveTabText)} onClick={() => handleCount("EUR")}>EUR</div>*/}
                {/*<div className={clsx(activeTab === "GBP" ? classes.activeTabText : classes.inActiveTabText)} onClick={() => handleCount("GBP")}>GBP</div>*/}
                {/*<div className={clsx(activeTab === "CAD" ? classes.activeTabText : classes.inActiveTabText)} onClick={() => handleCount("CAD")}>CAD</div>*/}
                <Dropdown
                    menu={{
                      onClick: handleDropdownItemClick,
                      items: itemsCurr ? itemsCurr?.slice(4) : [],
                    }}
                    placement="bottom"
                    overlayStyle={{width:"100px", height:"250px", overflow:"scroll" , fontSize:"16px !important" }}
                    // open={true}
                >
                  <div className={clsx(activeTab === more ? classes.activeTabText : classes.inActiveTabText, 'flex items-center gap-[0px]')}>
                    <>{more}</>
                    <img src={down} className="w-[20px] h-[20px]" />
                  </div>
                </Dropdown>
              </div>

              <div className={classes.mainCard}>
                <div className={classes.receivablesCard}>
                  <div className={clsx(classes.inner)}>
                    <div className={classes.circle}>
                      <div className={classes.dollar}>{currencyCodeSign}</div>
                    </div>
                    <div className='flex flex-col items-center'>
                      <div className={classes.heading}>Total Receivables Value</div>
                      <div className={clsx(classes.text, 'flex')}>{<span className='font-[400]'>{currencyCodeSign}</span>}{props.loading ? <Shimmer className="w-[100%] min-w-[180px] h-[36px] rounded-lg"/> : totalInvoicesCreated?.price}</div>
                    </div>
                    {
                      props.loading
                        ?
                          <Shimmer className="w-[100%] h-[18px] rounded-lg"/>
                        :
                          <div className={clsx(classes.ytd)}>
                            {totalInvoicesCreated?.count}{' '}
                            {totalInvoicesCreated?.count > 1 ? 'Invoices' : 'Invoice'} created YTD
                          </div>
                    }
                  </div>
                </div>

                <div className={classes.bothCards}>
                  <div className={classes.firstGroup}>
                    <div className={classes.outstandingCard}>
                      <div className='w-[100%]'>
                        <div className={classes.heading}>Outstanding Payment</div>
                        <div className={clsx(classes.price, 'flex')}>{<span className='font-[400]'>{currencyCodeSign}</span>}{props.loading ? <Shimmer className="w-[100%] mb-[10px] h-[30px] rounded-lg"/> : totalOutstandingInvoices?.price}</div>
                      </div>
                      {
                        props.loading
                          ?
                            <Shimmer className="w-[100%] h-[20px] rounded-lg"/>
                          :
                            <div>
                              <div className={classes.count}>{totalOutstandingInvoices?.count} {totalOutstandingInvoices?.count > 1 ? 'Invoices' : 'Invoice'}</div>
                              <div className={clsx('')}>
                                <Status
                                  status={`OVERDUE_summary`}
                                  statusText={`${totalOverdueInvoices?.count} OVERDUE`}
                                />
                              </div>
                            </div>
                      }
                    </div>
                    <div className={classes.settledCard}>
                      <div className='w-[100%]'>
                        <div className={classes.heading}>Settled via Fideo</div>
                        <div className={clsx(classes.price, 'flex')}>{<span className='font-[400]'>{currencyCodeSign}</span>}{props.loading ? <Shimmer className="w-[100%] mb-[10px] h-[30px] rounded-lg"/> : totalSettledInvoices?.price}</div>
                      </div>
                      {
                        props.loading
                          ?
                            <Shimmer className="w-[100%] h-[20px] rounded-lg"/>
                          :
                            <div>
                              <div className={classes.count}>{totalSettledInvoices?.count} {totalSettledInvoices?.count > 1 ? 'Invoices' : 'Invoice'}</div>
                              <div className={clsx('')}>
                                <div className={clsx('bg-[#FFE55E]',`rounded-full w-fit flex flex-row items-center justify-between gap-2 px-[0.8rem] py-[0.2rem]`)}>
                                  <img src={WalletBrown} alt="wallet" className="w-6 h-6" />{' '}
                                  <p className="text-[1.2rem] font-[600] text-center leading-[1.6rem]">
                                    <span className="inline-block text-[#8C6825]">
                                      {savingAmount?.sign}{savingAmount?.price} SAVED
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </div>
                      }
                    </div>
                  </div>

                  <div className={classes.firstGroup}>
                    <div className={classes.paidOutsideCard}>
                      <div className='w-[100%]'>
                        <div className={classes.heading}>Settled outside Fideo</div>
                        <div className={clsx(classes.price, 'flex')}>{<span className='font-[400]'>{currencyCodeSign}</span>}{props.loading ? <Shimmer className="w-[100%] mb-[10px] h-[30px] rounded-lg"/> : totalPaidOutsideInvoices?.price}</div>
                      </div>
                      {
                        props.loading
                          ?
                            <Shimmer className="w-[100%] h-[20px] rounded-lg"/>
                          :
                            <div>
                              <div className={classes.count}>{totalPaidOutsideInvoices?.count} {totalPaidOutsideInvoices?.count > 1 ? 'Invoices' : 'Invoice'}</div>
                            </div>
                      }
                    </div>
                    <div className={classes.progressCard}>
                      <div className='w-[100%]'>
                        <div className={classes.heading}>In-Progress</div>
                        <div className={clsx(classes.price, 'flex')}>{<span className='font-[400]'>{currencyCodeSign}</span>}{props.loading ? <Shimmer className="w-[100%] mb-[10px] h-[30px] rounded-lg"/> : totalInProgressInvoices?.price}</div>
                      </div>
                      {
                        props.loading
                          ?
                            <Shimmer className="w-[100%] h-[20px] rounded-lg"/>
                          :
                            <div>
                              <div className={classes.count}>{totalInProgressInvoices?.count} {totalInProgressInvoices?.count > 1 ? 'Invoices' : 'Invoice'}</div>
                            </div>
                      }
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
      }
    </div>
  )
}

export default TransactionDetails
