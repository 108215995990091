import classes from './CustomerDetailsModalHeader.module.scss'
import Button from 'Layout/Button'
import { Breadcrumb } from 'antd'
import sideBarIcon from 'assets/Images/SideBarIcon.svg'
import adminAvatar from 'assets/Icons/account.svg'
import { useDispatch } from 'react-redux'
import { GlobalActions } from 'Store/global-redux'
import clsx from 'clsx'
import useClickOutside from 'hooks/useClickOutside'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import UseApiCall from 'hooks/useApiCall'
import { apiSignOut } from 'services'
import StatusTag from '../InvoiceDetailsModal/StatusTag'
import CustomerStatusTag from './CustomerStatusTag'
import Action from 'Components/ReportTable/Action'
import edit from 'assets/Images/edit.svg'
import { modalActions } from 'Store/modal-redux'

const CustomerDetailsModalHeader = (props) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [showOptions, setShowOptions] = useState(false)
  const optRef = useClickOutside(() => setShowOptions(false))
  const [logOut] = UseApiCall(apiSignOut, () => {
    dispatch({ type: 'CLEAR_STATE' })
    navigate('/login?mode=email')
  })

  const options = [
    {
      title: 'Log Out',
      onClick: () => {
        logOut()
      },
    },
  ]

  const optionArr = [
    {
      text: 'Edit',
      icon: edit,
      onClick: (cellData) => {
        if (cellData?.verification_status === "PENDING_VERIFICATION") {
          // handleCustomerEdit(cellData?.customer_detail)
          dispatch(modalActions.setUpdateContact(true))
          dispatch(modalActions.addContact())
        }
      },
    }
  ]

  return (
    <div ref={optRef} className={`${classes.page_intro} ${props.className}`}>
      <img
        src={sideBarIcon}
        alt="side-bar-icon"
        className={classes.side_bar}
        onClick={() => dispatch(GlobalActions.setShowSideBarMobile())}
      />
      <div className={classes.pageInfo}>
        <div
          className={`flex items-center -sm:flex-col -sm:gap-1 -sm:items-center`}
        >
          <div className={classes.page_name}>{props.pageName}</div>
          <div className="flex ml-[20px] invoiceDetailsStatusTag">
            {
              props?.details?.created_by?.toLowerCase() === "fideo-tally-service" &&
              // true   &&
              <span className="py-[2px] px-[8px] text-[14px] leading-[16px] text-[#FFFFFF] bg-[#1E333F] rounded-[100px] mr-[5px]"
              >
                Tally
              </span>
            }
            <CustomerStatusTag
              status={props.status?.toLowerCase()}
              type="light"
              className={`ml-6 text-lg -sm:text-2xl`}
            />
          </div>
        </div>
        <div className={classes.pageSubtitleContainer}>
          {props.message && (
            <div className={classes.message}>{props.message}</div>
          )}
          <div className="-md:hidden">
            <Breadcrumb
              separator=">"
              items={[
                {
                  title: 'Customer',
                },
                {
                  title: props.customerName,
                },
              ]}
            />
          </div>
        </div>
      </div>
      <div className={'flex gap-5'}>
        <div className={clsx(classes.button, 'flex-row gap-5 justify-end')}>
          {props.arrBtn?.map((item, index) => (
            <Button
              key={`${item.text}-${index}`}
              text={item.text === 'Payment Link' ? 'Single Payment Link' : item.text}
              icon={item.icon}
              disabled={!item.active}
              onClick={() => item.onClick()}
              className={clsx(classes.btn, (item.text === 'Payment Link') && `bg-[#FFF] border border-solid border-[1px] border-[#060a0d1f]`)}
              btnClass={clsx(classes.btnClass, item.isVisible || 'hidden')}
              btnClassIcon={clsx(
                classes.btnClass_icon,
                !item.active && 'opacity-50'
              )}
              btnClassText={clsx(
                classes.btnClass_text,
                !item.active && 'opacity-50',
                (item.text === 'Payment Link') && `text-[#000]`
              )}
            />
          ))}
        </div>
        <div className={clsx(
          classes.cell_action,
          ''
        )} style={{ borderRadius: "8px", border: "1px solid rgba(24, 28, 48, 0.20)", background: "#FFF", padding: "5px" }}>
          <Action
            option={[...optionArr]}
            cellData={props?.details}
            classed={props?.details?.verification_status === "PENDING_VERIFICATION" ? 'cursor-pointer' : '!cursor-not-allowed opacity-50'}
          />
        </div>
      </div>
      <div
        className={classes.profile}
        onClick={() => setShowOptions((pre) => !pre)}
      >
        <img src={adminAvatar} alt="admin-img" className={classes.userImg} />
        <div
          className={clsx(
            'absolute top-32 right-[12px] min-w-[200px] z-10 bg-bg-white divide-y divide-gray-100 rounded-lg shadow w-44 dark:bg-gray-700',
            showOptions ? 'block' : 'hidden'
          )}
        >
          <ul
            className="py-2 w-full text-sm text-gray-700 dark:text-gray-200"
            aria-labelledby="dropdownDefaultButton"
          >
            {options?.map((option) => (
              <li onClick={option.onClick}>
                <p className="block cursor-pointer w-full text-center px-8 py-4 text-2xl hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white">
                  {option.title}
                </p>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default CustomerDetailsModalHeader
