// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PasswordInput_box__Ryykk {
  width: 100%;
  height: max-content;
}
.PasswordInput_box__Ryykk .PasswordInput_password__dro3a {
  position: relative;
  display: flex;
  justify-content: flex-end;
  height: 5rem;
  width: 100%;
}
.PasswordInput_box__Ryykk .PasswordInput_password_error__xJnOV {
  height: 7rem;
}
.PasswordInput_box__Ryykk .PasswordInput_password_input__uBl\\+p {
  width: 100%;
  position: absolute;
}
.PasswordInput_box__Ryykk .PasswordInput_password__dro3a .PasswordInput_eye__DRQvx {
  position: absolute;
  margin-right: 1.6rem;
  cursor: pointer;
  height: 2.4rem;
  width: 2.4rem;
  top: 1.2rem;
  background-color: white;
}
.PasswordInput_box__Ryykk .PasswordInput_tooltip__gYDTz {
  position: absolute;
  z-index: var(--level_2);
  top: 7.7rem;
}`, "",{"version":3,"sources":["webpack://./src/Components/Authentication/PasswordInput.module.scss"],"names":[],"mappings":"AAAA;EAEI,WAAA;EACA,mBAAA;AAAJ;AAEI;EAEI,kBAAA;EACA,aAAA;EACA,yBAAA;EACA,YAAA;EACA,WAAA;AADR;AAGQ;EACI,YAAA;AADZ;AAIQ;EACI,WAAA;EACA,kBAAA;AAFZ;AAKQ;EACI,kBAAA;EACA,oBAAA;EACA,eAAA;EACA,cAAA;EACA,aAAA;EACA,WAAA;EACA,uBAAA;AAHZ;AAOI;EACI,kBAAA;EACA,uBAAA;EACA,WAAA;AALR","sourcesContent":[".box{\n\n    width: 100%;\n    height: max-content;\n\n    .password{\n\n        position: relative;\n        display: flex;\n        justify-content: flex-end;\n        height: 5rem;\n        width: 100%;\n\n        &_error{\n            height: 7rem;\n        }\n    \n        &_input{\n            width: 100%;\n            position: absolute;\n        }\n    \n        .eye{\n            position: absolute;\n            margin-right: 1.6rem;\n            cursor: pointer;\n            height: 2.4rem;\n            width: 2.4rem;\n            top: 1.2rem;\n            background-color: white;\n        }\n    }\n\n    .tooltip{\n        position: absolute;\n        z-index: var(--level_2);\n        top: 7.7rem;\n    }\n    \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": `PasswordInput_box__Ryykk`,
	"password": `PasswordInput_password__dro3a`,
	"password_error": `PasswordInput_password_error__xJnOV`,
	"password_input": `PasswordInput_password_input__uBl+p`,
	"eye": `PasswordInput_eye__DRQvx`,
	"tooltip": `PasswordInput_tooltip__gYDTz`
};
export default ___CSS_LOADER_EXPORT___;
