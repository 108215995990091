/* eslint-disable no-unreachable */
import PageIntro from 'Components/Global/PageIntro'
import classes from 'Pages/Admin/Reports.module.scss'
import book from 'assets/Images/book-white.svg'
import Button from 'Layout/Button'
import FiraReportTable from 'Components/ReportTable/FiraReportTable'
// import ReportPageFilter from 'Components/Global/ReportPageFilter'
import { useDispatch, useSelector } from 'react-redux'
import UseApiCall from 'hooks/useApiCall'
import { apiGetAgingAccount, apiGetAgingAccountJson, apiGetFiraReports } from 'services'
import { FiraReportsActions } from 'Store/reports-redux'
import { useEffect, useState } from 'react'
import { Skeleton, Tabs, Tooltip } from 'antd'
import { tourArrays } from "constants/Tour";
import TooltipTourData from "Components/Tour/TooltipTourData";
import CryptoJS from 'crypto-js';
import { secretKey } from "constants";
import clsx from 'clsx'
import FXRateTable from 'Components/molecule/FxRateTable/FXRateTable'
import CustomerOverview from 'Components/ReportTable/CustomerOverview'


const tableHeaderCustomer = [
  {
    id:1,
    title:"CUSTOMER",
    title1:"NAME",
    width:12.25,
    minWidth:142,
  },
  {
    id:2,
    title:"OVERDUE",
    title1:"BY 0-30",
    width:12.25,
    minWidth:120,
  },
  {
    id:3,
    title:"OVERDUE",
    title1:"BY 31-45",
    width:12.25,
    minWidth:120,
  },
  {
    id:4,
    title:"OVERDUE",
    title1:"BY 46-60",
    width:12.25,
    minWidth:120,
  },
  {
    id:5,
    title:"OVERDUE",
    title1:"BY 61+",
    width:12.25,
    minWidth:120,
  },
  {
    id:6,
    title:"OVERDUE",
    title1:"BY 91+",
    width:12.25,
    minWidth:120,
  },
  {
    id:7,
    title:"TOTAL",
    title1:"OVERDUE",
    width:12.25,
    minWidth:120,
  },
  {
    id:8,
    title:"NOT",
    title1:"DUE",
    width:12.25,
    minWidth:120,
  },
  {
    id:9,
    title:"TOTAL",
    title1:" ",
    width:12.25,
    minWidth:120,
  },
  
]

const Reports = () => {
  //   return (
  //     <div className={classes.reports}>
  //       <div className={classes.text}>Coming Soon.</div>
  //       {/* <Button text="Get instant credit" className={classes.button} disabled={true}/> */}
  //     </div>
  //   )
  const arrBtn = [
    // {
    //   text: 'Generate Fira',
    //   icon: book,
    //   onClick: function () {},
    // },
  ]

  const dispatch = useDispatch()
  const showTourModal = useSelector((state) => state.global.showTour);
  const stepNumber = useSelector((state) => state.global.stepNumberInTour);
  const [tourArray, setTourArray] = useState(tourArrays[1]);
  const [downloadBtn , setDownloadBtn] = useState(true);
  const [agingAccountData , setAgingAccountData] = useState(null);
  const [agingAccountJson , setAgingAccountJson] = useState(null);
  const decryptValue = (encryptedValue) => {
    const bytes = CryptoJS.AES.decrypt(encryptedValue || '', secretKey);
    return bytes.toString(CryptoJS.enc.Utf8);
  };

  const storedUserRole = decryptValue(localStorage.getItem('nickName') || '');

  useEffect(() => {
    if(storedUserRole === 'malik') setTourArray(tourArrays[0])
  }, [])
  const firaReports = useSelector((state) => state.reports.fira_reports)
  const [otherLoader, setOtherLoader] = useState(true)



  const [getAgingAccountJson, fetchinggetAgingAccountJson] = UseApiCall(apiGetAgingAccountJson, (res) => {
    setOtherLoader(false)
    setAgingAccountData(res?.data)

  }, (err) => {
    setOtherLoader(false);
  })

  useEffect(() => {
    // getFiraReports()
    // getAgingAccount()
    getAgingAccountJson()

  }, [])

  const [defaultActiveKey] = useState("customer_overview");

  const [loading, setLoading] = useState(true);
  const showSkeleton = () => {
    setLoading(false);
  }
  useEffect(() => {
    showSkeleton();
  }, [])

  const onTabChange = (key) => {
    // if(isCustomerDetailsModalOpen) return;
   if (key === 'customer_overview') {
      // window.history.pushState(
      //   {},
      //   undefined,
      //   `/receivables/bank-account?invoiceId=`
      // )
      setDownloadBtn(true)
    }
    else{
      setDownloadBtn(false)
    }
    showSkeleton()
  }

  const sortedData = {
    USD : null,
    EUR : null,
    GBP : null,
    CAD : null,
    AED : null,
    SGD : null,
    HKD : null,
    AUD : null,
    CHF : null,
    SEK : null,
    NOK : null,
    CZK : null,
    DKK : null,
    PLN : null,
  }

  const sortedDataKeys = Object.keys(sortedData);

// Sort the keys of agingAccountData?.currencyWise based on the order of sortedData keys
  const sortedCurrencyWiseData = Object.fromEntries(
      sortedDataKeys.map((key) => [key, agingAccountData?.currencyWise?.[key] || null])
  );


  const sortedNewCustomerData = Object.assign(sortedData,agingAccountData?.currencyWise)

  console.log(('sortedCurrencyWiseData',sortedCurrencyWiseData))
  // console.log(('fetchinggetAgingAccountJson',fetchinggetAgingAccountJson))
  // console.log(('agingAccountData',agingAccountData))

  let allEmpty = true;
  let inrSign = '';

  for (const currency in agingAccountData?.currencyWise) {
    if (agingAccountData?.currencyWise[currency].length !== 0) {
      inrSign = agingAccountData?.currencyWise[currency][0]?.converted?.currencyDTO?.sign;
      allEmpty = false;
      break; 
    }
  }

  // console.log('inrSign',inrSign)

  const items = [
    {
      key: 'customer_overview',
      label: `Customer Dues`,
      children: (
        // <Skeleton >
          <CustomerOverview
            tableHeader = {tableHeaderCustomer}
            customerData = {agingAccountData}
            sortedNewCustomerData = {sortedNewCustomerData}
            loading={fetchinggetAgingAccountJson}
            allEmpty={allEmpty}
            inrSign={inrSign}
          />
          // {/* <div className={classes.coomingSoon}>
          // //   <div className={classes.soonText}>
          // //     Coming Soon.
          // //   </div>
          // // </div> */}
        // </Skeleton>
      ),
    },
    // {
    //   key: 'fx_rates',
    //   label: `FX Rates`,
    //   children: (
    //     <Skeleton loading={loading}>
    //       {/* <FiraReportTable
    //         tableHeader={tableHeader}
    //         tableData={firaReports}
    //         // tableData={tableData}
    //         mobileTableData={firaReports}
    //         loading={fetching || otherLoader}
    //       /> */}
    //       <FXRateTable />
    //     </Skeleton>
    //   ),
    // },
    {
      key: 'business_overview',
      label: `Business Overview`,
      children: (
        <Skeleton loading={loading}>
          <div className={classes.coomingSoon}>
            <div className={classes.soonText}>
              Coming Soon.
            </div>
          </div>
        </Skeleton>
      ),
    },
  ]

  // console.log('newCustomerData', JSON.stringify(newCustomerData));
  // console.log('newCustomerData', agingAccountData);
  



  return (
    <div className={classes.reports}>
      <PageIntro
        pageName={'Reports'}
        message={'View all reports in one place'}
        arrBtn={arrBtn}
        className={classes.pageIntroReports}
        downloadBtn = {downloadBtn}
        loadingDownloadBtn={fetchinggetAgingAccountJson || allEmpty }
        // tourId = "step6"
      />
      {/* <ReportPageFilter
        searchText="Search Customer name/FIRA id"
        download={true}
        sendReminder={true}
        filter={true}
      /> */}
      <div
        className="flex flex-col gap-[48px]  w-[100%]"
        style={{
          background: '#F3F6FA',
        }}
      >
        <Tabs
          defaultActiveKey={defaultActiveKey}
          // className="customTab"
          onChange={onTabChange}
          // tabBarStyle={{
          //   position: "relative",
          //   zIndex: "500",
          //   background: "beige",
          //   color: "blue",
          //   display: "flex"
          // }}
          // style={{
          //   backgroundColor: 'transparent',
          //   borderTopWidth: 0,
          //   position: 'relative',
          //   left: 0,
          //   right: 0,
          //   top: 0,
          //   zIndex: 1000,
          // }}
          tabBarStyle={{ zIndex: (showTourModal && "step6" === tourArray[stepNumber]?.id) && 1000 }}
          style={{ backgroundColor: 'transparent', borderTopWidth: 0 }}
        >
        {items.map(item => (
          <Tabs.TabPane
            key={item.key}
            disabled={showTourModal}
            className={clsx('custom-tab', classes.firstTab)}
            tab={
              ((item?.key === "fx_rates" && showTourModal && "step6" === tourArray[stepNumber]?.id && tourArray[stepNumber]?.id) && false)
                ?
                <Tooltip
                  title={
                    <TooltipTourData
                      heading={tourArray[stepNumber]?.heading}
                      body1={tourArray[stepNumber]?.body1}
                      body2={tourArray[stepNumber]?.body2}
                      path={tourArray[stepNumber]?.pageUrl}
                      previousUrl={tourArray[stepNumber > 0 ? stepNumber - 1 : stepNumber]?.previousUrl}
                      finalStep={tourArray[stepNumber]?.finalStep}
                      firstStep={tourArray[stepNumber]?.firstStep}
                    />
                  }
                  id="customTooltip"
                  placement={tourArray[stepNumber]?.placement}
                  // defaultOpen={tourArray[stepNumber]?.defaultOpen}
                  open={showTourModal}
                  overlayInnerStyle={{
                    // color: "orange",
                    background: "#060A0D",
                    width: "400px",
                    padding: "0px",
                    borderRadius: "16px",
                    ...tourArray[stepNumber]?.styles
                  }}
                >
                  <div className={clsx(classes.dam)}>
                    <span>{item.label}</span>
                  </div>
                </Tooltip>
                :
                  <span>{item.label}</span>
            }
          >
            <Skeleton loading={loading}>{item.children}</Skeleton>
          </Tabs.TabPane>
        ))}
        </Tabs>
      </div>
      <div className={classes.button}>
        {arrBtn.map((arr) => (
          <Button
            key={arr.text}
            text={arr.text}
            onClick={arr.onClick}
            className={classes.btn}
          />
        ))}
      </div>
    </div>
  )
}
export default Reports
