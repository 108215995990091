// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Cashflow_cashflow__7LUKE {
  font-size: 2.5rem;
  line-height: 3rem;
  border: 2px soild red;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Cashflow_cashflow__7LUKE .Cashflow_text__DOfr5 {
  background: -webkit-linear-gradient(right, #c4c4c4, #333);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Admin/Cashflow.module.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,iBAAA;EACA,qBAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AACF;AAAE;EACE,yDAAA;EACA,6BAAA;EACA,oCAAA;AAEJ","sourcesContent":[".cashflow {\n  font-size: 2.5rem;\n  line-height: 3rem;\n  border: 2px soild red;\n  margin: auto;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  .text {\n    background: -webkit-linear-gradient(right, #c4c4c4, #333);\n    -webkit-background-clip: text;\n    -webkit-text-fill-color: transparent;\n  }\n\n  .btn {\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cashflow": `Cashflow_cashflow__7LUKE`,
	"text": `Cashflow_text__DOfr5`
};
export default ___CSS_LOADER_EXPORT___;
