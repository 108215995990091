import Modal from "Layout/Modal";
import classes from "Components/General/AddContact.module.scss";
import ReactDOM from "react-dom";
import Backdrop from "Layout/Backdrop";
import Close from "Layout/Close";
import Button from "Layout/Button";
import userAdd from "assets/Images/user-add-white.svg";
import { useDispatch, useSelector } from "react-redux";
import CreateInvoice from "Components/Invoice/CreateInvoice";
import { modalActions } from "Store/modal-redux";
import AddPaymentDetail from "Components/Payment/AddPaymentDetail";
import clsx from "clsx";
import TextInput from "Layout/TextInput";
import MobileInput from "Layout/MobileInput";
import { useState ,useRef, useEffect } from "react";
import CustomSelector from "Components/atom/CustomSelector/CustomSelector";
import { COUNTRIES } from "lib/data/countries";
import UseApiCall from "hooks/useApiCall";
import { apiAddCustomer, apiUpdateCustomer } from "services";
import leftPointer from 'assets/Icons/arrow-left.svg'
import { enqueueSnackbar } from 'notistack'
import { CustomersActions } from 'Store/customer-redux'

const emailValidation = [
  (value) => value?.trim() !== '',
  (value) => /[a-zA-Z0-9_\.\-]+@([a-zA-Z0-9_\.\-]+\.)+[a-zA-Z]+/.test(value),
]

const AddContact = (props) => {
  const dispatch = useDispatch()
  const [fullLegalName, setFullLegalName] = useState('')
  const [nickName, setNickName] = useState('')
  const [email, setEmail] = useState('')
  const [countryCode, setCountryCode] = useState('+91')
  const [mobile, setMobile] = useState('')
  const [addressLine1, setAddressLine1] = useState('')
  const [addressLine2, setAddressLine2] = useState('')
  const [city, setCity] = useState('')
  const [state, setState] = useState('')
  const [postalCode, setPostalCode] = useState('')
  const [country, setCountry] = useState()
  const [customerId, setCustomerId] = useState()
  const [isMobileValid, setIsMobileValid] = useState(false)
  const [isEmailValid, setIsEmailValid] = useState(false)
  const [submitClicked, setSubmitClicked] = useState(false)
  const [countryError, setCountryError] = useState(false)
  const [addContactError , setAddContactError] = useState(false);
  const inputErrorScroll = useRef(null);
  const focusRef = useRef(null);
  const noneRef = useRef(null);
  const customer = useSelector((state) => state.customer.selected_customer)
  const updateContact = useSelector((state) => state.modal.updateContact)

  useEffect(() => {
    if (customer?.details && updateContact) {
      setFullLegalName(customer?.details?.customer_name);
      setNickName(customer?.details?.nick_name);
      setEmail(customer?.details?.email_address);
      setCountryCode(customer?.details?.mobile_country_code);
      setMobile(customer?.details?.mobile_number);
      setAddressLine1(customer?.details?.address_line1);
      setAddressLine2(customer?.details?.address_line2);
      setCity(customer?.details?.city);
      setState(customer?.details?.state);
      setPostalCode(customer?.details?.postal_code);
      setCustomerId(customer?.details?.customer_id);
      let prevCountry = { title: customer?.details?.country_name, value: customer?.details?.country_code };
      if (customer?.details?.country_name) {
        setCountry(prevCountry)
        setCountryError(prevCountry === '')
      }
    }
  }, [customer])

  const handleInputError = () =>{
    if(inputErrorScroll.current) {
      inputErrorScroll.current.scrollIntoView({
        behavior: 'smooth' , 
        block: 'start' ,
        inline: 'start',
      });
    }
  }

  const handleFocus = () =>{
    if(focusRef.current) {
      setAddContactError(false)
      focusRef.current.focus()
    }
  }

  const [addCustomer, adding] = UseApiCall(apiAddCustomer, () => {
    if (props.nextPageFunctionality) props.nextPageFunctionality()
    if (props?.onSubmit) props?.onSubmit(fullLegalName)
    enqueueSnackbar(`Customer created successfully.`, { variant: 'success' })
  }, (err) => {
    enqueueSnackbar(`${err?.data?.error_response?.message} ${err?.data?.error_response?.errors !== null ? JSON.stringify(err?.data?.error_response?.errors[0]) : ""}`, { variant: 'error' })
  })

  const [updateCustomer] = UseApiCall(apiUpdateCustomer, () => {
    dispatch(modalActions.setUpdateContact(false))
    dispatch(modalActions.addContact())
    dispatch(CustomersActions.setUpdateCustomers())
    // dispatch(CustomersActions.setSelectedCustomer(null))
    enqueueSnackbar(`Customer updated successfully.`, { variant: 'success' })
  }, (err) => {
    enqueueSnackbar(`${err?.data?.error_response?.message} ${err?.data?.error_response?.errors !== null ? JSON.stringify(err?.data?.error_response?.errors[0]) : ""}`, { variant: 'error' })
  })

  const createInvoiceDisplay = useSelector(
    (state) => state.modal.createInvoiceDisplay
  )
  const addPaymentDetailDisplay = useSelector(
    (state) => state.modal.addPaymentDetailDisplay
  )

  const isValid = () => {
    if (
      !fullLegalName ||
      !isMobileValid ||
      !isEmailValid ||
      !addressLine1 ||
      !addressLine2 ||
      !city ||
      !state ||
      !postalCode ||
      !country
    )
      return false
    return true
  }

  const handleSubmit = () => {
    setSubmitClicked(true)
    
    // Check if the country is selected
    if (!country) {
      setCountryError(true);
      setAddContactError(true)
      handleInputError()
      handleFocus()
      return;
    }
    
    if (isValid()) {
      if (updateContact) {
        updateCustomer({
          customerId: customerId,
          customer_name: fullLegalName,
          nick_name: nickName,
          mobile_country_code: countryCode,
          mobile,
          email,
          address_line1: addressLine1,
          address_line2: addressLine2,
          city,
          state,
          postal_code: postalCode,
          country: country?.value,
        })
      } else {
        addCustomer({
          customer_name: fullLegalName,
          nick_name: nickName,
          mobile_country_code: countryCode,
          mobile,
          email,
          address_line1: addressLine1,
          address_line2: addressLine2,
          city,
          state,
          postal_code: postalCode,
          country: country?.value,
        })
      }
    }
    else {
      setAddContactError(true)
      handleInputError()
      handleFocus()
      enqueueSnackbar('Please fill all mandatory fields.', { variant: 'error' })
    }
  }

  const handleCountryChange = (prop) => {
    setCountry(prop)
    setCountryError(prop === '')
  }

  return (
    <Modal className={clsx(classes.modal, '-sm:h-screen p-0 max-w-[630px]')}>
      {ReactDOM.createPortal(
        <Backdrop
          onClick={() => {
            dispatch(modalActions.setUpdateContact(false))
            dispatch(modalActions.addContact())
            // dispatch(CustomersActions.setSelectedCustomer(null))
          }}
          className={classes.backdrop}
        />,
        document.getElementById('backdrop-root')
      )}
      {createInvoiceDisplay &&
        ReactDOM.createPortal(
          <CreateInvoice />,
          document.getElementById('modal-root')
        )}
      {addPaymentDetailDisplay &&
        ReactDOM.createPortal(
          <AddPaymentDetail />,
          document.getElementById('modal-root')
        )}
      <div className="flex z-10  flex-row items-center justify-center sm:justify-between absolute top-0 right-0 left-0 px-16 py-[20px] bg-white">
        <img
          src={leftPointer}
          alt="left-pointer"
          className="block sm:hidden absolute left-8"
          onClick={() => {
            dispatch(modalActions.setUpdateContact(false))
            dispatch(modalActions.addContact())
            // dispatch(CustomersActions.setSelectedCustomer(null))
          }}
        />
        <p className="text-3xl">{updateContact ? "Update Customer" : "Add Customer"}</p>
        <Close
          className="w-[32px] h-[32px] hidden sm:block"
          onClick={() => {
            dispatch(modalActions.setUpdateContact(false))
            dispatch(modalActions.addContact())
            // dispatch(CustomersActions.setSelectedCustomer(null))
          }}
        />
      </div>
      <div
        className={clsx(
          classes.add_contact,
          '-sm:h-screen flex relative px-16 flex-col pt-20 h-screen overflow-scroll pb-72'
        )}
      >
        <div className="flex flex-col mt-16 items-start gap-4">
          <p className="text-[16px] ">Basic Information</p>
          <div className="w-full grid mt-8 grid-cols-1 sm:grid-cols-2 gap-x-6 gap-y-8">
            <div ref={!fullLegalName?inputErrorScroll:noneRef}>
              <TextInput
                type="text"
                text="Full Legal Name*"
                errorMessage="Please enter a valid name"
                validation={[(value) => value !== '']}
                inputValidity={() => {}}
                setInput={(value) => {
                  setFullLegalName(value)
                }}
                enteredInput={fullLegalName}
                hasError={submitClicked && !fullLegalName}
                initiateFocus = {!fullLegalName}
                focusRef = {focusRef}
                noneRef = {noneRef}
              />
            </div>
            <div>
              <TextInput
                type="text"
                text="Nick Name"
                // className={classes.input}
                validation={[]}
                inputValidity={() => {}}
                setInput={(value) => {
                  setNickName(value)
                }}
                enteredInput={nickName}
              />
            </div>
            <div ref={(fullLegalName && !isEmailValid)?inputErrorScroll:noneRef}>
              <TextInput
                type="text"
                text="Email Address*"
                errorMessage="Please enter a valid email address"
                validation={emailValidation}
                inputValidity={(value) => {
                  setIsEmailValid(value)
                }}
                setInput={(value) => {
                  setEmail(value)
                }}
                enteredInput={email}
                hasError={submitClicked && !isEmailValid}
                initiateFocus = {fullLegalName && !isEmailValid}
                focusRef = {focusRef}
                noneRef = {noneRef}
              />
            </div>
            <div>
              <MobileInput
                className={classes.input}
                // hasError = {wrongInput && loginType === 'mobile' && !isMobileValid}
                mobileCodeInput={countryCode}
                mobileNumberInput={mobile}
                setMobileNumberInput={(value) => setMobile(value)}
                setMobileValidity={(value) => setIsMobileValid(value)}
                setMobileCodeInput={(value) => setCountryCode(value)}
                hasError={submitClicked && !isMobileValid}
                errorMessage="Please enter a valid mobile number"
                // isRequired={true}
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col mt-16 items-start gap-4">
          <p className="text-[16px] ">Address</p>
          <div className="w-full grid mt-8 grid-cols-1 sm:grid-cols-2 gap-x-6 gap-y-8">
            <div ref={(fullLegalName && isEmailValid && !addressLine1)?inputErrorScroll:noneRef}>
              <TextInput
                type="text"
                text="Address line 1*"
                //   className={'relative'}
                errorMessage="Please enter a valid address"
                validation={[(value) => value !== '']}
                inputValidity={() => {}}
                setInput={(value) => {
                  setAddressLine1(value)
                }}
                enteredInput={addressLine1}
                hasError={submitClicked && !addressLine1}
                initiateFocus = {fullLegalName && isEmailValid && !addressLine1}
                focusRef = {focusRef}
                noneRef = {noneRef}
              />
            </div>
            <div ref={(fullLegalName && isEmailValid && addressLine1 && !addressLine2)?inputErrorScroll:noneRef}>
              <TextInput
                type="text"
                text="Address line 2*"
                errorMessage="Please enter a valid address"
                validation={[(value) => value !== '']}
                inputValidity={() => {}}
                setInput={(value) => {
                  setAddressLine2(value)
                }}
                enteredInput={addressLine2}
                hasError={submitClicked && !addressLine2}
                initiateFocus = {fullLegalName && isEmailValid && addressLine1 && !addressLine2}
                focusRef = {focusRef}
                noneRef = {noneRef}
              />
            </div>
            <div ref={(fullLegalName && isEmailValid && addressLine1 && addressLine2 && !city)?inputErrorScroll:noneRef}>
              <TextInput
                type="text"
                text="City*"
                errorMessage="Please enter a valid city"
                validation={[(value) => value !== '']}
                inputValidity={() => {}}
                setInput={(value) => {
                  setCity(value)
                }}
                enteredInput={city}
                hasError={submitClicked && !city}
                initiateFocus = {fullLegalName && isEmailValid && addressLine1 && addressLine2 && !city}
                focusRef = {focusRef}
                noneRef = {noneRef}
              />
            </div>
            <div ref={(fullLegalName && isEmailValid && addressLine1 && addressLine2 && city && !state)?inputErrorScroll:noneRef}>
              <TextInput
                type="text"
                text="State*"
                errorMessage="Please enter a valid state"
                validation={[(value) => value !== '']}
                inputValidity={() => {}}
                setInput={(value) => {
                  setState(value)
                }}
                enteredInput={state}
                hasError={submitClicked && !state}
                initiateFocus = {fullLegalName && isEmailValid && addressLine1 && addressLine2 && city && !state}
                focusRef = {focusRef}
                noneRef = {noneRef}
              />
            </div>
            <div ref={(fullLegalName && isEmailValid && addressLine1 && addressLine2 && city && state && !postalCode)?inputErrorScroll:noneRef}>
              <TextInput
                type="text"
                text="Postal Code*"
                errorMessage="Please enter a valid postal code"
                validation={[(value) => value !== '']}
                inputValidity={() => {}}
                setInput={(value) => {
                  setPostalCode(value)
                }}
                enteredInput={postalCode}
                hasError={submitClicked && !postalCode}
                initiateFocus = {fullLegalName && isEmailValid && addressLine1 && addressLine2 && city && state && !postalCode}
                focusRef = {focusRef}
                noneRef = {noneRef}
              />
            </div>
            <div className="w-full" ref={(fullLegalName && isEmailValid && addressLine1 && addressLine2 && city && state && !postalCode)?inputErrorScroll:noneRef}>
              <CustomSelector
                onChange={handleCountryChange}
                selectedValue={country}
                items={COUNTRIES}
                validation={[(value) => value !== '']}
                placeholder="Country*"
                errorMessage="Please enter a valid Business Country"
                // inputValidity={(value) => setCountryError(!value)}
                hasError={submitClicked && countryError}
                initiateFocus = {fullLegalName && isEmailValid && addressLine1 && addressLine2 && city && state && postalCode && !country}
                focusRef = {focusRef}
                noneRef = {noneRef}
              />
            </div>
          </div>
        </div>
        <div className={clsx(classes.documentupload, 'bg-[#F4F6FA] mt-[3rem] cursor-not-allowed')}>
          <div
            className={clsx(classes.dropMessage, `flex ${"flex-col"} justify-center items-center relative`)}
          >
            <p className="">
              { (
                  <>
                    <p className={classes.fontText}>Connect to your ERP/Accounting Software</p>
                    <p className={classes.fontSubText}>Tally, SAP, Microsoft Envision</p>
                    <p className={classes.fontSubText}>(To enable this option please connect with Fideo Support)</p>
                  </>
                )
              }
            </p>
          </div>
        </div>
      </div>
      <div className="absolute left-0 right-0 bottom-0 px-8 sm:px-16 py-5 flex border-t bg-bg-white border-t-[#ECEFF2]">
        <Button
          className={clsx(
            'flex-1 outline-none',
            !isValid() && 'text-[#ffffff80]'
          )}
          text={updateContact ? "Update Customer" : "Add Customer"}
          icon={userAdd}
          onClick={handleSubmit}
        />
      </div>
    </Modal>
  )
}

export default AddContact;
