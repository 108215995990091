import React from 'react';
import classes from "Pages/Admin/BankAccounts.module.scss";
import maximize from "assets/Icons/maximize.svg"
import share from "assets/Icons/share.svg"
import checkCircle from "assets/Icons/check-circle.svg"
import file from "assets/Icons/file.svg"
import arrowDown from "assets/Icons/arrow-down.svg"
import frame32 from "assets/Icons/Frame 332.svg"
import copy from "assets/Icons/copy.svg"
import arrowDownWhite from "assets/Icons/arrow-downWhite.svg"
import shareIcon from "assets/Icons/share-Icon.svg"
import alertCircle from "assets/Icons/alert-circleNew.svg"
import { enqueueSnackbar, useSnackbar } from 'notistack';

const BankAccountDetails = ({bank}) => {
    const { enqueueSnackbar } = useSnackbar()

    console.log(bank);
    const {accountHolderName,accountId,accountNumber,bankAccount,bankAddress,bankName,bankRoutingNumber,branchName,country,ifscCode,isActive,isPrimary,paymentMethod,swiftCode,bankAccountType} = bank;

    const handleCopy = (event, method) => {
        event.preventDefault()
        try {
          const text = `Payment Method : ${method.paymentMethod}\nBeneficiary : ${
            method.accountHolderName
          }\nBeneficiary address: ${method.bankAddress}\nAccount number : ${
            method.accountNumber
          }\nSWIFT code : ${
            method?.swiftCode}\nIFSC code : ${method?.ifscCode}\nBank Name: ${
                    method.bankName}\nBank Address: ${method.bankAddress}`
          if ('clipboard' in navigator) {
            navigator.clipboard.writeText(text)
          }
          document.execCommand('copy', true, text)
          enqueueSnackbar('Details Copied!', { variant: 'success' })
          return
        } catch (error) {}
      }
  return (
    <div>
        <div className={`${classes.virtualAccContainer}`}>
            <div className={`${classes.virtualAccContainerTop}`}>
                <span className={`${classes.virtualAccContainerTopText1}`}>Account Details</span>
                <div className={`${classes.virtualAccContainerTopRight}`}>
                    {/* <div className={`${classes.virtualAccContainerTopRight1}`}>
                        <img src={file} alt='file' className={`${classes.virtualAccContainerTopRight1Img}`} />
                        <span className={`${classes.virtualAccContainerTopRight1Text}`}>View Statement</span>
                    </div>
                    <div className={`${classes.virtualAccContainerTopRight2}`}>
                        <div className={`${classes.virtualAccContainerTopRight2A}`}>
                            <img src={arrowDown} alt='arrow-down' className={`${classes.virtualAccContainerTopRight2AImg}`} />
                            <span className={`${classes.virtualAccContainerTopRight2AText}`}>Download all details in a PDF</span>
                        </div>
                    </div> */}
                </div>
            </div>
            <div className={`${classes.virtualAccContainerBottom}`}>
                <div className={`${classes.virtualAccContainerBottomOne}`}>
                    <div className={`${classes.virtualAccContainerBottomOneHeader}`}>
                        <div className={`${classes.virtualAccLeft}`}>
                            <span className={`${classes.virtualAccLeftText}`}>{`Local ${bankAccountType ? bankAccountType : 'INR'} Account`}</span>
                            <div className={`${classes.virtualAccLeftBottom}`}>
                                <div className={`${classes.virtualAccLeftBottom1}`}>
                                    <img src={alertCircle} alt='share' className={`${classes.virtualAccLeftBottom1Img}`}/>
                                    <span className={`${classes.virtualAccLeftBottom1Text}`}>{`You will receive money ${bankAccountType ? 'without conversion' : 'after conversion' } in this bank account`}</span>
                                </div>
                                {/* <div className={`${classes.virtualAccLeftBottom1}`}>
                                    <img src={checkCircle} alt='check-circle' className={`${classes.virtualAccLeftBottom1Img}`}/>
                                    <span className={`${classes.virtualAccLeftBottom1Text}`}>Cheaper than Swift</span>
                                </div>
                                <div className={`${classes.virtualAccLeftBottom1}`}>
                                    <img src={maximize} alt='maximize' className={`${classes.virtualAccLeftBottom1Img}`}/>
                                    <span className={`${classes.virtualAccLeftBottom1Text}`}>Only accepts USD</span>
                                </div> */}
                            </div>
                        </div>
                        <div className={`${classes.virtualAccRight}`}>
                            <div className={`${classes.virtualAccRightOne}`}>
                                {/* <img src={frame32} alt='frame32'  className={`${classes.virtualAccRightOneImg}`}/> */}
                            </div>
                            {/* <div className={`${classes.virtualAccRightTwo}`}>
                                <span className={`${classes.virtualAccRightTwoText}`}>Preferred by payers in USA</span>
                            </div> */}
                        </div>
                    </div>
                    <div className={`${classes.virtualAccContainerBottomContent}`}>
                        <div className={`${classes.virtualAccContainerBottomContentOne}`}>
                            <div className={`${classes.virtualAccContainerBottomContentOneA}`}>
                                <span className={`${classes.virtualTitle}`}>Payment method</span>
                                <span className={`${classes.virtualDesc}`}>{paymentMethod}</span>
                            </div>
                            <div className={`${classes.virtualAccContainerBottomContentOneA}`}>
                                <span className={`${classes.virtualTitle}`}>Account number</span>
                                <span className={`${classes.virtualDesc}`}>{accountNumber}</span>
                            </div>
                            <div className={`${classes.virtualAccContainerBottomContentOneA}`}>
                                <span className={`${classes.virtualTitle}`}>Swift Code</span>
                                <span className={`${classes.virtualDesc}`}>{swiftCode}</span>
                            </div>
                        </div>
                        <div className={`${classes.virtualAccContainerBottomContentOne}`}>
                            <div className={`${classes.virtualAccContainerBottomContentOneA}`}>
                                <span className={`${classes.virtualTitle}`}>IFSC Code</span>
                                <span className={`${classes.virtualDesc}`}>{ifscCode}</span>
                            </div>
                            <div className={`${classes.virtualAccContainerBottomContentOneA}`}>
                                <span className={`${classes.virtualTitle}`}>Bank Name</span>
                                <span className={`${classes.virtualDesc}`}>{bankName}</span>
                            </div>
                            <div className={`${classes.virtualAccContainerBottomContentOneA}`}>
                                <span className={`${classes.virtualTitle}`}>Branch name</span>
                                <span className={`${classes.virtualDesc}`}>{branchName}</span>
                            </div>
                        </div>
                        <div className={`${classes.virtualAccContainerBottomContentOne}`}>
                            <div className={`${classes.virtualAccContainerBottomContentOneA}`} >
                                <span className={`${classes.virtualTitle}`}>Bank address</span>
                                <span className={`${classes.virtualDesc}`}>{bankAddress}</span>
                            </div>
                            <div className={`${classes.virtualAccContainerBottomContentOneA}`} >
                                <span className={`${classes.virtualTitle}`}>Account holder name</span>
                                <span className={`${classes.virtualDesc}`}>{accountHolderName}</span>
                            </div>
                        </div>

                        <hr className={`${classes.virtualHr}`}/>

                        <div className={`${classes.virtualBottomDownload}`}>
                            <div className={`${classes.virtualBottomDownloadSection}`}>
                                <img src={copy} alt='copy'  className={`${classes.virtualBottomDownloadSectionImg}`}/>
                                <span className={`${classes.virtualBottomDownloadSectionDesc}`}
                                onClick={(e) => handleCopy(e, bank)}
                                >
                                    Copy Details</span>
                            </div>
                            <div className={`${classes.virtualBottomDownloadSectionDisable}`}>
                                <img src={arrowDownWhite} alt='arrowDown'  className={`${classes.virtualBottomDownloadSectionImg}`}/>
                                <span className={`${classes.virtualBottomDownloadSectionDesc}`}>Download Details</span>
                            </div>
                            {/* <div className={`${classes.virtualBottomDownloadSection}`}>
                                <img src={shareIcon} alt='share'  className={`${classes.virtualBottomDownloadSectionImg}`}/>
                                <span className={`${classes.virtualBottomDownloadSectionDesc}`}>Share Details</span>
                            </div> */}
                        </div>
                    </div>
                </div>
           </div>
        </div>
    </div>
  )
}

export default BankAccountDetails