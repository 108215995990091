import { Tooltip } from "antd";
import classes from "./PaymentDetails.module.scss";
import lowMarkup from "assets/Images/lowMarkup.svg";
import moreInfo from "assets/Images/moreInfo.svg";

const ForexExchange = (props) => {
    const {forexData} = props
    if(!forexData) return null
  return (
        <div className={classes.converter_details}>
          <div className={classes.converter_details_header}>
            <div className={classes.converter_details_header_title}>
              Converted Amount
            </div>
            <div className={classes.converter_details_header_amount}>
            {/* {forexData?.output_currrency?.sign}{forexData?.converted_amount} */}
            {forexData?.output_currrency?.sign}
            {forexData?.output_currrency?.sign === '₹' 
              ? forexData?.converted_amount?.toLocaleString('en-IN', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              : forexData?.converted_amount?.toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
            </div>
          </div>
          <div className={classes.row}>
            <div className={classes.markup_title}>
              {/* <img
                src={lowMarkup}
                alt="low-markup"
                className={classes.markup_title_icon}
              /> */}
              <div className={classes.markup_title_text}>Mid market rate</div>
            </div>
            <div className={classes.message}>@{forexData?.conversion_rate} {forexData?.input_currrency?.code} {'< >'} {forexData?.output_currrency?.code}</div>
          </div>
          <div className={classes.row}>
            <div className={classes.charges_title}>
              <div className={classes.charges_title_text}>
                Fideo Charges + GST
              </div>
              <Tooltip
                placement="top"
                title={
                  (forexData?.total_revenue_without_gst || forexData?.gst_amount)
                    ?
                      <div>
                        {forexData?.total_revenue_without_gst !== null && forexData?.total_revenue_without_gst !== undefined && <div style={{ overflowWrap: "anywhere", maxWidth: "220px" }} className="flex justify-between"><div>Fideo Charges: </div><span className="font-[600]">{forexData?.transaction_charges_currency?.sign}
                        {
                          forexData?.transaction_charges_currency?.sign === '₹' 
                            ? forexData?.total_revenue_without_gst?.toLocaleString('en-IN', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }) 
                            : forexData?.total_revenue_without_gst?.toLocaleString('en-US', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })
                        }</span></div>}
                        {forexData?.total_revenue_without_gst !== null && forexData?.total_revenue_without_gst !== undefined && <div className="border-t-[1px] border-solid border-[#ffffff1f]  w-[220px]"></div>}
                        {forexData?.gst_amount !== null && forexData?.gst_amount !== undefined && <div style={{ overflowWrap: "anywhere", maxWidth: "220px" }} className="flex justify-between"><div>GST (18%): </div><span className="font-[600]">{forexData?.transaction_charges_currency?.sign}
                        {
                          forexData?.transaction_charges_currency?.sign === '₹' 
                            ? forexData?.gst_amount?.toLocaleString('en-IN', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })
                            : forexData?.gst_amount?.toLocaleString('en-US', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })
                        }</span></div>}
                      </div>
                    :
                      false
                }
                // title={
                //       <div>
                //         {<div style={{ overflowWrap: "anywhere", maxWidth: "220px" }}>Fideo Charges: <span className="font-[600]">{forexData?.transaction_charges_currency?.sign}2343432.44</span></div>}
                //         {<div className="border-t-[1px] border-solid border-[#ffffff1f] w-[220px]"></div>}
                //         {<div style={{ overflowWrap: "anywhere", maxWidth: "220px" }}>GST (18%): <span className="font-[600]">{forexData?.transaction_charges_currency?.sign}349.43</span></div>}
                //       </div>
                // }
                color="#1E333F"
                overlayInnerStyle={{ maxWidth: "240px", position: "relative", right: "30px" }}
                overlayStyle={{ fontSize: "14px", fontWeight: "300", lineHeight: "24px" }}
              >
                <img
                  src={moreInfo}
                  alt="more-info-icon"
                  className={classes.charges_title_icon}
                  onClick={() => {
                    // setShowTransactionInfo((prev) => !prev);
                  }}
                />
              </Tooltip>
            </div>
            <div className={classes.message}>
              {
                forexData?.transaction_charges
                  ?
                    `- ${forexData?.transaction_charges_currency?.sign}
                      ${forexData?.transaction_charges_currency?.sign === '₹' 
                      ? forexData?.transaction_charges?.toLocaleString('en-IN', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                      : forexData?.transaction_charges?.toLocaleString('en-US', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}`
                  :
                    '-'
              }
            </div>
          </div>
          <div className={`${classes.row} ${classes.row_last}`}>
            <div className={classes.row_title}>You’ll recieve</div>
            <div className={classes.receiveAmount}>
              {forexData?.output_currrency?.sign}
              {
                forexData?.output_currrency?.sign === '₹' 
                  ? forexData?.receiving_amount?.toLocaleString('en-IN', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
                  : forexData?.receiving_amount?.toLocaleString('en-US', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })
              }
            </div>
          </div>
        </div>
  );
};

export default ForexExchange
