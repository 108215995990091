import React, { useEffect, useRef, useState } from 'react';
import classes from 'Components/otpInnerScreen/EnterOtp.module.scss';
import crossImg from 'assets/Icons/crossImg.svg'
import rightTickImg from 'assets/Icons/rightTickImg.svg'
import clsx from 'clsx';


const TypeOtp = ({handleResendOTPView,invoiceData, setOtpViewNowBox ,otpErrorState, setPaymentLinkOtpRequired,handleOTPView,presignedUrlErrorState, handleOTPViewNow, otp, setOtp}) => {

    // const [otp, setOtp] = useState(['', '', '', '','','']); // Initialize OTP as an array of empty strings
    const otpInputs = useRef([]); // Create a ref to hold references to OTP input elements
    const [active,setActive] = useState(false)
    const [counter,setCounter] = useState(false)
    const [timer, setTimer] = useState(180); // Initial timer value in seconds
    const [isTimerActive, setIsTimerActive] = useState(false);

    const handleOtpInput = (e, index) => {
        const value = e.target.value;

        if(index  === 0)
            otp[index] = '';
            setOtp([...otp]);
          
          if (e.key === 'Backspace' && index > 0) {
            if(index - 1 === 0)
            otp[index] = '';
            
              otp[index] = ''; // Clear the current digit
              setOtp([...otp]);
              otpInputs.current[index - 1].focus();

            if(index === 0)
            otp[index] = '';

            if(index <= 5)
             setActive(false)
            return;
          }
          if (e.key === 'ArrowLeft' && index > 0) {
            // Handle Arrow Left key
            if (index > 0) otpInputs.current[index - 1]?.focus();
            return;
          }
          if (e.key === 'ArrowRight' && index < 5) {
            // Handle Arrow Right key
            if (index < 5) otpInputs.current[index + 1]?.focus();
            return;
          }

        const isDigit = /[0-9]/.test(value);
            
        if (isDigit && index >= 0 && index < otp.length) {
          otp[index] = value; // Update the OTP array
          setOtp([...otp]);

          // if(index === 5)
          // setActive(true)
          // if(index < 5)
          // setActive(false)

          const hasEmptyValue = otp.some((value) => value === '');

          if (hasEmptyValue) {
            setActive(false);
          } else {
            setActive(true);
          }
    
          // Automatically focus on the next input element if available
          if (otpInputs.current[index + 1] && e.key !== "ArrowLeft") {
            otpInputs.current[index + 1].focus();
          }
        } 
      };

    // console.log(otp.join(''))
    // console.log(invoiceData, "invoiceData")
    // let email = "invoiceData?.invoiceResponse?.customer?.emailAddress";
    // // let email = invoiceData;
    // let f = email?.slice(0,1);
    // let l = email?.substring(email?.indexOf("@")-1, email?.indexOf("@"))
    // let e = email?.substring(email?.indexOf("@")+1, email?.indexOf("@")+2)
    // const parts = email?.split('.');
    // const substringAfterDot = parts[parts?.length - 1];

    const handleOTPViewCross = () => {
        setOtpViewNowBox((prev) => !prev)
        // setPaymentLinkOtpRequired((prev) => !prev)
    }

  // Function to start the timer
  const startTimer = () => {
    setIsTimerActive(true);
  };

  const setTimerPrevious = () => {
    if (isTimerExpired()) {
      const currentTime = new Date().getTime();
      const expirationTime = currentTime + 3 * 60 * 1000; // 3 minutes in milliseconds
    
      localStorage.setItem(`timerStart${invoiceData?.invoice_id}`, currentTime);
      localStorage.setItem(`timerExpiration${invoiceData?.invoice_id}`, expirationTime);
    }
  };
  
  const isTimerExpired = () => {
    const currentTime = new Date().getTime();
    const expirationTime = parseInt(localStorage.getItem(`timerExpiration${invoiceData?.invoice_id}`), 10) || 0;
  
    return currentTime > expirationTime;
  };

  useEffect(() => {
    setTimerPrevious();
    const currentTime = new Date().getTime();
    const expirationTime = parseInt(localStorage.getItem(`timerExpiration${invoiceData?.invoice_id}`), 10) || 0;
  
    if (expirationTime - currentTime > 0) setTimer(parseInt((expirationTime - currentTime) / 1000, 10));
    else setTimer(180);
    // handleStart();

    return () => {
      const timerExpired = isTimerExpired();
      console.log(timerExpired);
    };
  }, []);

  useEffect(() => {
    let countdown;

    // Update the timer every second
    if (isTimerActive && timer > 0) {
      countdown = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }

    // Clear the interval when the timer reaches 0
    if (timer === 0) {
      setIsTimerActive(false);
      clearInterval(countdown);
    }

    return () => {
      clearInterval(countdown);
    };
  }, [isTimerActive, timer]);

  const handleStart = () => {
    startTimer();
  };

  const handleResend = () => {
    // Perform OTP resend logic here
    // Start the timer when the resend button is clicked
    setTimer(180);
    startTimer();
  };

  const handleResendAndOTPView = () => {
    handleResend();
    handleResendOTPView();
  };

  const onKeyDown = (e) => {
    if (e.key === "Enter" && active) {
      handleOTPViewNow();
    }
  };

  return (
    <div className={`${classes.typeOtpContainer}`} style={{ zIndex: 100000, width: "100%", left: "0px",
    //  height: "calc(84vh - 100px)",
      borderRadius: "12px" }}>
        <div className={clsx(classes.typeOtpBox, (window.innerWidth > 600 ? '' : 'rounded-[12px]'))} style={{ top: window.innerWidth < 600 && "44%", height: "fit-content" }}>
            <div className={`${classes.typeOtpBox1}`}>
                <span className={`${classes.typeOtpBox1Text}`}>View Invoice</span>
                <img src={crossImg} alt="" className={`${classes.crossImg}`} onClick={handleOTPViewCross}/>
            </div>
            <div className={`${classes.typeOtpBox2}`}>
                <div className={`${classes.typeOtpBox2A}`}>
                    <span className={`${classes.typeOtpBox2AText}`}>This invoice is OTP-protected. Please enter the OTP you've received on your on your email : {invoiceData?.user_email}</span>
                </div>
                <div className={`${classes.typeOtpBox2B}`} onKeyDown={onKeyDown} tabIndex={-1} style={{ padding: "0 40px", outline: "none" }}>
                    <span className={`${classes.typeOtpBox2BText}`}>Enter OTP</span>
                    <div className={`${classes.otpDigits}`}>
                        {otp?.map((digit, index) => (
                        <input
                            key={index}
                            type="text"
                            value={digit}
                            onChange={(e) => handleOtpInput(e, index)}
                            onKeyDown={(e) => handleOtpInput(e, index)}
                            ref={(el) => (otpInputs.current[index] = el)} // Store references to input elements
                            maxLength={1}// Limit input to a single digit
                            className={`${classes.otpDigit1}`}
                        />
                        ))}
                    </div>
                    {otpErrorState?.error &&  <span className={`${classes.typeOtpBox2BTextActive}`}>{otpErrorState?.title}</span>}
                    {presignedUrlErrorState?.error &&  <span className={`${classes.typeOtpBox2BTextActive}`}>{presignedUrlErrorState?.title}</span>}
                </div>
                <div
                  className={`${classes.typeOtpBox2C}`}
                  onClick={() => {
                    if (active) {
                      handleOTPViewNow()
                    }
                  }}
                  onKeyDown={onKeyDown}
                  tabIndex={-1}
                  style={{ outline: "none" }}
                >
                    <div className={`${classes.typeOtpBox2C1}`} style={active ? {background : "#181C30" }: {} }>
                        <div className={`${classes.typeOtpBox2c2}`}>
                            <span className={`${classes.typeOtpBox2c2Text}`} style={active ? {background : "#181C30" }: {} } >View Now</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`${classes.typeOtpBox3}`}>
                <span className={`${classes.typeOtpBox3Text}`} style={isTimerActive ? {color:'#FFF'} : {}}>Didn't receive OTP? <p className={`${classes.resend}`} style={isTimerActive ? {color:'#FFF'} : {}} onClick={handleResendAndOTPView} >Resend</p></span>
                { isTimerActive && 
                    (<div className={`${classes.counterContainer}`}>
                        <div className={`${classes.counterBox}`}>
                            <div className={`${classes.counterImg}`}>
                                <img src={rightTickImg} alt="" className={`${classes.rightTickImg}`}/>
                            </div>
                            <span className={`${classes.counterText}`}>{`OTP Sent (${timer} seconds)`}</span>
                        </div>
                    </div>)
                }
            </div>
        </div>
    </div>
  )
}

export default TypeOtp