import classes from 'Components/Global/ReconcileBankDetails.module.scss'
import clsx from 'clsx'
// import RightIcon from 'assets/Icons/chevron-right-white.svg'
import Button from 'Layout/Button';
import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { formatNumberToLocaleString } from 'hooks/formatNumberToLocaleString';

const ReconcileBankDetails = ({ allBalances }) => {
  const buttonRef = useRef(null)
  const navigate = useNavigate();

  return (
    <div className="-sm:hidden mb-[8px]" onClick={() => { navigate("/bank-accounts") }}>
      <>
        <div className={clsx(classes.uploaderBox)}>
            <div className={clsx(classes.dataLeft)}>
                <div className={clsx(classes.combineBoth)}>
                  {
                    allBalances &&
                      Object.entries(allBalances)
                        .filter(([currency, { balance }]) => balance !== null && balance > 0 && currency !== '')
                        .map(([currency, { balance }]) => (
                          <p key={currency} className={clsx(classes.invoicesText, 'font-[400]')}>
                            <span className='font-[600] text-[#1E333F]'>
                              {allBalances[currency]?.currencyDTO?.sign || currency}
                              {formatNumberToLocaleString(balance, 2, 2)}
                            </span> available in your global {currency} virtual bank account
                          </p>
                        ))
                  }
                </div>
            </div>
            <Button
                text="Reconcile Now"
                ref={buttonRef}
                btnClass={clsx('h-[40px] py-[8px] px-[16px] text-[16px] min-w-[135px]')}
                btnClassText={clsx(`!leading-[24px] !h-[24px] font-[600]`)}
                // onClick={() => { navigate("/bank-accounts") }}
            />
        </div>
      </>
    </div>
  )
}

export default ReconcileBankDetails