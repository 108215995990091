// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DropdownLayout_dropdown_layout__\\+5wVo {
  background-color: #FFFFFF;
  box-shadow: 0px 0.4px 3.2rem rgba(30, 51, 63, 0.12);
  border-radius: 1.2rem;
  z-index: 10;
}`, "",{"version":3,"sources":["webpack://./src/Components/Dropdown/DropdownLayout.module.scss"],"names":[],"mappings":"AAAA;EACI,yBAAA;EACA,mDAAA;EACA,qBAAA;EACA,WAAA;AACJ","sourcesContent":[".dropdown_layout{\n    background-color: #FFFFFF;\n    box-shadow: 0px 0.4px 3.2rem rgba(30, 51, 63, 0.12);\n    border-radius: 1.2rem;\n    z-index: 10;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dropdown_layout": `DropdownLayout_dropdown_layout__+5wVo`
};
export default ___CSS_LOADER_EXPORT___;
