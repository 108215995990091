import React, { useEffect, useState } from 'react';
import classes from "./profileComponents.module.scss";
import attachDoc from "assets/Icons/attachDoc.svg";
import downloadCloud from "assets/Icons/downloadCloud.svg";
import CheckmarkIcon from "assets/Icons/greenCheck.svg";
import SpinnerIcon from "assets/Icons/spinnerIcon.svg";
import UseApiCall from 'hooks/useApiCall';
import { fetchFileFromS3 } from 'services';

const KycContent = ({text1,text2,url}) => {

  const [downloading, setDownloading] = useState(false);
  const [downloaded, setDownloaded] = useState(false);
  const [pdf,setPdf] = useState([])

  const handleDownload = () => {
    if(url){setDownloading(true);

    // Simulate download process (can be replaced with actual download logic)
    setTimeout(() => {
      setDownloading(false);
      setDownloaded(true);

      // Reset the green checkmark after a few seconds (optional)
      setTimeout(() => {
        setDownloaded(false);
      }, 1000);
    }, 2000);}
  };

  const [getFilesFromS3, fetchingFiles] = UseApiCall(
    fetchFileFromS3,
    async (res, headers) => {
      try {
        var blob = new Blob([res], { type: headers['content-type'] })
        let link = window.URL.createObjectURL(blob);
        let blobSize = (blob.size / 1024).toFixed(1) + ' KB';
        if(Math.floor(blobSize) > 1025) blobSize = (blobSize / 1024).toFixed(1) + ' MB';
          
        setPdf(
              {
                url: link,
                type: headers['content-type'],
                size: blobSize || '',
              },
              )
      } catch (error) {
        console.log(error)
      }
    },
    (err)=> {},
    'direct'
  )

  useEffect(() => {
    getFilesFromS3(url)
  }, [])

  return (
    <div className={`${classes.kycContent}`}>
        <div className={`${classes.kycContentLeft}`}>
            <div className={`${classes.kycContentLeftOne}`}>
                <img src={attachDoc} alt='documents' className={`${classes.kycContentLeftOneImg}`} />
            </div>
            <div className={`${classes.kycContentLeftTwo}`}>
                <span className={`${classes.kycContentLeftTwoText1}`}>{`${text1}${pdf?.type?.split('/')[1] || ''}`}</span>
                <span className={`${classes.kycContentLeftTwoText2}`}>{pdf.size || '0 KB'}</span>
            </div>
        </div>
        <div className={`${classes.kycContentRight}`}>
            <a 
              href={pdf?.url}
              download={`${text1}_downloads.${
                pdf?.type?.split('/')[1]
              }`}
               onClick={handleDownload}
             >
                { !downloading && !downloaded  && <img src={downloadCloud} alt='' className={`${classes.kycContentRightImg}`} />}
                {downloading && url && 
                <div className={`${classes.downloadSpinner}`}></div>
                }
                {downloaded && url && <img src={CheckmarkIcon} alt="Downloaded"  className={`${classes.kycContentRightImg}`}/>}
            </a>
        </div>
    </div>
  )
}

export default KycContent