import classes from "./UserDetails.module.scss";
import { modalActions } from "Store/modal-redux";
import { useDispatch, useSelector } from "react-redux";
import Modal from "Layout/Modal";
import checkIcon from "assets/Icons/check.svg"
import useClickOutside from "hooks/useClickOutside";
import Close from "Layout/Close";
import ReactDOM from "react-dom";
import Backdrop from 'Layout/Backdrop'
import ContactInfo from "Components/Global/ContactInfo";
import INRFlag from 'assets/Images/INR_Flag.svg';
import clsx from 'clsx'
import { useState ,useEffect} from "react";
// import Close from "Layout/Close";

const UserDetails = () =>{
    const dispatch = useDispatch();
    const showUserDetails = useSelector((state) => state.modal.showUserDetails);

    return(
        <Modal
        className={clsx(
          classes.modal,
          'h-[100vh] -sm:h-[calc(100vh-7.2rem)] px-40px py-0px w-screen md:w-[100%] xl:w-auto z-[100]'
        )}>
          {
            showUserDetails && 
            ReactDOM.createPortal(
                <Backdrop
                  className={classes.backdrop}
                  onClick={() => {
                    dispatch(modalActions.showUserDetails(false))
                  }}
                />,
                document.getElementById('backdrop-root')
            )
          }
          <div className={classes.bigCont}>
            <div className={classes.topBox}>
                <div className={classes.head}>
                    <div className={classes.leftHead}>
                      <h1 className={classes.userHead}>Rahul Sahni</h1>
                      <p className={classes.userRole}>Admin</p>
                    </div>
                    <div className={classes.rightHead}>
                      {/* <Close
                        onClick={() => dispatch(modalActions.showUserDetails(false))}
                        className={classes.close}
                      /> */}
                    </div>
                </div>

            </div>
            <div className={classes.editBox}>

            </div>
          </div>
        </Modal>
    )
}

export default UserDetails