import PaymentStatus from "../PaymentStatus/PaymentStatus";

const PaymentStatusTabContent = (props) => {
    const {transaction_rail_details, invoice_status, invoice_id} = props.invoice;
    // console.log('props.invoice',props.invoice)
    return (
        <PaymentStatus recent_transaction_response={transaction_rail_details.recent_transaction_response} invoice_status={invoice_status} invoiceId={invoice_id}/>
    )
}

export default PaymentStatusTabContent;