// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TourStartHeader_bigCont__joQvg {
  width: 280px;
  padding: 24px 20px;
  background: #FDEED1;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  border-radius: 12px;
}
.TourStartHeader_bigCont__joQvg .TourStartHeader_contentBox__evUXl {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.TourStartHeader_bigCont__joQvg .TourStartHeader_contentBox__evUXl .TourStartHeader_heading__WcCM9 {
  color: #61450B;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px; /* 150% */
  letter-spacing: -0.32px;
  align-self: stretch;
}
.TourStartHeader_bigCont__joQvg .TourStartHeader_contentBox__evUXl .TourStartHeader_para__OdQXL {
  color: #61450B;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px; /* 150% */
  align-self: stretch;
}
.TourStartHeader_bigCont__joQvg .TourStartHeader_buttonBox__ZEr4x {
  display: flex;
  width: 232px;
  height: 48px;
  padding: 12px 0px;
  justify-content: center;
  align-items: center;
  gap: 4px;
  border-radius: 8px;
  border: 1px solid #61450B;
  color: #61450B;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px; /* 150% */
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/Components/Tour/TourStartHeader.module.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,kBAAA;EACA,mBAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;EACA,mBAAA;EACA,mBAAA;AACF;AACE;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;AACJ;AACI;EACE,cAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA,EAAA,SAAA;EACA,uBAAA;EACA,mBAAA;AACN;AAEI;EACE,cAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA,EAAA,SAAA;EACA,mBAAA;AAAN;AAIE;EACE,aAAA;EACA,YAAA;EACA,YAAA;EACA,iBAAA;EACA,uBAAA;EACA,mBAAA;EACA,QAAA;EACA,kBAAA;EACA,yBAAA;EACA,cAAA;EACA,kBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA,EAAA,SAAA;EACA,eAAA;AAFJ","sourcesContent":[".bigCont{\n  width: 280px;\n  padding: 24px 20px;\n  background: #FDEED1;\n  display: flex;\n  flex-direction: column;\n  gap: 16px;\n  align-items: center;\n  border-radius: 12px;\n\n  .contentBox{\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n\n    .heading{\n      color: #61450B;\n      font-size: 16px;\n      font-weight: 500;\n      line-height: 24px; /* 150% */\n      letter-spacing: -0.32px;\n      align-self: stretch;\n    }\n    \n    .para{\n      color: #61450B;\n      font-size: 16px;\n      font-weight: 400;\n      line-height: 24px; /* 150% */\n      align-self: stretch;\n    }\n  }\n\n  .buttonBox{\n    display: flex;\n    width: 232px;\n    height: 48px;\n    padding: 12px 0px;\n    justify-content: center;\n    align-items: center;\n    gap: 4px;\n    border-radius: 8px;\n    border: 1px solid #61450B;\n    color: #61450B;\n    text-align: center;\n    font-size: 16px;\n    font-weight: 600;\n    line-height: 24px; /* 150% */\n    cursor: pointer;\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bigCont": `TourStartHeader_bigCont__joQvg`,
	"contentBox": `TourStartHeader_contentBox__evUXl`,
	"heading": `TourStartHeader_heading__WcCM9`,
	"para": `TourStartHeader_para__OdQXL`,
	"buttonBox": `TourStartHeader_buttonBox__ZEr4x`
};
export default ___CSS_LOADER_EXPORT___;
