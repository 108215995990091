import PageIntro from 'Components/Global/PageIntro';
import classes from 'Pages/Admin/Vendors.module.scss';
import briefcase from 'assets/Images/briefcase-white.svg';
import { useDispatch, useSelector } from 'react-redux';
import arrowUp from 'assets/Images/arrow-up.svg';
import { modalActions } from 'Store/modal-redux';
import ReactDOM from 'react-dom';
import AddContact from 'Components/General/AddContact';
import SendPayment from "Components/Payment/SendPayment";
import TransactionDetails from "Components/Global/TransactionDetails";
import Status from 'Components/ReportTable/Status';
import invoiceCreated from 'assets/Images/invoice_created.svg';
import invoiceSettled from 'assets/Images/invoice_settled.svg';
import invoiceInprogress from 'assets/Images/invoice_inprogress.svg';
import invoicePending from 'assets/Images/invoice_pending.svg';
import Button from 'Layout/Button';
import VendorReportTable from 'Components/ReportTable/VendorReportTable';
import ReportPageFilter from "Components/Global/ReportPageFilter";

const transactionData = [
    {
        id : 1,
        money : '$20,000',
        status : <Status status={"requested"}/>,
        icon : invoiceCreated,
        title : `Invoices`,
        amount : '25'
    },
    {
        id : 2,
        money : '$12,000',
        status : <Status status={'in progress'}/>,
        icon : invoiceSettled,
        title : 'Invoices',
        amount : '12'
    },
    {
        id : 3,
        money : '$5,000',
        status : <Status status={'paid'}/>,
        icon : invoiceInprogress,
        title : `Invoices`,
        amount : `10`
    },
    {
        id : 4,
        money : '$3,000',
        status: <Status status={'refunded'}/>,
        icon : invoicePending,
        title : `Invoices payment`,
        amount : '3'
    },
]

const tableData = [
    {
        invoice : "#123456",
        name : "Rahul Sahni",
        email : "rahul@gmail.com",
        country : "UK",
        amount : "$300",
        status : "requested",
        lastUpdate : "05/12/2022",
    },
    {
        invoice : "#123456",
        name : "Rahul Sahni",
        email : "rahul@gmail.com",
        country : "UK",
        amount : "$300",
        status : "in progress",
        lastUpdate : "05/12/2022",
    },
    {
        invoice : "#123456",
        name : "Rahul Sahni",
        email : "rahul@gmail.com",
        country : "UK",
        amount : "$300",
        status : "paid",
        lastUpdate : "05/12/2022",
    },
    {
        invoice : "#123456",
        name : "Rahul Sahni",
        email : "rahul@gmail.com",
        country : "UK",
        amount : "$300",
        status : "refunded",
        lastUpdate : "05/12/2022",
    },
    {
        invoice : "#123456",
        name : "Rahul Sahni",
        email : "rahul@gmail.com",
        country : "UK",
        amount : "$300",
        status : "failed",
        lastUpdate : "05/12/2022",
    },
    {
        invoice : "#123456",
        name : "Rahul Sahni",
        email : "rahul@gmail.com",
        country : "UK",
        amount : "$300",
        status : "requested",
        lastUpdate : "05/12/2022",
    },
    {
        invoice : "#123456",
        name : "Rahul Sahni",
        email : "rahul@gmail.com",
        country : "UK",
        amount : "$300",
        status : "in progress",
        lastUpdate : "05/12/2022",
    },
    {
        invoice : "#123456",
        name : "Rahul Sahni",
        email : "rahul@gmail.com",
        country : "UK",
        amount : "$300",
        status : "paid",
        lastUpdate : "05/12/2022",
    },
    {
        invoice : "#123456",
        name : "Rahul Sahni",
        email : "rahul@gmail.com",
        country : "UK",
        amount : "$300",
        status : "refunded",
        lastUpdate : "05/12/2022",
    },
    {
        invoice : "#123456",
        name : "Rahul Sahni",
        email : "rahul@gmail.com",
        country : "UK",
        amount : "$300",
        status : "failed",
        lastUpdate : "05/12/2022",
    },
    {
        invoice : "#123456",
        name : "Rahul Sahni",
        email : "rahul@gmail.com",
        country : "UK",
        amount : "$300",
        status : "requested",
        lastUpdate : "05/12/2022",
    },
    {
        invoice : "#123456",
        name : "Rahul Sahni",
        email : "rahul@gmail.com",
        country : "UK",
        amount : "$300",
        status : "in progress",
        lastUpdate : "05/12/2022",
    },
    {
        invoice : "#123456",
        name : "Rahul Sahni",
        email : "rahul@gmail.com",
        country : "UK",
        amount : "$300",
        status : "paid",
        lastUpdate : "05/12/2022",
    },
    {
        invoice : "#123456",
        name : "Rahul Sahni",
        email : "rahul@gmail.com",
        country : "UK",
        amount : "$300",
        status : "refunded",
        lastUpdate : "05/12/2022",
    },
    {
        invoice : "#123456",
        name : "Rahul Sahni",
        email : "rahul@gmail.com",
        country : "UK",
        amount : "$300",
        status : "failed",
        lastUpdate : "05/12/2022",
    },
];

const mobileTableData = [
    {
        name: "Rahul",
        invoice: "#123456",
        country: "UK",
        email: "Last updated: 05/12/2022",
        amount : "$300",
        status : "paid"
    },
    {
        name: "Rahul",
        invoice: "#123456",
        country: "UK",
        email: "Last updated: 05/12/2022",
        amount : "$300",
        status : "requested"
    },
    {
        name: "Rahul",
        invoice: "#123456",
        country: "UK",
        email: "Last updated: 05/12/2022",
        amount : "$300",
        status : "in progress"
    },
    {
        name: "Rahul",
        invoice: "#123456",
        country: "UK",
        email: "Last updated: 05/12/2022",
        amount : "$300",
        status : "refunded"
    },
    {
        name: "Rahul",
        invoice: "#123456",
        country: "UK",
        email: "Last updated: 05/12/2022",
        amount : "$300",
        status : "failed"
    },
    {
        name: "Rahul",
        invoice: "#123456",
        country: "UK",
        email: "Last updated: 05/12/2022",
        amount : "$300",
        status : "paid"
    },
    {
        name: "Rahul",
        invoice: "#123456",
        country: "UK",
        email: "Last updated: 05/12/2022",
        amount : "$300",
        status : "requested"
    },
    {
        name: "Rahul",
        invoice: "#123456",
        country: "UK",
        email: "Last updated: 05/12/2022",
        amount : "$300",
        status : "in progress"
    },
    {
        name: "Rahul",
        invoice: "#123456",
        country: "UK",
        email: "Last updated: 05/12/2022",
        amount : "$300",
        status : "refunded"
    },
    {
        name: "Rahul",
        invoice: "#123456",
        country: "UK",
        email: "Last updated: 05/12/2022",
        amount : "$300",
        status : "failed"
    },
    {
        name: "Rahul",
        invoice: "#123456",
        country: "UK",
        email: "Last updated: 05/12/2022",
        amount : "$300",
        status : "paid"
    },
    {
        name: "Rahul",
        invoice: "#123456",
        country: "UK",
        email: "Last updated: 05/12/2022",
        amount : "$300",
        status : "requested"
    },
    {
        name: "Rahul",
        invoice: "#123456",
        country: "UK",
        email: "Last updated: 05/12/2022",
        amount : "$300",
        status : "in progress"
    },
    {
        name: "Rahul",
        invoice: "#123456",
        country: "UK",
        email: "Last updated: 05/12/2022",
        amount : "$300",
        status : "refunded"
    },
    {
        name: "Rahul",
        invoice: "#123456",
        country: "UK",
        email: "Last updated: 05/12/2022",
        amount : "$300",
        status : "failed"
    },
];

const tableHeader = ["vendor id", "vendor details", "amount", "status", "last Updated"];

const Vendors = ( ) => {
    return (
        <div className={classes.vendors}>
            <div className={classes.text}>Coming Soon.</div>
            {/* <Button text="Get instant credit" className={classes.button} disabled={true}/> */}
        </div>
    )
    /*const dispatch = useDispatch();
    const sendPaymentDisplay = useSelector(state => state.modal.sendPaymentDisplay);
    const addContactDisplay = useSelector(state => state.modal.addContactDisplay);

    const arrBtn = [
        {
            text: 'Send Payment',
            icon: arrowUp,
            onClick : function() {dispatch(modalActions.sendPayment())}
        },
        {
            text: 'Add Vendor',
            icon: briefcase,
            onClick : function() {dispatch(modalActions.addContact())}
        }
    ]
    
    return (
        <div className={classes.vendors}>
            {sendPaymentDisplay && ReactDOM.createPortal(<SendPayment /> , document.getElementById('modal-root'))}
            {addContactDisplay && ReactDOM.createPortal(
            <AddContact 
                contactType={'vendor'}
                nextPageFunctionality = {()=>{dispatch(modalActions.addContact())}}
                addContactURL= {''}
            />, 
            document.getElementById('modal-root'))}
            <PageIntro 
                pageName={'Vendors'} 
                message={'View, create & edit vendors'}
                arrBtn={arrBtn}
            />  
            {/!*<TransactionDetails transactionData={transactionData} />*!/}
            <ReportPageFilter searchText="Search by vendor name/transaction id" download={true} sendReminder={false} filter={true}/>
            <VendorReportTable tableHeader={tableHeader} tableData={tableData} mobileTableData={mobileTableData}/>
            <div className={classes.button}>
                {arrBtn.map(arr => <Button key={arr.text} text={arr.text} onClick={arr.onClick} className={classes.btn}/>)}
            </div>
        </div>
    )*/
}
export default Vendors;