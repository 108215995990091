import ReactDOM from 'react-dom'
import classes from 'Components/Payment/PaymentDetails.module.scss'
// import Status from 'Components/ReportTable/PaymentStatusTag'
// import useClickOutside from 'hooks/useClickOutside';
// import chevronDown from 'assets/Images/chevron-down.svg'
import PencilIcon from 'assets/Icons/pencil.svg'
import InfoIcon from 'assets/Icons/infoIcon.svg'
import Button from 'Layout/Button'
import EditIcon from 'assets/Icons/editBlack.svg'
import dayjs from 'dayjs'
import { useState, useEffect } from 'react'
import ForexExchange from './ForexExchange'
import clsx from 'clsx'
// import PaymentStatusTag from 'Components/ReportTable/PaymentStatusTag'
import FileIcon from 'assets/Images/verification-file-white-yellow.svg'
// import InfoIcon from 'assets/Icons/info_brown_circle.svg'
import { useDispatch, useSelector } from 'react-redux'
import { modalActions } from 'Store/modal-redux'
import EditInvoice from "Components/Invoice/EditInvoice"
// import CrossIcon from 'assets/Icons/cross_black.svg'
import arrowUpIcon from 'assets/Icons/arrow-up.svg'
import arrowDownIcon from 'assets/Icons/arrow-DownIcon.svg'
import DownloadIcon from 'assets/Icons/download-cloud.svg'
import { apiDownloadFiraReportWithTxnId, apiDownloadFiraReportUrl } from 'services'
import UseApiCall from 'hooks/useApiCall'
import { formatNumberToLocaleString } from 'hooks/formatNumberToLocaleString';
import { enqueueSnackbar } from 'notistack'
import { Tooltip } from 'antd'
import login from "../../Pages/Authentication/Login";

const paymentArray = [
  "1st",
  "2nd",
  "3rd",
  "4th",
  "5th",
  "6th",
  "7th",
  "8th",
  "9th",
  "10th",
  "11th",
  "12th",
  "13th",
  "14th",
  "15th",
  "16th",
  "17th",
  "18th",
  "19th",
  "20th",
  "21st",
  "22nd",
  "23rd",
  "24th",
  "25th",
  "26th",
  "27th",
  "28th",
  "29th",
  "30th",
  "31st",
  "32nd",
  "33rd",
  "34th",
  "35th",
  "36th",
  "37th",
  "38th",
  "39th",
  "40th",
];

const PaymentDetails = (props) => {
  const dispatch = useDispatch()
  const editInvoiceDisplay = useSelector((state) => state.modal.editInvoiceDisplay)
  const [windowSize, setWindowSize] = useState(false);
  // const [isCreatedBy, setIsCreatedBy] = useState(false);
  const [paidOutsideOrCancelled, setPaidOutsideOrCancelled] = useState(false);
  const [checkOtherRailStepStarted, setCheckOtherRailStepStarted] = useState(false);
  const [showFiraReport, setShowFiraReport] = useState(false);
  const [detailsLength, setDetailsLength] = useState(3);
  const [paidOutsideArray, setPaidOutsideArray] = useState([]);
  const [userTimezoneOffset, setUserTimezoneOffset] = useState(new Date().getTimezoneOffset());

  const [downloadFiraReportTxnId] = UseApiCall(
    apiDownloadFiraReportWithTxnId,
    (res) => {
      let url = res?.data
      window.location.assign(url)
    }, () => {
      enqueueSnackbar(`Error in downloading Fira Report.`, { variant: 'error' })
    }
  )

  const [downloadFiraReport] = UseApiCall(
    apiDownloadFiraReportUrl,
    (res) => {
      let url = res?.data
      window.location.assign(url);
    }, () => {
      enqueueSnackbar(`Error in downloading Fira Report.`, { variant: 'error' })
    }
  )

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  //   const [showTransactionInfo, setShowTransactionInfo] = useState(false);
  const { transactionData, showHeader = true, smallestIndex = 10, invoice_id } = props

  const { payment_details, transaction_info_list, payment_info_list } = transactionData?.transactions_detail || {}
  const [openDetailsBox, setOpenDetailsBox] = useState(payment_info_list?.length > 0 ? false : true);
  const [openPaymentBox, setOpenPaymentBox] = useState(payment_info_list?.length - 1);
  const targetValues = ["INVOICE_CANCELLED", "INVOICE_SETTLED_OUTSIDE_FIDEO"];

  useEffect(() => {
    setPaidOutsideOrCancelled(false);
    setPaidOutsideArray([]);
    transaction_info_list?.filter((item) => item?.is_processed)?.map((trans) => {
      if (targetValues?.includes(trans?.transaction_action)) {
        setPaidOutsideOrCancelled(true);
        setPaidOutsideArray(transaction_info_list?.filter((item) => item?.is_processed && targetValues?.includes(item?.transaction_action)));
      }
    })
  }, [transaction_info_list])

  useEffect(() => {
    if (payment_info_list?.length > 0) {
      let count = 0;
      payment_info_list && payment_info_list?.length > 0 && payment_info_list?.map((ite) => {
        if (ite?.transaction_info_list?.filter((item) => item?.transaction_action === "FIRA_REPORT_GENERATED")?.length > 0) count++;
      })
      setShowFiraReport(count > 0 ? true : false);
      setCheckOtherRailStepStarted(payment_info_list[0]?.transaction_info_list[0]?.is_processed);
      if (payment_info_list[0]?.transaction_info_list[0]?.is_processed) setDetailsLength(transaction_info_list?.filter((item) => item?.is_processed && !targetValues?.includes(item?.transaction_action))?.length);
      else {
        if (transaction_info_list?.filter((item) => !item?.is_processed && targetValues?.includes(item?.transaction_action))?.length === 2) {
          setDetailsLength(3);
        } else {
          setDetailsLength(transaction_info_list?.filter((item) => item?.is_processed));
        }
      }
    } else if (payment_info_list?.length === 0) {
      setDetailsLength(transaction_info_list?.filter((item) => item?.is_processed && !targetValues?.includes(item?.transaction_action))?.length === 1 ? 2 : transaction_info_list?.filter((item) => item?.is_processed && !targetValues?.includes(item?.transaction_action))?.length);
      setCheckOtherRailStepStarted(true);
    }
  }, [payment_info_list])
  // console.log(paidOutsideArray, transactionData?.status, showHeader, smallestIndex, transaction_info_list, payment_info_list, paidOutsideOrCancelled, detailsLength, "xxxxxxxx")
  // const tooltipRef = useClickOutside(() => setShowTransactionInfo(false));

  // the logic for loader status is not written for now
  // for now only we are manually setting the position of loader for created, in progress and settled status.

  const getLoaderState = (isCompleted, checkFailedAction) => {
    if (isCompleted) {
      if (targetValues.includes(checkFailedAction)) {
        return {
          loaderCircle: classes.loader_notCompleted,
          loader: classes.loader,
        }
      }
      return {
        loaderCircle: classes.loader_completed,
        loader: classes.loader,
      }
    }
    return {
      loaderCircle: classes.loader_inProgress,
      loader: classes.loader,
    }
  }

  const getAdjustedRail = (rail) => {
    const adjustedValueAvailable = rail && rail?.length > 0 && rail?.filter((item) => item?.transaction_action === "AMOUNT_ADJUSTED")?.length;
    return adjustedValueAvailable === 1 ? true : false;
  }

  const getAdjustedCount = (railArray, index) => {
    let count = 0;
    railArray && railArray?.length > 0 && railArray?.map((arr, ind) => {
      if (index >= ind) {
        const len = arr?.transaction_info_list?.filter((item) => item?.transaction_action === "AMOUNT_ADJUSTED")?.length;
        if (len > 0) count++;
      }
    });
    return count;
  }

  // const renderVerificationState = () => {
  //   return (
  //     <div className="backdrop-blur-xl border border-[#ECEFF2] flex items-center justify-center rounded-[12px]  absolute top-0 right-0 left-0 bottom-0">
  //       <div className="w-[80%]">
  //         <div className="flex flex-col items-center">
  //           <div className="w-[88px] h-[88px] mb-5 flex items-center justify-center rounded-full bg-[#DBFD00]">
  //             <img src={FileIcon} alt="User" className="w-20 h-20" />
  //           </div>
  //           <div className="relative flex flex-row items-center  text-[12px] text-[#8E6C28] -top-8 px-[12px] py-1 bg-[#FFF4DE] rounded-full ">
  //             <img src={InfoIcon} alt="i" className="w-[28px] aspect-square" />
  //             <p>UNDER REVIEW</p>
  //           </div>
  //         </div>
  //         <p className="font-normal text-center text-[#181C30]" style={{
  //           fontSize: 16,
  //         }}>
  //         Once invoice is verified, you can then send it to your customers and track payment status. It will take up to 1 hour for verification and Fideo team may make some edits if required.
  //         </p>
  //       </div>
  //     </div>
  //   )
  // }

  const handleReportDownload = async (txnId) => {
    if (txnId === "" || txnId === null) {
      await downloadFiraReport({ invoiceId: invoice_id })
    } else {
      await downloadFiraReportTxnId({ invoiceId: invoice_id, txnId })
    }
  }

  // const isCreatedByNull = (paymentInfo) => {
  //   return paymentInfo.transaction_info_list.every((info) => info.created_by === null);
  // };

  const isCreatedByTally = (transaction_info_list) => {
    // console.log('transaction_info_list',transaction_info_list)
    return transaction_info_list.some(item => item.created_by === "fideo-tally-service");
    // return transaction_info_list.every((info) => info.created_by?.toLowerCase() === "fideo-tally-service");
    // let hh = transaction_info_list.every((info) => info.created_by === null);
    // console.log('hh',hh)
  };

// Check for each payment info in the payment_info_list array


  // useEffect(() => {
  //   payment_info_list && payment_info_list?.forEach((paymentInfo, index) => {
  //     const isNull = isCreatedByNull(paymentInfo);
  //     setIsCreatedBy(isNull)
  //   });
  // }, [payment_info_list]);

  // console.log(`isCreatedBy`,isCreatedBy);
  // console.log('paymentDetails-props',props)


  return (
    <div className={`${classes.paymentStatus} ${props.className}`}>
      {editInvoiceDisplay &&
        ReactDOM.createPortal(
          <EditInvoice
            selectedInvoiceId={transactionData?.id}
            nextPageFunctionality={() => dispatch(modalActions.removeAll())}
          />,
          document.getElementById("modal-root")
      )}
      {showHeader && (
        <div className={classes.header} style={{ marginBottom: transactionData?.status?.toLowerCase() === "in draft" && "24px" }}>
          <div className={classes.left}>
            <div className={classes.heading}>Payment Status</div>
            {/* <div className={classes.message}>{message}</div> */}
          </div>
          { transactionData?.status?.toLowerCase() !== "in draft" &&
          // <PaymentStatusTag status={transactionData?.status} withIcon={true} />
          <div className='flex flex-col'>
            <div className={clsx(classes.steps)}>
              <div className={classes.tracking}>
                <div className={classes.loader}>
                  <div className={classes.loader_completed}></div>
                </div>
              </div>
              <div className={classes.stepsTextInfo}>Completed Steps</div>
            </div>
            <div className={clsx(classes.steps)}>
              <div className={classes.tracking}>
                <div className={classes.loader}>
                  <div className={classes.loader_inProgress}></div>
                </div>
              </div>
              <div className={classes.stepsTextInfo}>Pending Steps</div>
            </div>
          </div>
          }
        </div>
      )}
      {
        transactionData?.status?.toLowerCase() !== "in draft"
        ?
          <>
          { (payment_details?.receiving_amount !== undefined && payment_details?.receiving_amount !== null)
            ?
              <div className={clsx(classes.tracker, 'relative flex flex-col items-center')} style={{ height: transactionData?.invoice_status?.toLowerCase() === "in draft" && "100%" }}>
                {/* {transactionData?.invoice_status?.toLowerCase() === 'in review' &&
                  renderVerificationState()} */}
                  {/* {transactionData?.invoice_status?.toLowerCase() !== "in draft"
                  ? */}
                    <div className={clsx(classes.detailsBox)}>
                      <div className={clsx('w-[100%] flex', openDetailsBox ? 'mb-[12px]' : 'mb-[0px]')} onClick={() => {
                        openDetailsBox ? setOpenPaymentBox(payment_info_list?.length - 1) : setOpenPaymentBox(-1);
                        setOpenDetailsBox(!openDetailsBox);
                      }}>
                        <div className={clsx(classes.priceTextSubHead, 'flex flex-col w-[100%] gap-[4px]', 'items-start')}>
                          <div className={clsx(classes.priceTextInvoiceRail)}>
                            {payment_details?.invoice_currency?.sign}{formatNumberToLocaleString(payment_details?.receiving_amount, 2, 2)}
                          </div>
                          <div className={clsx('flex items-center')}>
                            {/* {<div className={clsx('rounded-full h-[8px] w-[8px] mr-[6px]')} style={{ backgroundColor: '#FF5151' }}></div>} */}
                            <div className={clsx(classes.priceTextSubHead, 'text-[12px]')}>
                              Receivable Amount
                            </div>
                          </div>
                        </div>
                        <div className={clsx(classes.priceTextSubHead, 'flex flex-col w-[100%] gap-[4px]', 'items-end')}>
                          <div className={clsx(classes.priceTextInvoiceRail)}>
                            {payment_details?.invoice_currency?.sign}{formatNumberToLocaleString(payment_details?.pending_amount, 2, 2)}
                          </div>
                          <div className={clsx('flex items-center')}>
                            {/* {<div className={clsx('rounded-full h-[8px] w-[8px] mr-[6px]')} style={{ backgroundColor: '#FF5151' }}></div>} */}
                            <div className={clsx(classes.priceTextSubHead, 'text-[12px]')}>
                              Pending Amount
                            </div>
                          </div>
                        </div>
                      </div>
                      { openDetailsBox &&
                        <div className={clsx(classes.subDetailsBox)}>
                          {transaction_info_list?.map((trans, index, arr) => {
                            if ((checkOtherRailStepStarted && trans?.is_processed && !targetValues.includes(trans?.transaction_action)) || (!checkOtherRailStepStarted && !targetValues.includes(trans?.transaction_action))) {
                              const loaderState = getLoaderState(trans?.is_processed, trans?.transaction_action)
                              return (
                                <div className={clsx(classes.steps)} key={trans?.invoice_id}>
                                  <div className={classes.tracking}>
                                    <div className={loaderState.loader}>
                                      <div className={loaderState.loaderCircle}></div>
                                    </div>
                                    {(index + 1 < Math.min(detailsLength)) && (
                                      <div className={classes.line}></div>
                                    )}
                                  </div>
                                  <div className={clsx(classes.content, 'flex flex-col')}>
                                    <div className={classes.title}>
                                      {!!trans?.title ? trans?.title : ''}
                                      {/* <div>Invoice created </div>
                                              <div className={classes.amount}>$10,000.00</div> */}
                                    </div>
                                    {trans?.created_time_stamp ? (
                                      <div className={classes.message}>
                                        {
                                          true
                                          ? dayjs(trans?.created_time_stamp)
                                            .utc()
                                            .utcOffset('+05:30')
                                            .format('D MMM YYYY, hh:mm a [IST]')
                                          : dayjs(trans?.created_time_stamp)
                                            .utc()
                                            .format('D MMM YYYY, hh:mm a [UTC]')
                                        }
                                      </div>
                                    ) : (
                                      <div className={classes.message} />
                                    )}
                                    <ForexExchange forexData={trans?.forex_conversion_details} />
                                  </div>
                                </div>
                              )
                            }
                          })}
                        </div>
                      }
                    </div>
                    <button className={classes.detailsButton} onClick={() => {
                        openDetailsBox ? setOpenPaymentBox(payment_info_list?.length - 1) : setOpenPaymentBox(-1);
                        setOpenDetailsBox(!openDetailsBox);
                      }}>
                      <div className='text-[#1E333F] text-[12px] leading-[20px] font-[500] font-inter'>Details</div>
                      <img src={openDetailsBox ? arrowUpIcon : arrowDownIcon} className={classes.imgIcon} alt="dropdown"/>
                    </button>

              </div>
            :
              <div
                style={{
                  textAlign: 'center',
                  width: '100%',
                  margin: '4rem 0',
                  fontSize: '2.5rem',
                  borderRadius: '12px',
                  boxShadow: '0px 4px 20px 0px rgba(76, 87, 125, 0.02)'
                }}
                className={'w-[100%] p-[16px] border-b-[1px] border-solid border-[#E6E9EF] bg-[#F6F8FB] rounded-[12px]'}
              >
                Unable to load payment rail.
              </div>
          }

            {
                // isCreatedBy &&
                payment_info_list?.map((payment_item, index, arrx) => {
                  const adjustedRail = false; // getAdjustedRail(payment_item?.transaction_info_list);
                  const adjustedCount = 0; // getAdjustedCount(arrx, index);
                  return (
                      <div key={index} className={clsx(classes.tracker, 'relative flex flex-col items-center')}
                           style={{height: transactionData?.invoice_status?.toLowerCase() === "in draft" && "100%"}}>
                        <div className={clsx(classes.paymentBox)}>
                          <div className={clsx('w-[100%] flex mb-[12px] justify-between gap-[2px]')}>
                            <div
                                className={clsx(classes.priceTextSubHead, classes.headFlow, 'flex w-[calc(100%-24px)] gap-[4px] items-start flex-wrap')}>
                              {
                                adjustedRail
                                ?
                                  <div className={clsx(classes.priceTextInvoiceRail)}>Payment Adjustment</div>
                                :
                                  (
                                    ((payment_info_list?.length <= 1 && payment_details?.pending_amount === 0) || (payment_info_list?.length <= 1 && (!payment_item?.transaction_info_list[0]?.is_processed)))
                                      ?
                                        <div className={clsx(classes.priceTextInvoiceRail)}>Payment Received</div>
                                      :
                                        <div className={clsx(classes.priceTextInvoiceRail)}>{`${paymentArray[index - adjustedCount]} Payment Received`}</div>
                                  )
                            }
                            {/* {payment_item?.transaction_id && <div className={clsx('flex items-center max-w-[132px]')} style={{ overflowWrap: "anywhere" }}>{`Txn ID: ${payment_item?.transaction_id}`}</div>} */}
                            {payment_item?.transaction_id && <div className={clsx(classes.txnIdText)}>{`Txn ID: ${payment_item?.transaction_id}`}</div>}
                          </div>
                          <div className='flex gap-[8px] items-center'>
                            <div className={clsx(windowSize > 1032 && 'h-[20px]', 'max-h-[20px] max-w-max w-[140px] flex justify-center items-center px-[8px] py-[2px] rounded-[100px] border-[1px] border-solid border-[#1E333F29]')}>
                              {payment_item?.payment_status?.split('_').join(' ')}
                            </div>
                            {
                              !adjustedRail && !isCreatedByTally(payment_info_list[index]?.transaction_info_list)
                                ?
                                  <div className={clsx('w-[24px] h-[24px] rounded-[4px] bg-[#EAEFF4] cursor-pointer')} onClick={() => {
                                    setOpenPaymentBox((prev) => prev === index ? -1 : index)
                                    setOpenDetailsBox(false)
                                  }}>
                                    <img src={openPaymentBox === index ? arrowUpIcon : arrowDownIcon} className={classes.imgIcon} alt="dropdown"/>
                                  </div>
                                :
                                  ''
                            }
                              
                          {
                            console.log('gdsh', index,isCreatedByTally(payment_info_list[index]?.transaction_info_list))
                          }
                          </div>
                        </div>
                        { (openPaymentBox === index) && !isCreatedByTally(payment_info_list[index]?.transaction_info_list)
                            ?
                              <div className={clsx('w-[100%] p-[16px] border-b-[1px] border-solid border-[#E6E9EF] bg-[#F6F8FB] rounded-[12px]')}>
                                {payment_item?.transaction_info_list?.map((trans, ind, arr) => {
                                  if (true) {
                                    const loaderState = getLoaderState(trans.is_processed, trans?.transaction_action)
                                    return (
                                      <div className={clsx(classes.steps)} key={trans?.invoice_id}>
                                        <div className={classes.tracking}>
                                          <div className={loaderState.loader}>
                                            <div className={loaderState.loaderCircle}></div>
                                          </div>
                                          {(ind + 1 < arr?.length) && (
                                            <div className={classes.line}></div>
                                          )}
                                        </div>
                                        <div className={clsx(classes.content, 'flex flex-col', (ind + 1 === arr?.length || ind === 0) && classes.iconHandled)} onClick={() => {
                                          if (ind === 0) {
                                            if (!adjustedRail) {
                                              setOpenPaymentBox((prev) => prev === index ? -1 : index)
                                              setOpenDetailsBox(false)
                                            }
                                          }
                                        }}>
                                          <div>
                                            <div className={classes.title}>
                                              {!!trans?.title ? trans?.title : ''}
                                              {
                                                trans?.transaction_action === "AMOUNT_ADJUSTED" && trans?.comments &&
                                                  <Tooltip
                                                    placement="top"
                                                    title={
                                                      <div>
                                                        {trans?.comments}
                                                      </div>
                                                    }
                                                    color="#1E333F"
                                                    // overlayInnerStyle={{ maxWidth: "240px", position: "relative", right: "30px" }}
                                                    // overlayStyle={{ fontSize: "14px", fontWeight: "300", lineHeight: "24px" }}
                                                  >
                                                    <img src={InfoIcon} alt="info" className="w-[18px] h-[18px] ml-[8px]" />
                                                  </Tooltip>
                                              }
                                              {/* <div>Invoice created </div>
                                                      <div className={classes.amount}>$10,000.00</div> */}
                                            </div>
                                            {trans?.created_time_stamp ? (
                                              <div className={classes.message}>
                                                {
                                                  true
                                                  ? dayjs(trans?.created_time_stamp)
                                                    .utc()
                                                    .utcOffset('+05:30')
                                                    .format('D MMM YYYY, hh:mm a [IST]')
                                                  : dayjs(trans?.created_time_stamp)
                                                    .utc()
                                                    .format('D MMM YYYY, hh:mm a [UTC]')
                                                }
                                                {(trans?.transaction_action === "PAYOUT_INITIATED" && payment_item?.payout_id) &&
                                                  <span>, Payout Id : {payment_item?.payout_id} </span> 
                                                }
                                              </div>
                                            ) : (
                                              <div className={classes.message} />
                                            )}
                                            <ForexExchange forexData={trans?.forex_conversion_details} />
                                          </div>
                                          <div>
                                            <div>
                                              {
                                                ind + 1 === arr?.length && showFiraReport && !adjustedRail &&
                                                  <button
                                                    disabled={!trans?.is_processed}
                                                    onClick={() => {
                                                      handleReportDownload(payment_item?.transaction_id)
                                                    }}
                                                  >
                                                    <img src={DownloadIcon} alt="download" style={{ opacity: trans?.is_processed ? '1' : '0.5', cursor: trans?.is_processed ? 'pointer' : 'not-allowed' }}/>
                                                  </button>
                                              }
                                              {/* {
                                                ind === 0 &&
                                                  <div className={clsx('flex justify-center items-center px-[8px] py-[2px] rounded-[100px] border-[1px] border-solid border-[#1E333F29]')}>
                                                    {payment_item?.payment_status?.split('_').join(' ')}
                                                  </div>
                                              } */}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    )
                                  }
                                })}
                              </div>
                            :
                              <div className={clsx('w-[100%] p-[16px] border-b-[1px] border-solid border-[#E6E9EF] bg-[#F6F8FB] rounded-[12px]')}>
                                {payment_item?.transaction_info_list?.map((trans, indOne, arr) => {
                                  if (trans?.created_by?.toLowerCase() === "fideo-tally-service") {
                                    const lastElement = arr[arr?.length - 1];
                                    const loaderState = getLoaderState(trans.is_processed, trans?.transaction_action);
                                    return (
                                        <div className={clsx(classes.steps)} key={trans?.invoice_id}>
                                          <div className={classes.tracking}>
                                            <div className={loaderState.loader}>
                                              <div className={loaderState.loaderCircle}></div>
                                            </div>
                                            {(indOne + 1 < (adjustedRail ? 2 : arr?.length)) && (
                                                <div className={classes.line}></div>
                                            )}
                                          </div>
                                          <div className={clsx(classes.shortContent, 'flex items-center justify-between', (indOne + 1 === arr?.length || indOne === 0) && classes.iconHandled)} onClick={() => {
                                            if (!adjustedRail) {
                                              setOpenPaymentBox((prev) => prev === index ? -1 : index);
                                              setOpenDetailsBox(false);
                                            }
                                          }}>
                                            <div className="flex flex-col">
                                              <div>
                                                <div className={clsx(classes.title, "flex items-center")}>
                                                  <span
                                                      className="flex items-center justify-center py-[0px] px-[8px] text-[10px] leading-[20px] font-inter text-[#FFFFFF] font-[500] bg-[#1E333F] rounded-[100px] mr-[5px]">
                                                      Tally
                                                  </span>
                                                  {!!trans?.title ? trans?.title : ''}
                                                  {
                                                      trans?.transaction_action === "AMOUNT_ADJUSTED" && trans?.comments &&
                                                      <Tooltip
                                                          placement="top"
                                                          title={
                                                            <div>
                                                              {trans?.comments}
                                                            </div>
                                                          }
                                                          color="#1E333F"
                                                          // overlayInnerStyle={{ maxWidth: "240px", position: "relative", right: "30px" }}
                                                          // overlayStyle={{ fontSize: "14px", fontWeight: "300", lineHeight: "24px" }}
                                                      >
                                                        <img src={InfoIcon} alt="info"
                                                             className="w-[18px] h-[18px] ml-[8px]"/>
                                                      </Tooltip>
                                                  }
                                                </div>
                                                <div className="flex">
                                                  {payment_item?.transaction_id &&
                                                      <div className={clsx(classes.txnIdText, "flex items-center")}>{`Txn ID: ${payment_item?.transaction_id}`}
                                                        <div className="w-[5px] h-[5px] rounded-[100px] bg-[gray] m-[4px]"></div>
                                                      </div>
                                                  }
                                                  {trans?.created_time_stamp ? (
                                                      <div className={classes.message}>
                                                        {
                                                          true ?
                                                              dayjs(trans?.created_time_stamp)
                                                                  .utc()
                                                                  .utcOffset('+05:30')
                                                                  .format('D MMM YYYY, hh:mm a [IST]')
                                                              :
                                                              dayjs(trans?.created_time_stamp)
                                                                  .utc()
                                                                  .format('D MMM YYYY, hh:mm a [UTC]')
                                                        }
                                                      </div>
                                                  ) : (
                                                      <div className={classes.message}/>
                                                  )}
                                                  <ForexExchange forexData={trans?.forex_conversion_details}/>
                                                </div>
                                              </div>
                                            </div>
                                            <div className={clsx(windowSize > 500 ? 'flex items-center gap-[12px]' : 'flex flex-col items-end gap-[4px]')}>
                                              {lastElement?.is_processed && showFiraReport && !adjustedRail &&
                                                  <button
                                                      disabled={!lastElement?.is_processed}
                                                      onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleReportDownload(payment_item?.transaction_id);
                                                      }}
                                                  >
                                                    <img src={DownloadIcon} alt="download" style={{
                                                      width: "24px",
                                                      height: "24px",
                                                      opacity: lastElement?.is_processed ? '1' : '0.5',
                                                      cursor: lastElement?.is_processed ? 'pointer' : 'not-allowed'
                                                    }}/>
                                                  </button>
                                              }
                                              <div className={clsx(windowSize > 1032 && 'h-[20px]', 'flex justify-center items-center px-[8px] py-[2px] rounded-[100px] border-[1px] border-solid border-[#1E333F29]')}>
                                                {/* {payment_item?.payment_status?.split('_').join(' ')} */}
                                                <p>PAID</p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                    )
                                  }
                                  else if (!indOne && !isCreatedByTally(payment_info_list[index]?.transaction_info_list)) {
                                    const lastElement = arr[arr?.length - 1];
                                    const loaderState = getLoaderState(trans.is_processed, trans?.transaction_action)
                                    return (
                                      <div className={clsx(classes.steps)} key={trans?.invoice_id}>
                                        <div className={classes.tracking}>
                                          <div className={loaderState.loader}>
                                            <div className={loaderState.loaderCircle}></div>
                                          </div>
                                          {(indOne + 1 < (adjustedRail ? 2 : arr?.length)) && (
                                            <div className={classes.line}></div>
                                          )}
                                        </div>
                                        <div className={clsx(classes.shortContent, 'flex flex-col', (indOne + 1 === arr?.length || indOne === 0) && classes.iconHandled)} onClick={() => {
                                          if (!adjustedRail) {
                                            setOpenPaymentBox((prev) => prev === index ? -1 : index)
                                            setOpenDetailsBox(false)
                                          }
                                        }}>
                                          <div>
                                            <div className={classes.title}>
                                              {!!trans?.title ? trans?.title : ''}
                                              {
                                                trans?.transaction_action === "AMOUNT_ADJUSTED" && trans?.comments &&
                                                  <Tooltip
                                                    placement="top"
                                                    title={
                                                      <div>
                                                        {trans?.comments}
                                                      </div>
                                                    }
                                                    color="#1E333F"
                                                    // overlayInnerStyle={{ maxWidth: "240px", position: "relative", right: "30px" }}
                                                    // overlayStyle={{ fontSize: "14px", fontWeight: "300", lineHeight: "24px" }}
                                                  >
                                                    <img src={InfoIcon} alt="info" className="w-[18px] h-[18px] ml-[8px]" />
                                                  </Tooltip>
                                              }
                                            </div>
                                            {trans?.created_time_stamp ? (
                                              <div className={classes.message}>
                                                {
                                                  true
                                                  ? dayjs(trans?.created_time_stamp)
                                                    .utc()
                                                    .utcOffset('+05:30')
                                                    .format('D MMM YYYY, hh:mm a [IST]')
                                                  : dayjs(trans?.created_time_stamp)
                                                    .utc()
                                                    .format('D MMM YYYY, hh:mm a [UTC]')
                                                }
                                              </div>
                                            ) : (
                                              <div className={classes.message} />
                                            )}
                                            <ForexExchange forexData={trans?.forex_conversion_details} />
                                          </div>
                                          <div className={clsx(windowSize > 500 ? 'flex items-center gap-[12px]' : 'flex flex-col items-end gap-[4px]')}>
                                            { showFiraReport && !adjustedRail &&
                                              <button
                                                disabled={!lastElement?.is_processed}
                                                onClick={(e) => {
                                                  e.stopPropagation();
                                                  handleReportDownload(payment_item?.transaction_id);
                                                }}
                                              >
                                                <img src={DownloadIcon} alt="download" style={{ width: "24px", height: "24px", opacity: lastElement?.is_processed ? '1' : '0.5', cursor: lastElement?.is_processed ? 'pointer' : 'not-allowed' }}/>
                                              </button>
                                            }
                                            {/* <div className={clsx(windowSize > 1032 && 'h-[20px]', 'flex justify-center items-center px-[8px] py-[2px] rounded-[100px] border-[1px] border-solid border-[#1E333F29]')}>
                                              {payment_item?.payment_status?.split('_').join(' ')}
                                            </div> */}
                                          </div>
                                        </div>
                                      </div>
                                    )
                                  }
                                })}
                              </div>
                        }
                      </div>
                    </div>
                )
              })
            }
            
            { 
              paidOutsideArray?.map((outside_item, index) => (
                <div key={index} className={clsx(classes.tracker, 'relative flex flex-col items-center')} style={{ height: transactionData?.invoice_status?.toLowerCase() === "in draft" && "100%" }}>
                  <div className={clsx(classes.paymentBox)}>
                    <div className={clsx('w-[100%] flex mb-[12px] justify-between')}>
                      <div className={clsx(classes.priceTextSubHead, 'flex w-[calc(100%-124px)] gap-[8px] items-center')}>
                        <div className={clsx(classes.priceTextInvoiceRail)}>Payment</div>
                        {/* {
                          payment_item?.transaction_id && <div className={clsx('flex items-center')}>Txn ID: {payment_item?.transaction_id}</div>
                        } */}
                      </div>
                      <div>

                      </div>
                    </div>
                    <div className={clsx('w-[100%] p-[16px] border-b-[1px] border-solid border-[#E6E9EF] bg-[#F6F8FB] rounded-[12px]')}>
                      <div className={clsx(classes.steps)}>
                        <div className={classes.tracking}>
                          <div className={getLoaderState(outside_item?.is_processed, outside_item?.transaction_action).loader}>
                            <div className={getLoaderState(outside_item?.is_processed, outside_item?.transaction_action).loaderCircle}></div>
                          </div>
                          {/* {(index + 1 < arrayLength) && (index < smallestIndex) && ( */}
                          {(index + 1 < Math.min(3,3)) && (
                            <div className={classes.line}></div>
                          )}
                        </div>
                        <div className={clsx(classes.contents, 'flex flex-col')}>
                          <div className={classes.title}>
                            {!!outside_item?.title ? outside_item?.title : ''}
                            {/* <div>Invoice created </div>
                                    <div className={classes.amount}>$10,000.00</div> */}
                          </div>
                          {outside_item?.created_time_stamp ? (
                            <div className={classes.message}>
                              {
                                true
                                ? dayjs(outside_item?.created_time_stamp)
                                  .utc()
                                  .utcOffset('+05:30')
                                  .format('D MMM YYYY, hh:mm a [IST]')
                                : dayjs(outside_item?.created_time_stamp)
                                  .utc()
                                  .format('D MMM YYYY, hh:mm a [UTC]')
                              }
                            </div>
                          ) : (
                            <div className={classes.message} />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            }
          </>
        :
          <div
            className={clsx(
              (windowSize <= 900) ? `h-[100vh] p-[24px] mb-[24px]` : (windowSize <= 1100 ? `h-[100%]  p-[60px] mb-[40px]` : `h-[100%]  p-[40px] mb-[20px]`),
              `flex flex-col items-center justify-center`
            )}
            style={{
              borderRadius: "12px",
              border: "1px solid #ECEFF2",
              background: "#FFF",
              height: (windowSize <= 900) ? "60vh" : "100%",
            }}
          >
            <div className="flex flex-col items-center">
              <div className="w-[88px] h-[88px] flex items-center justify-center rounded-full bg-[#DBFD00]">
                <img src={PencilIcon} alt="User" className="w-20 h-20" />
              </div>
              <div className="w-[140px] relative flex flex-row justify-center items-center h-[36px] text-[12px] text-[#FFF] -top-[12px] px-[12px] py-1 bg-[#4380A2] rounded-full ">
                <p className="uppercase">Invoice in Draft</p>
              </div>
            </div>
            <div className="flex flex-col relative rounded-xl items-center">
              <p className="text-[16px] text-center font-inter text-[#181C30]" style={{ fontWeight: "400" }}>
                Your invoice is in a draft state. To receive payment from this customer, please ensure all required attachments and fields are completed.
              </p>
            </div>
            <Button
              className={clsx(
                'mt-[12px] w-[145px] h-[48px] bg-[#FFF] rounded-[8px] border border-solid border-[#060a0d1f] p-[12px]',
                'text-[#1E333F] text-[16px] text-center font-inter font-normal'
              )}
              icon={EditIcon}
              text="Edit Invoice"
              disabled={false}
              btnClassText={clsx("font-medium")}
              onClick={() => {
                if(transactionData?.created_by === "create-invoice") {
                  dispatch(modalActions.setShowDraftEditInvoice(true))
                }else{
                  dispatch(modalActions.editInvoice())
                }
              }}
            />
          </div>
        }

      {/* <div className={clsx(classes.tracker, 'relative')} style={{ height: transactionData?.status?.toLowerCase() === "in draft" && "100%" }}>
        {transactionData?.status?.toLowerCase() === 'in review' &&
          renderVerificationState()}
          {transactionData?.status?.toLowerCase() !== "in draft" ?
          <>
            {transaction_info_list?.map((trans, index, arr) => {
              if (((arrayLength !== smallestIndex && trans.is_processed) || (arrayLength === smallestIndex && !targetValues.includes(trans?.transaction_action)))) {
                const loaderState = getLoaderState(trans.is_processed, trans?.transaction_action)
                const inputString = trans?.title || '';
                const firstSpaceIndex = inputString.indexOf(' ');
                const extractedAmount = inputString.slice(0, firstSpaceIndex);

                return (
                  <div className={clsx(classes.steps)} key={trans?.invoice_id}>
                    <div className={classes.tracking}>
                      <div className={loaderState.loader}>
                        <div className={loaderState.loaderCircle}></div>
                      </div>
                      {(index + 1 < arrayLength) && (index < smallestIndex) && (
                        <div className={classes.line}></div>
                      )}
                    </div>
                    <div className={clsx(classes.content, 'flex flex-col')}>
                      <div className={classes.title}>
                        {
                          trans?.transaction_action === "PAYMENT_CREDITED"
                          ? 
                            `${extractedAmount} Reconciled`
                          :
                            (
                              !!trans?.title ? (trans?.title === 'Invoice uploaded' ? 'Invoice created' : (trans?.title?.includes('NEFT/RTGS') ? trans?.title?.replace('NEFT/RTGS', 'Payout') : trans.title)) : ''
                            )
                        }
                      </div>
                      {trans?.created_time_stamp ? (
                        <div className={classes.message}>
                          {dayjs(trans?.created_time_stamp)
                            .local()
                            .format('D MMM YYYY, hh:mm a')}{' '}
                          IST
                        </div>
                      ) : (
                        <div className={classes.message} />
                      )}
                      <ForexExchange forexData={trans?.forex_conversion_details} />
                    </div>
                  </div>
                )
              }
            })}
          </>
          :
          // <div
          //   className={clsx(
          //     (windowSize <= 900) ? `h-[100vh] p-[24px] mb-[24px]` : (windowSize <= 1100 ? `h-[100%]  p-[60px] mb-[40px]` : `h-[100%]  p-[40px] mb-[20px]`),
          //     `flex flex-col items-center justify-center`
          //   )}
          //   style={{
          //     borderRadius: "12px",
          //     border: "1px solid #ECEFF2",
          //     background: "#FFF",
          //     height: (windowSize <= 900) ? "60vh" : "100%",
          //   }}
          // >
          //   <div className="flex flex-col items-center">
          //     <div className="w-[88px] h-[88px] flex items-center justify-center rounded-full bg-[#DBFD00]">
          //       <img src={PencilIcon} alt="User" className="w-20 h-20" />
          //     </div>
          //     <div className="w-[140px] relative flex flex-row justify-center items-center h-[36px] text-[12px] text-[#FFF] -top-[12px] px-[12px] py-1 bg-[#4380A2] rounded-full ">
          //       <p className="uppercase">Invoice in Draft</p>
          //     </div>
          //   </div>
          //   <div className="flex flex-col relative rounded-xl items-center">
          //     <p className="text-[16px] text-center font-inter text-[#181C30]" style={{ fontWeight: "400" }}>
          //       Your invoice is in a draft state. To receive payment from this customer, please ensure all required attachments and fields are completed.
          //     </p>
          //   </div>
          //   <Button
          //     className={clsx(
          //       'mt-[12px] w-[145px] h-[48px] bg-[#FFF] rounded-[8px] border border-solid border-[#060a0d1f] p-[12px]',
          //       'text-[#1E333F] text-[16px] text-center font-inter font-normal'
          //     )}
          //     icon={EditIcon}
          //     text="Edit Invoice"
          //     disabled={false}
          //     btnClassText={clsx("font-medium")}
          //     onClick={() => {
          //         dispatch(modalActions.editInvoice())
          //     }}
          //   />
          // </div>
          }

      </div> */}
    </div>
  )
}

export default PaymentDetails