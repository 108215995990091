import React from "react";
import clsx from "clsx";
import classes from "./DownloadFiraMobile.module.scss"
import Modal from "Layout/Modal";
import Close from "Layout/Close";
import Backdrop from "Layout/Backdrop";
import ReactDOM from 'react-dom'
import { useSelector ,useDispatch} from "react-redux";
import { modalActions } from "Store/modal-redux";
import { DownloadOutlined } from "@ant-design/icons";


const DownloadFiraMobile = (props) =>{
    const dispatch = useDispatch();
    return(
    <Modal
    className={clsx(
      classes.modal,
      ' -sm:h-fit p-0 w-screen md:w-[100%] xl:w-auto z-[100] rounded-t-[12px] bottom-0 overflow-y-scroll'
    )}
    >
    {
        ReactDOM.createPortal(
          <Backdrop className={classes.backdrop}
          onClick={()=>{
            dispatch(modalActions.downloadFiraMobile(false))
          }}
          />,
          document.getElementById('backdrop-root')
    )}
    <div className={classes.headerTop}>
        <div className={classes.invoiceHead}>{`Payment Advice(${props.invoice.invoiceId})`}</div>
        <Close
         onClick = {()=>{
            dispatch(modalActions.downloadFiraMobile(false))
         }}
        />
    </div>
    <div className={classes.bigCont}>
       {
        props?.invoice?.firaDetails.map((item)=>{
           return (
            <div className={classes.cont}>
                <p className={classes.remAmount}>{item.remittanceAmountCurrency.sign} {item.remittanceAmountCurrency.sign === '₹' ? item.remittanceAmount.toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : item.remittanceAmount.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                <DownloadOutlined
                    style={{
                      fontSize: 16,
                      background: 'lightgrey',
                      borderRadius: '50%',
                      padding: 6,
                    }}
                    onClick={()=>props.handleDownloadClick(props.invoice.invoiceId , item?.fideoTransactionId)}
                />
            </div>
           )
        })
       }
    </div>
    </Modal>
    )
}

export default DownloadFiraMobile