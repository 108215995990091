import classes from "Components/ReportTable/BankDropDown.module.scss";
// import EEFCTag from "assets/Icons/EEFC-tag.svg"
import { modalActions } from "Store/modal-redux";
import { useDispatch, useSelector } from "react-redux";
import checkIcon from "assets/Icons/check.svg"
import useClickOutside from "hooks/useClickOutside";
import Close from "Layout/Close";
import ReactDOM from "react-dom";
import Backdrop from 'Layout/Backdrop'
import ContactInfo from "Components/Global/ContactInfo";
import INRFlag from 'assets/Images/INR_Flag.svg';
import clsx from 'clsx'
import { useState ,useEffect} from "react";
// import dayjs from 'dayjs'

const BankDropDown = (props) => {
  const [screenWidth, setScreenWidth] = useState(getCurrentDimension());
  function getCurrentDimension() {
    return  window.innerWidth
  }
  useEffect(() => {
    const updateDimension = () => {
      setScreenWidth(getCurrentDimension());
    };
    window.addEventListener("resize", updateDimension);
    return () => {
      window.removeEventListener("resize", updateDimension);
    };
  }, [screenWidth]);
  const { cellData, option = [], setPrimaryBank } = props
  const [mainBank , setMainBank] = useState(cellData);
  const dispatch = useDispatch();
  const showBankDropDown = useSelector((state) => state.modal.showBankDropDown);
  const optRef = useClickOutside(() => {
    screenWidth<=600?
    dispatch(modalActions.showBankDropDown(false)):dispatch(modalActions.showBankDropDown(true))
  });
  const badRef = useClickOutside(()=>{

  })
  const handleBankClick = () =>dispatch(modalActions.showBankDropDown())
  

  return (
    <>
      {showBankDropDown &&
        ReactDOM.createPortal(
          <Backdrop
            className={classes.backdrop}
            onClick={() => {
              dispatch(modalActions.showBankDropDown(false))
            }}
          />,
          document.getElementById('backdrop-root')
        )}
      <div  className={`${classes.action} ${props.className}`} ref={optRef}>
        {showBankDropDown && (
          <div className={classes.action_all}  >
            <div className={classes.header} >
              <div className={classes.heading}>Receive Payment</div>
              <Close
                onClick={() => dispatch(modalActions.showBankDropDown())}
                className={classes.close}
              />
            </div>
            <div className={classes.options} >
              {option?.map((opt, index) => (
                <div
                  className={clsx(
                    classes.opt,
                    !opt.onClick && 
                    'cursor-pointer',
                    index && 'border-t border-[#ECEFF2]',
                  )}
                  key={`${opt.text}-${index}`} >
                  <div key={index} className={classes.bankCard}  style={{backgroundColor:`${index%2==0?"#FFF":"#F7F8FB"}`}} onClick={()=>{
                    setMainBank(opt)
                    setPrimaryBank(opt)
                    dispatch(modalActions.showBankDropDown(false))
                    }}  >
                        <div key={index} className={classes.bankContent} >
                              <img
                              src={
                              INRFlag
                              }
                              alt="country-flag"
                              className="w-[48px] h-[48px] rounded-full"
                            />
                             <ContactInfo 
                             name={`${opt?.bankName} Bank`}
                             email={opt?.accountNumber
                              ?.slice(-4)
                              .padStart(opt?.accountNumber?.length, 'X')}
                             />
                            </div>
                            <div className={classes.rightCheck}>
                                <p className={classes.eefc} style={{backgroundColor:`${index%2==0?"#F7F8FB":"#FFF"}`}}>{opt.bankAccountType=="EEFC"?"EEFC":"INR"}</p>
                                <div className={classes.checkImgWrap}>{opt.accountId==mainBank.accountId &&<img src={checkIcon} alt="check-icon" className={classes.checkIcon} />}</div>
                              </div>
                          </div>  
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </>
  )
}
export default BankDropDown;
