import classes from 'Components/ReportTable/FiraReportTable.module.scss'
import ReportTableLayout from 'Components/ReportTable/ReportTableLayout'
// import sorting from 'assets/Images/sorting-icon.svg'
import { DownloadOutlined } from '@ant-design/icons'
// import URL from 'constants/Network'
// import { baseUrl } from 'constants/Network'
// import httpClient from 'services/httpClient'
// import { useEffect, useState } from 'react'
import { Tag } from 'antd'
import ContactInfo from 'Components/Global/ContactInfoNew' 
import MobileFiraReportTable from './MobileFiraReportTable'
import UseApiCall from 'hooks/useApiCall'
import { apiDownloadFiraReport, fetchFileFromS3, apiDownloadFiraReportWithTxnId} from 'services'
// import axios from 'axios'
import { formatNumberToLocaleString } from 'hooks/formatNumberToLocaleString';
import Shimmer from 'Layout/Shimmer'
import { enqueueSnackbar } from 'notistack'
import { tourArrays } from "constants/Tour";
import { Tooltip } from "antd";
import TooltipTourData from "Components/Tour/TooltipTourData";
import { useSelector } from 'react-redux'
import CryptoJS from 'crypto-js';
import { secretKey } from "constants";
import { useEffect, useRef, useState } from 'react'
import clsx from 'clsx'
import useElementOnScreen from 'hooks/useElementOnScreen'
import { downloadWithInterval } from "hooks/downloadFiles";

const FiraReportTable = (props) => {
  const showTourModal = useSelector((state) => state.global.showTour);
  const stepNumber = useSelector((state) => state.global.stepNumberInTour);
  const theadRef = useRef(null);
  const tbodyRef = useRef(null);
  // const optionArr = [
  //     {text: 'View', icon: eye},
  //     {text: 'Edit', icon: edit}
  // ];

  const [tourArray, setTourArray] = useState(tourArrays[1]);
  const decryptValue = (encryptedValue) => {
    const bytes = CryptoJS.AES.decrypt(encryptedValue || '', secretKey);
    return bytes.toString(CryptoJS.enc.Utf8);
  };

  const storedUserRole = decryptValue(localStorage.getItem('nickName') || '');

  useEffect(() => {
    if(storedUserRole === 'malik') setTourArray(tourArrays[0])
  }, [])

  const [containerTableRef, isTableVisible] = useElementOnScreen({
    root: null,
    rootMargin: "0px",
    threshold: 0.9
  })

  const [downloadFiraReport, getFileUrlFetching] = UseApiCall(
    apiDownloadFiraReport,
    (res) => {
      const fileUrls = res?.data;
      downloadWithInterval(fileUrls, 1000);
    }, (err) => {
      enqueueSnackbar(`${"Error in downloading Fira Report."}`, { variant: 'error' })
    }
  )

  const [downloadFiraReportTxnId] = UseApiCall(
    apiDownloadFiraReportWithTxnId,
    (res) => {
      let url = res?.data
      window.location.assign(url)
    }, () => {
      enqueueSnackbar(`Error in downloading Fira Report.`, { variant: 'error' })
    }
  )

  const handleReportDownload = async (invoiceId ,txnId) => {
    if (txnId === "" || txnId === null) {
      await downloadFiraReport({ invoiceId: invoiceId })
    } else {
      await downloadFiraReportTxnId({ invoiceId: invoiceId, txnId })
    }
  }

  useEffect(() => {
    const handleScroll = (event) => {
      if (event.target.id === 'thead') {
        tbodyRef.current.scrollLeft = theadRef.current.scrollLeft;
      }
      else if (event.target.id === 'tbody') {
        theadRef.current.scrollLeft = tbodyRef.current.scrollLeft;
      }
    };

    const thead = theadRef.current;
    const tbody = tbodyRef.current;

    thead.addEventListener('scroll', handleScroll);
    tbody.addEventListener('scroll', handleScroll);

    return () => {
      thead.removeEventListener('scroll', handleScroll);
      tbody.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <div className={classes.allWrap}>
          <div className={clsx(`${classes.table} ${classes.stickyHeaderTop} ${classes.reportTableWrapTop}`)} id="thead" ref={theadRef}>
            <thead>
              {props.tableHeader.map((header, index) => (
                <th key={`${header}-${index}`} className={classes.cell_header1} 
                style={{width:`${header.width}%`}} 
                // style={{width: '25%'}}
                valign="top">
                  <div className={classes.cell_header2}>
                    <div className={classes.head}>{header.title}</div>
                    {/* <img
                      src={sorting}
                      alt="sorting-icon"
                      className={classes.sorting}
                    /> */}
                  </div>
                </th>
              ))}
              <th className={classes.cell_header1}>
                <div className={`${classes.cell_header2} ${classes.actionHeader}`}>
                  <div className={classes.head}>Action</div>
                </div>
              </th>
            </thead>
          </div>

          <div className={`${classes.reportTableWrap}`} id="tbody"  ref={tbodyRef}>
            <ReportTableLayout className={classes.table}>
              <tbody>
              { props.loading
                  ?
                    <td colSpan={5}>
                      {[1,2,3]?.map((item, index) => (
                        <Shimmer key={index} className="w-[100%] h-[80px] mb-[12px]"/>
                      ))}
                    </td>
                  :
                    props.tableData.map((data, index) => (
                      <tr key={data.invoiceId} className={classes.row}>
                        <td className={classes.cell} style={{width: '26%'}} valign="top">{data.invoiceId}</td>
                        {/* <td className={classes.cell} valign="top">{data.customer.customerName}</td> */}
                        <td className={classes.cell} style={{width: '32%'}} valign="top">
                          <ContactInfo
                            name={data?.customer?.customerName}
                            email={data?.customer?.emailAddress}
                            country={data?.customer?.country}
                            countryName={data?.customer?.countryName}
                            classNameCountry={classes.classNameCountry} 
                          />
                        </td>
                        <td className={classes.cell} style={{width: '20%'}} valign="top">
                          {data?.invoiceCurrency?.sign +
                            formatNumberToLocaleString((data?.receivingAmount), 2, 2)}
                        </td>
                        {/* TODO FUTURE: locale system required from backend */}
                        <td className={clsx(classes.cell,'!pr-[0px]')} style={{width: '20%'}} valign="top">
                          <div className='flex flex-col '>
                              { 
                                // data?.firaSummary?.remittanceAmount
                                // ? ` ${data?.firaSummary?.remittanceAmountCurrency?.sign}${data?.firaSummary?.remittanceAmountCurrency?.sign === '₹' ? data?.firaSummary?.remittanceAmount?.toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : data?.firaSummary?.remittanceAmount?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
                                // : '--'
                                data.firaDetails.length > 0 ?(data?.firaDetails?.map((item ,i )=>{
                                return <p className={`${i!==data?.firaDetails.length-1 ? "border-[#d3d3e5] border-dashed border-b-[1px] pt-[18px] pb-[18px]":"pt-[18px]" } ${i===0 && "!pt-[0px]"}`}>{item.remittanceAmountCurrency.sign} {item.remittanceAmountCurrency.sign === '₹' ? item.remittanceAmount.toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : item.remittanceAmount.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                                })):"--"
                          
                              }
                          </div>
                        </td>
                        <td className={clsx(classes.cell,'!pl-[0px] !pr-[0px]')}  valign="top">
                          <div
                            className={clsx(classes.cell_action,`flex flex-col  items-center`)}
                            style={{
                              cursor: 'pointer',
                            }}
                          >
                            {/* <Action option={optionArr}/> */}
                            {data.action === 'PENDING' ? (
                              <Tag color="volcano">PENDING</Tag>
                            ) : (
                              data.firaDetails.length > 0 ?(data?.firaDetails?.map((item,i)=>{
                                return <div className={`w-[100%] flex justify-center ${i!==data?.firaDetails.length-1 ? "border-[#d3d3e5] border-dashed border-b-[1px] pt-[18px] pb-[18px]":"pt-[18px]"} ${i===0 && "!pt-[0px]"}`}>
                                <DownloadOutlined
                                  style={{
                                    fontSize: 16,
                                    fontWeight: 'bold',
                                  }}
                                onClick={() => handleReportDownload(data.invoiceId , item?.fideoTransactionId)}
                              /></div>
                                })):"--"
                              // <DownloadOutlined
                              //   style={{
                              //     fontSize: 16,
                              //     fontWeight: 'bold',
                              //   }}
                              //   onClick={() => handleReportDownload(data.invoiceId)}
                              // />
                            )}
                          </div>
                        </td>
                      </tr>
                    ))
                }
              </tbody>
            </ReportTableLayout>
          </div>
        </div>
      {(props?.tableData.length === 0 && !props.loading) && 
        (<div
          style={{
            textAlign: 'center',
            width: '100%',
            marginTop: '4rem',
            fontSize: '2.5rem'
          }}
          className={classes.table}
        >
          No Reports Yet.
        </div>)
      }
      {/* TODO: MOBILE */}
      <MobileFiraReportTable
        tableData={props.tableData}
        handleReportDownload={handleReportDownload}
        loading={props.loading}
      />
    </>
  )
}

export default FiraReportTable
