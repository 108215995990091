import ReactDOM from "react-dom";
import React, { useState, useEffect } from 'react';
import Backdrop from "Layout/Backdrop";
import classes from 'Components/Payment/ReceiveBulkUploadPayment.module.scss';
import { useDispatch } from 'react-redux';
import Modal from 'Layout/Modal';
import {modalActions} from 'Store/modal-redux';
import UseApiCall from 'hooks/useApiCall';
import { apiGetBulkTransactionsId, apiGetBulkTransactionSubmit, apiEditInvoice, apiGetBankList } from 'services';
import Button from "Layout/Button";
import Close from "Layout/Close";
import leftPointer from "assets/Images/left-pointer.svg";
import clsx from 'clsx';
import BulkFileBrowser from 'Components/atom/FileBrowserForUploader/BulkFileBrowser';
import { enqueueSnackbar } from "notistack";
import DocumentSheet from 'Pages/Other/MakePayment/UploadInvoiceDocumentSheet'
import styles from "../atom/FileBrowserForUploader/FileBrowserNew.module.scss";
import ConnectIcon from "assets/Icons/connect.svg";
import dayjs from "dayjs";
import { GlobalActions } from "Store/global-redux";
import { useNavigate } from "react-router-dom";

const ReceiveBulkUploadPayment = (props) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [invoice, setInvoice] = useState({})
    const [ocrResponse , setOcrResponse] = useState([]);
    const [bulkUploadData , setBulkUploadData] = useState([]);
    const [ocrLoading , setOcrLoading] = useState(false);
    const [saveDisabled , setSaveDisabled] = useState(false);
    const [primaryBank, setPrimaryBank] = useState(null)
    const [getBulkTransactionsId, bulkTransactionsIdLoader, bulkTransactionsIdResult] = UseApiCall(apiGetBulkTransactionsId, (res) => {
    }, (err) => {
        enqueueSnackbar(`${err?.data?.error_response?.message} ${err?.data?.error_response?.errors !== null ? JSON.stringify(err?.data?.error_response?.errors[0]) : ""}`, { variant: 'error' })
    })

    // const [getBulkTransactionSubmit] = UseApiCall(apiGetBulkTransactionSubmit, (res) => {
    //     enqueueSnackbar("Invoices Uploaded Successfully.", { variant: 'success' })
    // }, (err) => {
    //     enqueueSnackbar(`${err?.data?.error_response?.message} ${err?.data?.error_response?.errors !== null ? JSON.stringify(err?.data?.error_response?.errors[0]) : ""}`, { variant: 'error' })
    // })

    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
      const handleResize = () => {
        setIsMobile(window.innerWidth <= 640);
      };
  
      window.addEventListener('resize', handleResize);
  
      handleResize();
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);

    useEffect(() => {
        getBulkTransactionsId()
        getBankAccounts()
    }, [])

    const [draftEditInvoiceCall, draftEditInvoiceCallLoader] = UseApiCall(
      apiEditInvoice,
      (res) => {
        enqueueSnackbar(paramsArray?.length > 1 ? 'Invoices saved as draft successfully.' : 'Invoice saved as draft successfully.', {
          variant: 'success',
          anchorOrigin: { horizontal: "center", vertical: "top" }
        });
      }, (err) => {
        enqueueSnackbar(`${err?.data?.error_response?.message} ${err?.data?.error_response?.errors !== null ? JSON.stringify(err?.data?.error_response?.errors[0]) : ""}`, { variant: 'error' })
      }
    )

    const [invoiceId, setInvoiceId] = useState('')
    const [purposeCode, setPurposeCode] = useState('')
    const [issueDate, setIssueDate] = useState()
    const [dueDate, setDueDate] = useState()
    const [blDate , setBLDate] = useState()
    const [invoiceAmount, setInvoiceAmount] = useState('')
    const [receivableAmount, setReceivableAmount] = useState('')
    const [invoiceDescription, setInvoiceDescription] = useState('')
    const [remarks, setRemarks] = useState('')
    const [paymentTerms, setPaymentTerms] = useState('')
    const [changedIssueDate, setChangedIssueDate] = useState(false)
    const [invoiceCurrency, setInvoiceCurrency] = useState('USD')
    const [transactionId, setTransactionId] = useState(null)
    const [hitDraftApi, setHitDraftApi] = useState(false)
    const [selectedFiles, setSelectedFiles] = useState({})
    const [paramsArray, setParamsArray] = useState([])
    const [currentTxnId, setCurrentTxnId] = useState('')

    function extractNumericValue(str) {
      if (typeof str === 'number') {
          str = str.toString();
      } else if (!str || typeof str !== 'string') {
          return '0.00';
      }
  
      const match = str.replace(/[^0-9.$]/g, '').match(/(\d+(\.\d+)?)$/);
  
      if (match) {
          const numericValue = parseFloat(match[1]);
          return numericValue;
      }
  
      return '0.00';
    }
  
    function parseDate(inputDate) {
      const [day, month, year] = inputDate.split('-').map(Number);
      const dateObject = new Date(year, month - 1, day);
    
      if (isNaN(dateObject.getTime())) {
          return null;
      }
    
      const formattedYear = dateObject.getFullYear().toString();
      const formattedMonth = (dateObject.getMonth() + 1).toString().padStart(2, '0');
      const formattedDay = dateObject.getDate().toString().padStart(2, '0');
    
      return [formattedYear, formattedMonth, formattedDay];
    }
  
    useEffect(() => {
      console.log(ocrResponse, "ocr_response")
      setInvoiceId('')
      setPurposeCode('')
      setIssueDate(null)
      setDueDate(null)
      setBLDate(null)
      setInvoiceAmount('')
      setReceivableAmount('')
      // setInvoiceDescription('')
      setRemarks('')
      setPaymentTerms('')
      if (ocrResponse?.length > 0) {
        // const newBgColors = { ...bgColors };
        if (ocrResponse[0]?.invoiceId) {
          setInvoiceId(ocrResponse[0]?.invoiceId?.trim());
          // newBgColors.invoiceId = true;
        }
        if (ocrResponse[0]?.invoiceAmount) {
          const stringWithCurrency = ocrResponse[0]?.invoiceAmount;
          const numericValue = extractNumericValue(stringWithCurrency);
          setInvoiceAmount(numericValue);
          setReceivableAmount(numericValue);
          // newBgColors.invoiceAmount = true;
        }
        if (ocrResponse[0]?.receivingAmount) {
          const stringWithCurrency = ocrResponse[0]?.receivingAmount;
          const numericValue = extractNumericValue(stringWithCurrency);
          setReceivableAmount(numericValue);
          // newBgColors.receivingAmount = true;
        }
        // if (ocrResponse[0]?.description) {
        //   setInvoiceDescription(ocrResponse[0]?.description);
        //   // newBgColors.description = true;
        // }
        if (ocrResponse[0]?.netTerms) {
          setPaymentTerms(ocrResponse[0]?.netTerms);
          // newBgColors.netTerms = true;
        }
        if (ocrResponse[0]?.remarks) {
          setRemarks(ocrResponse[0]?.remarks);
          // newBgColors.remarks = true;
        }
  
        if (ocrResponse && ocrResponse?.length > 0 && ocrResponse[0]?.issueDate) {
          const dateParts = parseDate(ocrResponse[0]?.issueDate);
          if (dateParts !== null) {
            const issue = dayjs()
              .year(Number(dateParts[0]))
              .month(Number(dateParts[1]) - 1)
              .date(Number(dateParts[2]));
            setIssueDate(issue);
            setChangedIssueDate(true);
            // newBgColors.issueDate = true;
          }
        }
  
        if (ocrResponse[0]?.dueDate) {
          const dateParts = parseDate(ocrResponse[0]?.dueDate);
          if (dateParts !== null) {
            const due = dayjs()
              .year(Number(dateParts[0]))
              .month(Number(dateParts[1]) - 1)
              .date(Number(dateParts[2]));
            setDueDate(due);
          }
          // newBgColors.dueDate = true;
        }
  
        if (ocrResponse[0]?.blDate) {
          const dateParts = parseDate(ocrResponse[0]?.blDate);
          if (dateParts !== null) {
            const bl = dayjs()
              .year(Number(dateParts[0]))
              .month(Number(dateParts[1]) - 1)
              .date(Number(dateParts[2]));
            setBLDate(bl);
            // newBgColors.blDate = true;
          }
        }
  
        if (ocrResponse[0]?.invoiceCurrency) {
          setInvoiceCurrency(ocrResponse[0]?.invoiceCurrency);
          // newBgColors.invoiceCurrency = true;
        }
        // setBgColors(newBgColors);
        setHitDraftApi(!hitDraftApi);
        setCurrentTxnId(ocrResponse[0]?.txnId)
      }
    }, [ocrResponse])

    const [getBankAccounts] = UseApiCall(apiGetBankList, (res) => {
      setPrimaryBank(res?.data[0]);
      res?.data?.forEach((item) => {
        if (item.isPrimary) {
          setPrimaryBank(item)
        }
      })
    })

    useEffect(() => {
      if (currentTxnId != '') {
        let params = {
          transactionId: currentTxnId,
          // invoiceId: Math.floor(1000000000 + Math.random() * 9000000000),
          invoiceId: invoiceId === "" ? null : invoiceId,
          purposeCode: purposeCode === "" ? null : purposeCode,
          issueDate: issueDate ? new Date(issueDate)?.getTime() : null,
          dueDate: dueDate ? new Date(dueDate)?.getTime() : null,
          blDate: blDate? new Date(blDate)?.getTime() : null,
          invoiceAmount: (invoiceAmount === '' || invoiceAmount === null) && (receivableAmount === '' || receivableAmount === null) 
                            ? null
                            : (invoiceAmount !== '' && invoiceAmount !== null) && (receivableAmount === '' || receivableAmount === null)
                              ? invoiceAmount
                              : (receivableAmount !== '' && receivableAmount !== null) && (invoiceAmount === '' || invoiceAmount === null)
                                ? receivableAmount
                                : Math.max(parseFloat(invoiceAmount), parseFloat(receivableAmount)),
          receivingAmount: (receivableAmount === '' || receivableAmount === null) ? (invoiceAmount === '' || invoiceAmount === null) ? null : invoiceAmount : receivableAmount,
          description: invoiceDescription,
          customerId: null,
          invoiceCurrency,
          outputCurrency: "INR",
          receiveMoneyAccountId: primaryBank?.accountId,
          mode: "DRAFT",
          requestSendBy: "from-bulk"
        };
        setParamsArray(prevParamsArray => [...prevParamsArray, params])
      }
    }, [hitDraftApi])

    const handleSave = async () => {
      try {
        await Promise.all(paramsArray.map(params => {
          draftEditInvoiceCall(params)
        }));
    
        await new Promise(resolve => setTimeout(resolve, 2000));
        dispatch(GlobalActions.setSubMenu("draft"));

        dispatch(modalActions.bulkUploaderDisplay(false));
        navigate("/receivablesDraft")
      } catch (error) {
        console.error('An error occurred:', error);
      }
    };

    return (
      <div>
        {ReactDOM.createPortal(
          <Backdrop
            onClick={() => {
              dispatch(modalActions.bulkUploaderDisplay(false));
            }}
            className={classes.backdrop}
          />,
          document.getElementById("backdrop-root")
        )}
        { !isMobile ?
            <Modal className={clsx(classes.modal, !isMobile && `min-w-[636px]`, 'bg-[#F4F6FA]')}>
              <div className={classes.loc}>
                <div className={classes.header}>
                  <img
                    src={leftPointer}
                    alt="left-pointer"
                    className={classes.left_pointer}
                    onClick={() => {
                        dispatch(modalActions.bulkUploaderDisplay(false));
                    }}
                  />
                  <div className={classes.subHeader}>
                    <div className={classes.sub1}>Upload Invoices</div>
                  </div>
                  <div></div>
                  <Close
                    onClick={() => {
                        dispatch(modalActions.bulkUploaderDisplay(false));
                    }}
                    className={classes.close}
                  />
                </div>
                <div className={classes.all_list}>
                    <BulkFileBrowser
                        setFile={setInvoice}
                        files={invoice}
                        setSelectedFiles={setSelectedFiles}
                        selectedFiles={selectedFiles}
                        transactionId={bulkTransactionsIdResult?.data}
                        isMobile={isMobile}
                        validFormats={['document', 'image']}
                        hasError={false}
                        setOcrResponse={setOcrResponse}
                        setOcrLoading={setOcrLoading}
                        setSaveDisabled={setSaveDisabled}
                        saveDisabled={saveDisabled}
                        setTransactionId={setTransactionId}
                        disabled={bulkTransactionsIdLoader}
                        docNum={Object.keys(invoice || {})?.length + 1 || 0}
                        setBulkUploadData={setBulkUploadData}
                        setParamsArray={setParamsArray}
                        // errorMessage={"Please upload a valid file"}
                    />
                </div>
                {
                  Object.keys(invoice || {})?.length === 0
                  ?
                    <div className={clsx(styles.documentupload, 'bg-[#c1c1c152] mt-[16px] cursor-not-allowed')}>
                      <div
                        className={clsx(styles.dropMessage, `flex ${"flex-col"} justify-center items-center relative`)}
                      >
                        <div className={clsx(`relative w-[84px] opacity-70 ${isMobile ? "h-[44px]" : "h-[84px] bottom-[-6px]"}`)}>
                          <img src={ConnectIcon} alt="file" className={styles.fileIconImg} />
                        </div>
                        <p className="mt-[16px]">
                          { (
                              <>
                                <p className={styles.fontText}>Connect to your ERP/Accounting Software</p>
                                <p className={styles.fontSubText}>Tally, SAP, Microsoft Envision</p>
                                <p className={styles.fontSubText}>(To enable this option please connect with Fideo Support)</p>
                              </>
                            )
                          }
                        </p>
                      </div>
                    </div>
                  :
                    ""
                }
                <div className={classes.button}>
                  <Button
                    className={clsx(classes.btn, (Object.keys(invoice || {})?.length === 0 || ocrLoading || saveDisabled) ? 'opacity-50' : 'opacity-100')}
                    text={"Save"}
                    disabled={(Object.keys(invoice || {})?.length === 0 || ocrLoading || saveDisabled) ? true : false}
                    onClick={handleSave}
                  />
                </div>
              </div>
            </Modal>
            :
              <DocumentSheet
                // ref={optRef}
                setInvoice={setInvoice}
                invoice={invoice}
                isMobile={isMobile}
                bulkTransactionsIdLoader={bulkTransactionsIdLoader}
                bulkTransactionsIdResult={bulkTransactionsIdResult}
              />
        //             {/* {
        // ReactDOM.createPortal(
        //   <ActionWithOutIcon
        //     option={[...optionArr]}
        //     // cellData={"data"}
        //   />,
        //   document.getElementById("modal-root")
        // )} */}
        }
      </div>
    )
}
export default ReceiveBulkUploadPayment;