// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ReconSuccessPrompt_bigCont__0pBRh {
  display: flex;
  flex-direction: column;
  padding: 40px 0px;
  z-index: 100;
}
.ReconSuccessPrompt_bigCont__0pBRh .ReconSuccessPrompt_imgBox__bf9zS {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 20px;
}
.ReconSuccessPrompt_bigCont__0pBRh .ReconSuccessPrompt_imgBox__bf9zS .ReconSuccessPrompt_mainIcon__Odt4R {
  width: 88px;
  height: 88px;
}
.ReconSuccessPrompt_bigCont__0pBRh .ReconSuccessPrompt_contentBox__8DeZu {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
  padding: 0px 40px;
}
.ReconSuccessPrompt_bigCont__0pBRh .ReconSuccessPrompt_contentBox__8DeZu .ReconSuccessPrompt_subHeading__bRJOH {
  color: #181C30;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}
.ReconSuccessPrompt_bigCont__0pBRh .ReconSuccessPrompt_contentBox__8DeZu .ReconSuccessPrompt_subHeading__bRJOH .ReconSuccessPrompt_reconAmount__5tfZR {
  font-weight: 700 !important;
}
.ReconSuccessPrompt_bigCont__0pBRh .ReconSuccessPrompt_contentBox__8DeZu .ReconSuccessPrompt_doneBtn__wHHQu {
  width: 416px;
  padding: 12px 20px;
  border-radius: 8px;
  font-size: 16px;
  text-align: center;
  color: #FFF;
  font-weight: 600;
  background-color: #181C30;
}

.ReconSuccessPrompt_errorBox__EuNBb {
  border-radius: 8px;
  background: rgba(255, 78, 78, 0.12);
  display: flex;
  padding: 20px;
  align-items: flex-start;
  gap: 10px;
}`, "",{"version":3,"sources":["webpack://./src/Components/atom/ReconSuccessPrompt/ReconSuccessPrompt.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,iBAAA;EACA,YAAA;AACJ;AAAI;EACI,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,oBAAA;AAER;AAAQ;EACI,WAAA;EACA,YAAA;AAEZ;AAEI;EACI,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;EACA,SAAA;EACA,iBAAA;AAAR;AAEQ;EACI,cAAA;EACA,kBAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;AAAZ;AAEY;EACI,2BAAA;AAAhB;AAIQ;EACI,YAAA;EACA,kBAAA;EACA,kBAAA;EACA,eAAA;EACA,kBAAA;EACA,WAAA;EACA,gBAAA;EACA,yBAAA;AAFZ;;AAQA;EACI,kBAAA;EACA,mCAAA;EACA,aAAA;EAEA,aAAA;EACA,uBAAA;EACA,SAAA;AANJ","sourcesContent":[".bigCont{\n    display: flex;\n    flex-direction: column;\n    padding: 40px 0px;\n    z-index: 100;\n    .imgBox{\n        display: flex;\n        align-items: center;\n        justify-content: center;\n        padding-bottom: 20px;\n\n        .mainIcon{\n            width: 88px;\n            height: 88px;\n        }\n    }\n\n    .contentBox{\n        display: flex;\n        flex-direction: column;\n        align-items: center;\n        justify-content: center;\n        gap: 24px;\n        padding: 0px 40px;\n\n        .subHeading{\n            color: #181C30;\n            text-align: center;\n            font-size: 16px;\n            font-weight: 500;\n            line-height: 24px;\n\n            .reconAmount{\n                font-weight: 700 !important;\n            }\n        }\n\n        .doneBtn{\n            width: 416px;\n            padding: 12px 20px;\n            border-radius: 8px;\n            font-size: 16px;\n            text-align: center;\n            color: #FFF;\n            font-weight: 600;\n            background-color: #181C30;\n        }\n    }\n\n}\n\n.errorBox {\n    border-radius: 8px;\n    background: rgba(255, 78, 78, 0.12);\n    display: flex;\n    // width: 416px;\n    padding: 20px;\n    align-items: flex-start;\n    gap: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"bigCont": `ReconSuccessPrompt_bigCont__0pBRh`,
	"imgBox": `ReconSuccessPrompt_imgBox__bf9zS`,
	"mainIcon": `ReconSuccessPrompt_mainIcon__Odt4R`,
	"contentBox": `ReconSuccessPrompt_contentBox__8DeZu`,
	"subHeading": `ReconSuccessPrompt_subHeading__bRJOH`,
	"reconAmount": `ReconSuccessPrompt_reconAmount__5tfZR`,
	"doneBtn": `ReconSuccessPrompt_doneBtn__wHHQu`,
	"errorBox": `ReconSuccessPrompt_errorBox__EuNBb`
};
export default ___CSS_LOADER_EXPORT___;
