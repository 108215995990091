import AddContact from 'Components/General/AddContact'
import ContactInfo from 'Components/Global/ContactInfo'
import FileBrowser from 'Components/atom/FileBrowser/FileBrowser'
import PaymentDetailsModal from 'Components/molecule/PaymentDetailsModal/PaymentDetailsModal'
import Button from 'Layout/Button'
import { modalActions } from 'Store/modal-redux'
import ReactDOM from 'react-dom'
import leftPointer from 'assets/Icons/arrow-left.svg'
import RightIcon from 'assets/Icons/chevron-right-white.svg'
import DownIcon from 'assets/Icons/chevron-down-white.svg'
import fideoLogo from 'assets/Images/FideoLogo.svg'
import LockIcon from 'assets/Icons/lock.svg'
import WarningIcon from 'assets/Icons/warning-yellow.svg'
import invoice1 from 'assets/Images/invoice1.png';
import whiteBlurred from 'assets/Images/whiteBlurred.jpeg';
import whiteTransBlurred from 'assets/Images/whiteBlurred.jpeg';
import { useNavigate } from 'react-router-dom';
import ExporterIcon from 'assets/Icons/exporter.svg'
import classes from 'Pages/Other/CustomerPaymentPage/index.module.scss';


import clsx from 'clsx'
import dayjs from 'dayjs'
import { useDispatch, useSelector } from 'react-redux'
import DocumentSheet from './DocumentSheetPayment'
import FileViewer from 'Components/atom/FileViewer/FileViewerPayment'
import UseApiCall from 'hooks/useApiCall'
import { useEffect, useRef, useState } from 'react'
import {
  apiGetNewPaymentDetails,
  apiGetOTP,
  apiGetPaymentDetails,
  apiGetPaymentInvoices,
  fetchFileFromS3,
} from 'services'
import { useParams } from 'react-router-dom'
import EnterOtp from 'Components/otpScreen/EnterOtp'
import TypeOtp from 'Components/otpScreen/TypeOtp'

const MakePayment = () => {

  const [screenSize, setScreenSize] = useState(getCurrentDimension());
    function getCurrentDimension() {
        return {
        width: window.innerWidth,
        height: window.innerHeight,
        };
    }

    const navigate = useNavigate();

    const goBackInHistory = () => {
      navigate(-1);
    };

    useEffect(() => {
        const updateDimension = () => {
          setScreenSize(getCurrentDimension());
        };
        window.addEventListener("resize", updateDimension);
        // console.log(screenSize)
    
        return () => {
          window.removeEventListener("resize", updateDimension);
        };
      }, [screenSize]);



  let { token, invoiceId } = useParams()
  const dispatch = useDispatch()
  const buttonRef = useRef(null)
  const searchParams = new URLSearchParams(document.location.search)
  const oldToken = searchParams.get('token')
  const [invoiceData, setInvoiceData] = useState()
  const [otpData, setOtpData] = useState(null)

  const [paymentLinkOtpRequired, setPaymentLinkOtpRequired] = useState(false);  // for now i use a state , once new API deployed i will fetch it from backend response
  const [otpViewNowBox,setOtpViewNowBox] = useState(false);
  const transId = useRef(0);
  const transIdpaymentLinkOtpRequired = useRef(false);

  const [invoices, setInvoices] = useState([
    {
      type : "image/png",
      url : RightIcon
    }
  ])
  const [errorState, setErrorState] = useState({ error: false })
  const [otpErrorState, setOtpErrorState] = useState({ error: false })
  const [presignedUrlErrorState, setPresignedUrlErrorState] = useState({ error: false })
  const [otp, setOtp] = useState(['', '', '', '','','']); 

  const handleOTPView = () => {
    setOtpViewNowBox((prev) => !prev);
    // setPaymentLinkOtpRequired((prev) => !prev);

    const params = oldToken
      ? { token: oldToken }
      : { token: token, invoiceId: invoiceId }
       fetchOtpData(params)
  }


  const handleResendOTPView = () => {
    const params = oldToken
      ? { token: oldToken }
      : { token: token, invoiceId: invoiceId }
       fetchOtpData(params)
  }

  // console.log(invoices)



  const handleOTPViewNow = () => {
    // setOtpViewNowBox(false);
    // setPaymentLinkOtpRequired(false);
    fetchPresignedUrl({ transactionId: invoiceData?.transactionId , otp : otp.join('')})
  }


  const [fetchInvoiceData, fetchingInvoiceLoader] = UseApiCall(
    oldToken ? apiGetPaymentDetails : apiGetNewPaymentDetails,
    (res) => {
      if (res?.data?.invoiceResponse?.status === "CANCELLED" || res?.data?.invoiceResponse?.status === "SETTLED_OUTSIDE") {
        setErrorState({
          error: true,
          title: 'This link is no longer valid. Please get in touch with the exporter.',
          subtext: '',
        })
      } else {
        setInvoiceData(res?.data)
        setPaymentLinkOtpRequired(res?.data?.paymentLinkOtpRequired)
        // console.log("transactionId",res?.data.transactionId)
        transId.current = res?.data?.transactionId
        transIdpaymentLinkOtpRequired.current = res?.data?.paymentLinkOtpRequired
        // !paymentLinkOtpRequired && fetchPresignedUrl({ transactionId: invoiceData?.transactionId , otp : otp.join('')})
        if(!transIdpaymentLinkOtpRequired.current){
          fetchPresignedUrl({ transactionId: transId.current , otp : otp.join('')})
        }
      }
    },
    (err) => {
      setErrorState({
        error: true,
        title: JSON.stringify(err?.data?.error_response?.message) || '',
        subtext: 'Please generate a new link.',
      })
    }
  )
  

  const [fetchOtpData] = UseApiCall(
    apiGetOTP,
    async (res) => {
      try {
        // Assuming res.data is an object
        setOtpData(res?.data);
        // Perform other actions if needed
      } catch (error) {
        // Handle any errors that occur during data processing
        console.error('Data processing error:', error);
      }
    },
    async (err) => {
      try {
        // Assuming err.data is an object
        console.error(err?.data?.detail);
        setOtpErrorState({
          error: true,
          title: err?.data?.detail || '',
          subtext: 'Please check your emailId.',
        });
      } catch (error) {
        // Handle any errors that occur during error handling
        console.error('Error handling error:', error);
      }
    }
  );
  


  const [fetchPresignedUrl, fetchingPresignedUrl] = UseApiCall(
    apiGetPaymentInvoices,
    (res) => {
      setOtpViewNowBox(false);
      setPaymentLinkOtpRequired(false);
      setInvoices([])
      // console.log(res.data)
      res.data.map(
        (item) => item.createdBy === 'fideo-backend' && item?.url && getFilesFromS3(item?.url)
      )
    },
    (err) => {
      // console.log(err)
      setPresignedUrlErrorState({
        error: true,
        title: err?.data?.detail || '',
        subtext: 'Not able to fetch Invoices',
      })
    }
  )

  const [getFilesFromS3, fetchingFiles] = UseApiCall(
    fetchFileFromS3,
    (res, headers) => {
      try {
        var blob = new Blob([res], { type: headers['content-type'] })
        let link = window.URL.createObjectURL(blob)
        setInvoices((pre) => [
          ...pre,
          {
            url: link,
            type: headers['content-type'],
          },
        ])
      } catch (error) {
        console.log(error)
      }
    },
    (err)=> {},
    'direct'
  )

  useEffect(() => {
    const params = oldToken
      ? { token: oldToken }
      : { token: token, invoiceId: invoiceId }
    fetchInvoiceData(params)
    
  }, [])

  // useEffect(() => {
  //   if (!paymentLinkOtpRequired) {
  //     const params = oldToken
  //       ? { token: oldToken }
  //       : { token: token, invoiceId: invoiceId };
  //     fetchInvoiceData(params)
  //     // fetchPresignedUrl({ transactionId: invoiceData?.transactionId , otp : otp.join('')})
  //     // fetchPresignedUrl({ transactionId: transId.current , otp : otp.join('')})
  //     // handleOTPViewNow();
  //   }
  // }, []);

  // useEffect(() => {
  //   if (screenSize.width < 768) {
  //     const params = oldToken
  //       ? { token: oldToken }
  //       : { token: token, invoiceId: invoiceId };
  //       handleOTPViewNow();
  //   }
  // }, []);
  

  // useEffect(() => {
  //   if (errorState?.error) throw new Error('Not Found', { status: 404 })
  // }, [errorState])

  const showPaymentDisplay = useSelector(
    (state) => state.modal.showPaymentDisplay
  )

  const handlePayment = () => {
    dispatch(modalActions.showPaymentDisplay())
  }

  function capitalizeFirstLetterOfWords(str) {
    return str
      ?.split(' ')
      ?.map(word => word.charAt(0).toUpperCase() + word.slice(1))
      ?.join(' ');
  }

  return (
    <>
    {otpViewNowBox && <TypeOtp handleResendOTPView={handleResendOTPView}  otpErrorState={otpErrorState} presignedUrlErrorState={presignedUrlErrorState} invoiceData={invoiceData} otp={otp} setOtp={setOtp} handleOTPView={handleOTPView} handleOTPViewNow={handleOTPViewNow} setOtpViewNowBox={setOtpViewNowBox} setPaymentLinkOtpRequired={setPaymentLinkOtpRequired}/>}
    <div className="w-[100vw] min-h-[100%] relative max-w-[1000px] 2xl:max-w-[1300px] self-center flex flex-col">
      {/* {otpViewNowBox && <TypeOtp handleResendOTPView={handleResendOTPView}  otpErrorState={otpErrorState} presignedUrlErrorState={presignedUrlErrorState} invoiceData={invoiceData} otp={otp} setOtp={setOtp} handleOTPView={handleOTPView} handleOTPViewNow={handleOTPViewNow} setOtpViewNowBox={setOtpViewNowBox} setPaymentLinkOtpRequired={setPaymentLinkOtpRequired}/>} */}
      {showPaymentDisplay &&
        ReactDOM.createPortal(
          <PaymentDetailsModal
            contactType={'Customer'}
            invoiceDetails={invoiceData}
          />,
          document.getElementById('modal-root')
        )}
      <div
        className={clsx(
          'relative sm:hidden sticky w- top-0 left-0 right-0 h-[72px] flex flex-row justify-center items-center px-[5%] border-b border-b-[#E8EAEF] bg-[#FFFFFF]'
        )}
      >
        <img
          src={leftPointer}
          alt="left-pointer"
          style={{ cursor: "pointer" }}
          className="w-fit h-[24px] mr-12 absolute left-[5%]"
          onClick={() => {
            goBackInHistory()
          }}
        />
        <p className="font-bold text-4xl text-[#1E333F]">Invoice: {invoiceId}</p>
      </div>

      {
        invoiceData?.leadDetails?.businessName &&
          <div className='sm:hidden my-[24px] w-[100%] self-center flex justify-between px-[16px]'>
            <div className={clsx(`
            flex w-[100%] justify-between
            `)}>
              <div className={clsx(`flex flex-col items-start gap-[4px]`)}>
                <p className={clsx(classes.exporterNameText)}>{invoiceData?.leadDetails?.businessName && capitalizeFirstLetterOfWords(invoiceData?.leadDetails?.businessName)}</p>
                <p className={clsx(classes.exporterDetailsText)}>{`${invoiceData?.leadDetails?.city && capitalizeFirstLetterOfWords(invoiceData?.leadDetails?.city)}, ${invoiceData?.leadDetails?.postalCode}, ${invoiceData?.leadDetails?.countryName && capitalizeFirstLetterOfWords(invoiceData?.leadDetails?.countryName)}`}</p>
                <p className={clsx(classes.exporterDetailsText)}>Email Id: {invoiceData?.leadDetails?.email}</p>
              </div>
              <div className="min-w-[72px] min-h-[72px] max-w-[72px] max-h-[72px] rounded-[12px] bg-[#DEE3EC]">
                <img
                  src={ExporterIcon}
                  className="relative top-[20px] left-[20px]"
                  style={{
                    borderRadius: "8px",
                  }}
                />
              </div>
            </div>
          </div>
      }

      <div className='hidden sm:flex mb-[32px] w-[90%] self-center flex justify-between'>
        <div className='hidden sm:block'>
          <div className='flex items-center gap-[8px] mb-[8px]'>
            <img
              src={leftPointer}
              alt="left-pointer"
              style={{ cursor: "pointer" }}
              // className="block sm:hidden absolute left-8"
              onClick={() => {
                goBackInHistory()
              }}
            />
            <p
              style={{
                color: "#1E333F",
                fontFamily: "Inter",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: "500",
                lineHeight: "20px"
              }}
            >
              View all invoices
            </p>
          </div>
          <p className="hidden self-center sm:block font-bold text-5xl text-[#1E333F]">
            Invoice: {invoiceId}
          </p>
        </div>
        {
          invoiceData?.leadDetails?.businessName &&
            <div className={clsx(`flex gap-[16px]`)}>
              <div className={clsx(`flex flex-col items-end gap-[4px]`)}>
                <p className={clsx(classes.exporterNameText)}>{invoiceData?.leadDetails?.businessName && capitalizeFirstLetterOfWords(invoiceData?.leadDetails?.businessName)}</p>
                <p className={clsx(classes.exporterDetailsText)}>{`${invoiceData?.leadDetails?.city && capitalizeFirstLetterOfWords(invoiceData?.leadDetails?.city)}, ${invoiceData?.leadDetails?.postalCode}, ${invoiceData?.leadDetails?.countryName && capitalizeFirstLetterOfWords(invoiceData?.leadDetails?.countryName)}`}</p>
                <p className={clsx(classes.exporterDetailsText)}>Email Id: {invoiceData?.leadDetails?.email}</p>
              </div>
              <div className="min-w-[72px] min-h-[72px] max-w-[72px] max-h-[72px] rounded-[12px] bg-[#DEE3EC]">
                <img
                  src={ExporterIcon}
                  className="relative top-[20px] left-[20px]"
                  style={{
                    borderRadius: "8px",
                  }}
                />
              </div>
            </div>
        }
      </div>
      {errorState?.error ? (
        <div className="flex flex-row self-center m-auto p-4 border border-[#1E333F] border-dashed rounded-[4px] mt-[60px]">
          <img src={WarningIcon} className="w-10 h-10 mr-4" />
          <div className="flex flex-col ">
            <p className="text-3xl font-semibold">{errorState?.title || ''}</p>
            <p className="text-2xl font-medium">{errorState?.subtext || ''}</p>
          </div>
        </div>
      ) : (
        <div className="w-[90%] flex flex-col sm:grid grid-cols-5 self-center justify-center sm:flex-row -sm:relative h-auto -sm:pb-12 gap-8 sm:gap-16" >
          <div className="w-full -sm:hidden col-span-3 order-2 sm:order-1">
            <div>
              <p className="font-bold text-[#1E333F] text-3xl mb-10">
                Invoice
              </p>
              <div className={`sm:pb-12 relative`} >
                <div className={`sm:pb-12 relative ${paymentLinkOtpRequired ? 'blur-[2px]' : ''}`}>
                  {screenSize.width >= 644 &&
                    <FileViewer
                      format={['document', 'image']}
                      displayUrls={invoices}
                      downloadPrefix={invoiceData?.invoiceResponse?.invoiceId}
                      showInnerTrue={true}
                      />
                  }
                </div>
                {paymentLinkOtpRequired && <EnterOtp handleOTPView={handleOTPView} setOtpViewNowBox={setOtpViewNowBox} setPaymentLinkOtpRequired={setPaymentLinkOtpRequired} />}
              </div>
            </div>
          </div>
          <div
            className={clsx(
              'flex sm:relative w-full order-1 flex-1 sm:order-2 flex-col h-auto col-span-2'
            )}
          >
            <div className="sm:flex z-10 flex-row items-center justify-center sm:justify-between pb-[20px] mb-2">
              <p className="text-3xl font-bold">Invoice Details</p>
            </div>
            <div className="p-8 flex flex-col gap-6 bg-[#FFFFFF] border border-[#ECEFF2] rounded-[12px] ">
              <div className="flex flex-row items-center justify-between pb-6 border-b border-[#ECEFF2]">
                <p className="text-[#1E333F80] text-2xl font-medium min-w-[110px]">
                  Invoice Amount
                </p>
                <p className="text-[#1E333F] text-4xl font-semibold" style={{ overflowWrap: "anywhere" }}>
                  {invoiceData?.invoiceResponse?.invoiceCurrency?.sign}
                  {invoiceData?.invoiceResponse?.invoiceAmount?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                </p>
              </div>
              <div className="flex flex-row justify-between pb-6 border-b border-[#ECEFF2] gap-[8px] items-end">
                <div className="flex flex-col items-start gap-1" style={{ overflowWrap: "anywhere" }}>
                  <p className="text-[#1E333F] text-3xl font-medium">
                    {invoiceData?.invoiceResponse?.invoiceId}
                  </p>
                  <p className="text-[#1E333F80] text-2xl font-medium">
                    Invoice ID
                  </p>
                </div>
                <div className="flex flex-col items-end gap-1 min-w-[100px]" style={{ overflowWrap: "anywhere" }}>
                  <p className="text-[#1E333F] text-3xl font-medium">
                    {invoiceData?.invoiceResponse?.purposeCode}
                  </p>
                  <p className="text-[#1E333F80] text-2xl font-medium">
                    Purpose Code
                  </p>
                </div>
              </div>
              <div className="flex flex-row items-center justify-between pb-6 border-b border-[#ECEFF2]">
                <div className="flex flex-col items-start gap-1">
                  <p className="text-[#1E333F] text-3xl font-medium">
                    {invoiceData?.invoiceResponse?.issueDate
                      ? dayjs(invoiceData?.invoiceResponse?.issueDate).format(
                          'DD/MM/YY'
                        )
                      : '-'}
                  </p>
                  <p className="text-[#1E333F80] text-2xl font-medium">
                    Issue Date
                  </p>
                </div>
                <div className="flex flex-col items-end gap-1">
                  <p className="text-[#1E333F] text-3xl font-medium">
                    {invoiceData?.invoiceResponse?.blDate
                      ? dayjs(invoiceData?.invoiceResponse?.blDate).format(
                          'DD/MM/YY'
                        )
                      : '--'}
                  </p>
                  <p className="text-[#1E333F80] text-2xl font-medium">
                    BL Date
                  </p>
                </div>
              </div>
              <div className="flex flex-row items-center justify-between pb-6 border-b border-[#ECEFF2]">
                <div className="flex flex-col items-start gap-1">
                  <p className="text-[#1E333F] text-3xl font-medium">
                    {invoiceData?.invoiceResponse?.dueDate
                      ? dayjs(invoiceData?.invoiceResponse?.dueDate).format(
                          'DD/MM/YY'
                        )
                      : '--'}
                  </p>
                  <p className="text-[#1E333F80] text-2xl font-medium">
                    Due Date
                  </p>
                </div>
              </div>
              {invoiceData?.invoiceResponse?.invoiceDescription && (
                <div className="flex flex-col items-start gap-2 pb-6 border-b border-[#ECEFF2]">
                  <p className="text-[#1E333F80] text-2xl">
                    Invoice Description
                  </p>
                  <p className="text-[#1E333F] text-3xl font-medium">
                    {invoiceData?.invoiceResponse?.invoiceDescription || ''}
                  </p>
                </div>
              )}
              <div className="sm:hidden flex flex-col items-start gap-2  border-b border-[#ECEFF2]">
                <p className="text-[#1E333F80] text-2xl">Attachments</p>
                <div className="w-full">
                  {screenSize.width < 644 &&
                    <DocumentSheet
                      invoices={invoices}
                      invoiceId={invoiceData?.invoiceResponse?.invoiceId}
                      setOtpViewNowBox={setOtpViewNowBox}
                      otpViewNowBox={otpViewNowBox}
                      setPaymentLinkOtpRequired={setPaymentLinkOtpRequired}
                      paymentLinkOtpRequired={paymentLinkOtpRequired}
                      handleResendOTPView={handleResendOTPView}
                      otpErrorState={otpErrorState}
                      presignedUrlErrorState={presignedUrlErrorState}
                      invoiceData={invoiceData}
                      otp={otp}
                      setOtp={setOtp}
                      handleOTPView={handleOTPView}
                      handleOTPViewNow={handleOTPViewNow}
                    />
                  }
                </div>
              </div>
              {invoiceData?.customerDTO && (
                <div className="flex flex-col gap-5">
                  <p className="text-[#1E333F80] text-2xl font-medium">
                    Your Details
                  </p>
                  <div className="w-full bg-[#F4F6FA] flex flex-row items-center p-[16px] gap-5 rounded-[12px]">
                    <div className="w-[48px] aspect-square rounded-full flex items-center justify-center bg-[#DBF0CC] text-2xl text-[#80B772]">
                      {invoiceData?.customerDTO?.customerName
                        ?.split(' ')
                        .map((char) => char.charAt(0).toUpperCase())
                        .slice(0, 2)
                        .join('')}
                    </div>
                    <ContactInfo
                      name={invoiceData?.customerDTO?.customerName}
                      country={invoiceData?.customerDTO?.country}
                      email={invoiceData?.customerDTO?.emailAddress}
                    />
                  </div>
                </div>
              )}

              {/* {invoiceData?.userResponseDTO && (
                <div className="flex flex-col gap-5">
                  <p className="text-[#1E333F80] text-2xl font-medium">
                    Paying to
                  </p>
                  <div className="w-full bg-[#F4F6FA] flex flex-row items-center p-5 gap-5 rounded-[12px]">
                    <div className="w-[48px] aspect-square rounded-full flex items-center justify-center bg-[#FFEBEB] text-2xl text-[#FFB2B2]">
                      {invoiceData?.userResponseDTO?.businessName
                        ?.split(' ')
                        .map((char) => char.charAt(0).toUpperCase())
                        .slice(0, 2)
                        .join('')}
                    </div>
                    <ContactInfo
                      name={invoiceData?.userResponseDTO?.businessName}
                      country={invoiceData?.userResponseDTO?.country}
                      email={invoiceData?.userResponseDTO?.email}
                    />
                  </div>
                </div>
              )} */}
            </div>
            <div className="p-4 mt-5 sm:p-8 flex flex-col gap-6 bg-transparent rounded-[12px] ">
              {/* <p className="text-[#1E333F] text-2xl font-medium">Payment</p> */}
              <Button
                text="Pay now"
                ref={buttonRef}
                icon={RightIcon}
                btnClassIcon={'order-2 w-[24px] h-[24px] ml-2'}
                onClick={handlePayment}
              />
              <div className="flex flex-row gap-3 items-center justify-center self-center">
                <img
                  src={LockIcon}
                  alt="secure"
                  className="w-6 aspect-square relative "
                />
                <p className="text-xl text-[#1E333FB2] font-medium">
                  Secured Payments
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
    </>
  )
}

export default MakePayment
