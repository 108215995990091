import classes from 'Components/ReportTable/UploadTable.module.scss'
import clsx from 'clsx'
import PaymentStatusTag from './PaymentStatusTag'
import FileIcon from "assets/Icons/file-icon.svg";
import ArrowIcon from "assets/Icons/chevron-d.svg"

const ReceivablesReportTable = (props) => {
  return (
    <div className="mb-[48px] -sm:mb-[12px]">
      {/* {showInvoiceDisplay &&
        ReactDOM.createPortal(
          <InvoiceDetailsModal
            activeTab={activeTab}
            invoice={selectedInvoice}
            mobileTableData
          />,
          document.getElementById('modal-root')
        )} */}
      <>
        <div className={clsx(classes.uploaderBox)}>
          <div className={clsx(classes.heading)}>
            Uploaded Invoices
          </div>
          <div className={clsx(classes.data)}>
            <div className={clsx(classes.dataLeft)}>
              <div className={clsx(`min-w-[48px] h-[48px] w-[48px] rounded-[8px] bg-[#F4F6FA] relative`, classes.fileIcon)}>
                <img src={FileIcon} alt="file" className="h-[20px] w-[16px] relative top-[14px] left-[16px]" />
              </div>
              <div className={clsx(classes.combineBoth)}>
                <p className={clsx(classes.invoicesText)}>{props.count} Invoices</p>
                <PaymentStatusTag status={"UNDER PROCESSING"} type="light" />
              </div>
              <p className={clsx(classes.normalInvoicesText)}>
                You’ll be notified once these invoices are created
              </p>
            </div>
            <div className={clsx(classes.dataRight)}>
              <div className="h-[48px] w-[16px] rounded-[8px] relative" style={{ display: "none" }}>
                <img src={ArrowIcon} alt="file" className="h-[20px] w-[16px] relative top-[14px]" />
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  )
}

export default ReceivablesReportTable
