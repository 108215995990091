import { useRef, useState, useCallback } from 'react';
import { useSnackbar } from 'notistack';
import { SOMETHING_WRONG } from 'constants';
import { useNavigate } from 'react-router-dom';
import { apiSignOut } from 'services';
import { SIGN_OUT } from 'constants';
import { useDispatch } from "react-redux";
import CryptoJS from 'crypto-js';
import { doEncryption } from 'constants';
import { handleDecrypt } from './handleDecryption';


// interface ApiConfigs {
//   successMsg?: string;
//   errorMsg?: string;
//   callBack?: ({
//     isError,
//     response,
//   }: {
//     isError: boolean;
//     response: Record<string, any>;
//   }) => void;
// }

// type ReturnProps<T> = [
//   callApi: (params?: T, configs?: ApiConfigs) => void,
//   loading: boolean,
//   data: any,
//   error: any
// ];

/**
 * ## UseApiCall - is a custom hook, use this to make HTTP API calls
 * ----
 * ### Returns - [makeCallApiFunction, loadingState, successData, errorData]
 * ----
 * @param apiCallService API call service function, that must be defined in service file
 * @param onSuccess optional callback, called on api success
 * @param onFail optional callback, called on api failure
 */
function UseApiCall(
  apiCallService,
  onSuccess,
  onFail,
  decrypt=''
) {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate()
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const retryCount = useRef(0);
  const dispatch = useDispatch();

  // const [userData, setUserData] = useRecoilState(userAuthDetails);

  // adding log event to make a api call on logs whenever it receives a logData

  // const logEvent = (changedData: any) => {
  //   if (logData && userData) {
  //     const fullLogData = {
  //       ...logData,
  //       change_data: { type: "raw", change: changedData },
  //       created_by: userData.id,
  //       email: userData.email || ''
  //     }
  //     apiCreateLog(fullLogData)
  //   }
  // }

  /**
   *
   * @param params - directly passed to api service function
   * @param configs - optional configs
   *  - successMsg - optional success message
   *  - errorMsg - optional error message
   *  - hideDefaultError - optional, if true, default error message will not be shown
   *  - callBack - optional, if true, callback will be called on success
   * */

  const callApi = async (params, configs = {}) => {
    setLoading(true);
    apiCallService(params || {})
      .then((response) => {
        if (configs.successMsg)
          enqueueSnackbar(configs.successMsg, { variant: 'success' });
        const decryptedData = (doEncryption && decrypt === '') ? handleDecrypt(response.data) : response.data;
        setData(decryptedData);
        if (onSuccess) {

          onSuccess(decryptedData, response.headers)

        };

        if (configs.callBack)

          configs.callBack({ isError: false, response: decryptedData });
      })
      .catch((err) => {

        const apiErrorMessage = '';



        let formatMessage;
        //  Not Found and Expired
        if ((Number(err?.status) === 400 && [601, 603].includes(err?.data?.status_code)) || (Number(err?.status) === 401 && [602, 604].includes(err?.data?.status_code))) {
          //retryCount.current < 2
          // onUnauthenticated(params, configs);
          dispatch({type: 'CLEAR_STATE'})
          navigate("/login?mode=email");
        } else {
          if (!err?.options?.hideDefaultError) {
            // enqueueSnackbar(
            //   apiErrorMessage ||
            //   configs.errorMsg ||
            //   err.options?.errMsg ||
            //   formatMessage ||
              
            //   {
            //     variant: 'error',
            //   }
            // );
          }
          setError(err);
          if (onFail) {
            onFail(err);
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const signOut = useCallback(() => {
    enqueueSnackbar(SIGN_OUT, { variant: 'error' });
    apiSignOut().then().catch((err) => console.log(err))
    if(navigate) navigate('/login?mode=email')
  }, [enqueueSnackbar]);


  const onUnauthenticated = useCallback(
    (params, configs) => {

      signOut();
    },
    [ signOut]

  );

  return [callApi, loading, data, error];
}

export default UseApiCall