import File from 'assets/Images/file.svg'
import Button from 'Layout/Button'
import arrowDown from 'assets/Images/arrow_down.svg'
import classes from './NoInvoicesView.module.scss'
import clsx from 'clsx'
import { useDispatch } from 'react-redux'
import { modalActions } from 'Store/modal-redux'

const NoInvoicesView = () => {
  const dispatch = useDispatch()
  return (
    <div className="backdrop-blur-xl border border-[#ECEFF2] flex items-center justify-center rounded-[12px] bg-[#fff] py-[60px] px-[24px]">
      <div className="w-[80%]">
        <div className="flex flex-col items-center">
          <div className="w-[88px] h-[88px] mb-5 flex items-center justify-center rounded-full bg-[#F4F6FA] relative">
            <img src={File} alt="User" className="w-20 h-20" />
            <div className={clsx(classes.littleDot, 'rounded-full')}>
              <span className="flex justify-center items-center">0</span>
            </div>
          </div>
        </div>
        <p className="text-2xl font-normal text-center text-[#181C30]">
          Currently, there is no invoice to display.
          <br /> Kindly generate an invoice to commence receiving payments.
        </p>
        <div className={clsx(classes.button, '-md:flex-col gap-5')}>
          <Button
            text={`Receive Payment`}
            icon={arrowDown}
            onClick={() => {
              dispatch(modalActions.receivePayment())
            }}
            className={clsx(classes.btn, 'mx-auto mt-[24px]')}
            btnClass={clsx(classes.btnClass)}
            btnClassIcon={clsx(classes.btnClass_icon)}
            btnClassText={clsx(classes.btnClass_text)}
          />
        </div>
      </div>
    </div>
  )
}

export default NoInvoicesView
