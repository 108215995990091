import {
  Form,
  Link,
  redirect,
  useActionData,
  useSearchParams,
  useSubmit,
} from "react-router-dom";
import classes from "Pages/Authentication/ForgotPassword.module.scss";
import leftPointer from "assets/Images/left-pointer.svg";
import TextInput from "Layout/TextInput";
import Button from "Layout/Button";
import greenTick from "assets/Images/green-tick.svg";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { ForgotPasswordActions, AuthActions } from "Store/auth-redux";
import { PasswordSetActions } from "Store/auth-redux";
import UseApiCall from "hooks/useApiCall";
import { apiResetPassword } from "services";

const emailValidation = [
  (value) => value.trim() !== "",
  (value) => /[a-zA-Z0-9_\.\-]+@[a-zA-Z0-9\-]+\.[a-zA-Z0-9\-.]+/.test(value),
];

const ForgetPassword = () => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const [linkSend, setLinkSend] = useState(false);
  const [linkSendFirstTime, setLinkSendFirstTime] = useState(false);

  const [emailInput, setEmailInput] = useState(searchParams.get("email") || "");
  const isEmailValid = useSelector(
    (state) => state.forgotPassword.isEmailValid
  );
  const showBackendError = useSelector(
    (state) => state.auth.showBackendError,
    shallowEqual
  );
  const [resetPassword, resetting] = UseApiCall(apiResetPassword, (data) => {
    // window.open(data?.data, '_self')
    setTimeout(() => {
      setLinkSend(true);
      setLinkSendFirstTime(true);
    }, 1000);
  });

  let data = useActionData();
  useEffect(() => {
    if (data !== undefined && data !== null) {
      dispatch(AuthActions.setShowBackendError(true));
    } else dispatch(AuthActions.setShowBackendError(false));
  }, [data]);

  const submitHandler = (e) => {
    e.preventDefault();
    if (!isEmailValid) return;

    resetPassword({ email: emailInput });
  };

  let btnText,
    disabled = true;
  let btnClass = `${classes.btn} ${classes.disabled}`;

  if (!isEmailValid) {
    btnText = "Send Reset Link";
    disabled = true;
  } else if (resetting) {
    btnText = "Sending Reset Link...";
    disabled = true;
  } else if (linkSend) {
    btnText = "Verification Link Sent";
    btnClass = `${classes.btn} ${classes.link_sent} ${classes.disabled}`;
    disabled = true;
  } else {
    btnText = "Send Reset Link";
    btnClass = `${classes.btn} ${classes.abled}`;
    disabled = false;
  }

  return (
    <div className={classes.forget_password}>
      <Link to="/" className={classes.link}>
        <img
          src={leftPointer}
          alt="left-pointer"
          className={classes.left_pointer}
        />
        <div className={classes.back}>Back</div>
      </Link>
      <div className={classes.header}>Forgot Password</div>
      <div className={classes.text}>Enter your registered email address</div>
      <Form>
        <TextInput
          type="text"
          text="Email Address"
          className={`${classes.input}`}
          disabled={resetting || linkSend}
          errorMessage="Please enter a valid email address"
          validation={emailValidation}
          inputValidity={(value) =>
            dispatch(ForgotPasswordActions.setEmailValidity(value))
          }
          setInput={(value) => {
            setEmailInput(value);
          }}
          enteredInput={emailInput}
          maxLength={128}
        />
        <Button
          className={btnClass}
          text={btnText}
          icon={linkSend ? greenTick : undefined}
          disabled={disabled}
          type="submit"
          onClick={submitHandler}
        />
      </Form>
      {linkSendFirstTime && (
        <div className={classes.footer}>
          Didn't receive the Link?{" "}
          <div
            onClick={(e) => {
              setLinkSend(false);
              submitHandler(e);
            }}
            className={`${classes.footer_link} ${
              resetting && classes.footer_link_disabled
            }`}
          >
            Resend
          </div>
        </div>
      )}
    </div>
  );
};

export default ForgetPassword;

// export async function action({request, params}) {
//     const data = await request.formData();

//     // console.log("password", data.get("password"));
//     // console.log("mobile",  data.get("mobileNumber"));
//     let failure = false
//     const resp = await fetch(BASE_URL + `/reset-password?email=${data.get("email")}`, {
//         method: "POST",
//         headers: {
//             "Content-Type": "application/json",
//         }
//     }).then(response => {
//         if (!response.ok || response.status / 100 !== 2) {
//             failure = true
//             return response.json().error_response
//         }
//     }).catch(error => {
//             failure = true
//             if (error.status !== 200 || !error.ok)
//                 return error.json().error_response;
//             else return 'Something went wrong..';
//         })

//     return failure ? resp : null
// }
