import React from 'react';
import classes from "./Profile.module.scss";
import ContentContainer from './profileComponents/ContentContainer';
import ContentWrapper from './profileComponents/ContentWrapper';
import Header from './profileComponents/Header';
import ContentBox from './profileComponents/ContentBox';
import Content from './profileComponents/Content';
import GrayBox from './profileComponents/GrayBox';

const BusinessDetails = ({businessDetails}) => {
    const {company_details, company_identification_details, company_business_director_details, company_business_owner_details, company_account_user_details} = businessDetails || {};
    const {brand_name, business_address_line1, business_address_line2, business_type, product_description, website} = company_details || {};
    const {cin_number, gstin_number} = company_identification_details || {};
  return (
    <div className={`${classes.businessDetails}`}>
        <ContentContainer>
            <Header text1={'Company Particulars'} text2={''} />
            <ContentWrapper>
                <ContentBox>
                    <Content head={'Brand Name / Doing Business as'} para={brand_name} />
                    <Content head={'Website'} para={website} />
                </ContentBox>
                <ContentBox>
                    <Content head={'Business Type'} para={business_type} />
                    <Content head={'Describe your Product / Services'} para={product_description} />
                </ContentBox>
                <ContentBox>
                    <Content head={'Business Address'} para={`${business_address_line1 || '--'} ${business_address_line2 || ''}`} />
                </ContentBox>
            </ContentWrapper>
        </ContentContainer>
        {/* <GrayBox/> */}
        <ContentContainer>
            <Header text1={'Registration & Identification'} text2={''} />
            <ContentWrapper>
                <ContentBox>
                    <Content head={'CIN Number'} para={cin_number} />
                    <Content head={'GSTIN Number'} para={gstin_number} />
                </ContentBox>
            </ContentWrapper>
        </ContentContainer>
        <ContentContainer>
            <Header text1={'Business Directors'} text2={'Directors with >10% shareholding.'} />
            <ContentWrapper>
                <ContentBox>
                    {/* {(company_business_director_details?.map((director,index) => (
                        <Content head={`Director ${index + 1}`} para={`${director.director_first_name} ${director.director_last_name}`}  key={index}/>
                    ))) }

                    {((company_business_director_details?.length == 0) || (true)) && 
                        <>
                            <Content head={`Director 1`} para={'--'}/>
                            <Content head={`Director 2`} para={'--'}/>
                        </>
                    } */}

                    {((company_business_director_details?.length != 0)) ? (company_business_director_details?.map((director,index) => (
                        <Content head={`Director ${index + 1}`} para={`${director.director_first_name} ${director.director_last_name}`}  key={index}/>
                    ))) : 
                    (<>
                        <Content head={`Director 1`} para={'--'}/>
                    </>
                    )}
                </ContentBox>
            </ContentWrapper>
        </ContentContainer>
        <ContentContainer>
            <Header text1={'Business Owners'} text2={'Owners with >10% shareholding.'} />
            <ContentWrapper>
                <ContentBox>
                    {company_business_owner_details?.length != 0 ? (company_business_owner_details?.map((owner,index) => (
                        <Content head={`Owner ${index + 1}`} para={`${owner.owner_first_name} ${owner.owner_last_name}`} key={index}/>
                    ))) : 
                    (<>
                        <Content head={`Owner 1`} para={'--'}/>
                    </>
                    )}
                </ContentBox>
            </ContentWrapper>
        </ContentContainer>
        <ContentContainer>
            <Header text1={'Fideo Account Users'} text2={''} />
            <ContentWrapper>
                <ContentBox>
                    {company_account_user_details?.length != 0 ? (company_account_user_details?.map((user,index) => (
                        <Content head={`User ${index + 1}`} para={`${user.user_first_name} ${user.user_last_name}`} key={index}/>
                    ))) : 
                    (
                    <>
                        <Content head={`User 1`} para={'--'}/>
                    </>
                    )}
                </ContentBox>
            </ContentWrapper>
        </ContentContainer>
    </div>
  )
}

export default BusinessDetails