// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ButtonSet_button__fbYRl {
  display: none;
  padding: 1.2rem 1.6rem;
  background-color: white;
  width: 100%;
  position: fixed;
  top: 100%;
  left: 0;
  transform: translateY(-100%);
}
.ButtonSet_button__fbYRl .ButtonSet_btn__VIS8d:not(:last-child) {
  margin-right: 1.2rem;
}
.ButtonSet_button__fbYRl .ButtonSet_btn__VIS8d {
  flex: 1 1;
}
@media (max-width: 800px) {
  .ButtonSet_button__fbYRl {
    display: flex;
  }
}`, "",{"version":3,"sources":["webpack://./src/Components/atom/ButtonSet/ButtonSet.module.scss"],"names":[],"mappings":"AAAA;EAEI,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,WAAA;EACA,eAAA;EACA,SAAA;EACA,OAAA;EACA,4BAAA;AAAJ;AAEI;EACE,oBAAA;AAAN;AAGI;EACE,SAAA;AADN;AAII;EAnBJ;IAoBM,aAAA;EADJ;AACF","sourcesContent":[".button {\n\n    display: none;\n    padding: 1.2rem 1.6rem;\n    background-color: white;\n    width: 100%;\n    position: fixed;\n    top: 100%;\n    left: 0;\n    transform: translateY(-100%);\n  \n    .btn:not(:last-child) {\n      margin-right: 1.2rem;\n    }\n  \n    .btn {\n      flex: 1;\n    }\n  \n    @media (max-width: 800px) {\n      display: flex;\n  \n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": `ButtonSet_button__fbYRl`,
	"btn": `ButtonSet_btn__VIS8d`
};
export default ___CSS_LOADER_EXPORT___;
