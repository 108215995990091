import react, {useEffect, useState} from "react";
import classes from "./CreateInvoice.module.scss";
import ReactDOM from "react-dom";
import arrowLeft from "assets/Icons/arrowLeft.svg";
import eyeOpen from "assets/Icons/eyeOpen.svg";
import billToLight from "assets/Icons/billToLight.svg";
import billToDark from "assets/Icons/billToDark.svg";
import itemsPriceLight from "assets/Icons/itemsPriceLight.svg";
import itemsPriceDark from "assets/Icons/itemsPriceDark.svg";
import otherDetailsLight from "assets/Icons/otherDetailsLight.svg";
import otherDetailsDark from "assets/Icons/otherDetailsDark.svg";
import chevronUp from "assets/Icons/chevron-upNew.svg";
import chevronDown from "assets/Icons/chevron-downNew.svg";
import greenStep from "assets/Icons/greenStep.svg";
import Ellipse from "assets/Icons/Ellipse.svg";
import moreIcon from "../../assets/Icons/Group 356.svg";
// import moreIcon from "../../assets/Icons/close-white-icon.svg";

import PercentageLoader from "./PercentageLoader";
import {Progress} from "antd";
import {modalActions} from "../../Store/modal-redux";
import {useDispatch, useSelector} from "react-redux";
import ItemsPrice from "./ItemsPrice";
import ContactBillTo from "./ContactBillTo";
import BankDetails from "./BankDetails";
import clsx from "clsx";
import OtherDetails from "./OtherDetails";
import Button from "../../Layout/Button";
import PreviewInvoice from "./PreviewInvoice";
import UseApiCall from "../../hooks/useApiCall";
import {
    apiCreateRealInvoice,
    apiCreateTransaction,
    apiGetBankList, apiGetCustomerCurrencies,
    apiGetInvoiceDetails, apiGetPurposeCodeData,
    apiPreviewInvoice
} from "../../services";
import {enqueueSnackbar} from "notistack";
import {showInvoiceVerificationPrompt} from "../../utils/layout";
import {CustomersActions, DashboardSummaryActions, ReceivablesActions} from "../../Store/customer-redux";
import {useRecoilValue} from "recoil";
import {modalRefState} from "../../recoil/layoutRef";
import {baseUrl} from "../../constants/Network";
import {BankAccountActions} from "../../Store/bank-redux";
import MobileInvoiceSuccessPrompt from "../atom/MobileInvoiceSuccess/MobileInvoiceSuccessPrompt";
import Modal from "../../Layout/Modal";
import Backdrop from "../../Layout/Backdrop";
import dayjs from "dayjs";
import {useNavigate} from "react-router-dom";
import {GlobalActions} from "../../Store/global-redux";
import {handleDecrypt} from "../../hooks/handleDecryption";
import { doEncryption } from 'constants';

const CreateInvoice = (props) => {
    const { selectedInvoiceId } = props
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const [transactionId, setTransactionId] = useState(null);
    const [stepNo, setStepNo] = useState("2");
    const modalRef = useRecoilValue(modalRefState)
    const [whichOpen, setWhichOpen] = useState("items");
    const [billToOpen, setBillToOpen] = useState(false);
    const [itemsPrice, setItemsPrice] = useState(false);
    const [bankDetails, setBankDetails] = useState(false);
    const [otherDetails, setOtherDetails] = useState(false);
    const [saveBillTo, setSaveBillTo] = useState(true);
    const [percentValue, setPercentValue] = useState(10);
    const [previewValue, setPreviewValue] = useState(null);
    const passOnContact = useSelector(state => state.dashboardSummary.passOnContact);
    const showPreviewDisplay = useSelector((state) => state.modal.showPreviewDisplay);
    const mobileInvoiceSuccess = useSelector((state) => state.modal.mobileInvoiceSuccess);

    const [itemType, setItemType] = useState('HSN');
    const [curr, setCurr] = useState('USD');


    // const [selectedAccountDetails, setSelectedAccountDetails] = useState(null);
    const [accDetails,setAccDetails] = useState([]);
    const [localAcc, setLocalAcc] = useState([]);
    const [intAcc, setIntAcc] = useState([]);
    const [loading,setLoading] = useState(true);


    const [subTotal, setSubTotal] = useState(null);
    const [discount, setDiscount] = useState(null);
    const [taxValue, setTaxValue] = useState(null);
    const [fobShipment, setFobShipment] = useState(null);
    const [grandTotal, setGrandTotal] = useState(null);
    const [tax, setTax] = useState('VAT');

    const [taxName, setTaxName] = useState(null);
    const [taxDescription, setTaxDescription] = useState(null);
    // const [bankIdList, setBankIdList] = useState();

    const [primaryBank, setPrimaryBank] = useState(null);
    const [isInvoiceIdValid, setIsInvoiceIdValid] = useState(false)
    const [ispurposeCodeValid, setIsPurposeCodeValid] = useState(false)
    const [invoiceId, setInvoiceId] = useState(null);
    const [purposeCode, setPurposeCode] = useState(null);
    const [issueDate, setIssueDate] = useState(dayjs());


    const [dueDate, setDueDate] = useState();
    const [blDate , setBLDate] = useState();
    const [paymentTerms, setPaymentTerms] = useState(null);
    const [generalTerms, setGeneralTerms] = useState(null);
    const [shipmentTerms, setShipmentTerms] = useState(null);
    const [addNotesTerms, setAddNotesTerms] = useState(null);
    const [whichSelect,setWhichSelect] = useState("local");
    const [currencyCodeSign, setCurrencyCodeSign] = useState('$');
    const [consigneeBuyer, setConsigneeBuyer] = useState(null);
    const [poNumberDate, setPoNumberDate] = useState(null);
    const [countryFinalDestination, setCountryFinalDestination] = useState(null);
    const [countryOriginGoods, setCountryOriginGoods] = useState(itemType === "HSN" ? 'India' : null);
    const [mode, setMode] = useState('CREATE');
    const [invoiceStatus, setInvoiceStatus] = useState('CREATE');
    const [key, setKey] = useState(false);
    const [allBanks, setAllBanks] = useState([]);
    const [purposeCodeArray, setPurposeCodeArray] = useState([])
    const [customerCurrencies, setCustomerCurrencies] = useState({})



    const [customerId, setCustomerId] = useState(passOnContact?.id);



    const [lineItems, setLineItems] = useState(
        [
            {
                itemName: null,
                itemType:itemType,
                itemTypeCode: null,
                quantity: null,
                price: null,
                total: null
            }
        ]
    );

    const handleCount = (sign) => {
        if (sign === "ALL") {
            setCurrencyCodeSign('$')
        }
        else if (sign === 'USD') {
            setCurrencyCodeSign('$')
        } else if (sign === 'CAD') {
            setCurrencyCodeSign('CA$')
        } else if (sign === 'GBP') {
            setCurrencyCodeSign('£')
        } else if (sign === 'EUR') {
            setCurrencyCodeSign('€')
        }else if (sign === 'AUD') {
            setCurrencyCodeSign('AU$')
        }else if (sign === 'AED') {
            setCurrencyCodeSign('د.إ')
        }else if (sign === 'CHF') {
            setCurrencyCodeSign('CHF')
            // setCurrencyCodeSign('Fr.')
        }else if (sign === 'CZK') {
            setCurrencyCodeSign('Kč')
        }else if (sign === 'DKK') {
            setCurrencyCodeSign('Kr')
        }else if (sign === 'HKD') {
            setCurrencyCodeSign('HK$')
        }else if (sign === 'NOK') {
            setCurrencyCodeSign('kr')
        }else if (sign === 'PLN') {
            setCurrencyCodeSign('zł')
        }else if (sign === 'SEK') {
            setCurrencyCodeSign('kr')
        }else if (sign === 'SGD') {
            setCurrencyCodeSign('S$')
        }
    }

    const handleSaveAndcontinue = (step,event) => {
        if(step === "2"){
            setPercentValue(10)
        }else if(step === "3" && lineItems[0].itemName !== null){
            setPercentValue(70)
        }else if(step === "4" && accDetails?.length !== 0 ){
            setPercentValue(80)
        }

        event.stopPropagation();

        setStepNo(step)
    }



    const handleBillTo = () => {
        setBillToOpen(!billToOpen)
        setStepNo("1")
    }

    const handleItemsPrice = () => {
        setItemsPrice(!itemsPrice)
        setStepNo("2")
    }

    const handleBankDetails = () => {
        setBankDetails(!bankDetails)
        setStepNo("3")
    }

    const handleOtherDetails = () => {
        setOtherDetails(!otherDetails)
        setStepNo("4")
    }





    const [screenWidth, setScreenWidth] = useState(getCurrentDimension());
    function getCurrentDimension() {
        return  window.innerWidth
    }
    useEffect(() => {
        const updateDimension = () => {
            setScreenWidth(getCurrentDimension());
        };
        window.addEventListener("resize", updateDimension);
        return () => {
            window.removeEventListener("resize", updateDimension);
        };
    }, [screenWidth]);

    const [createTransaction, creatingTransactionLoader] = UseApiCall(
        apiCreateTransaction,
        (res) => {
            setTransactionId(res.data)
        }, (err) => {
            enqueueSnackbar(`${err?.data?.error_response?.message} ${err?.data?.error_response?.errors !== null ? JSON.stringify(err?.data?.error_response?.errors[0]) : ""}`, { variant: 'error' })
        }
    );


    useEffect(() => {
        createTransaction({ customerId: passOnContact?.id });
    }, [passOnContact?.id])

    const [getBankAccounts, fetchingBanks] = UseApiCall(apiGetBankList, (res) => {
        setAllBanks(res?.data);
        setPrimaryBank(res?.data[0]);
        res?.data?.forEach((item) => {
            if (item.isPrimary) {
                setPrimaryBank(item)
            }
        })
    })

    const [getPurposeCode, fetchingPurposeCode, purposeCodeData] = UseApiCall(
        apiGetPurposeCodeData,
        (res) => {
            const purposeCodeDefault = res?.data?.find(p => p?.default)?.purposeCode;
            if (purposeCodeDefault) {
                setPurposeCode(purposeCodeDefault)
                setIsPurposeCodeValid(true);
              };
            setPurposeCodeArray(res?.data)
            setKey(!key)
        }
    )

    const [getCustomerCurrencies] = UseApiCall(
        apiGetCustomerCurrencies,
        (res) => {
            setCustomerCurrencies(res?.data)
        }
    )



    useEffect(() => {
        // createTransaction({ customerId: contact?.id })
        getCustomerCurrencies(passOnContact?.id)
        getBankAccounts()
        getPurposeCode()
        setTaxName(null)
    }, [])

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${baseUrl.api}/virtual-accounts/details`,{
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    credentials: "include",
                });

                setAccDetails(null)

                let jsonData;
                if (doEncryption) {
                    jsonData = await response.text();
                  } else {
                    jsonData = await response.json();
                  }
                  let res = doEncryption ? handleDecrypt(jsonData) : jsonData;

                const vbanDetailsLN = res?.data?.virtualAccountsDetails?.paymentMethodsDetailsMap?.[curr]?.details;

                // setAccData(vbanDetailsLN);
                // setAccDetails(jsonData?.data?.virtualAccountsDetails?.paymentMethodsDetailsMap?.[curr]?.details?.LocalMethods);
                if(whichSelect === "local"){
                    if(vbanDetailsLN?.LocalMethods){
                        setAccDetails(vbanDetailsLN?.LocalMethods || [])
                    }else if(vbanDetailsLN?.InternationalMethods){
                        setWhichSelect("int")
                        setAccDetails(vbanDetailsLN?.InternationalMethods || [])
                    }else{
                        setWhichSelect('')
                    }
                }else if(whichSelect === "int"){
                    if(vbanDetailsLN?.InternationalMethods){
                        setAccDetails(vbanDetailsLN?.InternationalMethods || [])
                    }else if(vbanDetailsLN?.LocalMethods){
                        setWhichSelect("local")
                        setAccDetails(vbanDetailsLN?.LocalMethods || [])
                    }else{
                        setWhichSelect('')
                    }
                }else if(whichSelect === "both"){
                    if(vbanDetailsLN?.LocalMethods && vbanDetailsLN?.InternationalMethods){
                        setAccDetails([
                            ...vbanDetailsLN?.LocalMethods,
                            ...vbanDetailsLN?.InternationalMethods
                        ])
                    }else if(vbanDetailsLN?.LocalMethods){
                        setWhichSelect("local")
                        setAccDetails(vbanDetailsLN?.LocalMethods || [])
                    }else if(vbanDetailsLN?.InternationalMethods){
                        setWhichSelect("int")
                        setAccDetails(vbanDetailsLN?.InternationalMethods || [])
                    }else{
                        setWhichSelect('')
                    }
                }

                setLocalAcc(res?.data?.virtualAccountsDetails?.paymentMethodsDetailsMap?.[curr]?.details?.LocalMethods);
                setIntAcc(res?.data?.virtualAccountsDetails?.paymentMethodsDetailsMap?.[curr]?.details?.InternationalMethods);
                setLoading(false);

            }catch (err){
                // setError(err);
                enqueueSnackbar(err, { variant: 'error' })

                setLoading(false);
            }
        }

        fetchData();
    }, [curr]);
    const handlePageUpdate = () => {


        // dispatch(GlobalActions.setSubMenu("all"))
        navigate("/receivables");
        dispatch(GlobalActions.setSelectedOption(2));
        dispatch(GlobalActions.setSubMenu("all"));
        dispatch(ReceivablesActions.setUpdateReceivables());
        // window.history.push("/receivables")
    }
    const handlePageUpdateDraft = () => {
        // const url = window.location.pathname;
        // const parts = url.split('/');
        //
        // if (parts[parts?.length - 1] === "receivables") {
        //     dispatch(ReceivablesActions.setUpdateReceivables())
        // } else if (parts[parts?.length - 1] === "dashboard") {
        //     dispatch(ReceivablesActions.setUpdateReceivables())
        //     dispatch(DashboardSummaryActions.setUpdateDashboard())
        // } else if (parts[parts?.length - 1] === "customers") {
        //     dispatch(ReceivablesActions.setUpdateReceivables())
        //     dispatch(CustomersActions.setUpdateCustomers())
        // }

        navigate("/receivablesDraft")
        dispatch(GlobalActions.setSelectedOption(2));
        dispatch(GlobalActions.setSubMenu("draft"));
        dispatch(ReceivablesActions.setUpdateReceivables());
        // navigate("/receivablesDraft")
        // window.history.push("/receivablesDraft")
    }

    const [createInvoiceCall, creatingInvoice] = UseApiCall(
        apiCreateRealInvoice,
        (res) => {
            if(screenWidth>640){
                // if (props.nextPageFunctionality) props.nextPageFunctionality()
                showInvoiceVerificationPrompt(modalRef, {
                    invoiceId: invoiceId,
                    whereCalled: "createInvoice"
                })


                enqueueSnackbar(`Invoice created successfully.`, {
                    variant: 'success',
                    anchorOrigin: { horizontal: "center", vertical: "top" }
                });
                handlePageUpdate();
            }
            if(screenWidth<=640){
                dispatch(modalActions.mobileInvoiceSuccess(true))
            }
        }, (err) => {
            enqueueSnackbar(`${err?.data?.error_response?.message} ${err?.data?.error_response?.errors !== null ? JSON.stringify(err?.data?.error_response?.errors[0]) : ""}`, { variant: 'error' })
        }
    )


    const [createInvoiceCallDraft, creatingInvoiceDraft] = UseApiCall(
        apiCreateRealInvoice,
        (res) => {

            if(screenWidth>640){



                enqueueSnackbar(`Invoice saved as draft successfully.`, {
                    variant: 'success',
                    anchorOrigin: { horizontal: "center", vertical: "top" }
                });

                dispatch(modalActions.setCreateInvoiceModal(false))
                dispatch(modalActions.setCreateInvoiceReceivePayment(false));

                if(props.draftSubmitFunction){
                    props.draftSubmitFunction();
                }
                else {
                    handlePageUpdateDraft();
                }

            }
            if(screenWidth<=640){
                enqueueSnackbar(`Invoice saved as draft successfully.`, {
                    variant: 'success',
                    anchorOrigin: { horizontal: "center", vertical: "top" }
                });

                dispatch(modalActions.setCreateInvoiceModal(false))
                dispatch(modalActions.setCreateInvoiceReceivePayment(false));


                // dispatch(modalActions.mobileInvoiceSuccess(true))
            }
        }, (err) => {
            enqueueSnackbar(`${err?.data?.error_response?.message} ${err?.data?.error_response?.errors !== null ? JSON.stringify(err?.data?.error_response?.errors[0]) : ""}`, { variant: 'error' })
        }
    )




    const handlePreview = async (event) => {
        const queryParams = {
            subTotal: subTotal,
            itemsList: lineItems[0].itemName === null ? [] : lineItems,
            discount: discount,
            tax: taxValue,
            taxType: (taxName === null || tax !== "Other") ? tax : taxName,
            itemType : itemType,
            paymentTerm: paymentTerms,
            shipmentCharges: fobShipment,
            grandTotal: grandTotal,
            taxDescription: taxDescription,
            shipmentTerm: shipmentTerms,
            notes: addNotesTerms,
            generalTerm: generalTerms,
            consigneeBuyer:consigneeBuyer,
            poNumberDate:poNumberDate,
            countryFinalDestination:countryFinalDestination,
            countryOriginGoods:countryOriginGoods,
            invoiceId: invoiceId,
            purposeCode: purposeCode,
            issueDate: issueDate ? new Date(issueDate)?.getTime() : null,
            dueDate: dueDate ? new Date(dueDate)?.getTime() : null,
            blDate: blDate? new Date(blDate)?.getTime() : null,
            customerId: passOnContact?.id,
            currency:curr,
            receiveMoneyAccountId: primaryBank?.accountId,
            bankIdList: accDetails,
        };

        const response = await fetch(`${baseUrl.api}/invoices/invoice-preview`,{
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body:JSON.stringify(queryParams),
            credentials: "include",
        }).then(async function (t) {

            const b = await t.blob();
            const file = window.URL.createObjectURL(b)


            setPreviewValue(file)


        });

        event.stopPropagation()
        dispatch(modalActions.setShowPreviewDisplay(true))
    }

    const isValidDraftButton = () => {
        if (
            !isInvoiceIdValid ||
            !primaryBank?.accountId ||
            !passOnContact?.id
        )
            return false
        return true
    }

    const isValidDraft = () => {


        if (!primaryBank?.accountId) {
            enqueueSnackbar('Please select a bank.', {
                variant: 'error',
                anchorOrigin: { horizontal: "center", vertical: "top" }
            });
            return false;
        } else if (!passOnContact?.id) {
            enqueueSnackbar('Please select a customer.', {
                variant: 'error',
                anchorOrigin: { horizontal: "center", vertical: "top" }
            });
            return false;
        }

        return true;
    }

    const isValidInvoice = () => {
        if (
            !isInvoiceIdValid ||
            !ispurposeCodeValid ||
            !issueDate ||
            !primaryBank?.accountId ||
            !passOnContact?.id ||
            grandTotal < 1 ||
            subTotal < 1

        )
            return false
        return true
    }

    const handleSubmit = () => {
        setMode("CREATE")
        if (isValidInvoice())
            createInvoiceCall({
                subtotal: subTotal,
                itemsList: lineItems[0].itemName === null ? [] : lineItems,
                discount: discount,
                tax: taxValue,
                taxType: (taxName === null || tax !== "Other") ? tax : taxName,
                itemType : itemType,
                paymentTerm: paymentTerms,
                shipmentCharges: fobShipment,
                grandTotal: grandTotal,
                taxDescription: taxDescription,
                shipmentTerm: shipmentTerms,
                notes: addNotesTerms,
                generalTerm: generalTerms,
                consigneeBuyer:consigneeBuyer,
                poNumberDate:poNumberDate,
                countryFinalDestination:countryFinalDestination,
                countryOriginGoods:countryOriginGoods,
                transactionId,
                invoiceId: invoiceId,
                purposeCode: purposeCode,
                issueDate: issueDate ? new Date(issueDate)?.getTime() : null,
                dueDate: dueDate ? new Date(dueDate)?.getTime() : null,
                blDate: blDate? new Date(blDate)?.getTime() : null,
                customerId: passOnContact?.id,
                currency:curr,
                outputCurrency: "INR",
                receiveMoneyAccountId: primaryBank?.accountId,
                bankIdList: accDetails,
                mode: "CREATE",
            })

    }


    const handleDraft = () => {

        setMode("DRAFT")
        if (isValidDraft())
            createInvoiceCallDraft({
                subtotal: subTotal,
                itemsList: lineItems[0].itemName === null ? [] : lineItems,
                discount: discount,
                tax: taxValue,
                taxType: (taxName === null || tax !== "Other") ? tax : taxName,
                itemType : itemType,
                paymentTerm: paymentTerms,
                shipmentCharges: fobShipment,
                grandTotal: grandTotal,
                taxDescription: taxDescription,
                shipmentTerm: shipmentTerms,
                notes: addNotesTerms,
                generalTerm: generalTerms,
                consigneeBuyer:consigneeBuyer,
                poNumberDate:poNumberDate,
                countryFinalDestination:countryFinalDestination,
                countryOriginGoods:countryOriginGoods,
                transactionId,
                invoiceId: invoiceId,
                purposeCode: purposeCode,
                issueDate: issueDate ? new Date(issueDate)?.getTime() : null,
                dueDate: dueDate ? new Date(dueDate)?.getTime() : null,
                blDate: blDate? new Date(blDate)?.getTime() : null,
                customerId: passOnContact?.id,
                currency:curr,
                outputCurrency: "INR",
                receiveMoneyAccountId: primaryBank?.accountId,
                bankIdList: accDetails,
                mode: "DRAFT",
            })
        else {


            enqueueSnackbar('Please check mandatory fields before continuing.', {
              variant: 'error',
              anchorOrigin: { horizontal: "center", vertical: "top" }
            });
        }
    }

    useEffect(() => {
        setCountryFinalDestination(itemType === "HSN" ? "India" : null)
    }, [itemType])



    return (
        <>
            {showPreviewDisplay &&
                ReactDOM.createPortal(
                    <PreviewInvoice
                        previewValue={previewValue}
                        creatingInvoice={creatingInvoice}
                        isValidInvoice={isValidInvoice}
                        handleSubmit={handleSubmit}
                        handleDraft={handleDraft}
                    />,
                    document.getElementById('modal-root')
                )
            }

            {
                mobileInvoiceSuccess &&
                ReactDOM.createPortal(
                    <MobileInvoiceSuccessPrompt
                        invoiceId = {invoiceId}
                    />,
                    document.getElementById("modal-root")
                )
            }

            <div className={classes.topCon}>
                <div className={classes.left} onClick={() => dispatch(modalActions.setCreateInvoiceModal(false))}>
                    <div className={classes.arrowLeftImg}>
                        <img src={arrowLeft}/>
                    </div>
                    <p className={classes.text1}>Create Invoice</p>
                </div>
                <div className={classes.right}>
                    <div className={classes.completeCon}>
                        <div className={classes.completeImg}>
                            {/*<PercentageLoader percentage={20}/>*/}
                            <Progress
                                type="circle"
                                percent={percentValue}
                                // format={(percent) => `${percent} %`}
                                // width={40}
                                size={40}
                                strokeColor={"#1E333F"}
                                strokeWidth={8}
                            />
                        </div>
                        <p className={classes.text2}>Completed </p>
                    </div>



                    <div className={classes.previewCon} onClick={(event)=>handlePreview(event)}>
                        <div className={classes.previewImg}>
                            <img src={eyeOpen}/>
                        </div>
                        <p className={classes.text3}>Preview</p>
                    </div>

                    {/*save as draft*/}
                    <div className={classes.draftCon} onClick={handleDraft}>
                        <div className={classes.draft}>
                            <p className={classes.draftText}>Save as Draft</p>
                        </div>
                    </div>


                </div>
            </div>
            {/*bottom part*/}
            <div className={classes.bottomConWrap}>
                <ul className={classes.bottomCon}>
                    {/*billTo*/}
                    <li className={`${classes.billToCon} ${stepNo >= "2" ? classes.completedStep : classes.incompleteStep}`}
                        onClick={handleBillTo} style={{marginTop: "24px"}}
                    >
                        {/*billTo TOP */}
                        <div className={classes.billToConTop}>
                            <div className={classes.billToConTopLeft}>
                                <div className={classes.billToConImg}>
                                    {stepNo >= "2"
                                        ?
                                        <img src={greenStep}/>
                                        :
                                        <img src={stepNo === "1" ? billToDark : billToLight}/>
                                    }
                                </div>
                                <div className="flex flex-col">
                                    <p className={classes.billToConText}>Bill To</p>
                                    {stepNo !== "1" && passOnContact &&
                                        <div className="flex gap-[8px] items-center justify-center">
                                            <p className={classes.billToConText2}>{passOnContact?.customerName}</p>
                                            <img src={Ellipse}/>
                                            <p className={classes.billToConText2}>{passOnContact?.country}</p>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className={classes.billToConTopRight}>
                                <img src={stepNo === "1" ? chevronUp : chevronDown}/>
                            </div>
                        </div>

                        {/*billTo BOTTOM */}
                        <div className={classes.billToConBottom}>
                            <div className={classes.billToConBottomLeft}>
                                {/*<div className={classes.verticalLineGray}></div>*/}
                            </div>
                            <div className={classes.billToConBottomRight}>
                                {stepNo === "1" &&
                                    <>
                                        <ContactBillTo
                                            passOnContact={passOnContact}
                                            setSaveBillTo={setSaveBillTo}
                                            saveBillTo={saveBillTo}
                                        />
                                        <div className={classes.currencyType} style={{marginTop:"20px"}}>
                                            <div className={classes.save} onClick={(event) => handleSaveAndcontinue("2",event)}>
                                                <p className={classes.saveText}>Save & Continue</p>
                                            </div>
                                        </div>
                                    </>
                                }
                                <div className={classes.hrCon}>
                                    <div className={classes.hrRight}></div>
                                </div>
                            </div>
                        </div>
                    </li>

                    {/*items&price*/}
                    <li className={`${classes.billToCon} ${stepNo >= "3" ? classes.completedStep : classes.incompleteStep}`}
                        onClick={handleItemsPrice}>
                    {/*items&price TOP */}
                        <div className={classes.billToConTop} style={{position: "relative"}}>
                            <div className={classes.billToConTopLeft}>
                                <div className={classes.billToConImg}>
                                    {stepNo >= "3"
                                        ?
                                        <img src={greenStep}/>
                                        :
                                        <img src={stepNo === "2" ? itemsPriceDark : itemsPriceLight}/>
                                    }
                                </div>
                                <div className="flex flex-col">
                                    <p className={classes.billToConText}>Items & Price</p>
                                    {stepNo !== "2" && grandTotal >= 1 &&
                                        <div className="flex gap-[8px] items-center justify-center">
                                            <p className={classes.billToConText2}>{`${lineItems?.length} Items`}</p>
                                            <img src={Ellipse}/>
                                            <p className={classes.billToConText2}>{`${currencyCodeSign} ${grandTotal}`}</p>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className={classes.billToConTopRight}>
                                <img src={stepNo === "2" ? chevronUp : chevronDown}/>
                            </div>
                        </div>

                        {/*items&price BOTTOM */}
                        <div className={classes.billToConBottom}>
                            <div className={classes.billToConBottomLeft}>
                                {/*<div className={classes.verticalLineGray}></div>*/}
                            </div>
                            <div className={classes.billToConBottomRight}>
                                {stepNo === "2" &&
                                    <>
                                    <ItemsPrice
                                        itemType={itemType}
                                        setItemType={setItemType}
                                        curr={curr}
                                        setCurr={setCurr}
                                        tax={tax}
                                        setTax={setTax}
                                        subTotal={subTotal}
                                        setSubTotal={setSubTotal}
                                        discount={discount}
                                        setDiscount={setDiscount}
                                        taxValue={taxValue}
                                        setTaxValue={setTaxValue}
                                        fobShipment={fobShipment}
                                        setFobShipment={setFobShipment}
                                        grandTotal={grandTotal}
                                        setGrandTotal={setGrandTotal}
                                        taxName={taxName}
                                        setTaxName={setTaxName}
                                        taxDescription={taxDescription}
                                        setTaxDescription={setTaxDescription}
                                        lineItems={lineItems}
                                        setLineItems={setLineItems}
                                        handleCount={handleCount}
                                        currencyCodeSign={currencyCodeSign}
                                        setCurrencyCodeSign={setCurrencyCodeSign}
                                        customerCurrencies={customerCurrencies}
                                    />
                                    <div className={classes.currencyType} style={{marginTop:"20px"}}>
                                        <div className={classes.save} onClick={(event) => handleSaveAndcontinue("3",event)}>
                                            <p className={classes.saveText}>Save & Continue</p>
                                        </div>
                                    </div>
                                    </>
                                }
                                <div className={classes.hrCon}>
                                    <div className={classes.hrRight}></div>
                                </div>
                            </div>
                        </div>
                    </li>

                    {/*bank details*/}
                    <li className={`${classes.billToCon} ${stepNo >= "4" ? classes.completedStep : classes.incompleteStep}`}
                        onClick={handleBankDetails}>
                    {/*bank details TOP */}
                        <div className={classes.billToConTop}>
                            <div className={classes.billToConTopLeft}>
                                <div className={classes.billToConImg}>
                                    {stepNo >= "4"
                                        ?
                                        <img src={greenStep}/>
                                        :
                                        <img src={stepNo === "3" ? billToDark : billToLight}/>
                                    }
                                </div>
                                <div className="flex flex-col">
                                    <p className={classes.billToConText}>Bank Details</p>
                                    {stepNo !== "3" ?

                                        (
                                            // loading ? "Loading..." :
                                                <div className="flex gap-[8px] items-center justify-center">
                                                    {/*<p className={classes.billToConText2}>*/}
                                                    {/*    {whichSelect === "local" && localAcc && accDetails?.length ? `Local ${curr} Account` : (whichSelect === "int" && intAcc && accDetails?.length ? `International Swift Account` : (whichSelect === "both" && accDetails?.length >= 2 ? `Local ${curr} and International Swift Account` : null))}*/}
                                                    {/*</p>*/}
                                                    {whichSelect === "both" && accDetails?.length >= 2
                                                        ?
                                                        <>
                                                            <p className={classes.billToConText2}>
                                                                {`Local ${curr} Account`}
                                                            </p>
                                                            <img src={Ellipse}/>
                                                            <p className={classes.billToConText2}>
                                                                {accDetails[0]?.accountNumber ?
                                                                    "X".repeat(accDetails[0]?.accountNumber.length - 4) + accDetails[0]?.accountNumber.slice(-4)
                                                                    :
                                                                    `No Account for ${curr}`
                                                                }
                                                            </p>

                                                            {/*<img src={Ellipse}/>*/}

                                                            <p className={classes.billToConText2}>
                                                                {`and International Swift Account`}
                                                            </p>

                                                            <img src={Ellipse}/>
                                                            <p className={classes.billToConText2}>
                                                                {accDetails[1]?.accountNumber ?
                                                                    "X".repeat(accDetails[1]?.accountNumber.length - 4) + accDetails[1]?.accountNumber.slice(-4)
                                                                    :
                                                                    `No Account for ${curr}`
                                                                }
                                                            </p>
                                                        </>
                                                        :
                                                        whichSelect === "local" && accDetails?.length ?
                                                            <>
                                                                <p className={classes.billToConText2}>
                                                                    {`Local ${curr} Account`}
                                                                </p>
                                                                <img src={Ellipse}/>
                                                                <p className={classes.billToConText2}>
                                                                    {accDetails[0]?.accountNumber ?
                                                                        "X".repeat(accDetails[0]?.accountNumber.length - 4) + accDetails[0]?.accountNumber.slice(-4)
                                                                        :
                                                                        `No Account for ${curr}`
                                                                    }
                                                                </p>
                                                            </>

                                                            :
                                                            whichSelect === "int" && accDetails?.length ?
                                                            <>
                                                                <p className={classes.billToConText2}>
                                                                    {`International Swift Account`}
                                                                </p>

                                                                <img src={Ellipse}/>
                                                                <p className={classes.billToConText2}>
                                                                    {accDetails[0]?.accountNumber ?
                                                                        "X".repeat(accDetails[0]?.accountNumber.length - 4) + accDetails[0]?.accountNumber.slice(-4)
                                                                        :
                                                                        `No Account for ${curr}`
                                                                    }
                                                                </p>
                                                            </>

                                                                :

                                                                ""

                                                    }
                                                </div>
                                        )
                                        :
                                        <div className="flex gap-[8px] items-center justify-center">
                                            <p className={classes.billToConText2}>This will be printed on Invoice</p>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className={classes.billToConTopRight}>
                                <img src={stepNo === "3" ? chevronUp : chevronDown}/>
                            </div>
                        </div>

                        {/*bank details BOTTOM */}
                        <div className={classes.billToConBottom}>
                            <div className={classes.billToConBottomLeft}>
                                {/*<div className={classes.verticalLineGray}></div>*/}
                            </div>
                            <div className={classes.billToConBottomRight}>
                                {stepNo === "3" &&
                                    <>
                                        <BankDetails
                                            curr={curr}
                                            accDetails={accDetails}
                                            setAccDetails={setAccDetails}
                                            // virtualMap={virtualMap}
                                            localAcc={localAcc}
                                            setLocalAcc={setLocalAcc}
                                            intAcc={intAcc}
                                            setIntAcc={setIntAcc}
                                            // bothAcc={bothAcc}
                                            whichSelect={whichSelect}
                                            setWhichSelect={setWhichSelect}
                                            loading={loading}
                                            setLoading={setLoading}
                                        />
                                        <div className={classes.currencyType} style={{marginTop: "20px"}}>
                                            <div className={classes.save}
                                                 onClick={(event) => handleSaveAndcontinue("4", event)}>
                                                <p className={classes.saveText}>Save & Continue</p>
                                            </div>
                                        </div>
                                    </>
                                }
                                <div className={classes.hrCon}>
                                    <div className={classes.hrRight}></div>
                                </div>
                            </div>
                        </div>
                    </li>

                    {/*other details*/}
                    <li className={`${classes.billToCon} ${stepNo === "6" && classes.incompleteStep}`}
                        onClick={handleOtherDetails} style={{marginBottom: "24px"}}>
                        {/*other details TOP*/}
                        <div className={classes.billToConTop}>
                            <div className={classes.billToConTopLeft}>
                                <div className={classes.billToConImg}>
                                    {stepNo === "5"
                                        ?
                                        <img src={greenStep}/>
                                        :
                                        <img src={stepNo === "4" ? otherDetailsDark : otherDetailsLight}/>
                                    }
                                </div>
                                <div className="flex flex-col">
                                    <p className={classes.billToConText}>Other Details</p>
                                    {/*{stepNo !== "4" && <div className="flex gap-[8px] items-center justify-center">*/}
                                    {/*    <p className={classes.billToConText2}>{passOnContact?.customerName}</p>*/}
                                    {/*    <img src={Ellipse}/>*/}
                                    {/*    <p className={classes.billToConText2}>{passOnContact?.country}</p>*/}
                                    {/*</div>}*/}
                                </div>
                            </div>
                            <div className={classes.billToConTopRight}>
                                <img src={stepNo === "4" ? chevronUp : chevronDown}/>
                            </div>
                        </div>

                        {/*other details BOTTOM*/}
                        <div className={classes.billToConBottom}>
                            <div className={classes.billToConBottomLeft}>
                                {/*<div className={classes.verticalLineGray}></div>*/}
                            </div>
                            <div className={classes.billToConBottomRight}>
                                {stepNo === "4" &&
                                    <>
                                        <OtherDetails
                                            primaryBank={primaryBank}
                                            setPrimaryBank={setPrimaryBank}
                                            invoiceId={invoiceId}
                                            setInvoiceId={setInvoiceId}
                                            purposeCode={purposeCode}
                                            setPurposeCode={setPurposeCode}
                                            issueDate={issueDate}
                                            setIssueDate={setIssueDate}
                                            dueDate={dueDate}
                                            setDueDate={setDueDate}
                                            blDate={blDate}
                                            setBLDate={setBLDate}
                                            paymentTerms={paymentTerms}
                                            setPaymentTerms={setPaymentTerms}
                                            generalTerms={generalTerms}
                                            setGeneralTerms={setGeneralTerms}
                                            shipmentTerms={shipmentTerms}
                                            setShipmentTerms={setShipmentTerms}
                                            addNotesTerms={addNotesTerms}
                                            setAddNotesTerms={setAddNotesTerms}
                                            isInvoiceIdValid={isInvoiceIdValid}
                                            setIsInvoiceIdValid={setIsInvoiceIdValid}
                                            ispurposeCodeValid={ispurposeCodeValid}
                                            setIsPurposeCodeValid={setIsPurposeCodeValid}
                                            consigneeBuyer={consigneeBuyer}
                                            setConsigneeBuyer={setConsigneeBuyer}
                                            poNumberDate={poNumberDate}
                                            setPoNumberDate={setPoNumberDate}
                                            countryFinalDestination={countryFinalDestination}
                                            setCountryFinalDestination={setCountryFinalDestination}
                                            countryOriginGoods={countryOriginGoods}
                                            setCountryOriginGoods={setCountryOriginGoods}
                                            key={key}
                                            setKey={setKey}
                                            allBanks={allBanks}
                                            setAllBanks={setAllBanks}
                                            purposeCodeArray={purposeCodeArray}
                                            setPurposeCodeArray={setPurposeCodeArray}
                                            itemType={itemType}
                                        />
                                        <div className="flex flex-col justify-end" style={{marginTop: "20px",}}>
                                            <div className="flex gap-[16px]">
                                                {/*Preview btn*/}
                                                <div className={classes.previewCon}
                                                     onClick={(event) => handlePreview(event)}>
                                                    <div className={classes.previewImg}>
                                                        <img src={eyeOpen}/>
                                                    </div>
                                                    <p className={classes.text3}>Preview</p>
                                                </div>

                                                {/*save as draft btn*/}
                                                <div className={classes.draftCon} onClick={handleDraft}>
                                                    <div className={classes.draft}>
                                                        <p className={classes.draftText}>Save as Draft</p>
                                                    </div>
                                                </div>

                                                <div>
                                                    <Button
                                                        className={clsx(
                                                            'flex-1 outline-none h-[48px] ml-[6px]',
                                                            (creatingInvoice || !isValidInvoice()) && 'text-[#ffffff80]'
                                                        )}
                                                        disabled={creatingInvoice}
                                                        text="Create Invoice"
                                                        onClick={handleSubmit}
                                                    />
                                                </div>

                                            </div>

                                            <div className="flex justify-end gap-[8px] mt-[8px]">
                                                <p className={classes.defaultText}>Default (Template 1)</p>
                                                <img src={moreIcon} width={"16px"} height={"16px"}/>
                                            </div>

                                        </div>
                                    </>
                                }

                            </div>
                        </div>
                    </li>
                </ul>
            </div>

        </>
    )
}

export default CreateInvoice;