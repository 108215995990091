import classes from "./TourStartPrompt.module.scss"
import clsx from "clsx"
import Modal from "Layout/Modal"
import Backdrop from "Layout/Backdrop"
import { modalActions } from "Store/modal-redux"
import { GlobalActions } from "Store/global-redux";
import { useSelector, useDispatch } from "react-redux"
import chevronRight from "assets/Icons/chevron-rightNew.svg"
import ReactDOM from 'react-dom'
import { useNavigate } from "react-router-dom"
import CloseIcon from 'assets/Icons/close-white-icon.svg'
import CryptoJS from 'crypto-js';
import { secretKey } from "constants";

const TourStartPrompt = (props) =>{
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {firstName , lastName} = props

    const decryptValue = (encryptedValue) => {
        const bytes = CryptoJS.AES.decrypt(encryptedValue || '', secretKey);
        return bytes.toString(CryptoJS.enc.Utf8);
    };

    const storedUserRole = decryptValue(localStorage.getItem('nickName') || '');

    return(
        <Modal
        className={clsx(
            classes.modal,
            'w-[400px] p-0 max-w-[400px] !min-w-[400px] z-[100]  left-[38%] top-[30%] rounded-[16px]'
        )}
        >
        {  
            ReactDOM.createPortal(
            <Backdrop
                onClick={() => {
                
                }}
                className={classes.backdrop}
            />,
            document.getElementById('backdrop-root')
        )}
        <div className={classes.bigCont}>
            <div className={classes.upperSection}>
                <div className={classes.nameAcroBox}>
                        {firstName[0]}
                </div>
                <div className={classes.welcomeHead}>
                    <h1 className={classes.welcomeHeadFirst}>Welcome {firstName}</h1>
                    {/* <h1 className={classes.welcomeHeadSecond}>We’re glad to have you on Fideo</h1> */}
                </div>
                <div className={classes.welcomeText}>
                    Let us invite you to our guided tour, where we’ll get you set up in no time.
                </div>
            </div>
            <div className={classes.lowerSection}>
                <div className={classes.skipBtn} onClick={() => dispatch(modalActions.tourStart(false))}>
                    <img src={CloseIcon} alt="" className={classes.rightIcon} />
                    Skip
                </div>
                <div
                    className={classes.startBtn}
                    onClick={() => {
                        dispatch(GlobalActions.setShowTour(true))
                        dispatch(modalActions.tourStart(false))
                        if (storedUserRole === 'malik') navigate("/bank-accounts")
                        else navigate("/customers")
                    }}
                >
                    Start Tour
                    <img src={chevronRight} alt="" className={classes.rightIcon} />
                </div>
            </div>
        </div>
        </Modal>
    )
}

export default TourStartPrompt