import React from 'react';
import classes from './ReportPageFilter.module.scss';
import down from 'assets/Icons/chevron-down.svg';
import up from 'assets/Icons/chevron-up.svg';
import rotate from "assets/Icons/rotate-cw.svg";
import check from "assets/Icons/check.svg";
// import filter from "assets/Icons/filters.svg";
// import sort from "assets/Icons/sort.svg";
// import cross from "assets/Icons/crossImg.svg";
// import UseApiCall from 'hooks/useApiCall';
// import { apiGetReceivableCustomers, apiGetReceivableStatuses } from 'services';
import useClickOutside from 'hooks/useClickOutside';
import MobilePageFilter from './MobilePageFilter';
import downloadIcon from "assets/Icons/downloadCloud.svg"
import clsx from 'clsx';
import { all } from 'axios';

const apiStatusToDisplayStatus = {
    "IN_REVIEW": "Under Review",
    "CREATED": "Verified",
    "DRAFT": "Draft",
    "READY_TO_SEND": "Verified",
    "LINK_SENT": "Link Sent",
    "IN_PROGRESS": "In Progress",
    "SETTLED": "Settled",
    "SETTLED_OUTSIDE": "Settled Outside Fideo",
    "CANCELLED": "Cancelled",
    "FAILED": "Failed",
    "REJECTED": "Rejected",
    "REFUNDED": "Refunded",
    "PENDING": "Pending",
    "PARTIALLY_PAID": "Partially Paid"
};

const ReportPageFilter = (props) => {
    const {selectedCustomers,setSelectedCustomers,toggleCustomerSelection,selectedCustomerIds, selectedStatuses,setSelectedStatuses,toggleStatusSelection
        ,selectedDate,setSelectedDate,handleDateSelection, activeDate, setActiveDate,
        showCustomerDropdown,setShowCustomerDropdown,toggleCustomerDropdown,showStatusDropdown,setShowStatusDropdown,toggleStatusDropdown,showInvoiceDateDropdown,
        setShowInvoiceDateDropdown,toggleInvoiceDateDropdown,allSelected,setAllSelected,setAllStatusSelected,
        allStatusSelected,setShowDateCounter,showDateCounter,customerCounter,setCustomerCounter,statusCounter,setStatusCounter,toggleAllStatesSelection,
        mobileFilterOpen,setMobileFilterOpen,mobileSortOpen,setMobileSortOpen,
        customers,setCustomers,
        statuses,setStatuses,tableData,tableHeader
    } = props;

    // let searchText = props.searchText;
    // if(window.innerWidth <= 700 ) searchText = "Search";

    const customerDropdownRef = useClickOutside(() => setShowCustomerDropdown(false));
    const statusDropdownRef = useClickOutside(() => setShowStatusDropdown(false));
    const invoiceDateDropdownRef = useClickOutside(() => setShowInvoiceDateDropdown(false));

      const handleAllCustomersToggle = (x) => {
        if (!x) {
            const newSelectedCustomers = {};
            customers.forEach(customer => {
                newSelectedCustomers[customer.id] = true;
            });
            setSelectedCustomers(newSelectedCustomers);
            setCustomerCounter(Object.values(newSelectedCustomers).filter(value => value).length)
            setAllSelected(!allSelected);
        } else {
            setSelectedCustomers({})
            setAllSelected(false);
        }
      };

      const handleAllStatusToggle = (x) => {
        if (!x) {
            const newSelectedStatuses = {};
            statuses.forEach(status => {
              newSelectedStatuses[status] = true;
            });
            setSelectedStatuses(newSelectedStatuses)
            setStatusCounter(statuses?.length)
            setAllStatusSelected(!allStatusSelected);
        } else {
            setSelectedStatuses({})
            setAllStatusSelected(false);
            const newSelectedStatuses = {};
            statuses.forEach(status => {
              newSelectedStatuses[status] = false;
            });
            setSelectedStatuses(newSelectedStatuses)
            setStatusCounter(0)
            setAllStatusSelected(false);
        }
      };


  return (
    <>
        <div className={classes.filterPage}>
            <div className={classes.left} >
                <div className={classes.dropdownWrapper} ref={customerDropdownRef}>
                    <div className={classes.customer} onClick={toggleCustomerDropdown}>
                        <span className={classes.text}>Customers</span>
                        <div className={classes.counter}>
                            {customerCounter >= 1 && customerCounter !== customers?.length &&
                            (<div className={classes.count}>
                                <span className={classes.countText}>{customerCounter}</span>
                            </div>)}
                            <img src={showCustomerDropdown ? up : down} className={classes.imgIcon} alt="dropdown"/>
                        </div>
                    </div>
                    {showCustomerDropdown && (
                        <div className={`${classes.dropdownCustomer}`} >
                            <div className={`${classes.scrollableDropdown}`}>
                                <div className={classes.dropdownListCustomer} onClick={(e) => handleAllCustomersToggle(allSelected)}>
                                    <input type='checkbox' className={classes.dropdownCheckbox}
                                        checked={allSelected}
                                    />
                                    <span className={classes.dropdownText}>All Customers</span>
                                </div>
                                {customers && customers?.map(customer => (
                                    <div className={classes.dropdownListCustomerMap} key={customer?.id}
                                    onClick={() => toggleCustomerSelection(statuses, customer.id, customers?.length)}
                                    >
                                        <input type='checkbox' className={classes.dropdownCheckbox} 
                                                checked={selectedCustomers[customer.id] || allSelected}
                                                // onChange={() => toggleCustomerSelection(customer.id, customers?.length)}
                                        />
                                        <span className={classes.dropdownText}
                                            // onClick={() => toggleCustomerSelection(customer.id, customers?.length)}
                                        >
                                        {customer.customerName.length > 22
                                            ? `${customer.customerName.slice(0, 22)}...` 
                                            : customer.customerName
                                        }
                                        </span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </div>
                {props?.isDraft && (<div className={classes.dropdownWrapper} ref={statusDropdownRef}>
                    <div className={`${classes.status}`} onClick={toggleStatusDropdown}>
                        <span className={classes.text}>Status</span>
                        <div className={classes.counter}>
                            {statusCounter >= 1 && statusCounter !== statuses?.length && !allStatusSelected &&
                                (<div className={classes.count}>
                                    <span className={classes.countText}>{statusCounter}</span>
                                </div>)}
                            <img src={showStatusDropdown ? up : down} className={classes.imgIcon} alt="dropdown"/>
                        </div>
                    </div>
                    {showStatusDropdown && (
                        <div className={`${classes.dropdownStatus}`}>
                            <div className={`${classes.scrollableDropdown}`}>
                                <div className={`${classes.dropdownListStatus}`}
                                     onClick={(e) => handleAllStatusToggle(allStatusSelected)}>
                                    <input type='checkbox' className={classes.dropdownCheckbox}
                                           checked={allStatusSelected}
                                    />
                                    <span className={classes.dropdownText}>All Statuses</span>
                                </div>
                                <div>
                                    {statuses?.filter((item) => item?.toLowerCase() !== "draft")?.map(status => (
                                        <div className={`${classes.dropdownListStatusMap}`} key={status}
                                             onClick={() => toggleStatusSelection(status, statuses?.length)}>
                                            <input type='checkbox' className={classes.dropdownCheckbox}
                                                   checked={selectedStatuses[status] || allStatusSelected}
                                                // onChange={() => toggleStatusSelection(status, statuses?.length)}
                                            />
                                            <span className={classes.dropdownText}
                                                // onClick={() => toggleStatusSelection(status, statuses?.length)}
                                            >
                                            {/* {apiStatusToDisplayStatus[status]} */}
                                                {apiStatusToDisplayStatus[status]?.length > 20
                                                    ? `${apiStatusToDisplayStatus[status].slice(0, 20)}...`
                                                    : apiStatusToDisplayStatus[status]
                                                }
                                        </span>
                                        </div>
                                    ))}
                                </div>
                                {/* <div className={classes.dropdownListReset} onClick={() => toggleStatusSelection('reset')}>
                                    <img src={rotate} className={classes.dropdownListImg} />
                                    <span className={classes.dropdownText}
                                    >Reset</span>
                                </div> */}
                            </div>
                        </div>
                    )}
                </div>)}
                {props?.isDraft &&(<div className={classes.dropdownWrapper} ref={invoiceDateDropdownRef}>
                    <div className={classes.date} onClick={toggleInvoiceDateDropdown}>
                        <span className={classes.text}>Issue Date</span>
                        <div className={classes.counter}>
                            {showDateCounter &&
                                (<div className={classes.count}>
                                    <span className={classes.countText}>1</span>
                                </div>)}
                            <img src={showInvoiceDateDropdown ? up : down} className={classes.imgIcon} alt="dropdown"/>
                        </div>
                    </div>
                    {showInvoiceDateDropdown && (
                        <div className={classes.dropdownDate}>
                            <div className={classes.dropdownListDate} onClick={() => handleDateSelection('all')}>
                                <span className={classes.dropdownText}>All Issue Dates</span>
                                {activeDate === 'all' && <img src={check} className={classes.dropdownListImg}/>}
                            </div>
                            <div className={classes.dropdownListMap} onClick={() => handleDateSelection('one')}>
                                <span className={classes.dropdownText}>Last month</span>
                                {activeDate === 'one' && <img src={check} className={classes.dropdownListImg}/>}
                            </div>
                            <div className={classes.dropdownListMap} onClick={() => handleDateSelection('three')}>
                                <span className={classes.dropdownText}>Last 3 months</span>
                                {activeDate === 'three' && <img src={check} className={classes.dropdownListImg}/>}
                            </div>
                            <div className={classes.dropdownListMap} onClick={() => handleDateSelection('six')}>
                                <span className={classes.dropdownText}>Last 6 months</span>
                                {activeDate === 'six' && <img src={check} className={classes.dropdownListImg}/>}
                            </div>
                        </div>
                    )}
                </div>)}
                {(((customerCounter >= 1 && !allSelected) && (statusCounter >= 1 && !allStatusSelected)) ||
                ((customerCounter >= 1 && !allSelected) && (activeDate === 'one' || activeDate === 'three' || activeDate === 'six')) ||
                ((activeDate === 'one' || activeDate === 'three' || activeDate === 'six') && (statusCounter >= 1 && !allStatusSelected)))
                &&
                    (
                        <div className={classes.resetAll} onClick={() => toggleAllStatesSelection('resetAll')}>
                            <img src={rotate} className={classes.dropdownListImg} />
                            <span className={classes.dropdownText}>Clear All</span>
                        </div>
                    )
                }
            </div>
            <div className={classes.right}>
                {props?.isloadingDownloadBtn &&
                    <div
                    className={clsx(classes.downloadBtn, props.loadingDownloadBtn ? 'cursor-default opacity-50' : 'cursor-pointer opacity-100')}
                    onClick={() => {
                        if (!props.loadingDownloadBtn) props.exportData()
                    }}>
                    <img src={downloadIcon} alt='download' className={clsx(classes.downloadBtnImg)}/>
                </div>}
            </div>
            {/* <div className={classes.right} ></div> */}
        </div>
        <MobilePageFilter  
            mobileFilterOpen={ mobileFilterOpen} 
            setMobileFilterOpen={setMobileFilterOpen}
            mobileSortOpen={mobileSortOpen}
            setMobileSortOpen={setMobileSortOpen}
            toggleCustomerDropdown={toggleCustomerDropdown}
            showCustomerDropdown={showCustomerDropdown}
            setShowCustomerDropdown={setShowCustomerDropdown}
            handleAllCustomersToggle={handleAllCustomersToggle}
            allSelected={allSelected}
            customers={customers}
            toggleCustomerSelection={toggleCustomerSelection}
            statuses={statuses}
            selectedCustomers={selectedCustomers}
            handleAllStatusToggle={handleAllStatusToggle}
            allStatusSelected={allStatusSelected}
            toggleStatusSelection={toggleStatusSelection}
            selectedStatuses={selectedStatuses}
            apiStatusToDisplayStatus={apiStatusToDisplayStatus}
            handleDateSelection={handleDateSelection}
            activeDate={activeDate}
            tableData={tableData}
            customerCounter={customerCounter}
            statusCounter={statusCounter}
            toggleAllStatesSelection={toggleAllStatesSelection}
            showDateCounter={showDateCounter}
            tableHeader={tableHeader}
            setSortState={props.setSortState}
            sortState={props.sortState}
        />
    </>
  )
}

export default ReportPageFilter;