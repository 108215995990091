import ReactDOM from 'react-dom'
import classes from './InvoicesTable.module.scss'
import sorting from 'assets/Images/sorting-icon.svg'
import ReportTableLayout from 'Components/ReportTable/ReportTableLayout'
import Tooltip from 'Layout/Tooltip'
import eye from 'assets/Images/eye.svg'
import edit from 'assets/Images/edit.svg'
import moreInfo from 'assets/Images/moreInfo.svg'
import dayjs from 'dayjs'
import { useCallback, useEffect, useRef, useState } from 'react'
import MobileInvoiceTable from './MobileInvoiceTable'
import clsx from 'clsx'
import PaymentStatusTag from 'Components/ReportTable/PaymentStatusTag'
import Action from 'Components/ReportTable/Action'
import { useDispatch, useSelector } from 'react-redux'
import { modalActions } from 'Store/modal-redux'
import UseApiCall from 'hooks/useApiCall'
import { apiGetInvoiceDetails } from 'services'
import { CustomersActions, ReceivablesActions } from 'Store/customer-redux'
import { Pagination } from 'antd'
import { enqueueSnackbar } from 'notistack'
import EditInvoice from "Components/Invoice/EditInvoice";
import { formatNumberToLocaleString } from 'hooks/formatNumberToLocaleString';
import InvoiceDetailsModal from "../../InvoiceDetailsModal/InvoiceDetailsModal";

const InvoicesTable = (props) => {


  const dispatch = useDispatch()
  const [selectedInvoiceId, setSelectedInvoiceId] = useState(null);
  const [selectedInvoice, setSelectedInvoice] = useState(null);
  const editInvoiceDisplay = useSelector((state) => state.modal.editInvoiceDisplay);
  const [openedInvoice, setOpenedInvoice] = useState(null);
  const showInvoiceDisplay = useSelector(
      (state) => state.modal.showInvoiceDisplay
  )
  const theadRef = useRef(null);
  const tbodyRef = useRef(null);

  const getTime = useCallback(
    (date) => {
      const invoiceDate = dayjs(date)
      const now = dayjs()
      return now.diff(invoiceDate, 'days')
    },
    [props]
  )
  const optionArr = [
    {
      text: 'View',
      icon: eye,
      active: true,
      onClick: (cellData) => {
        if (cellData?.status?.toLowerCase() !== "in draft") handleInvoiceSelection(cellData?.id);
      },
    },
    {
      text: 'Edit',
      icon: edit,
      // disabled: cellData?.status?.toLowerCase() === 'in draft' ? false : true,
      onClick: (cellData) => {
        // if (cellData?.status?.toLowerCase() === 'in draft') 
        {
          dispatch(modalActions.editInvoice())
          setSelectedInvoiceId(cellData?.id)
        }
      },
    },
  ]
  const [getSelectedInvoice] = UseApiCall(apiGetInvoiceDetails, (res) => {
    dispatch(ReceivablesActions.getSelectedInvoice(res?.data))
    dispatch(CustomersActions.setSelectedInvoice(res?.data))
    dispatch(CustomersActions.setActiveInvoiceTab('details'))
    setSelectedInvoice(res?.data)
    dispatch(modalActions.showInvoiceDisplay())
  }, (err) => {
    enqueueSnackbar(`${err?.data?.error_response?.message} ${err?.data?.error_response?.errors !== null ? JSON.stringify(err?.data?.error_response?.errors[0]) : ""}`, { variant: 'error' })
  })

  const handleInvoiceSelection = async (invoiceId,data) => {
    setOpenedInvoice(data)
    await getSelectedInvoice({ invoiceId })
  }

  const itemRender = (_, type, originalElement) => {
    if (type === 'prev') {
      return <a>Previous</a>
    }
    if (type === 'next') {
      return <a>Next</a>
    }
    return originalElement
  }

  const [current, setCurrent] = useState(1);
  const handlePaginate = (page) => {
    setCurrent(page);
    props.handlePaginate(page - 1)
  };

  useEffect(() => {
    const handleScroll = (event) => {
      if (event.target.id === 'thead') {
        tbodyRef.current.scrollLeft = theadRef.current.scrollLeft;
      }
      else if (event.target.id === 'tbody') {
        theadRef.current.scrollLeft = tbodyRef.current.scrollLeft;
      }
    };

    const thead = theadRef.current;
    const tbody = tbodyRef.current;

    thead.addEventListener('scroll', handleScroll);
    tbody.addEventListener('scroll', handleScroll);

    return () => {
      thead.removeEventListener('scroll', handleScroll);
      tbody.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      {showInvoiceDisplay &&
          selectedInvoice &&
          ReactDOM.createPortal(
              <InvoiceDetailsModal
                  // activeTab={activeTab}
                  invoice={selectedInvoice}
                  openedInvoice={openedInvoice}
                  mobileTableData
              />,
              document.getElementById('modal-root')
          )}

      {editInvoiceDisplay &&
        ReactDOM.createPortal(
          <EditInvoice
            selectedInvoiceId={selectedInvoiceId}
            nextPageFunctionality={() => dispatch(modalActions.removeAll())}
          />,
          document.getElementById("modal-root")
      )}
      <div className={classes.allWrap} >
            <div className={clsx(`${classes.table}  ${classes.reportTableWrapTop}`)} id="thead" ref={theadRef}>
              <thead >
                {props.tableHeader.map((header, index) => (
                  <th key={`${header}-${index}`} className={classes.cell_header1}
                      style={{
                        width: `${header.width}%`,
                        minWidth: `${header.minWidth}px`,
                        maxWidth: `${header.minWidth}px`
                      }}  valign="top"
                  >
                    <div className={classes.cell_header2} >
                      <div className={classes.head}>{header.title}</div>
                      {/* <img
                        src={sorting}
                        alt="sorting-icon"
                        className={classes.sorting}
                      /> */}
                    </div>
                  </th>
                ))}
                {/*<th className={classes.cell_header1}*/}
                {/*    style={{*/}
                {/*      width: `${props.tableHeader[0].width}%`,*/}
                {/*      minWidth: `${props.tableHeader[0].minWidth}px`,*/}
                {/*      maxWidth: `${props.tableHeader[0].minWidth}px`*/}
                {/*    }}  valign="top"*/}
                {/*>*/}
                {/*  <div className={`${classes.cell_header2}`}  >*/}
                {/*    <div className={classes.head}>{'ESTD. Savings'}</div>*/}
                {/*    /!* <img*/}
                {/*      src={sorting}*/}
                {/*      alt="sorting-icon"*/}
                {/*      className={classes.sorting}*/}
                {/*    /> *!/*/}
                {/*  </div>*/}
                {/*</th>*/}
                {/*<th className={classes.cell_header1}*/}
                {/*    style={{*/}
                {/*      width: `${props.tableHeader[0].width}%`,*/}
                {/*      minWidth: `${props.tableHeader[0].minWidth}px`,*/}
                {/*      maxWidth: `${props.tableHeader[0].minWidth}px`*/}
                {/*    }}  valign="top"*/}
                {/*>*/}
                {/*  <div*/}
                {/*    className={`${classes.cell_header2} ${classes.actionHeader}`}*/}
                {/*  >*/}
                {/*    <div className={`${classes.head}`}>Action</div>*/}
                {/*  </div>*/}
                {/*</th>*/}
              </thead>
            </div>
          <div className={`${classes.reportTableWrap}`} id="tbody"  ref={tbodyRef}>
            <ReportTableLayout className={clsx(classes.table)}>
              <tbody>
                {props.tableData && props.tableData?.length > 0 && props.tableData.map((data) => (
                  <tr key={data.id} className={classes.row}>
                    <td
                      className={`${classes.cell}`}
                      style={{
                        userSelect: 'none',
                        cursor: 'pointer',
                        width: `${props.tableHeader[0].width}%`,
                        minWidth: `${props.tableHeader[0].minWidth}px`,
                        maxWidth: `${props.tableHeader[0].minWidth}px`
                      }}

                       valign="top"
                      onClick={() => {
                        if (data?.status !== "In Draft") handleInvoiceSelection(data.id,data)
                        else {
                          dispatch(modalActions.editInvoice())
                          setSelectedInvoiceId(data?.id)
                        }
                      }}
                    >
                      {dayjs(data?.createdTime).local().format('D MMM YYYY')}
                    </td>
                    <td
                      className={`${classes.cell} ${classes.invoice}`}
                      style={{
                        userSelect: 'none',
                        cursor: 'pointer',
                        width: `${props.tableHeader[1].width}%`,
                        minWidth: `${props.tableHeader[1].minWidth}px`,
                        maxWidth: `${props.tableHeader[1].minWidth}px`
                      }}
                      valign="top"
                      onClick={() => {
                        if (data?.status !== "In Draft") handleInvoiceSelection(data.id,data)
                        else {
                          dispatch(modalActions.editInvoice())
                          setSelectedInvoiceId(data?.id)
                        }
                      }}
                    >
                      {data.invoiceId}
                    </td>
                    <td className={classes.cell}
                        style={{
                          userSelect: 'none',
                          cursor: 'pointer',
                          width: `${props.tableHeader[2].width}%`,
                          minWidth: `${props.tableHeader[2].minWidth}px`,
                          maxWidth: `${props.tableHeader[2].minWidth}px`
                        }} valign="top"
                        onClick={() => {
                          if (data?.status !== "In Draft") handleInvoiceSelection(data.id,data)
                          else {
                            dispatch(modalActions.editInvoice())
                            setSelectedInvoiceId(data?.id)
                          }
                        }}
                    >
                      <div className={classes.amount}>
                        {data?.invoiceCurrency?.sign +
                          formatNumberToLocaleString((data.invoiceAmount), 2, 2)}
                      </div>

                      <div className={classes.amount}>
                        { data?.conversionInvoiceCurrency ? (
                          <span>
                            {`${data?.conversionInvoiceCurrency.sign}${data?.convertedInvoiceAmount?.toLocaleString(
                              'en-IN'
                            )}`}
                          </span>
                        ) : ('')}
                      </div>
                      {/* <div className={classes.amount}>{`${
                        data.conversionInvoiceCurrency?.sign
                      }${data.convertedInvoiceAmount?.toLocaleString(
                        'en-IN'
                      )}`}</div> */}
                    </td>
                    <td className={classes.cell}
                        style={{
                          userSelect: 'none',
                          cursor: 'pointer',
                          width: `${props.tableHeader[3].width}%`,
                          minWidth: `${props.tableHeader[3].minWidth}px`,
                          maxWidth: `${props.tableHeader[3].minWidth}px`
                        }} valign="top"
                        onClick={() => {
                          if (data?.status !== "In Draft") handleInvoiceSelection(data.id,data)
                          else {
                            dispatch(modalActions.editInvoice())
                            setSelectedInvoiceId(data?.id)
                          }
                        }}
                    >
                      <PaymentStatusTag status={data.status} type="light" />
                      <div className={classes.text}>
                        {getTime(data.lastUpdatedTime)} day
                        {getTime(data.lastUpdatedTime) > 1 ? 's' : ''} ago
                      </div>
                    </td>
                    <td className={classes.cell}
                        style={{
                          userSelect: 'none',
                          cursor: 'pointer',
                          width: `${props.tableHeader[4].width}%`,
                          minWidth: `${props.tableHeader[4].minWidth}px`,
                          maxWidth: `${props.tableHeader[4].minWidth}px`
                        }} valign="top"
                        onClick={() => {
                          if (data?.status !== "In Draft") handleInvoiceSelection(data.id,data)
                          else {
                            dispatch(modalActions.editInvoice())
                            setSelectedInvoiceId(data?.id)
                          }
                        }}
                    >
                    {
                        data?.savingAmount
                        ?
                          <Tooltip
                            // content={data.savingCurrency.sign + data.savingAmount}
                            content={(data?.savingCurrency?.sign === "₹" ? (data?.savingCurrency?.sign + data?.savingAmount?.toLocaleString('en-IN', { minimumFractionDigits: 2, maximumFractionDigits: 2 })) : (data?.savingCurrency?.sign + data?.savingAmount?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })))}
                            text={'Estimated Savings'}
                          />
                        :
                        '--'
                      }
                    </td>
                    <td className={classes.cell}
                        style={{
                          userSelect: 'none',
                          cursor: 'pointer',
                          width: `${props.tableHeader[5].width}%`,
                          minWidth: `${props.tableHeader[5].minWidth}px`,
                          maxWidth: `${props.tableHeader[5].minWidth}px`
                        }} valign="top"
                    >
                      <div className={classes.cell_action}>
                        <Action option={optionArr} cellData={data} />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </ReportTableLayout>
          </div>
          {props.tableData && props.tableData?.length > 0 && <div className="-md:hidden w-full flex flex-row justify-end mt-[16px] p-[16px]">
            <Pagination showSizeChanger={false} current={current} total={props?.pages} itemRender={itemRender} onChange={handlePaginate} />
          </div>}
          </div>
          <MobileInvoiceTable
            tableData={props.mobileTableData}
            option={optionArr}
          />
          {props.tableData && props.tableData?.length > 0 && <div className="md:hidden w-full flex flex-row justify-end mt-[16px]">
            <Pagination showSizeChanger={false} current={current} total={props?.pages} itemRender={itemRender} onChange={handlePaginate} />
          </div>}
          
    </>
  )
}

export default InvoicesTable
