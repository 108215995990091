// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Vendors_vendors__Bc00R {
  font-size: 2.5rem;
  line-height: 3rem;
  border: 2px soild red;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  /*.button{

      display: none;
      padding: 1.2rem 1.6rem;
      background-color: white;
      width: 100%;
      position: fixed;
      top: 100%;
      left: 0;
      transform: translateY(-100%);

      .btn:not(:last-child){
          margin-right: 1.2rem;
      }

      .btn{
          flex: 1;
      }

      @media (max-width: 600px) {
          display: flex;
      }
  }*/
}
.Vendors_vendors__Bc00R .Vendors_text__t3huT {
  background: -webkit-linear-gradient(right, #c4c4c4, #333);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Admin/Vendors.module.scss"],"names":[],"mappings":"AAAA;EACI,iBAAA;EACA,iBAAA;EACA,qBAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EAUA;;;;;;;;;;;;;;;;;;;;;;IAAA;AAcJ;AAvBI;EACA,yDAAA;EACA,6BAAA;EACA,oCAAA;AAyBJ","sourcesContent":[".vendors{\n    font-size: 2.5rem;\n    line-height: 3rem;\n    border: 2px soild red;\n    margin: auto;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    .text {\n    background: -webkit-linear-gradient(right,#c4c4c4, #333);\n    -webkit-background-clip: text;\n    -webkit-text-fill-color: transparent;\n    }\n\n    .btn{\n\n    }\n    /*.button{\n        \n        display: none;\n        padding: 1.2rem 1.6rem;\n        background-color: white;\n        width: 100%;\n        position: fixed;\n        top: 100%;\n        left: 0;\n        transform: translateY(-100%);\n        \n        .btn:not(:last-child){\n            margin-right: 1.2rem;\n        }\n\n        .btn{\n            flex: 1;\n        }\n\n        @media (max-width: 600px) {\n            display: flex;\n        }\n    }*/\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"vendors": `Vendors_vendors__Bc00R`,
	"text": `Vendors_text__t3huT`
};
export default ___CSS_LOADER_EXPORT___;
