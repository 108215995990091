// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AddPaymentDetail_modal__8L3wn {
  z-index: var(--level_8);
}

.AddPaymentDetail_backdrop__MODYf {
  z-index: var(--level_7);
}

.AddPaymentDetail_add_payment_detail__9WUJw {
  height: 90vh;
}`, "",{"version":3,"sources":["webpack://./src/Components/Payment/AddPaymentDetail.module.scss"],"names":[],"mappings":"AAAA;EACI,uBAAA;AACJ;;AAEA;EACI,uBAAA;AACJ;;AAEA;EACI,YAAA;AACJ","sourcesContent":[".modal{\n    z-index: var(--level_8);\n}\n\n.backdrop{\n    z-index: var(--level_7);\n}\n\n.add_payment_detail{\n    height: 90vh;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modal": `AddPaymentDetail_modal__8L3wn`,
	"backdrop": `AddPaymentDetail_backdrop__MODYf`,
	"add_payment_detail": `AddPaymentDetail_add_payment_detail__9WUJw`
};
export default ___CSS_LOADER_EXPORT___;
