import PageIntro from "Components/Global/PageIntro";
import classes from "Pages/Admin/Customers.module.scss";
import { modalActions } from "Store/modal-redux";
import { useDispatch, useSelector } from "react-redux";
import ReactDOM from "react-dom";
// import TransactionDetails from "Components/Global/TransactionDetails";
// import Status from "Components/ReportTable/Status";
// import invoiceCreated from "assets/Images/invoice_created.svg";
// import invoiceSettled from "assets/Images/invoice_settled.svg";
// import invoiceInprogress from "assets/Images/invoice_inprogress.svg";
// import invoicePending from "assets/Images/invoice_pending.svg";
import arrowDown from "assets/Images/arrow_down.svg";
import userAdd from "assets/Images/user-add-white.svg";
import ReceivePayment from "Components/Payment/ReceivePayment";
import AddContact from "Components/General/AddContact";
import CustomerReportTable from "Components/ReportTable/CustomerReportTable";
import Button from "Layout/Button";
// import ReportPageFilter from "Components/Global/ReportPageFilter";
import { useEffect, useState } from "react";
import { CustomersActions } from "Store/customer-redux";
import UseApiCall from "hooks/useApiCall";
import { apiGetCustomerReport, apiGetReceivables } from "services";
import ComingSoonPrompt from "Components/atom/ComingSoonPrompt/ComingSoonPrompt";
import CustomModal from "Components/atom/CustomModal/CustomModal";
import clsx from "clsx";
import UnderVericationPrompt from "Components/atom/UnderVericationPrompt/UnderVericationPrompt";
import CreateInvoice from "../../Components/CreateInvoice/CreateInvoice";
import ReceiveBulkUploadPayment from "../../Components/Payment/ReceiveBulkUploadPayment";

const tableHeader = [
  {
    id:1,
    title:"Cust. ID",
    width: 20,
    minWidth:162.26,
  },
  {
    id:2,
    title:"customer details",
    width: 20,
    minWidth:290.26,
  },
  {
    id:3,
    title:"Total receivables",
    width: 16.5,
    minWidth:143.26,
  },
  {
    id:4,
    title:"Settled",
    width: 14,
    minWidth:100.26,
  },
  {
    id:5,
    title:"settled outside fideo",
    width: 15,
    minWidth:117.26,
  },
  {
    id:6,
    title:"Pending",
    width: 14,
    minWidth:117.26,
  },
  // "Cust. ID",
  // "customer details",
  // "total outstanding",
  // "over due",
  // "not due",
  // "Total receivables",
  // `Settled`,
  // "settled outside fideo",
  // "Pending",
];

const Customers = () => {
  const dispatch = useDispatch();
  const receivePaymentDisplay = useSelector(
    (state) => state.modal.receivePaymentDisplay
  );
  const addContactDisplay = useSelector(
    (state) => state.modal.addContactDisplay
  );
  const customerList = useSelector((state) => state.customer.customers);
  const updateCustomersValue = useSelector((state) => state.customer.updateCustomers);
  const createInvoiceModal = useSelector((state) => state.modal.createInvoiceModal);
  const createInvoiceReceivePayment = useSelector((state) => state.modal.createInvoiceReceivePayment);
  const bulkUploaderDisplay = useSelector((state) => state.modal.bulkUploaderDisplay)

  const [modalRef, setModalRef] = useState(null);
  const [otherLoader, setOtherLoader] = useState(true)
  const [getCustomerReport, fetching] = UseApiCall(
    apiGetCustomerReport,
    (res) => {
      setOtherLoader(false);
      dispatch(CustomersActions.setcustomers(res.data.customer_details));
    }, (err) => {
      setOtherLoader(false);
    }
  );

  const showComingSoon = () => {
    modalRef.show({
      title: "",
      child: <ComingSoonPrompt />,
      onClose: () => {},
      compactSize: true,
      closeOnClickOutside: true,
    });
  };
  const showUnderVerification = (name = '') => {
    modalRef.show({
      title: "What’s next?",
      child: <UnderVericationPrompt name={name} closeModal={() => {modalRef?.close()}} />,
      onClose: () => {
        getCustomerReport()
      },
      compactSize: true,
      closeOnClickOutside: true,
    });
  };

  const arrBtn = [
    {
      text: "Add Customer",
      icon: userAdd,
      active: true,
      tourId: "step2",
      onClick: function () {
        dispatch(modalActions.addContact())
      },
    },
  ];
  useEffect(() => {
    getCustomerReport();
    // loader().then(customerResponse =>
    //     dispatch(CustomersActions.setcustomers(customerResponse.data.customer_details)))
  }, [updateCustomersValue]);

  useEffect(() => {
    dispatch(() => {
      dispatch(modalActions.setCreateInvoiceModal(false))
      // dispatch(modalActions.setShowDraftEditInvoice(false))
      dispatch(modalActions.setCreateInvoiceReceivePayment(false))
    })
  }, []);

  if(createInvoiceModal){
    return (
        <>
          <CreateInvoice/>
        </>
    )
  }



  return (
    <div className={classes.customer}>
      <CustomModal ref={setModalRef} />
      {(createInvoiceReceivePayment) &&
          ReactDOM.createPortal(
              <ReceivePayment modal={"createInvoice"}/>,
              document.getElementById("modal-root")
          )}

      {receivePaymentDisplay &&
        ReactDOM.createPortal(
          <ReceivePayment />,
          document.getElementById('modal-root')
        )}

      {bulkUploaderDisplay &&
          ReactDOM.createPortal(
              <ReceiveBulkUploadPayment/>,
              document.getElementById('modal-root')
          )}

      {addContactDisplay &&
        ReactDOM.createPortal(
          <AddContact
            contactType={'Customer'}
            nextPageFunctionality={() => {
              dispatch(modalActions.addContact())
            }}
            onSubmit={(name) => {
              showUnderVerification(name)
            }}
            addContactURL={''}
          />,
          document.getElementById('modal-root')
        )}
      <PageIntro
        pageName={'Customers'}
        message={'View all your customers at one place'}
        arrBtn={arrBtn}
        downloadBtn={true}
        exportDataList={customerList}
        loadingDownloadBtn={fetching || customerList?.length === 0}
        dontShowBtn={true}
      />
      {/*<TransactionDetails transactionData={transactionData}/>*/}
      {/*<ReportPageFilter searchText="Search by customer name/Invoice id" download={true} sendReminder={true}
                              filter={true}/>*/}
      <CustomerReportTable
        tableData={customerList}
        tableHeader={tableHeader}
        mobileTableData={customerList}
        loading={fetching || otherLoader}
      />
      <div className={classes.button}>
        {arrBtn.map((item) => (
          <Button
            key={item.text}
            text={item.text}
            icon={item.icon}
            onClick={item.onClick}
            className={classes.btn}
            disabled={!item.active}
            btnClassIcon={clsx(!item.active && 'opacity-50')}
            btnClassText={clsx(!item.active && 'opacity-50')}
          />
        ))}
      </div>
    </div>
  )
};
export default Customers;
