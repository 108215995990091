import AccountIcon from "assets/Icons/account-neon.svg";
import { useDispatch } from "react-redux";
import Button from "Layout/Button";
import MailIcon from "assets/Icons/mail-white.svg";
import FileIcon from "assets/Icons/file-icon-black.svg";
import CopyIcon from 'assets/Icons/copy-black.svg';
import TickCircleIcon from 'assets/Icons/tick-circle-white.svg'
import { apiMarkSettledOutside, apiGetReceivables, apiGetInvoiceDetails } from 'services'
import { CustomersActions, ReceivablesActions } from 'Store/customer-redux'
import UseApiCall from 'hooks/useApiCall'
import { useSnackbar } from 'notistack'
import dayjs from 'dayjs'
import clsx from 'clsx'
import ContactInfo from 'Components/Global/ContactInfo';
import classes from './CancelledOrPaidOutsidePaymentPrompt.module.scss';
import InfoIcon from 'assets/Icons/info_black_circle.svg';
import WalletWhite from 'assets/Icons/wallet-white.svg';
import PaymentStatusTag from '../../ReportTable/PaymentStatusTag';
import { modalActions } from 'Store/modal-redux'
import { formatNumberToLocaleString } from 'hooks/formatNumberToLocaleString';

const CancelledOrPaidOutsidePaymentPrompt = (props) => {
  const { customerName, email, country, currentStatus, invoiceId, id, purposeCode, issueDate, dueDate, invoiceAmount, invoiceCurrencyCode, invoiceCurrencySign, savingAmount, savingCurrencySign, invoiceTab } = props;
  const { enqueueSnackbar } = useSnackbar()
  const dispatch = useDispatch()

  const [getReceivables, fetching] = UseApiCall(apiGetReceivables, (res) => {
    dispatch(ReceivablesActions.getReceivabelReports(res?.data.invoice_list))
  })

  const [getSelectedInvoice] = UseApiCall(apiGetInvoiceDetails, (res) => {
    dispatch(ReceivablesActions.getSelectedInvoice(res?.data))
    dispatch(CustomersActions.setSelectedInvoice(res?.data))
    // dispatch(CustomersActions.setActiveInvoiceTab("details"))
    // dispatch(modalActions.showInvoiceDisplay())
  })

  const [settledOutsideFideo, settlingOutsideFideo] = UseApiCall(apiMarkSettledOutside, () => {
    enqueueSnackbar('Payment marked Paid Outside Successfully', { variant: 'success' })
    if (props.closeModal) {
      props.closeModal()
      const url = window.location.pathname;
      const parts = url.split('/');
      if (parts[parts?.length - 1] === "receivables") {
        getReceivables({
          page: 0,
          status: 'DEFAULT',
          fromDate: '2023-01-01',
          toDate: dayjs().add(1, 'day').format('YYYY-MM-DD'),
        })
      }
      if (invoiceTab) {
        getSelectedInvoice({ invoiceId: id })
      }
    }
  }, (err) => {
    enqueueSnackbar(`${err?.data?.error_response?.message} ${err?.data?.error_response?.errors !== null ? JSON.stringify(err?.data?.error_response?.errors[0]) : ""}`, { variant: 'error' })
  })

  const handleClick = () => {
    settledOutsideFideo({ 
      invoiceId: id,
      invoiceStatus: "SETTLED_OUTSIDE",
      comments: "SETTLED_OUTSIDE"
    })
  }
  
  return (
    <div className={clsx(
      classes.main,
      `max-w-[496px] sm:px-[40px] sm:pt-[32px] sm:pb-[20px] h-auto flex flex-col items-center justify-center`
    )}>
      <div
        className="rounded-full w-[88px] h-[88px] flex items-center justify-center mb-[20px]"
        style={{
          background: '#DBFD00',
        }}
      >
        <img src={FileIcon} alt="file" className="w-[48px] h-[48px]" />
      </div>

      <div
        className={clsx(
          classes.add_contact,
          'flex sm:relative w-full sm:max-w-[64rem] flex-col pt-[12px] pb-[32px] h-auto sm:h-[max-content] -sm:py-[16px] sm:overflow-scroll'
        )}
        style={{
          borderRadius: '12px',
          background: '#FFF',
          boxShadow: '0px 4px 20px 0px rgba(76, 87, 125, 0.02)',
        }}
      >
        <div className="sm:overflow-scroll">
          <div className="mt-6">
            <ul className={classes.all_list}>
              <li className={`${classes.list} ${classes.border_bottom}`} style={{ paddingBottom: "16px", display: "flex", alignItems: "flex-start", flexWrap: "unset" }}>
                <div className={clsx(
                  classes.name_id,
                  classes.widthNameAndGmail
                )}>
                  <div className={classes.name}>
                    <p className={classes.name} style={{ overflowWrap: 'anywhere', display: 'inline-block' }}>
                      Bill to: {customerName}
                      <div className={classes.dot} style={{ display: 'inline-block', margin: "4px" }}></div>
                      {country}
                    </p>
                  </div>
                  <div className={classes.invoiceId} style={{ overflowWrap: 'break-word', display: "block" }}>
                    <div>{email}</div>
                  </div>
                </div>
                <div className={classes.amount_status}>
                  <div className={classes.amount}>
                    <PaymentStatusTag status={currentStatus} type="light" />
                  </div>
                  <div className={classes.heading}>
                    <div>Current Status</div>
                  </div>
                </div>
              </li>
              <li className={`${classes.list} ${classes.border_bottom}`} style={{ padding: "16px 0" }}>
                <div className={classes.name_id}>
                  <div className={classes.name}>{`${invoiceId}`}</div>
                  <div className={classes.heading}>
                    <div>Invoice ID</div>
                  </div>
                </div>
                <div className={classes.amount_status}>
                  <div className={classes.name}>{purposeCode}</div>
                  <div className={classes.heading}>
                    <div>Purpose Code</div>
                  </div>
                </div>
              </li>
              <li className={`${classes.list} ${classes.border_bottom}`} style={{ padding: "16px 0" }}>
                <div className={classes.name_id}>
                  <div className={classes.name}>
                    {dayjs(issueDate).format('DD/MM/YYYY')}
                  </div>
                  <div className={classes.heading}>
                    <div>Issue Date</div>
                  </div>
                </div>
                <div className={classes.amount_status}>
                  <div className={classes.name}>
                    {dueDate ? dayjs(dueDate).format('DD/MM/YYYY') : "--"}
                  </div>
                  <div className={classes.heading}>
                    <div>Due Date</div>
                  </div>
                </div>
              </li>
              <li className={`${classes.list}`} style={{ paddingTop: "16px", marginBottom: "20px" }}>
                <div className={classes.name_id}>
                  <div className={clsx(classes.invoiceCurrency, '!bg-transparent !p-[0px]')}>
                    <div>Receivable Amount</div>
                  </div>
                </div>
                <div className={classes.amount_status}>
                  <div className={clsx(classes.amount, classes.amountVal)}>
                    {`${invoiceCurrencySign}${formatNumberToLocaleString((invoiceAmount), 2, 2)}`}
                  </div>
                  <div className={classes.heading}>
                    <div>Invoice Amount</div>
                  </div>
                </div>
              </li>
              <li className={`${classes.list}`}>
                <div className="col-span-2 rounded-xl flex flex-row items-center justify-between gap-3 bg-[#F6F7FA] px-[16px] py-[8px] w-full h-[40px]">
                  <div className="rounded-full w-fit flex flex-row items-center justify-between gap-2 bg-[#1e333f80] px-4 py-1 ">
                    <img src={WalletWhite} alt="wallet" className="w-6 h-6" />{' '}
                    <p className="text-lg font-bold text-center text-[#FFFFFF]">
                      <span className="inline-block">{savingCurrencySign}{Number(savingAmount)?.toFixed(0)} SAVINGS</span>
                    </p>
                  </div>
                  <p className="text-[#1E333F] text-xl font-normal flex-grow">
                    You will lose these savings.
                  </p>
                  {/* <img src={InfoIcon} alt="i" className="w-7 h-7" /> */}
                </div>
              </li>
            </ul>
          </div>

          {/* <div className="flex flex-col mt-[16px] items-start gap-4">
            <h3>Description</h3>
            <p
              className={clsx(
                'text-xl font-medium text-[#1E333F80] col-span-2'
              )}
            >
              {invoice_attributes.invoice_description ||
                `Description not available`}
            </p>
          </div> */}
        </div>
      </div>
      
      <div className="w-full flex">
        <Button
          className="w-full bg-white border border-solid text-black border-[#181C3033] mr-[6px] h-[48px]"
          text={'Cancel'}
          disabled={false}
          onClick={() => props.closeModal()}
        />
        <Button
          className={clsx(
            'w-full ml-[6px] h-[48px]',
            settlingOutsideFideo && 'cursor-not-allowed',
            settlingOutsideFideo ? 'text-[#ffffff80]' : 'text-[#ffffff]',
          )}
          icon={TickCircleIcon}
          text="Settled Outside Fideo"
          disabled={settlingOutsideFideo}
          onClick={handleClick}
        />
      </div>
    </div>
  )
}
export default CancelledOrPaidOutsidePaymentPrompt;
