import classes from "Components/ReportTable/Action.module.scss";
import actionIcon from "assets/Images/action-icon.svg";
import { useState, useCallback, useEffect } from "react";
import useClickOutside from "hooks/useClickOutside";
import Close from "Layout/Close";
import ReactDOM from "react-dom";
import Backdrop from 'Layout/Backdrop'
import clsx from 'clsx'
import dayjs from 'dayjs'

const Action = (props) => {
  const { cellData, option = [], classed = '' } = props
  const [showOption, setShowOption] = useState(false)
  const [notShowFiraButton, setNotShowFiraButton] = useState(false)
  const optRef = useClickOutside(() => setShowOption(false))
  const handleOptionClick = (event,option) => {
    event.stopPropagation()
    if (option?.onClick) option?.onClick(cellData)
    setShowOption(false)
  }

  const handleOptionOpen = (event,option) => {
    event.stopPropagation()
    setShowOption((prev) => !prev)
  }

  const status = cellData?.customer_detail?.verificationStatus;
  const send_link = (cellData?.status?.toLowerCase() === 'created' || cellData?.status?.toLowerCase() === 'partially paid' || cellData?.status?.toLowerCase() === 'in review' || cellData?.status?.toLowerCase() === 'link sent' || (cellData?.status?.toLowerCase() === 'in progress' && Number(cellData?.pendingAmount || 0) > 0));
  const receivablesCustomerStatus = cellData?.status?.split(' ')?.join('')?.toLowerCase();

  useEffect(() => {
    const invoiceDate = dayjs(cellData?.lastUpdatedTime)
    const now = dayjs()
    if (cellData?.firaStatus && cellData?.firaStatus?.toLowerCase() === 'generated')
      setNotShowFiraButton(true)
    else setNotShowFiraButton(false)
  }, [cellData])

  return (
    <>
      {showOption &&
        ReactDOM.createPortal(
          <Backdrop className={classes.backdrop} />,
          document.getElementById('backdrop-root')
        )}
      <div ref={optRef} className={`${classes.action} ${props.className}`}>
        {showOption && (
          <div className={classes.action_all}>
            <div className={classes.header}>
              <div className={classes.heading}>Actions</div>
              <Close
                onClick={() => setShowOption(false)}
                className={classes.close}
              />
            </div>
            <div className={classes.options}>
              {option?.map((opt, index) => (
                <div
                  className={clsx(
                    classes.opt,
                    classed,
                    !opt.onClick && 'opacity-50',
                    // opt.text === 'Receive Payment' && 
                    opt.text === 'Receive Payment' &&  (status === ('PENDING_VERIFICATION') || status === 'VERIFICATION_FAILED')  && 'opacity-50',
                    ((opt.text === 'Send Link' &&  (receivablesCustomerStatus !== 'created' && receivablesCustomerStatus !== 'linksent' && receivablesCustomerStatus !== 'inreview' && receivablesCustomerStatus !== 'partiallypaid')) ||
                    // (opt.text === 'Edit' &&  
                    // (receivablesCustomerStatus !== 'indraft') && (receivablesCustomerStatus !== 'inreview') && (receivablesCustomerStatus !== 'created') && (receivablesCustomerStatus !== 'linksent') 
                    //   && (receivablesCustomerStatus !== 'inprogress') && (receivablesCustomerStatus !== 'settled')) ||
                    (opt.text === 'View' &&  (receivablesCustomerStatus === 'indraft')) ||
                    (opt.text === 'Download FIRA' &&  (!notShowFiraButton))) ? 'cursor-default opacity-50' : 'cursor-pointer',
                  )}
                  key={`${opt.text}-${index}`}
                  onClick={(event) => handleOptionClick(event,opt)}
                >
                  <img
                    src={opt.icon}
                    alt={`${opt.icon}-icon`}
                    className={classes.opt_icon}
                  />
                  <div className={classes.text}>{opt.text}</div>
                </div>
              ))}
            </div>
          </div>
        )}
        <img
          src={actionIcon}
          alt="action-icon"
          className={clsx(
            classes.icon,
            showOption && classes.icon_active,
            'min-w-[19.5px] w-[19.5px] max-w-[19.5px]'
          )}
          onClick={(event) => handleOptionOpen(event)}
        />
      </div>
    </>
  )
}
export default Action;
