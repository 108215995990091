import classes from 'Components/Tour/TourStartHeader.module.scss'
import clsx from 'clsx'
import RightIcon from 'assets/Icons/chevron-right-white.svg'
import Button from 'Layout/Button';
import { useRef, useState } from 'react';
import Close from 'Layout/Close';
import chevronRight from "assets/Icons/chevron-right.svg"
import { modalActions } from "Store/modal-redux"
import { useSelector, useDispatch } from "react-redux"

const TourStartHeader = () => {
  const dispatch = useDispatch();
  const buttonRef = useRef(null)
  const [displayBox, setDisplayBox] = useState(true);
  return (
    <div className="mb-[48px] -sm:mb-[12px] -sm:hidden relative">
      {displayBox && 
      <>
      <Close
       className = "absolute top-[5px] right-[5px] w-[24px] h-[24px]"
       onClick = {()=>{
         setDisplayBox(false)
      }} />
        <div className={classes.bigCont}>
          <div className={classes.contentBox}>
            <div className={classes.heading}>
               We’re glad to have you on Fideo
            </div>
            <div className={classes.para}>
               Let us invite you to our guided tour, where we’ll get you set up in no time.
            </div>
          </div>
          <div className={classes.buttonBox} onClick={()=>{
            dispatch(modalActions.tourStart(true))
            }}>
             Start Tour
             <img src={chevronRight} alt='' className={classes.rightIcon} />
          </div>
        </div>
      </>
      }
    </div>
  )
}

export default TourStartHeader
