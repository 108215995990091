import ReactDOM from 'react-dom'
import classes from './InvoicesTable.module.scss'
import sorting from 'assets/Images/sorting-icon.svg'
import ReportTableLayout from 'Components/ReportTable/ReportTableLayout'
import Tooltip from 'Layout/Tooltip'
import eye from 'assets/Images/eye.svg'
import edit from 'assets/Images/edit.svg'
import moreInfo from 'assets/Images/moreInfo.svg'
import dayjs from 'dayjs'
import { useCallback, useState } from 'react'
import MobileInvoiceTable from './MobileInvoiceTable'
import clsx from 'clsx'
import PaymentStatusTag from 'Components/ReportTable/PaymentStatusTag'
import Action from 'Components/ReportTable/Action'
import { useDispatch, useSelector } from 'react-redux'
import { modalActions } from 'Store/modal-redux'
import UseApiCall from 'hooks/useApiCall'
import { apiGetInvoiceDetails } from 'services'
import { CustomersActions, ReceivablesActions } from 'Store/customer-redux'
import { Pagination } from 'antd'
import { enqueueSnackbar } from 'notistack'
import EditInvoice from "Components/Invoice/EditInvoice";
import AttachmentsIcon from "assets/Icons/attachments.svg";
import ArrowIcon from "assets/Icons/chevron-right.svg";
import Button from 'Layout/Button'
import { formatNumberToLocaleString } from 'hooks/formatNumberToLocaleString';

const InvoicesTable = (props) => {
  const dispatch = useDispatch()
  // const [selectedInvoiceId, setSelectedInvoiceId] = useState([])
  // const [selectedInvoices, setSelectedInvoices] = useState([])
  const [key, setKey] = useState()
  const editInvoiceDisplay = useSelector((state) => state.modal.editInvoiceDisplay)
  // const getTime = useCallback(
  //   (date) => {
  //     const invoiceDate = dayjs(date)
  //     const now = dayjs()
  //     return now.diff(invoiceDate, 'days')
  //   },
  //   [props]
  // )

  // const optionArr = [
  //   {
  //     text: 'View',
  //     icon: eye,
  //     active: true,
  //     onClick: (cellData) => {
  //       if (cellData?.status?.toLowerCase() !== "in draft") handleInvoiceSelection(cellData?.invoiceId);
  //     },
  //   },
  //   {
  //     text: 'Edit',
  //     icon: edit,
  //     // disabled: cellData?.status?.toLowerCase() === 'in draft' ? false : true,
  //     onClick: (cellData) => {
  //       if (cellData?.status?.toLowerCase() === 'in draft') {
  //         dispatch(modalActions.editInvoice())
  //         setSelectedInvoiceId(cellData?.invoiceId)
  //       }
  //     },
  //   },
  // ]
  // const [getSelectedInvoice] = UseApiCall(apiGetInvoiceDetails, (res) => {
  //   dispatch(ReceivablesActions.getSelectedInvoice(res?.data))
  //   dispatch(CustomersActions.setSelectedInvoice(res?.data))
  //   dispatch(CustomersActions.setActiveInvoiceTab('details'))
  //   dispatch(modalActions.showInvoiceDisplay())
  // }, (err) => {
  //   enqueueSnackbar(`${err?.data?.error_response?.message} ${err?.data?.error_response?.errors !== null ? JSON.stringify(err?.data?.error_response?.errors[0]) : ""}`, { variant: 'error' })
  // })

  // const handleInvoiceSelection = async (invoiceId) => {
  //   await getSelectedInvoice({ invoiceId })
  // }

  const itemRender = (_, type, originalElement) => {
    if (type === 'prev') {
      return <a>Previous</a>
    }
    if (type === 'next') {
      return <a>Next</a>
    }
    return originalElement
  }

  const handlePaginate = (page) => {
    props.setCurrent(page);
    props.handlePaginate(page - 1)
  };

  const checkedInvoiceId = (event, invoiceId, price, currency) => {

    if (props.selectedInvoices?.length < 1000) {
      !event.target.checked
        ? props.setSelectedInvoices(props.selectedInvoices.filter((invoice) => invoice.invoiceId !== invoiceId))
        : props.setSelectedInvoices((prev) => [...prev, {invoiceId, price, currency}]);
    } else {
      return false;
    }
  };

  return (
    <div>
      <div className={clsx( classes.mainTable, 'mt-[8px]', (props.selectedInvoices?.length > 0 && props.screenSize?.width > 640) ? 'mb-[0px]' : 'mb-[10px]')}>
        {/* {editInvoiceDisplay &&
          ReactDOM.createPortal(
            <EditInvoice
              selectedInvoiceId={selectedInvoiceId}
              nextPageFunctionality={() => dispatch(modalActions.removeAll())}
            />,
            document.getElementById("modal-root")
        )} */}
        <div className={clsx(classes.tableHeading, '')}>
          Invoices details
        </div>
        <ReportTableLayout className={clsx(classes.table, '!p-[0px]')}>
          <thead>
            <th className={classes.cell_header1} style={{ borderTopLeftRadius: "10px", width: "40px" }}>
              {/* <div className={`${classes.cell_header2}`}> */}
                {/* <div className={classes.head}> */}
                  {/* <input
                    type="checkbox"
                    className={classes.termsCheckboxForInvoice}
                    name="selectall"
                    id="selectall"
                    key={key}
                    checked={props.checklist[props.page].isChecked}
                    style={{ cursor: "pointer" }}
                    onChange={(e) => {
                      const updatedChecklist = props.checklist.map((item, index) => {
                        if (index === props.page) {
                          return { isChecked: !props.checklist[props.page].isChecked };
                        }
                        return item;
                      });
                      props.setChecklist(updatedChecklist);
                      const updatedSelectedInvoices = props.tableData?.reduce((selectedInvoices, data, index) => {
                        if (index < 1000) {
                          const newInvoice = { invoiceId: data?.invoiceId, price: data?.invoiceAmount, currency: data?.invoiceCurrency };
                          if (!e.target.checked) {
                            // Remove the invoiceId if it exists in selectedInvoices
                            return selectedInvoices.filter(
                              (invoice) => invoice.invoiceId !== newInvoice.invoiceId
                            );
                          }
                          if (selectedInvoices.some((invoice) => invoice.invoiceId === newInvoice.invoiceId)) {
                            return selectedInvoices;
                          }
                          return [...selectedInvoices, newInvoice];
                        }
                        return selectedInvoices;
                      }, props.selectedInvoices || []);
                      
                      props.setSelectedInvoices(updatedSelectedInvoices);
                    }}
                  /> */}
                {/* </div> */}
                {/* <inpt type="checkbox" /> */}
              {/* </div> */}
            </th>
            {props.tableHeader.map((header, index) => (
              <th key={`${header?.heading}-${index}`} className={classes.cell_header1}>
                <div className={classes.cell_header2}>
                  <div className={classes.head}>{header?.heading}</div>
                  { header?.sorting &&
                      <img
                        src={sorting}
                        alt="sorting-icon"
                        className={classes.sorting}
                      />
                  }
                </div>
              </th>
            ))}
            <th className={classes.cell_header1} style={{ borderTopRightRadius: "10px" }}>
              <div className={`${classes.cell_header2}`}>
                <div className={classes.head}>{''}</div>
              </div>
            </th>
            {/* <th className={classes.cell_header1}>
              <div
                className={`${classes.cell_header2} ${classes.actionHeader}`}
              >
                <div className={`${classes.head}`}>Action</div>
              </div>
            </th> */}
          </thead>
          <tbody>
            {props.tableData && props.tableData?.length > 0 && props.tableData.map((data, index) => (
              <tr className={classes.row}>
                <td
                  className={`${classes.cell}`}
                  style={{
                    borderBottom: props.tableData?.length === index + 1 ? '0px' : '',
                    borderBottomLeftRadius: props.tableData?.length === index + 1 ? "10px" : '',
                    // position: "relative",
                    // left: "10px",
                  }}
                  // onClick={() => {
                  //   if (data?.status !== "In Draft") handleInvoiceSelection(data?.invoiceId)
                  //   else {
                  //     dispatch(modalActions.editInvoice())
                  //     setSelectedInvoiceId(data?.invoiceId)
                  //   }
                  // }}
                >
                  <input
                    type="checkbox"
                    name="selectall"
                    className={classes.termsCheckboxForInvoice}
                    id="selectall"
                    style={{ cursor: "pointer" }}
                    checked={props.selectedInvoices.find(
                      (t) => {
                        // console.log(t, "object")
                        return t.invoiceId === data?.invoiceId
                      }
                    )}
                    onChange={(e) => checkedInvoiceId(e, data?.invoiceId, data?.invoiceAmount, data?.invoiceCurrency)}
                  />
                </td>
                <td
                  className={`${classes.cell} ${classes.invoice}`}
                  style={{
                    userSelect: 'none',
                    cursor: 'pointer',
                    borderBottom: props.tableData?.length === index + 1 ? '0px' : ''
                  }}
                  onClick={() => {
                    data?.paymentLink && window.open(data?.paymentLink, "_self");
                  }}
                >
                  {data?.invoiceId}
                </td>
                <td
                  className={classes.cell}
                  style={{
                    cursor: 'pointer',
                    borderBottom: props.tableData?.length === index + 1 ? '0px' : ''
                  }}
                  onClick={() => {
                    data?.paymentLink && window.open(data?.paymentLink, "_self");
                  }}
                >
                  <div className={classes.amount}>
                    {data?.invoiceCurrency && data.invoiceCurrency?.sign + ''}
                      {formatNumberToLocaleString((data?.invoiceAmount), 2, 2)}
                  </div>

                  <div className={classes.amount}>
                    { data?.conversionInvoiceCurrency ? (
                      <span>
                        {`${data?.conversionInvoiceCurrency?.sign}${data?.convertedInvoiceAmount?.toLocaleString(
                          'en-IN'
                        )}`}
                      </span>
                    ) : ('')}
                  </div>
                  {/* <div className={classes.amount}>{`${
                    data.conversionInvoiceCurrency?.sign
                  }${data.convertedInvoiceAmount?.toLocaleString(
                    'en-IN'
                  )}`}</div> */}
                </td>
                <td
                  className={clsx(classes.cell)}
                  style={{
                    cursor: 'pointer',
                    borderBottom: props.tableData?.length === index + 1 ? '0px' : ''
                  }}
                  onClick={() => {
                    data?.paymentLink && window.open(data?.paymentLink, "_self");
                  }}
                >
                  <div className={'flex items-center'}>
                    <img
                      src={AttachmentsIcon}
                      alt="attachments-icon"
                      className={`h-[16px] w-[16px] my-[2px] mr-[4px]`}
                    />
                    <div>
                      {data?.uploadCount ? data?.uploadCount : 0}
                    </div>
                  </div>
                </td>
                <td
                  className={classes.cell}
                  style={{
                    cursor: 'pointer',
                    borderBottom: props.tableData?.length === index + 1 ? '0px' : ''
                  }}
                  onClick={() => {
                    data?.paymentLink && window.open(data?.paymentLink, "_self");
                  }}
                >
                  {data.isOverDue === true && <PaymentStatusTag status={"OVERDUE"} type="dark" />}
                  {dayjs(data?.issueDate).local().format('DD/MM/YYYY')}
                </td>
                <td
                  className={classes.cell}
                  style={{
                    borderBottom: props.tableData?.length === index + 1 ? '0px' : ''
                  }}
                >
                  <Button
                    // key={item.text}
                    text={"Pay"}
                    icon={ArrowIcon}
                    onClick={() => props.handlePayment(data.invoiceAmount?.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }), data.invoiceCurrency)}
                    btnClassIcon={`order-2 w-[16px] h-[16px] ml-[0px] ${props.selectedInvoices?.length > 0 && 'opacity-50'}`}
                    // onClick={item.onClick}
                    className={clsx(classes.btn, `bg-[#FFF] border rounded-[6px] px-[11px] py-[4px] border-solid border-[2px] border-[#181C30] h-[28px] gap-[2px]`, props.selectedInvoices?.length > 0 && 'cursor-not-allowed border-[#808080]')}
                    // disabled={!item.active}
                    // btnClassIcon={clsx(!item.active && 'opacity-50')}
                    btnClassText={clsx(
                      // !item.active && 'opacity-50',
                      props.selectedInvoices?.length > 0 ? `text-[#808080]` : `text-[#000]`
                    )}
                    disabled={props.selectedInvoices?.length > 0}
                  />
                  {/* <div className={classes.cell_action}>
                    <Action option={optionArr} cellData={data} />
                  </div> */}
                </td>
                <td
                  className={classes.cell}
                  style={{
                    cursor: 'pointer',
                    borderBottom: props.tableData?.length === index + 1 ? '0px' : '',
                    borderBottomRightRadius: props.tableData?.length === index + 1 ? "10px" : ''
                  }}
                  onClick={() => {
                    data?.paymentLink && window.open(data?.paymentLink, "_self");
                  }}
                >
                  <div className={'flex items-center'}>
                    <div>
                      Details
                    </div>
                    <img
                      src={ArrowIcon}
                      alt="arrow-icon"
                      className={`h-[16px] w-[16px] my-[2px] ml-[4px]`}
                    />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </ReportTableLayout>
        {props.tableData && props.tableData?.length > 0 && <div className="-md:hidden w-full flex flex-row justify-end mt-[16px]">
          <Pagination showSizeChanger={false} current={props.current} total={props?.pages} itemRender={itemRender} onChange={handlePaginate} />
        </div>}
        <MobileInvoiceTable
          tableData={props.mobileTableData}
          handlePayment={props.handlePayment}
          // option={optionArr}
          // tableHeader={tableHeader}
          handlePaginate={props.handlePaginate}
          pages={props.totalPages}
          selectedInvoices={props.selectedInvoices}
          setSelectedInvoices={props.setSelectedInvoices}
          setCurrent={props.setCurrent}
          current={props.current}
        />
      </div>
      {
        props.tableData && props.tableData?.length > 0 && <div className="md:hidden w-full flex flex-row justify-center mb-[32px]">
          <Pagination showSizeChanger={false} current={props.current} total={props?.pages} itemRender={itemRender} onChange={handlePaginate} />
        </div>
      }
    </div>
  )
}

export default InvoicesTable
