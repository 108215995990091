// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ComingSoonPromp_animatedText__ZtH\\+T:before{
    content:'Coming Soon!';
    animation: ComingSoonPromp_animate__3jZbK ease 1s;
  }
  @keyframes ComingSoonPromp_animate__3jZbK{
    0%{
      content: 'C'
    }
    10%{
      content: 'Co'
    }
    20%{
      content: 'Com'
    }
    30%{
      content: 'Comi'
    }
    40%{
      content: 'Comin'
    }
    50%{
      content: 'Coming'
    }
    60%{
      content: 'Coming S'
    }
    70%{
      content: 'Coming So'
    }
    80%{
      content: 'Coming Soo'
    }
    90%{
      content: 'Coming Soon'
    }
    100%{
      content: 'Coming Soon!'
    }
  }`, "",{"version":3,"sources":["webpack://./src/Components/atom/ComingSoonPrompt/ComingSoonPromp.module.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,iDAA0B;EAC5B;EACA;IACE;MACE;IACF;IACA;MACE;IACF;IACA;MACE;IACF;IACA;MACE;IACF;IACA;MACE;IACF;IACA;MACE;IACF;IACA;MACE;IACF;IACA;MACE;IACF;IACA;MACE;IACF;IACA;MACE;IACF;IACA;MACE;IACF;EACF","sourcesContent":[".animatedText:before{\n    content:'Coming Soon!';\n    animation: animate ease 1s;\n  }\n  @keyframes animate{\n    0%{\n      content: 'C'\n    }\n    10%{\n      content: 'Co'\n    }\n    20%{\n      content: 'Com'\n    }\n    30%{\n      content: 'Comi'\n    }\n    40%{\n      content: 'Comin'\n    }\n    50%{\n      content: 'Coming'\n    }\n    60%{\n      content: 'Coming S'\n    }\n    70%{\n      content: 'Coming So'\n    }\n    80%{\n      content: 'Coming Soo'\n    }\n    90%{\n      content: 'Coming Soon'\n    }\n    100%{\n      content: 'Coming Soon!'\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"animatedText": `ComingSoonPromp_animatedText__ZtH+T`,
	"animate": `ComingSoonPromp_animate__3jZbK`
};
export default ___CSS_LOADER_EXPORT___;
