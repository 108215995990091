import { Outlet } from "react-router-dom";
import AdminHeader from "Components/General/AdminHeader";
import SideBar from "Components/General/SideBar";
import classes from "Layout/Admin/Admin.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { useRecoilState, useSetRecoilState } from "recoil";
import { modalRefState } from "recoil/layoutRef";
import CustomModal from "Components/atom/CustomModal/CustomModal";
import { useEffect, useRef, useState } from "react";
import { GlobalActions } from "Store/global-redux";
import Backdrop from 'Layout/Backdrop'
import ScrollToTop from "Layout/ScrollToTop";

const AdminRoot = () => {
  const dispatch = useDispatch()
  const showSideBarMobile = useSelector((state) => state.global.showSideBarMobile);
  const showTourModal = useSelector((state) => state.global.showTour);
  const [modalRef, setModalRef] = useState();
  const scrollEffect = useSelector((state) => state.global.scrollEffect)

  const setRecoilModalRef = useSetRecoilState(modalRefState);
  useEffect(() => {
    if (modalRef) {
      setRecoilModalRef(modalRef);
    }
  }, [modalRef]);

  // console.log('scrollEffect',scrollEffect)

  const handleScroll = () => {
    const scrollElement = document.getElementById('yourScrollableElementId');
    const scrollY = scrollElement.scrollTop;
    // console.log('scrollY Admin:', scrollY);
    dispatch(GlobalActions.setScrollYValue(scrollY))
    // Perform actions based on the scrollY value
  };

  useEffect(() => {
    const scrollElement = document.getElementById('yourScrollableElementId');
    if (scrollElement) {
      scrollElement.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (scrollElement) {
        scrollElement.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  
  return (
    <>
      <AdminHeader className={classes.admin_header} />
      <div className={classes.admin_root}>
        <div id="backdrop-root">
          {
            showTourModal &&
              <Backdrop
                onClick={() => {}}
                className={classes.backdrop}
              />
          }
        </div>
        <div id="modal-root"></div>
        <SideBar
          className={`${classes.sideBar} ${
            showSideBarMobile && classes.sideBar_able
          }`}
        />
        <div className={`${classes.outlet}`} style={{overflow : "scroll"}} id="yourScrollableElementId">
          <CustomModal ref={setModalRef} />
          <>
            <ScrollToTop />
            <Outlet />
          </>
          {/* <Outlet /> */}
        </div>
      </div>
    </>
  );
};
export default AdminRoot;
