import PageIntro from 'Components/Global/PageIntro';
import classes from 'Pages/Admin/Settings.module.scss';
import userAdd from 'assets/Images/user-add-white.svg';
import { useState } from 'react';
import UserReportTable from 'Components/ReportTable/UserReportTable';
import Button from 'Layout/Button';
import ReportPageFilter from "Components/Global/ReportPageFilter";


const tableData = [
    {
        invoice : "#123456",
        name : "Rahul Sahni",
        email : "rahul@gmail.com",
        country : "UK",
        role : "Admin",
        status : "created",
        lastModified : "05/12/2022",
    },
    {
        invoice : "#123456",
        name : "Rahul Sahni",
        email : "rahul@gmail.com",
        country : "UK",
        role : "Admin",
        status : "created",
        lastModified : "05/12/2022",
    },
    {
        invoice : "#123456",
        name : "Rahul Sahni",
        email : "rahul@gmail.com",
        country : "UK",
        role : "Admin",
        status : "created",
        lastModified : "05/12/2022",
    },
    {
        invoice : "#123456",
        name : "Rahul Sahni",
        email : "rahul@gmail.com",
        country : "UK",
        role : "Admin",
        status : "created",
        lastModified : "05/12/2022",
    },
    {
        invoice : "#123456",
        name : "Rahul Sahni",
        email : "rahul@gmail.com",
        country : "UK",
        role : "Admin",
        status : "created",
        lastModified : "05/12/2022",
    },
    {
        invoice : "#123456",
        name : "Rahul Sahni",
        email : "rahul@gmail.com",
        country : "UK",
        role : "Admin",
        status : "created",
        lastModified : "05/12/2022",
    },
    {
        invoice : "#123456",
        name : "Rahul Sahni",
        email : "rahul@gmail.com",
        country : "UK",
        role : "Admin",
        status : "created",
        lastModified : "05/12/2022",
    },
    {
        invoice : "#123456",
        name : "Rahul Sahni",
        email : "rahul@gmail.com",
        country : "UK",
        role : "Admin",
        status : "created",
        lastModified : "05/12/2022",
    },
    {
        invoice : "#123456",
        name : "Rahul Sahni",
        email : "rahul@gmail.com",
        country : "UK",
        role : "Admin",
        status : "created",
        lastModified : "05/12/2022",
    },
    {
        invoice : "#123456",
        name : "Rahul Sahni",
        email : "rahul@gmail.com",
        country : "UK",
        role : "Admin",
        status : "created",
        lastModified : "05/12/2022",
    },
    {
        invoice : "#123456",
        name : "Rahul Sahni",
        email : "rahul@gmail.com",
        country : "UK",
        role : "Admin",
        status : "created",
        lastModified : "05/12/2022",
    },
];

const tableHeaderRole = ["Role id", "created by", "roll name", "status", "last updated"];

const tableHeaderUsers = ["user id", "created by", "roll name", "status", "last updated"];

const tableDataRole = tableData;

const tableDataUsers = tableData;

const mobileTableDataRole = [
    {
        name: "Rahul",
        invoice: "#123456",
        country: "UK",
        email: "Last updated: 05/12/2022",
        amount : "$300",
        status : "Created"
    },
    {
        name: "Rahul",
        invoice: "#123456",
        country: "UK",
        email: "Last updated: 05/12/2022",
        amount : "$300",
        status : "Created"
    },
    {
        name: "Rahul",
        invoice: "#123456",
        country: "UK",
        email: "Last updated: 05/12/2022",
        amount : "$300",
        status : "Created"
    },
    {
        name: "Rahul",
        invoice: "#123456",
        country: "UK",
        email: "Last updated: 05/12/2022",
        amount : "$300",
        status : "Created"
    },
    {
        name: "Rahul",
        invoice: "#123456",
        country: "UK",
        email: "Last updated: 05/12/2022",
        amount : "$300",
        status : "Created"
    },
    {
        name: "Rahul",
        invoice: "#123456",
        country: "UK",
        email: "Last updated: 05/12/2022",
        amount : "$300",
        status : "Created"
    },
    {
        name: "Rahul",
        invoice: "#123456",
        country: "UK",
        email: "Last updated: 05/12/2022",
        amount : "$300",
        status : "Created"
    },
    {
        name: "Rahul",
        invoice: "#123456",
        country: "UK",
        email: "Last updated: 05/12/2022",
        amount : "$300",
        status : "Created"
    },
    {
        name: "Rahul",
        invoice: "#123456",
        country: "UK",
        email: "Last updated: 05/12/2022",
        amount : "$300",
        status : "Created"
    },
    {
        name: "Rahul",
        invoice: "#123456",
        country: "UK",
        email: "Last updated: 05/12/2022",
        amount : "$300",
        status : "Created"
    },
    {
        name: "Rahul",
        invoice: "#123456",
        country: "UK",
        email: "Last updated: 05/12/2022",
        amount : "$300",
        status : "Created"
    },
    {
        name: "Rahul",
        invoice: "#123456",
        country: "UK",
        email: "Last updated: 05/12/2022",
        amount : "$300",
        status : "Created"
    },
    {
        name: "Rahul",
        invoice: "#123456",
        country: "UK",
        email: "Last updated: 05/12/2022",
        amount : "$300",
        status : "Created"
    },
    {
        name: "Rahul",
        invoice: "#123456",
        country: "UK",
        email: "Last updated: 05/12/2022",
        amount : "$300",
        status : "Created"
    },
]

const mobileTableDataUsers = mobileTableDataRole;


const Settings = () => {
    return (
        <div className={classes.settings}>
            <div className={classes.text}>Coming Soon.</div>
            {/* <Button text="Get instant credit" className={classes.button} disabled={true}/> */}
        </div>
    )
    /*const [role, setRole] = useState('roles');
    const arrBtn = [
        {
            text: 'Create',
            icon: userAdd,
            onClick : function() {}
        },
    ]

    return (
        <div className={classes.settings}> 
            <PageIntro 
                pageName={'User Management'} 
                message={'Create Roles, Users to use your Fideo account'}
                arrBtn={arrBtn}
            />
            <div className={classes.userState}>
                <div className={`${classes.user} ${role==='roles' ? classes.active : undefined}`} onClick={()=>setRole('roles')}>Roles</div>
                <div className={`${classes.user} ${role==='users' ? classes.active : undefined}`} onClick={()=>setRole('users')}>Users</div>
            </div>
            <ReportPageFilter searchText={`Search by ${role === "roles" ? "Role id" : "User name"}`} download={true} sendReminder={false} filter={true}/>
            <UserReportTable tableHeader={role==='roles' ? tableHeaderRole : tableHeaderUsers} tableData={role==='roles' ? tableDataRole : tableDataUsers} mobileTableData={role==='roles' ? mobileTableDataRole : mobileTableDataUsers}/>
            <div className={classes.button}>
                {arrBtn.map(arr => <Button key={arr.text} text={arr.text} icon={arr.icon} onClick={arr.onClick} className={classes.btn}/>)}
            </div>
        </div>
    )*/
}
export default Settings;