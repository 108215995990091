import classes from "./PaymentStatusTag.module.scss"
import { useEffect, useState } from 'react'
import clsx from 'clsx'
import InitiatedIcon from '../../assets/Icons/initiated-arrow-white.svg'
import LinkSentIcon from '../../assets/Icons/link-sent-white.svg'
import TickCircleIcon from '../../assets/Icons/tick-circle-white.svg'
import CrossIcon from '../../assets/Icons/cross-circle-white.svg'
import EditWhiteIcon from '../../assets/Icons/edit-white.svg'
import DiscWhiteIcon from '../../assets/Icons/disc-white.svg'
import InfoIcon from '../../assets/Icons/info_brown_circle.svg'
import StatusVerifiedIcon from '../../assets/Icons/status-verified.svg'

const getDarkStatusDetails = (status) => {
    switch (status.split(' ').join('').toLowerCase()) {
        case 'created':
            return {
                icon: EditWhiteIcon,
                title: 'CREATED',
                bgColor: '#FFB800',
            }

        default:
            return {
                icon: EditWhiteIcon,
                title: status,
                bgColor: '#FFB800',
            }
    }
}

const getLightStatusDetails = (status) => {
    switch (status.split(' ').join('').toLowerCase()) {
        case 'potential_match' :
            return {
                title : "POTENTIAL MATCH",
                bgColor: "#FFEBF0",
                textColor: "#E3506E"
            }
        case 'no_match' :
            return {
                title: 'NO MATCH',
                bgColor: "#EBFFF4",
                textColor: "#38B565",
            }
        case 'match' :
            return {
                title: 'MATCH',
                bgColor: "#EBFFF4",
                textColor: "#38B565",
            }
        case 'false_positive':
            return {
                icon: InitiatedIcon,
                title: 'FALSE POSITIVE',
                bgColor: '#e3506e54',
                textColor: '#E3506E',
            }
        case 'true_positive':
            return {
                icon: InitiatedIcon,
                title: 'TRUE POSITIVE',
                bgColor: "#EBFFF4",
                textColor: "#38B565",
            }
        case 'true_positive_reject':
            return {
                icon: InitiatedIcon,
                title: 'TRUE POSITIVE REJECT',
                bgColor: '#FFEBDC',
                textColor: '#FF8934',
            }
        case 'true_positive_approve':
            return {
                icon: InitiatedIcon,
                title: 'TRUE POSITIVE APPROVE',
                bgColor: "#EBFFF4",
                textColor: "#38B565",
            }
        case 'unknown':
            return {
                icon: InitiatedIcon,
                title: 'UNKNOWN',
                bgColor: '#FFF4D9',
                textColor: '#FFB800',
            }

        default:
            return {
                icon: EditWhiteIcon,
                title: status,
                bgColor: '#FFF4D9',
                textColor: '#FFB800',
            }
    }
}

const ComplianceStatusTag = (props) => {
    let { status, withIcon = false, type = 'dark' } = props


    const [statusDetails, setStatusDetails] = useState(
        props?.type === 'light'
            ? getLightStatusDetails(status || '')
            : getDarkStatusDetails(status || '')
    )

    useEffect(() => {
        setStatusDetails(
            props?.type === 'light'
                ? getLightStatusDetails(status || '')
                : getDarkStatusDetails(status || '')
        )
    }, [props, status])

    return (
        <div
            className={clsx(
                'px-[8px] min-h-[20px] rounded-full flex flex-row items-center w-fit'
            )}
            style={{
                backgroundColor: statusDetails?.bgColor
                    ? statusDetails?.bgColor
                    : 'inherit',
            }}
        >
            {withIcon && statusDetails.icon && (
                <img
                    className="w-[16px] aspect-square mr-1"
                    src={statusDetails.icon}
                    alt="icon"
                />
            )}
            <div className={clsx(classes.text)}>
                <p
                    className="text-white font-bold w-max"
                    style={{
                        color: statusDetails?.textColor ? statusDetails?.textColor : '#fff',
                        fontSize: 12,
                    }}
                >
                    {statusDetails?.title}
                </p>
            </div>
        </div>
    )
}

export default ComplianceStatusTag
