import classes from 'Components/Global/ContactInfo.module.scss';
import clsx from 'clsx'

const ContactInfoNew = (props) => {
  return (
    <div className={clsx(`${classes.contact_info} ${props.classNameCountry ? props.classNameCountry : 'w-[55%]'}`)}>
      <div className={clsx(classes.name_country_receivable_report, props.nameCountryClass)}>
        <p className={classes.name} style={{ overflowWrap: 'anywhere', display: 'inline-block' }}>
          {props.name}
          {/* <div className={classes.dot} style={{ display: 'inline-block', margin: "4px" }}></div>
          {props.country} */}
        </p>
      </div>
      <p className={clsx(classes.email, 'w-full', props.emailClass)} 
      style={{ overflowWrap: 'break-word' }}
      >{props.countryName}</p>
    </div>
  )
}
export default ContactInfoNew;