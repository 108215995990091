/* eslint-disable complexity */
import { get, post, httpPut, httpDelete, patch } from "./network";
import URL from "constants/Network";
import {  REFRESH_TOKEN } from "constants/Enums";
import axios from 'axios'

export async function apiAuthToken() {
  const token = window.localStorage.getItem(REFRESH_TOKEN)
  return get(URL.refreshToken, {}, { Authorization: `Bearer ${token}` })
}
export async function apiGetReceivables(params) {
  return get(URL.receivables, params)
}
export async function apiGetReceivableCustomers(params) {
  return get(URL.receivableCustomers, params)
}
export async function apiGetReceivableStatuses(params) {
  const {customerIds} = params;
  return get(`${URL.receivableStatus}?customerIds=${customerIds}`)
}
export async function apiGetCustomerCurrencies(customerId) {
  return get(`${URL.customerCurrencies}?customerId=${customerId}`)
}
export async function apiGetCustomerReport() {
  return get(`${URL.customerReport}?trace=true`)
}
export async function apiGetSummary() {
  return get(URL.summary)
}

export async function apiGetNewSummary() {
  return get(URL.newSummary)
}
export async function apiGetDashboardInvoice() {
  return get(URL.dashboardInvoice)
}
export async function apiGetInvoiceDetails(params) {
  const { invoiceId } = params;
  return get(`${URL.invoiceDetails}?invoiceId=${invoiceId}`)
}
export async function apiGetCustomerDetails(params) {
  const { customerId } = params;
  return get(`${URL.customerDetails}/${customerId}`)
}

export async function apiGetComplianceDetails(params){
  const {customerId} = params
  return get(`${URL.complianceDetails}?customerId=${customerId}`)
}

export async function apiGetSearchComp(params) {
  const { searchId } = params;
  return get(`${URL.searchComp}?searchId=${searchId}`)
}
export async function apiGetCustomerInvoices(params) {
  const { searchString, page } = params;
  return get(`${URL.customerInvoices}?searchString=${searchString}&page=${page}`)
}
export async function apiGetFiraReports() {
  return get(`${URL.firaReports}`)
}
export async function apiGetPayoutReports() {
  return get(`${URL.payoutReports}`)
}
export async function apiDownloadFiraReport(params) {
  const { invoiceId } = params;
  return get(`${URL.downloadFiraReport}?invoiceId=${invoiceId}`)
}
export async function apiDownloadFiraReportWithTxnId(params) {
  const { invoiceId, txnId } = params;
  return get(`${URL.downloadFiraReportWithTxnId}?invoiceId=${invoiceId}&fideoTxnId=${txnId}`)
}
export async function apiDownloadFiraReportWithFiraId(params) {
  const { firaId } = params;
  return get(`${URL.downloadFiraReportWithFiraId}?firaId=${firaId}`)
}
export async function apiDownloadVban(params) {
  const { currency } = params;
  return get(`${URL.downloadVban}?currency=${currency}`)
}
export async function apiGetBankList() {
  return get(URL.bankAccounts)
}
export async function apiGetComplianceList(params) {
  let {pageNum, size} = params
  return get(`${URL.complianceList}?pageSize=${size}&pageNumber=${pageNum}`)
}
export async function apiGetBankDetails(params) {
  const { accountIdd } = params;
  return get(`${URL.bankAccountsDetails}/${accountIdd}`)
}
export async function apiGetForexRate(params) {
  return get(URL.forexRate, params)
}
export async function apiGetVirtualBankList() {
  return get(URL.virtualAccounts)
}
export async function apiGetVirtualBankDetails() {
  return get(URL.virtualAccountsDetails)
}

export async function apiGetVBankDeposits(params) {
  return get(`${URL.VBankDeposits}?currency=${params}`)
}
export async function apiGetVirtualBankDetailsWithParams(params) {
  return get(URL.virtualAccountsDetails, params)
}
export async function apiGetVirtualBankListGetBalance() {
  return get(URL.virtualAccountsGetBalance)
}
export async function apiGetCustomerContactList() {
  return get(URL.customers)
}
export async function apiSignOut() {
  return get(URL.signOut)
}
export async function apiGetUserEmailFromResetToken(params) {
  return get(URL.verifyPasswordResetToken, params)
}
export async function apiLogin(params) {
  return post(URL.login, params)
}
export async function apiGetProfile() {
  // const {userId} = params;
  return get(URL.profile)
}
export async function apiAddCustomer(params) {
  return post(`${URL.customers}?trace=true`, params)
}
export async function apiUpdateCustomer(params) {
  const { customerId, ...rest } = params
  return patch(`${URL.customers}?customerId=${customerId}`, rest)
}
export async function apiCreateInvoice(params) {
  const { transactionId, ...rest } = params
  return post(`${URL.receivables}?transactionId=${transactionId}`, rest)
}
export async function apiAddUser(params) {
  return post(`${URL.addUsers}`, params)
}
export async function apiSendPaymentLink(params) {
  const { invoiceId, paymentLink, ...rest } = params
  return post(
    `${URL.sendPaymentLink}?invoiceId=${invoiceId}&paymentLink=${paymentLink}`,
    rest
  )
}
export async function apiCreateTransaction(params) {
  const { customerId } = params
  return post(`${URL.transactions}?customerId=${customerId}`)
}
export async function apiSetPassword(params) {
  return post(URL.setPassword, params)
}
export async function apiSignUp(params) {
  return post(URL.register, params)
}
export async function fetchFileFromS3(presignedUrl) {
  return axios.get(presignedUrl, {
    responseType: 'arraybuffer',
  })
}
export async function apiGetPaymentDetails(params) {
  return get(URL.verifyPaymentLinkToken, params)
}
export async function apiGetOTP(params) {
  return get(URL.sendPaymentLinkOtp, params)
}
export async function apiGetInvoiceLinkOTP(params) {
  return get(URL.sendInvoiceLinkOtp, params)
}
export async function apiGetNewPaymentDetails(params) {
  return get(URL.verifyNewPaymentLinkToken, params)
}
export async function apiGetPaymentInvoices(params) {
  return get(URL.transactionPresignedUrls, params)
}
export async function apiGetInvoicesPresignedUrlsWithOtp(params) {
  return get(URL.invoicesPresignedUrlsWithOtp, params)
}
export async function apiGetHistoricFxRates(params) {
  return get(URL.fxRates, params)
}

export async function apiResetPassword(queryParams) {
  const urlParams = new URLSearchParams(queryParams).toString();
  let resetWithParams = URL.resetPassword;
  if (urlParams) {
    resetWithParams = `${URL.resetPassword}?${decodeURIComponent(urlParams)}`;
  }
  return post(resetWithParams);
}

export async function apiGetBusinessType() {
  return get(URL.businessType)
}

export async function apiCreateMediaDocumentUploadUrl(params) {
  return get(URL.uploadMediaDocument, params)
}

export async function apiUploadMediaDocumentInS3(url, file) {
  return httpPut(url, file);
}

export async function apiDeleteMediaDocument({linkId}) {
  return httpDelete(`${URL.deleteMediaDocument}?linkId=${linkId}`);
}

export async function apiSubmitLeadForm(params) {
  return post(URL.submitLeadForm, params)
}

// API to mark as settled outside.
export async function apiMarkSettledOutside(body) {
  return httpPut(URL.markSettledOutside, body);
}

export async function apiEditInvoice(params) {
  const { transactionId, ...rest } = params
  return httpPut(`${URL.receivables}?transactionId=${transactionId}`, rest)
}

export async function apiCreateInvoiceChatbot(params) {
  const { transactionId, ...rest } = params
  return httpPut(`${URL.receivablesChatbot}?transactionId=${transactionId}`, rest)
}

export async function apiCreateRealInvoice(params) {
  const { transactionId, ...rest } = params
  return post(`${URL.invoiceCreate}?transactionId=${transactionId}`, rest)
}

export async function apiPreviewInvoice(params) {
  // const { transactionId, ...rest } = params
  return post(`${URL.invoicePreview}`, params)
}

export async function apiGenerateSinglePaymentLink(params) {
  return get(URL.generateSinglePaymentLink, params)
}

export async function apiVerifySinglePaymentInvoices(params) {
  return get(URL.verifySinglePaymentLinkInvoices, params)
}

export async function apiVerifySinglePaymentSummary(params) {
  return get(URL.verifySinglePaymentLinkSummary, params)
}

export async function apiDownloadFiraReportUrl(params) {
  const { invoiceId } = params;
  return get(`${URL.downloadFiraReport}?invoiceId=${invoiceId}`)
}

export async function apiGetBulkTransactionsData(params) {
  return get(URL.bulkTransactionsData, params)
}

export async function apiGetReconcilationData(params) {
  const {currencyCode,customerIds} = params
  return get(`${URL.reconcilationTable}/${currencyCode}?customerIds=${customerIds}`)
}
export async function apiGetReconcilationCustomerList(params) {
  return get(`${URL.reconcilationTableCustomers}/${params}`)
}

export async function apiSendReconcilationData(params) {
  return post(URL.sendReconcilationData, params)
}

export async function apiGetPurposeCodeData() {
  return get(URL.purposeCode)
}

export async function apiGetBulkTransactionsId(params) {
  return post(URL.bulkTransactionsId, params)
}

export async function apiGetBulkTransactionSubmit({ transactionId }) {
  return post(`${URL.bulkTransactionSubmit}?transactionId=${transactionId}`)
}

export async function apiSendSinglePaymentLink({customerId, paymentLink}) {
  return post(`${URL.sendSinglePaymentLink}?customerId=${customerId}&paymentLink=${paymentLink}`)
}

export async function apiGetAgingAccountJson() {
  return get(URL.agingAccountJson)
}
export async function apiGetAgingAccount() {
  return get(URL.agingAccount)
}

export async function apiGetTermsAndConditions(){
  return get(URL.termsAndConditions)
}

export async function apiDownloadFiraReportWithInvoiceId(params) {
  const { invoiceId } = params;
  return get(`${URL.downloadFiraReportWithInvoiceId}?invoiceId=${invoiceId}`)
}

export async function apiShowChatBotModal(params) {
  const { configKeys } = params;
  return get(`${URL.showChatBotModal}?configKeys=${configKeys}`)
}

export async function apiDeleteInvoice(params) {
  const { ...rest } = params
  return httpPut(`${URL.deleteInvoice}`, rest)
}

export async function apiDownloadFircReportWithPayoutRef(params) {
  const { payoutRef } = params;
  return get(`${URL.downloadFirc}?payoutRef=${payoutRef}`)
}

export async function apiFiraFromLastTransactionChatbot() {
  return get(`${URL.firaFromLastTransactionChatbot}`)
}

export async function apiFiraFromCustomerNameChatbot(params) {
  const { customerName } = params;
  return get(`${URL.firaFromCustomerNameChatbot}?customerName=${customerName}`)
}

export async function apiGeneralFiraDownloadChatbot(params) {
  const { ...rest } = params
  return post(`${URL.generalFiraDownloadChatbot}`, rest)
}

export async function apiDeactivateChatbot() {
  return post(`${URL.deactivateChatbot}`)
}

export async function apiMessageChatbot(params) {
  return post(URL.messageChatbot, params);
}

export async function apiConversationChatbot() {
  return get(`${URL.conversationChatbot}`)
}