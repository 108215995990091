import React from 'react';

const PercentageLoader = ({ percentage }) => {
    // Calculate the stroke-dashoffset based on the percentage
    const circumference = 2 * Math.PI * 40;
    const offset = circumference - (percentage / 100) * circumference;

    return (
        <svg width="50" height="50">
            {/* Circle outline */}
            <circle cx="50" cy="50" r="20" fill="none" stroke="#e6e6e6" strokeWidth="4" />
            {/* Percentage loader */}
            <circle
                cx="50"
                cy="50"
                r="20"
                fill="none"
                stroke="#1E333F"
                strokeWidth="4"
                strokeDasharray={circumference}
                strokeDashoffset={offset}
                transform="rotate(-90 50 50)"
            />
            {/* Percentage text */}
            <text x="50%" y="50%" textAnchor="middle" dominantBaseline="middle" fontSize="12px" fill="#1E333FB2">
                {percentage}%
            </text>
        </svg>
    );
};

export default PercentageLoader;
