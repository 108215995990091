import ReportTableLayout from 'Components/ReportTable/ReportTableLayout'
import classes from './CustomerDetailsTabContent.module.scss'

import clsx from 'clsx'

const MobileCustomerDetailsTable = (props) => {
  const { details, address, heading, containerClass } = props
  const mobileDetails = [].concat(...details)
  const mobileAddress = [].concat(...address)
  return (
    <>
      <div className={containerClass}>
        <p className={clsx(classes.tableHeading, 'md:hidden')}>Settlement bank account</p>
        <ReportTableLayout className={clsx(classes.mobileTable)}>
          <tbody>
            {mobileDetails.map((dataItem, index) => (
              <tr key={index} className={classes.row}>
                <td className={`${classes.cell} ${classes.invoice}`}>
                  <div className={classes.value}>{dataItem?.value || ""}</div>
                  <div className={classes.label}>{dataItem.label}</div>
                </td>
              </tr>
            ))}
          </tbody>
        </ReportTableLayout>
      </div>
      <div className={containerClass}>
        <p className={clsx(classes.tableHeading, 'md:hidden sm:pt-[12px]')}>Address</p>
        <ReportTableLayout className={clsx(classes.mobileTable)}>
          <tbody>
            {mobileAddress.map((dataItem, index) => (
              <tr key={index} className={classes.row}>
                <td className={`${classes.cell} ${classes.invoice}`}>
                  <div className={classes.value}>{dataItem?.value || ""}</div>
                  <div className={classes.label}>{dataItem.label}</div>
                </td>
              </tr>
            ))}
          </tbody>
        </ReportTableLayout>
      </div>
    </>
  )
}
export default MobileCustomerDetailsTable
