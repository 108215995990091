import ContactInfo from 'Components/Global/ContactInfo';
import classes from 'Components/ReportTable/UserReportTable.module.scss';
import Action from 'Components/ReportTable/Action';
import MobileReportTable from 'Components/ReportTable/MobileReceivableReportTable';
import ReportTableLayout from 'Components/ReportTable/ReportTableLayout';
import Status from 'Components/ReportTable/PaymentStatusTag';
import eye from 'assets/Images/eye.svg';
import edit from 'assets/Images/edit.svg';
import sorting from 'assets/Images/sorting-icon.svg';

const UserReportTable = (props) => {

    return (
        <div className={classes.usertable}>
            <div className={classes.text}>Coming Soon.</div>
            {/* <Button text="Get instant credit" className={classes.button} disabled={true}/> */}
        </div>
    )
    /*const optionArr=[
        {text: 'View', icon: eye}, 
        {text: 'Edit', icon: edit}
    ];

    return (
        <>
        
            <ReportTableLayout className={classes.table}>
                <thead>
                    {props.tableHeader.map(header => 
                        <th key={header} className={classes.cell_header1}>
                            <div className={classes.cell_header2}>
                                <div className={classes.head}>{header}</div>
                                <img src={sorting} alt='sorting-icon' className={classes.sorting} />
                            </div>
                        </th>
                    )}
                    <th className={classes.cell_header1}>
                        <div className={`${classes.cell_header2} ${classes.actionHeader}`}>
                            <div className={classes.head}>Action</div>
                        </div>
                    </th>
                </thead>
                <tbody>
                    {props.tableData.map(data => 
                        <tr key={data.id} className={classes.row}>
                            <td className={classes.cell}>{data.invoice}</td>
                            <td className={classes.cell}>
                                <ContactInfo name={data.name} email={data.email} country={data.country}/>
                            </td>
                            <td className={classes.cell}>{data.role}</td>
                            <td className={classes.cell}><Status status={data.status}/></td>
                            <td className={classes.cell}>{data.lastModified}</td>
                            <td className={classes.cell}>
                                <div className={classes.cell_action}>
                                    <Action option={optionArr}/>
                                </div>
                            </td>
                        </tr>
                    )}
                </tbody>
            </ReportTableLayout>
            <MobileReportTable tableData={props.mobileTableData} option={optionArr}/>
        </>
    )*/
}

export default UserReportTable;