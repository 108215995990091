import React from 'react';
import classes from 'Components/otpScreen/EnterOtp.module.scss';
import otpLock from 'assets/Icons/otpLock.svg';

const EnterOtp = ({setOtpViewNowBox,setPaymentLinkOtpRequired, handleOTPView}) => {

    // const handleOTPView = () => {
    //     setOtpViewNowBox((prev) => !prev);
    //     setPaymentLinkOtpRequired((prev) => !prev);
    // }

  return (
    <div className={`${classes.otpBlurrContainer}`}>
        <div className={`${classes.otpcontainer}`}>
            <div className={`${classes.lockImg}`}>
                <img src={otpLock} alt='otpLock' className={`${classes.img}`} />
            </div>
            <p className={`${classes.otpPara}`}>This invoice is OTP-protected. Please enter the OTP you've received on your email id.</p>
            <div className={`${classes.otpEnterBox}`} onClick={handleOTPView}>
                <div className={`${classes.otpEntertext}`}>
                    <span className={`${classes.text}`}>Enter OTP to View</span>
                </div>
            </div>
        </div>
    </div>
  )
}

export default EnterOtp;