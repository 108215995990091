import Backdrop from 'Layout/Backdrop'
import Modal from 'Layout/Modal'
import clsx from 'clsx'
import ReactDOM from 'react-dom'
import { useDispatch } from 'react-redux'
import { modalActions } from 'Store/modal-redux'
import Close from 'Layout/Close'

import classes from './PaymentModal.module.scss'
import leftPointer from 'assets/Icons/arrow-left.svg'
import ContactInfo from 'Components/Global/ContactInfo'
import InfoIcon from 'assets/Icons/info_white_circle.svg'
import PointerIcon from 'assets/Icons/mouse-pointer.svg'
import FileIcon from 'assets/Icons/file-plus.svg'
import PurposeIcon from 'assets/Icons/purpose-code.svg'
import PayIcon from 'assets/Icons/pay-icon.svg'
import CopyIcon from 'assets/Icons/copy-black.svg'
import { formatNumberToLocaleString } from 'hooks/formatNumberToLocaleString';

import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from 'Components/atom/Accordion/Accordion'
import Button from 'Layout/Button'
import { useSnackbar } from 'notistack'

const paymentTime = {
  fedwire: 'Settles in 1-2 hours. Cheaper than Swift.',
  swift: 'Settles same day or next day.',
  ach: 'Settles in 2-3 days after payment.',
}

const paymentSteps = [
  {
    title: 'Step 1',
    text: 'Login to your bank account',
    icon: PointerIcon,
  },
  {
    title: 'Step 2',
    text: 'Add the beneficiary details shared above',
    icon: FileIcon,
  },
  {
    title: 'Step 3',
    text: 'Input the purpose code on the banking portal as comment/remark',
    icon: PurposeIcon,
  },
  {
    title: 'Step 4',
    text: 'Make the payment and it’s done',
    icon: PayIcon,
  },
]

const PaymentDetailsModal = (props) => {
  const { enqueueSnackbar } = useSnackbar()
  const { invoiceDetails } = props
  const { invoiceResponse, virtualAccountDto } = invoiceDetails
  const dispatch = useDispatch()

  const handleCopy = (event, method) => {
    event.preventDefault()
    try {
      const text = `Beneficiary : ${
        method.accountHolderName
      }\nBeneficiary address: ${method.bankAddress}\nAccount Number : ${
        method.accountNumber
      }\nAccount Type : ${method.accountType}\n${
        method?.routingCodeType &&
        `${method.routingCodeType}: ${method.routingCode}`
      }\nBank Name: ${method.bankName}\nBank Address: ${method.bankAddress}`
      if ('clipboard' in navigator) {
        navigator.clipboard.writeText(text)
      }
      document.execCommand('copy', true, text)
      enqueueSnackbar('Details Copied!', { variant: 'success' })
      return
    } catch (error) {}
  }
  return (
    <Modal
      className={clsx(
        classes.modal,
        'h-screen max-w-[640px] p-0 overflow-scroll bg-[#F4F6FA]'
      )}
    >
      {ReactDOM.createPortal(
        <Backdrop
          onClick={() => {
            dispatch(modalActions.showPaymentDisplay())
          }}
          className={classes.backdrop}
        />,
        document.getElementById('backdrop-root')
      )}
      <div className="flex z-10  flex-row items-center justify-center sm:justify-between sticky top-0 right-0 left-0 px-16 py-[20px] bg-[#F4F6FA] ">
        <img
          src={leftPointer}
          alt="left-pointer"
          className="block sm:hidden absolute left-8"
          onClick={() => {
            dispatch(modalActions.showPaymentDisplay())
          }}
        />
        <p className="text-4xl font-bold">Payment Details</p>
        <Close
          className="w-[32px] h-[32px] hidden sm:block"
          onClick={() => {
            dispatch(modalActions.showPaymentDisplay())
          }}
        />
      </div>
      <div className=" flex flex-col gap-[32px]">
        <div className="w-full">
          {/* <p className="text-3xl font-bold mb-8">Amount to Pay</p> */}
          <div className="sm:px-16">
            <div className="flex flex-row -sm:border-b border-b-[#ECEFF2] items-center justify-between w-full bg-[#FFFFFF] py-6 px-8 sm:py-10 gap-8 sm:rounded-[12px]">
              <p className="text-2xl font-medium text-[#1E333F80] ">
                Invoice Amount
              </p>
              { props.showSelectedPrice ?
                <p className="text-5xl font-bold text-[#1E333F]">
                  {invoiceResponse?.invoiceCurrency?.sign}{props.invoicesPrice}
                </p> :
                <p className="text-5xl font-bold text-[#1E333F]">
                  {invoiceResponse?.invoiceCurrency?.sign}
                  {formatNumberToLocaleString((invoiceResponse?.invoiceAmount), 2, 2)}
                </p>
              }
            </div>
          </div>
        </div>
        <div className="w-full px-8 sm:px-16 flex flex-col gap-[20px]">
          <div className="flex flex-col gap-[12px]">
            <p className="text-4xl font-bold">Payment Methods</p>
            <p className="text-2xl font-medium  text-[#1E333F80] ">
              Select payment method , copy details and make the transfer
            </p>
          </div>
          <div className="flex flex-col items-center justify-between w-full gap-4 pb-[100px] ">
            <Accordion
              type="single"
              collapsible
              className="w-full flex flex-col gap-[20px]"
            >
              {virtualAccountDto?.map((method, index) => (
                <AccordionItem
                  value={method?.paymentMethod}
                  className="bg-[#FFFFFF] px-8 py-8 rounded-[12px]"
                >
                  <AccordionTrigger openOnLoad={index === 0}>
                    <div className="w-full flex flex-col items-start gap-2 justify-between">
                      <p className="text-3xl font-semibold text-[#1E333F]">
                        {method.paymentMethod} {index === 0 && '(Recommended)'}
                      </p>
                      <p className="text-2xl font-medium text-left text-[#1E333F80]">
                        {paymentTime[method.paymentMethod?.toLowerCase()] || ''}
                      </p>
                    </div>
                  </AccordionTrigger>
                  <AccordionContent>
                    <div className="flex flex-col gap-[16px]">
                      <PaymentMethodDetails
                        title={'Beneficiary'}
                        value={method?.accountHolderName}
                      />
                      <PaymentMethodDetails
                        title={'Beneficiary address'}
                        value={method?.bankAddress || '-'}
                      />
                      <PaymentMethodDetails
                        title={'Account Number'}
                        value={method?.accountNumber}
                      />
                      <PaymentMethodDetails
                        title={'Account Type'}
                        value={method?.accountType}
                      />
                      <PaymentMethodDetails title={'BIC'} value={method?.BIC} />
                      <PaymentMethodDetails
                        title={method?.routingCodeType}
                        value={
                          method?.routingCodeType ? method?.routingCode : null
                        }
                      />
                      <PaymentMethodDetails
                        title={'Bank Name'}
                        value={method?.bankName || '-'}
                      />
                      <PaymentMethodDetails
                        title={'Bank Address'}
                        value={method?.bankAddress || '-'}
                      />
                    </div>
                  </AccordionContent>
                  <Button
                    className="w-full mt-2 bg-white border border-dashed border-[#181C3033]"
                    btnClassText="text-2xl font-medium text-black"
                    icon={CopyIcon}
                    text="Copy Details"
                    disabled={false}
                    onClick={(e) => handleCopy(e, method)}
                  />
                </AccordionItem>
              ))}
            </Accordion>
          </div>
        </div>
        {/* <div className="w-full relative flex flex-row gap-2 pt-12 sm:pt-10 p-8 bg-[#F8F1D8] rounded-[12px] ">
          <div
            className={clsx(
              'absolute -top-2 px-[8px] h-[20px] bg-[#846F3A] gap-2 rounded-full flex flex-row items-center'
            )}
          >
            <img src={InfoIcon} alt="." className="w-[16px] aspect-square" />
            <p className={clsx('text-xl font-semibold text-white')}>
              IMPORTANT INSTRUCTIONS
            </p>
          </div>
          <div className="flex h-14 flex-row">
            <div className="h-full w-0 border-r border-r-[#DBD1B1]" />
            <div className="flex w-5 flex-col justify-between">
              <div className="w-full relative top-3 border-t border-t-[#DBD1B1]" />
              <div className="w-full relative  border-t border-t-[#DBD1B1]" />
            </div>
          </div>
          <div className="w-full flex flex-col gap-2">
            <p className="text-2xl font-normal text-[#846F3A]">
              Please pay via your bank.
            </p>
            <p className="text-2xl font-normal text-[#846F3A]">
              While making the payment, please input this purpose code on the
              banking portal as comment/remark.
            </p>
          </div>
        </div>
        <div className="w-full h-0 border-b border-b-[#ECEFF2]" />
        <div className="w-full pb-[120px] sm:pb-40">
          <p className="text-3xl font-bold mb-8">How to make a payment?</p>
          {paymentSteps?.map((item, index, arr) => {
            return (
              <PaymentStep
                title={item.title}
                text={item.text}
                icon={item.icon}
                isEnd={arr.length - 1 === index}
              />
            )
          })}
        </div> */}
      </div>
    </Modal>
  )
}

const PaymentStep = (props) => {
  const { title, text, icon, isEnd } = props
  return (
    <div className="flex flex-row items-center w-full gap-5">
      <div className="w-[48px] aspect-square rounded-full flex items-center justify-center bg-[#F7F8FB]">
        <img src={icon} alt="" className="w-[24px] aspect-square" />
      </div>
      <div
        className={clsx(
          'w-full flex flex-col gap-2 py-6',
          !isEnd && 'border-b border-b-[#E9EBEC]'
        )}
      >
        <p className="text-xl text-[#1E333F80] font-semibold">{title}</p>
        <p className="text-2xl text-[#1E333F] font-medium">{text}</p>
      </div>
    </div>
  )
}

const PaymentMethodDetails = ({ title, value }) => {
  if (!value) return
  return (
    <div className="w-full flex flex-col gap-[4px] justify-between">
      <p className="text-2xl font-medium text-[#1E333F80]">{title}</p>
      <p className="text-2xl font-medium text-[#1E333F]">{value}</p>
    </div>
  )
}

export default PaymentDetailsModal


