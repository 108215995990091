import classes from 'Components/Payment/ReceivePayment.module.scss';
import {useDispatch, useSelector} from 'react-redux';
import Modal from 'Layout/Modal';
import {modalActions} from 'Store/modal-redux';
import ListOfContact from 'Components/General/ListOfContact';
import {useEffect} from 'react';
import {ContactsActions} from 'Store/customer-redux';
import UseApiCall from 'hooks/useApiCall';
import { apiGetCustomerContactList } from 'services';
import { baseUrl } from 'constants/Network';

const BASE_URL = baseUrl.api
const listOfCustomerData = [
    {
        id: 'c1',
        name: 'Rahul Sahni',
        country: 'UK',
        email: 'rahul@gmail.com',
        phoneNumber: '+91 9876543210'
    },
    {
        id: 'c2',
        name: 'Rahul Sahni',
        country: 'UK',
        email: 'rahul@gmail.com',
        phoneNumber: '+91 9876543210'
    },
    {
        id: 'c3',
        name: 'Rahul Sahni',
        country: 'UK',
        email: 'rahul@gmail.com',
        phoneNumber: '+91 9876543210'
    },
    {
        id: 'c4',
        name: 'Rahul Sahni',
        country: 'UK',
        email: 'rahul@gmail.com',
        phoneNumber: '+91 9876543210'
    },
    {
        id: 'c5',
        name: 'Rahul Sahni',
        country: 'UK',
        email: 'rahul@gmail.com',
        phoneNumber: '+91 9876543210'
    },
    {
        id: 'c6',
        name: 'Rahul Sahni',
        country: 'UK',
        email: 'rahul@gmail.com',
        phoneNumber: '+91 9876543210'
    },
    {
        id: 'c7',
        name: 'Rahul Sahni',
        country: 'UK',
        email: 'rahul@gmail.com',
        phoneNumber: '+91 9876543210'
    },
    {
        id: 'c8',
        name: 'Rahul Sahni',
        country: 'UK',
        email: 'rahul@gmail.com',
        phoneNumber: '+91 9876543210'
    },
    {
        id: 'c9',
        name: 'Rahul Sahni',
        country: 'UK',
        email: 'rahul@gmail.com',
        phoneNumber: '+91 9876543210'
    },
    {
        id: 'c10',
        name: 'Rahul Sahni',
        country: 'UK',
        email: 'rahul@gmail.com',
        phoneNumber: '+91 9876543210'
    },
    {
        id: 'c11',
        name: 'Rahul Sahni',
        country: 'UK',
        email: 'rahul@gmail.com',
        phoneNumber: '+91 9876543210'
    },
    {
        id: 'c12',
        name: 'Rahul Sahni',
        country: 'UK',
        email: 'rahul@gmail.com',
        phoneNumber: '+91 9876543210'
    },

]

const ReceivePayment = (props) => {
    const contactList = useSelector(state => state.customerContacts.contacts)
    const dispatch = useDispatch();
    const [getContactList, fetching] = UseApiCall(apiGetCustomerContactList, (res) => { 
        dispatch(ContactsActions.setcontacts(res.data))
    })

    useEffect(() => {
        getContactList()
    }, [])

    return (
        <Modal className={classes.modal}>
            <ListOfContact
                contactType={'Customer'}
                contactFunctionality={'receive'}
                contact={contactList}
                btnFunction={(type) => {
                    if (type === "addContact") {
                        dispatch(modalActions.addContact())
                    } else {
                        dispatch(modalActions.receivePayment())
                    }
                    // dispatch(modalActions.receivePayment())
                    // dispatch(modalActions.addContact())
                }}
                btnText={'Add Customer'}
                addContactURL={''}
                nextPageFunctionality={() => {
                    props.modal === "createInvoice" ?
                        dispatch(modalActions.setCreateInvoiceModal(true))
                        :
                    // props.modal === "createInvoiceRec" ?
                    //     dispatch(modalActions.setCreateInvoiceModalRec(true))
                    //     :
                    // props.modal === "createInvoiceRecDraft" ?
                    //     dispatch(modalActions.setCreateInvoiceModalRecDraft(true))
                    //     :
                    dispatch(modalActions.createInvoice())
                }}
                onClose={
                    props.modal === "createInvoice" ?
                    () => {
                    dispatch(modalActions.setCreateInvoiceReceivePayment(false))
                    } :
                        () => {
                            dispatch(modalActions.receivePayment(true))
                        }
                }
                loading = {fetching}
                modal={props.modal}
            />
        </Modal>
    )
}
export default ReceivePayment;