import React, { useEffect, useRef, useState } from 'react'
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetFooter,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from 'Components/atom/BottomSheet/BottomSheet'
import FileBrowser from 'Components/atom/FileBrowser/FileBrowser'
import Button from 'Layout/Button'
import ViewIcon from 'assets/Images/search.svg'

import DownloadIcon from 'assets/Icons/download-cloud-white.svg'
import otpLock from 'assets/Icons/otpLock.svg'

import FileViewer from 'Components/atom/FileViewer/FileViewer'
import { downloadBlob } from 'utils/utils'
import { useDispatch, useSelector } from 'react-redux'
import { modalActions } from 'Store/modal-redux'
import clsx from 'clsx'

const DocumentSheet = (props) => {
  const documentRef = useRef(null)
  const dispatch = useDispatch();
  const [isSheetDialogOpen, setIsSheetDialogOpen] = useState(false);
  const [key, setKey] = useState(false);
  // const documentRef = useRef(null);

  const handleOpenSheetDialog = () => {
    setIsSheetDialogOpen(true);
  };

  const handleCloseSheetDialog = () => {
    setIsSheetDialogOpen(false);
  };
  const showFileDisplay = useSelector((state) => state.modal.showFileDisplay)
  const { invoices, invoiceId ,handleResendOTPView,otpErrorState,presignedUrlErrorState,invoiceData,otp,setOtp,handleOTPView,
    handleOTPViewNow,setOtpViewNowBox, otpViewNowBox, setPaymentLinkOtpRequired, paymentLinkOtpRequired,setInvoicetoBigScreen, invoicetoBigScreen} = props
  const handleDownload = (e) => {
    e.preventDefault()
    invoices?.map((invoice, index) =>
      downloadBlob(
        invoice?.url,
        invoice?.type,
        `${invoiceId}_${index + 1}_downloads`
      )
    )
  }
//  console.log(paymentLinkOtpRequired);
  const openZoomModal = () => {
      dispatch(modalActions.showFileDisplay())
  };

  // console.log(!otpViewNowBox && !showFileDisplay, invoices, "debug")

  return (
    <Sheet className="bg-white relative">
      <SheetTrigger
        asChild
        onClick={(e) => {
          if (!invoices || invoices.length === 0) {
            e.preventDefault()
          }
        }}
      >
        <div className="overflow-x-scroll">
          {
          <FileViewer
            key={key}
            format={['document', 'image']}
            displayUrls={invoices}
            downloadPrefix={invoiceId}
            mobileView={true}
            otpViewNowBox={otpViewNowBox}
            setOtpViewNowBox={setOtpViewNowBox}
            setPaymentLinkOtpRequired={setPaymentLinkOtpRequired}
            paymentLinkOtpRequired={paymentLinkOtpRequired}
            handleResendOTPView={handleResendOTPView}
            otpErrorState={otpErrorState}
            presignedUrlErrorState={presignedUrlErrorState}
            invoiceData={invoiceData}
            otp={otp}
            setOtp={setOtp}
            handleOTPView={handleOTPView}
            handleOTPViewNow={handleOTPViewNow}
            whereCalled="smallScreen"
            setInvoicetoBigScreen={setInvoicetoBigScreen}
            invoicetoBigScreen={invoicetoBigScreen}
          />}
        </div>
      </SheetTrigger>
      {!otpViewNowBox && !showFileDisplay &&
        (<SheetContent
        position="bottom"
        size="content"
        className="bg-white rounded-t-[16px]  h-[90vh] "
        id="checking"
      >
        <SheetHeader className="sticky">
          <SheetTitle>Attachments</SheetTitle>
          {/* <div className="absolute right-[64px]" style={{ zIndex: "1000" }} onClick={openZoomModal}>
            <img src={ViewIcon} alt="view" />
          </div> */}
        </SheetHeader>
        <div className="h-[60vh] pt-[12px] ">
          <div className="mb-[12px] px-[16px] mt-[0px]">
            {
              <FileViewer
                format={['document', 'image']}
                displayUrls={invoices}
                ref={documentRef}
                downloadPrefix={invoiceId}
                otpViewNowBox={otpViewNowBox}
                setOtpViewNowBox={setOtpViewNowBox}
                setPaymentLinkOtpRequired={setPaymentLinkOtpRequired}
                paymentLinkOtpRequired={paymentLinkOtpRequired}
                handleResendOTPView={handleResendOTPView}
                otpErrorState={otpErrorState}
                presignedUrlErrorState={presignedUrlErrorState}
                invoiceData={invoiceData}
                otp={otp}
                setOtp={setOtp}
                handleOTPView={handleOTPView}
                handleOTPViewNow={handleOTPViewNow}
                whereCalled="bigSmall"
                setInvoicetoBigScreen={setInvoicetoBigScreen}
                invoicetoBigScreen={invoicetoBigScreen}
              />
            }
          </div>
          <SheetFooter className="px-[18px] mb-[12px] mt-[20px] justify-center"
          // style={ !otpViewNowBox ? {background : 'rgba(24, 28, 48, 0.24)' }: {} }
          >
            {/* {console.log(otpViewNowBox, paymentLinkOtpRequired, "otp")} */}
            <SheetClose asChild>
              <Button
                text="Download Attachments"
                icon={DownloadIcon}
                className={clsx("outline-none", paymentLinkOtpRequired && 'opacity-50')}
                btnClassIcon={'w-[24px] h-[24px] ml-2'}
                disabled={paymentLinkOtpRequired}
                onClick={handleDownload}
              />
            </SheetClose>
          </SheetFooter>
        </div>
      </SheetContent>)}
    </Sheet>
  )
}

export default React.memo(DocumentSheet)
