import { clsx } from 'clsx'
import { twMerge } from 'tailwind-merge'

function cn(...inputs) {
  return twMerge(clsx(inputs))
}

const downloadBlob = (url, type, downloadName) => {
  var a = document.createElement('a')
  a.href = url
  a.download = `${downloadName}.${type.split('/')[1]}`
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
}

const getDatefromUnix = (time) => {
  const date = new Date(parseInt(time, 10))
  return date
}

// utils.js

// Save data to local storage
const setDataToLocalStorage = (key, data) => {
  localStorage.setItem(key, JSON.stringify(data));
};

// Get data from local storage
const getDataFromLocalStorage = (key) => {
  const data = localStorage.getItem(key);
  return data ? JSON.parse(data) : null;
};

export { downloadBlob, cn, getDatefromUnix , setDataToLocalStorage, getDataFromLocalStorage}
