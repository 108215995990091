import classes from "./CreateInvoice.module.scss";
import billToDark from "../../assets/Icons/billToDark.svg";
import billToLight from "../../assets/Icons/billToLight.svg";
import chevronUp from "../../assets/Icons/chevron-upNew.svg";
import chevronDown from "../../assets/Icons/chevron-downNew.svg";
import checkIcon from "../../assets/Icons/check.svg";
import ContactInfo from "../Global/ContactInfo";
import {useState} from "react";
import clsx from "clsx";
import {useDispatch, useSelector} from "react-redux";
import Shimmer from "../../Layout/Shimmer";
import ReportsPaymentStatusTag from "../ReportTable/ReportsPaymentStatusTag";
import arrowRight from "../../assets/Images/arrow-right.svg";
import {DashboardSummaryActions} from "../../Store/customer-redux";
import useClickOutside from "../../hooks/useClickOutside";

const ContactBillTo = (props) => {
    const dispatch = useDispatch();
    const [contactOpen, setContactOpen] = useState(false);
    const contactRef = useClickOutside(() =>
        setContactOpen(false)
    );
    const contactList = useSelector(state => state.customerContacts.contacts)
    const handleContact = () => {
        setContactOpen(!contactOpen)
    }
    const nextPageAfterLOCHandler = (contact) => {
        dispatch(DashboardSummaryActions.setPassOnContact([]));
        dispatch(DashboardSummaryActions.setPassOnContact(contact));
        // setContactPassOn(contact);
        // props.nextPageFunctionality();
    };
    return (
        <>
            <div className={classes.contactCon} ref={contactRef}>
                {/*contact*/}
                <div
                    className={clsx(`${classes.contact} ${contactOpen ? classes.contactInActive : classes.contactActive}`)}
                    onClick={handleContact}
                >
                    <div className={classes.contactLeft}>
                        <div className={classes.shortName}>
                               <p className={classes.shortNameText}>
                                   {
                                       props?.passOnContact?.customerName?.split(' ')
                                           .map((char) => char.charAt(0).toUpperCase())
                                           .join('').slice(0,2)
                                   }
                               </p>
                        </div>
                        <ContactInfo
                            className={classes.contact_info}
                            name={props?.passOnContact?.customerName}
                            country={props?.passOnContact?.country}
                            phoneNumber={props?.passOnContact?.phoneNumber}
                            email={props?.passOnContact?.emailAddress}
                        />
                    </div>
                    <div className={classes.contactRight}>
                        <img src={contactOpen ? chevronUp : chevronDown}/>
                    </div>
                </div>
                {contactOpen &&
                    <div className={classes.contactListCon}>
                        {
                                (contactList?.length > 0 ? contactList?.map((cnt,index) => (
                                            <div
                                                className={clsx(`${classes.contact} ${index !== contactList?.length && classes.borderBottom }`)}
                                                onClick={(e) => {
                                                    nextPageAfterLOCHandler(cnt);
                                                }}
                                                key={index}
                                            >
                                                <div className={classes.contactLeft}>
                                                    <div className={classes.shortName}>
                                                        <p className={classes.shortNameText}>
                                                            {
                                                                cnt.customerName?.split(' ')
                                                                    .map((char) => char.charAt(0).toUpperCase())
                                                                    .join('').slice(0,2)
                                                            }
                                                        </p>
                                                    </div>
                                                    <ContactInfo
                                                        className={classes.contact_info}
                                                        name={cnt.customerName}
                                                        country={cnt.country}
                                                        phoneNumber={cnt.phoneNumber}
                                                        email={cnt.emailAddress}
                                                    />
                                                </div>
                                                <div className={classes.contactRight}>
                                                    {
                                                        cnt?.id === props?.passOnContact?.id && <img src={checkIcon}/>
                                                    }
                                                </div>
                                            </div>

                                        ))
                                        :
                                        (
                                            contactList.length === 0 && (
                                                <div
                                                    style={{
                                                        textAlign: 'center',
                                                        width: '100%',
                                                        marginTop: '4rem',
                                                        fontSize: '2.5rem',
                                                        // color: 'rgba(24, 28, 49, 0.7)'
                                                    }}
                                                >
                                                No Customer Added Yet.
                                                </div>
                                            )
                                        )
                                )
                        }
                    </div>
                }

                {/*save button*/}
                {/*<div className={classes.currencyType}>*/}
                {/*    <div className={classes.save} onClick={()=>props?.handleSaveAndcontinue("2")}>*/}
                {/*        <p className={classes.saveText}>Save & Continue</p>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>
        </>
    )
}

export default ContactBillTo;