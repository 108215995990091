import classes from 'Components/ReportTable/Status.module.scss';

const Status = (props) => {

    const statusType = (props.type === undefined || props.type === "light") ? "light" : "dark";
    const statusName = props.status?.split(" ").join("").toLowerCase()
    const statusText = props.statusText ? props.statusText : props.status

    return (
        <div className={`${classes.status} ${classes[`${statusName}_${statusType}`]} ${props.className}`}>
            {props.icon && <img className={classes.icon} src={props.icon} alt='icon'/>}
            <div className={classes.text}>{statusText}</div>
        </div>
    )
}

export default Status;