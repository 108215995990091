// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Close_close__GhvtG {
  cursor: pointer;
  height: 3.2rem;
  width: 3.2rem;
}`, "",{"version":3,"sources":["webpack://./src/Layout/Close.module.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,cAAA;EACA,aAAA;AACJ","sourcesContent":[".close{\n    cursor: pointer;\n    height: 3.2rem;\n    width: 3.2rem;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"close": `Close_close__GhvtG`
};
export default ___CSS_LOADER_EXPORT___;
