import classes from "./CreateInvoice.module.scss";
import TextInput from "../../Layout/TextInput";
import CustomSelectorValuesCodes from "../atom/CustomerSelecterValues/CustomSelectorValuesCodes";
import {useEffect, useRef, useState} from "react";
import {DatePicker, Select, Tooltip} from "antd";
import clsx from "clsx";
import dayjs from "dayjs";
import moreIcon from "../../assets/Icons/Group 356.svg";
import {modalActions} from "../../Store/modal-redux";
import INRFlag from "../../assets/Images/INR_Flag.svg";
import ContactInfo from "../Global/ContactInfo";
import upArrow from "../../assets/Icons/arrow-up.svg";
import arrowDownIcon from "../../assets/Icons/arrow-DownIcon.svg";
import checkIcon from "../../assets/Icons/check.svg";
import useClickOutside from "../../hooks/useClickOutside";
import {useDispatch, useSelector} from "react-redux";
import UseApiCall from "../../hooks/useApiCall";
import {apiGetBankList, apiGetPurposeCodeData} from "../../services";
import HelpCircle from "../../assets/Icons/help-circle.svg";
import InfoIcon from "../../assets/Icons/info_black_circle.svg";
import ReactDOM from "react-dom";
import PurposeCodeDropdown from "../ReportTable/PurposeCodeDropdown";

const OtherDetails = (props) => {
    const {
        primaryBank,
        setPrimaryBank,
        invoiceId,
        setInvoiceId,
        purposeCode,
        setPurposeCode,
        issueDate,
        setIssueDate,
        dueDate,
        setDueDate,
        blDate,
        setBLDate,
        paymentTerms,
        setPaymentTerms,
        generalTerms,
        setGeneralTerms,
        shipmentTerms,
        setShipmentTerms,
        addNotesTerms,
        setAddNotesTerms,
        isInvoiceIdValid,
        setIsInvoiceIdValid,
        ispurposeCodeValid,
        setIsPurposeCodeValid,
        consigneeBuyer,
        setConsigneeBuyer,
        poNumberDate,
        setPoNumberDate,
        countryFinalDestination,
        setCountryFinalDestination,
        countryOriginGoods,
        setCountryOriginGoods,
        key,
        setKey,
        allBanks,
        setAllBanks,
        purposeCodeArray,
        setPurposeCodeArray,
        itemType,
    } = props;
    const dispatch = useDispatch();
    const showBankDropDown = useSelector((state) => state.modal.showBankDropDown);
    const purposeCodeDropDown = useSelector((state) => state.modal.purposeCodeDropDown);
    // const [transactionId, setTransactionId] = useState(null);
    // const [primaryBank, setPrimaryBank] = useState(null);
    // const [allBanks, setAllBanks] = useState([]);
    // const [invoiceId, setInvoiceId] = useState('');
    // const [purposeCode, setPurposeCode] = useState('');
    // const [key, setKey] = useState(false)
    // const [issueDate, setIssueDate] = useState();
    // const [dueDate, setDueDate] = useState();
    // const [blDate , setBLDate] = useState();
    // const [paymentTerms, setPaymentTerms] = useState('');
    // const [generalTerms, setGeneralTerms] = useState('');
    // const [shipmentTerms, setShipmentTerms] = useState('');
    // const [addNotesTerms, setAddNotesTerms] = useState('');

    // const [isInvoiceIdValid, setIsInvoiceIdValid] = useState(false);
    // const [ispurposeCodeValid, setIsPurposeCodeValid] = useState(false);
    const [invoiceCheckbox, setInvoiceCheckbox] = useState(true);
    // const [purposeCodeArray, setPurposeCodeArray] = useState([])

    const [submitClicked, setSubmitClicked] = useState(false);
    const [draftClicked, setDraftClicked] = useState(false);
    const focusRef = useRef(null);
    const noneRef = useRef(null);
    const dropdownRef = useRef(null);
    const inputErrorScroll = useRef(null);
    const [changedIssueDate, setChangedIssueDate] = useState(false);
    const optRef = useClickOutside(() => {
        screenWidth > 600
        && dispatch(modalActions.showBankDropDown(false))
        // : dispatch(modalActions.showBankDropDown(true))
    });

    const handleCheckboxChange = () => {
        setInvoiceCheckbox((prevState) => !prevState)
    };
    const handleBankClick = () => dispatch(modalActions.showBankDropDown())
    const [screenWidth, setScreenWidth] = useState(getCurrentDimension());

    function getCurrentDimension() {
        return window.innerWidth
    }

    useEffect(() => {
        const updateDimension = () => {
            setScreenWidth(getCurrentDimension());
        };
        window.addEventListener("resize", updateDimension);
        return () => {
            window.removeEventListener("resize", updateDimension);
        };
    }, [screenWidth]);

    const handlePurposeCodeChange = (prop) => {
        setPurposeCode(prop?.purposeCode)
        setIsPurposeCodeValid(!(prop?.purposeCode === ''))
    }

    const handleDropdownClick = () => {
        if (dropdownRef.current) {
            dropdownRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
                inline: 'start',
            });
        }
    };





    return (
        <>
            {purposeCodeDropDown &&
                ReactDOM.createPortal(
                    <PurposeCodeDropdown
                        option={purposeCodeArray}
                        heading={"Select Purpose Code"}
                        onClick={handlePurposeCodeChange}
                        purposeCode={purposeCode}
                    />,
                    document.getElementById("modal-root")
            )}

            <div className={classes.otherDetailsCon}>
                <div className="flex flex-col mt-16 items-start gap-4">
                    <p className={classes.inRelText}>Invoice Details</p>
                    <div className={clsx(classes.rowGap, 'w-full   mt-[12px] grid-cols-2 gap-x-6 sm:grid')}
                         ref={!isInvoiceIdValid ? inputErrorScroll : noneRef}>
                        <div className={classes.invoiceID}>
                            <TextInput
                                type="text"
                                text="Invoice ID*"
                                className="col-span-2"
                                validation={[(value) => value !== '']}
                                errorMessage="Please enter a valid Invoice Id"
                                inputValidity={(value) => {
                                    setIsInvoiceIdValid(value)
                                }}
                                setInput={(value) => {
                                    setInvoiceId(value)
                                }}
                                enteredInput={invoiceId}
                                hasError={(submitClicked || draftClicked) && !isInvoiceIdValid}
                                initiateFocus={!isInvoiceIdValid}
                                focusRef={focusRef}
                                noneRef={noneRef}
                            />
                        </div>

                        < div
                            className="date-picker-wrapper relative"
                            ref={(isInvoiceIdValid && !ispurposeCodeValid) ? inputErrorScroll : noneRef
                            }>
                            <
                                CustomSelectorValuesCodes
                                onChange={handlePurposeCodeChange}
                                key={key}
                                selectedValue={purposeCode}
                                items={purposeCodeArray}
                                validation={[(value) => value !== '']}
                                placeholder="Purpose Code*"
                                searchText="Purpose Code*"
                                headingText="All Purpose Codes"
                                nothingFoundText="No Matching Purpose Code Found"
                                errorMessage="Please select a valid Purpose code"
                                // inputValidity={(value) => setBusinessTypeValid(!value)}
                                hasError={(submitClicked || draftClicked) && !purposeCode
                                }
                                handleDropdownClick={handleDropdownClick}
                                initiateFocus={isInvoiceIdValid && !ispurposeCodeValid}
                                focusRef={focusRef}
                                noneRef={noneRef}
                            />
                        </div>


                        <div className="date-picker-wrapper relative mt-[24px]"
                             ref={(isInvoiceIdValid && ispurposeCodeValid && !issueDate) ? inputErrorScroll : noneRef}>
                            {issueDate && <p className={classes.dateLabel}>Issue Date*</p>}
                            <DatePicker
                                status={(submitClicked || draftClicked) && !issueDate ? 'error' : undefined}
                                defaultValue={issueDate}
                                key={changedIssueDate}
                                onChange={(date, dateString) => {
                                    setIssueDate(date)
                                    setChangedIssueDate(true)
                                    if (
                                        date?.isSame(dueDate) ||
                                        date?.isAfter(dueDate) ||
                                        !date
                                    )
                                        setDueDate()
                                }}
                                className={clsx(
                                    ((submitClicked || draftClicked) && !issueDate) && `border-[2px]`,
                                    `w-full py-[1.2rem] px-[1.6rem] font-normal focus-within:border-[2px] hover:border-inherit focus-within:hover:border-[#1E333F] focus-within:border-[#1E333F] focus-within:shadow-none`
                                )}
                                placeholder="Issue Date*"
                                size="large"
                                format="DD/MM/YYYY"
                                disabledDate={(d) => d && d > dayjs().endOf("day")}
                                ref={(isInvoiceIdValid && ispurposeCodeValid && !issueDate) ? focusRef : noneRef}
                            />
                            {(submitClicked || draftClicked) && !issueDate && (
                                <div className={classes.error} style={{width: '100%'}}>
                                    Please enter valid issue date.
                                </div>
                            )}
                        </div>

                        <div className="date-picker-wrapper relative mt-[24px]">
                            <TextInput
                                type="text"
                                text="PO No. & Date"
                                className="col-span-2"
                                // multiline={true}
                                setInput={(value) => {
                                    setPoNumberDate(value)
                                }}
                                enteredInput={poNumberDate}
                                setLimit={true}
                            />
                        </div>

                        <div className="flex flex-row relative col-span-2 justify-between" style={{marginTop: "24px"}}>
                            <div className="flex gap-[8px] w-[100%] items-center">
                                <input
                                    className={classes.receivableCheckbox}
                                    type="checkbox"
                                    style={{cursor: "pointer"}}
                                    checked={invoiceCheckbox}
                                    onChange={() => handleCheckboxChange()}
                                />
                                <p className={classes.inRelText}>Check if Consignee & Buyer / Customer are same</p>
                                {
                                    window.innerWidth > 600
                                        ?
                                        <Tooltip
                                            placement="bottom"
                                            color="#060A0D"
                                            title={'If Consignee & Buyer are not same then fill Consignee details.'}
                                        >
                                            <img
                                                src={HelpCircle}
                                                alt="help-circle"
                                                className={classes.tooltipImage}
                                            />
                                        </Tooltip>
                                        :
                                        <img
                                            src={HelpCircle}
                                            alt="help-circle"
                                            className={classes.tooltipImage}
                                            onClick={() => dispatch(modalActions.invoiceTooltipDisplay(true))}
                                        />
                                }
                            </div>
                        </div>

                        {!invoiceCheckbox &&
                            <div className="flex flex-row relative col-span-2 mt-[24px]">
                                <TextInput
                                    type="text"
                                    text="Consignee Details"
                                    className="col-span-2"
                                    multiline={true}
                                    setInput={(value) => {
                                        setConsigneeBuyer(value)
                                    }}
                                    enteredInput={consigneeBuyer}
                                    setLimit={true}
                                />
                            </div>
                        }
                    </div>
                </div>

                <div className="flex flex-col items-start gap-4">
                    <p className={classes.inRelText}>Payment and Delivery Terms</p>

                    <div className={clsx(classes.rowGap, 'w-full   mt-2px grid-cols-2 gap-x-6 sm:grid')}
                         ref={!isInvoiceIdValid ? inputErrorScroll : noneRef}>
                        <div className='col-span-2 mt-[12px]'>
                            <TextInput
                                type="text"
                                text="Payment and Delivery Terms"
                                // className="col-span-2"
                                multiline={true}
                                setInput={(value) => {
                                    setPaymentTerms(value)
                                }}
                                enteredInput={paymentTerms}
                                setLimit={true}
                            />
                        </div>
                        {itemType === "HSN" &&
                            <div className=' mt-[24px]'>
                            <TextInput
                                type="text"
                                text="Country of Goods Origin"
                                // className="col-span-2"
                                // multiline={true}
                                setInput={(value) => {
                                    setCountryOriginGoods(value)
                                }}
                                enteredInput={countryOriginGoods}
                                setLimit={true}
                            />
                        </div>}

                        {itemType === "HSN" &&
                            <div className=' mt-[24px]'>
                            <TextInput
                                type="text"
                                text="Country of Final Destination"
                                // className="col-span-2"
                                // multiline={true}
                                setInput={(value) => {
                                    setCountryFinalDestination(value)
                                }}
                                enteredInput={countryFinalDestination}
                                setLimit={true}
                            />
                        </div>}

                        {itemType === "HSN" &&
                            <div className="date-picker-wrapper relative mt-[24px]">
                            {blDate && <p className={classes.dateLabel}>BL Date</p>}
                            <DatePicker
                                status={
                                    (submitClicked || draftClicked) && issueDate && !blDate
                                        // ? 'error'
                                        ? undefined
                                        : undefined
                                }
                                onChange={(date, dateString) => {
                                    setBLDate(date)
                                }}
                                defaultPickerValue={issueDate}
                                // disabled={!issueDate}
                                value={blDate}
                                className={clsx(
                                    // ((submitClicked || draftClicked) && issueDate && !dueDate) && `border-[2px]`,
                                    `w-full py-[1.2rem] px-[1.6rem] font-normal focus-within:border-[2px] hover:border-inherit focus-within:hover:border-[#1E333F] focus-within:border-[#1E333F] focus-within:shadow-none`
                                )}
                                placeholder="BL Date"
                                size="large"
                                format="DD/MM/YYYY"
                                disabledDate={(d) => !d || d.isBefore(issueDate)}
                            />
                        </div>}

                        <div className="date-picker-wrapper relative mt-[24px]">
                            {(dueDate || changedIssueDate) && <p className={classes.dateLabel}>Due Date</p>}
                            <DatePicker
                                status={
                                    (submitClicked || draftClicked) && issueDate && !dueDate
                                        // ? 'error'
                                        ? undefined
                                        : undefined
                                }
                                onChange={(date, dateString) => {
                                    setDueDate(date)
                                }}
                                defaultPickerValue={issueDate}
                                // disabled={!issueDate}
                                value={dueDate}
                                className={clsx(
                                    // ((submitClicked || draftClicked) && issueDate && !dueDate) && `border-[2px]`,
                                    `w-full py-[1.2rem] px-[1.6rem] font-normal focus-within:border-[2px] hover:border-inherit focus-within:hover:border-[#1E333F] focus-within:border-[#1E333F] focus-within:shadow-none`
                                )}
                                placeholder="Due Date"
                                size="large"
                                format="DD/MM/YYYY"
                                disabledDate={(d) => !d || d.isBefore(issueDate)}
                            />
                        </div>
                    </div>
                </div>

                <div className={classes.invoiceRelated}>
                    <div className="flex items-center gap-[8px]">
                        <p className={classes.inRelText}>General Terms & Conditions</p>
                        {/*<img src={moreIcon} width={"16px"} height={"16px"}/>*/}
                    </div>
                    <div className="mt-[12px]">
                        <TextInput
                            type="text"
                            text="General Terms"
                            className="col-span-2"
                            multiline={true}
                            setInput={(value) => {
                                setGeneralTerms(value)
                            }}
                            enteredInput={generalTerms}
                            setLimit={true}
                        />
                    </div>

                </div>

                {/*<div className={classes.invoiceRelated}>*/}
                {/*    <div className="flex items-center gap-[8px]">*/}
                {/*        <p className={classes.inRelText}>Inco/Shipment Term</p>*/}
                {/*        /!*<img src={moreIcon} width={"16px"} height={"16px"}/>*!/*/}
                {/*    </div>*/}

                {/*    <TextInput*/}
                {/*        type="text"*/}
                {/*        text="Inco/Shipment Term"*/}
                {/*        className="col-span-2"*/}
                {/*        multiline={true}*/}
                {/*        setInput={(value) => {*/}
                {/*            setShipmentTerms(value)*/}
                {/*        }}*/}
                {/*        enteredInput={shipmentTerms}*/}
                {/*        setLimit={true}*/}
                {/*    />*/}
                {/*</div>*/}

                {/*<div className={classes.invoiceRelated}>*/}
                {/*    <div className="flex items-center gap-[8px]">*/}
                {/*        <p className={classes.inRelText}>Add Notes</p>*/}
                {/*        /!*<img src={moreIcon} width={"16px"} height={"16px"}/>*!/*/}
                {/*    </div>*/}

                {/*    <TextInput*/}
                {/*        type="text"*/}
                {/*        text="Add Notes (optional)"*/}
                {/*        className="h-[]"*/}
                {/*        multiline={true}*/}
                {/*        setInput={(value) => {*/}
                {/*            setAddNotesTerms(value)*/}
                {/*        }}*/}
                {/*        enteredInput={addNotesTerms}*/}
                {/*        setLimit={true}*/}
                {/*    />*/}
                {/*</div>*/}

                <div className={classes.invoiceRelated}>
                    <div className="flex items-center gap-[8px]">
                        <p className={classes.inRelText}>Settlement Bank Account</p>
                        {/*<img src={moreIcon} width={"16px"} height={"16px"}/>*/}
                    </div>

                    {primaryBank && (
                        <div className="relative" ref={optRef}>
                            <div
                                className="w-full  bg-[#F7F8FB] flex flex-row items-center justify-between px-[20px] py-[16px] gap-8 rounded-[12px] mt-[12px]"
                                style={{cursor: allBanks?.length > 1 ? "pointer" : "default"}}
                                onClick={handleBankClick}>
                                <div className={classes.pbankWrap}>
                                    <img
                                        src={
                                            primaryBank?.countryFlag
                                                ? `Images/${primaryBank.country}.svg`
                                                : INRFlag
                                        }
                                        alt="country-flag"
                                        className="w-[48px] h-[48px] rounded-full"
                                    />

                                    <ContactInfo
                                        name={`${primaryBank?.bankName} Bank`}
                                        email={primaryBank?.accountNumber
                                            ?.slice(-4)
                                            .padStart(primaryBank?.accountNumber?.length, 'X')}
                                        className={classes.ctinfo}/>
                                </div>

                                <div className={classes.rightDown}>
                                    {<p className={classes.eefc}
                                        style={{backgroundColor: "#FFF"}}>{primaryBank.bankAccountType == "EEFC" ? "EEFC" : "INR"}</p>}
                                    {allBanks?.length > 1 && (
                                        <img
                                            src={showBankDropDown ? upArrow : arrowDownIcon}
                                            alt="arrow-down-icon"
                                            className="w-[24px] h-[24px] me-10px"
                                            style={{cursor: "pointer"}}
                                        />)}
                                </div>

                            </div>
                            {allBanks?.length > 1 && showBankDropDown && (
                                <div className={classes.webContent}>
                                    <div className={classes.options}>
                                        {allBanks?.map((opt, index) => (
                                            <div
                                                className={clsx(
                                                    classes.opt,
                                                    !opt.onClick &&
                                                    'cursor-pointer',
                                                    index && 'border-t border-[#ECEFF2]',
                                                )}
                                                key={`${opt.text}-${index}`}>
                                                <div key={index} className={classes.bankCard}
                                                     style={{backgroundColor: `${index % 2 == 0 ? "#FFF" : "#F7F8FB"}`}}
                                                     onClick={() => {
                                                         setPrimaryBank(opt)
                                                         dispatch(modalActions.showBankDropDown(false))
                                                     }}>
                                                    <div key={index} className={classes.bankContent}>
                                                        <img
                                                            src={
                                                                INRFlag
                                                            }
                                                            alt="country-flag"
                                                            className="w-[48px] h-[48px] rounded-full"
                                                        />
                                                        <ContactInfo
                                                            name={`${opt?.bankName} Bank`}
                                                            email={opt?.accountNumber
                                                                ?.slice(-4)
                                                                .padStart(opt?.accountNumber?.length, 'X')}
                                                        />
                                                    </div>

                                                    <div className={classes.rightCheck}>
                                                        <p className={classes.eefc}
                                                           style={{backgroundColor: `${index % 2 == 0 ? "#F7F8FB" : "#FFF"}`}}>{opt.bankAccountType == "EEFC" ? "EEFC" : "INR"}</p>
                                                        <div
                                                            className={classes.checkImgWrap}>{opt.accountId == primaryBank.accountId &&
                                                            <img src={checkIcon} alt="check-icon"
                                                                 className={classes.checkIcon}/>}</div>
                                                    </div>

                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                    <p className="text-[14px] leading-[20px] text-[#1E333F80] mb-[10px] ">{primaryBank?.bankAccountType==="EEFC"?"You will receive money in this bank account without conversion":"You will receive money in this bank account after conversion to INR"}</p>
                </div>
            </div>


        </>
    )
}


export default OtherDetails;

// <div className={classes.invoiceRelated}>
//     <p className={classes.inRelText}>Invoice Related</p>
//     <div className={classes.invCon}>
//         <div className={classes.invConTop}>
//             <TextInput
//                 type="text"
//                 text="Invoice ID*"
//                 className=""
//                 validation={[(value) => value !== '']}
//                 errorMessage="Please enter a valid Invoice Id"
//                 inputValidity={(value) => {
//                     setIsInvoiceIdValid(value)
//                 }}
//                 setInput={(value) => {
//                     setInvoiceId(value)
//                 }}
//                 enteredInput={invoiceId}
//                 hasError={(submitClicked || draftClicked) && !isInvoiceIdValid}
//                 initiateFocus={!isInvoiceIdValid}
//                 focusRef={focusRef}
//                 noneRef={noneRef}
//             />
//
//             <div className="date-picker-wrapper relative"
//                  ref={(isInvoiceIdValid && !ispurposeCodeValid) ? inputErrorScroll : noneRef}>
//                 <CustomSelectorValuesCodes
//                     onChange={handlePurposeCodeChange}
//                     key={key}
//                     classNameStyle={classes.widthStyleDate}
//                     selectedValue={purposeCode}
//                     items={purposeCodeArray}
//                     validation={[(value) => value !== '']}
//                     placeholder="Purpose Code*"
//                     searchText="Purpose Code*"
//                     headingText="All Purpose Codes"
//                     nothingFoundText="No Matching Purpose Code Found"
//                     errorMessage="Please select a valid Purpose code"
//                     // inputValidity={(value) => setBusinessTypeValid(!value)}
//                     hasError={(submitClicked || draftClicked) && !purposeCode}
//                     handleDropdownClick={handleDropdownClick}
//                     initiateFocus={isInvoiceIdValid && !ispurposeCodeValid}
//                     focusRef={focusRef}
//                     noneRef={noneRef}
//                 />
//             </div>
//         </div>
//         <div className={classes.invConTop}>
//             <div className="date-picker-wrapper relative"
//                  ref={(isInvoiceIdValid && ispurposeCodeValid && !issueDate) ? inputErrorScroll : noneRef}>
//                 {issueDate && <p className={classes.dateLabel}>Issue Date*</p>}
//                 <DatePicker
//                     status={(submitClicked || draftClicked) && !issueDate ? 'error' : undefined}
//                     defaultValue={issueDate}
//                     key={changedIssueDate}
//                     onChange={(date, dateString) => {
//                         setIssueDate(date)
//                         setChangedIssueDate(true)
//                         if (
//                             date?.isSame(dueDate) ||
//                             date?.isAfter(dueDate) ||
//                             !date
//                         )
//                             setDueDate()
//                     }}
//                     className={clsx(
//                         ((submitClicked || draftClicked) && !issueDate) && `border-[2px]`,
//                         `${classes.widthStyle} py-[1.2rem] px-[1.6rem] font-normal focus-within:border-[2px] hover:border-inherit focus-within:hover:border-[#1E333F] focus-within:border-[#1E333F] focus-within:shadow-none`
//                     )}
//                     placeholder="Issue Date*"
//                     size="large"
//                     format="DD/MM/YYYY"
//                     disabledDate={(d) => d && d > dayjs().endOf("day")}
//                     ref={(isInvoiceIdValid && ispurposeCodeValid && !issueDate) ? focusRef : noneRef}
//                 />
//                 {(submitClicked || draftClicked) && !issueDate && (
//                     <div className={classes.error} style={{width: '100%'}}>
//                         Please enter valid issue date.
//                     </div>
//                 )}
//             </div>
//
//             <div className="date-picker-wrapper relative">
//                 {blDate && <p className={classes.dateLabel}>BL Date</p>}
//                 <DatePicker
//                     status={
//                         (submitClicked || draftClicked) && issueDate && !blDate
//                             // ? 'error'
//                             ? undefined
//                             : undefined
//                     }
//                     onChange={(date, dateString) => {
//                         setBLDate(date)
//                     }}
//                     defaultPickerValue={issueDate}
//                     // disabled={!issueDate}
//                     value={blDate}
//                     className={clsx(
//                         // ((submitClicked || draftClicked) && issueDate && !dueDate) && `border-[2px]`,
//                         `${classes.widthStyle} py-[1.2rem] px-[1.6rem] font-normal focus-within:border-[2px] hover:border-inherit focus-within:hover:border-[#1E333F] focus-within:border-[#1E333F] focus-within:shadow-none`
//                     )}
//                     placeholder="BL Date"
//                     size="large"
//                     format="DD/MM/YYYY"
//                     disabledDate={(d) => !d || d.isBefore(issueDate)}
//                 />
//             </div>
//         </div>
//     </div>
// </div>
//
// <div className={classes.invoiceRelated}>
//     <div className="flex items-center gap-[8px]">
//         <p className={classes.inRelText}>Payment Term</p>
//         {/*<img src={moreIcon} width={"16px"} height={"16px"}/>*/}
//     </div>
//
//     <TextInput
//         type="text"
//         text="Payment Terms(optional)"
//         className="col-span-2"
//         multiline={true}
//         setInput={(value) => {
//             setPaymentTerms(value)
//         }}
//         enteredInput={paymentTerms}
//         setLimit={true}
//     />
// </div>
//
// <div className={classes.invConTop}>
//     <div className="date-picker-wrapper relative">
//         {(dueDate || changedIssueDate) && <p className={classes.dateLabel}>Due Date</p>}
//         <DatePicker
//             status={
//                 (submitClicked || draftClicked) && issueDate && !dueDate
//                     // ? 'error'
//                     ? undefined
//                     : undefined
//             }
//             onChange={(date, dateString) => {
//                 setDueDate(date)
//             }}
//             defaultPickerValue={issueDate}
//             // disabled={!issueDate}
//             value={dueDate}
//             className={clsx(
//                 // ((submitClicked || draftClicked) && issueDate && !dueDate) && `border-[2px]`,
//                 `${classes.widthStyle} py-[1.2rem] px-[1.6rem] font-normal focus-within:border-[2px] hover:border-inherit focus-within:hover:border-[#1E333F] focus-within:border-[#1E333F] focus-within:shadow-none`
//             )}
//             placeholder="Due Date"
//             size="large"
//             format="DD/MM/YYYY"
//             disabledDate={(d) => !d || d.isBefore(issueDate)}
//         />
//         {/* {(submitClicked || draftClicked) && issueDate && !dueDate && (
//                             <div className={classes.error} style={{ width: '100%' }}>
//                               Please enter valid due date.
//                             </div>
//                           )} */}
//     </div>
// </div>
//
// <div className={classes.invoiceRelated}>
//     <div className="flex items-center gap-[8px]">
//         <p className={classes.inRelText}>General Terms & Conditions</p>
//         {/*<img src={moreIcon} width={"16px"} height={"16px"}/>*/}
//     </div>
//
//     <TextInput
//         type="text"
//         text="General Term (optional)"
//         className="col-span-2"
//         multiline={true}
//         setInput={(value) => {
//             setGeneralTerms(value)
//         }}
//         enteredInput={generalTerms}
//         setLimit={true}
//     />
// </div>
//
// <div className={classes.invoiceRelated}>
//     <div className="flex items-center gap-[8px]">
//         <p className={classes.inRelText}>Inco/Shipment Term</p>
//         {/*<img src={moreIcon} width={"16px"} height={"16px"}/>*/}
//     </div>
//
//     <TextInput
//         type="text"
//         text="Inco/Shipment Term"
//         className="col-span-2"
//         multiline={true}
//         setInput={(value) => {
//             setShipmentTerms(value)
//         }}
//         enteredInput={shipmentTerms}
//         setLimit={true}
//     />
// </div>
//
// <div className={classes.invoiceRelated}>
//     <div className="flex items-center gap-[8px]">
//         <p className={classes.inRelText}>Add Notes</p>
//         {/*<img src={moreIcon} width={"16px"} height={"16px"}/>*/}
//     </div>
//
//     <TextInput
//         type="text"
//         text="Add Notes (optional)"
//         className="col-span-2"
//         multiline={true}
//         setInput={(value) => {
//             setAddNotesTerms(value)
//         }}
//         enteredInput={addNotesTerms}
//         setLimit={true}
//     />
// </div>