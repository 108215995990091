import PasswordInput from 'Components/Authentication/PasswordInput';
import Button from 'Layout/Button';
import TextInput from 'Layout/TextInput';
import classes from 'Pages/Authentication/PasswordSet.module.scss';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import {AuthActions, ForgotPasswordActions, PasswordSetActions} from 'Store/auth-redux';
import {Form, useActionData, useNavigate, useSearchParams, useSubmit} from 'react-router-dom';
import {useEffect, useState} from 'react';
import { baseUrl } from 'constants/Network';
import UseApiCall from 'hooks/useApiCall';
import { apiGetUserEmailFromResetToken, apiSetPassword, apiSignOut } from 'services';

const BASE_URL = baseUrl.api;
const passwordValidation = [
    value => value.length >= 8 && value.length <= 30,
    value => /[a-z]/.test(value),
    value => /[A-Z]/.test(value),
    value => /[0-9]/.test(value),
]

const emailValidation = [
    (value) => value.trim() !== "",
    (value) => /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value)
  ];

const PasswordSet = (props) => {

    const [hasSetPassword, setHasSetPassword] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    // const [isWrongInput, setIsWrongInput] = useState(false);
    const navigate = useNavigate();
    const [searchParam, setSearchParam] = useSearchParams();
    const token = searchParam.get("token");

    const [email, setEmail] = useState(props.email || '')
    const [passwordInput, setPasswordInput] = useState('')
    const [confirmPasswordInput, setConfirmPasswordInput] = useState('')
    const [isEmailValid, setIsEmailValid] = useState(false);
    const [isPasswordValid, setIsPasswordValid] = useState(false)
    const [isConfirmPasswordValid, setIsConfirmPasswordValid] = useState(false)

    const [fetchEmail, fetchingEmail] = UseApiCall(apiGetUserEmailFromResetToken, (res) => {
        setEmail(res?.data)
    })
    const [setPassword, setting] = UseApiCall(apiSetPassword, () => {
        setHasSetPassword(true)
    })

    useEffect(() => {
        fetchEmail({token})
    }, [])

    let passwordMatch = confirmPasswordInput === passwordInput;
    let formValid = isEmailValid && isPasswordValid && isConfirmPasswordValid && passwordMatch;


    const formSubmitHandler = () => {

        if (!formValid) {
            return;
        }
        setPassword(
            {
                email: email,
                password: passwordInput,
                confirmPassword: confirmPasswordInput,
                passwordResetToken:token,
            }
        )

    }

    const navigatetoSignInHandler = () => {
        navigate('/');
    }

    return (
        <>
            <div className={classes.password_set}>
                {!hasSetPassword &&
                    <>
                        <div className={classes.header}>{props.text} your Password</div>
                        <Form>
                            <TextInput
                                type='text'
                                text='Email Address'
                                className={classes.input}
                                errorMessage="Please enter a valid email address"
                                validation={emailValidation}
                                inputValidity={(value) => {
                                    setIsEmailValid(value);
                                  }}
                                setInput={(value) => {
                                    setEmail(value)
                                }}
                                disabled={false}
                                enteredInput={email}
                                // hasError = {isWrongInput && !isEmailValid}
                                maxLength={128}
                            />
                            <PasswordInput
                                className={classes.password}
                                text='Enter Password'
                                inputValidity={value =>setIsPasswordValid(value)}
                                setInput={value => setPasswordInput(value)}
                                errorMessage='Please enter a valid password'
                                isValid={isPasswordValid}
                                enteredInput={passwordInput}
                                validation={passwordValidation}
                                maxLength={30}
                                // hasError = {isWrongInput && !isPasswordValid}
                            />
                            <PasswordInput
                                className={classes.password}
                                text='Confirm Password'
                                inputValidity={value => setIsConfirmPasswordValid(value)}
                                setInput={value => setConfirmPasswordInput(value)}
                                errorMessage={`Password doesn't match`}
                                isValid={true}
                                enteredInput={confirmPasswordInput}
                                validation={[value => passwordInput === value]}
                                maxLength={30}
                                // hasError = {isConfirmPasswordValid && !passwordMatch}
                            />
                            <Button
                                text={!isLoading ? `${props.text} Password` : `${props.text}ting Password...`}
                                disabled={(isLoading || !formValid)}
                                onClick={formSubmitHandler}
                                className={`${classes.btn} ${(isLoading || !formValid) && classes.disabled} ${formValid && !isLoading && classes.abled}`}
                            />
                        </Form>

                    </>}
                {hasSetPassword &&
                    <>
                        <div className={classes.header}>Password {props.text}</div>
                        <div className={classes.text}>Your password has been {props.text.toLowerCase()} successfully.
                            Please login to your Fideo business account with your new password.
                        </div>
                        <Button
                            text='Go to Sign in'
                            className={`${classes.btn} ${classes.abled}`}
                            onClick={navigatetoSignInHandler}
                        />
                    </>
                }
            </div>
        </>

    )
}

export async function action({request, params}) {
    const data = await request.formData();

    const setPassword = {
        email: data.get("email"),
        password: data.get("password"),
        confirmPassword: data.get("confirmPassword"),
        passwordResetToken: data.get("token"),
    };

    const response = await fetch(BASE_URL + `/set-password`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify(setPassword),
    })


    if (response.status !== 200 || !response.ok) {
        return response;
    }
}

export default PasswordSet;

export const validateToken = async ({token, email}) => {

    const dummyResponse = {
        "data": email,
        "error_response": null
    }

    return await fetch(BASE_URL + `/verify-password-reset-token?token=${token}`).then((res) => {
        if (!res.ok || res.status / 100 !== 2) {
            return dummyResponse
        }
        return res.json()
    })

}