import React from 'react';
import classes from "./Profile.module.scss";
import Header from './profileComponents/Header';
import ContentWrapper from './profileComponents/ContentWrapper';
import ContentBox from './profileComponents/ContentBox';
import Content from './profileComponents/Content';
import ContentContainer from './profileComponents/ContentContainer';
import editBlackIcon from "assets/Icons/editBlack.svg"
import { useSnackbar } from 'notistack';

const BasicDetails = ({basicDetails,error}) => {
    const { enqueueSnackbar } = useSnackbar()
    if(error){
        enqueueSnackbar('Unable to fetch data', { variant: 'error' })
      }
    const {first_name, last_name, email_id, mobile_number} = basicDetails;
  return (
    <div className={`${classes.basicDetails}`}>
        <ContentContainer>
            <Header text1={'Basic Details'} text2={''} />
            <ContentWrapper>
                <ContentBox>
                    <Content head={'First Name'} para={first_name} />
                    <Content head={'Last Name'} para={last_name} />
                </ContentBox>
                <ContentBox>
                    <Content head={'Email ID'} para={email_id} />
                    <Content head={'Phone Number'} para={mobile_number} />
                </ContentBox>
                {/* <div className={`${classes.ProfileEditBox}`}>
                    <div className={`${classes.editBox}`}>
                        <img src={editBlackIcon} alt='edit-icon'  className={`${classes.editBoxImg}`}/>
                        <span className={`${classes.editBoxText}`}>Edit Profile</span>
                    </div>
                </div> */}
            </ContentWrapper>
        </ContentContainer>
    </div>
  )
}

export default BasicDetails