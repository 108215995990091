import React, { useState } from 'react';
import arrowUpIcon from 'assets/Icons/arrow-up.svg'
import arrowDownIcon from 'assets/Icons/arrow-DownIcon.svg'
import classes from './FxRateTable.module.scss'
import clsx from 'clsx'
import dayjs from 'dayjs'
import { ConversionValue } from './FXRateTable';
import { getDatefromUnix } from 'utils/utils';


const TableLayout = ({currentDateEntries,previousDateEntries,groupedData,date,markup, openDate, setOpenDate}) => {
  const [isCollapsed, setIsCollapsed] = useState(true);
//   const [openDate, setOpenDate] = useState(date);

  const handleToggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const handleToggleDateCollapse = (date) => {
    setOpenDate(date === openDate ? '' : date);
  };

  return (
    <>
      <div className={classes.ratesBox}>
        <div className={classes.ratesBoxTop}>
          <div className={classes.dateBox}>
            <span className={classes.dateText}>
              <span className={classes.dateTextDay}>
              {date.split(' ')[2]}
              </span>
              {date.split(' ')[1]}{"'"}{date.split(' ')[3]}   
            </span>
          </div>
        </div>
        <div className={classes.ratesBoxBottom} onClick={() => handleToggleDateCollapse(date)}>
          <div className={classes.ratesBoxBottomLeft} onClick={() => handleToggleDateCollapse(date)}>
            <img src={openDate == date ? arrowUpIcon : arrowDownIcon} alt='arrow-up' 
                    className={classes.ratesBoxBottomLeftImg} 
                    />
          </div>
          <div className={classes.ratesBoxBottomRight}>
                {groupedData[date].map((entry, index) => {
                    const isFirstEntry = index === 0;
                    const isLastEntry = index === currentDateEntries?.length - 1;
                    const isLastEntryOfPreviousDate = isLastEntry && previousDateEntries?.length > 0;

                  return (openDate != date ? index < 1 : true) && 
                  (<div className={clsx(classes.ratesBoxBottomRow, openDate != date ? index < 1 : index < groupedData[date].length - 1  && classes.ratesBoxBottomRowBorder)} key={index}>
                      <div className={classes.ratesBoxBottomRowContent}>
                        <div className={classes.boxCellTime} >
                            <p className={clsx(classes.timeBox)}>
                                {dayjs(getDatefromUnix(entry?.timestamp))
                                    .local()
                                    .format('hh:mm a')}{' '}
                                IST
                            </p>
                        </div> 
                            <div className={classes.boxCell} key={index}>
                            <ConversionValue
                                conversionData={entry?.currencyFxRates[0]}
                                previousDateEntry = {isLastEntryOfPreviousDate ? previousDateEntries?.[0]?.currencyFxRates?.[0] : []}
                                previousEntry = {groupedData[date][index + 1]?.currencyFxRates[0]}
                                isLastEntry = {isLastEntry}
                                markup={markup}
                            />
                            </div> 
                            <div className={classes.boxCell} key={index}>
                            <ConversionValue
                                conversionData={entry?.currencyFxRates[1]}
                                previousDateEntry = {isLastEntryOfPreviousDate ? previousDateEntries?.[0]?.currencyFxRates?.[1] : []}
                                previousEntry = {groupedData[date][index + 1]?.currencyFxRates[1]}
                                isLastEntry = {isLastEntry}
                                markup={markup}
                            />
                            </div> 
                            <div className={classes.boxCell} key={index}>
                            <ConversionValue
                                conversionData={entry?.currencyFxRates[2]}
                                previousDateEntry = {isLastEntryOfPreviousDate ? previousDateEntries?.[0]?.currencyFxRates?.[2] : []}
                                previousEntry = {groupedData[date][index + 1]?.currencyFxRates[2]}
                                isLastEntry = {isLastEntry}
                                markup={markup}
                            />
                            </div> 
                    </div>
                  </div>)
                })} 
          </div>
        </div>
      </div>
    </>
  )
}

export default TableLayout