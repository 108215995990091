import Button from "Layout/Button";
import MobileInput from "Layout/MobileInput";
import TextInput from "Layout/TextInput";
import { useState } from "react";
import classes from "./SignUp.module.scss";
import { Form, Link } from "react-router-dom";
import BackIcon from "assets/Icons/arrow-left.svg";
import clsx from "clsx";
import CustomSelector from "Components/atom/CustomSelector/CustomSelector";
import { COUNTRIES } from "lib/data/countries";

const Form2 = (props) => {
  const { formData, setFormData, formSubmitHandler, setPage,setIsForm2Complete } = props;

  const [businessName, setBusinessName] = useState(formData?.businessName);
  const [businessCountry, setBusinessCountry] = useState(
    formData?.businessCountry
  );
  const [isBusinessNameValid, setIsBusinessNameValid] = useState(false);
  const [isBusinessCountryValid, setIsBusinessCountryValid] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    updateFormData();
    if (formSubmitHandler){
      setIsForm2Complete(true);
      formSubmitHandler({
        businessName,
        businessCountry: businessCountry?.value,
      });}
  };

  const handleCountryChange = (prop) => {
    setBusinessCountry(prop);
  };
  const updateFormData = () => {
    if (setFormData)
      setFormData((pre) => {
        return { ...pre, businessName, businessCountry };
      });
  };

  const handleGoBack = () => {
    setPage(0);
    updateFormData();
  };

  const isValid = () => {
    if (!isBusinessNameValid || !isBusinessCountryValid) return false;
    return true;
  };

  return (
    <>
      <div className="flex flex-col mb-16">
        <div
          onClick={handleGoBack}
          className="flex flex-row items-center cursor-pointer mb-16"
        >
          <img
            src={BackIcon}
            className="mr-3 "
            alt="back"
            width="24px"
            height="24px"
          />
          <p className="text-2xl text-[ #181C30]">Back</p>
        </div>
        <div className={classes.header}>
          Hey {formData?.firstName || ""}, <br className="sm:hidden" />let’s get started
        </div>
      </div>
      <Form className={classes.form}>
        <TextInput
          type="text"
          text="Your Business Name"
          className={classes.input}
          errorMessage="Please enter a valid Business name"
          validation={[(value) => value !== ""]}
          inputValidity={(value) => {
            setIsBusinessNameValid(value);
          }}
          setInput={(value) => {
            setBusinessName(value);
          }}
          enteredInput={businessName}
          hasError={false}
          maxLength={128}
        />
        <div className="w-full mb-12">
          <CustomSelector
            onChange={handleCountryChange}
            selectedValue={businessCountry}
            items={COUNTRIES}
            validation={[(value) => !!value]}
            inputValidity={(value) => {
              setIsBusinessCountryValid(value);
            }}
            placeholder='Country of Registration'
            errorMessage="Please enter a valid Business Country"
          />
        </div>
        <Button
          text="Continue"
          disabled={!isValid()}
          className={clsx(classes.btn, !isValid() && classes.disabled)}
          onClick={handleSubmit}
        />
      </Form>
    </>
  );
};

export default Form2;
