const URL = {
  refreshToken: 'refresh-token',
  receivables: '/invoices',
  receivablesChatbot: '/invoices/chatBot',
  invoiceCreate: '/invoices/create-invoice',
  invoicePreview: '/invoices/invoice-preview',
  receivableCustomers: '/invoices/filter/customers',
  receivableStatus: '/invoices/filter/statuses',
  forexRate: '/invoices/forex-rate',
  transactions: '/transactions',
  summary: '/invoices/summary',
  newSummary: '/invoices/new-summary',
  dashboardInvoice: 'dashboard/invoicedetails',
  customerReport: '/customers-report',
  virtualAccounts: '/virtual-accounts',
  virtualAccountsDetails: '/virtual-accounts/details',
  VBankDeposits: '/deposit',
  virtualAccountsGetBalance: '/virtual-accounts/getBalance',
  bankAccounts: '/bank-accounts',
  bankAccountsDetails: '/bank-accounts',
  customers: '/customers',
  login: '/login',
  setPassword: '/set-password',
  register: '/users/register',
  profile: '/users/profile',
  resetPassword: '/reset-password',
  signOut: '/sign-out',
  verifyPasswordResetToken: '/verify-password-reset-token',
  sendPaymentLink: '/invoices/send-payment-link-email',
  verifyPaymentLinkToken: '/verify-payment-link-token',
  verifyNewPaymentLinkToken: '/verify-new-payment-link-token',
  sendPaymentLinkOtp: '/send-payment-link-otp',
  sendInvoiceLinkOtp: '/invoices/send-invoice-otp',
  transactionPresignedUrls: '/media/transaction-presigned-urls',
  invoicesPresignedUrlsWithOtp: 'invoices/invoice-details-otp',
  firaReports: '/fira-report',
  payoutReports: '/reports/payout',
  downloadFiraReport: '/reports/fira/url',
  downloadFiraReportWithTxnId: '/reports/fira/txn',
  downloadFiraReportWithFiraId: '/reports/fira/id',
  invoiceDetails: '/invoices/invoice-details',
  customerDetails: '/customers/info',
  complianceDetails: 'compliance/customer',
  searchComp: '/compliance/search-data',
  customerCurrencies: '/customers/currencies',
  fxRates: '/fx/historical-fx-rates',
  customerInvoices: '/customers/invoices',
  businessType: '/upload-data/businessTypes',
  uploadMediaDocument: 'documents/upload',
  deleteMediaDocument: 'documents/delete',
  submitLeadForm: 'upload-data/submitLead',
  downloadVban: '/download/vban-details',
  markSettledOutside: 'invoices/status-update',
  generateSinglePaymentLink: 'generate-single-payment-link',
  verifySinglePaymentLinkSummary: 'verify-single-payment-link-summary',
  verifySinglePaymentLinkInvoices: 'verify-single-payment-link-invoices',
  bulkTransactionsData: '/bulk/transactions/getData',
  bulkTransactionsId: '/transactions',
  bulkTransactionSubmit: '/bulk/transactions/submit',
  sendSinglePaymentLink: '/invoices/send-single-payment-link-email',
  reconcilationTable: '/reconcile',
  reconcilationTableCustomers: '/reconcile/customers',
  sendReconcilationData: '/reconcileReceivables',
  purposeCode: '/invoices/purpose-code',
  complianceList : 'compliance/report',
  agingAccountJson: '/download/aging-account/json',
  agingAccount: '/download/aging-account',
  addUsers: '/users/create',
  termsAndConditions:'download/terms-and-conditions',
  downloadFiraReportWithInvoiceId: '/reports/fira/invoice',
  showChatBotModal: '/config',
  deleteInvoice: '/invoices/delete',
  downloadFirc: '/download/firc',
  firaFromLastTransactionChatbot: '/reports/fira/last-transaction',
  firaFromCustomerNameChatbot: '/reports/fira/customer',
  generalFiraDownloadChatbot: '/reports/fira/download',
  deactivateChatbot: '/chatBot/deactivate',
  messageChatbot: '/chatBot/message',
  conversationChatbot: '/conversations/history',
}

const baseUrl = {
  api:
    process.env.REACT_APP_NODE_ENV === "production"
      ? "https://app.fideo.tech/api"
      : (process.env.REACT_APP_NODE_ENV === "qa" ? "https://qa.fideo.tech/api" : "https://dev.fideo.tech/api"),
      // : 'http://localhost:8081/api',
  media:
    process.env.REACT_APP_NODE_ENV === "production"
      ? "https://app.fideo.tech/api"
      : (process.env.REACT_APP_NODE_ENV === "qa" ? "https://qa.fideo.tech/api" : "https://dev.fideo.tech/api"),
      // : 'http://localhost:8081/api',
};

const COMMON_HEADERS = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

/**
 * These config options can be passed to the api options
 * errMsg: Error message to be shown in case of error
 * hasFormData - to convert api data into form data
 * fullResponse - to get the actual axios api response (only data object in the apu response is returned)
 * hideDefaultError - to hide the default error message (use in case of custom conditional error message)
 * onProgressCallback - to get the progress of the upload (in put and post requests)
 */
const DEFAULT_INTERCEPTOR_CONFIG = {
  errMsg: "",
  hasFormData: false,
  fullResponse: false,
  hideDefaultError: false,
  onProgressCallback: null,
};

export { COMMON_HEADERS, DEFAULT_INTERCEPTOR_CONFIG, baseUrl };
export default URL;
