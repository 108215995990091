// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UserDetails_backdrop__oNN5C {
  height: 100vh;
  z-index: 50 !important;
}`, "",{"version":3,"sources":["webpack://./src/Pages/Admin/profile/profileComponents/UserDetails.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;AACJ","sourcesContent":[".backdrop{\n    height: 100vh;\n    z-index: 50 !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"backdrop": `UserDetails_backdrop__oNN5C`
};
export default ___CSS_LOADER_EXPORT___;
