import clsx from "clsx";
import classes from "./CreateInvoice.module.scss";
import ReactDOM from "react-dom";
import Backdrop from "../../Layout/Backdrop";
import {modalActions} from "../../Store/modal-redux";
import checkIcon from "../../assets/Icons/Frame 130.svg";
import lockIcon from "../../assets/Icons/lockClose.svg";
import xIcon from "../../assets/Icons/xIcon.svg";
import BgIcon from "../../assets/Icons/bg-file-uploader.png";
import {Document, Page, pdfjs} from "react-pdf";
import FideoLogo from "../../assets/Icons/fideo-logo-faded.svg";
import backBtn from "../../assets/Icons/backBtn.svg";
import Button from "../../Layout/Button";
import Modal from "../../Layout/Modal";
import React, {useEffect, useRef, useState} from "react";
import ScreenWidth from "../atom/ScreenWidth";
import {useDispatch, useSelector} from "react-redux";
import useClickOutside from "../../hooks/useClickOutside";
import temInvoice from "../../assets/Icons/edit-this 7.svg";

const MobilePreviewInvoice = (props) => {
    let screenWidth = ScreenWidth();
    const dispatch = useDispatch();
    const previewRef = useClickOutside(() =>
        dispatch(modalActions.setShowPreviewDisplay(false))
    );

    const showFileDisplay = useSelector((state) => state.modal.showFileDisplay)


    const [selectedTemp, setSelectedTemp] = useState('Template 1');
    const [pdfLoaded, setPdfLoaded] = useState(false);
    const [zoomOpen, setZoomOpen] = useState(false);
    const [numPages, setNumPages] = useState(false);
    const [pdfUrl, setPdfUrl] = useState(
        {
            type: '',
            url: ''
        }
    );

    const iframeRef = useRef(null);

    useEffect(() => {
        if (props.previewValue) {
            setPdfUrl(
                {
                    type: 'pdf',
                    url: props.previewValue
                }
            );
            setPdfLoaded(true);
        }
    }, [props.previewValue]);

    console.log('previewValue',props?.previewValue);

    useEffect(() => {
        pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`
    }, [])

    const openZoomModal = () => {
        setZoomOpen(true)
        dispatch(modalActions.showFileDisplay())
    };

    const tempArr = [
        {
            imgTemp: temInvoice,
            text: 'Template 1',
            isActive: true,
        },
        {
            imgTemp: temInvoice,
            text: 'Template 2',
            isActive: false,
        },
        {
            imgTemp: temInvoice,
            text: 'Template 3',
            isActive: false,
        },
        {
            imgTemp: temInvoice,
            text: 'Template 4',
            isActive: false,
        },
        {
            imgTemp: temInvoice,
            text: 'Template 5',
            isActive: false,
        },
    ]

    const handleTemplate = (text) => {
        console.log('text',text)
        setSelectedTemp(text)
    }

    // console.log('showFileDisplay',showFileDisplay)
    console.log('screenWidth',screenWidth)

    return (
        <Modal
            className={clsx(
                classes.modal,
                'flex justify-end h-screen max-w-[100%] p-0  bg-[transparent] z-[50] '
            )}
        >
            {ReactDOM.createPortal(
                <Backdrop
                    onClick={(event) => {
                        event.stopPropagation()
                        dispatch(modalActions.setShowPreviewDisplay(false))
                    }}
                    className={classes.backdropPreview}
                />,
                document.getElementById('backdrop-root')
            )}

            {/*{showFileDisplay && pdfUrl.url &&*/}
            {/*    ReactDOM.createPortal(*/}
            {/*        <ZoomButtonsModal*/}
            {/*            fileUrl={pdfUrl}*/}
            {/*        />*/}
            {/*        ,document.getElementById("modal-root")*/}
            {/*    )*/}
            {/*}*/}

            <div className="flex flex-col-reverse w-[100%] " >
                {/*left*/}
                <div className="flex flex-col max-w-[100%] bg-[#F4F6FA] ">
                    {/*top*/}
                    <div className={classes.temLeftTop}>
                        <p className={classes.temText1}>Choose a Template</p>
                    </div>

                    {/*bottom*/}
                    <div className={`flex  h-[240px] p-[12px] overflow-x-scroll`}>
                        <div className="flex  gap-[16px]">
                            {tempArr.map((item, index) => (
                                <div className="flex flex-col max-w-[70px] gap-[8px] rounded-[8px]" key={index}
                                     onClick={(event) => {
                                         if (item.isActive)
                                             handleTemplate(item.text)
                                     }}
                                >
                                    <div className="flex flex-col  gap-[8px] relative"
                                         style={{
                                             border: item.text === selectedTemp ? "1px solid #1E333F" : "",
                                             borderRadius: item.text === selectedTemp ? "4px" : "",
                                             cursor: item.isActive ? "pointer" : "",
                                         }}
                                    >
                                        <img src={item.imgTemp} className="max-w-[70px] max-h-[100px]"/>
                                        {
                                            item.text === selectedTemp &&
                                            <img src={checkIcon}
                                                 className="max-w-[14px] max-h-[14px] absolute top-3 right-3"/>
                                        }
                                        {!item.isActive &&
                                            (<div
                                                className="flex flex-col items-center justify-center w-[70px] h-[100px] gap-[8px]
                                                                rounded-[8px] bg-[#FFFFFF80] absolute top-0 left-0">
                                                <div
                                                    className="w-[28px] h-[28px] rounded-[100px] p-[4px] bg-[#E7E9EE]">
                                                    <img src={lockIcon}/>
                                                </div>
                                                <p className={classes.comingText}>Coming soon</p>
                                            </div>)
                                        }
                                    </div>
                                    <p className={classes.temText2}>{item.text}</p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                {/*right*/}
                <div className="flex flex-col w-[100%] mt-[50px] overflow-y-scroll bg-[#FFFFFF]">
                    {/*top*/}
                    <div className="w-[100%]  p-[16px 30px] gap-[24px] bg-white shadow-left relative">
                        {/*x button*/}
                        <div
                            className="w-[28px] h-[28px] p-[4px] rounded-bl-[8px] bg-[#DDE0E6] absolute top-0 right-0 cursor-pointer z-[200]"
                            onClick={(event) => {
                                event.stopPropagation()
                                dispatch(modalActions.setShowPreviewDisplay(false))
                            }}
                        >
                            <img src={xIcon}/>
                        </div>

                        {/*show pdf here*/}
                        <div className="w-[100%] h-[60vh]">
                            {
                                <div
                                    className="flex items-center justify-center w-[100%] h-[100%]  mb-[30px] px-[30px] py-[15px] ">
                                    <div
                                        className={clsx(classes.dropContainer,
                                            'backdrop-blur-lg')}
                                        style={{
                                            backgroundImage: `url(${BgIcon})`,
                                            backgroundRepeat: 'repeat',
                                        }}
                                    >
                                        {(
                                            <>
                                                {
                                                    <Document
                                                        file={pdfUrl.url}
                                                        className={clsx('w-full h-full', classes.documentFullMweb)}
                                                        // style={{ cursor: "pointer" }}
                                                        // onLoadSuccess={handlePdfLoad}
                                                        onLoadSuccess={({ numPages }) => {
                                                            setNumPages(numPages); // Store the total number of pages in state
                                                        }}
                                                        loading={""}
                                                    >
                                                        {Array.from(new Array(numPages), (el, index) => (
                                                            <Page
                                                                key={`page_${index + 1}`}
                                                                pageNumber={index + 1}
                                                                className={clsx('w-full h-full', classes.pdfViewerSmallMweb)}
                                                                // onClick={() => {
                                                                //     openZoomModal()
                                                                // }}
                                                                renderTextLayer={false}
                                                                loading=""
                                                                renderAnnotationLayer={false}
                                                            />
                                                        ))}
                                                    </Document>
                                                }
                                                <div
                                                    className="absolute z-1 bottom-3 flex flex-row items-center gap-2">
                                                    <p className="text-2xl font-medium text-[#1E333F4D]">
                                                        Powered by
                                                    </p>
                                                    <img src={FideoLogo} className="w-18 h-6" alt="Fideo"/>
                                                </div>
                                            </>
                                        )}
                                    </div>
                                </div>
                            }
                        </div>
                    </div>

                    {/*bottom*/}
                    {/*<div className={classes.previewRightBottom}>*/}
                    {/*    <div className={classes.backBtn}>*/}
                    {/*        /!*Back button*!/*/}
                    {/*        <div className={classes.draftCon}>*/}
                    {/*            <div className="flex gap-[10px]"*/}
                    {/*                 onClick={() => {*/}
                    {/*                     dispatch(modalActions.setShowPreviewDisplay(false))*/}
                    {/*                 }}*/}
                    {/*            >*/}
                    {/*                <img src={backBtn}/>*/}
                    {/*                <p className={classes.draftText}>Back</p>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}

                    {/*    <div className={classes.createPreviewCon}>*/}
                    {/*        <div className={classes.draftCon}>*/}
                    {/*            <div className={classes.draft}>*/}
                    {/*                <p className={classes.draftText}>Save as Draft</p>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}

                    {/*        <Button*/}
                    {/*            className={clsx(*/}
                    {/*                'flex-1 outline-none ml-[6px]',*/}
                    {/*                (props.creatingInvoice || !props.isValidInvoice()) && 'text-[#ffffff80]'*/}
                    {/*            )}*/}
                    {/*            disabled={props.creatingInvoice || !props.isValidInvoice()}*/}
                    {/*            text="Create Invoice"*/}
                    {/*            onClick={props.handleSubmit}*/}
                    {/*        />*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
            </div>
        </Modal>
    )
}

export default MobilePreviewInvoice;