// import CryptoJS from 'crypto-js';
// import { secretKey } from "constants";
// import { useEffect, useState } from 'react';

// const TourArrayGenerator = () => {
//     const decryptValue = (encryptedValue) => {
//         const bytes = CryptoJS.AES.decrypt(encryptedValue || '', secretKey);
//         return bytes.toString(CryptoJS.enc.Utf8);
//     };
//     const [tourArray, setTourArray] = useState(null);

//     // let storedUserRole = decryptValue(localStorage.getItem('nickName') || '');

//     useEffect(() => {
//         const storedUserRole = decryptValue(localStorage.getItem('nickName') || '');
//         setTourArray(
//             storedUserRole === 'malik'
//                 ?
//                 [
//                     {
//                         id: "step1",
//                         placement: "left",
//                         color: "blue",
//                         title: "Hello",
//                         heading: "Vbank details",
//                         body1: "Download and share bank account details with your customers.",
//                         body2: "Make sure you also update the bank account information in the invoice copy to avoid any confusion with your customer",
//                         body1Colour: "#FFFFFFB2",
//                         body2Colour: "",
//                         step: 1,
//                         defaultOpen: true,
//                         open: true,
//                         pageUrl: '/customers',
//                         isActive: false,
//                         previousUrl: '/bank-accounts',
//                         furtherPreviousUrl: false,
//                         finalStep: false,
//                         firstStep: true,
//                         styles:{
//                             position:"relative",
//                             right:"150px"
//                         }
//                     },
//                     {
//                         id: "step2",
//                         placement: "bottomRight",
//                         color: "blue",
//                         title: "Hello",
//                         heading: "Add Customer and Upload Invoices",
//                         body1: "Click here to add customer",
//                         body2: "Upload invoices against the customer",
//                         body1Colour: "#FFFFFFB2",
//                         body2Colour: "",
//                         step: 2,
//                         defaultOpen: true,
//                         open: true,
//                         pageUrl: '/receivables',
//                         isActive: false,
//                         previousUrl: '/customers',
//                         furtherPreviousUrl: '/bank-accounts',
//                         finalStep: false,
//                         firstStep: false,
//                     },
//                     {
//                         id: "step3",
//                         placement: "bottom",
//                         color: "blue",
//                         title: "Hello",
//                         heading: "Add Customer and Upload Invoices",
//                         body1: "Click here to add customers",
//                         body2: "Upload invoices against the customer",
//                         body1Colour: "#FFFFFFB2",
//                         body2Colour: "",
//                         step: 3,
//                         defaultOpen: true,
//                         open: true,
//                         pageUrl: '/receivables',
//                         isActive: false,
//                         previousUrl: '/receivables',
//                         furtherPreviousUrl: false,
//                         finalStep: false,
//                         firstStep: false,
//                         styles:{
//                             position:"relative",
//                             right:"180px"
//                         }
//                     },
//                     {
//                         id: "step4",
//                         placement: "bottomRight",
//                         color: "blue",
//                         title: "Hello",
//                         heading: "Add Invoice",
//                         body1: "Click here to add Invoice",
//                         body2: "",
//                         body1Colour: "#FFFFFFB2",
//                         body2Colour: "",
//                         step: 4,
//                         defaultOpen: true,
//                         open: true,
//                         pageUrl: '/bank-accounts',
//                         isActive: false,
//                         previousUrl: '/receivables',
//                         furtherPreviousUrl: false,
//                         finalStep: false,
//                         firstStep: false,
//                     },
//                     {
//                         id: "step5",
//                         placement: "bottom",
//                         color: "blue",
//                         title: "Hello",
//                         heading: "View Balance and Reconcile",
//                         body1: "Balance will reflect here when you receive any payment in your virtual bank account",
//                         body2: "Reconcile the payment against uploaded invoices",
//                         body1Colour: "#FFFFFFB2",
//                         body2Colour: "",
//                         step: 5,
//                         defaultOpen: true,
//                         open: true,
//                         pageUrl: '/payouts',
//                         isActive: false,
//                         previousUrl: '/bank-accounts',
//                         furtherPreviousUrl: false,
//                         finalStep: false,
//                         firstStep: false,
//                     },
//                     {
//                         id: "step6",
//                         placement: "bottom",
//                         color: "blue",
//                         title: "Hello",
//                         heading: "Payouts",
//                         body1: "View and download payment advice for all FIDEO processed payments received in your settlement bank account",
//                         body2: "",
//                         body1Colour: "#FFFFFFB2",
//                         body2Colour: "",
//                         step: 6,
//                         defaultOpen: true,
//                         open: true,
//                         pageUrl: '/dashboard',
//                         isActive: false,
//                         previousUrl: '/payouts',
//                         furtherPreviousUrl: false,
//                         finalStep: true,
//                         firstStep: false,
//                         // styles:{
//                         //     position:"relative",
//                         //     top:"63px",
//                         //     left:"241px"
//                         // }
//                     },
//                 ]
//             :
//                 [
//                     // {
//                     //     id: "step1",
//                     //     placement: "left",
//                     //     color: "blue",
//                     //     title: "Hello",
//                     //     heading: "Vbank details",
//                     //     body1: "Download and share bank account details with your customers.",
//                     //     body2: "Make sure you also update the bank account information in the invoice copy to avoid any confusion with your customer",
//                     //     body1Colour: "#FFFFFFB2",
//                     //     body2Colour: "",
//                     //     step: 1,
//                     //     defaultOpen: true,
//                     //     open: true,
//                     //     pageUrl: '/customers',
//                     //     isActive: false,
//                     //     previousUrl: '/bank-accounts',
//                     //     furtherPreviousUrl: false,
//                     //     finalStep: false,
//                     //     firstStep: true,
//                     //     styles:{
//                     //         position:"relative",
//                     //         right:"150px"
//                     //     }
//                     // },
//                     {
//                         id: "step2",
//                         placement: "bottomRight",
//                         color: "blue",
//                         title: "Hello",
//                         heading: "Add Customer and Upload Invoices",
//                         body1: "Click here to add customer",
//                         body2: "Upload invoices against the customer",
//                         body1Colour: "#FFFFFFB2",
//                         body2Colour: "",
//                         step: 2,
//                         defaultOpen: true,
//                         open: true,
//                         pageUrl: '/receivables',
//                         isActive: false,
//                         previousUrl: '/customers',
//                         furtherPreviousUrl: 'false',
//                         finalStep: false,
//                         firstStep: true,
//                     },
//                     {
//                         id: "step3",
//                         placement: "bottom",
//                         color: "blue",
//                         title: "Hello",
//                         heading: "Add Customer and Upload Invoices",
//                         body1: "Click here to add customers",
//                         body2: "Upload invoices against the customer",
//                         body1Colour: "#FFFFFFB2",
//                         body2Colour: "",
//                         step: 3,
//                         defaultOpen: true,
//                         open: true,
//                         pageUrl: '/receivables',
//                         isActive: false,
//                         previousUrl: '/receivables',
//                         furtherPreviousUrl: false,
//                         finalStep: false,
//                         firstStep: false,
//                         styles:{
//                             position:"relative",
//                             right:"180px"
//                         }
//                     },
//                     {
//                         id: "step4",
//                         placement: "bottomRight",
//                         color: "blue",
//                         title: "Hello",
//                         heading: "Add Invoice",
//                         body1: "Click here to add Invoice",
//                         body2: "",
//                         body1Colour: "#FFFFFFB2",
//                         body2Colour: "",
//                         step: 4,
//                         defaultOpen: true,
//                         open: true,
//                         pageUrl: '/payouts',
//                         isActive: false,
//                         previousUrl: '/receivables',
//                         furtherPreviousUrl: false,
//                         finalStep: false,
//                         firstStep: false,
//                     },
//                     // {
//                     //     id: "step5",
//                     //     placement: "bottom",
//                     //     color: "blue",
//                     //     title: "Hello",
//                     //     heading: "View Balance and Reconcile",
//                     //     body1: "Balance will reflect here when you receive any payment in your virtual bank account",
//                     //     body2: "Reconcile the payment against uploaded invoices",
//                     //     body1Colour: "#FFFFFFB2",
//                     //     body2Colour: "",
//                     //     step: 5,
//                     //     defaultOpen: true,
//                     //     open: true,
//                     //     pageUrl: '/payouts',
//                     //     isActive: false,
//                     //     previousUrl: '/bank-accounts',
//                     //     furtherPreviousUrl: false,
//                     //     finalStep: false,
//                     //     firstStep: false,
//                     // },
//                     {
//                         id: "step6",
//                         placement: "bottom",
//                         color: "blue",
//                         title: "Hello",
//                         heading: "Payouts",
//                         body1: "View and download payment advice for all FIDEO processed payments received in your settlement bank account",
//                         body2: "",
//                         body1Colour: "#FFFFFFB2",
//                         body2Colour: "",
//                         step: 6,
//                         defaultOpen: true,
//                         open: true,
//                         pageUrl: '/dashboard',
//                         isActive: false,
//                         previousUrl: '/payouts',
//                         furtherPreviousUrl: false,
//                         finalStep: true,
//                         firstStep: false,
//                         // styles:{
//                         //     position:"relative",
//                         //     top:"63px",
//                         //     left:"241px"
//                         // }
//                     },
//                 ]
//         )
//     }, [])

//     return tourArray;
// };

// export const tourArray = TourArrayGenerator();

// import CryptoJS from 'crypto-js';
// import { secretKey } from "constants";

// const generateTourArray = () => {
//     const decryptValue = (encryptedValue) => {
//         const bytes = CryptoJS.AES.decrypt(encryptedValue || '', secretKey);
//         return bytes.toString(CryptoJS.enc.Utf8);
//     };

//     console.log(decryptValue(localStorage.getItem('nickName') || ''), "xxxxxx")

//     const storedUserRole = decryptValue(localStorage.getItem('nickName') || '');

//     return (
//         storedUserRole === 'malik'
//         ?
//             [
//                 {
//                     id: "step1",
//                     placement: "left",
//                     color: "blue",
//                     title: "Hello",
//                     heading: "Vbank details",
//                     body1: "Download and share bank account details with your customers.",
//                     body2: "Make sure you also update the bank account information in the invoice copy to avoid any confusion with your customer",
//                     body1Colour: "#FFFFFFB2",
//                     body2Colour: "",
//                     step: 1,
//                     defaultOpen: true,
//                     open: true,
//                     pageUrl: '/customers',
//                     isActive: false,
//                     previousUrl: '/bank-accounts',
//                     furtherPreviousUrl: false,
//                     finalStep: false,
//                     firstStep: true,
//                     styles:{
//                         position:"relative",
//                         right:"150px"
//                     }
//                 },
//                 {
//                     id: "step2",
//                     placement: "bottomRight",
//                     color: "blue",
//                     title: "Hello",
//                     heading: "Add Customer and Upload Invoices",
//                     body1: "Click here to add customer",
//                     body2: "Upload invoices against the customer",
//                     body1Colour: "#FFFFFFB2",
//                     body2Colour: "",
//                     step: 2,
//                     defaultOpen: true,
//                     open: true,
//                     pageUrl: '/receivables',
//                     isActive: false,
//                     previousUrl: '/customers',
//                     furtherPreviousUrl: '/bank-accounts',
//                     finalStep: false,
//                     firstStep: false,
//                 },
//                 {
//                     id: "step3",
//                     placement: "bottom",
//                     color: "blue",
//                     title: "Hello",
//                     heading: "Add Customer and Upload Invoices",
//                     body1: "Click here to add customers",
//                     body2: "Upload invoices against the customer",
//                     body1Colour: "#FFFFFFB2",
//                     body2Colour: "",
//                     step: 3,
//                     defaultOpen: true,
//                     open: true,
//                     pageUrl: '/receivables',
//                     isActive: false,
//                     previousUrl: '/receivables',
//                     furtherPreviousUrl: false,
//                     finalStep: false,
//                     firstStep: false,
//                     styles:{
//                         position:"relative",
//                         right:"180px"
//                     }
//                 },
//                 {
//                     id: "step4",
//                     placement: "bottomRight",
//                     color: "blue",
//                     title: "Hello",
//                     heading: "Add Invoice",
//                     body1: "Click here to add Invoice",
//                     body2: "",
//                     body1Colour: "#FFFFFFB2",
//                     body2Colour: "",
//                     step: 4,
//                     defaultOpen: true,
//                     open: true,
//                     pageUrl: '/bank-accounts',
//                     isActive: false,
//                     previousUrl: '/receivables',
//                     furtherPreviousUrl: false,
//                     finalStep: false,
//                     firstStep: false,
//                 },
//                 {
//                     id: "step5",
//                     placement: "bottom",
//                     color: "blue",
//                     title: "Hello",
//                     heading: "View Balance and Reconcile",
//                     body1: "Balance will reflect here when you receive any payment in your virtual bank account",
//                     body2: "Reconcile the payment against uploaded invoices",
//                     body1Colour: "#FFFFFFB2",
//                     body2Colour: "",
//                     step: 5,
//                     defaultOpen: true,
//                     open: true,
//                     pageUrl: '/payouts',
//                     isActive: false,
//                     previousUrl: '/bank-accounts',
//                     furtherPreviousUrl: false,
//                     finalStep: false,
//                     firstStep: false,
//                 },
//                 {
//                     id: "step6",
//                     placement: "bottom",
//                     color: "blue",
//                     title: "Hello",
//                     heading: "Payouts",
//                     body1: "View and download payment advice for all FIDEO processed payments received in your settlement bank account",
//                     body2: "",
//                     body1Colour: "#FFFFFFB2",
//                     body2Colour: "",
//                     step: 6,
//                     defaultOpen: true,
//                     open: true,
//                     pageUrl: '/dashboard',
//                     isActive: false,
//                     previousUrl: '/payouts',
//                     furtherPreviousUrl: false,
//                     finalStep: true,
//                     firstStep: false,
//                     // styles:{
//                     //     position:"relative",
//                     //     top:"63px",
//                     //     left:"241px"
//                     // }
//                 },
//             ]
//         :
//             [
//                 // {
//                 //     id: "step1",
//                 //     placement: "left",
//                 //     color: "blue",
//                 //     title: "Hello",
//                 //     heading: "Vbank details",
//                 //     body1: "Download and share bank account details with your customers.",
//                 //     body2: "Make sure you also update the bank account information in the invoice copy to avoid any confusion with your customer",
//                 //     body1Colour: "#FFFFFFB2",
//                 //     body2Colour: "",
//                 //     step: 1,
//                 //     defaultOpen: true,
//                 //     open: true,
//                 //     pageUrl: '/customers',
//                 //     isActive: false,
//                 //     previousUrl: '/bank-accounts',
//                 //     furtherPreviousUrl: false,
//                 //     finalStep: false,
//                 //     firstStep: true,
//                 //     styles:{
//                 //         position:"relative",
//                 //         right:"150px"
//                 //     }
//                 // },
//                 {
//                     id: "step2",
//                     placement: "bottomRight",
//                     color: "blue",
//                     title: "Hello",
//                     heading: "Add Customer and Upload Invoices",
//                     body1: "Click here to add customer",
//                     body2: "Upload invoices against the customer",
//                     body1Colour: "#FFFFFFB2",
//                     body2Colour: "",
//                     step: 2,
//                     defaultOpen: true,
//                     open: true,
//                     pageUrl: '/receivables',
//                     isActive: false,
//                     previousUrl: '/customers',
//                     furtherPreviousUrl: 'false',
//                     finalStep: false,
//                     firstStep: true,
//                 },
//                 {
//                     id: "step3",
//                     placement: "bottom",
//                     color: "blue",
//                     title: "Hello",
//                     heading: "Add Customer and Upload Invoices",
//                     body1: "Click here to add customers",
//                     body2: "Upload invoices against the customer",
//                     body1Colour: "#FFFFFFB2",
//                     body2Colour: "",
//                     step: 3,
//                     defaultOpen: true,
//                     open: true,
//                     pageUrl: '/receivables',
//                     isActive: false,
//                     previousUrl: '/receivables',
//                     furtherPreviousUrl: false,
//                     finalStep: false,
//                     firstStep: false,
//                     styles:{
//                         position:"relative",
//                         right:"180px"
//                     }
//                 },
//                 {
//                     id: "step4",
//                     placement: "bottomRight",
//                     color: "blue",
//                     title: "Hello",
//                     heading: "Add Invoice",
//                     body1: "Click here to add Invoice",
//                     body2: "",
//                     body1Colour: "#FFFFFFB2",
//                     body2Colour: "",
//                     step: 4,
//                     defaultOpen: true,
//                     open: true,
//                     pageUrl: '/payouts',
//                     isActive: false,
//                     previousUrl: '/receivables',
//                     furtherPreviousUrl: false,
//                     finalStep: false,
//                     firstStep: false,
//                 },
//                 // {
//                 //     id: "step5",
//                 //     placement: "bottom",
//                 //     color: "blue",
//                 //     title: "Hello",
//                 //     heading: "View Balance and Reconcile",
//                 //     body1: "Balance will reflect here when you receive any payment in your virtual bank account",
//                 //     body2: "Reconcile the payment against uploaded invoices",
//                 //     body1Colour: "#FFFFFFB2",
//                 //     body2Colour: "",
//                 //     step: 5,
//                 //     defaultOpen: true,
//                 //     open: true,
//                 //     pageUrl: '/payouts',
//                 //     isActive: false,
//                 //     previousUrl: '/bank-accounts',
//                 //     furtherPreviousUrl: false,
//                 //     finalStep: false,
//                 //     firstStep: false,
//                 // },
//                 {
//                     id: "step6",
//                     placement: "bottom",
//                     color: "blue",
//                     title: "Hello",
//                     heading: "Payouts",
//                     body1: "View and download payment advice for all FIDEO processed payments received in your settlement bank account",
//                     body2: "",
//                     body1Colour: "#FFFFFFB2",
//                     body2Colour: "",
//                     step: 6,
//                     defaultOpen: true,
//                     open: true,
//                     pageUrl: '/dashboard',
//                     isActive: false,
//                     previousUrl: '/payouts',
//                     furtherPreviousUrl: false,
//                     finalStep: true,
//                     firstStep: false,
//                     // styles:{
//                     //     position:"relative",
//                     //     top:"63px",
//                     //     left:"241px"
//                     // }
//                 },
//             ]
//     )
// };

// export const tourArray = generateTourArray();
export const tourArrays = [
    [
        {
            id: "step1",
            placement: "left",
            color: "blue",
            title: "Hello",
            heading: "Virtual Bank Account Details",
            body1: `Download and share your "local and international (swift)" bank account details with your customers.`,
            body2: "Make sure you also update the bank account information in the invoice copy to avoid any confusion with your customer",
            body1Colour: "#FFFFFFB2",
            body2Colour: "",
            step: 1,
            defaultOpen: true,
            open: true,
            pageUrl: '/customers',
            isActive: false,
            previousUrl: '/bank-accounts',
            furtherPreviousUrl: false,
            finalStep: false,
            firstStep: true,
            styles:{
                position:"relative",
                right:"150px"
            }
        },
        {
            id: "step2",
            placement: "bottomRight",
            color: "blue",
            title: "Hello",
            heading: "Add Customer",
            body1: `Click on "Add Customer" button to add a customer from whom you want to receive payments`,
            body2: "",
            body1Colour: "#FFFFFFB2",
            body2Colour: "",
            step: 2,
            defaultOpen: true,
            open: true,
            pageUrl: '/receivables',
            isActive: false,
            previousUrl: '/customers',
            furtherPreviousUrl: '/bank-accounts',
            finalStep: false,
            firstStep: false,
        },
        {
            id: "step3",
            placement: "left",
            color: "blue",
            title: "Hello",
            heading: "Upload Invoices",
            body1: `To upload invoices in bulk click on "Upload Invoices". Once bulk upload is done and all invoices are verified by FIDEO team, a payment link will be auto generated for all invoices separately which you can send to your customers to receive payment`,
            body2: "",
            body1Colour: "#FFFFFFB2",
            body2Colour: "",
            step: 3,
            defaultOpen: true,
            open: true,
            pageUrl: '/receivables',
            isActive: false,
            previousUrl: '/receivables',
            furtherPreviousUrl: false,
            finalStep: false,
            firstStep: false,
            styles:{
                position:"relative",
                right:"180px"
            }
        },
        {
            id: "step4",
            placement: "left",
            color: "blue",
            title: "Hello",
            heading: "Add Invoice",
            body1: `Click on "Add Invoice" button to upload invoice. After you upload , invoice will be verified by FIDEO team & a payment link will be auto generated which you can send to the customer to receive payments`,
            body2: "",
            body1Colour: "#FFFFFFB2",
            body2Colour: "",
            step: 4,
            defaultOpen: true,
            open: true,
            pageUrl: '/bank-accounts',
            isActive: false,
            previousUrl: '/receivables',
            furtherPreviousUrl: false,
            finalStep: false,
            firstStep: false,
            styles:{
                position:"relative",
                right:"180px"
            }
        },
        {
            id: "step5",
            placement: "bottom",
            color: "blue",
            title: "Hello",
            heading: "View Balance and Reconcile",
            body1: `Balance and "Reconcile" button will appear here in respective currencies when money is credited to your virtual bank account so that you can reconcile payments against uploaded invoices`,
            body2: "",
            body1Colour: "#FFFFFFB2",
            body2Colour: "",
            step: 5,
            defaultOpen: true,
            open: true,
            pageUrl: '/payouts',
            isActive: false,
            previousUrl: '/bank-accounts',
            furtherPreviousUrl: false,
            finalStep: false,
            firstStep: false,
        },
        {
            id: "step6",
            placement: "bottom",
            color: "blue",
            title: "Hello",
            heading: "Payouts",
            body1: "View and download payment advice for all FIDEO processed payments received in your settlement bank account",
            body2: "",
            body1Colour: "#FFFFFFB2",
            body2Colour: "",
            step: 6,
            defaultOpen: true,
            open: true,
            pageUrl: '/dashboard',
            isActive: false,
            previousUrl: '/payouts',
            furtherPreviousUrl: false,
            finalStep: true,
            firstStep: false,
            // styles:{
            //     position:"relative",
            //     top:"63px",
            //     left:"241px"
            // }
        },
    ],
    [
        // {
        //     id: "step1",
        //     placement: "left",
        //     color: "blue",
        //     title: "Hello",
        //     heading: "Vbank details",
        //     body1: "Download and share bank account details with your customers.",
        //     body2: "Make sure you also update the bank account information in the invoice copy to avoid any confusion with your customer",
        //     body1Colour: "#FFFFFFB2",
        //     body2Colour: "",
        //     step: 1,
        //     defaultOpen: true,
        //     open: true,
        //     pageUrl: '/customers',
        //     isActive: false,
        //     previousUrl: '/bank-accounts',
        //     furtherPreviousUrl: false,
        //     finalStep: false,
        //     firstStep: true,
        //     styles:{
        //         position:"relative",
        //         right:"150px"
        //     }
        // },
        {
            id: "step2",
            placement: "bottomRight",
            color: "blue",
            title: "Hello",
            heading: "Add Customer",
            body1: `Click on "Add Customer" button to add a customer from whom you want to receive payments`,
            body2: "",
            body1Colour: "#FFFFFFB2",
            body2Colour: "",
            step: 2,
            defaultOpen: true,
            open: true,
            pageUrl: '/receivables',
            isActive: false,
            previousUrl: '/customers',
            furtherPreviousUrl: 'false',
            finalStep: false,
            firstStep: true,
        },
        {
            id: "step3",
            placement: "left",
            color: "blue",
            title: "Hello",
            heading: "Upload Invoices",
            body1: `To upload invoices in bulk click on "Upload Invoices". Once bulk upload is done and all invoices are verified by FIDEO team, a payment link will be auto generated for all invoices separately which you can send to your customers to receive payment`,
            body2: "",
            body1Colour: "#FFFFFFB2",
            body2Colour: "",
            step: 3,
            defaultOpen: true,
            open: true,
            pageUrl: '/receivables',
            isActive: false,
            previousUrl: '/receivables',
            furtherPreviousUrl: false,
            finalStep: false,
            firstStep: false,
            styles:{
                position:"relative",
                right:"180px"
            }
        },
        {
            id: "step4",
            placement: "left",
            color: "blue",
            title: "Hello",
            heading: "Add Invoice",
            body1: `Click on "Add Invoice" button to upload invoice. After you upload , invoice will be verified by FIDEO team & a payment link will be auto generated which you can send to the customer to receive payments`,
            body2: "",
            body1Colour: "#FFFFFFB2",
            body2Colour: "",
            step: 4,
            defaultOpen: true,
            open: true,
            pageUrl: '/payouts',
            isActive: false,
            previousUrl: '/receivables',
            furtherPreviousUrl: false,
            finalStep: false,
            firstStep: false,
            styles:{
                position:"relative",
                right:"180px"
            }
        },
        // {
        //     id: "step5",
        //     placement: "bottom",
        //     color: "blue",
        //     title: "Hello",
        //     heading: "View Balance and Reconcile",
        //     body1: "Balance will reflect here when you receive any payment in your virtual bank account",
        //     body2: "Reconcile the payment against uploaded invoices",
        //     body1Colour: "#FFFFFFB2",
        //     body2Colour: "",
        //     step: 5,
        //     defaultOpen: true,
        //     open: true,
        //     pageUrl: '/payouts',
        //     isActive: false,
        //     previousUrl: '/bank-accounts',
        //     furtherPreviousUrl: false,
        //     finalStep: false,
        //     firstStep: false,
        // },
        {
            id: "step6",
            placement: "bottom",
            color: "blue",
            title: "Hello",
            heading: "Payouts",
            body1: "View and download payment advice for all FIDEO processed payments received in your settlement bank account",
            body2: "",
            body1Colour: "#FFFFFFB2",
            body2Colour: "",
            step: 6,
            defaultOpen: true,
            open: true,
            pageUrl: '/dashboard',
            isActive: false,
            previousUrl: '/payouts',
            furtherPreviousUrl: false,
            finalStep: true,
            firstStep: false,
            // styles:{
            //     position:"relative",
            //     top:"63px",
            //     left:"241px"
            // }
        },
    ]
]