import React ,{useState , useEffect} from 'react'
import UserTable from './profileComponents/UserTable';
import UserTableMobile from './profileComponents/UserTableMobile';
import classes from './UserManagement.module.scss'


const UserManagement = (props) => {
    const [screenWidth, setScreenWidth] = useState(getCurrentDimension());
    function getCurrentDimension() {
        return  window.innerWidth
    }
    
    useEffect(() => {
      const updateDimension = () => {
          setScreenWidth(getCurrentDimension());
       };
       window.addEventListener("resize", updateDimension);
        return () => {
          window.removeEventListener("resize", updateDimension);
        };
    }, [screenWidth]);

    // console.log(props?.userList);
return(
    <>
      {
        screenWidth>600
        ?
        // <UserTable tableData = {user_list_dto}/>
        <UserTable tableData = {props?.userList?.profile_user_details_list} fetchingUserDetails={props?.fetchingUserDetails} />
        :
        <UserTableMobile tableData = {props?.userList?.profile_user_details_list}/>
        // <UserTableMobile tableData = {user_list_dto}/>
      }
    </>
)
}

export default UserManagement