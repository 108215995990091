import React from "react";
import clsx from "clsx";
import classes from "./ReconSuccessPrompt.module.scss"
import { Link } from 'react-router-dom';


const ReconSuccess = (props) =>{
   const {mainIcon ,title ,heading, subHeading ,reconCurrency ,reconAmount , buttonText ,handleBtn, showReconPartial, reconPartialAmount, partialCount} = props
   return (
    <>
      <div className={classes.bigCont}>
        <div className={classes.imgBox}>
           <img src={mainIcon} alt="img" className={classes.mainIcon} />
        </div>
        <div className={classes.contentBox}>
           <h1 className={classes.subHeading}>{subHeading} <span className={classes.reconAmount}>{reconCurrency}{reconAmount}</span> </h1>
           {
            showReconPartial
               ?
                  <div className={clsx(classes.errorBox, 'max-w-[416px] gap-[10px] flex')}>
                     <h1 className={clsx(classes.subHeading, '!text-[#F74F51]')}>Reconciliation of <span className={classes.reconAmount}>{reconCurrency}{reconPartialAmount}</span> failed for {partialCount} {partialCount > 1 ? 'invoices' : 'invoice'}.</h1>
                     <button
                        className={clsx('w-[58px] h-[40px] py-[8px] px-[10px] rounded-[8px] bg-[#FFF] border-[2px] border-solid border-[#181C30]')} 
                        onClick={()=>{
                           handleBtn()
                           props.closeModal()
                        }}
                     >
                        <div className={'w-[34px] text-[14px] font-[600] text-[#181C30]'}>
                           {buttonText}
                        </div>
                     </button>
                  </div>
               :
                  <button
                     className={classes.doneBtn} 
                     onClick={()=>{
                        handleBtn()
                        props.closeModal()
                     }}
                  >
                     {buttonText}
                  </button>
            }
        </div>
      </div>
    </>
   )
}

export default ReconSuccess