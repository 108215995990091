import classes from 'Components/Authentication/PasswordTooltip.module.scss';
import wrongCircle from 'assets/Icons/cross-circle.svg';
import checkCircle from 'assets/Icons/check-circle.svg';

const PasswordTooltip = (props) => {

    let classImgArray = [];

    const checkValidation = (condition) =>{
        const elementClass = `${classes.condition} `; 
        const checkResult = condition ? "right": "wrong"
        const elementImg = <img src={condition ? checkCircle : wrongCircle} alt={`${checkResult}-icon`} className={`${classes.img} ${classes[`img_${checkResult}`]}`}/>;

        return {
            class : elementClass,
            img : elementImg
        }
    }

    for(let i=0; i<props.validation.length; i++){
        const isTrue = props.validation[i](props.enteredInput);
        classImgArray.push(checkValidation(isTrue));
    }

    return (
        <div className={`${classes.tooltip} ${props.className}`}>
            <div className={classes.tooltip_header}>Your password must have:</div>
            <div className={classImgArray[0].class}> {classImgArray[0].img} (8-30) characters in length</div>
            <div className={classImgArray[1].class}> {classImgArray[1].img} At least 1 lower case letter (a-z)</div>
            <div className={classImgArray[2].class}> {classImgArray[2].img} At least 1 upper case letter (A-Z)</div>
            <div className={classImgArray[3].class}> {classImgArray[3].img} Numbers (i.e. 0-9)</div>
        </div>
    )
}

export default PasswordTooltip;