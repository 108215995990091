import { useState, useEffect } from 'react';

const ScreenWidth = () => {
    let [screenWidth, setScreenWidth] = useState(getCurrentDimension());

    function getCurrentDimension() {
        return window.innerWidth;
    }

    useEffect(() => {
        const updateDimension = () => {
            setScreenWidth(getCurrentDimension());
        };
        window.addEventListener("resize", updateDimension);
        return () => {
            window.removeEventListener("resize", updateDimension);
        };
    }, [screenWidth]);

    return screenWidth;
}

export default ScreenWidth;
