import { useDispatch, useSelector } from "react-redux";
import ReactDOM from "react-dom";
import sorting from 'assets/Images/sorting-icon.svg'
import descIcon from 'assets/Icons/descSortIcon.svg'
import ascSortIcon from 'assets/Icons/ascSortIcon.svg'
import PageIntro from "Components/Global/PageIntro";
import PlusIcon from "assets/Icons/plus-black.svg";
import UploadInvoicesIcon from "assets/Icons/upload-invoices.svg";
import { modalActions } from 'Store/modal-redux'
import classes from 'Pages/Admin/Receivables.module.scss'
import ReceivePayment from 'Components/Payment/ReceivePayment'
import ReceiveBulkUploadPayment from 'Components/Payment/ReceiveBulkUploadPayment'
import Button from 'Layout/Button'
import ReceivablesReportTable from 'Components/ReportTable/ReceivablesReportTable'
import UploadTable from 'Components/ReportTable/UploadTable'
import { useEffect, useMemo, useState } from 'react'
import { ReceivablesActions } from 'Store/customer-redux'
import UseApiCall from 'hooks/useApiCall'
import { apiGetReceivables, apiGetBulkTransactionsData, apiGetReceivableCustomers, apiGetReceivableStatuses } from 'services'
import dayjs from 'dayjs'
import clsx from 'clsx'
import { enqueueSnackbar } from "notistack";
import ReportPageFilter from "Components/Global/ReportPageFilter";
// import TransactionDetails from "Components/Global/TransactionDetails";
// import FilterBox from "Components/Global/FilterBox/FilterBox";
import useClickOutside from "hooks/useClickOutside";
// import { calcLength } from "framer-motion";
// import TourStartHeader from "Components/Tour/TourStartHeader";
// import { formatNumberToLocaleString } from 'hooks/formatNumberToLocaleString';
import Excel from 'exceljs';
import { saveAs } from 'file-saver';
import ReceivablesReportTableDraft from "../../Components/ReportTable/ReceivablesReportTableDraft";
import editInvoicesIcon from "../../assets/Icons/editBlack.svg";
import CreateInvoice from "../../Components/CreateInvoice/CreateInvoice";
import ActionWithOutIcon from "../../Components/ReportTable/ActionWithOutIcon";
import arrowDown from "../../assets/Images/arrow_down.svg";
import ArrowUp from "../../assets/Icons/arrow-up-traingle-white.svg";
import DraftEditInvoice from "../../Components/CreateInvoice/DraftEditInvoice";

const tableHeader = ['invoice id', 'issue date', 'customer details', 'Receivable Amount', 'Pending Amount', 'status']

const ReceivablesDraft = () => {
    const sortingStateObj = {
        defaultState:{
            name:"default",
            icon:sorting,
            active:false,
        },
        ascState:{
            name:"ascending",
            icon:ascSortIcon,
            active:true,
        },
        descState:{
            name:"descending",
            icon:descIcon,
            active:true,
        },
    }
    const [selectedInvoiceId, setSelectedInvoiceId] = useState(null)
    const [mobileFilterOpen,setMobileFilterOpen] = useState(false);
    const [mobileSortOpen,setMobileSortOpen] = useState(false);
    // const [sortingState,setSortingState] = useState(sortingStateObj.defaultState);
    const [issueDateState,setIssueDateState] = useState(sortingStateObj.defaultState);
    const [customerDetailsState,setCustomerDetailsState] = useState(sortingStateObj.defaultState);
    const [amountState,setAmountState] = useState(sortingStateObj.defaultState);
    const [headerStatusState,setHeaderStatusState] = useState(sortingStateObj.defaultState);
    const [invoiceIdState,setInvoiceIdState] = useState(sortingStateObj.defaultState);
    const [sortState,setSortState] = useState({sortField:"",order:""})
    const resetAll = () =>{
        setInvoiceIdState(sortingStateObj.defaultState)
        setIssueDateState(sortingStateObj.defaultState)
        setCustomerDetailsState(sortingStateObj.defaultState)
        setAmountState(sortingStateObj.defaultState)
        setHeaderStatusState(sortingStateObj.defaultState)
    }
    const arr = [
        {
            id:1,
            header:`creation date`,
            width: 1.5,
            minWidth: 130,
            button:true,
            icon:issueDateState?.icon,
            active:issueDateState?.active,
            cursor:true,
            handleClick:()=>{
                setCurrentPage(1)
                if(issueDateState.name === "default"){
                    resetAll()
                    setIssueDateState(sortingStateObj.ascState)
                    setSortState({sortField:"issueDate",order:"ASC"})
                }
                else if(issueDateState.name === "ascending"){
                    resetAll()
                    setIssueDateState(sortingStateObj.descState)
                    setSortState({sortField:"issueDate",order:"DESC"})
                }
                else if(issueDateState.name === "descending"){
                    resetAll()
                    setIssueDateState(sortingStateObj.defaultState)
                    setSortState({sortField:"",order:""})
                }
            }
        },
        {
            id:2,
            header:"invoice id",
            width: 3.5,
            minWidth:106.96,
            button:true,
            icon:invoiceIdState?.icon,
            active:invoiceIdState?.active,
            cursor:true,
            handleClick:()=>{
                setCurrentPage(1)
                if(invoiceIdState.name === "default"){
                    resetAll()
                    setInvoiceIdState(sortingStateObj.ascState)
                    setSortState({sortField:"invoiceId",order:"ASC"})
                }
                else if(invoiceIdState.name === "ascending"){
                    resetAll()
                    setInvoiceIdState(sortingStateObj.descState)
                    setSortState({sortField:"invoiceId",order:"DESC"})
                }
                else if(invoiceIdState.name === "descending"){
                    resetAll()
                    setInvoiceIdState(sortingStateObj.defaultState)
                    setSortState({sortField:"",order:""})
                }
            }
        },
        {
            id:3,
            header:"customer details",
            width: 10,
            minWidth: 130,
            button:true,
            icon:customerDetailsState?.icon,
            active:customerDetailsState?.active,
            cursor:true,
            handleClick:()=>{
                setCurrentPage(1)
                if(customerDetailsState.name === "default"){
                    resetAll()
                    setCustomerDetailsState(sortingStateObj.ascState)
                    setSortState({sortField:"customerBaseEntity.customerDetails.customerName",order:"ASC"})
                }
                else if(customerDetailsState.name === "ascending"){
                    resetAll()
                    setCustomerDetailsState(sortingStateObj.descState)
                    setSortState({sortField:"customerBaseEntity.customerDetails.customerName",order:"DESC"})
                }
                else if(customerDetailsState.name === "descending"){
                    resetAll()
                    setCustomerDetailsState(sortingStateObj.defaultState)
                    setSortState({sortField:"",order:""})
                }
            }
        },
        {
            id:4,
            header:`Receivable amount`,
            width: 2.5,
            minWidth: 130,
            button:true,
            icon:amountState?.icon,
            active:amountState?.active,
            cursor:true,
            handleClick:()=>{
                setCurrentPage(1)
                if(amountState.name === "default"){
                    resetAll()
                    setAmountState(sortingStateObj.ascState)
                    setSortState({sortField:"amount",order:"ASC"})
                }
                else if(amountState.name === "ascending"){
                    resetAll()
                    setAmountState(sortingStateObj.descState)
                    setSortState({sortField:"amount",order:"DESC"})
                }
                else if(amountState.name === "descending"){
                    resetAll()
                    setAmountState(sortingStateObj.defaultState)
                    setSortState({sortField:"",order:""})
                }
            }
        },
        {
            id:5,
            header:"Due Date",
            width: 2.5,
            minWidth: 130,
            button:false,
            icon:amountState?.icon,
            active:false,
            cursor:false,
            handleClick:()=>{

                // if(amountState.name === "default"){
                //   resetAll()
                //   setAmountState(sortingStateObj.ascState)
                //   setSortState({sortField:"amount",order:"ASC"})
                // }
                // else if(amountState.name === "ascending"){
                //   resetAll()
                //   setAmountState(sortingStateObj.descState)
                //   setSortState({sortField:"amount",order:"DESC"})
                // }
                // else if(amountState.name === "descending"){
                //   resetAll()
                //   setAmountState(sortingStateObj.defaultState)
                //   setSortState({sortField:"",order:""})
                // }
            }
        },
        {
            id:6,
            header:"status",
            width: 2.5,
            minWidth: 130,
            button:true,
            icon:headerStatusState?.icon,
            active:headerStatusState?.active,
            cursor:true,
            handleClick:()=>{
                setCurrentPage(1)
                if(headerStatusState.name === "default"){
                    resetAll()
                    setHeaderStatusState(sortingStateObj.ascState)
                    setSortState({sortField:"invoiceOrder",order:"ASC"})
                }
                else if(headerStatusState.name === "ascending"){
                    resetAll()
                    setHeaderStatusState(sortingStateObj.descState)
                    setSortState({sortField:"invoiceOrder",order:"DESC"})
                }
                else if(headerStatusState.name === "descending"){
                    resetAll()
                    setHeaderStatusState(sortingStateObj.defaultState)
                    setSortState({sortField:"",order:""})
                }
            }
        },
    ]

    const createInvoiceModal = useSelector((state) => state.modal.createInvoiceModal);
    const showDraftEditInvoice = useSelector((state) => state.modal.showDraftEditInvoice);
    const createInvoiceReceivePayment = useSelector((state) => state.modal.createInvoiceReceivePayment);
    const optRefMwebBtn = useClickOutside(()=>{
        dispatch(modalActions.invoiceActionDisplayMwebRec(false))
    });
    const invoiceActionDisplayMwebRec = useSelector((state) => state.modal.invoiceActionDisplayMwebRec);
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);


    const optRef = useClickOutside(() => setShowCustomerDropdown(false));

    const optRefInvoice = useClickOutside(()=>{
        dispatch(modalActions.invoiceActionDisplay(false))
    })
    const [selectedCustomers, setSelectedCustomers] = useState({});
    const [selectedStatuses, setSelectedStatuses] = useState({});
    const [selectedStatusesNew, setSelectedStatusesNew] = useState({
        CANCELLED: false,
        CREATED: false,
        DRAFT: true,
        IN_PROGRESS: false,
        IN_REVIEW: false,
        LINK_SENT: false,
        PARTIALLY_PAID: false,
        SETTLED: false,
        SETTLED_OUTSIDE: false
    });
    const [selectedDate, setSelectedDate] = useState({
        fromDate:'',
        toDate:''
    });
    const [activeDate, setActiveDate] = useState('all');
    const [allSelected, setAllSelected] = useState(true);
    const [allStatusSelected, setAllStatusSelected] = useState(true);
    const [showCustomerDropdown, setShowCustomerDropdown] = useState(false);
    const [showStatusDropdown, setShowStatusDropdown] = useState(false);
    const [showInvoiceDateDropdown, setShowInvoiceDateDropdown] = useState(false);
    const [showDateCounter, setShowDateCounter] = useState(false);
    const [customerCounter, setCustomerCounter] = useState('0');
    const [statusCounter, setStatusCounter] = useState('0');
    const [pageRendered, setPageRendered] = useState(true);
    const [firstApiCall, setFirstApiCall] = useState(false);

    const [statuses,setStatuses] = useState();


    const toggleCustomerDropdown = () => {
        setShowCustomerDropdown(!showCustomerDropdown);
        setShowStatusDropdown(false);
        setShowInvoiceDateDropdown(false);
    };

    const toggleStatusDropdown = () => {
        setShowCustomerDropdown(false);
        setShowStatusDropdown(!showStatusDropdown);
        setShowInvoiceDateDropdown(false);
    };

    const toggleInvoiceDateDropdown = () => {
        setShowCustomerDropdown(false);
        setShowStatusDropdown(false);
        setShowInvoiceDateDropdown(!showInvoiceDateDropdown);
    };
    const handlePaginate = (page)=>{
        setCurrentPage(page)
    }
    const [curentPage , setCurrentPage] = useState(1);
    // const [totalPage , setTotalPage] = useState(30);
    const dispatch = useDispatch()
    const receivePaymentDisplay = useSelector((state) => state.modal.receivePaymentDisplay)
    const bulkUploaderDisplay = useSelector((state) => state.modal.bulkUploaderDisplay)
    const invoice_list = useSelector((state) => state.receivable.invoice_list)
    const updateReceivablesValue = useSelector((state) => state.receivable.updateReceivables);
    const bulkUploadDataValue = useSelector((state) => state.receivable.bulkUploadData);
    const invoice_count = useSelector((state)=>state.receivable.total_invoices);
    const [otherLoader, setOtherLoader] = useState(true)
    const [getReceivables, fetching] = UseApiCall(apiGetReceivables, (res) => {
        setOtherLoader(false)
        dispatch(ReceivablesActions.getReceivabelReports(res?.data.invoice_list))
        dispatch(ReceivablesActions.getTotalInvoiceList(res?.data.invoice_count))
    }, (err) => {
        setOtherLoader(false);
        enqueueSnackbar(`${err?.data?.error_response?.message} ${err?.data?.error_response?.errors !== null ? JSON.stringify(err?.data?.error_response?.errors[0]) : ""}`, { variant: 'error' })
    })

    const [getUploadData, uploadDataLoading, uploadDataResult] = UseApiCall(apiGetBulkTransactionsData, (res) => {
        dispatch(ReceivablesActions.getBulkUploadData(res?.data))
    }, (err) => {
        enqueueSnackbar(`${err?.data?.error_response?.message} ${err?.data?.error_response?.errors !== null ? JSON.stringify(err?.data?.error_response?.errors[0]) : ""}`, { variant: 'error' })
    })

    const [customers,setCustomers] = useState([]);

    const [getReceivableCustomers, fetchingCustomers] = UseApiCall(apiGetReceivableCustomers, (res) => {
        const data = res?.data?.reduce((acc, obj) => {
            if (obj?.id) {
                acc[obj?.id] = true;
            }
            return acc;
        }, {});
        if (JSON.stringify(res?.data) === '[]') {
            setFirstApiCall(true);
        }
        setSelectedCustomers(data)
        setCustomers(res?.data)
    }, (err) => {
        // enqueueSnackbar(`${err?.data?.error_response?.message} ${err?.data?.error_response?.errors !== null ? JSON.stringify(err?.data?.error_response?.errors[0]) : ""}`, { variant: 'error' })
    })

    const selectedCustomerIds = useMemo(() => {
        return Object.keys(selectedCustomers).filter(customerId => selectedCustomers[customerId]);
    }, [selectedCustomers]);

    const toggleCustomerSelection = async (statuses,customerId, len) => {
        setCurrentPage(1)
        setSelectedCustomers(prevSelectedCustomers => {
            const updatedSelection = {
                ...prevSelectedCustomers,
                [customerId]: !prevSelectedCustomers[customerId]
            };
            const countTrueKeys = Object.values(updatedSelection).filter(value => value).length;
            setCustomerCounter(countTrueKeys);
            setAllSelected(countTrueKeys === len);
            return updatedSelection;
        });
    };


    const selectedStatusList = Object.keys(selectedStatuses).filter(status => selectedStatuses[status]);

    const toggleStatusSelection = (status, len) => {
        setCurrentPage(1)
        if(status === 'reset'){
            setAllStatusSelected(false)
            setStatusCounter('0')
            return setSelectedStatuses({});
        }
        setSelectedStatuses(prevSelectedStatuses => {
            const updatedSelection = {
                ...prevSelectedStatuses,
                [status]: !prevSelectedStatuses[status]
            };
            const countTrueKeys = Object.values(updatedSelection).filter(value => value).length;
            setStatusCounter(countTrueKeys)
            setAllStatusSelected(statuses?.length === countTrueKeys)

            return updatedSelection;
        });
    };

    const toggleAllStatesSelection = () => {
        setCurrentPage(1)
        handleDateSelection('all')
        setCustomerCounter('0')
        setStatusCounter('0')
        setAllSelected(true);

        const dataC = customers?.reduce((acc, obj) => {
            if (obj?.id) {
                acc[obj?.id] = true;
            }
            return acc;
        }, {});
        setSelectedCustomers(dataC);

        const data = statuses?.reduce((acc, obj) => {
            if (obj) {
                acc[obj] = true;
            }
            return acc;
        }, {});

        setAllStatusSelected(true)
        setSelectedStatuses(data)
    }

    const handleDateSelection = (period) => {
        setCurrentPage(1)
        setShowDateCounter(period === 'all' ? false : true)
        setActiveDate(period)
        const currentDate = dayjs();
        let fromDate, toDate;

        switch (period) {
            case 'all':
                fromDate = '';
                toDate = '';
                break;
            case 'one':
                fromDate = currentDate.subtract(1, 'month').format('YYYY-MM-DD');
                toDate = currentDate.format('YYYY-MM-DD');
                break;
            case 'three':
                fromDate = currentDate.subtract(3, 'month').format('YYYY-MM-DD');
                toDate = currentDate.format('YYYY-MM-DD');
                break;
            case 'six':
                fromDate = currentDate.subtract(6, 'month').format('YYYY-MM-DD');
                toDate = currentDate.format('YYYY-MM-DD');
                break;
            default:
                // Handle default case or throw an error if needed
                break;
        }

        setSelectedDate({
            fromDate: fromDate,
            toDate: toDate
        });
    };


    const arrBtn = [
        {
            text: 'Add Single Invoice',
            icon: PlusIcon,
            active: true,
            tourId: "step4",
            onClick: function () {
                dispatch(modalActions.receivePayment())
            },
        },
        {
            text: 'Upload Multiple Invoices',
            icon: UploadInvoicesIcon,
            active: true,
            tourId: "step3",
            onClick: function () {
                if (bulkUploaderDisplay) {
                    dispatch(modalActions.bulkUploaderDisplay(false))
                    setTimeout(() => {
                        dispatch(modalActions.bulkUploaderDisplay(true))
                    }, [20])
                }
                else dispatch(modalActions.bulkUploaderDisplay(true))
            },
        },
        {
            text: 'Create Invoice',
            icon: editInvoicesIcon,
            // disabled: cellData?.status?.toLowerCase() === 'in draft' ? false : true,
            onClick: function () {
                // dispatch(modalActions.setCreateInvoiceModal(true))
                dispatch(modalActions.setCreateInvoiceReceivePayment(true))
            },
        },
    ]

    const sortString = sortState.sortField && sortState.order ? `${sortState.sortField},${sortState.order}` : "";

    useEffect(() => {
        if (selectedCustomerIds?.length > 0 && selectedStatusList?.length > 0 && !firstApiCall) {
            setFirstApiCall(true);
        }
    }, [selectedCustomers, selectedStatuses, selectedDate, sortState, curentPage, updateReceivablesValue])

    useEffect(() => {
        if (firstApiCall) {
            const params = {
              page: 0,
              status: 'DEFAULT',
              invoiceStatuses: "DRAFT",
            };
          
            if (selectedDate.fromDate !== '' || selectedDate.toDate !== '') {
              if (selectedDate.fromDate !== '') {
                params.fromDate = selectedDate.fromDate;
              }
              if (selectedDate.toDate !== '') {
                params.toDate = selectedDate.toDate;
              }
            }
          
            if (customers?.length !== selectedCustomerIds?.length) {
              params.customerIds = selectedCustomerIds;
            }
          
            if (sortString !== '') {
              params.sort = sortString;
            }
          
            getReceivables(params);
        }
    }, [selectedStatuses, selectedDate, sortState, curentPage, firstApiCall])

    useEffect(() => {
        if (firstApiCall) {
            const params = {
              page: 0,
              status: 'DEFAULT',
              invoiceStatuses: "DRAFT",
            };
          
            if (selectedDate.fromDate !== '' || selectedDate.toDate !== '') {
              if (selectedDate.fromDate !== '') {
                params.fromDate = selectedDate.fromDate;
              }
              if (selectedDate.toDate !== '') {
                params.toDate = selectedDate.toDate;
              }
            }
          
            if (customers?.length !== selectedCustomerIds?.length) {
              params.customerIds = selectedCustomerIds;
            }
          
            if (sortString !== '') {
              params.sort = sortString;
            }
          
            getReceivables(params);
        }
    }, [updateReceivablesValue])

    useEffect(() => {
        getReceivableCustomers()
    }, [])

    const [getReceivableStatuses, fetchingStatuses] = UseApiCall(apiGetReceivableStatuses, (res) => {
        if (pageRendered) {
            setPageRendered(false)
            const data = res?.data?.reduce((acc, obj) => {
                if (obj) {
                    acc[obj] = true;
                }
                return acc;
            }, {});
            setStatusCounter(res?.data?.length)
            setAllStatusSelected(true)
            setSelectedStatuses(data)
        } else {
            const newSelectedStatuses = {};
            res?.data?.forEach(status => {
                if (status in selectedStatuses) {
                    newSelectedStatuses[status] = (selectedStatuses[status] === true || allStatusSelected) ? true : false
                } else {
                    newSelectedStatuses[status] = allStatusSelected ? true : false
                }
            });
            setSelectedStatuses(newSelectedStatuses)
            setStatusCounter(Object.values(newSelectedStatuses).filter(value => value).length)
        }
        setStatuses(res?.data)
    }, (err) => {
        // enqueueSnackbar(`${err?.data?.error_response?.message} ${err?.data?.error_response?.errors !== null ? JSON.stringify(err?.data?.error_response?.errors[0]) : ""}`, { variant: 'error' })
    })

    useEffect(() => {
        if (selectedCustomerIds?.length > 0) {
            getReceivableStatuses({
                customerIds: selectedCustomerIds,
            })
        }
    }, [selectedCustomerIds]);

    useEffect(() => {
        if (!bulkUploaderDisplay) getUploadData()
    }, [bulkUploaderDisplay]);

    useEffect(() => {
        const updateWindowWidth = () => {
            setScreenWidth(window.innerWidth);
        };
        window.addEventListener('resize', updateWindowWidth);
        return () => {
            window.removeEventListener('resize', updateWindowWidth);
        };
    }, []);

    useEffect(() => {
        dispatch(() => {
            dispatch(modalActions.setCreateInvoiceModal(false))
            dispatch(modalActions.setShowDraftEditInvoice(false))
            dispatch(modalActions.setCreateInvoiceReceivePayment(false))
        })
    }, []);

    if(createInvoiceModal){
        return (
            <>
                <CreateInvoice draftSubmitFunction = {()=>{
                    getReceivables({
                        page: curentPage-1,
                        status: 'DEFAULT',
                        fromDate: selectedDate.fromDate,
                        toDate: selectedDate.toDate,
                        invoiceStatuses: "DRAFT",
                        customerIds: selectedCustomerIds,
                        sort: sortString
                    })
                }}/>
            </>
        )
    }

    if(showDraftEditInvoice){
        return (
            <>
                <DraftEditInvoice
                    selectedInvoiceId={selectedInvoiceId}
                />
            </>
        )
    }

    return (
        <div className={classes.receivables} ref={optRef}>
            {(createInvoiceReceivePayment) &&
                ReactDOM.createPortal(
                    <ReceivePayment modal={"createInvoice"}/>,
                    document.getElementById("modal-root")
                )}

            {invoiceActionDisplayMwebRec && screenWidth < 600 &&
                ReactDOM.createPortal(
                    <ActionWithOutIcon
                        option={[...arrBtn]}
                        optRef={optRefMwebBtn}
                        // cellData={"data"}
                    />,
                    document.getElementById("modal-root")
                )}

            {receivePaymentDisplay &&
                ReactDOM.createPortal(
                    <ReceivePayment/>,
                    document.getElementById('modal-root')
                )}

            {bulkUploaderDisplay &&
                ReactDOM.createPortal(
                    <ReceiveBulkUploadPayment/>,
                    document.getElementById('modal-root')
                )}
            {/* <TourStartHeader /> */}
            <PageIntro
                pageName={'Receivables (Draft)'}
                createInvoice={'receivableDraft'}
                message={'View all your receivables at one place'}
                dontShowBtn={true}
                optRef = {optRefInvoice}
            />

            <ReportPageFilter
                statuses={statuses}
                setStatuses={setStatuses}
                customers={customers}
                selectedCustomers={selectedCustomers}
                setSelectedCustomers={setSelectedCustomers}
                toggleCustomerSelection={toggleCustomerSelection}
                selectedCustomerIds={selectedCustomerIds}
                selectedStatuses={selectedStatuses}
                setSelectedStatuses={setSelectedStatuses}
                toggleStatusSelection={toggleStatusSelection}
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
                handleDateSelection={handleDateSelection}
                activeDate={activeDate}
                setActiveDate={setActiveDate}
                showCustomerDropdown={showCustomerDropdown}
                setShowCustomerDropdown={setShowCustomerDropdown}
                toggleCustomerDropdown={toggleCustomerDropdown}
                showStatusDropdown={showStatusDropdown}
                setShowStatusDropdown={setShowStatusDropdown}
                toggleStatusDropdown={toggleStatusDropdown}
                showInvoiceDateDropdown={showInvoiceDateDropdown}
                setShowInvoiceDateDropdown={setShowInvoiceDateDropdown}
                toggleInvoiceDateDropdown={toggleInvoiceDateDropdown}
                allSelected={allSelected}
                setAllSelected={setAllSelected}
                setAllStatusSelected={setAllStatusSelected}
                allStatusSelected={allStatusSelected}
                setShowDateCounter={setShowDateCounter}
                showDateCounter={showDateCounter}
                customerCounter={customerCounter}
                setCustomerCounter={setCustomerCounter}
                statusCounter={statusCounter}
                setStatusCounter={setStatusCounter}
                toggleAllStatesSelection={toggleAllStatesSelection}

                mobileFilterOpen={mobileFilterOpen}
                setMobileFilterOpen={setMobileFilterOpen}
                mobileSortOpen={mobileSortOpen}
                setMobileSortOpen={setMobileSortOpen}
                // exportData={exportData}
                isloadingDownloadBtn={false}
                tableData={invoice_list}
                setSortState={setSortState}
                sortState={sortState}
                isDraft={false}
            />

            {
                (
                    <ReceivablesReportTableDraft
                        tableData={invoice_list}
                        tableHeader={arr}
                        mobileTableData={invoice_list}
                        loading={fetching || otherLoader}
                        customerCounter={customerCounter}
                        statusCounter={statusCounter}
                        showDateCounter={showDateCounter}
                        activeDate={activeDate}
                        current={curentPage}
                        total={invoice_count}
                        handlePaginate={handlePaginate}
                        mobileFilterOpen={mobileFilterOpen}
                        mobileSortOpen={mobileSortOpen}
                        selectedInvoiceId={selectedInvoiceId}
                        setSelectedInvoiceId={setSelectedInvoiceId}
                    />
                )}
            <div className={classes.button}>
                <button
                    onClick={() => dispatch(modalActions.invoiceActionDisplayMwebRec(true))}
                    className='flex items-stretch w-[100%]'
                >
                    <div className={classes.btnLeftPart}>
                        <img
                            src={arrowDown}
                            alt={`ArrowLeft-icon`}
                            className=''
                        />
                        <div className={classes.btnFontText} style={{color: true ? "gray" : "white"}}>Receive Payment
                        </div>
                    </div>
                    <div className={classes.btnRightPart}>
                        <img
                            src={ArrowUp}
                            alt={`ArrowLeft-icon`}
                            className=''
                        />
                    </div>
                </button>
            </div>
        </div>
    )
}
export default ReceivablesDraft;