// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ReportTableLayout_table__9p2DH {
  width: 100%;
  background-color: white;
  border-radius: 1.2rem;
  padding: 2.4rem;
  border-spacing: 0;
  box-shadow: 0px 0.4rem 2rem rgba(76, 87, 125, 0.02);
}

.ReportTableLayout_tableFxPage__mIF2k {
  width: 100%;
  background-color: white;
  border-radius: 1.2rem;
  padding: 2.4rem;
  border-spacing: 0;
  box-shadow: 0px 0.4rem 2rem rgba(76, 87, 125, 0.02);
}`, "",{"version":3,"sources":["webpack://./src/Components/ReportTable/ReportTableLayout.module.scss"],"names":[],"mappings":"AAEA;EACI,WAAA;EACA,uBAAA;EACA,qBAAA;EAEA,eAAA;EACA,iBAAA;EACA,mDAAA;AAFJ;;AAKA;EACI,WAAA;EACA,uBAAA;EACA,qBAAA;EACA,eAAA;EACA,iBAAA;EACA,mDAAA;AAFJ","sourcesContent":["@import \"../../mixins.scss\";\n\n.table{\n    width: 100%;\n    background-color: white;\n    border-radius: 1.2rem;\n//   border-radius: 0rem 0rem 1.2rem 1.2rem;\n    padding: 2.4rem;\n    border-spacing: 0;\n    box-shadow: 0px 0.4rem 2rem rgba(76, 87, 125, 0.02);\n}\n\n.tableFxPage{\n    width: 100%;\n    background-color: white;\n    border-radius: 1.2rem;\n    padding: 2.4rem;\n    border-spacing: 0;\n    box-shadow: 0px 0.4rem 2rem rgba(76, 87, 125, 0.02);\n    // margin-top: 20px;\n\n    @include sm{\n        // padding: 0rem;\n        // border-radius: 0px;\n        // margin-top: 20px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table": `ReportTableLayout_table__9p2DH`,
	"tableFxPage": `ReportTableLayout_tableFxPage__mIF2k`
};
export default ___CSS_LOADER_EXPORT___;
