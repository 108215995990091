// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Backdrop_backdrop__Hbhh2 {
  background: rgba(6, 10, 13, 0.7607843137);
  width: 100%;
  position: fixed;
  z-index: var(--level_3);
}`, "",{"version":3,"sources":["webpack://./src/Layout/Backdrop.module.scss"],"names":[],"mappings":"AAAA;EACI,yCAAA;EACA,WAAA;EACA,eAAA;EACA,uBAAA;AACJ","sourcesContent":[".backdrop{\n    background: #060a0dc2;\n    width: 100%;\n    position: fixed;\n    z-index: var(--level_3);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"backdrop": `Backdrop_backdrop__Hbhh2`
};
export default ___CSS_LOADER_EXPORT___;
