import { Outlet } from 'react-router-dom'
import fideoLogo from 'assets/Images/FideoLogo.svg'
import classes from './OtherPagesWrapper.module.scss'
import clsx from 'clsx'

const OtherPagesWrapper = () => {
  return (
    <div className="relative flex flex-col items-center min-h-screen bg-[#F4F6FA]">
      <div
        className={clsx(
          classes.admin_header,
          'hidden sticky w-full top-0 left-0 right-0 sm:h-[72px] sm:flex flex-row items-center sm:px-20 border-b border-b-[#E8EAEF] bg-[#FFFFFF]'
        )}
      >
        <img src={fideoLogo} alt="fideo-logo" className="h-[24px]" />
      </div>
      <div className={classes.admin_root}>
        <div id="backdrop-root"></div>
        <div id="modal-root"></div>
        <div className={clsx(classes.outlet, 'flex flex-col')}> 
          <Outlet />
        </div>
      </div>
    </div>
  )
}

export default OtherPagesWrapper
