import classes from 'Pages/Authentication/Login.module.scss';
import TextInput from 'Layout/TextInput';
import {
    createSearchParams,
    Form,
    Link,
    redirect,
    useActionData,
    useNavigation,
    useSearchParams,
    useSubmit
} from 'react-router-dom';
import MobileInput from 'Layout/MobileInput';
import Button from 'Layout/Button';
import {useEffect, useState, useRef} from 'react';
import PasswordInput from 'Components/Authentication/PasswordInput';
import {useDispatch, useSelector, shallowEqual} from 'react-redux';
import {LoginActions, AuthActions} from 'Store/auth-redux';
import UseApiCall from 'hooks/useApiCall';
import { apiLogin } from 'services';
import { baseUrl } from 'constants/Network';
import clsx from 'clsx';
import CryptoJS from 'crypto-js';
import { secretKey } from 'constants';
import { GlobalActions } from "Store/global-redux";
import { doEncryption } from 'constants';
import { handleDecrypt } from 'hooks/handleDecryption';

const emailValidation = [
    value => value.trim() !== '',
    value => /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value)
]

// value => /[a-zA-z0-9_\.\-]+[@][a-z]+[\.][a-z]+/.test(value),


const BASE_URL = baseUrl.api;

const Login = (props) => {

    const submit = useSubmit();
    const dispatch = useDispatch();
    const navigation = useNavigation();
    const [wrongInput, setWrongInput] = useState(false);
    const [clicked, setClicked] = useState(false);
    const [searchParam, setSearchParam] = useSearchParams();

    // const [submitLoginForm, submitting] = UseApiCall(apiLogin, (data) => console.log(data))

    let loginType = searchParam.get('mode');

    // map to state
    const emailInput = useSelector(state => state.login.emailInput);
    const isEmailValid = useSelector(state => state.login.isEmailValid);
    const passwordInput = useSelector(state => state.login.passwordInput);
    const isPasswordValid = useSelector(state => state.login.isPasswordValid);
    const isMobileValid = useSelector(state => state.login.isMobileValid);
    const mobileNumberInput = useSelector(state => state.login.mobileNumberInput);
    const mobileCodeInput = useSelector(state => state.login.mobileCodeInput);
    const showBackendError = useSelector(state => state.auth.showBackendError, shallowEqual);
    const focusRef = useRef(null);
    const noneRef = useRef(null);

    const handleFocus = () =>{
        if(focusRef.current) {
        // setInvoiceError(false)
        focusRef.current.focus()
        }
    }

    let data = useActionData();
    // useEffect(() => {
    //     if (data !== undefined) {
    //         dispatch(AuthActions.setShowBackendError(true));
    //     } else dispatch(AuthActions.setShowBackendError(false));
    // }, [dataObj, data])

    useEffect(() => {
        if (localStorage.getItem('error')) {
            dispatch(AuthActions.setShowBackendError(true));
        } else dispatch(AuthActions.setShowBackendError(false));
    }, [localStorage.getItem('error')])

    let invalidForm = !(((loginType?.toLowerCase() !== 'mobile' && isEmailValid) || (loginType?.toLowerCase() === 'mobile' && isMobileValid)) && isPasswordValid);
    const isSubmitting = navigation.state === "submitting";

    const formSubmitHandler = (event) => {
        event.preventDefault();
        setClicked(true);
        dispatch(AuthActions.setShowBackendError(false));
        dispatch(GlobalActions.setStepNumberInTour(0));
        dispatch(GlobalActions.setShowTour(false));

        if (invalidForm) {
            // handleInputError()
            handleFocus()
            setWrongInput(true);
            return;
        }
        setWrongInput(false);
        submit({
            email: emailInput,
            mobile: `${mobileCodeInput} ${mobileNumberInput}`,
            password: passwordInput
        }, {method: "POST"});
        // submitLoginForm({
        //     username: emailInput,
        //     password: passwordInput
        // })
        setWrongInput(true);
    }

    return (
        <div className={`${classes.login} ${props.className}`}>
            <div className={clsx(classes.header)}>Sign in to your account with:</div>
            <div className={classes.opt}>
                <div
                    className={`${classes.email} ${loginType?.toLowerCase() !== 'mobile' && classes.opt_active}`}
                    onClick={() => {
                        setSearchParam(createSearchParams({mode: "email"})
                        )
                    }}>
                    Email
                </div>
                <div
                    className={`${classes.mobile} ${loginType?.toLowerCase() === 'mobile' && classes.opt_active}`}
                    onClick={() => {
                        setSearchParam(createSearchParams({mode: "mobile"}))
                    }}>
                    Mobile number
                </div>
            </div>

            <Form className={classes.form} onSubmit={formSubmitHandler}>
                {loginType?.toLowerCase() !== 'mobile' ?
                    <TextInput
                        type='text'
                        text='Email Address'
                        className={classes.input}
                        errorMessage='Please enter a valid email address'
                        validation={emailValidation}
                        inputValidity={value => dispatch(LoginActions.setEmailValidity(value))}
                        setInput={(value) => {
                            setClicked(true)
                            dispatch(LoginActions.setEmailInput(value))
                        }}
                        enteredInput={emailInput}
                        hasError={wrongInput && loginType?.toLowerCase() !== 'mobile' && !isEmailValid}
                        maxLength={128}
                        name="email"
                        initiateFocus = {!isEmailValid}
                        focusRef = {focusRef}
                        noneRef = {noneRef}
                    />
                    :
                    <MobileInput
                        className={classes.input}
                        hasError={wrongInput && loginType?.toLowerCase() === 'mobile' && !isMobileValid}
                        mobileCodeInput={mobileCodeInput}
                        mobileNumberInput={mobileNumberInput}
                        setMobileNumberInput={value => dispatch(LoginActions.setMobileNumberInput(value))}
                        setMobileValidity={value => dispatch(LoginActions.setMobileValidity(value))}
                        setMobileCodeInput={value => dispatch(LoginActions.setMobileCodeInput(value))}
                        initiateFocus = {!isMobileValid}
                        focusRef = {focusRef}
                        noneRef = {noneRef}
                    />
                }
                <PasswordInput
                    className={classes.password}
                    text={'Password'}
                    inputValidity={value => dispatch(LoginActions.setPasswordValidity(value))}
                    setInput={value => {
                        setClicked(true)
                        dispatch(LoginActions.setPasswordInput(value))
                    }}
                    errorMessage='Please enter a valid password'
                    isValid={true}
                    validation={[value => value !== '']}
                    enteredInput={passwordInput}
                    hasError={wrongInput && !isPasswordValid}
                    maxLength={30}
                    name="password"
                    initiateFocus = {(isEmailValid||isMobileValid) && !isPasswordValid}
                    focusRef = {focusRef}
                    noneRef = {noneRef}
                />
                <Link to={`/forgot-password${isEmailValid? `?email=${emailInput}`: ''}`} className={`${classes.link} ${classes.forget_password}`}>Forgot
                    Password?</Link>

                <Button
                    text={isSubmitting ? 'Signing in...' : 'Sign in'}
                    disabled={isSubmitting}
                    className={`
                        ${classes.btn}
                        ${(isSubmitting || (clicked && (!isPasswordValid || !isEmailValid))) ? classes.disabled : classes.abled}
                    `}
                />
            </Form>
            <div className={classes.footer}>Don’t have an account? <Link to='/sign-up' className={classes.link}>Sign
                up</Link></div>
        </div>
    )
}
export default Login;

export async function action({request, params}) {
    const data = await request.formData();

    const encryptValue = (value) => {
      return CryptoJS.AES.encrypt(value, secretKey).toString();
    };
  
    const loginData = {
        username: data.get("email") || data.get("mobile"),
        password : data.get("password"),
    }
    
    const encryptAndDecryptSecretKey = process.env.REACT_APP_SECRET_KEY;
    const iv = process.env.REACT_APP_IV;

    // const encryptAndDecryptSecretKey = "testFideo11sfsdf"
    // const iv = "testFideoSalt123";

    // const doEncryption = true;

    const handleEncrypt = (originalString) => {
        const encrypted = CryptoJS.AES.encrypt(
          JSON.stringify(originalString),
          CryptoJS.enc.Utf8.parse(encryptAndDecryptSecretKey),
          { iv: CryptoJS.enc.Utf8.parse(iv), mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 }
        );
        return encrypted.toString();
    };

    const response = await fetch(baseUrl.api + `/login`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        // body: JSON.stringify(loginData),
        body: doEncryption ? JSON.stringify({payload: handleEncrypt(loginData)}) : JSON.stringify(loginData),
        credentials: "include"

    });

    if (!response.ok) {
        localStorage.setItem("error", Math.floor(100000 + Math.random() * 900000))
        const res = doEncryption ? handleEncrypt(response) : response;
        return res;
    }
     else{
        localStorage.removeItem("error")
        if (doEncryption) {
            // const responseEncrypted = await response.json(); // Parse the response data as JSON
            const responseBodyText = await response.text();

            // Use your decryption function (replace handleDecrypt with your actual decryption logic)
            // const decryptedData = handleDecrypt(responseBodyText);
            // const responseData = JSON.parse(decryptedData);
            const responseData = handleDecrypt(responseBodyText);
            localStorage.setItem('username', responseData?.data?.username || '');
            localStorage.setItem('isRefresh', true);
            // localStorage.setItem('resetKey', responseData?.data?.role || '');
            if(responseData?.data?.role === "ADMIN"){
                // localStorage.setItem('resetKeyencrypt', 'malik');
                localStorage.setItem('nickName', encryptValue('malik'));
            }else
            localStorage.removeItem('nickName');
            // localStorage.setItem('username', responseData?.data || '');
            // localStorage.setItem('userRole', "USER");
            // localStorage.setItem('userRole', "ADMIN");
        } else {
            const responseData = await response.json();
            localStorage.setItem('username', responseData?.data?.username || '');
            localStorage.setItem('isRefresh', true);
            if (responseData?.data?.role === "ADMIN") {
                localStorage.setItem('nickName', encryptValue('malik'));
            } else localStorage.removeItem('nickName');

        }
    }

    // console.log('response',response)

    return redirect("/dashboard");
}