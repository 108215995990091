// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Customers_customer__ZGPcQ .Customers_button__MP1\\+t {
  display: none;
  padding: 1.2rem 1.6rem;
  background-color: white;
  width: 100%;
  position: fixed;
  top: 100%;
  left: 0;
  transform: translateY(-100%);
}
.Customers_customer__ZGPcQ .Customers_button__MP1\\+t .Customers_btn__JRkX5:not(:last-child) {
  margin-right: 1.2rem;
}
.Customers_customer__ZGPcQ .Customers_button__MP1\\+t .Customers_btn__JRkX5 {
  flex: 1 1;
}
@media (max-width: 600px) {
  .Customers_customer__ZGPcQ .Customers_button__MP1\\+t {
    display: flex;
  }
}`, "",{"version":3,"sources":["webpack://./src/Pages/Admin/Customers.module.scss"],"names":[],"mappings":"AACI;EAEI,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,WAAA;EACA,eAAA;EACA,SAAA;EACA,OAAA;EACA,4BAAA;AADR;AAGQ;EACI,oBAAA;AADZ;AAIQ;EACI,SAAA;AAFZ;AAKQ;EAnBJ;IAoBQ,aAAA;EAFV;AACF","sourcesContent":[".customer{\n    .button{\n        \n        display: none;\n        padding: 1.2rem 1.6rem;\n        background-color: white;\n        width: 100%;\n        position: fixed;\n        top: 100%;\n        left: 0;\n        transform: translateY(-100%);\n        \n        .btn:not(:last-child){\n            margin-right: 1.2rem;\n        }\n    \n        .btn{\n            flex: 1;\n        }\n    \n        @media (max-width: 600px) {\n            display: flex;\n    \n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"customer": `Customers_customer__ZGPcQ`,
	"button": `Customers_button__MP1+t`,
	"btn": `Customers_btn__JRkX5`
};
export default ___CSS_LOADER_EXPORT___;
