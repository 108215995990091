import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop() {
  const { pathname } = useLocation();
  
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
        section.scrollIntoView({ block: 'start', behavior: 'auto' });
      }
  };

  useEffect(() => {
    scrollToSection('come_above');
  }, [pathname])

  return <div id="come_above" style={{ scrollMargin: "343px" }}></div>
}