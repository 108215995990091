import InvoicesTable from './InvoicesTable'
import UseApiCall from 'hooks/useApiCall'
import { apiGetCustomerInvoices, apiGetReceivables } from 'services'
import { ReceivablesActions } from 'Store/customer-redux'
import { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import NoInvoicesView from './NoInvoicesView'
import { useSelector } from "react-redux";

const InvoicesTabContent = (props) => {
  const [customerInvoiceList, setCustomerInvoiceList] = useState(null)
  const [loading, setLoading] = useState(true)
  const [page, setPage] = useState(0)
  const updateCustomersModalValue = useSelector((state) => state.customer.updateCustomers);
  const [getCustomerInvoices] = UseApiCall(apiGetCustomerInvoices, (res) => {
    // const customerInvoiceList = res?.data?.invoice_list.filter((invoice) => {
    //   return invoice.customer.emailAddress === props.details.email_address
    // })
    setCustomerInvoiceList(res?.data?.invoice_list)
    ReceivablesActions.getReceivabelReports(res?.data?.invoice_list)
  })

  const showInvoiceDisplay = useSelector(
    (state) => state.modal.showInvoiceDisplay
  )

  useEffect(() => {
    // const invoiceStatus = props?.details?.verification_status
    // const toDate = dayjs().add(1, 'day').format('YYYY-MM-DD')
    const params = `customerId:${props.customerId}`

    if (!showInvoiceDisplay)
      getCustomerInvoices({
        searchString: params,
        page: page,
      })
    setLoading(false)
  }, [page, showInvoiceDisplay, updateCustomersModalValue])

  const handlePaginate = (page) => {
    setPage(page)
  }

  const tableHeader = [
    // 'created on', 'invoice id', 'amount', 'status',
    {
      id:1,
      title:"created on",
      width : 6,
      minWidth:80,
    },
    {
      id:2,
      title:"invoice id",
      width: 6,
      minWidth:80,
    },
    {
      id:3,
      title:"amount",
      width : 6,
      minWidth:100,
    },
    {
      id:4,
      title:"status",
      width: 6,
      minWidth:80,
    },
    {
      id:5,
      title:"ESTD. Savings",
      width: 6,
      minWidth:100,
    },
    {
      id:6,
      title:"Action",
      width: 3,
      minWidth:20,
    },
  ]

  // console.log('customerId',props.customerId)
  // console.log('propssummary',props.summary)

  return (
    <>
      {!loading ? (
        <InvoicesTable
          tableData={customerInvoiceList}
          tableHeader={tableHeader}
          mobileTableData={customerInvoiceList}
          handlePaginate={handlePaginate}
          // pages={props?.summary?.SETTLED?.count + props?.summary?.OUTSTANDING?.count}
          pages={props?.summary?.CREATED?.count}
        />
      ) : (
        <NoInvoicesView />
      )}
    </>
  )
}
export default InvoicesTabContent
