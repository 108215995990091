// import RoutesUrls from '../Router/RouteUrls';
// import UploadIcon from '../../asset/upload.svg';
// import ListIcon from '../../asset/list.svg';
// import ConfigIcon from '../../asset/config.svg';

// export const DRAWER_MENU = Object.freeze([
//   {
//     name: 'Contest',
//     icon: ListIcon,
//     pageRoute: RoutesUrls.Contest,
//   },
//   {
//     name: 'App Data',
//     icon: UploadIcon,
//     pageRoute: RoutesUrls.AppData,
//   },
//   {
//     name: 'App Configs',
//     icon: ConfigIcon,
//     pageRoute: RoutesUrls.AppConfig,
//   },
// ]);

export const TOKEN = 'TOKEN';
export const REFRESH_TOKEN = 'REFRESH_TOKEN';

export const DEFAULT_ROW_PER_PAGE = 10;
export const DEFAULT_STAGE_PER_LOAD = 50;
export const DEFAULT_USER_STREAM_PER_LOAD = 20;
export const secretKey="jbcdsk4653$%1wd@&*@D(SX!@32490)";
export const doEncryption = process.env.REACT_APP_DO_DECRYPTION === "true" ? true : false;
