import Shimmer from 'Layout/Shimmer';
import classes from './MobileComplianceTable.module.scss'
import ReportTableLayout from 'Components/ReportTable/ReportTableLayout'
import clsx from 'clsx'
import dayjs from 'dayjs'
import { modalActions } from 'Store/modal-redux';
import Action from './Action';
import PaymentStatusTag from './PaymentStatusTag';
import {Pagination, Tooltip} from 'antd';
import { useDispatch, useSelector} from 'react-redux';
import ReactDOM from 'react-dom'
import { useState } from 'react';

const MobileComplianceTable = (props) => {
    function abbreviateString(str) {
        if (str.length > 20) {
          return str.substr(0, 15) + '...';
        }
        return str;
    }

    if (props.tableData?.length > 0)
    return (
        <>
            <ReportTableLayout className={`${classes.table} ${props.className}`}>
                {props?.tableData?.map((data, index) => {
                    return (
                        <div key={`${data?.email}-${index}`} className={classes.row}>
                            <div
                                className={classes.infoTwo}
                            >
                                <div className={clsx(classes.amountText)} style={{
                                    maxWidth: "100%",
                                    overflowWrap: "anywhere",
                                    color: "#1e333fc7",
                                    fontWeight: "400"
                                }}>
                                    {data?.customerDetails ? data?.customerDetails : '--'}
                                </div>
                                {/* <div>
                                <Action/>
                            </div> */}
                            </div>
                            <div className={classes.infoOne}>
                                <div
                                    className={clsx(classes.invoice, 'flex items-center gap-[8px]')}
                                >
                                    <div
                                        className={clsx(classes.amountText, '!text-[#1E333F50]')}
                                    >
                                        Search Date
                                    </div>
                                </div>
                                <div className={clsx(classes.amountText, 'flex gap-[5px]')}
                                     style={{maxWidth: "125px", overflowWrap: "anywhere"}}>
                                    {data?.searchDate ? dayjs(data?.searchDate).local().format('DD MMM YYYY') : '--'}
                                    {/* {data?.searchDate ? dayjs(data?.searchDate).local().format('hh:mm A')  : '--'} */}
                                </div>
                            </div>
                            <div
                                className={classes.infoTwo}
                            >
                                <div className={clsx(classes.amountText, '!text-[#1E333F50]')}>Match Status</div>
                                <div className={clsx(classes.amountText)}
                                     style={{maxWidth: "125px", overflowWrap: "anywhere"}}>
                                    {data?.matchStatus ?
                                        <PaymentStatusTag status={data?.matchStatus} type="light"/> : "--"}
                                </div>
                            </div>
                            <div className={classes.infoTwo}>
                                <div className={clsx(classes.amountText, '!text-[#1E333F50]')}>Risk Level</div>
                                <div className={clsx(classes.amountText)}
                                     style={{maxWidth: "125px", overflowWrap: "anywhere"}}>
                                    {data?.riskLevel ? data?.riskLevel : "--"}
                                </div>
                            </div>
                            <div className={classes.infoTwo}>
                                <div className={clsx(classes.amountText, '!text-[#1E333F50]')}>Status</div>
                                <div className={clsx(classes.amountText)}
                                     style={{maxWidth: "125px", overflowWrap: "anywhere"}}>
                                    {data?.status ? <PaymentStatusTag status={data?.status} type="light"/> : "--"}
                                </div>
                            </div>
                            <div className={classes.infoTwo}>
                                <div className={clsx(classes.amountText, '!text-[#1E333F50]')}>Remarks</div>
                                <div className={clsx(classes.amountText)}
                                     style={{maxWidth: "125px", overflowWrap: "anywhere"}}>
                                    {data?.remarks?.length > 20 ?
                                        <Tooltip
                                            title={<div>{data?.remarks}</div>}
                                            placement="bottomRight"
                                        >
                                            {abbreviateString(data?.remarks)}
                                        </Tooltip>
                                        :
                                        <div>{data?.remarks ? data?.remarks : "--"}</div>
                                    }
                                </div>
                            </div>

                            <div className={classes.infoTwo}>
                                <div className={clsx(classes.amountText, '!text-[#1E333F50]')}>Action</div>
                                <div className="flex flex-col gap-[4px]">
                                    <button
                                        className="p-[4px] text-[#fff] bg-black rounded-[4px] cursor-pointer"
                                        onClick={() => props.handleCustomerSelection(data)}
                                    >
                                        Details
                                    </button>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </ReportTableLayout>
            {
                props?.tableData?.length > 0 &&
                <div className={classes.paginationWrap}>
                    <Pagination
                        showSizeChanger={false}
                        current={props?.currentPage + 1}
                        total={props?.resData?.totalElements}
                        pageSize={props?.pageSize}
                        // defaultPageSize={30}
                        itemRender={props?.itemRender}
                        onChange={props?.handlePaginate}
                    />
                </div>
            }
        </>
    )
    if (props.tableData?.length === 0)
        return (
            <div
                style={{
                    textAlign: 'center',
                    width: '100%',
                    marginTop: '4rem',
                    fontSize: '2.5rem'
                }}
                className={classes.table}
            >
                No Payouts Yet.
        </div>
    )
  return (
    <div
      style={{
        textAlign: 'center',
        width: '100%',
        marginTop: '4rem',
      }}
    >
        <>
            <Shimmer className="w-[100%] mb-[10px] h-[100px] rounded-[12px]"/>
            <Shimmer className="w-[100%] mb-[10px] h-[100px] rounded-[12px]"/>
            <Shimmer className="w-[100%] h-[100px] rounded-[12px]"/>
        </>
    </div>
  )
}

export default MobileComplianceTable