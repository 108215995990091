import { useDispatch } from 'react-redux';
import Close from 'Layout/Close';
import Modal from 'Layout/Modal';
import { modalActions } from 'Store/modal-redux';
import classes from 'Components/Payment/AddPaymentDetail.module.scss';
import ReactDOM from 'react-dom';
import Backdrop from 'Layout/Backdrop';

const AddPaymentDetail = () => {

    const dispatch = useDispatch();

    return (
        <Modal className={classes.modal}>
            {ReactDOM.createPortal(
                <Backdrop onClick={() => {dispatch(modalActions.addPaymentDetail())}} className={classes.backdrop}/>, 
                document.getElementById('backdrop-root')
            )}
            <div className={classes.add_payment_detail}>
                Hi, I am on add payment detail page.
                <Close onClick={() => {dispatch(modalActions.addPaymentDetail())}}/>
                
            </div>
        </Modal>
    )
}
export default AddPaymentDetail;