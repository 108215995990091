import classes from  "./CreateInvoice.module.scss"
import {Dropdown} from "antd";
import {useEffect, useRef, useState} from "react";
import clsx from "clsx";
import chevronUp from "assets/Icons/chevron-upNew.svg";
import chevronDown from "assets/Icons/chevron-downNew.svg";
import deleteIcon from "assets/Icons/deleteNew.svg";
import addLineImg from "assets/Icons/addLine.svg";
import minusIcon from "assets/Icons/minusIcon.svg";
import TextInput from "../../Layout/TextInput";
import TextInputCurrency from "./TextInputCurrency";
import ReportTableLayout from "../ReportTable/ReportTableLayout";
const ItemsPrice = (props) => {
    const {
        itemType,
        setItemType,
        curr,
        setCurr,
        tax,
        setTax,
        subTotal,
        setSubTotal,
        discount,
        setDiscount,
        taxValue,
        setTaxValue,
        fobShipment,
        setFobShipment,
        grandTotal,
        setGrandTotal,
        taxName,
        setTaxName,
        taxDescription,
        setTaxDescription,
        lineItems,
        setLineItems,
        handleCount,
        currencyCodeSign,
        setCurrencyCodeSign,
        customerCurrencies,
    } = props;
    // const [hsn, setHsn] = useState('HSN');
    const [activeTabHsn, setActiveTabHsn] = useState("HSN");
    // const [curr, setCurr] = useState('USD');
    // const [currencyCodeSign, setCurrencyCodeSign] = useState('$')
    const [activeTabCurr, setActiveTabCurr] = useState("USD");
    // const [tax, setTax] = useState('VAT');
    const [activeTabTax, setActiveTabTax] = useState("VAT");
    // let [addLineNum, setAddLineNum] = useState(1);
    // const [addLine, setAddLine] = useState([addLineNum]);
    const [isDiscount, setIsDiscount] = useState(false);
    const [isFOB, setIsFOB] = useState(false);
    const focusRef = useRef(null);
    const noneRef = useRef(null);

    const theadRef = useRef(null);
    const tbodyRef = useRef(null);

    // const [subTotal, setSubTotal] = useState("");
    // const [discount, setDiscount] = useState("");
    // const [taxValue, setTaxValue] = useState("");
    // const [fobShipment, setFobShipment] = useState("");
    // const [grandTotal, setGrandTotal] = useState("");
    // const [taxName, setTaxName] = useState('');
    // const [taxDescription, setTaxDescription] = useState('');
    //
    // const [lineItems, setLineItems] = useState(
    //     [
    //         {
    //             description: "",
    //             hsn: "",
    //             qty: "",
    //             price: "",
    //             total: 0
    //         }
    //     ]
    // );

    const calculateSubTotal = () => {
        let total = 0;
        lineItems.forEach(item => {
            total += parseFloat(item.total || 0);
        });
        setSubTotal(total.toFixed(2));
    };

    const calculateGrandTotal = () => {
        const total = parseFloat(subTotal || 0) - parseFloat(discount || 0) + parseFloat(taxValue || 0) + parseFloat(fobShipment || 0);
        setGrandTotal(total.toFixed(2));
    };

    useEffect(() => {
        calculateSubTotal();
    }, [lineItems]);

    useEffect(() => {
        calculateGrandTotal();
    }, [subTotal, discount, taxValue, fobShipment]);

    console.log('itemType',itemType)

    const handleInputChange = (index, field, value) => {
        const updatedItems = [...lineItems];
        updatedItems[index][field] = value;

        // Calculate total
        if (field === 'quantity' || field === 'price') {
            const qty = parseFloat(updatedItems[index].quantity || 0);
            const price = parseFloat(updatedItems[index].price || 0);
            updatedItems[index].total = (qty * price).toFixed(2);
        }

        setLineItems(updatedItems);
    };


    const handleAddLine = () => {
        setLineItems(prevItems => [...prevItems, { itemName: "", itemType:itemType, itemTypeCode: "", quantity: "", price: "", total: "" }]);
    };

    const handleDeleteLine = (index) => {
        setLineItems(prevItems => prevItems.filter((_, i) => i !== index));
    };

    const itemsHsn = [
        {
            key: 'HSN',
            label: <div className='text-[16px] font-inter'>HSN</div>,
        },
        {
            key: 'SAC',
            label: <div className='text-[16px] font-inter '>SAC</div>,
        },
    ];
    const handleDropdownHsnClick = (e) => {

        const updatedItems = lineItems.map((item) => {
            // Return a new object with updated itemType value
            return { ...item, itemType: e.key };
        });

        // Update the state with the new array of items
        setLineItems(updatedItems);

        // console.log(e.key);
        setItemType(e.key)
        // setHsn(e.key);
        setActiveTabHsn(e.key)
    };

    // console.log('lineItems',lineItems)

    const itemsCurr = customerCurrencies?.supportedCurrency?.map((item) => {
        return {
            key: item?.toUpperCase(),
            label: <div className='text-[16px] font-inter'>{item?.toUpperCase()}</div>,
        }
    })

    // console.log('itemsCurr',itemsCurr)

    // const itemsCurr = [
    //     {
    //         key: 'USD',
    //         label: <div className='text-[16px] font-inter'>USD</div>,
    //     },
    //     {
    //         key: 'EUR',
    //         label: <div className='text-[16px] font-inter '>EUR</div>,
    //     },
    //     {
    //         key: 'CAD',
    //         label: <div className='text-[16px] font-inter '>CAD</div>,
    //     },
    //     {
    //         key: 'GBP',
    //         label: <div className='text-[16px] font-inter '>GBP</div>,
    //     },
    //     {
    //         key: 'AED',
    //         label: <div className='text-[16px] font-inter '>AED</div>,
    //     },
    // ];

    const handleDropdownCurrClick = (e) => {
        // console.log('e.key',e.key);
        setCurr(e.key);
        setActiveTabCurr(e.key)
        handleCount(e.key);
    };

    const itemsTax = [
        {
            key: 'VAT',
            label: <div className='text-[16px] font-inter'>VAT</div>,
        },
        {
            key: 'GST',
            label: <div className='text-[16px] font-inter '>GST</div>,
        },
        {
            key: 'Other',
            label: <div className='text-[16px] font-inter '>Other</div>,
        },
    ];
    const handleDropdownTaxClick = (e) => {
        // console.log(e.key);
        setTaxName(null)
        setTax(e.key);
        setActiveTabTax(e.key);
    };

    const handleDiscount = () => {
        setIsDiscount(!isDiscount)
    }

    const handleFOB = () => {
        setIsFOB(!isFOB)
    }

    useEffect(() => {
        const handleScroll = (event) => {
            if (event.target.id === 'thead') {
                tbodyRef.current.scrollLeft = theadRef.current.scrollLeft;
            }
            else if (event.target.id === 'tbody') {
                theadRef.current.scrollLeft = tbodyRef.current.scrollLeft;
            }
        };

        const thead = theadRef.current;
        const tbody = tbodyRef.current;

        thead.addEventListener('scroll', handleScroll);
        tbody.addEventListener('scroll', handleScroll);

        return () => {
            thead.removeEventListener('scroll', handleScroll);
            tbody.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const tableHeaderData = [
        {
            header:'Item Name',
            maxWidth:240,
            width:6,
        },
        {
            header:'ItemCode',
            maxWidth:80,
            width:2,
        },
        {
            header:'Qty',
            maxWidth:60,
            width:1,
        },
        {
            header:'Price / Unit',
            maxWidth:90,
            width:4,
        },
        {
            header:'Total',
            maxWidth:90,
            width:4,
        },
        {
            header:'Delete',
            maxWidth:50,
            width:1,
        },
    ]


    // console.log('focusRef',focusRef)
    // console.log('lineItems',lineItems)

    return(
        <>
            <div className={classes.ItemsPriceCon}>
                <div className={classes.currencyType}>
                    <div className={classes.hsn}>
                        <p className={classes.hsnText}>Item Type:</p>
                        <Dropdown
                            menu={{
                                onClick: handleDropdownHsnClick,
                                items: itemsHsn,
                            }}
                            placement="bottom"
                            overlayStyle={{width: "100px", fontSize: "16px !important"}}
                            // open={true}
                        >
                            <div
                                className={clsx((activeTabHsn === itemType) ? classes.activeTabText :
                                    classes.inActiveTabText, 'flex w-[fit-content] items-center justify-center gap-[15px] px-[4px] py[8px] bg-[#F4F4F4] rounded-[4px]')}>
                                <>{itemType}</>
                                <img src={chevronDown} className="w-[20px] h-[20px]"/>
                            </div>
                        </Dropdown>
                    </div>

                    <div className={classes.hsn}>
                        <p className={classes.hsnText}>Currency</p>
                        <Dropdown
                            menu={{
                                onClick: handleDropdownCurrClick,
                                items: itemsCurr,
                            }}
                            placement="bottom"
                            overlayStyle={{width:"100px", height:"250px", overflow:"scroll" , fontSize:"16px !important" }}
                            // open={true}
                        >
                            <div
                                className={clsx((activeTabCurr === curr) ? classes.activeTabText :
                                    classes.inActiveTabText, 'flex w-[fit-content] items-center justify-center gap-[15px] bg-[#F4F4F4] px-[4px] py[8px] rounded-[4px]')}>
                                <>{curr}</>
                                <img src={chevronDown} className="w-[20px] h-[20px]"/>
                            </div>
                        </Dropdown>
                    </div>
                </div>

                <div className={classes.allWrap}>
                    <div className={clsx(`${classes.table} ${classes.reportTableWrapTop}`)} ref={theadRef}>
                        <thead>
                        {tableHeaderData.map((header, index) => (
                            <th key={`${header}-${index}`} className={classes.cell_header1}
                                style={{
                                    width: `${header.width}%`,
                                    minWidth: `${header.maxWidth}px`,
                                    maxWidth: `${header.maxWidth}px`
                                }}
                                valign="top"
                            >
                                <div className={classes.cell_header2}>
                                    <div className={classes.head}>
                                        {header.header === "ItemCode" ? itemType  : header.header}
                                    </div>
                                </div>
                            </th>
                        ))}
                        </thead>
                    </div>

                    <div className={`${classes.reportTableWrap}`} id="tbody" ref={tbodyRef}>
                        <div className={`${classes.table} !pt-[0px]`}>
                            <tbody>
                            {
                                lineItems?.map((item, index) => (
                                    <tr key={index} className={classes.row} style={{marginTop: index === 1 ? "20px" : ""}}>
                                        <td className={classes.cell}
                                            style={{
                                                width: `${tableHeaderData[0].width}%`,
                                                minWidth: `${tableHeaderData[0].maxWidth}px`,
                                                maxWidth: `${tableHeaderData[0].maxWidth}px`
                                            }}
                                            valign="top"
                                        >
                                            <TextInput
                                                type="text"
                                                text=""
                                                // className="w-[324px] min-w-[324px]"
                                                setInput={(value) => handleInputChange(index, 'itemName', value)}
                                                enteredInput={item.itemName}
                                                focusRef={focusRef}
                                                noneRef={noneRef}
                                            />
                                        </td>
                                        <td className={classes.cell}
                                            style={{
                                                width: `${tableHeaderData[1].width}%`,
                                                minWidth: `${tableHeaderData[1].maxWidth}px`,
                                                maxWidth: `${tableHeaderData[1].maxWidth}px`
                                            }}
                                            valign="top"
                                        >
                                            <TextInput
                                                type="text"
                                                text=""
                                                // className="w-[120px] min-w-[120px]"
                                                placeholder="Optional"
                                                setInput={(value) => handleInputChange(index, 'itemTypeCode', value)}
                                                enteredInput={item.itemTypeCode}
                                            />
                                        </td>
                                        <td className={classes.cell}
                                            style={{
                                                width: `${tableHeaderData[2].width}%`,
                                                minWidth: `${tableHeaderData[2].maxWidth}px`,
                                                maxWidth: `${tableHeaderData[2].maxWidth}px`
                                            }}
                                            valign="top"
                                        >
                                            <TextInput
                                                type="number"
                                                text=""
                                                // className="w-[120px] min-w-[120px]"
                                                setInput={(value) => handleInputChange(index, 'quantity', value)}
                                                enteredInput={item.quantity}
                                            />
                                        </td>
                                        <td className={classes.cell}
                                            style={{
                                                width: `${tableHeaderData[3].width}%`,
                                                minWidth: `${tableHeaderData[3].maxWidth}px`,
                                                maxWidth: `${tableHeaderData[3].maxWidth}px`
                                            }}
                                            valign="top"
                                        >
                                            <TextInputCurrency
                                                type="text"
                                                text=""
                                                // className="w-[140px] min-w-[140px]"
                                                setInput={(value) => handleInputChange(index, 'price', value)}
                                                enteredInput={item.price}
                                                currency={currencyCodeSign}
                                            />
                                        </td>
                                        <td className={classes.cell}
                                            style={{
                                                width: `${tableHeaderData[4].width}%`,
                                                minWidth: `${tableHeaderData[4].maxWidth}px`,
                                                maxWidth: `${tableHeaderData[4].maxWidth}px`
                                            }}
                                            valign="top"
                                        >
                                            <TextInputCurrency
                                                type="text"
                                                text=""
                                                // className="w-[200px] min-w-[200px]"
                                                // setInput={(value) => handleInputChange(index, 'total', value)}
                                                enteredInput={item.total}
                                                currency={currencyCodeSign}
                                                disabled={true}
                                            />
                                        </td>
                                        <td className={classes.cell}
                                            style={{
                                                width: `${tableHeaderData[5].width}%`,
                                                minWidth: `${tableHeaderData[5].maxWidth}px`,
                                                maxWidth: `${tableHeaderData[5].maxWidth}px`
                                            }}
                                            valign="top"
                                        >
                                            <div className={classes.deleteBtn}
                                                 onClick={() => handleDeleteLine(index)}>
                                                <img src={deleteIcon}/>
                                            </div>
                                        </td>

                                    </tr>
                                ))
                            }

                            <div className={classes.addLine} onClick={handleAddLine} style={{marginBottom:"20px", marginLeft:"10px" , marginRight:"10px"}}>
                                <img src={addLineImg} className='h-[24px] w-[24px]'/>
                                <p className={classes.addText}>Add a line item</p>
                            </div>
                            </tbody>
                        </div>
                    </div>
                </div>

                {/*subtotal*/}
                <div className='flex justify-between'>
                    <div></div>
                    <div className={classes.subTotalCon}>
                        {/*subTotal*/}
                        <div className={classes.subTotal}>
                            <p className={classes.subText}>Subtotal</p>
                            <p className={classes.subPrice}>{`${currencyCodeSign} ${subTotal}`}</p>
                        </div>

                        <div className={classes.hrLine}></div>

                        {/*discount*/}
                        <div className={classes.subTotal}>
                            <p className={classes.subText}>Discount</p>

                            {(isDiscount || discount) ? (
                                <div className='flex gap-[8px] items-center '>
                                    <div className={classes.minusIcon}>
                                        <img src={minusIcon}/>
                                    </div>

                                    <TextInputCurrency
                                        type="number"
                                        text=""
                                        className="w-[115px] min-w-[115px]"
                                        setInput={(value) => {
                                            setDiscount(value)
                                        }}
                                        enteredInput={discount}
                                        currency={currencyCodeSign}
                                    />
                                </div>
                            ) : (
                                <div className={classes.discount} onClick={handleDiscount}>
                                    <p className={classes.subText}>+Add</p>
                                </div>
                            )}
                        </div>


                        <div className={classes.hrLine}></div>

                        {/*tax*/}
                        <div className={classes.subTotal}>
                            <div className='flex flex-col gap-[12px] w-[100%]'>
                                <div className='flex items-center justify-between w-[100%]'>
                                    <p className={classes.subText}>Tax</p>
                                    <div className='flex '>
                                        <Dropdown
                                            menu={{
                                                onClick: handleDropdownTaxClick,
                                                items: itemsTax,
                                            }}
                                            placement="bottom"
                                            overlayStyle={{width: "140px", fontSize: "16px !important"}}
                                            // open={true}
                                        >
                                            <div
                                                className={clsx((activeTabTax === "VAT" || activeTabTax === "GST") ? classes.activeTabText :
                                                    classes.inActiveTabText, 'flex items-center mr-[10px] gap-[15px] bg-[#F4F4F4] px-[10px] py[8px] rounded-[8px]')}>
                                                <>{tax}</>
                                                <img src={chevronDown} className="w-[20px] h-[20px]"/>
                                            </div>
                                        </Dropdown>
                                        <TextInputCurrency
                                            type="number"
                                            text=""
                                            className="w-[115px] min-w-[115px]"
                                            setInput={(value) => {
                                                setTaxValue(value)
                                            }}
                                            enteredInput={taxValue}
                                            currency={currencyCodeSign}
                                        />
                                    </div>
                                </div>
                                <div className='flex flex-col gap-[14px]'>
                                    {/*tax name*/}
                                    {(tax === "Other") &&
                                        <TextInput
                                            type="text"
                                            text="Tax Name"
                                            placeholder="Tax Name"
                                            // className="w-[324px] min-w-[324px]"
                                            setInput={(value) => {
                                                setTaxName(value)
                                            }}
                                            enteredInput={taxName}
                                        />
                                    }
                                    {/*tax description*/}
                                    <TextInput
                                        type="text"
                                        // text={(taxDescription !== null || taxDescription !== undefined) ? "Tax Description (optional)" : " " }
                                        text="Tax Description (optional)"
                                        placeholder="Tax Description (optional)"
                                        // className="w-[324px] min-w-[324px]"
                                        setInput={(value) => {
                                            setTaxDescription(value)
                                        }}
                                        enteredInput={taxDescription}
                                        focusRef={focusRef}
                                        noneRef={noneRef}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className={classes.hrLine}></div>

                        {/*fob/shipment*/}
                        <div className={classes.subTotal}>
                            <p className={classes.subText}>Freight Charges</p>

                            {(isFOB || fobShipment) ? (
                                <div className='flex gap-[8px] items-center '>
                                    {/*<div className={classes.minusIcon}>*/}
                                    {/*    <img src={minusIcon}/>*/}
                                    {/*</div>*/}

                                    <TextInputCurrency
                                        type="number"
                                        text=""
                                        className="w-[115px] min-w-[115px]"
                                        setInput={(value) => {
                                            setFobShipment(value)
                                        }}
                                        enteredInput={fobShipment}
                                        currency={currencyCodeSign}
                                    />
                                </div>
                            ) : (
                                <div className={classes.discount} onClick={handleFOB}>
                                    <p className={classes.subText}>+Add</p>
                                </div>
                            )}
                        </div>

                        <div className={classes.hrLine}></div>

                        {/*grandTotal*/}
                        <div className={classes.subTotal}>
                            <p className={classes.subText}>Grand Total</p>
                            <p className={classes.grandPrice}>{`${currencyCodeSign} ${grandTotal}`}</p>
                        </div>
                    </div>
                </div>

                {/*save and continue*/}
                {/*<div className={classes.currencyType}>*/}
                {/*    <div className={classes.save} onClick={()=>props?.handleSaveAndcontinue("3")}>*/}
                {/*        <p className={classes.saveText}>Save & Continue</p>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>
        </>
    )
}

export default ItemsPrice;