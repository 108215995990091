import React from 'react';
import classes from "./Profile.module.scss";
import ContentContainer from './profileComponents/ContentContainer';
import Header from './profileComponents/Header';
import ContentWrapper from './profileComponents/ContentWrapper';
import ContentBoxKyc from './profileComponents/ContentBoxKyc';
import KycHeader from './profileComponents/KycHeader';
import KycContentWrapper from './profileComponents/KycContentWrapper';
import KycContent from './profileComponents/KycContent';
import ContentWrapperKyc from './profileComponents/ContentWrapperKyc';
import ContentContainerKyc from './profileComponents/ContentContainerKyc';
import GrayBox from './profileComponents/GrayBox';
import KycContentNot from './profileComponents/KycContentNot';

const KYCDetails = ({businessDetails}) => {
    const {company_details, company_identification_details, company_business_director_details, company_business_owner_details, company_account_user_details} = businessDetails || {};
    const {cin_urls, company_pan_urls, gstin_urls, llpin_urls} = company_identification_details || {};
    let alphabet = 65;

  return (
    <div className={`${classes.kycDetails}`}>
        <ContentContainerKyc>
            <Header text1={'Company identifiers'} text2={''}/>
            <ContentWrapperKyc>
                <ContentBoxKyc>
                    <KycHeader text1={'A'} text2={'CIN/ LLPIN - Certificate of Incorporation/ Registration'} />
                    {(cin_urls?.length !== 0 && llpin_urls?.length !== 0) &&
                    (<KycContentWrapper>
                                    {cin_urls?.length !== 0 && (
                                    <>
                                        {cin_urls?.map((url, index) => (
                                        <React.Fragment key={index}>
                                            <KycContent text1={'Certificate of Incorporation.'} text2={'10 KB'} url={url} />
                                            {cin_urls?.length !== index + 1 && <GrayBox />}
                                        </React.Fragment>
                                        ))}
                                    </>
                                    )}

                                    {llpin_urls && <GrayBox/> }

                                    {llpin_urls?.length !== 0 && (
                                    <>
                                        {llpin_urls?.map((url, index) => (
                                        <React.Fragment key={index}>
                                            <KycContent text1={'LLPIN.'} text2={'10 KB'} url={url} />
                                            {llpin_urls?.length !== index + 1 && <GrayBox />}
                                        </React.Fragment>
                                        ))}
                                    </>
                                    )}
                    </KycContentWrapper>)}
                    {(llpin_urls?.length === 0 || cin_urls?.length === 0) && <KycContentNot/>}
                </ContentBoxKyc>
                <ContentBoxKyc>
                    <KycHeader text1={'B'} text2={'GSTIN - GST Registration'} />
                                    {gstin_urls?.length !== 0 && (
                                    <KycContentWrapper>
                                        {gstin_urls?.map((url, index) => (
                                        <React.Fragment key={index}>
                                            <KycContent text1={'GSTIN.'} text2={'10 KB'} url={url} />
                                            {gstin_urls?.length !== index + 1 && <GrayBox />}
                                        </React.Fragment>
                                        ))}
                                    </KycContentWrapper>)}
                    {gstin_urls?.length === 0 && <KycContentNot/>}
                </ContentBoxKyc>
                <ContentBoxKyc>
                    <KycHeader text1={'C'} text2={'Company PAN - Copy of company PAN card'} />
                                    {company_pan_urls?.length !== 0 && (
                                    <KycContentWrapper>
                                        {company_pan_urls?.map((url, index) => (
                                        <React.Fragment key={index}>
                                            <KycContent text1={'PAN.'} text2={'10 KB'} url={url} />
                                            {company_pan_urls?.length !== index + 1 && <GrayBox />}
                                        </React.Fragment>
                                        ))}
                                    </KycContentWrapper>
                                    )}
                    {company_pan_urls?.length == 0 && <KycContentNot />}
                </ContentBoxKyc>
            </ContentWrapperKyc>
        </ContentContainerKyc>
        <ContentContainerKyc>
            <Header text1={'Director(s)'} text2={''}/>
            <ContentWrapperKyc>
                    {company_business_director_details?.map((director,index) => (
                        <ContentBoxKyc>
                            <KycHeader text1={String.fromCharCode(alphabet+index)} text2={'PAN for '} text3={`${director?.director_first_name} ${director?.director_last_name}`} />
                                {director?.director_pan_urls?.length !== 0 && (
                                    <KycContentWrapper>
                                        {director?.director_pan_urls?.map((url, innerIndex) => (
                                        <React.Fragment key={index}>
                                            <KycContent text1={'PAN.'} text2={'10 KB'} url={url} />
                                            {director?.director_pan_urls?.length !== innerIndex + 1 && <GrayBox />}
                                        </React.Fragment>
                                        ))}
                                    </KycContentWrapper>
                                )}

                            {director?.director_pan_urls?.length === 0 && <KycContentNot />}
                        </ContentBoxKyc>
                    ))}
            </ContentWrapperKyc>
        </ContentContainerKyc>
        <ContentContainerKyc>
            <Header text1={'Business Owner(s)'} text2={''} />
            <ContentWrapperKyc>
                {company_business_owner_details?.map((owner, index) => (
                <ContentBoxKyc key={index}>
                    <KycHeader
                    text1={String.fromCharCode(alphabet + index)}
                    text2={'PAN for '}
                    text3={`${owner?.owner_first_name} ${owner?.owner_last_name}`}
                    />
                    {owner?.owner_pan_url?.length !== 0 && (
                        <KycContentWrapper>
                            {owner?.owner_pan_url?.map((url, innerIndex) => (
                            <React.Fragment key={innerIndex}>
                                <KycContent text1={'PAN.'} text2={'10 KB'} url={url} />
                                {owner?.owner_pan_url?.length !== innerIndex + 1 && <GrayBox />}
                                {/* You can add the GrayBox component here if needed */}
                            </React.Fragment>
                            ))}
                        </KycContentWrapper>
                    )}
                    {owner?.owner_pan_url?.length === 0 && <KycContentNot />}
                </ContentBoxKyc>
                ))}
            </ContentWrapperKyc>
        </ContentContainerKyc>

        <ContentContainerKyc>
            <Header text1={'Fideo account user(s)'} text2={''}/>
            <ContentWrapperKyc>
                    {company_account_user_details?.map((user,index) => (
                        <ContentBoxKyc>
                            <KycHeader text1={String.fromCharCode(alphabet+index)} text2={'PAN for '} text3={`${user?.user_first_name} ${user?.user_last_name}`} />
                            {user?.user_pan_urls?.length !== 0 && (
                                <KycContentWrapper>
                                    {user?.user_pan_urls?.map((url, innerIndex) => (
                                    <React.Fragment key={innerIndex}>
                                        <KycContent text1={'PAN.'} text2={'10 KB'} url={url} />
                                        {user?.user_pan_urls?.length !== innerIndex + 1 && <GrayBox />}
                                        {/* You can add the GrayBox component here if needed */}
                                    </React.Fragment>
                                    ))}
                                </KycContentWrapper>
                            )}
                            {user?.user_pan_urls?.length === 0 && <KycContentNot />}
                        </ContentBoxKyc>
                    ))}
            </ContentWrapperKyc>
        </ContentContainerKyc>
    </div>
  )
}

export default KYCDetails